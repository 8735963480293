import * as React from 'react';
import { Typography } from '@symphony-ui/uitoolkit-components';
import { printAddress, printTimestampString } from 'utils/table';
import OriginalHTML from '../../transactions/update-manager/OriginalHTML';
import OriginalContent from '../../transactions/update-manager/OriginalContent';
import EmailPartViewer from './EmailPartViewer';
import './EmailGridViewer.css';
const EmailGridViewer = ({ email, hasEmailError, hasPartsError, isEmailLoading, isHtml, isPartsLoading, parts, prettify, }) => (React.createElement("div", { style: { display: 'flex', flexDirection: 'row', overflow: 'hidden' } },
    React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', overflowX: 'scroll', width: '50%',
        } },
        React.createElement(Typography, { type: "h2" }, email.subject),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            React.createElement(Typography, { type: "h3" }, printAddress(email.from)),
            React.createElement(Typography, { type: "h3" }, printTimestampString(email.timestamp))),
        !isHtml
            ? (React.createElement(OriginalContent, { className: "co-email-panel", html: email.html, hasError: hasEmailError, isLoading: isEmailLoading, isVisible: true, noStyle: false })) : (React.createElement(OriginalHTML, { className: "co-email-panel", html: email.html, hasError: hasEmailError, isLoading: isEmailLoading, isVisible: true, prettify: prettify }))),
    React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', overflowX: 'scroll', width: '50%',
        } },
        React.createElement("div", { className: "co-email-panel co-visible" }, parts.map((p, index) => (React.createElement(EmailPartViewer, { hasError: hasPartsError, index: index, isHtml: isHtml, isLoading: isPartsLoading, key: `${p.subject}-${p.timestamp}-${p.body.length.toString()}`, messageId: email.messageId, part: p })))))));
export default EmailGridViewer;
