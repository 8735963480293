import * as React from 'react';
export default function useDebounce(callback, defaultValue, delay) {
    const inputRef = React.useRef(null);
    return React.useCallback(async (args) => {
        inputRef.current = args;
        await new Promise(resolve => setTimeout(resolve, delay));
        if (inputRef.current === args) {
            return callback(args);
        }
        return new Promise((resolve) => resolve(defaultValue));
    }, [callback, defaultValue, delay]);
}
;
