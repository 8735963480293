import * as React from 'react';
import { Dropdown, TextField } from '@symphony-ui/uitoolkit-components';
import Period from '../../models/Period';
import './PeriodInput.css';
const PeriodInput = ({ defaultPeriod, disabled, onChange, period, }) => {
    const [data, setData] = React.useState({
        period: period?.period ? period.period : defaultPeriod,
        periodMultiplier: period?.periodMultiplier.toString() ?? '',
    });
    const options = [{ label: 'D', value: 'D' }, { label: 'W', value: 'W' }, { label: 'M', value: 'M' }, { label: 'Y', value: 'Y' }];
    const onChangePeriod = React.useCallback((event) => {
        document.activeElement.blur();
        const { value } = event.target.value;
        if (value === 'D' || value === 'W' || value === 'M' || value === 'Y') {
            setData({ period: value, periodMultiplier: data.periodMultiplier });
            const newValue = Period.parseMultiplier(data.periodMultiplier);
            onChange({
                target: {
                    value: data.periodMultiplier !== undefined && newValue !== undefined
                        ? new Period({ period: value, periodMultiplier: newValue })
                        : undefined,
                },
            });
        }
    }, [data, onChange]);
    const onChangePeriodMultiplier = React.useCallback((event) => {
        const newValue = event.target.value;
        const newData = {
            period: data.period,
            periodMultiplier: Period.multiplierRegex.test(newValue) ? newValue : data.periodMultiplier,
        };
        setData(newData);
        const newValueMultiplier = Period.parseMultiplier(newValue);
        onChange({
            target: {
                value: newValueMultiplier !== undefined
                    ? new Period({ period: data.period, periodMultiplier: newValueMultiplier })
                    : undefined,
            },
        });
    }, [data, onChange]);
    return (React.createElement(TextField, { className: "input-with-addon period-input", disabled: disabled, label: "", onChange: onChangePeriodMultiplier, rightDecorators: (React.createElement(Dropdown, { className: "select-addon", disabled: disabled, isTypeAheadEnabled: false, label: "", onChange: onChangePeriod, options: options, value: options.find((o) => 'value' in o && o.value === data.period) })), value: data.periodMultiplier !== undefined ? data.periodMultiplier.toString() : '' }));
};
export default PeriodInput;
