import * as React from 'react';
const ProgressError = ({ error }) => (React.createElement("div", null,
    React.createElement("p", null,
        React.createElement("b", null, error.detail),
        ' ',
        "-",
        ' ',
        error.title,
        ' ',
        "-",
        ' ',
        error.status),
    error.type ? (React.createElement("a", { href: error.type }, "GO")) : null));
export default ProgressError;
