import ecbGenericDownload from './ecbGenericDownload';
const currencyDownload = async () => {
    const today = new Date();
    const threeDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 4);
    const body = await ecbGenericDownload(threeDaysAgo, today, 'EXR', 'D..EUR.SP00.A');
    const { series } = body.structure.dimensions;
    const parsedCurrencies = new Map();
    parsedCurrencies.set('EUR', 1);
    Object.entries(body.dataSets[0].series).forEach(([key, value]) => {
        const [, currencyIndex] = key.split(':');
        const currencyIndexValue = Number.parseInt(currencyIndex, 10);
        const [, currencyList] = series;
        const currency = currencyList.values[currencyIndexValue].id;
        const lastObservationIndex = Object.keys(value.observations).length - 1;
        const [currencyObservations] = value.observations[lastObservationIndex];
        parsedCurrencies.set(currency, currencyObservations);
    });
    return parsedCurrencies;
};
export default currencyDownload;
