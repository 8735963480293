export const unescapeRegex = (text) => (text
    .replace(/\\\)/g, ')')
    .replace(/\\\(/g, '(')
    .replace(/\\\\</g, '<')
    .replace(/\\\\>/g, '>')
    .replace(/\\\$/g, '$')
    .replace(/\\\\\\"/g, '"')
    .replace(/\\\./g, '.')
    .replace(/\\\{/g, '{')
    .replace(/\\\}/g, '}')
    .replace(/\\\|/g, '|')
    .replace(/\\\*/g, '*')
    .replace(/\\\+/g, '+')
    .replace(/\\\]/g, ']')
    .replace(/\\\[/g, '[')
    .replace(/\\\?/g, '?'));
export const escapeRegex = (text) => (text
    .replace(/\)/g, '\\)')
    .replace(/\(/g, '\\(')
    .replace(/\]/g, '\\]')
    .replace(/\[/g, '\\[')
    .replace(/\\"/g, '\\"')
    .replace(/\\</g, '\\<')
    .replace(/\\>/g, '\\>')
    .replace(/\./g, '\\.')
    .replace(/\*/g, '\\*')
    .replace(/\+/g, '\\+')
    .replace(/\$/g, '\\$')
    .replace(/{/g, '\\{')
    .replace(/}/g, '\\}')
    .replace(/\\{\\}/g, '{}')
    .replace(/\|/g, '\\|')
    .replace(/\?/g, '\\?'));
