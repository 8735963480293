import * as React from 'react';
import { Button, Dropdown, Icon, TextField, } from '@symphony-ui/uitoolkit-components';
import FilterItem from '../../models/FilterItem';
import cfiOptions from '../../utils/cfiOptions';
import TimeRangeInput from '../time-range-input/TimeRangeInput';
import TimeRange from '../../models/TimeRange';
import LEIInput from '../searches/esma-search-form/LEIInput';
import MultiInput from '../searches/esma-search-form/MultiInput';
import RemoteFilterItem from '../filter-item/RemoteFilterItem';
import './NameValuePair.css';
const NameValuePair = ({ description, name, remove, setValues, type, values, }) => {
    const onChangeDate = React.useCallback((event) => {
        setValues(event.target.value.toStringArray());
    }, [setValues]);
    const onChangeFilter = React.useCallback((event) => {
        const filterItem = event.target.value;
        setValues(filterItem.values);
    }, [setValues]);
    const onChangeMulti = React.useCallback((event) => {
        const { value } = event.target;
        console.log("Changed: %o", value);
        const newValues = Array.isArray(value) ? value.map((o) => o.value) : [value.value];
        setValues(newValues);
    }, [setValues]);
    const onChangeSingle = React.useCallback((event) => {
        setValues([event.target.value.value]);
    }, [setValues]);
    const onChangeText = React.useCallback((event) => {
        setValues([event.target.value]);
    }, [setValues]);
    const filterItem = React.useMemo(() => {
        return new FilterItem({ exclude: false, values });
    }, [values]);
    const content = React.useMemo(() => {
        switch (type) {
            case 'FILTER':
                return (React.createElement(RemoteFilterItem, { label: name.substring(0, 1).toUpperCase() + name.substring(1), onChange: onChangeFilter, path: `/nit/companies${name !== 'tags' ? `/esma/${name}/options` : '/tags/options/esma'}`, value: filterItem }));
            case 'MULTI':
                return (React.createElement(MultiInput, { label: description, name: name, onChange: onChangeMulti, values: values }));
            case 'LEI':
                return React.createElement(LEIInput, { onChange: onChangeSingle, value: values.length > 0 ? values[0] : undefined });
            case 'DATE':
                return (React.createElement(TimeRangeInput, { defaultPeriod: "Y", label: description, onChange: onChangeDate, value: TimeRange.from(values) }));
            case 'DATETIME':
                return (React.createElement(TimeRangeInput, { defaultPeriod: "D", label: description, onChange: onChangeDate, value: TimeRange.from(values) }));
            case 'CFI':
                return (React.createElement(Dropdown, { label: "Category", onChange: onChangeSingle, options: cfiOptions, value: cfiOptions.find((o) => 'value' in o && o.value === values[0]) }));
            // return <CFIInput onChange={change.single} values={nameValue.values} />;
            default:
                return React.createElement(TextField, { label: description, onChange: onChangeText, value: values.length > 0 ? values[0] : '' });
        }
    }, [description, filterItem, name, onChangeDate, onChangeFilter, onChangeMulti, onChangeSingle, onChangeText, type, values]);
    return (React.createElement("div", { className: "co-dropdown-row co-dropdown-first" },
        content,
        React.createElement(Button, { className: "co-button", iconButton: true, onClick: remove },
            React.createElement(Icon, { iconName: "delete" }))));
};
export default NameValuePair;
