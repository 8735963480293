import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, } from '@symphony-ui/uitoolkit-components';
const ShareConfirmationExport = ({ confirm, numberOfPages, show }) => {
    const clickCancel = React.useCallback(() => {
        confirm(false);
    }, [confirm]);
    const clickGo = React.useCallback(() => {
        confirm(true);
    }, [confirm]);
    return (React.createElement(Modal, { closeButton: true, onClose: clickCancel, parentNode: document.body, show: show, size: "medium" },
        React.createElement(ModalBody, null,
            "Each message from the NIT Bot on Symphony will contain up to 40 securities, so this will send",
            numberOfPages,
            "messages."),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: clickGo, variant: "primary-destructive" }, "Yes, I am sure"),
            React.createElement(Button, { onClick: clickCancel }, "No please cancel"))));
};
export default ShareConfirmationExport;
