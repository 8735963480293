import { Button, Checkbox, Icon, TextEllipsis, } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import { printAddress, printTimestampString } from 'utils/table';
import './SubRow.scss';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
const SubRow = ({ className, onClick, raw, setSelectedUpdate, setShowModal, setUpdates, update, updates, }) => {
    const { apiFetchNoContent: noContentPlus, apiFetchText: textPlus } = React.useContext(DownloadContext);
    const click2 = React.useCallback(() => {
        const url = new ApiServerURL('/syndicate/emails/html');
        url.searchParams.append('messageId', update.emailId.id);
        url.searchParams.append('index', update.emailId.index.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const text = await textPlus(request, 'Unable to download html');
                const checkedWithHtml = { ...update, html: text };
                setSelectedUpdate(checkedWithHtml);
                setShowModal(true);
            }
            catch (error) {
                //
            }
        })();
    }, [textPlus, setSelectedUpdate, setShowModal, update]);
    const click3 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        const url = new ApiServerURL('/syndicate/update/force-event');
        (async () => {
            const request = new ServerRequest(url, { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await noContentPlus(request, 'Unable to download html');
            }
            catch (error) {
                //
            }
        })();
    }, [noContentPlus, update]);
    const click4 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        (async () => {
            const url = new ApiServerURL('/syndicate/update/remove-event');
            // url.searchParams.append('messageId', update.emailId.id);
            // url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url, { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await noContentPlus(request, 'Unable to download html');
            }
            catch (error) {
                //
            }
        })();
    }, [noContentPlus, update]);
    const onChange = React.useCallback((index) => () => {
        const updatesCopy = [...updates];
        let updateFound = updatesCopy.find((u) => u.index === index);
        if (updateFound === undefined) {
            updateFound = updatesCopy.filter((u) => 'copies' in u).flatMap((u) => u.copies).find((c) => c !== undefined && c.index === index);
        }
        if (updateFound !== undefined) {
            updateFound.checked = !updateFound.checked;
        }
        setUpdates(updatesCopy);
    }, [setUpdates, updates]);
    return (React.createElement("tr", { className: className },
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: onClick, variant: "primary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click2, variant: "secondary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click3, variant: "tertiary" },
                React.createElement(Icon, { iconName: "symphony" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click4, variant: "tertiary" },
                React.createElement(Icon, { iconName: "delete" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Checkbox, { "aria-label": "plus", key: `key${update.index}`, name: "updateCheck", status: update.checked ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: "", onChange: onChange(update.index), label: "" })),
        React.createElement("td", { className: "co-sub-subject" },
            React.createElement(TextEllipsis, null, raw ? update.subjectRaw : update.subject)),
        React.createElement("td", { className: "co-sub-title" },
            React.createElement(TextEllipsis, null, raw ? update.titleRaw : update.title)),
        React.createElement("td", { className: "co-sub-from" },
            React.createElement(TextEllipsis, null, printAddress(update.from))),
        React.createElement("td", { className: "co-sub-timestamp" },
            React.createElement(TextEllipsis, null, printTimestampString(update.timestamp)))));
};
export default SubRow;
