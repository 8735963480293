class Option {
    /**
     * Load options; this can be either a string or a ServerRequest
     *
     * @param {string|ServerRequest} url
     */
    static async load(url) {
        try {
            const response = await fetch(url);
            if (response.status === 200) {
                const body = await response.json();
                const newOptions = body.results ? body.results.map((r) => ({ label: r.label, value: r.id }))
                    .reduce((accumulator, currentValue) => {
                    const existingValue = accumulator.find((a) => a.label === currentValue.label);
                    if (existingValue) {
                        existingValue.value = `${existingValue.value}:${currentValue.value}`;
                    }
                    else {
                        accumulator.push({ label: currentValue.label, value: currentValue.value });
                    }
                    return accumulator;
                }, []) : body;
                return newOptions;
            }
            return [];
        }
        catch (error) {
            return [];
        }
    }
    static fixDoubles(euroclearData) {
        const newOptions = euroclearData.results ? euroclearData.results
            .reduce((accumulator, currentValue) => {
            const existingValue = accumulator.find((a) => a.label === currentValue.label);
            if (existingValue && 'value' in existingValue) {
                existingValue.value = `${existingValue.value}:${currentValue.id}`;
            }
            else {
                accumulator.push({ label: currentValue.label, value: currentValue.id });
            }
            return accumulator;
        }, []) : [];
        return newOptions;
    }
    static find(options, value) {
        return options ? options.find((o) => o.value === value)?.label : undefined;
    }
}
export default Option;
