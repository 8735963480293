import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import SearchModal from '../SearchModal';
const SearchButton = () => {
    const [showSearchModal, setShowSearchModal] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        showSearchModal ?
            React.createElement(SearchModal, { onClose: () => { setShowSearchModal(false); } }) : undefined,
        React.createElement(Button, { iconButton: true, onClick: () => { setShowSearchModal(true); }, size: "small", title: "Search Update" },
            React.createElement(Icon, { iconName: "search" }))));
};
export default SearchButton;
