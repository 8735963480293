import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import { getOptionsMulti } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';
const CompanyForm = ({ company, save, types }) => {
    const [data, setData] = React.useState(company);
    const [errorState, setErrorState] = React.useState();
    const validators = React.useMemo(() => ({
        name: [Validators.Required, Validators.MaxLength(60)],
        types: CustomValidators.RequiredArray,
    }), []);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = errorState !== undefined ? { ...errorState, [name]: isValid } : { [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, save: save, name: "Company", setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "company-form" },
            React.createElement(Validation, { validator: validators.name, errorMessage: errorMessage, onValidationChanged: onValidationChanged('name') },
                React.createElement(TextField, { label: "Name", onChange: onChangeInput('name'), value: data.name || '' })),
            React.createElement(Validation, { validator: validators.types, errorMessage: errorMessage, onValidationChanged: onValidationChanged('types') },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Types", name: "types", onChange: onChangeDropdown('types'), options: types, value: getOptionsMulti(data.types, types) })))));
};
export default CompanyForm;
