import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const UpdatePagination = ({ callback, disabled, index, numberOfUpdates, setIndex, }) => {
    const clickStart = React.useCallback(() => {
        callback();
        setIndex(0);
    }, [callback, setIndex]);
    const clickEnd = React.useCallback(() => {
        callback();
        setIndex(numberOfUpdates - 1);
    }, [callback, setIndex, numberOfUpdates]);
    const clickPrevious = React.useCallback(() => {
        callback();
        setIndex(index - 1);
    }, [callback, index, setIndex]);
    const clickNext = React.useCallback(() => {
        callback();
        setIndex(index + 1);
    }, [callback, index, setIndex]);
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(Button, { disabled: disabled || index === 0, iconButton: true, name: "start", onClick: clickStart, size: "small" },
            React.createElement(Icon, { iconName: "chevron-left" })),
        React.createElement(Button, { disabled: disabled || index === 0, iconButton: true, name: "previous", onClick: clickPrevious, size: "small", style: { marginRight: '0.25rem' } }, "<"),
        React.createElement("div", null,
            index + 1,
            ' ',
            "out of",
            ' ',
            numberOfUpdates),
        React.createElement(Button, { disabled: disabled || index === numberOfUpdates - 1, iconButton: true, name: "next", onClick: clickNext, size: "small", style: { marginLeft: '0.25rem' } }, ">"),
        React.createElement(Button, { disabled: disabled || index === numberOfUpdates - 1, iconButton: true, name: "end", onClick: clickEnd, size: "small" },
            React.createElement(Icon, { iconName: "chevron-right" }))));
};
export default UpdatePagination;
