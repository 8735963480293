import * as React from 'react';
import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
import ContributorTable from './ContributorTable';
const Contributors = () => {
    const { updateView } = React.useContext(NavigationContext);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Contributors"),
            React.createElement("div", null,
                React.createElement(Button, { onClick: () => updateView('OVERVIEW'), size: "small" }, "OVERVIEW"),
                React.createElement(Button, { onClick: () => updateView('STATS'), size: "small" }, "STATS"))),
        React.createElement(ContributorTable, null)));
};
export default Contributors;
