import * as React from 'react';
import * as ReactDom from 'react-dom/client';
import MyRoutes from '../modules/core/pages/routes';
import initServiceWorker from './init-service-worker';
const rootElement = document.getElementById('root');
if (rootElement !== null) {
    const root = ReactDom.createRoot(rootElement);
    root.render((React.createElement(React.StrictMode, null,
        React.createElement(MyRoutes, null))));
    window.addEventListener('load', initServiceWorker, false);
}
