import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import NavigationContext from 'contexts/NavigationContext';
import NavigationService from 'services/NavigationService';
import { App } from 'utils/appUtils';
import Main from '../../../site/pages/main-page/Main';
import PageWrapper from 'components/PageWrapper';
import './generic.css';
const LocationRouter = () => {
    const [appView, setAppView] = React.useState();
    const [tab, setTab] = React.useState();
    const [view, setView] = React.useState();
    const [availableTabs, setAvailableTabs] = React.useState();
    const auth = useAuth();
    const navigationService = React.useMemo(() => new NavigationService({
        appView,
        setAppView,
        tab,
        setTab,
        view,
        setView,
        availableTabs,
        setAvailableTabs,
        user: auth.user,
        storage: localStorage,
    }), [auth.user, appView, availableTabs, tab, view]);
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('app') && params.get('app') === 'CMO') {
            navigationService.updateAppView(App.SYND);
        }
    }, [navigationService]);
    return (React.createElement(NavigationContext.Provider, { value: navigationService }, appView === undefined ? React.createElement(Main, null) : React.createElement(PageWrapper, null)));
};
export default LocationRouter;
