import securitiesTableActionCell from '../../components/results/securitiesTableActionCell';
import SecCompany from '../company/SecCompany';
class SecSecurity {
    accessionNumber;
    category;
    eqvamount;
    issuername;
    issuertype;
    links;
    name;
    tags;
    title;
    static create = (base, companies) => {
        const security = Object.assign(new SecSecurity(), base);
        if (companies.length > 0) {
            const regex = /^(?<type>[A-Z0-9]+) - (?<name>.*) \((?<cik>[0-9]{10})\) \(Filer\)$/;
            const match = regex.exec(security.title);
            const addZeroes = (code) => '0'.repeat(10 - code.length) + code;
            const matchGroups = match?.groups;
            const company = matchGroups !== undefined
                ? companies
                    .find((c) => addZeroes(c.company.cik) === matchGroups.cik)
                : undefined;
            if (company !== undefined && company.company instanceof SecCompany) {
                security.issuername = company ? company.company.name : '';
                security.issuertype = company ? company.company.sicDescription : '';
            }
            security.tags = company ? company.tags : [];
        }
        else {
            security.tags = [];
        }
        return security;
    };
    //  @typescript-eslint/no-unused-vars
    static getSort = () => 0;
    getShortname = () => this.title;
    static columnsSpreadsheet = [{
            accessorFn: (s) => s.issuername, header: 'Issuer',
        }];
    getProperties = () => [
        { label: 'Issuer', value: this.issuername },
    ];
    static columns(setModalContent) {
        return [
            { cell: securitiesTableActionCell(setModalContent), id: 'actions' },
            { accessorKey: 'issuername', header: 'Issuer Name' },
            // { cell: TableLink, header: 'Link to Document' },
            { accessorKey: 'issuertype', header: 'Issuer Type' },
            { accessorKey: 'category', header: 'Form' },
            { accessorKey: 'accessionNumber', header: 'Code' },
            { accessorKey: 'tags', header: 'Tags' },
        ];
    }
    companyMatcher = (c) => {
        const regex = /^(?<type>[A-Z0-9]+) - (?<name>.*) \((?<cik>[0-9]{10})\) \(Filer\)$/;
        const match = regex.exec(this.title);
        const matchGroups = match?.groups;
        return matchGroups && c.company instanceof SecCompany ? c.company.cik.padStart(10, '0') === matchGroups.cik : false;
    };
}
export default SecSecurity;
