import * as React from 'react';
import MessageContext from 'contexts/MessageContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { BannerType } from '@symphony-ui/uitoolkit-components';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import SearchForm from '../components/searches/SearchForm';
import SearchTable from '../components/searches/SearchTable';
import { fromSource, Source } from '../sources/sources';
import AnnaSearch from '../models/search/AnnaSearch';
import EsmaSearch from '../models/search/EsmaSearch';
import EuroclearSearch from '../models/search/EuroclearSearch';
import SecSearch from '../models/search/SecSearch';
import IpoSearch from '../models/search/IpoSearch';
const Searches = () => {
    const PATH = '/nit/search';
    const { apiFetchNoContent: noContentPlus, apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const { updateView, view } = React.useContext(NavigationContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [searches, setSearches] = React.useState([]);
    const [source, setSource] = React.useState(Source.EUROCLEAR);
    const [searchMap, setSearchMap] = React.useState({});
    React.useEffect(() => {
        const storedSearch = localStorage.getItem('searches');
        if (storedSearch !== null) {
            setSearchMap(JSON.parse(storedSearch));
        }
    }, [setSearchMap]);
    React.useEffect(() => {
        const storedSource = localStorage.getItem('source');
        if (storedSource !== null) {
            setSource(fromSource(storedSource));
        }
    }, [setSource]);
    React.useEffect(() => {
        try {
            localStorage.setItem('source', source);
        }
        catch (error) {
            // unable to set search as no access to localStorage
        }
    }, [source]);
    const path = React.useCallback((db) => `${PATH}/${db.toLowerCase()}`, []);
    const turnIntoClass = React.useCallback((db, search) => {
        switch (db) {
            case Source.ANNA:
                return AnnaSearch.create(search);
            case Source.ESMA:
                return EsmaSearch.create(search);
            case Source.EUROCLEAR:
                return EuroclearSearch.create(search);
            case Source.IPO:
                return IpoSearch.create(search);
            case Source.SEC:
                return SecSearch.create(search);
            default:
                throw new Error('Unable to find source');
        }
    }, []);
    React.useEffect(() => {
        (async () => {
            setSearches([]);
            const request = new ServerRequest(PATH);
            try {
                const body = await jsonPlus(request, 'Error downloading Searches');
                const tableData = [];
                Object.entries(body).forEach(([db, searchList]) => {
                    searchList.forEach((t) => tableData
                        .push(Object.assign(turnIntoClass(fromSource(db), t), { database: fromSource(db) })));
                });
                setSearches(tableData);
            }
            catch (error) {
                setError(true);
            }
            setLoading(false);
        })();
    }, [jsonPlus, turnIntoClass]);
    React.useEffect(() => {
        localStorage.setItem('searches', JSON.stringify(searchMap));
    }, [searchMap]);
    const deleteFn = React.useCallback(({ row: { original: newSearch } }) => {
        //      const isDeleted = await service.delete(entry);
        const db = newSearch.database;
        const request = new ServerRequest(path(db), { body: JSON.stringify(newSearch), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        (async () => {
            try {
                await noContentPlus(request, `Error deleting ${db} search`);
                const filteredData = searches.filter((i) => i.id !== newSearch.id);
                setSearches(filteredData);
            }
            catch {
                //
            }
        })();
    }, [noContentPlus, path, searches]);
    const edit = React.useCallback(({ row: { original: newSearch } }) => {
        const newMap = { ...searchMap, [newSearch.database]: newSearch };
        setSearchMap(newMap);
        updateView('FORM');
    }, [searchMap, updateView]);
    const save = React.useCallback(async (search) => {
        const request = new ServerRequest(path(source), { body: JSON.stringify(search), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });
        try {
            const body = await jsonPlus(request, `Error updating ${source} search`);
            const classedObject = turnIntoClass(source, body);
            const updatedData = CrudService.updateData(searches, classedObject);
            const newMap = { ...searchMap, [source]: classedObject };
            setSearchMap(newMap);
            setSearches(updatedData);
            setMessage({ content: `Search ${body.name} updated succesfully`, variant: BannerType.SUCCESS });
        }
        catch (error) {
            //
        }
        setSearchMap(searchMap);
    }, [jsonPlus, path, searchMap, searches, setMessage, source, turnIntoClass]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [updateView, view]);
    switch (view) {
        case 'FORM':
            return (React.createElement(SearchForm, { save: save, source: source, anySearch: searchMap[source], setSource: setSource, setAnySearch: (s) => {
                    const newMap = { ...searchMap, [source]: s };
                    setSearchMap(newMap);
                } }));
        case 'TABLE':
            return (React.createElement(SearchTable, { deleteFn: deleteFn, edit: edit, isLoading: isLoading, searches: searches, hasError: hasError }));
        default:
            updateView('TABLE');
            return React.createElement(LoadingPage, null);
    }
};
export default Searches;
