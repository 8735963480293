/**
 * This uses https://www.npmjs.com/package/xlsx-populate
 * @param {Security[]} data
 */
export async function exportToExcelOverview(headers, data, exportHeaders) {
    const XlsxPopulate = await import(
    /* webpackChunkName: "xlsx-chunk" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    'xlsx-populate/browser/xlsx-populate.js');
    const workbook = await XlsxPopulate.default.fromBlankAsync();
    const sheet = workbook.sheet('Sheet1');
    if (exportHeaders) {
        sheet.freezePanes(0, 1);
        const autoFilterRange = sheet.range(1, 1, 1, headers.length + 1);
        autoFilterRange.autoFilter();
    }
    let issuerColumn = 0;
    headers.forEach((header, index) => {
        const headerText = header.header;
        if (exportHeaders) {
            sheet.cell(1, index + 1).value(headerText);
        }
        sheet.column(index + 1).columnName(headerText);
        if (headerText === 'Issuer') {
            issuerColumn = index;
            sheet.column(index + 1).style('horizontalAlignment', 'right');
            sheet.column(index + 1).style('verticalAlignment', 'center');
            sheet.column(index + 1).style('bold', true);
        }
        else {
            sheet.column(index + 1).style('horizontalAlignment', 'center');
        }
        if (header.size) {
            sheet.column(index + 1).width(header.size / 7);
        }
    });
    data.forEach((row, rowIndex) => {
        row.data.forEach((value, columnIndex) => {
            const spreadsheetCell = sheet.cell(rowIndex + (exportHeaders ? 2 : 1), columnIndex + 1);
            if (value == null) {
                spreadsheetCell.value('-');
            }
            else if (value instanceof Date) {
                spreadsheetCell.value(value).style('numberFormat', 'DD MMM YYYY');
            }
            else if (typeof value === 'number') {
                spreadsheetCell.value(value).style('numberFormat', '#,##0;-#,##0');
            }
            else if (typeof value === 'string') {
                spreadsheetCell.value(value);
            }
            else {
                spreadsheetCell.value(`Unknown value type: ${typeof value}`);
            }
            //      spreadsheetCell.value(value);
        });
    });
    let issuerName = null;
    let issuerFirstRow = 0;
    data.forEach((row, rowIndex) => {
        if (rowIndex !== data.length - 1) {
            const newIssuerName = row.data[issuerColumn];
            //  console.log('Rowindex: %o, Row: %o, issuerName: %o, newIssuerName %o', rowIndex, row[issuerColumn], issuerName, newIssuerName);
            if (newIssuerName === issuerName && newIssuerName !== '') {
                // ignore
                //   console.log("It's the same and not empty so we are ignoring it");
            }
            else {
                // group the previous ones if necessary
                if (rowIndex - issuerFirstRow > 1) {
                    //    console.log("It's bigger than one so we will have start row: %o end row: %o", issuerFirstRow + 1, rowIndex);
                    const range = sheet.range(issuerFirstRow + 2, issuerColumn + 1, rowIndex + 1, issuerColumn + 1);
                    //   console.log('GROUPING - difference is %o Range: %o', rowIndex - issuerFirstRow, range);
                    range.merged(true);
                    // group
                }
                // now start a new range with the new ones
                issuerName = newIssuerName;
                issuerFirstRow = rowIndex;
            }
        }
    });
    // table.getRowModel().rows.forEach((row: Row<AnySecurity>, rowIndex: number) => {
    //   row.getVisibleCells()
    //     .forEach((cell: Cell<AnySecurity, string | Date | number>, columnIndex: number) => {
    //       const spreadsheetCell = sheet.cell(rowIndex + 2, columnIndex + 1);
    //       const value: string | Date | number = cell.getValue();
    //       if (value == null) {
    //         spreadsheetCell.value('-');
    //       } else if (value instanceof Date) {
    //         spreadsheetCell.value(value).style('numberFormat', 'DD MMM YYYY');
    //       } else if (typeof value === 'number') {
    //         spreadsheetCell.value(value).style('numberFormat', '#,##0;-#,##0');
    //       } else if (typeof value === 'string') {
    //         spreadsheetCell.value(value);
    //       } else {
    //         throw new Error(`Unknown value type: ${typeof value}`);
    //       }
    //     });
    // });
    sheet.row(1).style('bold', true);
    sheet.range(1, 1, data.length + 1, headers.length + 1).style('fontSize', 10).style('fontFamily', 'Arial');
    const blob = await workbook.outputAsync();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'out.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
export default exportToExcelOverview;
