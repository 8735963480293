import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import AuthenticationMonitor from './authentication/AuthenticationMonitor';
import WebPushMonitor from './web-push/WebPushMonitor';
import WebSocketMonitor from './web-socket/WebSocketMonitor';
const Monitor = () => {
    const { updateView, view } = React.useContext(NavigationContext);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('authentication');
        }
    }, [view, updateView]);
    switch (view) {
        case 'authentication':
            return (React.createElement(AuthenticationMonitor, null));
        case 'web-push':
            return (React.createElement(WebPushMonitor, null));
        case 'web-sockets':
            return (React.createElement(WebSocketMonitor, null));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default Monitor;
