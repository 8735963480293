import { Button } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import CrudService from 'services/CrudService';
import KeyValueRegex from '../../../../models/regexes/KeyValueRegex';
const CreateKeyValueRegexButton = ({ name, callback, }) => {
    const download = React.useContext(DownloadContext);
    const service = React.useMemo(() => new CrudService({ download, name: 'Regex', path: '/syndicate/regex/keyvalues' }), [download]);
    const create = () => {
        if (name !== undefined) {
            const newKeyValueRegex = new KeyValueRegex({ keys: [name] });
            newKeyValueRegex.keys = [name];
            (async () => {
                try {
                    const savedVersion = await service.createPlus(newKeyValueRegex);
                    callback(savedVersion);
                }
                catch (error) {
                    //
                }
            })();
        }
    };
    return (React.createElement(Button, { onClick: create }, "Create New"));
};
export default CreateKeyValueRegexButton;
