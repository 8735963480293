import * as React from 'react';
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Validation, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import { getOptionsMulti } from 'utils/change';
import { errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import DownloadContext from 'contexts/DownloadContext';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Source } from '../../sources/sources';
import exportToExcel, { getColumnsSpreadSheet } from '../../service/exportToExcel';
import ShareConfirmation from '../../pages/ShareConfirmation';
const ResultsShareModal = ({ filter, result, securities, setShow, show, source, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [data, setData] = React.useState();
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [errorState, setErrorState] = React.useState();
    const [loading, setLoading] = React.useState({ excel: false, symphony: false });
    const [options, setOptions] = React.useState([]);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const numberOfPages = React.useMemo(() => Math.round((securities.length - 1) / 40 + 1), [securities.length]);
    const validators = React.useMemo(() => ({
        streamIds: CustomValidators.RequiredArray,
    }), []);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/nit/streams/options');
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            if (typeof data !== 'undefined') {
                const initialErrorState = await initialValidation(validators, data);
                setErrorState(initialErrorState);
            }
        })();
    }, [validators, data]);
    React.useEffect(() => {
        const disabled = errorState !== undefined && Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled);
    }, [errorState]);
    const shareOnSymphony = React.useCallback(async () => {
        if (data?.streamIds !== undefined && result !== undefined) {
            const [resultSource, id] = result.value.split('|');
            const formData = new FormData();
            formData.append('streamIds', data.streamIds.join(','));
            formData.append('id', id);
            const jsonOption = { type: 'application/json' };
            formData.append('filter', new Blob([JSON.stringify(filter)], jsonOption));
            const request = new ServerRequest(`/nit/${resultSource.toLowerCase()}/share`, { body: formData, method: 'POST' });
            const response = await fetch(request);
            if (response.status === 204) {
                setShow(false);
            }
            else {
                //
            }
        }
    }, [filter, result, setShow, data?.streamIds]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [];
        const newObject = data !== undefined ? Object.assign(data, { [name]: newValue }) : { [name]: newValue };
        setData(newObject);
    }, [data]);
    const onClickClose = React.useCallback(() => {
        setShow(false);
    }, [setShow]);
    const onClickConfirm = React.useCallback((event) => {
        setShowConfirmation(false);
        if (event === true) {
            (async () => {
                await shareOnSymphony();
            })();
        }
    }, [shareOnSymphony]);
    const table = useReactTable({
        columns: getColumnsSpreadSheet(source ?? Source.EUROCLEAR),
        data: securities,
        getCoreRowModel: getCoreRowModel(),
    });
    const onClickExcel = React.useCallback(() => {
        setLoading({ ...loading, excel: true });
        (async () => {
            await exportToExcel(table);
            setLoading({ ...loading, excel: false });
        })();
    }, [loading, table]);
    const onClickSymphony = React.useCallback(() => {
        if (numberOfPages > 1) {
            setShowConfirmation(true);
        }
        else {
            setLoading({ ...loading, symphony: true });
            (async () => {
                await shareOnSymphony();
                setLoading({ ...loading, symphony: false });
            })();
        }
    }, [loading, numberOfPages, shareOnSymphony]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement("div", null,
        React.createElement(ShareConfirmation, { confirm: onClickConfirm, numberOfPages: numberOfPages, show: showConfirmation }),
        React.createElement(Modal, { show: show, closeButton: true, onClose: onClickClose, parentNode: document.body, size: "medium" },
            React.createElement(ModalHeader, null,
                React.createElement(ModalTitle, null, "Share on Symphony or Excel")),
            React.createElement(ModalBody, null,
                React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('streamIds'), validator: validators.streamIds },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Select Rooms and IMs to share on Symphony", onChange: onChangeDropdown('streamIds'), options: options, value: getOptionsMulti(data?.streamIds, options) }))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { disabled: disabledButton, loading: loading.symphony, onClick: onClickSymphony }, "Share on Symphony"),
                React.createElement(Button, { disabled: source === null, loading: loading.excel, onClick: onClickExcel }, "Open as Excel")))));
};
export default ResultsShareModal;
