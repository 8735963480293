import * as React from 'react';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import TermSheet from '../../model/TermSheet';
import TermSheetTable from './TermSheetTable';
import TermSheetForm from './TermSheetForm';
const TermSheets = () => {
    const { view, updateView } = React.useContext(NavigationContext);
    const download = React.useContext(DownloadContext);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [termSheets, setTermSheets] = React.useState([]);
    const [currencyOptions, setCurrencyOptions] = React.useState([]);
    const [interestBasisOptions, setInterestBasisOptions] = React.useState([]);
    const [issuerOptions, setIssuerOptions] = React.useState([]);
    const service = React.useMemo(() => new CrudService({ download, name: 'Term Sheet', path: '/mtn/term-sheets' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                setTermSheets(body);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
        (async () => {
            const currencyRequest = new ServerRequest('/mtn/currencies/options');
            try {
                const body = await download.apiFetchJson(currencyRequest, 'Error downloading currency options');
                setCurrencyOptions(body);
            }
            catch (error) {
                //
            }
        })();
        (async () => {
            const interestBasisRequest = new ServerRequest('/mtn/interest-basis/options');
            try {
                const body = await download.apiFetchJson(interestBasisRequest, 'Error downloading interest basis options');
                setInterestBasisOptions(body);
            }
            catch (error) {
                //
            }
        })();
        (async () => {
            const issuerRequest = new ServerRequest('/mtn/issuers/options');
            try {
                const body = await download.apiFetchJson(issuerRequest, 'Error downloading issuers options');
                setIssuerOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    const create = React.useCallback(() => {
        setDetails(new TermSheet());
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: termSheet } }) => {
        (async () => {
            try {
                await service.delete(termSheet);
                const filteredTermSheets = termSheets.filter((u) => u.id !== termSheet.id);
                setTermSheets(filteredTermSheets);
            }
            catch (error) {
                //
            }
        })();
    }, [service, termSheets]);
    const edit = React.useCallback(({ row: { original: termSheet } }) => {
        setDetails(termSheet);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedTermSheet = Object.assign(new TermSheet(), body);
            const updatedTermSheets = CrudService.updateData(termSheets, updatedTermSheet);
            setTermSheets(updatedTermSheets);
            updateView('TABLE');
        }
        catch (error) {
            //
        }
    }, [service, updateView, termSheets]);
    switch (view) {
        case 'FORM':
            return (React.createElement(TermSheetForm, { currencyOptions: currencyOptions, interestBasisOptions: interestBasisOptions, issuerOptions: issuerOptions, termSheet: details, save: save }));
        case 'TABLE':
            return (React.createElement(TermSheetTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, termSheets: termSheets }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default TermSheets;
