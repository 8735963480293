import * as React from 'react';
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import { getOptionsMulti } from 'utils/change';
import { errorMessage, initialValidation } from 'utils/validation/initialValidation';
import Party from '../../models/Party';
const PartyForm = ({ onClose, party, save }) => {
    const [data, setData] = React.useState(party ?? new Party({ name: '', names: [] }));
    const [errorState, setErrorState] = React.useState({});
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const isNew = React.useMemo(() => party?.id !== undefined, [party]);
    const validators = React.useMemo(() => ({
        name: [Validators.Required],
        names: [Validators.Required],
        podId: [Validators.Number],
        shortName: [Validators.MaxLength(6)],
    }), []);
    const dropdownOptions = React.useMemo(() => (data.names.map((p) => ({ label: p, value: p }))), [data.names]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        const updatedParty = data.updateStringArrayField(name, newValue);
        setData(updatedParty);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedParty = data.updateStringField(name, value);
        setData(updatedParty);
    }, [data]);
    const onChangePodId = React.useCallback((event) => {
        const { value } = event.target;
        const updatedParty = data.updateNumberField('podId', Number.parseInt(value, 10));
        setData(updatedParty);
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    React.useEffect(() => {
        (async () => {
            const newErrorState = await initialValidation(validators, data);
            setErrorState(newErrorState);
        })();
    }, [data, setErrorState, validators]);
    const disabledButton = React.useMemo(() => (Object.values(errorState).some((v) => !v)), [errorState]);
    const clickSave = React.useCallback(() => {
        if (party !== undefined) {
            setButtonLoading(true);
            (async () => {
                await save(party);
                setButtonLoading(false);
            })();
        }
    }, [party, save]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: 'large' },
        React.createElement(ModalTitle, null, "Edit Party"),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "base-form", id: "party-form", style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(Validation, { validator: validators.name, errorMessage: errorMessage, onValidationChanged: onValidationChanged('name') },
                    React.createElement(TextField, { label: "Name", onChange: onChangeInput('name'), value: data.name })),
                React.createElement("div", { style: { display: 'flex', flexDirection: 'row' } },
                    React.createElement(Validation, { validator: validators.shortName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('shortName') },
                        React.createElement(TextField, { label: "Short Name", onChange: onChangeInput('shortName'), tooltip: "Up to 6 capital letters", value: data.shortName ?? '' })),
                    React.createElement("div", { style: { marginLeft: '1rem', width: '4rem' } },
                        React.createElement(Validation, { validator: validators.podId, errorMessage: errorMessage, onValidationChanged: onValidationChanged('podId') },
                            React.createElement(TextField, { label: "PodId", onChange: onChangePodId, tooltip: "Symphony Pod Id", value: data.podId?.toString() ?? '' })))),
                React.createElement(Validation, { validator: validators.names, errorMessage: errorMessage, onValidationChanged: onValidationChanged('names') },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Synonyms", name: "names", onChange: onChangeDropdown('names'), options: dropdownOptions, value: getOptionsMulti(data.names, dropdownOptions) })))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabledButton, loading: buttonLoading, onClick: clickSave }, isNew ? 'UPDATE' : 'CREATE'))));
};
export default PartyForm;
