import { Button, Card, Icon, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import DownloadContext from 'contexts/DownloadContext';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import LabelPlacements from '@symphony-ui/uitoolkit-components/components/selection/LabelPlacements';
import './UpdateManager.scss';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
import SyndicateContext from '../../../context/SyndicateContext';
import UpdatePagination from './UpdatePagination';
import JsonModal from './JsonModal';
import GridViewer from './GridViewer';
import DataModal from './DataModal';
import SearchModal from './SearchModal';
const UpdateManager = () => {
    const { apiFetchNoContent, apiFetchJson, apiFetchText } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const { updateId, updateIndex } = React.useContext(SyndicateContext);
    const [content, setContent] = React.useState('');
    const [data, setData] = React.useState();
    const [tranches, setTranches] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState(updateId === undefined ? 'You need to choose a transaction first' : undefined);
    const [hasContentError, setContentError] = React.useState(false);
    const [hasError, setError] = React.useState(updateId === undefined);
    const [html, setHtml] = React.useState('');
    const [hasHtmlError, setHtmlError] = React.useState(false);
    const [isContentLoading, setContentLoading] = React.useState(false);
    const [isHtmlLoading, setHtmlLoading] = React.useState(false);
    const [isDataLoading, setDataLoading] = React.useState(false);
    const [isLoading, setLoading] = React.useState(updateId !== undefined);
    const [prettify, setPrettify] = React.useState(localStorage.getItem('prettify') === 'true' || false);
    // const [updates, setUpdates] = React.useState<Update[]>([]);
    const [showDataModal, setShowDataModal] = React.useState(false);
    const [showJsonModal, setShowJsonModal] = React.useState(false);
    const [showSearchModal, setShowSearchModal] = React.useState(false);
    const [numberOfUpdates, setNumberOfUpdates] = React.useState(0);
    const [update, setUpdate] = React.useState();
    const [touchStart, setTouchStart] = React.useState();
    const [touchEnd, setTouchEnd] = React.useState();
    React.useEffect(() => {
        const abortController = new AbortController();
        if (updateId !== undefined) {
            setLoading(true);
            (async () => {
                const url = new ApiServerURL('/syndicate/transactions/no-updates');
                url.searchParams.append('id', updateId.transactionId);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setNumberOfUpdates(body);
                }
                catch (error) {
                    sendFetchError('Unable to download transactions', error, request);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
        else {
            setError(true);
        }
        return () => abortController.abort();
    }, [apiFetchJson, updateId]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const index = updateId?.index;
        if (updateId !== undefined && index !== undefined) {
            setLoading(true);
            setHtmlLoading(true);
            (async () => {
                const url = new ApiServerURL('/syndicate/transactions/update');
                url.searchParams.append('transactionId', updateId.transactionId);
                url.searchParams.append('index', index.toString());
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setUpdate(body);
                }
                catch (error) {
                    sendFetchError('Unable to download update', error, request);
                }
                finally {
                    setHtmlLoading(false);
                    setLoading(false);
                }
            })();
        }
        else {
            setError(true);
        }
        return () => abortController.abort();
    }, [apiFetchJson, updateId]);
    React.useEffect(() => {
        if (numberOfUpdates > 0 && updateId?.index === undefined) {
            updateIndex(numberOfUpdates - 1);
        }
    }, [numberOfUpdates, updateIndex, updateId]);
    const clickParseAll = React.useCallback(() => {
        if (updateId !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/background-update');
            url.searchParams.append('id', updateId.transactionId);
            const request = new ServerRequest(url, { method: 'POST' });
            (async () => {
                try {
                    await apiFetchNoContent(request);
                }
                catch (error) {
                    sendFetchError('Unable to perform background update', error, request);
                    //      setErrorMessage(error as string);
                }
            })();
        }
    }, [apiFetchNoContent, updateId]);
    const refresh = React.useCallback((messageId, send) => {
        const abortController = new AbortController();
        setContentLoading(true);
        const url = new ApiServerURL('/syndicate/transactions/content');
        url.searchParams.append('messageId', messageId.id);
        url.searchParams.append('index', messageId.index.toString());
        url.searchParams.append('type', 'true');
        url.searchParams.append('send', send.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchText(request, abortController.signal);
                setContent(body);
            }
            catch (error) {
                sendFetchError('Unable to download content', error, request);
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setContentError(true);
                }
            }
            finally {
                setContentLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchText]);
    const copyId = React.useCallback(() => {
        const emailId = {
            'updates.emailId': {
                _id: update?.emailId.id,
                index: update?.emailId.index,
            },
        };
        const t = { type: 'text/plain' };
        const p = JSON.stringify(emailId);
        const blob = new Blob([p], t);
        (async () => navigator.clipboard.write([new ClipboardItem({ 'text/plain': blob })]))();
    }, [update]);
    const backgroundParse = React.useCallback((type) => {
        if (update !== undefined) {
            const url = new ApiServerURL(`/syndicate/transactions/parse-${type}`);
            url.searchParams.append('messageId', update.emailId.id);
            url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url, { method: 'POST' });
            (async () => {
                try {
                    setDataLoading(true);
                    await apiFetchNoContent(request);
                }
                catch (error) {
                    sendFetchError(`Unable to parse ${type}`, error, request);
                }
                finally {
                    setDataLoading(false);
                }
            })();
        }
    }, [apiFetchNoContent, update]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (update !== undefined) {
            setHtmlLoading(true);
            const url = new ApiServerURL('/syndicate/emails/html');
            url.searchParams.append('messageId', update.emailId.id);
            url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchText(request, abortController.signal);
                    setHtml(body);
                }
                catch (error) {
                    if (error instanceof Error && error.name !== 'AbortError') {
                        sendFetchError('Unable to download HTML', error, request);
                        setHtmlError(true);
                    }
                }
                setHtmlLoading(false);
            })();
        }
        return () => abortController.abort();
    }, [apiFetchText, update]);
    const reloadFunction = React.useCallback((messageId) => () => {
        refresh(messageId, false);
    }, [refresh]);
    React.useEffect(() => {
        if (updateId?.index !== undefined && numberOfUpdates > updateId.index && update !== undefined) {
            refresh(update.emailId, false);
        }
    }, [updateId?.index, numberOfUpdates, update, refresh]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (update !== undefined && 'emailId' in update) {
            document.addEventListener('reloadcontent', reloadFunction(update.emailId), { once: false, signal: abortController.signal });
        }
        return () => abortController.abort();
    }, [reloadFunction, update]);
    React.useEffect(() => {
        if (updateId === null) {
            setErrorMessage('You need to choose a transaction first');
            setError(true);
        }
    }, [updateId]);
    React.useEffect(() => {
        if (updateId?.index !== undefined && numberOfUpdates > 0 && numberOfUpdates < updateId?.index) {
            updateIndex(0);
        }
    }, [updateIndex, numberOfUpdates, updateId?.index]);
    React.useEffect(() => {
        setError(numberOfUpdates === 0);
    }, [numberOfUpdates]);
    const handleStart = React.useCallback((e) => {
        //  e.preventDefault();
        setTouchEnd(undefined); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }, []);
    const handleEnd = React.useCallback(() => {
        //  e.preventDefault();
        if (!touchStart || !touchEnd || updateId?.index === undefined)
            return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 100;
        const isRightSwipe = distance < -100;
        if (isLeftSwipe && updateId.index < numberOfUpdates - 1) {
            updateIndex(updateId.index + 1);
        }
        else if (isRightSwipe && updateId.index > 0) {
            updateIndex(updateId.index - 1);
        }
    }, [touchEnd, touchStart, numberOfUpdates, updateId?.index, updateIndex]);
    const handleCancel = React.useCallback(() => {
        //    e.preventDefault();
    }, []);
    const handleMove = React.useCallback((e) => {
        //  e.preventDefault();
        setTouchEnd(e.targetTouches[0].clientX);
    }, []);
    const handleData = React.useCallback(() => {
        const abortController = new AbortController();
        if (updateId?.index !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/data');
            url.searchParams.append('id', updateId.transactionId);
            url.searchParams.append('index', updateId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setData(body);
                    setShowDataModal(true);
                }
                catch (error) {
                    sendFetchError('Unable to download transaction data', error, request);
                }
            })();
        }
        return () => abortController.abort();
    }, [apiFetchJson, updateId?.index, updateId?.transactionId]);
    const handleJson = React.useCallback(() => {
        const abortController = new AbortController();
        if (updateId?.index !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/tranches');
            url.searchParams.append('id', updateId.transactionId);
            url.searchParams.append('index', updateId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setTranches(body);
                    setShowJsonModal(true);
                }
                catch (error) {
                    sendFetchError('Unable to download tranche', error, request);
                }
            })();
        }
        return () => abortController.abort();
    }, [apiFetchJson, updateId]);
    return (React.createElement(Card, { style: { padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Update Management"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "overview", onClick: () => updateView('OVERVIEW'), size: "small" }, "OVERVIEW"),
                React.createElement(Button, { name: "transaction", onClick: () => updateView('TABLE'), size: "small", style: { marginLeft: '0.25rem' } }, "TRANSACTIONS"))),
        !hasError && !isLoading && updateId?.index !== undefined
            ? (React.createElement("div", null,
                React.createElement(DataModal, { onClose: () => setShowDataModal(false), show: showDataModal, title: update?.subject ?? 'no subject', data: data }),
                React.createElement(JsonModal, { onClose: () => setShowJsonModal(false), show: showJsonModal, title: update?.subject ?? 'no subject', tranches: tranches }),
                React.createElement(SearchModal, { onClose: () => setShowSearchModal(false), show: showSearchModal }),
                React.createElement("div", { onTouchCancel: handleCancel, onTouchEnd: handleEnd, onTouchStart: handleStart, onTouchMove: handleMove, className: "co-update-header" },
                    React.createElement(Typography, { type: "h1" }, update?.subject),
                    React.createElement(Typography, { type: "h2" }, update?.from.map((f) => `${f.personal} <${f.value}>`).join(','))),
                React.createElement("div", { className: "co-update-buttons", style: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '0.25rem' } },
                        React.createElement(Button, { onClick: handleData, size: "small" }, "DATA"),
                        React.createElement(Button, { onClick: handleJson, size: "small" }, "JSON"),
                        React.createElement(Button, { loading: isDataLoading, onClick: () => backgroundParse('syntax'), size: "small" }, "PARSE SYNTAX"),
                        React.createElement(Button, { loading: isDataLoading, onClick: () => backgroundParse('data'), size: "small" }, "PARSE DATA"),
                        React.createElement(Button, { onClick: () => clickParseAll(), size: "small" }, "PARSE ALL")),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(UpdatePagination, { callback: () => {
                                setUpdate(undefined);
                            }, disabled: isLoading || isContentLoading, numberOfUpdates: numberOfUpdates }),
                        React.createElement("div", { style: { display: 'flex' } },
                            React.createElement(Button, { iconButton: true, onClick: () => setShowSearchModal(true), size: "small", title: "Search Update" },
                                React.createElement(Icon, { iconName: "search" })),
                            React.createElement(Button, { iconButton: true, onClick: () => copyId(), size: "small", title: "Copy MessageId to Clipboard" },
                                React.createElement(Icon, { iconName: "copy" })),
                            React.createElement(Switch, { label: "Prettify", labelPlacement: LabelPlacements.BOTTOM, name: "prettify", onChange: () => setPrettify(!prettify), status: prettify ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: prettify.toString() })))),
                React.createElement(GridViewer, { html: html, hasHtmlError: hasHtmlError, isHtmlLoading: isHtmlLoading, content: content, hasContentError: hasContentError, isContentLoading: isContentLoading, noStyle: false, prettify: prettify })))
            : React.createElement("h4", null, errorMessage)));
};
export default UpdateManager;
