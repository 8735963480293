import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
import SyndInfoValue from './SyndInfoValue';
const SyndInfoLine = ({ path, text }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest(`/syndicate/info/${path}`);
        setLoading(true);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setValue(body);
            }
            catch (error) {
                sendFetchError('Error getting system info', error, request);
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, path]);
    return (React.createElement("tr", null,
        React.createElement("td", { style: { paddingRight: '1rem', textAlign: 'right' } },
            text,
            ":"),
        React.createElement("td", null,
            React.createElement(SyndInfoValue, { isLoading: isLoading, value: value }))));
};
export default SyndInfoLine;
