import * as React from 'react';
import { Button, Icon, Typography } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
import './CardHeader.css';
const CardHeaderForm = ({ newView, title }) => {
    const { updateView } = React.useContext(NavigationContext);
    const onClick = () => { if (newView !== undefined) {
        updateView(newView);
    } };
    return (React.createElement("div", { className: "card-header" },
        React.createElement(Typography, { type: "h1" }, title),
        newView !== undefined
            ? React.createElement(Button, { iconButton: true, onClick: (onClick), variant: "tertiary" },
                React.createElement(Icon, { iconName: "cross" })) : null));
};
export default CardHeaderForm;
