import { printBoolean } from 'components/custom-table/dateFormat';
import print from '../transactions/overview/cells/print';
const tableMap = (esgMap) => ([
    { header: 'DealId', printFn: (t) => t.transaction.id },
    { header: 'Issuer', printFn: print.issuerRaw },
    { header: 'DealStatus', printFn: () => 'PRICED' },
    { header: 'BBGTicker', printFn: print.ticker },
    { header: 'TrancheId', printFn: (t) => t.trancheIndex.toString() },
    { header: 'Coupon', printFn: print.coupon },
    { header: 'CouponType', printFn: print.interestBasis },
    { header: 'Currency', printFn: print.currency },
    { header: 'ESG', printFn: print.documentation(esgMap, 'esg') },
    { header: 'Guidance', printFn: print.guidance('guidance') },
    { header: 'IPT', printFn: print.guidance('initialPriceThoughts') },
    { header: 'Maturity', printFn: print.maturity },
    {
        header: 'Oversubscription',
        printFn: (value) => {
            const os = print.oversubscription(value);
            if (os === undefined || typeof os === 'string') {
                return os;
            }
            const options = { maximumFractionDigits: 1 };
            return Intl.NumberFormat((new Intl.NumberFormat()).resolvedOptions().locale, options).format(os);
        },
    },
    { header: 'Pricing', printFn: print.pricing },
    { header: 'Rating', printFn: print.ratings },
    { header: 'PricingDate', printFn: print.pricingDate },
    { header: 'Settlement', printFn: print.settlementDate },
    { header: 'Size', printFn: print.amount },
    { header: 'Spread', printFn: print.spread },
    { header: 'Yield', printFn: print.yield },
    {
        header: 'Tap',
        printFn: (value) => {
            const t = print.tap(value);
            return t !== undefined ? printBoolean(t, 'code') : undefined;
        },
    },
]);
export const interpriceColumns = tableMap(new Map()).map((m) => m.header);
const transformDataForInterprice = (data, esgMap) => {
    const all = tableMap(esgMap);
    const allArrays = [];
    const headers = all.map((i) => i.header);
    allArrays.push(headers);
    data.forEach((t) => {
        const trancheRow = all.map((i) => {
            const value = i.printFn(t);
            if (typeof value === 'number') {
                return value.toString();
            }
            if (typeof value === 'string') {
                if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                    const newValue = value.replaceAll('"', '""');
                    return `"${newValue}"`;
                }
                return value;
            }
            if (typeof value === 'object' && value instanceof Date) {
                return value.toISOString().substring(0, 10);
            }
            return '';
        });
        allArrays.push(trancheRow);
    });
    return allArrays;
};
export default transformDataForInterprice;
