var _a;
import MetaData from 'components/metadata/MetaData';
class Report extends MetaData {
    frequency;
    name;
    searchId;
    showEmpty;
    source;
    streamIds;
    constructor(response) {
        super(response);
        this.frequency = response?.frequency;
        this.name = response?.name;
        this.searchId = response?.searchId;
        this.showEmpty = response?.showEmpty;
        this.source = response?.source;
        this.streamIds = response?.streamIds ?? [];
    }
    #clone() {
        return new _a({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            frequency: this.frequency,
            id: this.id,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            name: this.name,
            permissions: this.permissions,
            searchId: this.searchId,
            showEmpty: this.showEmpty,
            source: this.source,
            streamIds: this.streamIds
        });
    }
    setSearchId(value) {
        this.searchId = value;
        return this;
    }
    updateFrequency(value) {
        const clone = this.#clone();
        clone.frequency = value;
        return clone;
    }
    update(name, value) {
        const clone = this.#clone();
        clone[name] = value;
        return clone;
    }
    updateSearch(searchId, source) {
        const clone = this.#clone();
        this.searchId = searchId;
        this.source = source;
        return clone;
    }
    updatePermissions(permissions) {
        const clone = this.#clone();
        clone.permissions = permissions;
        return clone;
    }
}
_a = Report;
export default Report;
