import * as React from 'react';
import TimestampFilter from './TimestampFilter';
import print from '../cells/print';
import TimestampFilterPlus from './TimestampFilterPlus';
import RenderingContext from 'contexts/RenderingContext';
function labelValuesToMap(values) {
    const map = new Map();
    values.filter(v => 'value' in v).forEach((v) => {
        if ('value' in v && v.value) {
            map.set(v.value, v.label);
        }
    });
    return map;
}
;
export default function useOverviewColumnsUser(sectorOptions, esgOptions, statusOfNotesOptions, typeOptions, regionOptions) {
    const { printAny, printDate } = React.useContext(RenderingContext);
    const transform = (printFn) => {
        return (tranche) => printAny(printFn(tranche));
    };
    return [
        {
            accessorFn: print.sector(labelValuesToMap(sectorOptions)),
            header: 'Sector',
            id: 'sector',
            meta: { canCombine: true },
            size: 80,
        },
        {
            accessorFn: print.types(labelValuesToMap(typeOptions)),
            header: 'Types',
            id: 'types',
            meta: { canCombine: true },
            size: 240,
        },
        {
            accessorFn: print.regions(labelValuesToMap(regionOptions)),
            header: 'Regions',
            id: 'regions',
            meta: { canCombine: true },
            size: 100,
        },
        {
            accessorFn: print.documentation(labelValuesToMap(esgOptions), 'esg'),
            header: 'ESG',
            id: 'esg',
            size: 80,
        },
        {
            accessorFn: print.documentationArray(labelValuesToMap(statusOfNotesOptions), 'statusOfNotes'),
            header: 'Rank/Type',
            id: 'statusOfNotes',
            size: 200,
        },
        {
            accessorFn: print.issuer,
            enableColumnFilter: true,
            header: 'Issuer',
            id: 'issuer',
            meta: { canCombine: true, style: { textAlign: 'right', fontWeight: 'bold' } },
            size: 400,
        },
        {
            accessorFn: print.ticker,
            enableColumnFilter: false,
            header: 'Ticker',
            id: 'ticker',
            size: 120,
        },
        {
            accessorFn: print.ratings,
            enableColumnFilter: false,
            header: 'Ratings',
            id: 'ratings',
        },
        {
            accessorFn: print.currency,
            enableColumnFilter: true,
            header: 'Currency',
            id: 'currency',
            size: 100,
        },
        {
            accessorFn: transform(print.amount),
            enableColumnFilter: false,
            header: 'Amount',
            id: 'amount',
            maxSize: 82,
        },
        {
            accessorFn: transform(print.coupon),
            enableColumnFilter: false,
            header: 'Coupon',
            id: 'coupon',
            size: 80,
        },
        {
            accessorFn: transform(print.maturity),
            enableColumnFilter: false,
            header: 'Maturity',
            id: 'maturity',
        },
        {
            accessorFn: print.tenor,
            enableColumnFilter: false,
            header: 'Tenor',
            id: 'tenor',
            size: 48,
        },
        {
            accessorFn: print.guidance('initialPriceThoughts'),
            enableColumnFilter: false,
            header: 'IPT',
            id: 'initialPriceThoughts',
        },
        {
            accessorFn: print.guidance('guidance'),
            enableColumnFilter: false,
            header: 'Guidance',
            id: 'guidance',
        },
        {
            accessorFn: print.pricing,
            enableColumnFilter: false,
            header: 'Spd at Issue',
            id: 'pricing',
        },
        {
            accessorFn: transform(print.iptMove),
            enableColumnFilter: false,
            header: 'Move from IPT',
            id: 'iptMove',
        },
        {
            accessorFn: transform(print.book),
            enableColumnFilter: false,
            header: 'Last Book Update',
            id: 'books',
        },
        {
            accessorFn: transform(print.oversubscription),
            enableColumnFilter: false,
            header: 'Oversubscription level',
            id: 'oversubscription',
        },
        {
            accessorFn: print.bookrunners,
            header: 'Leads',
            id: 'bookrunners',
            meta: { canCombine: true, style: { textAlign: 'left' } },
            size: 720,
        },
        {
            accessorFn: print.isins,
            enableColumnFilter: false,
            header: 'ISIN',
            id: 'isin',
        },
        {
            accessorFn: print.lei,
            enableColumnFilter: false,
            header: 'LEI',
            id: 'lei',
        },
        {
            accessorFn: transform(print.pricingDate),
            enableColumnFilter: true,
            header: 'Pricing Date',
            id: 'pricingDate',
            meta: {
                Panel: TimestampFilterPlus,
            },
        },
        {
            accessorFn: transform(print.settlementDate),
            enableColumnFilter: false,
            header: 'Settlement Date',
            id: 'settlementDate',
        },
        {
            accessorFn: print.tap,
            enableColumnFilter: false,
            header: 'Tap',
            id: 'tap',
            size: 50,
        },
        {
            accessorFn: (t) => printDate(t.transaction.firstUpdate),
            header: 'Announced',
            id: 'announcementDate',
        },
        {
            accessorFn: (t) => printDate(t.transaction.lastUpdate),
            header: 'Last Update',
            id: 'lastUpdate',
            meta: { Panel: TimestampFilter },
        },
        {
            accessorFn: (t) => t.transaction.id,
            header: 'Transaction Id',
            id: 'transactionId',
        },
        {
            accessorFn: (t) => t.trancheIndex.toString(),
            header: 'Tranche Index',
            id: 'trancheIndex',
        }
    ];
}
