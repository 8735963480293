/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { Button, Card } from '@symphony-ui/uitoolkit-components';
import { initialValidation } from 'utils/initialValidation';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
const FormWrapper = function FormWrapperElement({ children, data, errorState, name, save, setErrorState, validators, }) {
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const isNew = React.useMemo(() => data !== undefined && 'id' in data, [data]);
    React.useEffect(() => {
        (async () => {
            const newErrorState = await initialValidation(validators, data);
            setErrorState(newErrorState);
        })();
    }, [data, setErrorState, validators]);
    React.useEffect(() => {
        const disabled = errorState === undefined || Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled);
    }, [errorState]);
    const clickSave = React.useCallback(() => {
        if (data !== undefined) {
            setButtonLoading(true);
            (async () => {
                await save(data);
                setButtonLoading(false);
            })();
        }
    }, [data, save]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { newView: "TABLE", title: isNew ? `Edit ${name}` : `Create ${name}` }),
        children,
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Button, { disabled: disabledButton, loading: buttonLoading, onClick: clickSave }, isNew ? 'UPDATE' : 'CREATE'))));
};
export default FormWrapper;
