import * as React from 'react';
import CloseIcon from './CloseIcon';
import DebouncedInput from './DebouncedInput';
const NumberFilter = function NubmerFilterElement({ close, column }) {
    return (React.createElement("div", { className: "co-check-panel" },
        React.createElement("div", { className: "flex" },
            React.createElement(DebouncedInput, { type: "number", min: Number(column.getFacetedMinMaxValues()?.[0] ?? ''), max: Number(column.getFacetedMinMaxValues()?.[1] ?? ''), value: column.getFilterValue()?.[0] ?? '', onChange: (value) => column
                    .setFilterValue((old) => [value, old?.[1]]), placeholder: `Min ${column.getFacetedMinMaxValues()?.[0]
                    ? `(${column.getFacetedMinMaxValues()?.[0]})`
                    : ''}`, className: "w-24 border shadow rounded" }),
            React.createElement(DebouncedInput, { type: "number", min: Number(column.getFacetedMinMaxValues()?.[0] ?? ''), max: Number(column.getFacetedMinMaxValues()?.[1] ?? ''), value: column.getFilterValue()?.[1] ?? '', onChange: (value) => column
                    .setFilterValue((old) => [old?.[0], value]), placeholder: `Max ${column.getFacetedMinMaxValues()?.[1]
                    ? `(${column.getFacetedMinMaxValues()?.[1]})`
                    : ''}`, className: "w-24 border shadow rounded" }),
            React.createElement(CloseIcon, { onClick: close }))));
};
export default NumberFilter;
