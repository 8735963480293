import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const LeiDropdown = ({ lei, name, options, setLei, }) => {
    const onChangeLei = React.useCallback((event) => {
        setLei(event.target.value.value);
    }, [setLei]);
    const optionRenderer = React.useCallback((value) => (React.createElement("div", null,
        React.createElement("div", null, value.data.label),
        value.data.data !== undefined ? value.data.data.map((t) => (React.createElement("div", { style: {
                color: 'darkgray', fontSize: 'x-small', marginLeft: '0.5rem', textWrap: 'nowrap',
            } }, `${t.name} - ${t.type}`))) : undefined)), []);
    if (options === undefined) {
        return undefined;
    }
    if (options.length > 0) {
        return (React.createElement(Dropdown, { onChange: onChangeLei, optionRenderer: optionRenderer, options: options, value: options.find((o) => 'value' in o && o.value === lei) }));
    }
    return (React.createElement("div", { style: {
            fontSize: 'small', paddingLeft: '0.5rem', color: '#dd342e',
        } },
        React.createElement("span", { style: { fontStyle: 'italic' } }, "None found for"),
        name));
};
export default LeiDropdown;
