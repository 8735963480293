import * as React from 'react';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import CurrencyTable from './CurrencyTable';
import CurrencyForm from './CurrencyForm';
const Currencies = () => {
    const download = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [currencies, setCurrencies] = React.useState();
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const service = React.useMemo(() => new CrudService({ download, name: 'Currency', path: '/core/currencies' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                setCurrencies(body);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [service]);
    const create = React.useCallback(() => {
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        (async () => {
            try {
                await service.delete(item);
                const filteredCurrencies = currencies !== undefined ? currencies.filter((u) => u.id !== item.id) : undefined;
                setCurrencies(filteredCurrencies);
            }
            catch (error) {
                //
            }
        })();
    }, [currencies, service]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setDetails(item);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        if (currencies !== undefined) {
            try {
                const body = await (data.id
                    ? service.updatePlus(data) : service.createPlus(data));
                const updatedCurrencies = CrudService.updateData(currencies, body);
                setCurrencies(updatedCurrencies);
                updateView('TABLE');
            }
            catch (error) {
                //
            }
        }
    }, [currencies, service, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(CurrencyForm, { currency: details, save: save }));
        case 'TABLE':
            return (React.createElement(CurrencyTable, { create: create, deleteFn: deleteFn, edit: edit, currencies: currencies ?? [], hasError: hasError, isLoading: isLoading }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Currencies;
