export const BLSColumns = [
    { accessorKey: 'summary', header: 'Summary' },
];
export const BOECOlumns = [
    { accessorKey: 'category', header: 'Category' },
    { accessorKey: 'type', header: 'Type' },
];
export const ECBColumns = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'dataSet', header: 'Data Set' },
];
export const IFOColumns = [
    { accessorKey: 'name', header: 'Name' },
];
export const MARKITColumns = [
    { accessorKey: 'name', header: 'Name' },
];
