import * as React from 'react';
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle, Validation, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import { getOptionsMulti } from 'utils/change';
import { errorMessage, initialValidation } from 'utils/validation/initialValidation';
import CustomValidators from 'components/CustomValidators';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Source } from '../../sources/sources';
import exportToExcel, { getColumnsSpreadSheet } from '../../service/exportToExcel';
import ShareConfirmation from '../../pages/ShareConfirmation';
import useOption from 'components/customHooks';
const ResultsShareModal = ({ filter, result, securities, setShow, source, }) => {
    const [data, setData] = React.useState({ streamIds: [] });
    const [errorState, setErrorState] = React.useState();
    const [loading, setLoading] = React.useState({ excel: false, symphony: false });
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const numberOfPages = React.useMemo(() => Math.round((securities.length - 1) / 40 + 1), [securities.length]);
    const validators = React.useMemo(() => ({
        streamIds: CustomValidators.RequiredArray,
    }), []);
    const options = useOption('/nit/streams/options');
    React.useEffect(() => {
        (async () => {
            const initialErrorState = await initialValidation(validators, data);
            setErrorState(initialErrorState);
        })();
    }, [validators, data]);
    const disabledButton = React.useMemo(() => errorState !== undefined && Object.values(errorState).some(v => !v), [errorState]);
    const shareOnSymphony = React.useCallback(async () => {
        if (data.streamIds.length > 0 && result !== undefined) {
            const formData = new FormData();
            formData.append('streamIds', data.streamIds.join(','));
            formData.append('id', result.id);
            const jsonOption = { type: 'application/json' };
            formData.append('filter', new Blob([JSON.stringify(filter)], jsonOption));
            const request = new ServerRequest(`/nit/${result.source.toLowerCase()}/share`, { body: formData, method: 'POST' });
            const response = await fetch(request);
            if (response.status === 204) {
                setShow(false);
            }
            else {
                //
            }
        }
    }, [filter, result, setShow, data.streamIds]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        setData({ [name]: newValue });
    }, []);
    const onClickClose = React.useCallback(() => {
        setShow(false);
    }, [setShow]);
    const onClickConfirm = React.useCallback((event) => {
        setShowConfirmation(false);
        if (event) {
            (async () => {
                await shareOnSymphony();
            })();
        }
    }, [shareOnSymphony]);
    const table = useReactTable({
        columns: getColumnsSpreadSheet(source ?? Source.EUROCLEAR),
        data: securities,
        getCoreRowModel: getCoreRowModel(),
    });
    const onClickExcel = React.useCallback(() => {
        setLoading({ ...loading, excel: true });
        (async () => {
            await exportToExcel(table);
            setLoading({ ...loading, excel: false });
        })();
    }, [loading, table]);
    const onClickSymphony = React.useCallback(() => {
        if (numberOfPages > 1) {
            setShowConfirmation(true);
        }
        else {
            setLoading({ ...loading, symphony: true });
            (async () => {
                await shareOnSymphony();
                setLoading({ ...loading, symphony: false });
            })();
        }
    }, [loading, numberOfPages, shareOnSymphony]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement("div", null,
        showConfirmation ?
            React.createElement(ShareConfirmation, { confirm: onClickConfirm, numberOfPages: numberOfPages }) : undefined,
        React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClickClose, parentNode: document.body, show: true, size: "medium" },
            React.createElement(ModalTitle, null, "Share on Symphony or Excel"),
            React.createElement(ModalBody, null,
                React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('streamIds'), validator: validators.streamIds },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Select Rooms and IMs to share on Symphony", onChange: onChangeDropdown('streamIds'), options: options, value: getOptionsMulti(data.streamIds, options) }))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { disabled: disabledButton, loading: loading.symphony, onClick: onClickSymphony }, "SHARE ON SYMPHONY"),
                React.createElement(Button, { disabled: source === undefined, loading: loading.excel, onClick: onClickExcel }, "OPEN AS EXCEL")))));
};
export default ResultsShareModal;
