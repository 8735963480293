class SyndNotificationSubscription {
    admin;
    filter;
    id;
    subscriptionId;
    constructor(response) {
        this.admin = response.admin;
        this.filter = response.filter;
        this.id = response.id;
        this.subscriptionId = response.subscriptionId;
    }
    clone() {
        return new SyndNotificationSubscription({
            admin: this.admin,
            filter: this.filter,
            id: this.id,
            subscriptionId: this.subscriptionId
        });
    }
    toggleAdmin() {
        const clone = this.clone();
        clone.admin = !clone.admin;
        return clone;
    }
    toggleDev() {
        const clone = this.clone();
        return clone;
    }
    updateFilter(filter) {
        const clone = this.clone();
        clone.filter = filter;
        return clone;
    }
    static createFromFilter(filter) {
        return new SyndNotificationSubscription({ filter });
    }
}
export default SyndNotificationSubscription;
