import * as React from 'react';
import ConstantValues from './ConstantValues';
import ConstantNameTable from './ConstantNameTable';
import { FiniteTableContextProvider } from 'components/custom-table/TableContext';
const ConstantViewer = ({ field }) => {
    const [constant, setConstant] = React.useState();
    return (React.createElement(FiniteTableContextProvider, null,
        React.createElement("div", { style: { display: 'flex', height: '100%', marginTop: '1rem', overflow: 'auto' } },
            React.createElement("div", { style: { width: '50%' } },
                React.createElement(ConstantValues, { constant: constant, field: field, setConstant: setConstant })),
            React.createElement("div", { style: { marginTop: '0.5rem', width: '50%' } }, constant !== undefined ? React.createElement(ConstantNameTable, { constant: constant, setConstant: setConstant }) : null))));
};
export default ConstantViewer;
