/**
 * When debugging the mobile site, this needs to be dev.server on a network where dev.server is
 * defined in the DNS on the router. In that way the mobile can connect to the site etc. without
 * any cross site problems.
 */
import { API_URL_WS } from 'utils/system/envs-constants';
class WebsocketURL extends URL {
    constructor(path) {
        super(`${API_URL_WS}${path}`, API_URL_WS);
    }
}
export default WebsocketURL;
