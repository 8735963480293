import { Dropdown } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import './DataViewerForm.scss';
const TypePicker = ({ clearRegexes, setType, type, }) => {
    const typeOptions = React.useMemo(() => [
        { label: 'Key Value', value: 'keyvalues' },
        { label: 'List Item', value: 'listitems' },
        { label: 'Paragraph', value: 'paragraphs' },
        { label: 'Subject', value: 'subject' },
    ], []);
    const onChangeType = React.useCallback((event) => {
        clearRegexes();
        setType(event.target.value.value);
        localStorage.setItem('type', event.target.value.value);
    }, [clearRegexes, setType]);
    return (React.createElement("div", { className: "co-type-picker" },
        React.createElement(Dropdown, { label: "Select a Type", name: "type", onChange: onChangeType, options: typeOptions, value: typeOptions.find((o) => o.value === type), width: "100px" })));
};
export default TypePicker;
