import * as React from 'react';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import { sendError } from 'utils/messageUtils';
import Benchmark from '../../models/Benchmark';
import BenchmarkTable from './BenchmarkTable';
import BenchmarkForm from './BenchmarkForm';
const Benchmarks = () => {
    const { view, updateView } = React.useContext(NavigationContext);
    const download = React.useContext(DownloadContext);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const service = React.useMemo(() => new CrudService({ download, name: 'Benchmark', path: '/core/benchmarks' }), [download]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            try {
                const body = await service.getPlus(abortController.signal);
                const downloadedBenchmarks = body.map((u) => Object.assign(new Benchmark(), u));
                setBenchmarks(downloadedBenchmarks);
            }
            catch (error) {
                sendError('Unable to load benchmarks', error);
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [service]);
    const create = React.useCallback(() => {
        setDetails(new Benchmark());
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: benchmark } }) => {
        try {
            (async () => {
                await service.delete(benchmark);
                const filteredBenchmarks = benchmarks.filter((u) => u.id !== benchmark.id);
                setBenchmarks(filteredBenchmarks);
            })();
        }
        catch (error) {
            //
        }
    }, [benchmarks, service]);
    const edit = React.useCallback(({ row: { original: benchmark } }) => {
        setDetails(benchmark);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedBenchmark = Object.assign(new Benchmark(), body);
            const updatedCurrencies = CrudService.updateData(benchmarks, updatedBenchmark);
            setBenchmarks(updatedCurrencies);
            updateView('TABLE');
        }
        catch (error) {
            //
        }
    }, [benchmarks, service, updateView]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [view, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(BenchmarkForm, { benchmark: details, save: save }));
        case 'TABLE':
            return (React.createElement(BenchmarkTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, benchmarks: benchmarks }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Benchmarks;
