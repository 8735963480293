/* eslint-disable react/no-array-index-key */
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const SearchModal = ({ onClose, show, setMessageId, }) => {
    const [emailId, setEmailId] = React.useState('');
    const [emailIndex, setEmailIndex] = React.useState('0');
    const onChangeId = React.useCallback((event) => {
        setEmailId(event.target.value);
    }, []);
    const onChangeIndex = React.useCallback((event) => {
        const { value } = event.target;
        if (value === '' || Number.isInteger(value)) {
            setEmailIndex(value);
        }
    }, []);
    const onClickSearch = React.useCallback(() => {
        const index = Number.parseInt(emailIndex, 10);
        if (!Number.isNaN(index)) {
            setMessageId({ id: emailId, index });
            onClose();
        }
    }, [emailId, emailIndex, onClose, setMessageId]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: show, size: "small" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Go To Update")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(TextField, { label: "Transaction Id", onChange: onChangeId, value: emailId }),
                React.createElement(TextField, { label: "Index", onChange: onChangeIndex, value: emailIndex }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSearch }, "SEARCH"))));
};
export default SearchModal;
