import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { optionSort } from 'utils/table';
import { sendFetchError } from 'utils/messageUtils';
import ConstantList from './ConstantList';
const ConstantViewer = ({ field }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [constantOptions, setConstantOptions] = React.useState([]);
    const [constantId, setConstantId] = React.useState();
    const [hasError, setError] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (typeof field !== 'undefined') {
            (async () => {
                const url = new ApiServerURL('/syndicate/constant/options');
                url.searchParams.append('field', field);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    body.sort(optionSort);
                    setConstantOptions(body);
                    const updatedConstantId = body.length > 0 && 'value' in body[0] ? body[0].value : undefined;
                    setConstantId(updatedConstantId);
                }
                catch (error) {
                    if (!(error instanceof Error && error.name === 'AbortError')) {
                        sendFetchError('Unable to download constant options', error, request);
                        setError(true);
                    }
                }
            })();
        }
    }, [apiFetchJson, field]);
    const changeConstant = React.useCallback((event) => {
        if (!constantOptions.includes(event.target.value)) {
            const const2 = { field, names: [event.target.value.value] };
            const request = new ServerRequest('/syndicate/constant', {
                body: JSON.stringify(const2),
                headers: { 'content-type': 'application/json' },
                method: 'POST',
            });
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    const newOptions = [...constantOptions];
                    setConstantId(body.id);
                    newOptions.push({ label: body.names[0], value: body.id });
                    setConstantOptions(newOptions);
                }
                catch (error) {
                    sendFetchError('Error saving constant', error, request);
                    //
                }
            })();
        }
        else {
            setConstantId(event.target.value.value);
        }
    }, [apiFetchJson, constantOptions, field]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { addNewOptions: true, isDisabled: hasError, label: "Constants", onChange: changeConstant, options: constantOptions, value: constantOptions.find((o) => ('value' in o && o.value === constantId)) }),
        typeof constantId !== 'undefined' ? React.createElement(ConstantList, { constantId: constantId }) : null));
};
export default ConstantViewer;
