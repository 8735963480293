import * as React from 'react';
import { Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptions } from 'utils/change';
import { sendFetchError } from 'utils/messageUtils';
const BaseCurrency = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const [currency, setCurrency] = React.useState();
    const onChangeCurrency = React.useCallback((event) => {
        const { value } = event.target.value;
        setCurrency(value);
        localStorage.setItem('base-currency', value);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        /**
        * Only when the user has access to localStorage can he choose the base currency
        */
        const storedValue = localStorage.getItem('base-currency') ?? 'EUR';
        setCurrency(storedValue);
        const request = new ServerRequest('/nit/currencies');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const newOptions = body.sort().map((c) => ({ label: c, value: c }));
                setOptions(newOptions);
            }
            catch (error) {
                sendFetchError('Unable to download currency options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    return (React.createElement(Card, { className: "co-card-stack" },
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, { type: "h1" }, "Base Currency")),
        React.createElement("div", { className: "co-info co-item" },
            React.createElement(Dropdown, { label: "Base currency from which equivalent amounts are calculated.", name: "period", onChange: onChangeCurrency, options: options, value: getOptions(currency, options) }))));
};
export default BaseCurrency;
