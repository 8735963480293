import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import Company from '../../models/Company';
import CompanyTable from './CompanyTable';
import { FiniteTableContextProvider } from 'components/custom-table/TableContext';
const Companies = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [companies, setCompanies] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/admin/companies');
        setLoading(true);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const downloadedCompanies = body.map((u) => new Company(u));
                setCompanies(downloadedCompanies);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError("Unable to download companies", error, request);
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const deleteFn = React.useCallback(async (company) => {
        const request = new ServerRequest('/admin/companies', { body: JSON.stringify(company), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        try {
            await apiFetchNoContent(request);
            const filteredCompanies = [...companies].filter((u) => u.id !== company.id);
            setCompanies(filteredCompanies);
        }
        catch (error) {
            sendFetchError("Unable to delete company", error, request);
        }
    }, [apiFetchNoContent, companies]);
    switch (view) {
        case 'TABLE':
            return (React.createElement(FiniteTableContextProvider, null,
                React.createElement(CompanyTable, { companies: companies, deleteFn: deleteFn, hasError: hasError, isLoading: isLoading, setCompanies: setCompanies })));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Companies;
