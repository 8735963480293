import CoreData from './CoreData';
class ItemData extends CoreData {
    item;
    constructor(response) {
        super(response);
        this.item = response.item;
    }
    getValue() {
        return this.item.value;
    }
}
export default ItemData;
