import { Dropdown, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TransactionMetaForm = ({ isLoading, regionOptions, sectorOptions, typeOptions, initialTransactionMeta, setTransactionMeta, }) => {
    const [companyName, setCompanyName] = React.useState("");
    const [regions, setRegions] = React.useState();
    const [sector, setSector] = React.useState();
    const [types, setTypes] = React.useState();
    React.useEffect(() => {
        setSector(initialTransactionMeta?.sector);
    }, [initialTransactionMeta?.sector]);
    React.useEffect(() => {
        setRegions(initialTransactionMeta?.regions);
    }, [initialTransactionMeta?.regions]);
    React.useEffect(() => {
        setTypes(initialTransactionMeta?.types ?? ['NEW_ISSUE']);
    }, [initialTransactionMeta?.types]);
    React.useEffect(() => {
        setCompanyName(initialTransactionMeta?.companyName ?? '');
    }, [initialTransactionMeta?.companyName]);
    const onChangeCompanyName = React.useCallback((event) => {
        setCompanyName(event.target.value);
    }, []);
    const onChangeRegion = React.useCallback((event) => {
        const eventValue = event.target.value;
        let newValue = [];
        if (Array.isArray(eventValue)) {
            newValue = eventValue.map(e => e.value);
        }
        else if (typeof eventValue === 'string') {
            newValue = [eventValue];
        }
        setRegions(newValue);
    }, []);
    const onChangeSector = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        setSector(newValue);
    }, []);
    const onChangeTypes = React.useCallback((event) => {
        const eventValue = event.target.value;
        let newValue = [];
        if (Array.isArray(eventValue)) {
            newValue = eventValue.map((e) => e.value);
        }
        else if (typeof eventValue === 'string') {
            newValue = [eventValue];
        }
        setTypes(newValue);
    }, []);
    const isMobile = React.useMemo(() => {
        if ('userAgentData' in navigator) {
            const navigatorUAData = navigator.userAgentData;
            if (navigatorUAData !== undefined) {
                return navigatorUAData.mobile;
            }
        }
        return 'virtualKeyboard' in navigator;
    }, []);
    React.useEffect(() => {
        setTransactionMeta({
            sector, regions, types, companyName,
        });
    }, [companyName, regions, sector, types, setTransactionMeta]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cmo-company-name" },
            React.createElement(TextField, { disabled: isLoading, label: "Company Name", onChange: onChangeCompanyName, value: companyName })),
        React.createElement("div", { className: "cmo-sector" },
            React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute' } },
                React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: true, isTypeAheadEnabled: !isMobile, label: "Sector", closeMenuOnSelect: true, onChange: onChangeSector, options: sectorOptions, value: sectorOptions.find((o) => 'value' in o && sector === o.value) }))),
        React.createElement("div", { className: "cmo-region" },
            React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute' } },
                React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: false, isMultiSelect: true, isTypeAheadEnabled: !isMobile, closeMenuOnSelect: true, label: "Regions", onChange: onChangeRegion, options: regionOptions, value: regionOptions.filter((o) => 'value' in o && regions?.includes(o.value)) }))),
        React.createElement("div", { className: "cmo-types" },
            React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute' } },
                React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: true, isMultiSelect: true, isTypeAheadEnabled: !isMobile, label: "Type(s)", closeMenuOnSelect: true, onChange: onChangeTypes, options: typeOptions, value: typeOptions.filter((o) => 'value' in o && types?.includes(o.value)) })))));
};
export default TransactionMetaForm;
