import { Button, Checkbox, Icon, TextEllipsis, } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import { printAddress } from 'utils/table';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import NavigationContext from 'contexts/NavigationContext';
import SyndicateContext from '../../../../../context/SyndicateContext';
import './SubRow.css';
const SubRow = ({ checkUpdate, className, raw, setSelectedUpdate, setShowModal, update, }) => {
    const { apiFetchNoContent, apiFetchText } = React.useContext(DownloadContext);
    const { updateIndex } = React.useContext(SyndicateContext);
    const { updateTab } = React.useContext(NavigationContext);
    const click2 = React.useCallback(() => {
        const url = new ApiServerURL('/syndicate/emails/html');
        url.searchParams.append('messageId', update.emailId.id);
        url.searchParams.append('index', update.emailId.index.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const text = await apiFetchText(request);
                const checkedWithHtml = { ...update, html: text };
                setSelectedUpdate(checkedWithHtml);
                setShowModal(true);
            }
            catch (error) {
                sendFetchError('Unable to download HTML', error, request);
            }
        })();
    }, [apiFetchText, setSelectedUpdate, setShowModal, update]);
    const click3 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        const url = new ApiServerURL('/syndicate/update/force-event');
        (async () => {
            const request = new ServerRequest(url, { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await apiFetchNoContent(request);
            }
            catch (error) {
                sendFetchError('Unable to Force Update Event', error, request);
            }
        })();
    }, [apiFetchNoContent, update]);
    const click4 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        (async () => {
            const url = new ApiServerURL('/syndicate/update/remove-event');
            // url.searchParams.append('messageId', update.emailId.id);
            // url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url, { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await apiFetchNoContent(request);
            }
            catch (error) {
                sendFetchError('Unable to Remove Event', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, update]);
    const onChange = React.useCallback((emailId) => () => {
        checkUpdate(emailId);
    }, [checkUpdate]);
    const onClick = React.useCallback(() => {
        updateIndex(update.index);
        updateTab('overview', 'UPDATE');
    }, [updateIndex, updateTab, update.index]);
    return (React.createElement("tr", { className: className },
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: onClick, variant: "primary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click2, variant: "secondary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click3, variant: "tertiary" },
                React.createElement(Icon, { iconName: "symphony" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click4, variant: "tertiary" },
                React.createElement(Icon, { iconName: "delete" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Checkbox, { "aria-label": "plus", key: `key${update.emailId.id}${update.emailId.index}`, name: "updateCheck", status: update.checked ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: "", onChange: onChange(update.emailId), label: "" })),
        React.createElement("td", { className: "co-sub-subject" },
            React.createElement(TextEllipsis, null, raw ? update.subjectRaw : update.subject)),
        React.createElement("td", { className: "co-sub-title" },
            React.createElement(TextEllipsis, null, raw ? update.titleRaw : update.title)),
        React.createElement("td", { className: "co-sub-from" },
            React.createElement(TextEllipsis, null, printAddress(update.from))),
        React.createElement("td", { className: "co-sub-timestamp" },
            React.createElement(TextEllipsis, null, update.timestamp.toLocaleString()))));
};
export default SubRow;
