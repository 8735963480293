import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TableSettingsSaveModal = ({ close, saveSettings, setMeta, setName, }) => {
    const [value, setValue] = React.useState('');
    const onChangeName = React.useCallback(({ target }) => {
        setValue(target.value);
    }, []);
    const onClickSave = React.useCallback(() => {
        if (value !== undefined) {
            setName(value);
            setMeta(undefined);
            saveSettings(value, undefined);
            close();
        }
    }, [value, close, saveSettings, setMeta, setName]);
    return (React.createElement(Modal, { closeButton: true, onClose: close, show: true, size: "small" },
        React.createElement(ModalHeader, null, "Save Current Settings"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Name", onChange: onChangeName, value: value })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "SAVE"))));
};
export default TableSettingsSaveModal;
