import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, } from '@symphony-ui/uitoolkit-components';
const EmailGridManualModal = ({ content, onClose, send }) => {
    const onClick = React.useCallback(() => send(), [send]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "full-width" },
        React.createElement(ModalBody, null,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClick, variant: "primary-destructive" }, "SEND"))));
};
export default EmailGridManualModal;
