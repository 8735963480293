var _a;
class Company {
    alternativeNames;
    cik;
    countryId;
    id;
    industry;
    lei;
    name;
    sector;
    sectors;
    shortName;
    tickers;
    types;
    ultimateParentId;
    constructor(response) {
        this.alternativeNames = response?.alternativeNames;
        this.cik = response?.cik;
        this.countryId = response?.countryId;
        this.id = response?.id;
        this.industry = response?.industry;
        this.lei = response?.lei;
        this.name = response?.name ?? '';
        this.sector = response?.sector;
        this.sectors = response?.sectors ?? [];
        this.shortName = response?.shortName;
        this.tickers = response?.tickers;
        this.types = response?.types ?? [];
        this.ultimateParentId = response?.ultimateParentId;
    }
    #clone() {
        return new _a({
            alternativeNames: this.alternativeNames,
            cik: this.cik,
            countryId: this.countryId,
            id: this.id,
            industry: this.industry,
            lei: this.lei,
            name: this.name,
            ultimateParentId: this.ultimateParentId,
            sector: this.sector,
            sectors: this.sectors,
            shortName: this.shortName,
            tickers: this.tickers,
            types: this.types
        });
    }
    static cloneOrNew(company) {
        return company !== undefined ? company.#clone() : new _a();
    }
    addAlternativeName(name) {
        if (this.alternativeNames === undefined) {
            this.alternativeNames = [];
        }
        this.alternativeNames.push(name);
    }
    removeAlternativeName() {
        if (this.alternativeNames === undefined) {
            return this;
        }
        const clone = this.#clone();
        clone.alternativeNames = undefined;
        return clone;
    }
    setCountryId(countryId) {
        if (countryId === undefined) {
            return this;
        }
        const clone = this.#clone();
        clone.countryId = countryId;
        return clone;
    }
    setLei(lei) {
        const clone = this.#clone();
        clone.lei = lei;
        return clone;
    }
    setCik(cik) {
        const clone = this.#clone();
        clone.cik = cik;
        return clone;
    }
    setIndustry(industry) {
        const clone = this.#clone();
        clone.industry = industry;
        return clone;
    }
    setName(newName) {
        const clone = this.#clone();
        clone.name = newName;
        return clone;
    }
    setSectors(sectors) {
        const clone = this.#clone();
        clone.sectors = sectors;
        return clone;
    }
    setTickers(tickers) {
        const clone = this.#clone();
        clone.tickers = tickers;
        ;
        return clone;
    }
    setShortName(shortName) {
        const clone = this.#clone();
        clone.shortName = shortName;
        return clone;
    }
    setSector(subSector) {
        const clone = this.#clone();
        clone.sector = subSector;
        return clone;
    }
    setTypes(types) {
        const clone = this.#clone();
        clone.types = types;
        return clone;
    }
    setUltimateParentId(ultimateParentId) {
        const clone = this.#clone();
        clone.ultimateParentId = ultimateParentId;
        return clone;
    }
    setNameLei(nameLEI) {
        const clone = this.#clone();
        clone.lei = nameLEI.lei;
        clone.countryId = nameLEI.countryId;
        const englishName = nameLEI.alternativeNames?.find(n => n.language === 'en' && n.type === 'ALTERNATIVE_LANGUAGE_LEGAL_NAME');
        clone.name = englishName?.name ?? nameLEI.name.name;
        return clone;
    }
}
_a = Company;
export default Company;
