import * as React from 'react';
import { Button, Card, Icon, Typography, } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
const SettingsHeader = ({ children, onClose }) => {
    const { updateAppView } = React.useContext(NavigationContext);
    const clickLogout = React.useCallback(() => {
        const event = new CustomEvent('logout');
        window.dispatchEvent(event);
    }, []);
    const clickMenu = React.useCallback(() => {
        updateAppView(undefined);
    }, [updateAppView]);
    return (React.createElement(Card, { className: "co-card-stack", style: { padding: '1rem' } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Typography, { type: "h1" }, "Settings"),
            React.createElement(Button, { iconButton: true, onClick: onClose, variant: "tertiary" },
                React.createElement(Icon, { iconName: "cross" }))),
        children,
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Button, { onClick: clickLogout }, "Log out"),
            React.createElement(Button, { onClick: clickMenu }, "Apps Menu"),
            React.createElement(Button, { onClick: onClose }, "Close"))));
};
export default SettingsHeader;
