import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
import * as React from 'react';
const WebSocketMonitor = () => {
    const { updateView } = React.useContext(NavigationContext);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Web Sockets"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "authentication", onClick: () => updateView('authentication'), size: "small" }, "AUTHENTICATION"),
                React.createElement(Button, { name: "web-push", onClick: () => updateView('web-Push'), size: "small", style: { marginLeft: '0.25rem' } }, "WEB PUSH")))));
};
export default WebSocketMonitor;
