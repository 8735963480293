import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import useOption from 'components/customHooks';
import ApiServerURL from 'models/ServerUrl';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import Company from '../../../../../admin/models/Company';
import CompanySelector from '../../overview/table/IssuerSelector';
const TransactionMetaForm = ({ isLoading, transactionMeta, selectedCompany, setSelectedCompany, setTransactionMeta, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const regionOptions = useOption('/syndicate/overview/regions/options');
    const sectorOptions = useOption('/syndicate/overview/sectors/options');
    const typeOptions = useOption('/syndicate/overview/types/options');
    const companyTypeOptions = useOption('/syndicate/overview/company-type/options');
    const onChangeCompanyId = React.useCallback((companyId) => {
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/from-company');
            url.searchParams.append('id', companyId);
            const request = new ServerRequest(url);
            ;
            const body = await apiFetchJson(request);
            console.log("Body: %o", body);
            const updatedCompany = body.company;
            if (updatedCompany !== undefined) {
                if (updatedCompany.company !== undefined) {
                    updatedCompany.company = new Company(updatedCompany.company);
                }
                if (updatedCompany.ultimateParent !== undefined) {
                    updatedCompany.ultimateParent = new Company(updatedCompany.ultimateParent);
                }
                if (transactionMeta.company?.type !== undefined) {
                    updatedCompany.type = transactionMeta.company.type;
                }
            }
            const updated = {
                company: updatedCompany,
                regions: body.regions !== undefined ? [...new Set(body.regions.concat(transactionMeta.regions ?? []))] : transactionMeta.regions,
                sector: transactionMeta.sector ?? body.sector,
                types: transactionMeta.types
            };
            setTransactionMeta(updated);
            if (setSelectedCompany !== undefined) {
                setSelectedCompany(undefined);
            }
        })();
    }, [apiFetchJson, transactionMeta, setSelectedCompany, setTransactionMeta]);
    const onChangeCompanyType = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        const { company, regions, sector, types } = transactionMeta;
        const clone = company !== undefined ? { ...company, type: newValue } : { type: newValue };
        const updated = { company: clone, regions, sector, types };
        setTransactionMeta(updated);
    }, [transactionMeta, setTransactionMeta]);
    const onChangeRegion = React.useCallback((event) => {
        const eventValue = event.target.value;
        let newValue = [];
        if (Array.isArray(eventValue)) {
            newValue = eventValue.map(e => e.value);
        }
        else if (typeof eventValue === 'string') {
            newValue = [eventValue];
        }
        const { company, sector, types } = transactionMeta;
        setTransactionMeta({ sector, regions: newValue, types, company });
    }, [transactionMeta, setTransactionMeta]);
    const onChangeSector = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        const { company, regions, types } = transactionMeta;
        setTransactionMeta({ sector: newValue, regions, types, company });
    }, [transactionMeta, setTransactionMeta]);
    const onChangeTypes = React.useCallback((event) => {
        const eventValue = event.target.value;
        let newValue = [];
        if (Array.isArray(eventValue)) {
            newValue = eventValue.map((e) => e.value);
        }
        else if (typeof eventValue === 'string') {
            newValue = [eventValue];
        }
        const { company, regions, sector } = transactionMeta;
        setTransactionMeta({ sector, regions, types: newValue, company });
    }, [transactionMeta, setTransactionMeta]);
    const isMobile = React.useMemo(() => {
        if ('userAgentData' in navigator) {
            const navigatorUAData = navigator.userAgentData;
            if (navigatorUAData !== undefined) {
                return navigatorUAData.mobile;
            }
        }
        return 'virtualKeyboard' in navigator;
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cmo-company" },
            React.createElement(CompanySelector, { company: transactionMeta.company?.company, disabled: false, label: "Company", onChange: onChangeCompanyId, selectedCompany: selectedCompany, setSelectedCompany: setSelectedCompany })),
        React.createElement("div", { className: "cmo-company-type" },
            React.createElement(Dropdown, { label: "Company Type", onChange: onChangeCompanyType, options: companyTypeOptions, value: companyTypeOptions.find(o => 'value' in o && transactionMeta.company?.type === o.value) })),
        React.createElement("div", { className: "cmo-sector" },
            React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: true, isTypeAheadEnabled: !isMobile, label: "Sector", closeMenuOnSelect: true, onChange: onChangeSector, options: sectorOptions, value: sectorOptions.find((o) => 'value' in o && transactionMeta.sector === o.value) })),
        React.createElement("div", { className: "cmo-region" },
            React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: false, isMultiSelect: true, isTypeAheadEnabled: !isMobile, closeMenuOnSelect: true, label: "Regions", onChange: onChangeRegion, options: regionOptions, value: regionOptions.filter((o) => 'value' in o && transactionMeta.regions?.includes(o.value)) })),
        React.createElement("div", { className: "cmo-types" },
            React.createElement(Dropdown, { isDisabled: isLoading, displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: true, isMultiSelect: true, isTypeAheadEnabled: !isMobile, label: "Transaction Type(s)", closeMenuOnSelect: true, onChange: onChangeTypes, options: typeOptions, value: typeOptions.filter((o) => 'value' in o && transactionMeta.types?.includes(o.value)) }))));
};
export default TransactionMetaForm;
