import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const EditableTextCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { printDate, printNumber } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    const [originalValue, setOriginalValue] = React.useState();
    const print = React.useCallback((calculated) => {
        if (calculated === undefined) {
            return '';
        }
        if (typeof calculated === 'string') {
            return calculated;
        }
        if (typeof calculated === 'number') {
            const options = column.columnDef.meta?.numberFormat;
            return printNumber(calculated, options);
        }
        if (typeof calculated === 'object' && calculated instanceof Date) {
            try {
                return printDate(calculated, 'YMD');
            }
            catch (error) {
                setErrorText(JSON.stringify(error));
            }
        }
        return '';
    }, [column.columnDef.meta?.numberFormat, printDate, printNumber]);
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            setValue(print(calculated));
        }
        else {
            setValue('');
        }
    }, [column, print, row.index, row.original]);
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && newValue !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', newValue);
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const response = body.map((v) => new TrancheWrapper(v));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                sendFetchError('Unable to update override information', error, request);
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setEditing(false);
                setLoading(false);
                setDisabled(false);
            }
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const onBlur = React.useCallback(() => {
        (async () => save(value))();
    }, [save, value]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => {
                if (editableCellRef.current !== null) {
                    editableCellRef.current.focus();
                }
                await save(value);
            })();
        }
    }, [save, value]);
    const overrideLess = React.useMemo(() => row.original.removeOverrideMap(), [row.original]);
    const removeOverride = React.useCallback(() => {
        (async () => {
            await save('');
        })();
    }, [save]);
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(overrideLess, row.index);
            setOriginalValue(print(calculated));
        }
    }, [column, overrideLess, print, row.index]);
    const content = React.useMemo(() => {
        if (originalValue === undefined) {
            return undefined;
        }
        if (isEditing) {
            return React.createElement("input", { autoFocus: true, className: "co-editable-input", disabled: isDisabled, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value });
        }
        return value;
    }, [isDisabled, isEditing, onBlur, onChange, onKeyUp, originalValue, value]);
    return (React.createElement(EditableCell, { errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, removeOverride: removeOverride, setEditing: setEditing, title: originalValue },
        React.createElement("div", { className: "co-text-edit-container" }, content)));
};
export default EditableTextCell;
