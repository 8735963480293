import * as React from 'react';
import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import NavigationContext from 'contexts/NavigationContext';
import sort from '../transactions/overview/cells/sort';
const EmailTrackerTable = () => {
    const { updateView } = React.useContext(NavigationContext);
    const duration = React.useCallback((startDateIso, endDateIso) => {
        const start = new Date(startDateIso).getTime() / 1000;
        const end = new Date(endDateIso).getTime() / 1000;
        const seconds = (end - start) % 60;
        const minutes = (((end - start) - seconds) / 60) % 60;
        const hours = ((end - start) - seconds - minutes * 60) / 3600;
        return `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${Math.round(seconds).toString().padStart(2, '0')}`;
    }, []);
    const columns = React.useMemo(() => [
        {
            accessorKey: 'name',
            enableColumnFilter: true,
            header: 'Name',
            minSize: 200,
        },
        {
            accessorKey: 'company',
            enableColumnFilter: true,
            header: 'Company',
            minSize: 200,
        },
        {
            accessorFn: ((originalRow) => (new Date(originalRow.time))
                .toLocaleString().substring(0, 24)),
            enableColumnFilter: false,
            header: 'Opened',
            minSize: 210,
            sortingFn: sort.date('time'),
        },
        {
            accessorFn: ((originalRow) => (new Date(originalRow.sent))
                .toLocaleString().substring(0, 24)),
            enableColumnFilter: false,
            header: 'Sent',
            minSize: 210,
            sortingFn: sort.date('sent'),
        },
        {
            accessorFn: (originalRow) => duration(originalRow.sent, originalRow.time),
            enableColumnFilter: false,
            header: 'Time to Open',
            minSize: 100,
        },
        {
            accessorKey: 'host',
            enableColumnFilter: true,
            header: 'IP Address',
            minSize: 200,
        },
        {
            accessorKey: 'agent',
            enableColumnFilter: true,
            header: 'Agent',
            minSize: 400,
        },
    ], [duration]);
    return (React.createElement(Card, { style: { padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Email Tracker Data"),
            React.createElement("div", null,
                React.createElement(Button, { name: "overview", onClick: () => updateView('CREATOR'), size: "small" }, "EMAIL CREATOR"))),
        React.createElement(InfiniteTable, { columns: columns, emptyMessage: "", errorMessage: "", fetchSize: 100, name: "email-tracker", urlPage: "/syndicate/email-tracking/page", urlTotal: "/syndicate/email-tracking/total" })));
};
export default EmailTrackerTable;
