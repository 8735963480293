import * as React from 'react';
import { Button, Card, Dropdown, Switch, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import CustomValidators from 'components/CustomValidators';
import MetadataButtons from 'components/metadata/MetadataButtons';
import { getOptions } from 'utils/change';
import { errorMessage, initialValidation, } from 'utils/validation/initialValidation';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import UserContext from 'contexts/UserContext';
import Report from '../../models/Report';
import FrequencyInput from '../frequency-input/FrequencyInput';
const ReportForm = ({ options, report, save }) => {
    const { checkEditable } = React.useContext(UserContext);
    const [data, setData] = React.useState(report ?? new Report());
    const [disabled, setDisabled] = React.useState(false);
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [errorState, setErrorState] = React.useState({});
    const [title, setTitle] = React.useState('');
    const validators = React.useMemo(() => ({
        frequency: CustomValidators.Frequency,
        name: [Validators.Required, Validators.MaxLength(24)],
        search: CustomValidators.RequiredOption,
    }), []);
    React.useEffect(() => {
        if (data?.id !== undefined && report !== undefined) {
            const isEditable = checkEditable(report);
            setDisabled(!isEditable);
            setTitle(isEditable ? 'Edit Report' : 'Report');
        }
        else {
            setTitle('Reports');
        }
    }, [checkEditable, data, report]);
    React.useEffect(() => {
        (async () => {
            const initialErrorState = await initialValidation(validators, report);
            setErrorState(initialErrorState);
        })();
    }, [report, validators]);
    React.useEffect(() => {
        const hasError2 = Object.values(errorState).some((v) => v === false);
        setButtonDisabled(hasError2);
    }, [errorState]);
    const onChangeFrequency = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newReport = data.update(name, value);
        setData(newReport);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData(data.update(name, value));
    }, [data]);
    const onChangePermissions = React.useCallback((event) => {
        setData(data.update('permissions', event));
    }, [data]);
    const onChangeSelect = React.useCallback((event) => {
        const newValue = event.target.value;
        const searchId = newValue.value;
        const source = newValue.data;
        const newData = Object.assign(new Report(), data, { searchId, source });
        setData(newData);
    }, [data]);
    const onValidationChanged = (name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    };
    // Give a name to the Report so that you can easily find it later.
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { newView: "TABLE", title: title }),
        React.createElement("div", { className: "base-form" },
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('search'), validator: validators.search },
                React.createElement(Dropdown, { isDisabled: disabled, label: "Search to run", onChange: onChangeSelect, name: "search", options: options, value: data?.source ? getOptions(`${data.source}|${data.searchId}`, options) : '' })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('name'), validator: validators.name },
                React.createElement(TextField, { disabled: disabled, id: "name", label: "Name", value: data?.name ?? '', onChange: onChangeInput('name'), tooltip: "" })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('frequency'), validator: validators.frequency },
                React.createElement(FrequencyInput, { defaultPeriod: "MINUTES", disabled: disabled, label: "Frequency", onChange: onChangeFrequency('frequency'), tooltip: "Set how often you want the report to run, and post into the stream. You can toggle whether you want it to run every day at a fixed time, or at an interval of a fixed number of minutes or hours.", value: data?.frequency || null })),
            React.createElement(Switch, { disabled: disabled, label: "Show Empty Search", status: data?.showEmpty ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data?.showEmpty ? 'true' : 'false', name: "showEmpty", onChange: onChangeInput })),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement(Button, { disabled: disabledButton, onClick: save(data) }, "Save"),
            React.createElement(MetadataButtons, { data: data, onChange: onChangePermissions, disabled: disabled }))));
};
export default ReportForm;
