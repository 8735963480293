import * as React from 'react';
import SectorModal from './SectorModal';
import TypeModal from './TypeModal';
import DetailsModal from './DetailsModal';
const ServiceWorkerMessageHandler = () => {
    const [messageData, setMessageData] = React.useState();
    // From your client pages:
    React.useEffect(() => {
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', (event) => {
            const data = event.data;
            setMessageData(data);
        });
    }, []);
    const onClose = React.useCallback(() => setMessageData(undefined), []);
    if (messageData === undefined) {
        return React.createElement("div", null);
    }
    switch (messageData.action) {
        case 'sector-other': {
            return (React.createElement(SectorModal, { data: messageData.data, onClose: onClose }));
        }
        case 'type-other': {
            return (React.createElement(TypeModal, { data: messageData.data, onClose: onClose }));
        }
        case 'details':
            return (React.createElement(DetailsModal, { data: messageData.data, onClose: onClose }));
        default:
            return React.createElement("div", null);
    }
};
export default ServiceWorkerMessageHandler;
