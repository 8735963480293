import LocationRouter from 'pages/location-router';
import * as React from 'react';
import UserContext from 'contexts/UserContext';
import FullPageLoader from 'pages/FullPageLoader';
import UserAccessService from 'services/UserAccessService';
import { useAuth } from 'react-oidc-context';
import '../modules/core/public/sass/main.scss';
import ThemeContext from 'contexts/ThemeContext';
import ServerRequest from 'models/ServerRequest';
import FrontPage from './pages/main-page/FrontPage';
/**
 * This is the main entry point for
 * @returns
 */
const MainRoute = () => {
    const { colorScheme } = React.useContext(ThemeContext);
    const auth = useAuth();
    const [csrf, setCsrf] = React.useState();
    const userAccessService = React.useMemo(() => new UserAccessService({ log: 'real', user: auth.user, csrf }), [auth.user, csrf]);
    React.useEffect(() => {
        if (auth.user !== undefined && auth.user !== null && !auth.user?.expired) {
            const request = new ServerRequest('/csrf', { credentials: 'include', headers: { Authorization: `Bearer ${auth.user.access_token}` } });
            (async () => {
                try {
                    const response = await fetch(request);
                    if (response.status === 200) {
                        const body = await response.json();
                        setCsrf(body);
                    }
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [auth.user]);
    React.useEffect(() => {
        window.addEventListener('logout', () => {
            (async () => auth.removeUser())();
        });
    }, [auth]);
    const content = React.useMemo(() => {
        switch (auth.activeNavigator) {
            case 'signinSilent':
                return React.createElement("div", null, "Signing you in...");
            case 'signoutRedirect':
                return React.createElement("div", null, "Signing you out...");
            default:
        }
        if (auth.isLoading) {
            return (React.createElement(FullPageLoader, null));
        }
        if (auth.isAuthenticated) {
            return (React.createElement(UserContext.Provider, { value: userAccessService },
                React.createElement(LocationRouter, null)));
        }
        return React.createElement(FrontPage, null);
    }, [auth.activeNavigator, auth.isAuthenticated, auth.isLoading, userAccessService]);
    return (React.createElement("div", { className: colorScheme === 'dark' ? 'dark' : '' }, content));
};
export default MainRoute;
