import * as React from 'react';
import { BannerType } from '@symphony-ui/uitoolkit-components';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import TableContext from 'components/custom-table/TableContext';
import { sendError } from 'utils/messageUtils';
import ExpanderCell from './ExpanderCell';
import TableCheckbox from './TableCheckbox';
import CollapseCell from './CollapseCell';
import TransactionMetaModal from './TransactionMetaModal';
import SubComponent from './sub-component/SubComponent';
import useMap from 'components/customHooksMap';
import useOption from 'components/customHooks';
import CompanyCellTranche from './CompanyCell';
const TransactionTable = ({ actionElements, dataTransform }) => {
    const { resetRowExpansion, tableUpdateFn } = React.useContext(TableContext);
    const regionMap = useMap('/syndicate/overview/regions/map');
    const sectorMap = useMap('/syndicate/overview/sectors/map');
    const typeMap = useMap('/syndicate/overview/types/map');
    const regionOptions = useOption('/syndicate/overview/regions/options');
    const sectorOptions = useOption('/syndicate/overview/sectors/options');
    const typeOptions = useOption('/syndicate/overview/types/options');
    const [showMetaModal, setShowMetaModal] = React.useState(false);
    const [transaction, setTransaction] = React.useState();
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]),
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber].findIndex((d) => d.id === response.id);
                }
                if (index > -1) {
                    updated.pages[pageNumber].splice(index, 1, response);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
        }
        else {
            sendError('No table update function.', BannerType.ERROR);
        }
    }, [tableUpdateFn]);
    const updateNames = React.useCallback((companyId, names) => {
        if (tableUpdateFn !== undefined) {
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]).map((page) => {
                        return page.map(t => {
                            if (t.company?.companyId === companyId) {
                                t.updateAlternativeNames(names);
                            }
                            return t;
                        });
                    })
                };
                return updated;
            };
            tableUpdateFn(updateFn);
        }
    }, [tableUpdateFn]);
    const onClickCollapse = React.useCallback(() => {
        if (resetRowExpansion !== undefined) {
            resetRowExpansion([]);
        }
    }, [resetRowExpansion]);
    const onCloseMeta = React.useCallback(() => {
        setShowMetaModal(false);
    }, []);
    const test = React.useCallback(() => (React.createElement(CollapseCell, { onClick: onClickCollapse })), [onClickCollapse]);
    const subComponent = React.useCallback((row) => (React.createElement(SubComponent, { colSpan: row.getVisibleCells().length, updateNames: updateNames, transaction: row.original, setTransaction: setTransaction })), [updateNames]);
    const openModal = React.useCallback((item) => () => {
        setTransaction(item);
        setShowMetaModal(true);
    }, []);
    const meta = React.useMemo(() => ({
        idFunction: (d) => d.id,
        updateTable,
        onClickFn: (item) => openModal(item),
    }), [openModal, updateTable]);
    const columns = React.useMemo(() => [
        {
            cell: (ExpanderCell), header: test, id: 'expander', size: 28,
        },
        {
            cell: (TableCheckbox), header: () => null, id: 'transactionTickbox', size: 28,
        },
        {
            accessorFn: (t) => t.sector !== undefined ? sectorMap.get(t.sector) : undefined,
            header: 'Sector',
            id: 'sector',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: sectorOptions }, isClickable: true },
            size: 80,
        },
        {
            accessorFn: (t) => t.regions?.map(r => regionMap.get(r)).join(", "),
            header: 'Region(s)',
            id: 'regions',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: regionOptions }, isClickable: true },
            size: 80,
        },
        {
            accessorFn: (t) => t.types?.map(t => typeMap.get(t)).join(', '),
            header: 'Type(s)',
            id: 'types',
            meta: { className: 'co-centered', dropdown: { isMulti: true, options: typeOptions }, isClickable: true },
            size: 80,
        },
        {
            cell: CompanyCellTranche,
            header: 'Company',
            id: 'company',
            meta: { isClickable: true },
            size: 240,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            id: 'name',
            meta: { isClickable: true },
            size: 9999,
        },
        {
            accessorFn: (t) => t.firstUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'First Update',
            id: 'firstUpdate',
            meta: { className: 'co-centered', isClickable: true },
            size: 200,
        },
        {
            accessorFn: (t) => t.lastUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'Last Update',
            id: 'lastUpdate',
            meta: { className: 'co-centered', isClickable: true },
            size: 200,
        },
        {
            accessorFn: (t) => t.errors?.join(", "),
            enableColumnFilter: true,
            header: 'Error(s)',
            id: 'errors',
            size: 80,
        }
    ], [regionMap, sectorMap, test, typeMap, regionOptions, sectorOptions, typeOptions]);
    return (React.createElement(React.Fragment, null,
        showMetaModal && transaction !== undefined ? React.createElement(TransactionMetaModal, { onClose: onCloseMeta, transaction: transaction }) : undefined,
        React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, dataTransform: dataTransform, emptyMessage: "You have no transactions yet", errorMessage: "Unable to connect to server to load the data. Please check connection.", fetchSize: 100, meta: meta, name: "transactions", subComponent: subComponent, urlPage: "/syndicate/transactions/page", urlTotal: "/syndicate/transactions/total" })));
};
export default TransactionTable;
