/* eslint-disable react/no-danger */
import { BannerType, Button, Icon, Loader, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import pretty from 'pretty';
import { printAddress, printTimestampString } from 'utils/table';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import MessageContext from 'contexts/MessageContext';
import ApiServerURL from 'models/ServerUrl';
import NavigationContext from 'contexts/NavigationContext';
const EmailPartViewer = ({ hasError, index: index2, isHtml, isLoading, messageId, part, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { updateTab } = React.useContext(NavigationContext);
    const { setMessage } = React.useContext(MessageContext);
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const clickOpen = React.useCallback(() => {
        const url = new ApiServerURL('/syndicate/update/from-email');
        url.searchParams.append('messageId', messageId);
        url.searchParams.append('index', index2.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                setLoading(true);
                setDisabled(true);
                const body = await jsonPlus(request, 'Unable to get UpdateId');
                localStorage.setItem('transactionId', body.transactionId);
                localStorage.setItem('index', body.index.toString());
                localStorage.setItem('view', 'UPDATE');
                updateTab('Transactions', 'UPDATE');
                updateTab('Transactions', 'UPDATE');
            }
            catch (error) {
                setMessage({ content: `Error opening ${error}`, variant: BannerType.ERROR });
            }
        })();
    }, [jsonPlus, index2, setMessage, messageId, updateTab]);
    if (hasError || isLoading) {
        return (React.createElement("div", null, isLoading ? React.createElement(Loader, { className: "co-loader", type: "spinner" }) : 'Error in Content download'));
    }
    return (React.createElement("div", { className: index2 % 2 === 1 ? 'co-email-part-odd' : '' },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            part.subject ? React.createElement(Typography, { type: "h2" }, part.subject) : null,
            React.createElement(Button, { disabled: disabled, iconButton: true, loading: loading, onClick: clickOpen, size: "small" },
                React.createElement(Icon, { iconName: "search" }))),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            part.from ? React.createElement(Typography, { type: "h3" }, printAddress(part.from)) : null,
            part.timestamp ? React.createElement(Typography, { type: "h3" }, printTimestampString(part.timestamp)) : null),
        !isHtml
            ? React.createElement("div", { dangerouslySetInnerHTML: { __html: part.body } })
            : (React.createElement("pre", null, pretty(part.body, { ocd: false }))),
        part.footer ? (React.createElement(React.Fragment, null,
            React.createElement("hr", null),
            !isHtml
                ? React.createElement("div", { className: "co-email-footer", dangerouslySetInnerHTML: { __html: part.footer } })
                : (React.createElement("div", { className: "co-email-footer" }, pretty(part.footer, { ocd: false }))))) : null));
};
export default EmailPartViewer;
