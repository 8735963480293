import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import SyndicateContext from '../../context/SyndicateContext';
import Overview from './overview/Overview';
import TransactionViewer from './transaction-viewer/TransactionViewer';
import UpdateManager from './update-manager/UpdateManager';
const Transactions = () => {
    const { updateView, view } = React.useContext(NavigationContext);
    const { updateId } = React.useContext(SyndicateContext);
    React.useEffect(() => {
        if (view === 'UPDATE' && updateId === undefined) {
            updateView('OVERVIEW');
        }
    }, [updateId, updateView, view]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('OVERVIEW');
        }
    }, [view, updateView]);
    switch (view) {
        case 'OVERVIEW':
            return (React.createElement(Overview, null));
        case 'TABLE':
            return (React.createElement(TransactionViewer, null));
        case 'UPDATE':
            if (updateId !== undefined) {
                return (React.createElement(UpdateManager, null));
            }
            return (React.createElement(Overview, null));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default Transactions;
