class Contributor {
    emailAddress;
    id;
    regions;
    sectors;
    companyId;
    timeZone;
    constructor(contributorResponse) {
        this.companyId = contributorResponse.companyId;
        this.emailAddress = contributorResponse.emailAddress;
        this.id = contributorResponse.id;
        this.regions = contributorResponse.regions;
        this.sectors = contributorResponse.sectors;
        this.timeZone = contributorResponse.timeZone;
    }
    clone() {
        return new Contributor({
            companyId: this.companyId,
            emailAddress: this.emailAddress,
            id: this.id,
            regions: this.regions,
            sectors: this.sectors,
            timeZone: this.timeZone
        });
    }
    setRegions(regions) {
        const clone = this.clone();
        clone.regions = regions;
        return clone;
    }
    setSectors(sectors) {
        const clone = this.clone();
        clone.sectors = sectors;
        return clone;
    }
    setCountryId(companyId) {
        const clone = this.clone();
        clone.companyId = companyId;
        return clone;
    }
    setTimeZone(timeZone) {
        const clone = this.clone();
        clone.timeZone = timeZone;
        return clone;
    }
}
export default Contributor;
