/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import PopupButton from '../action/PopupButton';
import ColumnPicker from '../ColumnPicker';
import TableSettingsSaveModal from '../TableStateSaveModal';
import TableFormatSettingsModal, { TableFormattingDefault } from '../TableFormatSettingsModal';
import TableStateClass from '../TableStateClass';
import TableSettingsModal from '../TableSettingsModal';
const SettingsButton = function SettingsButtonElement({ formatting, meta, name, tableName, setFormatting, setMeta, setName, table, tableState, }) {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isAmended, setAmended] = React.useState(false);
    const [showColumnPicker, setShowColumnPicker] = React.useState(false);
    const [showTableSettingsModal, setShowTableSettingsModal] = React.useState(false);
    const [showTableSettingsSaveModal, setShowTableSettingsSaveModal] = React.useState(false);
    const [showTableFormattingModal, setShowTableFormattingModal] = React.useState(false);
    const [originalSettings, setOriginalSettings] = React.useState();
    const saveSettings = React.useCallback((newName, newMeta) => {
        const updatedTableSettings = {
            formatting,
            state: tableState,
            id: newMeta?.id,
            createdBy: newMeta?.createdBy,
            createdDate: newMeta?.createdDate,
            modifiedBy: newMeta?.modifiedBy,
            modifiedDate: newMeta?.modifiedDate,
            name: newName,
            permissions: newMeta?.permissions,
            table: tableName,
        };
        (async () => {
            const init = { body: JSON.stringify(updatedTableSettings), headers: { 'Content-Type': 'application/json' }, method: 'POST' };
            const request = new ServerRequest('/syndicate/table', init);
            try {
                const body = await apiFetchJson(request);
                const { id, createdBy, createdDate, modifiedBy, modifiedDate, permissions, } = body;
                setMeta({
                    id, createdBy, createdDate, modifiedBy, modifiedDate, permissions,
                });
                setName(body.name);
                table.setState(new TableStateClass(body.state));
                setFormatting(body.formatting);
            }
            catch (error) {
                sendFetchError('Unable to save table settings', error, request);
            }
        })();
    }, [apiFetchJson, formatting, setMeta, setName, setFormatting, table, tableState, tableName]);
    const setTableSettings = React.useCallback((tableSettings) => {
        const { id, createdBy, createdDate, modifiedBy, modifiedDate, permissions, } = tableSettings;
        setFormatting(tableSettings.formatting);
        setMeta({
            id, createdBy, createdDate, modifiedBy, modifiedDate, permissions,
        });
        setName(tableSettings.name);
        table.setState(new TableStateClass(tableSettings.state));
    }, [setFormatting, setMeta, setName, table]);
    const loadSettings = React.useCallback(async (id, signal) => {
        const url = new ApiServerURL('/syndicate/table');
        url.searchParams.append('id', id);
        const request = new ServerRequest(url);
        const body = await apiFetchJson(request, signal);
        setOriginalSettings(body);
        return body;
    }, [apiFetchJson]);
    const childFn = React.useCallback((close) => {
        const list = [
            (React.createElement(DropdownMenuItem, { key: "columnpicker", onClick: () => {
                    setShowColumnPicker(true);
                    close();
                } }, "Select Columns")),
            (React.createElement(DropdownMenuItem, { key: "resetstate", onClick: () => {
                    table.setState(TableStateClass.DEFAULT);
                    setFormatting(TableFormattingDefault);
                    setName(undefined);
                    setMeta(undefined);
                    localStorage.removeItem(`${tableName}-table-settings`);
                    close();
                } }, "Reset to Default"))
        ];
        if (meta === undefined || name === undefined) {
            list.push((React.createElement(DropdownMenuItem, { key: "savesettings", onClick: () => {
                    setShowTableSettingsSaveModal(true);
                    close();
                } }, "Save curent settings")));
        }
        else {
            list.push((React.createElement(DropdownMenuItem, { key: "saveSettings", onClick: () => {
                    setShowTableSettingsSaveModal(true);
                    close();
                } }, "Save as New")));
            list.push((React.createElement(DropdownMenuItem, { key: "updateSettings", onClick: () => {
                    saveSettings(name, meta);
                    close();
                } }, "Update settings")));
        }
        list.push((React.createElement(DropdownMenuItem, { key: "loadSettings", onClick: () => {
                setShowTableSettingsModal(true);
                close();
            } }, "Load settings")));
        list.push((React.createElement(DropdownMenuItem, { key: "formats", onClick: () => {
                setShowTableFormattingModal(true);
                close();
            } }, "Formatting")));
        return list;
    }, [meta, name, saveSettings, setFormatting, setMeta, setName, table, tableName]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const currentId = meta?.id;
        if (currentId !== undefined && originalSettings === undefined) {
            (async () => {
                try {
                    await loadSettings(currentId, abortController.signal);
                }
                catch (error) {
                    //
                }
            })();
        }
        return () => abortController.abort();
    }, [loadSettings, meta?.id, originalSettings]);
    React.useEffect(() => {
        if (originalSettings !== undefined) {
            if (!new TableStateClass(originalSettings.state).equals(tableState)) {
                setAmended(true);
            }
            else {
                setAmended(false);
            }
        }
    }, [originalSettings, tableState]);
    return (React.createElement(React.Fragment, null,
        showColumnPicker
            ? React.createElement(ColumnPicker, { onClose: () => setShowColumnPicker(false), table: table }) : null,
        showTableSettingsSaveModal
            ? (React.createElement(TableSettingsSaveModal, { close: () => setShowTableSettingsSaveModal(false), saveSettings: saveSettings, setMeta: setMeta, setName: setName })) : undefined,
        showTableSettingsModal
            ? (React.createElement(TableSettingsModal, { loadSettings: loadSettings, onClose: () => setShowTableSettingsModal(false), id: meta?.id, setTableSettings: setTableSettings, tableName: tableName })) : undefined,
        showTableFormattingModal
            ? (React.createElement(TableFormatSettingsModal, { close: () => setShowTableFormattingModal(false), formatting: formatting, setFormatting: setFormatting })) : undefined,
        meta?.id !== undefined ? (React.createElement("div", { style: { fontStyle: isAmended ? 'italic' : undefined, fontWeight: 600, marginTop: '0.375rem' } },
            name,
            isAmended ? ' (amended)' : undefined)) : undefined,
        React.createElement(PopupButton, { childFn: childFn, iconName: "settings", style: { transform: 'translate(-9rem, -0.5rem)' } })));
};
export default SettingsButton;
