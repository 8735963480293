/* eslint-disable react/function-component-definition */
import { Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const SortIcon = function SortIconElement({ column }) {
    const onClick = React.useCallback(() => {
        column.toggleSorting(undefined, true);
    }, [column]);
    if (column.getIsSorted() === 'asc') {
        return React.createElement(Icon, { iconName: "drop-up", onClick: onClick });
    }
    if (column.getIsSorted() === 'desc') {
        return React.createElement(Icon, { iconName: "drop-down", onClick: onClick });
    }
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        React.createElement(Icon, { iconName: "drop-up", onClick: onClick }),
        React.createElement(Icon, { iconName: "drop-down", onClick: onClick, style: { marginTop: '-0.5rem' } })));
};
export default SortIcon;
