const ecbGenericDownload = async (startPeriod, endPeriod, service, options) => {
    const url = new URL(`/service/data/${service}/${options.toUpperCase()}`, 'https://data-api.ecb.europa.eu');
    url.searchParams.append('startPeriod', startPeriod.toISOString().substring(0, 10));
    url.searchParams.append('endPeriod', endPeriod.toISOString().substring(0, 10));
    url.searchParams.append('detail', 'dataonly');
    const headers = new Headers();
    headers.append('Accept', 'application/vnd.sdmx.data+json;version=1.0.0-wd');
    const init = { headers };
    const response = await fetch(url.toString(), init);
    const body = await response.json();
    return body;
};
export default ecbGenericDownload;
