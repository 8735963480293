import ActionButton from 'components/custom-table/action/ActionButton';
import * as React from 'react';
import ResultsModal from './ResultsModal';
const securitiesTableActionCell = (setModalContent) => function func(context) {
    const showDetails = ({ row: { original: security } }) => {
        setModalContent(React.createElement(ResultsModal, { hideModal: () => setModalContent(undefined), security: security }));
    };
    const actionsMenu = [{ callback: showDetails, label: 'Details' }];
    return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
};
export default securitiesTableActionCell;
