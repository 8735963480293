import * as React from 'react';
import { Checkbox, SelectionStatus } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
const SectorSelector = ({ save, sectors }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const onChange = React.useCallback((sector) => () => {
        if (sectors.includes(sector)) {
            save(sectors.filter((s) => s !== sector));
        }
        else {
            save(sectors.concat([sector]));
        }
    }, [save, sectors]);
    React.useEffect(() => {
        const request = new ServerRequest('/syndicate/notification-sub/sector-options');
        (async () => {
            const body = await apiFetchJson(request, 'error downloading sector options');
            setOptions(body);
        })();
    }, [apiFetchJson]);
    return (React.createElement("div", null, options.map((o) => (React.createElement(Checkbox, { label: o.label, key: o.value, name: o.value, onChange: onChange(o.value), status: sectors.includes(o.value) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: sectors.includes(o.value).toString() })))));
};
export default SectorSelector;
