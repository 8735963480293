import IndexedRegex from './IndexedRegex';
import Regex from './Regex';
class LineRegex {
    category;
    id;
    regexes = [];
    constructor({ category, id, regexes }) {
        this.category = category;
        this.id = id;
        this.regexes = regexes.map((r) => new Regex(r));
    }
    add(regex, index) {
        if (index !== undefined) {
            this.regexes[index] = regex;
        }
        else {
            this.regexes.push(regex);
        }
        return this;
    }
    remove(index) {
        this.regexes.splice(index, 1);
    }
    switch(index1, index2) {
        const newLineRegex = new LineRegex({ category: this.category, id: this.id, regexes: this.regexes });
        newLineRegex.regexes[index1] = this.regexes[index2];
        newLineRegex.regexes[index2] = this.regexes[index1];
        return newLineRegex;
    }
    updateRegexes(regexes) {
        return new LineRegex({ category: this.category, id: this.id, regexes });
    }
    static create(newRegexObject) {
        return new LineRegex(newRegexObject);
    }
    toIndexedRegexes() {
        return this.regexes.map((r, index) => new IndexedRegex({
            id: this.id, index, markers: r.markers, template: r.template,
        }));
    }
}
export default LineRegex;
