import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import { printAddress } from 'utils/table';
const ContentModal = ({ hideModal, show, update }) => (React.createElement(Modal, { closeButton: true, onClose: hideModal, parentNode: document.body, show: show, size: "full-width" },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, update.subject)),
    React.createElement(ModalBody, null,
        React.createElement("div", null, printAddress(update.from)),
        React.createElement("div", { className: "co-update-timestamp" }, update.timestamp.toLocaleString()),
        React.createElement("div", { className: "co-update-html", dangerouslySetInnerHTML: { __html: update.html } })),
    React.createElement(ModalFooter, null)));
export default ContentModal;
