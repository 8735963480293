import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import Schedule from './Schedule';
import ScheduleModal from './ScheduleModal';
import ScheduleTable from './ScheduleTable';
const Scheduling = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [schedule, setSchedule] = React.useState();
    const [schedules, setSchedules] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const edit = React.useCallback(({ row: { original: editSchedule } }) => {
        setSchedule(editSchedule);
        setShowModal(true);
    }, []);
    const onClose = React.useCallback(() => setShowModal(false), []);
    const save = React.useCallback((updatedSchedule) => {
        const request = new ServerRequest('/core/schedule', { body: JSON.stringify(updatedSchedule), headers: { 'content-type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const updatedSchedules = schedules.map((s) => (s.id === body.id ? body : s)).map((s) => new Schedule(s));
                setSchedules(updatedSchedules);
            }
            catch (error) {
                sendFetchError('Unable to save schedule', error, request);
            }
        })();
    }, [apiFetchJson, schedules]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL('/core/schedule/all');
        const request = new ServerRequest(url);
        (async () => {
            setLoading(true);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const updatedSchedules = body.map((s) => new Schedule(s));
                setSchedules(updatedSchedules);
            }
            catch (error) {
                sendFetchError('Unable to load all schedules', error, request);
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [apiFetchJson]);
    return (React.createElement(React.Fragment, null,
        schedule !== undefined && showModal
            ? (React.createElement(ScheduleModal, { onClose: onClose, save: save, schedule: schedule })) : undefined,
        React.createElement(ScheduleTable, { edit: edit, hasError: hasError, isLoading: isLoading, schedules: schedules })));
};
export default Scheduling;
