import companiesTableActionCell from './companiesTableActionCell';
const markupColumns = function func(columns, setActualCompanyTags, setCompany, source) {
    columns.unshift({
        cell: companiesTableActionCell(setActualCompanyTags, setCompany, source),
        id: 'actions',
    });
    columns.push({
        accessorFn: ({ tags }) => (tags && Array.isArray(tags.tags) ? tags.tags.join(', ') : ''),
        header: 'Tags',
        id: 'companyTags',
    });
    return columns;
};
export default markupColumns;
