import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import options from './anna';
import './AnnaForm.css';
const AnnaForm = ({ search, setSearch }) => {
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        isinValue: Validators.Pattern(/[A-Z0-9\\*]{12}/),
    }), []);
    const issueCurrencyOptions = React.useMemo(() => options
        .issueCurrency.map((o) => ({ ...o, label: o.value })), []);
    const countryOptions = React.useMemo(() => options.country.map((o) => {
        const label = o.label === ' All' ? ' All' : o.label.substring(4);
        return { ...o, label };
    }), []);
    const instrumentCategoryOptions = React.useMemo(() => options.instrumentCategory.map((o) => {
        const label = o.label === ' All' ? ' All' : o.label.substring(0, o.label.length - 5);
        return { ...o, label };
    }), []);
    const state = React.useMemo(() => search.getState(), [search]);
    // TODO #325 #324 Fix validation ANNA Form
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    const changeQueryDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target.value;
        const updatedQuery = search.query.updateString(name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryMultiDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map((v) => v.value) : [value.value];
        const firstValue = 'value' in options[name][0] ? options[name][0].value : undefined;
        let updatedValues = null;
        if (newValue.length === 0 || (newValue.length === 1 && newValue[0] === firstValue)) {
            updatedValues = firstValue !== undefined ? [firstValue] : [];
        }
        else if (newValue.length > 0) {
            updatedValues = newValue.filter(v => v !== firstValue);
        }
        if (updatedValues !== null) {
            const updatedQuery = search.query.updateStringArray(name, updatedValues);
            const updatedSearch = search.updateQuery(updatedQuery);
            setSearch(updatedSearch);
        }
    }, [search, setSearch]);
    const changeQueryInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = search.query.updateString(name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryTimeRange = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = search.query.updateTimeRange(name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeFilterItem = React.useCallback((event) => {
        const { value } = event.target;
        const updatedFilter = search.filter.updateTags(value);
        const udpatedSearch = search.updateFilter(updatedFilter);
        setSearch(udpatedSearch);
    }, [search, setSearch]);
    const list = React.useMemo(() => [
        React.createElement(Dropdown, { className: "select-addon select-contains", isTypeAheadEnabled: false, key: "entity-name-option2", label: "", onChange: changeQueryDropdown('entityNameOpton2'), options: options.entityNameOpton2, value: options.entityNameOpton2.find(o => 'value' in o && o.value === search.query.entityNameOpton2) }),
        React.createElement(Dropdown, { className: "select-addon select-length", isTypeAheadEnabled: false, key: "entity-name-option1", label: "", onChange: changeQueryDropdown('entityNameOpton1'), options: options.entityNameOpton1, value: options.entityNameOpton1.find(o => 'value' in o && o.value === search.query.entityNameOpton1) }),
    ], [changeQueryDropdown, search.query.entityNameOpton1, search.query.entityNameOpton2]);
    return (React.createElement("div", { className: "base-form" },
        state !== 'other'
            ? (React.createElement(Validation, { errorMessage: { pattern: 'ISIN should have 12 characters' }, onValidationChanged: onValidationChanged('isinValue'), validator: validators.isinValue },
                React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "ISIN", onChange: changeQueryInput('isinValue'), rightDecorators: (React.createElement(Dropdown, { className: "select-addon select-isin-type", isTypeAheadEnabled: false, label: "", onChange: changeQueryDropdown('isinType'), options: options.isinType, value: options.isinType.find(o => 'value' in o && o.value === search.query.isinType) })), tooltip: 'Search by ISIN, either of the security itself, or of securities with that ISIN as underlying.&nbsp;&nbsp;Note: you can use * as wildcard.', value: search.query.isinValue ?? '' }))) : undefined,
        state !== 'isin' ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "other-form-first" },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Status", onChange: changeQueryMultiDropdown('annaStatus'), options: options.annaStatus, value: search.query.annaStatus.length === 0 ? [options.annaStatus[0]] : options.annaStatus.filter(o => 'value' in o && search.query.annaStatus.includes(o.value)) })),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Country", onChange: changeQueryMultiDropdown('country'), options: countryOptions, value: search.query.country.length === 0 ? [countryOptions[0]] : countryOptions.filter(o => 'value' in o && search.query.country.includes(o.value)) }),
            React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "Entity Name", onChange: changeQueryInput('entityName'), rightDecorators: list, value: search.query.entityName ?? '' }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Category", onChange: changeQueryMultiDropdown('instrumentCategory'), options: instrumentCategoryOptions, value: search.query.instrumentCategory.length === 0 ? [instrumentCategoryOptions[0]] : instrumentCategoryOptions.filter(o => 'value' in o && search.query.instrumentCategory.includes(o.value)) }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Currency", onChange: changeQueryMultiDropdown('issueCurrency'), options: issueCurrencyOptions, value: search.query.issueCurrency.length === 0 ? [issueCurrencyOptions[0]] : issueCurrencyOptions.filter(o => 'value' in o && search.query.issueCurrency.includes(o.value)) }),
            React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "Issue Description", onChange: changeQueryInput('issueDescription'), rightDecorators: (React.createElement(Dropdown, { className: "select-addon select-contains", isTypeAheadEnabled: false, label: "", onChange: changeQueryDropdown('issueDesOptions'), options: options.issueDesOptions, value: options.issueDesOptions.find(o => 'value' in o && o.value === search.query.issueDesOptions) })), value: search.query.issueDescription ?? '' }),
            React.createElement(TimeRangeInput, { defaultPeriod: "Y", label: "Maturity", onChange: changeQueryTimeRange('maturity'), value: search.query.maturity }),
            React.createElement(TimeRangeInput, { defaultPeriod: "D", label: "Last Update", onChange: changeQueryTimeRange('update'), value: search.query.update }),
            React.createElement(RemoteFilterItem, { label: "Tags", onChange: changeFilterItem, path: "/nit/companies/tags/options/anna", value: search.filter.tags }))) : ''));
};
export default AnnaForm;
