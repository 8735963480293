import * as React from 'react';
import { Dropdown, Typography, Validation, } from '@symphony-ui/uitoolkit-components';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import FileInput from 'components/file-input/FileInput';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptionsMulti } from 'utils/change';
import { sendFetchError } from 'utils/messageUtils';
import { errorMessage } from 'utils/validation/initialValidation';
const WorkflowForm = ({ save, workflow }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [data, setData] = React.useState(workflow);
    const [errorState, setErrorState] = React.useState({});
    const [optionsParties, setOptionsParties] = React.useState([]);
    const validators = React.useMemo(() => ({
        partiesBloomberg: CustomValidators.RequiredArray,
        partiesBooking: CustomValidators.RequiredArray,
        partiesDocumentation: CustomValidators.RequiredArray,
        partiesIsin: CustomValidators.RequiredArray,
        partiesListing: CustomValidators.RequiredArray,
        termsheet: CustomValidators.RequiredFile,
    }), []);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const request = new ServerRequest('/users/dropdown');
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsParties(body);
            }
            catch (error) {
                sendFetchError('Unable to download users for dropdown', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    const onChangeFile = React.useCallback((event) => {
        const { files } = event.target;
        const file = files?.item(0);
        if (file != null) {
            setData({ ...data, id: file.name });
        }
    }, [data]);
    const onValidationChanged = (name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    };
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Workflow", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "workflow-form" },
            React.createElement(Validation, { validator: validators.termsheet, errorMessage: errorMessage, onValidationChanged: onValidationChanged('termsheet') },
                React.createElement(FileInput, { label: "Term Sheet", name: "termsheet", onChange: onChangeFile })),
            React.createElement(Typography, { type: "h2" }, "Select those responsible for"),
            React.createElement(Validation, { validator: validators.partiesBloomberg, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesBloomberg') },
                React.createElement(Dropdown, { label: "Bloomberg Set Up", options: optionsParties, onChange: onChangeDropdown('partiesBloomberg'), isMultiSelect: true, value: getOptionsMulti(data.partiesBloomberg, optionsParties) })),
            React.createElement(Validation, { validator: validators.partiesIsin, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesIsin') },
                React.createElement(Dropdown, { label: "ISIN Code", options: optionsParties, onChange: onChangeDropdown('partiesIsin'), isMultiSelect: true, value: getOptionsMulti(data.partiesIsin, optionsParties) })),
            React.createElement(Validation, { validator: validators.partiesDocumentation, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesDocumentation') },
                React.createElement(Dropdown, { label: "Documentation", options: optionsParties, onChange: onChangeDropdown('partiesDocumentation'), isMultiSelect: true, value: getOptionsMulti(data.partiesDocumentation, optionsParties) })),
            React.createElement(Validation, { validator: validators.partiesListing, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesListing') },
                React.createElement(Dropdown, { label: "Listing", options: optionsParties, onChange: onChangeDropdown('partiesListing'), isMultiSelect: true, value: getOptionsMulti(data.partiesListing, optionsParties) })),
            React.createElement(Validation, { validator: validators.partiesBooking, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesBooking') },
                React.createElement(Dropdown, { label: "Trade Booking", options: optionsParties, onChange: onChangeDropdown('partiesBooking'), isMultiSelect: true, value: getOptionsMulti(data.partiesBooking, optionsParties) })))));
};
export default WorkflowForm;
