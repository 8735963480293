import { Button, Modal, ModalBody, ModalFooter, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const NameAddModal = ({ addName, closeModal }) => {
    const [value, setValue] = React.useState('');
    const onChange = React.useCallback((event) => {
        console.log('Change event: %o', event);
        setValue(event.target.value);
    }, []);
    const onClickSave = React.useCallback(() => {
        (async () => {
            await addName(value);
            closeModal();
        })();
    }, [addName, closeModal, value]);
    return (React.createElement(Modal, { closeButton: true, onClose: closeModal, show: true, size: "small" },
        React.createElement(ModalTitle, null, "Add Name"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Add synonym", value: value, onChange: onChange })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default NameAddModal;
