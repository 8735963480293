import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import ItemRegex from '../../../models/regexes/ItemRegex';
import SubjectRegex from '../../../models/regexes/SubjectRegex';
import KeyValueRegex from '../../../models/regexes/KeyValueRegex';
import LineRegex from '../../../models/regexes/LineRegex';
import RegexTable from './regex-table/RegexTable';
import KeyValueRegexForm from './key-value-regex-form/KeyValueRegexForm';
const RegexViewer = ({ category, keyValueRegex, lineRegex, myKey, regexes, setKeyValueRegex, setLineRegex, setMyKey, setRegexes, type, }) => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const loadRegexes = React.useCallback((signal) => {
        if (type === 'keyvalues' && keyValueRegex === undefined) {
            //
        }
        else {
            setLoading(true);
            const url = new ApiServerURL(`/syndicate/regex/${type}`);
            if (type === 'keyvalues') {
                url.searchParams.append('id', keyValueRegex?.id ?? '');
            }
            else if (type === 'paragraphs') {
                url.searchParams.append('category', category);
            }
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, signal);
                    switch (type) {
                        case 'keyvalues':
                            {
                                const newKeyValueRegex = new KeyValueRegex(body);
                                setKeyValueRegex(newKeyValueRegex);
                            }
                            break;
                        case 'listitems':
                            {
                                const itemRegexes = body.map((r) => new ItemRegex(r));
                                const indexedRegexes = itemRegexes.sort((r0, r1) => (r0.index ?? 0) - (r1.index ?? 0)).map((i) => i.toIndexedRegex());
                                setRegexes(indexedRegexes);
                            }
                            break;
                        case 'paragraphs':
                            {
                                const newLineRegex = new LineRegex(body);
                                setLineRegex(newLineRegex);
                            }
                            break;
                        case 'subject':
                            {
                                const subjectRegexes = body.map((r) => new SubjectRegex(r));
                                const indexedRegexes = subjectRegexes.sort((r0, r1) => (r0.index ?? 0) - (r1.index ?? 0)).map((i) => i.toIndexedRegex());
                                setRegexes(indexedRegexes);
                            }
                            break;
                        default:
                            throw new Error('Unknown type');
                    }
                }
                catch (error) {
                    if (!(error instanceof Error && error.name === 'AbortError')) {
                        setError(true);
                        sendFetchError('Unable to download regexes', error, request);
                    }
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [category, apiFetchJson, keyValueRegex, setKeyValueRegex, setLineRegex, setRegexes, type]);
    const deleteRegex = React.useCallback(({ row }) => {
        const id = Number.parseInt(row.id, 10);
        (async () => {
            let request = null;
            try {
                switch (type) {
                    case 'keyvalues':
                        if (keyValueRegex !== undefined) {
                            keyValueRegex.remove(id);
                            request = new ServerRequest('/syndicate/regex/keyvalues', { body: JSON.stringify(keyValueRegex), headers: { 'Content-Type': 'application/json' }, method: 'PATCH' });
                            const body = await apiFetchJson(request);
                            const updatedKeyValueRegex = new KeyValueRegex(body);
                            setKeyValueRegex(updatedKeyValueRegex);
                            document.dispatchEvent(new Event('reloaddata'));
                        }
                        break;
                    case 'listitems':
                        {
                            const itemRegex = ItemRegex.createFromIndexedRegex(row.original);
                            request = new ServerRequest('/syndicate/regex/listitems', { body: JSON.stringify(itemRegex), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
                            await apiFetchNoContent(request);
                            const clonedRegexes = [...regexes];
                            clonedRegexes.splice(id, 1);
                            setRegexes(clonedRegexes);
                            document.dispatchEvent(new Event('reloaddata'));
                        }
                        break;
                    case 'paragraphs':
                        if (lineRegex !== undefined) {
                            lineRegex.remove(id);
                            request = new ServerRequest('/syndicate/regex/paragraphs', { body: JSON.stringify(lineRegex), headers: { 'Content-Type': 'application/json' }, method: 'PATCH' });
                            const body = await apiFetchJson(request);
                            const updatedLineRegex = new LineRegex(body);
                            setLineRegex(updatedLineRegex);
                            document.dispatchEvent(new Event('reloaddata'));
                        }
                        break;
                    case 'subject': {
                        const subjectRegex = SubjectRegex.createFromIndexedRegex(row.original);
                        request = new ServerRequest('/syndicate/regex/subject', { body: JSON.stringify(subjectRegex), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
                        await apiFetchNoContent(request);
                        const clonedRegexes = [...regexes];
                        clonedRegexes.splice(id, 1);
                        setRegexes(clonedRegexes);
                        document.dispatchEvent(new Event('reloaddata'));
                        break;
                    }
                    default:
                }
            }
            catch (error) {
                if (request !== null) {
                    sendFetchError("Unable to remove regex", error, request);
                }
            }
        })();
    }, [apiFetchJson, apiFetchNoContent, keyValueRegex, lineRegex, regexes, setKeyValueRegex, setLineRegex, setRegexes, type]);
    const callbackCreate = React.useCallback((updatedKeyValueRegex) => {
        if (myKey !== undefined) {
            setMyKey({ ...myKey, id: updatedKeyValueRegex.id });
        }
    }, [myKey, setMyKey]);
    const callbackAdd = () => {
        // ignore
    };
    const isFormDisabled = React.useMemo(() => (keyValueRegex !== undefined && myKey !== undefined && keyValueRegex.id === myKey.id), [keyValueRegex, myKey]);
    const resetData = React.useCallback((newData) => {
        (async () => {
            switch (type) {
                case 'keyvalues':
                    if (keyValueRegex !== undefined) {
                        const newKeyValueRegex = keyValueRegex.updateRegexes(newData);
                        const method = newKeyValueRegex.id ? "PATCH" : "POST";
                        const request = new ServerRequest('/syndicate/regex/keyvalues', { body: JSON.stringify(newKeyValueRegex), headers: { 'Content-Type': 'application/json' }, method });
                        const body = await apiFetchJson(request);
                        const updated = new KeyValueRegex(body);
                        setKeyValueRegex(updated);
                    }
                    break;
                case 'listitems':
                    {
                        const ids = newData.map((r) => r.id);
                        const url = new ApiServerURL('/syndicate/regex/listitems/reorder');
                        const request = new ServerRequest(url, { body: JSON.stringify(ids), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
                        try {
                            await apiFetchNoContent(request);
                            setRegexes(newData);
                        }
                        catch (error) {
                            sendFetchError('Unable to reorder list items', error, request);
                        }
                    }
                    break;
                case 'paragraphs':
                    if (lineRegex !== undefined) {
                        const newLineRegex = lineRegex.updateRegexes(newData);
                        const method = lineRegex.id ? "PATCH" : "POST";
                        const request = new ServerRequest('/syndicate/regex/paragraphs', { body: JSON.stringify(newLineRegex), headers: { 'Content-Type': 'application/json' }, method });
                        const body = await apiFetchJson(request);
                        const updated = new LineRegex(body);
                        setLineRegex(updated);
                    }
                    break;
                case 'subject':
                    {
                        const ids = newData.map((r) => r.id);
                        const url = new ApiServerURL('/syndicate/regex/subject/reorder');
                        const request = new ServerRequest(url, { body: JSON.stringify(ids), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
                        try {
                            await apiFetchNoContent(request);
                        }
                        catch (error) {
                            sendFetchError('Unable to reorder subjects', error, request);
                        }
                        setRegexes(newData);
                    }
                    break;
                default:
            }
        })();
    }, [apiFetchJson, apiFetchNoContent, keyValueRegex, lineRegex, setKeyValueRegex, setLineRegex, setRegexes, type]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (type === 'paragraphs') {
            setRegexes(lineRegex !== undefined ? lineRegex.toIndexedRegexes() : []);
        }
        else if (type === 'keyvalues') {
            setRegexes(keyValueRegex !== undefined ? keyValueRegex.toIndexedRegexes() : []);
        }
        else {
            loadRegexes(abortController.signal);
        }
        return () => { abortController.abort(); };
    }, [keyValueRegex, lineRegex, loadRegexes, setRegexes, type]);
    const actionElements = React.useMemo(() => {
        if (type === 'keyvalues') {
            return [(React.createElement(KeyValueRegexForm, { callbackAdd: callbackAdd, callbackCreate: callbackCreate, isFormDisabled: isFormDisabled, key: "regex-form", initialKeyValueRegexId: keyValueRegex?.id, name: myKey !== undefined ? myKey.name : undefined, setKeyValueRegex: setKeyValueRegex }))];
        }
        return [];
    }, [callbackCreate, isFormDisabled, keyValueRegex, myKey, setKeyValueRegex, type]);
    return (React.createElement(RegexTable, { actionElements: actionElements, deleteRegex: deleteRegex, emptyMessage: "No regexes found", errorMessage: "Error downloading regexes", hasError: hasError, isLoading: isLoading, regexes: regexes, resetData: resetData }));
};
export default RegexViewer;
