import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { optionSort } from 'utils/table';
import TableContext from 'components/custom-table/TableContext';
import NavigationContext from 'contexts/NavigationContext';
import WebSocketContext from 'contexts/WebSocketContext';
import TrancheWrapper from '../../../models/TrancheWrapper';
import MesssagesModal from './MessagesModal';
import OverviewTable from './table/OverviewTable';
import OtherSourcesModal from './OtherSourcesModal';
import MetaModal from './MetaModal';
const OverviewBody = ({ setIndex, setTransactionId }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const { addSubscription } = React.useContext(WebSocketContext);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showMetaModal, setShowMetaModal] = React.useState(false);
    const [showOtherModal, setShowOtherModal] = React.useState(false);
    const [trancheWrapper, setTrancheWrapper] = React.useState();
    const [isin, setIsin] = React.useState();
    const [currencyOptions, setCurrencyOptions] = React.useState([]);
    const [esgOptions, setEsgOptions] = React.useState([]);
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [statusOfNotesOptions, setStatusOfNotesOptions] = React.useState([]);
    const [statusOptions, setStatusOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [overviewClass, setOverviewClass] = React.useState();
    const { tableUpdateFn } = React.useContext(TableContext);
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const tranches = response;
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]),
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber]?.findIndex((d) => d.transaction.id === tranches[0].transaction.id);
                }
                if (index > -1) {
                    const currentLength = data.pages[pageNumber].filter((d) => d.transaction.id === tranches[0].transaction.id).length;
                    updated.pages[pageNumber].splice(index, currentLength, ...tranches);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
            // } else {
            //   setMessage({ content: 'No table update function.', variant: BannerType.ERROR });
        }
    }, [tableUpdateFn]);
    const rowClassFn = React.useCallback((row) => {
        const classes = [];
        if (row.original.isEditing) {
            classes.push('co-row-loading');
        }
        const { confirm, lastUpdate } = row.original.transaction;
        if (confirm !== undefined && new Date(confirm.timestamp).getTime() > new Date(lastUpdate).getTime()) {
            classes.push('co-confirmed');
        }
        return classes.length > 0 ? ` ${classes.join(' ')}` : '';
    }, []);
    const meta = React.useMemo(() => ({
        className: 'co-centered',
        idFunction: (d) => d.transaction.id,
        rowClassFn,
        updateTable,
    }), [rowClassFn, updateTable]);
    const addTranche = React.useCallback(({ row: { original: { transaction: { id } } } }) => {
        const url = new ApiServerURL('/syndicate/transactions/add-tranche');
        url.searchParams.append('transactionId', id);
        const request = new ServerRequest(url, { method: 'PATCH' });
        (async () => {
            try {
                const body = await jsonPlus(request, 'Unable to add new tranche');
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus, updateTable]);
    const confirm = React.useCallback(({ row: { original } }) => {
        const { id } = original.transaction;
        const url = new ApiServerURL('/syndicate/transactions/confirm');
        url.searchParams.append('transactionId', id);
        const request = new ServerRequest(url, { headers: { 'Content-Type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus, updateTable]);
    const details = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowDetailsModal(true);
    }, []);
    const manageMeta = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowMetaModal(true);
    }, []);
    const removeOverrides = React.useCallback(({ row: { original: { transaction: { id }, trancheIndex } } }) => {
        const url = new ApiServerURL('/syndicate/transactions/override');
        url.searchParams.append('transactionId', id);
        url.searchParams.append('trancheIndex', trancheIndex.toString());
        const request = new ServerRequest(url, { method: 'DELETE' });
        (async () => {
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus, updateTable]);
    const seeUpdate = React.useCallback(({ row: { original: { transaction: { id } } } }) => {
        setTransactionId(id);
        setIndex(-1);
        localStorage.setItem('transactionId', id);
        updateView('UPDATE');
    }, [setIndex, setTransactionId, updateView]);
    const updateWebSocket = React.useCallback((e) => {
        const body = JSON.parse(e.body);
        const tranches = body.map((t) => new TrancheWrapper(t));
        updateTable(tranches);
    }, [updateTable]);
    const viewOthers = React.useCallback(({ row: { original: { overrideMap, data } } }) => {
        if ('identifiers' in data) {
            const i = data?.identifiers.find((id) => id.type === 'ISIN');
            setIsin(i?.value);
        }
        else if (overrideMap?.isin !== undefined) {
            const isinRegex = /(?<isin>[A-Z]{2}[A-Z0-9]{12})/;
            const match = overrideMap.isin.match(isinRegex);
            setIsin(match?.groups?.isin);
        }
        else {
            setIsin(undefined);
        }
        setShowOtherModal(true);
    }, []);
    React.useEffect(() => {
        addSubscription('/topic/newissue.data', updateWebSocket);
    }, [addSubscription, updateWebSocket]);
    React.useEffect(() => {
        setTransactionId(undefined);
        localStorage.removeItem('transactionId');
    }, [setTransactionId]);
    React.useEffect(() => {
        const showAmendments = !(localStorage.getItem('show-amendments') === 'false');
        setOverviewClass(showAmendments ? 'admin' : undefined);
    }, []);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'currencies');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setCurrencyOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/constant/map');
            url.searchParams.append('field', 'ESG');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                const downloadedEsgOptions = Object.entries(body).map(([key, v]) => ({ label: v, value: key })).sort(optionSort);
                setEsgOptions(downloadedEsgOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/constant/map');
            url.searchParams.append('field', 'STATUS_NOTES');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                const downloadedStatusOptions = Object.entries(body).map(([key, value]) => ({ label: value, value: key })).sort(optionSort);
                setStatusOfNotesOptions(downloadedStatusOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sector');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setSectorOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'region');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setRegionOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'status');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setStatusOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'type');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading options');
                setTypeOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    return (React.createElement(Card, { className: overviewClass, style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "New Issues"),
            React.createElement("div", null,
                React.createElement(Button, { name: "overview", onClick: () => updateView('TABLE'), size: "small" }, "TRANSACTIONS"))),
        trancheWrapper !== undefined && showMetaModal
            ? (React.createElement(MetaModal, { hideModal: () => setShowMetaModal(false), setTranche: setTrancheWrapper, tranche: trancheWrapper, updateTable: updateTable })) : undefined,
        isin !== undefined && showOtherModal
            ? (React.createElement(OtherSourcesModal, { hideModal: () => setShowOtherModal(false), show: showOtherModal, title: "Other sources", isin: isin })) : undefined,
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', overflow: 'auto', height: '100%', justifyContent: 'space-between',
            } },
            trancheWrapper !== undefined && showDetailsModal ? (React.createElement(MesssagesModal, { hideModal: () => setShowDetailsModal(false), show: showDetailsModal, splitScreen: true, transactionId: trancheWrapper.transaction.id })) : undefined,
            React.createElement(OverviewTable, { addTranche: addTranche, confirm: confirm, details: details, currencyOptions: currencyOptions, esgOptions: esgOptions, manageMeta: manageMeta, meta: meta, regionOptions: regionOptions, removeOverrides: removeOverrides, sectorOptions: sectorOptions, seeUpdate: seeUpdate, statusOfNotesOptions: statusOfNotesOptions, statusOptions: statusOptions, typeOptions: typeOptions, viewOthers: viewOthers }))));
};
export default OverviewBody;
