import { printEquivalentAmount, realDateSort, realNumberSort } from 'utils/table';
import cfiParser from '../../utils/cfiParser';
import securitiesTableActionCell from '../../components/results/securitiesTableActionCell';
class AnnaSecurity {
    cdsNameLei;
    cfi;
    cfiCode;
    entryDate;
    eqvamount;
    exercisePrice;
    exercisePriceCurrency;
    fisn;
    instrumentCategory;
    interestFirstPaymentDate;
    interestFrequency;
    interestRate;
    interestType;
    isin;
    issueCurrency;
    issueDescription;
    issuerHqCity;
    issuerHqCountry;
    issuerHqPostalCode;
    issuerLei;
    issuerNameLong;
    issuerNameShort;
    issuerRegAddress1;
    issuerRegAddress2;
    issuerRegCity;
    issuerRegCountry;
    issuerRegPostalCode;
    issuerRegState;
    maturityCode;
    maturityDate;
    mic;
    modifiedDate;
    nominalValue;
    operator;
    status;
    tags;
    getAmount = () => (this.nominalValue !== undefined ? AnnaSecurity.parseNumber(this.nominalValue) : undefined);
    getMaturity = () => AnnaSecurity.parseDate(this.maturityDate);
    static create = (base, companies) => {
        const security = Object.assign(new AnnaSecurity(), base);
        security.cfi = cfiParser(security.cfiCode);
        if (companies.length > 0) {
            const company = companies.find(security.companyMatcher);
            security.tags = company !== undefined ? company.tags : [];
        }
        else {
            security.tags = [];
        }
        return security;
    };
    static parseDate = (date) => {
        const regex = /(?<month>[0-9]{2})\/(?<day>[0-9]{2})\/(?<year>[0-9]{4})$/;
        const match = regex.exec(date);
        return match?.groups !== undefined ? new Date(parseInt(match.groups.year, 10), parseInt(match.groups.month, 10) - 1, parseInt(match.groups.day, 10)) : 'Unknown';
    };
    static parseNumber = (number) => parseFloat(number);
    getCfi = () => this.cfiCode;
    getCurrency = () => this.issueCurrency;
    getIssuername = () => this.issuerNameLong;
    getShortname = () => this.issuerNameLong;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getSort = (s0, s1) => 0;
    getProperties = () => ([
        { label: 'Issuer', value: this.issuerNameLong },
        { label: 'Currency', value: this.issueCurrency },
        { label: 'Amount', value: this.nominalValue.toString() },
        { label: 'Maturity', value: this.maturityDate.toLocaleString() },
        { label: 'Category', value: this.cfi.category },
        { label: 'Group', value: this.cfi.group },
        { label: 'Attribute1', value: this.cfi.attribute1 },
        { label: 'Attribute2', value: this.cfi.attribute2 },
        { label: 'Attribute3', value: this.cfi.attribute3 },
        { label: 'CDS Name LEI', value: this.cdsNameLei },
        { label: 'Entry Date', value: this.entryDate.toLocaleString() },
        { label: 'Exercise Price', value: this.exercisePrice },
        { label: 'Exercise Price Currency', value: this.exercisePriceCurrency },
        { label: 'FISN', value: this.fisn },
        { label: 'Instrument Category', value: this.instrumentCategory },
        { label: 'Interest First Payment Date', value: this.interestFirstPaymentDate },
        { label: 'Interest Frequency', value: this.interestFrequency },
        { label: 'Interest Rate', value: this.interestRate.toString() },
        { label: 'Interest Type', value: this.interestType },
        { label: 'ISIN', value: this.isin },
        { label: 'Issue Description', value: this.issueDescription },
        { label: 'HQ City', value: this.issuerHqCity },
        { label: 'HQ Country', value: this.issuerHqCountry },
        { label: 'HQ Postal Code', value: this.issuerHqPostalCode },
        { label: 'LEI', value: this.issuerLei },
        { label: 'Issuer Name Short', value: this.issuerNameShort },
        { label: 'Reg Address 1', value: this.issuerRegAddress1 },
        { label: 'Reg Address 2', value: this.issuerRegAddress2 },
        { label: 'Reg City', value: this.issuerRegCity },
        { label: 'Reg Country', value: this.issuerRegCountry },
        { label: 'Reg Postal Code', value: this.issuerRegPostalCode },
        { label: 'Reg State', value: this.issuerRegState },
        { label: 'Maturity Code', value: this.maturityCode },
        { label: 'MIC', value: this.mic },
        { label: 'Modified Date', value: this.modifiedDate.toLocaleString() },
        { label: 'Operator', value: this.operator },
        { label: 'Status', value: this.status },
    ]);
    static columnsSpreadSheet = [
        { accessorFn: (s) => s.issuerNameLong, header: 'Issuer' },
        { accessorFn: (s) => s.issueCurrency, header: 'Currency' },
        { accessorFn: (s) => s.nominalValue, header: 'Amount' },
        { accessorFn: (s) => s.maturityDate, header: 'Maturity' },
        { accessorFn: (s) => s.cfi.category, header: 'Category' },
        { accessorFn: (s) => s.cfi.group, header: 'Group' },
        { accessorFn: (s) => s.cfi.attribute1, header: 'Attribute1' },
        { accessorFn: (s) => s.cfi.attribute2, header: 'Attribute2' },
        { accessorFn: (s) => s.cfi.attribute3, header: 'Attribute3' },
        { accessorFn: (s) => s.cfi.attribute4, header: 'Attribute4' },
        { accessorFn: (s) => s.cdsNameLei, header: 'CDS Name LEI' },
        { accessorFn: (s) => s.entryDate, header: 'Entry Date' },
        { accessorFn: (s) => s.exercisePrice, header: 'Exercise Price' },
        { accessorFn: (s) => s.exercisePriceCurrency, header: 'Exercise Price Currency' },
        { accessorFn: (s) => s.fisn, header: 'FISN' },
        { accessorFn: (s) => s.instrumentCategory, header: 'Instrument Category' },
        { accessorFn: (s) => s.interestFirstPaymentDate, header: 'Interest First Payment Date' },
        { accessorFn: (s) => s.interestFrequency, header: 'Interest Frequency' },
        { accessorFn: (s) => s.interestRate, header: 'Interest Rate' },
        { accessorFn: (s) => s.interestType, header: 'Interest Type' },
        { accessorFn: (s) => s.isin, header: 'ISIN' },
        { accessorFn: (s) => s.issueDescription, header: 'Issue Description' },
        { accessorFn: (s) => s.issuerHqCity, header: 'HQ City' },
        { accessorFn: (s) => s.issuerHqCountry, header: 'HQ Country' },
        { accessorFn: (s) => s.issuerHqPostalCode, header: 'HQ Postal Code' },
        { accessorFn: (s) => s.issuerLei, header: 'LEI' },
        { accessorFn: (s) => s.issuerNameShort, header: 'Issuer Name Short' },
        { accessorFn: (s) => s.issuerRegAddress1, header: 'Reg Address 1' },
        { accessorFn: (s) => s.issuerRegAddress2, header: 'Reg Address 2' },
        { accessorFn: (s) => s.issuerRegCity, header: 'Reg City' },
        { accessorFn: (s) => s.issuerRegCountry, header: 'Reg Country' },
        { accessorFn: (s) => s.issuerRegPostalCode, header: 'Reg Postal Code' },
        { accessorFn: (s) => s.issuerRegState, header: 'Reg State' },
        { accessorFn: (s) => s.maturityCode, header: 'Maturity Code' },
        { accessorFn: (s) => s.mic, header: 'MIC' },
        { accessorFn: (s) => s.modifiedDate, header: 'Modified Date' },
        { accessorFn: (s) => s.operator, header: 'Operator' },
        { accessorFn: (s) => s.status, header: 'Status' },
    ];
    static realNumberSort({ original: data1 }, { original: data2 }) {
        const number1 = data1.interestRate || Number.NEGATIVE_INFINITY;
        const number2 = data2.interestRate || Number.NEGATIVE_INFINITY;
        if (number1 > number2) {
            return 1;
        }
        if (number2 > number1) {
            return -1;
        }
        return 0;
    }
    static columns(currencies, setModalContent) {
        return [
            { cell: securitiesTableActionCell(setModalContent), id: 'actions' },
            { accessorKey: 'issuerNameLong', header: 'Name' },
            { accessorKey: 'issueCurrency', header: 'Currency' },
            {
                accessorFn: (data) => printEquivalentAmount(data.issueCurrency, Number.parseInt(data.nominalValue, 10), currencies),
                header: `Eqv in ${localStorage.getItem('baseCurrency') ?? 'EUR'}`,
                id: 'eqvAmount',
                sortingFn: (d0, d1) => realNumberSort(d0.original.nominalValue, d1.original.nominalValue),
            },
            {
                accessorFn: (data) => (new Intl.NumberFormat()).format(Number.parseInt(data.nominalValue, 10)),
                header: 'Amount',
                id: 'nominalValue',
                sortingFn: (d0, d1) => realNumberSort(d0.original.nominalValue, d1.original.nominalValue),
            },
            {
                accessorFn: (data) => data.maturityDate,
                header: 'Maturity Date',
                id: 'maturityDate',
                sortingFn: (d0, d1) => realDateSort(d0.original.maturityDate, d1.original.maturityDate),
            },
            {
                accessorFn: (data) => (data.entryDate),
                header: 'Entry Date',
                id: 'entryDate',
                sortingFn: (d0, d1) => realDateSort(d0.original.entryDate, d1.original.entryDate),
            },
            {
                accessorKey: 'interestRate',
                header: 'Interest Rate',
                sortingFn: (d0, d1) => realNumberSort(d0.original.interestRate, d1.original.interestRate),
            },
            { accessorKey: 'interestType', header: 'Interest Type' },
            { accessorKey: 'instrumentCategory', header: 'Security Type' },
            { accessorKey: 'issuerRegCity', header: 'City' },
            { accessorKey: 'issuerRegCountry', header: 'Country' },
            { accessorKey: 'cfi.category', header: 'Category' },
            { accessorKey: 'cfi.group', header: 'Group' },
            { accessorKey: 'cfi.attribute1', header: 'Attribute 1' },
            { accessorKey: 'cfi.attribute2', header: 'Attribute 2' },
            { accessorKey: 'cfi.attribute3', header: 'Attribute 3' },
            { accessorKey: 'cfi.attribute4', header: 'Attribute 4' },
            { accessorKey: 'tags', header: 'Tags' },
        ];
    }
    companyMatcher = (c) => c.company.nameLong === this.issuerNameLong
        && c.company.reg.city === this.issuerRegCity && c.company.reg.country === this.issuerRegCountry
        && c.company.reg.postalCode === this.issuerRegPostalCode
        && c.company.reg.state === this.issuerRegState;
    markupMap = () => this;
}
export default AnnaSecurity;
