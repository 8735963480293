import * as React from 'react';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
const IFRStreamItem = ({ hideStory, story, setStory }) => {
    const onClick = React.useCallback(() => {
        setStory();
    }, [setStory]);
    const onClickHide = React.useCallback(() => {
        hideStory();
    }, [hideStory]);
    const timeString = React.useMemo(() => {
        if (story?.info?.date !== undefined) {
            return new Date(story.info.date).toLocaleString();
        }
        return 'NONE';
    }, [story?.info?.date]);
    return (React.createElement("div", { style: {
            display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem', width: '100%',
        } },
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { marginRight: '1rem' } },
                React.createElement(Button, { onClick: onClick, size: "small" },
                    React.createElement(Icon, { iconName: "plus" }))),
            React.createElement("p", { style: { fontWeight: story.data.assets.includes('nim') ? 'bold' : 'normal' } },
                timeString,
                ' ',
                "-",
                ' ',
                story.data.title)),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", null,
                React.createElement("p", { style: { fontSize: 'small' } }, story.info?.region)),
            React.createElement("div", null,
                React.createElement(Button, { onClick: onClickHide, size: "small", variant: "tertiary" },
                    React.createElement(Icon, { iconName: "delete" }))))));
};
export default IFRStreamItem;
