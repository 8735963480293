import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import AppViewContext from 'contexts/AppViewContext';
import NavigationContext from 'contexts/NavigationContext';
import NavigationService from 'services/NavigationService';
import { App, appFromModule } from 'utils/appUtils';
import Main from '../../../site/pages/main-page/Main';
import PageWrapper from 'components/PageWrapper';
import './generic.css';
const LocationRouter = () => {
    const [imitate, setImitate] = React.useState(false);
    const [appView, setAppView] = React.useState();
    const [tab, setTab] = React.useState();
    const [view, setView] = React.useState();
    const [availableTabs, setAvailableTabs] = React.useState();
    const auth = useAuth();
    const navigationService = React.useMemo(() => new NavigationService({
        appView,
        setAppView,
        tab,
        setTab,
        view,
        setView,
        availableTabs,
        setAvailableTabs,
        user: auth.user,
        storage: localStorage,
    }), [auth.user, appView, availableTabs, tab, view]);
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('app') && params.get('app') === 'CMO') {
            navigationService.updateAppView(App.SYND);
        }
    }, [navigationService]);
    const appViewValue = React.useMemo(() => ({
        imitate: imitate ?? false,
        setImitate,
    }), [imitate, setImitate]);
    React.useEffect(() => {
        if (auth.user != null) {
            const claims = auth.user.profile;
            const moduleNames = Object.keys(claims.authorityMap);
            if (moduleNames.length === 1) {
                const singleApp = appFromModule(moduleNames[0]);
                setAppView(singleApp);
            }
        }
    }, [auth.user]);
    return (React.createElement(NavigationContext.Provider, { value: navigationService },
        React.createElement(AppViewContext.Provider, { value: appViewValue }, appView === undefined ? React.createElement(Main, null) : React.createElement(PageWrapper, null))));
};
export default LocationRouter;
