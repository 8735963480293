import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import { getOptions, getOptionsMulti } from 'utils/change';
import './AnnaForm.css';
import AnnaSearch from '../../../models/search/AnnaSearch';
import options from './anna';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import TimeRange from '../../../models/TimeRange';
const AnnaForm = ({ search, setSearch }) => {
    const [state, setState] = React.useState(search?.query?.isinValue !== '' ? 'isin' : 'other');
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        isinValue: Validators.Pattern(/^[A-Z0-9\\*]{12}$/),
    }), []);
    const testInput = React.useCallback((value) => {
        if (typeof value === 'undefined' || value === null) {
            return false;
        }
        // TODO #278 ANNA Form bug null value
        return value.length > 0;
    }, []);
    const testMulti = React.useCallback((value) => {
        if (value?.values == null) {
            return false;
        }
        return value.values.length > 0 && !value.values.includes(value.first);
    }, []);
    const testDate = React.useCallback((timeRange) => TimeRange.isEmpty(timeRange), []);
    const testFilter = React.useCallback((filter) => {
        if (filter?.values == null) {
            return false;
        }
        return filter.values.length > 0;
    }, []);
    const issueCurrencyOptions = React.useMemo(() => options
        .issueCurrency.map((o) => ({ ...o, label: o.value })), []);
    const countryOptions = React.useMemo(() => options
        .country.map((o) => {
        const label = o.label === ' All' ? ' All' : o.label.substring(4);
        return { ...o, label };
    }), []);
    const instrumentCategoryOptions = React.useMemo(() => options
        .instrumentCategory.map((o) => {
        const label = o.label === ' All' ? ' All' : o.label.substring(0, o.label.length - 5);
        return { ...o, label };
    }), []);
    React.useEffect(() => {
        const { query } = search;
        if (testInput(query.isinValue)) {
            setState('isin');
        }
        else {
            const inputs = [query.entityName, query.issueDescription];
            const multis = [
                { first: options.annaStatus[0].value, values: query.annaStatus },
                { first: countryOptions[0].value, values: query.country },
                {
                    first: instrumentCategoryOptions[0].value,
                    values: query.instrumentCategory,
                },
                { first: issueCurrencyOptions[0].value, values: query.issueCurrency }
            ];
            const dates = [query.maturity, query.update];
            const filter = [search.filter.tags];
            setState(multis.some(testMulti) || inputs.some(testInput) || dates.some(testDate) || filter.some(testFilter) ? 'other' : 'none');
        }
    }, [countryOptions, search, instrumentCategoryOptions, issueCurrencyOptions, testDate, testFilter,
        testInput, testMulti]);
    // TODO #325 #324 Fix validation ANNA Form
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    const changeQueryDropdown = (name) => (event) => {
        const newSearch = Object.assign(new AnnaSearch(), search);
        newSearch.query[name] = event.target.value.value;
        setSearch(newSearch);
    };
    const changeQueryMultiDropdown = (name) => (event) => {
        const { value } = event.target;
        const newSearch = Object.assign(new AnnaSearch(), search);
        const newValue = Array.isArray(value) ? value.map((v) => v.value) : [value.value];
        newSearch.query[name] = newValue;
        setSearch(newSearch);
    };
    const changeQueryInput = (name) => (event) => {
        const newSearch = Object.assign(new AnnaSearch(), search);
        newSearch.query[name] = event.target.value;
        setSearch(newSearch);
    };
    const changeQueryTimeRange = (name) => (event) => {
        const newSearch = Object.assign(new AnnaSearch(), search);
        newSearch.query[name] = event.target.value;
        setSearch(newSearch);
    };
    const changeFilterItem = (name) => (event) => {
        const newSearch = Object.assign(new AnnaSearch(), search);
        newSearch.filter[name] = event.target.value;
        setSearch(newSearch);
    };
    const list = [
        React.createElement(Dropdown, { className: "select-addon select-contains", isTypeAheadEnabled: false, key: "entity-name-option2", label: "", onChange: changeQueryDropdown('entityNameOpton2'), options: options.entityNameOpton2, value: getOptions(search.query.entityNameOpton2, options.entityNameOpton2) }),
        React.createElement(Dropdown, { className: "select-addon select-length", isTypeAheadEnabled: false, key: "entity-name-option1", label: "", onChange: changeQueryDropdown('entityNameOpton1'), options: options.entityNameOpton1, value: getOptions(search.query.entityNameOpton1, options.entityNameOpton1) }),
    ];
    React.useEffect(() => {
        const singleFields = ['entityNameOpton1', 'entityNameOpton2', 'isinType', 'issueDesOptions'];
        const multiFields = ['annaStatus', 'country', 'instrumentCategory', 'issueCurrency'];
        let changed = false;
        const searchCopy = Object.assign(new AnnaSearch(), search);
        singleFields.forEach((field) => {
            if (!search.query[field]) {
                searchCopy.query[field] = options[field][0].value;
                changed = true;
            }
        });
        multiFields.forEach((field) => {
            const allValues = options[field].map((o) => o.value);
            const firstValue = allValues.shift();
            // if it is empty, fill it with the first value
            if (firstValue !== undefined && (search.query[field] === undefined || search.query[field].length === 0)) {
                searchCopy.query[field] = [firstValue];
                changed = true;
            }
            // if it includes both the first and others, change id
            if (firstValue !== undefined && searchCopy.query[field].some((v) => allValues.includes(v)) && searchCopy.query[field].includes(firstValue)) {
                if (field in searchCopy.query) {
                    searchCopy.query[field] = searchCopy.query[field].filter((v) => v !== firstValue);
                    changed = true;
                }
            }
        });
        if (changed) {
            setSearch(searchCopy);
        }
    }, [search, setSearch]);
    return (React.createElement("div", { className: "base-form" },
        state !== 'other'
            ? (React.createElement(Validation, { errorMessage: { pattern: 'ISIN should have 12 characters' }, onValidationChanged: onValidationChanged('isinValue'), validator: validators.isinValue },
                React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "ISIN", onChange: changeQueryInput('isinValue'), rightDecorators: (React.createElement(Dropdown, { className: "select-addon select-isin-type", isTypeAheadEnabled: false, label: "", onChange: changeQueryDropdown('isinType'), options: options.isinType, value: getOptions(search.query.isinType, options.isinType) })), tooltip: 'Search by ISIN, either of the security itself, or of securities with that ISIN as underlying.&nbsp;&nbsp;Note: you can use * as wildcard.', value: search.query.isinValue || '' }))) : undefined,
        state !== 'isin' ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "other-form-first" },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Status", onChange: changeQueryMultiDropdown('annaStatus'), options: options.annaStatus, value: getOptionsMulti(search.query.annaStatus, options.annaStatus) })),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Country", onChange: changeQueryMultiDropdown('country'), options: countryOptions, value: getOptionsMulti(search.query.country, countryOptions) }),
            React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "Entity Name", onChange: changeQueryInput('entityName'), rightDecorators: list, value: search.query.entityName || '' }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Category", onChange: changeQueryMultiDropdown('instrumentCategory'), options: instrumentCategoryOptions, value: getOptionsMulti(search.query.instrumentCategory, instrumentCategoryOptions) }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Currency", onChange: changeQueryMultiDropdown('issueCurrency'), options: issueCurrencyOptions, value: getOptionsMulti(search.query.issueCurrency, issueCurrencyOptions) }),
            React.createElement(TextField, { className: "input-with-addon addon-group-reverse", label: "Issue Description", onChange: changeQueryInput('issueDescription'), rightDecorators: (React.createElement(Dropdown, { className: "select-addon select-contains", isTypeAheadEnabled: false, label: "", onChange: changeQueryDropdown('issueDesOptions'), options: options.issueDesOptions, value: getOptions(search.query.issueDesOptions, options.issueDesOptions) })), value: search.query.issueDescription || '' }),
            React.createElement(TimeRangeInput, { defaultPeriod: "Y", label: "Maturity", onChange: changeQueryTimeRange('maturity'), value: search.query.maturity }),
            React.createElement(TimeRangeInput, { defaultPeriod: "D", label: "Last Update", onChange: changeQueryTimeRange('update'), value: search.query.update }),
            React.createElement(RemoteFilterItem, { label: "Tags", onChange: changeFilterItem('tags'), path: "/nit/companies/tags/options/anna", value: search.filter.tags }))) : ''));
};
export default AnnaForm;
