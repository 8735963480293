import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import PermissionGroup from './PermissionGroup';
import './PermissionModal.css';
const PermissionsModal = ({ hideModal, onChange, value, }) => {
    const [permissions, setPermissions] = React.useState(value ?? { user: { read: false, write: false }, group: { read: false, write: false }, other: { read: false, write: false } });
    const setValue = React.useCallback((name) => (value2) => {
        setPermissions({ ...permissions, [name]: value2 });
    }, []);
    React.useEffect(() => {
        onChange(permissions);
    }, [onChange, permissions]);
    const onClose = () => {
        hideModal();
    };
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Permissions")),
        React.createElement(ModalBody, { className: "co-permissions-group" },
            React.createElement(PermissionGroup, { label: "User", value: permissions.user, setValue: setValue('user') }),
            React.createElement(PermissionGroup, { label: "Company", value: permissions.group, setValue: setValue('group') }),
            React.createElement(PermissionGroup, { label: "Other", value: permissions.other, setValue: setValue('other') }))));
};
export default PermissionsModal;
