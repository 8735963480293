import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import SyndicateContext from '../../../context/SyndicateContext';
const UpdatePagination = ({ callback, disabled, numberOfUpdates }) => {
    const { isStart, updateIndex, updateId } = React.useContext(SyndicateContext);
    const clickStart = React.useCallback(() => {
        callback();
        updateIndex(0);
    }, [callback, updateIndex]);
    const clickEnd = React.useCallback(() => {
        callback();
        updateIndex(numberOfUpdates - 1);
    }, [callback, numberOfUpdates, updateIndex]);
    const clickPrevious = React.useCallback(() => {
        if (updateId?.index !== undefined) {
            callback();
            updateIndex(updateId.index - 1);
        }
    }, [callback, updateId, updateIndex]);
    const clickNext = React.useCallback(() => {
        if (updateId?.index !== undefined) {
            callback();
            updateIndex(updateId.index + 1);
        }
    }, [callback, updateId, updateIndex]);
    if (updateId?.index === undefined) {
        return undefined;
    }
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(Button, { disabled: disabled || isStart(), iconButton: true, name: "start", onClick: clickStart, size: "small" },
            React.createElement(Icon, { iconName: "chevron-left" })),
        React.createElement(Button, { disabled: disabled || isStart(), iconButton: true, name: "previous", onClick: clickPrevious, size: "small", style: { marginRight: '0.25rem' } }, "<"),
        React.createElement("div", null,
            updateId.index + 1,
            ' ',
            "out of",
            ' ',
            numberOfUpdates),
        React.createElement(Button, { disabled: disabled || updateId.index === numberOfUpdates - 1, iconButton: true, name: "next", onClick: clickNext, size: "small", style: { marginLeft: '0.25rem' } }, ">"),
        React.createElement(Button, { disabled: disabled || updateId.index === numberOfUpdates - 1, iconButton: true, name: "end", onClick: clickEnd, size: "small" },
            React.createElement(Icon, { iconName: "chevron-right" }))));
};
export default UpdatePagination;
