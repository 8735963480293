import { Icon, TextField } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
// A debounced input react component
const DebouncedInput = ({ className, clear, debounce = 500, onChange, placeholder, value: initialValue, }) => {
    const [isDisabled, setDisabled] = React.useState(false);
    const [value, setValue] = React.useState(initialValue);
    const textRef = React.useRef(null);
    React.useEffect(() => {
        setValue(initialValue);
        if (textRef.current !== null) {
            textRef.current.focus();
        }
    }, [initialValue]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);
        return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);
    const onClick = React.useCallback(() => {
        setDisabled(true);
        if (clear !== undefined) {
            clear();
        }
    }, [clear]);
    return (React.createElement(TextField, { className: className, disabled: isDisabled, onChange: (e) => setValue(e.target.value), placeholder: placeholder, ref: textRef, rightDecorators: (React.createElement(Icon, { iconName: "erase", onClick: onClick })), value: value.toString() }));
};
export default DebouncedInput;
