import { Loader } from '@symphony-ui/uitoolkit-components';
import pretty from 'pretty';
import * as React from 'react';
const OriginalHTML = ({ className, hasError, html, isLoading, isVisible, prettify, setRef, }) => {
    const gridRef = React.useRef(null);
    const updateHtml = React.useCallback(() => {
        if (html !== undefined) {
            const pre = document.createElement('pre');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const text = prettify ? pretty(html, { ocd: false }) : html;
            let textNodeString = '';
            let numberOfCharacters = 0;
            let inTag = true;
            let firstNodeOfLine = null;
            text.split('').forEach((character) => {
                if (character === '<') {
                    inTag = true;
                }
                else if (character === '>') {
                    inTag = false;
                }
                else if (!inTag && character !== '\n') {
                    numberOfCharacters += 1;
                }
                if (character === ' ') {
                    const textNode = document.createTextNode(textNodeString);
                    if (firstNodeOfLine === null && textNodeString.length > 0 && textNodeString !== '\n')
                        firstNodeOfLine = textNode;
                    pre.appendChild(textNode);
                    textNodeString = '';
                    const span = document.createElement('span');
                    span.className = 'co-special-char';
                    span.innerText = '·';
                    pre.appendChild(span);
                    if (firstNodeOfLine === null)
                        firstNodeOfLine = span;
                }
                else if (character === '\r') {
                    const textNode = document.createTextNode(textNodeString);
                    pre.appendChild(textNode);
                    textNodeString = '';
                    const span = document.createElement('span');
                    span.className = 'co-special-char';
                    span.innerText = '␍';
                    pre.appendChild(span);
                    textNodeString += character;
                    if (firstNodeOfLine === null)
                        firstNodeOfLine = span;
                }
                else if (character === '\t') {
                    const textNode = document.createTextNode(textNodeString);
                    pre.appendChild(textNode);
                    textNodeString = '';
                    const span = document.createElement('span');
                    span.className = 'co-special-char';
                    span.innerText = '⇥';
                    pre.appendChild(span);
                    textNodeString += character;
                    if (firstNodeOfLine === null)
                        firstNodeOfLine = span;
                }
                else if (character === '\n') {
                    const textNode = document.createTextNode(textNodeString);
                    pre.appendChild(textNode);
                    textNodeString = '';
                    const span = document.createElement('span');
                    span.className = 'co-special-char';
                    span.innerText = '↵';
                    pre.appendChild(span);
                    textNodeString += character;
                    const numberText = document.createElement('span');
                    numberText.innerText = `[${numberOfCharacters}]`;
                    numberText.className = 'co-count';
                    inTag = true;
                    if (firstNodeOfLine === null) {
                        pre.appendChild(numberText);
                    }
                    else {
                        firstNodeOfLine.before(numberText);
                    }
                    numberOfCharacters = 0;
                    firstNodeOfLine = null;
                }
                else {
                    textNodeString += character;
                }
            });
            if (textNodeString !== '') {
                const textNode = document.createTextNode(textNodeString);
                pre.appendChild(textNode);
            }
            while (gridRef.current?.children?.item(0) !== null && gridRef.current?.children !== undefined) {
                const t = gridRef.current.children.item(0);
                if (t !== null) {
                    t.remove();
                }
            }
            if (gridRef.current != null) {
                gridRef.current.appendChild(pre);
            }
        }
    }, [html, prettify]);
    React.useEffect(() => {
        if (gridRef.current != null) {
            updateHtml();
        }
        else {
            setTimeout(() => updateHtml(), 100);
        }
    }, [updateHtml]);
    React.useEffect(() => {
        if (isVisible && gridRef.current !== null && setRef !== undefined) {
            setRef(gridRef.current);
        }
    });
    if (hasError) {
        return React.createElement("div", null, "Error in Content download");
    }
    if (isLoading) {
        return React.createElement("div", null,
            React.createElement(Loader, { className: "co-loader", type: "spinner" }));
    }
    return (React.createElement("div", { className: `${className}${isVisible ? ' co-visible' : ''}`, ref: gridRef }));
};
export default OriginalHTML;
