import { Button, Card } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TickerPicker = ({ tickers, saveTicker }) => {
    const onClick = React.useCallback((ticker) => () => {
        saveTicker(ticker);
    }, [saveTicker]);
    return tickers.length === 0
        ? (React.createElement("div", { style: {
                fontStyle: 'italic', paddingLeft: '0.5rem', marginBottom: '0.5rem', color: 'darkred',
            } }, "None found for LEI")) : (React.createElement(Card, null, tickers.map((t) => (React.createElement(Button, { key: t, onClick: onClick(t) }, t)))));
};
export default TickerPicker;
