import * as React from 'react';
import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const UserTable = ({ create, deleteFn, edit, hasError, isLoading, users, }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            enableColumnFilter: false,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'displayName',
            enableColumnFilter: false,
            header: 'Display Name',
        }, {
            accessorKey: 'firstName',
            enableColumnFilter: false,
            header: 'First Name',
        }, {
            accessorKey: 'lastName',
            enableColumnFilter: false,
            header: 'Last Name',
        }, {
            accessorKey: 'company',
            enableColumnFilter: false,
            header: 'Company',
        }, {
            accessorKey: 'emailAddress',
            enableColumnFilter: false,
            header: 'Email Address',
        }, {
            accessorKey: 'division',
            enableColumnFilter: false,
            header: 'Division',
        }, {
            accessorKey: 'department',
            enableColumnFilter: false,
            header: 'Department',
        }, {
            accessorKey: 'jobFunction',
            enableColumnFilter: false,
            header: 'Job Function',
        }, {
            accessorKey: 'location',
            enableColumnFilter: false,
            header: 'Location',
        }, {
            accessorKey: 'mobilePhoneNumber',
            enableColumnFilter: false,
            header: 'Phone (Mobile)',
        }, {
            accessorKey: 'workPhoneNumber',
            enableColumnFilter: false,
            header: 'Phone (Work)',
        },
    ], [deleteFn, edit]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Users")),
        React.createElement(CustomTable, { actionElements: [
                (React.createElement(Button, { disabled: hasError, onClick: create, size: "small" }, "CREATE NEW"))
            ], columns: columns, data: users ?? [], emptyMessage: "You have no users yet", errorMessage: "Error downloading users", hasError: hasError, isLoading: isLoading, name: "user" })));
};
export default UserTable;
