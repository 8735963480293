import * as React from 'react';
import CustomTable from '../../core/components/custom-table/CustomTable';
import { BMEColumns, BORSAColumns, EuronextColumns, HKSEColumns, LSEColumns, NASDAQColumns, NYSEColumns, XETRAColumns, } from './exchanges';
const IpoExchangeTable = ({ emptyMessage, errorMessage, exchange, ipos, hasError, isLoading, }) => {
    switch (exchange) {
        case 'BME':
            return (React.createElement(CustomTable, { columns: BMEColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "bmeIpos" }));
        case 'BORSA':
            return (React.createElement(CustomTable, { columns: BORSAColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "euronextIpos" }));
        case 'EURONEXT':
            return (React.createElement(CustomTable, { columns: EuronextColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "euronextIpos" }));
        case 'HKSE':
            return (React.createElement(CustomTable, { columns: HKSEColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "hkseIpos" }));
        case 'LSE':
            return (React.createElement(CustomTable, { columns: LSEColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "lseIpos" }));
        case 'NASDAQ':
            return (React.createElement(CustomTable, { columns: NASDAQColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "nasdaqIpos" }));
        case 'NYSE':
            return (React.createElement(CustomTable, { columns: NYSEColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "nyseIpos" }));
        case 'XETRA':
            return (React.createElement(CustomTable, { columns: XETRAColumns, data: ipos, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "xetraIpos" }));
        default:
            return undefined;
    }
};
export default IpoExchangeTable;
