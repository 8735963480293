import { printEquivalentAmount, realDateSort, realNumberSort } from 'utils/table';
import cfiParser from '../../utils/cfiParser';
import securitiesTableActionCell from '../../components/results/securitiesTableActionCell';
class EuroclearSecurity {
    cfi; // created later
    cficode;
    codecreationdate;
    commondepositarylabel;
    currencylabel;
    firstclosingdate;
    isin;
    issuercategory;
    issuercityname;
    issuercountryname;
    issuername;
    lastupdate;
    legalform;
    marketlabel;
    maturitydate;
    minimaltradevalueamount;
    nextintrestrate;
    nexttyperate;
    securitytypelabel;
    shortname;
    totalissuedamount;
    tags;
    constructor(response, companies) {
        this.cficode = response.cficode;
        this.codecreationdate = response.codecreationdate;
        this.commondepositarylabel = response.commondepositarylabel;
        this.currencylabel = response.currencylabel;
        this.firstclosingdate = response.firstclosingdate;
        this.isin = response.isin;
        this.issuercategory = response.issuercategory;
        this.issuercityname = response.issuercityname;
        this.issuercountryname = response.issuercountryname;
        this.issuername = response.issuername;
        this.lastupdate = response.lastupdate;
        this.legalform = response.legalform;
        this.marketlabel = response.marketlabel;
        this.maturitydate = response.maturitydate;
        this.minimaltradevalueamount = response.minimaltradevalueamount;
        this.nextintrestrate = response.nextintrestrate;
        this.nexttyperate = response.nexttyperate;
        this.securitytypelabel = response.securitytypelabel;
        this.shortname = response.shortname;
        this.totalissuedamount = response.totalissuedamount;
        this.cfi = cfiParser(response.cficode);
        if (companies !== undefined) {
            const company = companies.find(this.companyMatcher);
            this.tags = company !== undefined ? company.tags : [];
        }
        else {
            this.tags = [];
        }
    }
    getAmount = () => EuroclearSecurity.parseNumber(this.totalissuedamount);
    getCfi = () => this.cficode;
    getCurrency = () => this.currencylabel;
    getIssuername = () => this.issuername;
    getLei = () => undefined;
    getMaturity = () => EuroclearSecurity.parseDate(this.maturitydate);
    getShortname = () => this.shortname;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getSort = (s0, s1) => 0;
    static columnsSpreadsheet = [
        { accessorFn: (s) => s.issuername, header: 'Issuer Name' },
        { accessorFn: (s) => s.isin, header: 'ISIN' },
        { accessorFn: (s) => s.currencylabel, header: 'Currency' },
        { accessorFn: (s) => s.totalissuedamount, header: 'Amount' },
        { accessorFn: (s) => s.firstclosingdate, header: 'First Closing Date' },
        { accessorFn: (s) => s.maturitydate, header: 'Maturity Date' },
        { accessorFn: (s) => s.codecreationdate, header: 'Code Creation Date' },
        { accessorFn: (s) => s.lastupdate, header: 'Last Update' },
        { accessorFn: (s) => s.issuercategory, header: 'Issuer Category' },
        { accessorFn: (s) => s.issuercityname, header: 'Issuer City' },
        { accessorFn: (s) => s.issuercountryname, header: 'Issuer Country' },
        { accessorFn: (s) => s.securitytypelabel, header: 'Security Type' },
        { accessorFn: (s) => s.nexttyperate, header: 'Rate Type' },
        { accessorFn: (s) => s.nextintrestrate, header: 'Rate' },
        { accessorFn: (s) => s.marketlabel, header: 'Market' },
        { accessorFn: (s) => s.minimaltradevalueamount, header: 'Min Trade Amount' },
        { accessorFn: (s) => s.cfi?.category, header: 'Category' },
        { accessorFn: (s) => s.cfi?.group, header: 'Group' },
        { accessorFn: (s) => s.cfi?.attribute1, header: 'Attribute1' },
        { accessorFn: (s) => s.cfi?.attribute2, header: 'Attribute2' },
        { accessorFn: (s) => s.cfi?.attribute3, header: 'Attribute3' },
        { accessorFn: (s) => s.cfi?.attribute4, header: 'Attribute4' },
        { accessorFn: (s) => s.legalform, header: 'Legal Form' },
        { accessorFn: (s) => s.commondepositarylabel, header: 'Common Depositary' },
        { accessorFn: (s) => s.tags.join(', '), header: 'Tags' },
    ];
    getProperties = () => [{ label: 'issuer', value: this.issuername }];
    static parseDate(date) {
        if (date === 'Perpetual') {
            return date;
        }
        return new Date(date);
    }
    static parseNumber = (number) => parseInt(number.replace(/,/g, ''), 10);
    static parseRate = (number) => (number ? number.replace(/%/g, '') : number);
    static columns(currencies, setModalContent) {
        return [
            { cell: securitiesTableActionCell(setModalContent), id: 'actions', size: 20 },
            {
                accessorKey: 'issuername',
                enableResizing: true,
                header: 'Name',
                meta: { className: 'co-issuer' },
                size: 200,
            },
            { accessorKey: 'currencylabel', header: 'Currency' },
            {
                accessorFn: (data) => (new Intl.NumberFormat()).format(Number.parseInt(data.totalissuedamount, 10)),
                header: 'Amount',
                id: 'totalissuedamount',
                sortingFn: (d0, d1) => realNumberSort(d0.original.totalissuedamount, d1.original.totalissuedamount),
            },
            {
                accessorFn: (data) => printEquivalentAmount(data.currencylabel, Number.parseInt(data.totalissuedamount, 10), currencies),
                header: `Eqv in ${localStorage.getItem('baseCurrency') ?? 'EUR'}`,
                id: 'eqvAmount',
                sortingFn: (d0, d1) => realNumberSort(d0.original.totalissuedamount, d1.original.totalissuedamount),
            },
            {
                accessorKey: 'maturitydate',
                header: 'Maturity Date',
                id: 'maturitydate',
                sortingFn: (d0, d1) => realDateSort(d0.original.maturitydate, d1.original.maturitydate),
            }, {
                accessorKey: 'firstclosingdate',
                header: 'First Closing Date',
                id: 'firstclosingdate',
                sortingFn: (d0, d1) => realDateSort(d0.original.firstclosingdate, d1.original.firstclosingdate),
            },
            { accessorKey: 'nexttyperate', header: 'Rate Type' },
            { accessorKey: 'nextintrestrate', header: 'Interest Rate' },
            { accessorKey: 'securitytypelabel', header: 'Security Type' },
            { accessorKey: 'issuercityname', header: 'City' },
            { accessorKey: 'issuercountryname', header: 'Country' },
            { accessorKey: 'cfi.category', header: 'Category' },
            { accessorKey: 'cfi.group', header: 'Group' },
            { accessorKey: 'cfi.attribute1', header: 'Attribute 1' },
            { accessorKey: 'cfi.attribute2', header: 'Attribute 2' },
            { accessorKey: 'cfi.attribute3', header: 'Attribute 3' },
            { accessorKey: 'cfi.attribute4', header: 'Attribute 4' },
            { accessorKey: 'tags', header: 'Tags' },
        ];
    }
    companyMatcher = (c) => c.company.category === this.issuercategory
        && c.company.cityname === this.issuercityname
        && c.company.countryname === this.issuercountryname
        && c.company.name === this.issuername;
    markupMap = () => this;
}
export default EuroclearSecurity;
