import Company from '../../admin/models/Company';
class TransactionWrapper {
    company;
    confirm;
    id;
    isEditing;
    name;
    firstUpdate;
    lastUpdate;
    sector;
    regions;
    types;
    errors;
    constructor({ company, confirm, id, name, firstUpdate, lastUpdate, sector, regions, types, errors }) {
        this.confirm = confirm;
        this.id = id;
        this.name = name;
        this.firstUpdate = new Date(firstUpdate);
        this.lastUpdate = new Date(lastUpdate);
        this.sector = sector;
        this.regions = regions;
        this.types = types;
        this.errors = errors;
        if (company?.company) {
            company.company = new Company(company.company);
        }
        this.company = company;
    }
    setEditable(flag) {
        const newTranche = this.clone();
        newTranche.isEditing = flag;
        return newTranche;
    }
    #printDate(d) {
        try {
            return d.toISOString();
        }
        catch (error) {
            if (error instanceof Error) {
                return error.message;
            }
            return "ERROR: ";
        }
    }
    toResponse() {
        return {
            company: this.company,
            confirm: this.confirm,
            id: this.id,
            name: this.name,
            firstUpdate: this.#printDate(this.firstUpdate),
            lastUpdate: this.#printDate(this.lastUpdate),
            sector: this.sector,
            regions: this.regions,
            types: this.types,
        };
    }
    clone() {
        return new TransactionWrapper(this.toResponse());
    }
    setMeta({ companyName, regions, sector, types, }) {
        const updateTransaction = this.clone();
        if (updateTransaction.company === undefined) {
            updateTransaction.company = { name: companyName };
        }
        updateTransaction.sector = sector;
        updateTransaction.regions = regions;
        updateTransaction.types = types;
        return updateTransaction;
    }
    getMeta() {
        return {
            company: this.company,
            regions: this.regions,
            sector: this.sector,
            types: this.types,
        };
    }
    updateAlternativeNames(names) {
        const clone = this.clone();
        if (clone.company === undefined) {
            clone.company = {};
        }
        if (clone.company.company === undefined) {
            clone.company.company = new Company();
        }
        clone.company.company.alternativeNames = names;
        ;
        return clone;
    }
}
export default TransactionWrapper;
