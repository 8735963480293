import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import useOption from 'components/customHooks';
const ContributorFormModal = ({ contributor, onClose, save }) => {
    const [newContributor, setNewContributor] = React.useState(contributor.clone());
    const optionsCompany = useOption('/admin/companies/CONTRIBUTOR/options');
    const optionsRegion = useOption('/syndicate/overview/regions/options');
    const optionsSector = useOption('/syndicate/overview/sectors/options');
    const optionsTimeZone = useOption('/syndicate/contributor/timezones');
    const onClickSave = React.useCallback(() => {
        (async () => {
            await save(newContributor);
            onClose();
        })();
    }, [newContributor, onClose, save]);
    const onChangeSectors = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setNewContributor(newContributor.setSectors(newValue));
    }, [newContributor]);
    const onChangeRegions = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setNewContributor(newContributor.setRegions(newValue));
    }, [newContributor]);
    const onChangeCompany = React.useCallback((event) => {
        const newValue = event.target.value.value;
        setNewContributor(newContributor.setCountryId(newValue));
    }, [newContributor]);
    const onChangeTimeZone = React.useCallback((event) => {
        const newValue = event.target.value.value;
        setNewContributor(newContributor.setTimeZone(newValue));
    }, [newContributor]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalTitle, null,
            "Update ",
            contributor.emailAddress.personal + " - " + contributor.emailAddress.value),
        React.createElement(ModalBody, null,
            React.createElement(Dropdown, { isMultiSelect: true, label: "Sectors", onChange: onChangeSectors, options: optionsSector, value: optionsSector.filter(o => 'value' in o).filter(o => newContributor.sectors?.includes(o.value)) }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "Regions", onChange: onChangeRegions, options: optionsRegion, value: optionsRegion.filter(o => 'value' in o).filter(o => newContributor.regions?.includes(o.value)) }),
            React.createElement(Dropdown, { label: "Company", onChange: onChangeCompany, options: optionsCompany, value: optionsCompany.filter(o => 'value' in o).find(o => newContributor.companyId === o.value) }),
            React.createElement(Dropdown, { label: "Time Zone", onChange: onChangeTimeZone, options: optionsTimeZone, value: optionsTimeZone.filter(o => 'value' in o).find(o => newContributor.timeZone === o.value) })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default ContributorFormModal;
