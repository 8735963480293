import * as React from 'react';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import Workflow from '../../model/Workflow';
import WorkflowTable from './WorkflowTable';
import WorkflowForm from './WorkflowForm';
const Workflows = () => {
    const download = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [workflows, setWorkflows] = React.useState();
    const service = React.useMemo(() => new CrudService({ download, name: 'Workflows', path: '/mtn/workflows' }), [download]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            try {
                const body = await service.getPlus(abortController.signal);
                setWorkflows(body);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [service]);
    const create = React.useCallback(() => {
        setDetails(new Workflow());
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        (async () => {
            try {
                await service.delete(item);
                if (workflows !== undefined) {
                    const filteredWorkflows = workflows.filter((u) => u.id !== item.id);
                    setWorkflows(filteredWorkflows);
                }
            }
            catch (error) {
                //
            }
        })();
    }, [service, workflows]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setDetails(item);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        if (workflows !== undefined) {
            try {
                const body = await service.updatePlus(data);
                const updatedWorkflow = Object.assign(new Workflow(), body);
                const updatedWorkflows = CrudService.updateData(workflows, updatedWorkflow);
                setWorkflows(updatedWorkflows);
                updateView('TABLE');
            }
            catch (error) {
                //
            }
        }
    }, [service, updateView, workflows]);
    switch (view) {
        case 'FORM':
            return (React.createElement(WorkflowForm, { save: save, workflow: details ?? new Workflow() }));
        case 'TABLE':
            return (React.createElement(WorkflowTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, workflows: workflows ?? [] }));
        default:
            updateView('TABLE');
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Workflows;
