import { defaultPermissions } from 'components/metadata/utils';
import IpoFilter from '../filter/IpoFilter';
import IpoQuery from '../query/IpoQuery';
class IpoSearch {
    createdBy;
    createdDate;
    database;
    filter;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    query;
    constructor() {
        this.permissions = defaultPermissions;
        this.query = new IpoQuery();
    }
    static create(base) {
        const query = Object.assign(new IpoQuery(), base.query);
        const filter = Object.assign(new IpoFilter(), base.filter);
        return Object.assign(new IpoSearch(), base, { filter, query });
    }
    updateName(value) {
        return Object.assign(new IpoSearch(), this, { name: value });
    }
    updatePermissions(value) {
        return Object.assign(new IpoSearch(), this, { permissions: value });
    }
}
export default IpoSearch;
