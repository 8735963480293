class EuroclearCompany {
    category;
    cityname;
    countryname;
    id;
    name;
    tags;
    static getSort = (s0, s1) => s0.name.localeCompare(s1.name);
    static columns = [
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'cityname', header: 'City' },
        { accessorKey: 'countryname', header: 'Country' },
        { accessorKey: 'category', header: 'Category' },
    ];
}
export default EuroclearCompany;
