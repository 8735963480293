var _a;
import FilterItem from '../FilterItem';
import EuroclearSecurity from '../security/EuroclearSecurity';
import { GenericFilter } from '../GenericFilter';
class EuroclearFilter extends GenericFilter {
    categories;
    cfiCategories;
    cfiGroups;
    countries;
    includeSecondary;
    constructor(response) {
        super(response);
        this.categories = response?.categories !== undefined ? new FilterItem(response.categories) : undefined;
        this.cfiCategories = response?.cfiCategories !== undefined ? new FilterItem(response.cfiCategories) : undefined;
        this.cfiGroups = response?.cfiGroups !== undefined ? new FilterItem(response.cfiGroups) : undefined;
        this.countries = response?.countries !== undefined ? new FilterItem(response.countries) : undefined;
        this.includeSecondary = response?.includeSecondary;
    }
    #clone() {
        return new _a({
            categories: this.categories,
            cfiCategories: this.cfiCategories,
            cfiGroups: this.cfiGroups,
            countries: this.countries,
            includeSecondary: this.includeSecondary,
            tags: this.tags
        });
    }
    updateFilter(name, value) {
        const clone = this.#clone();
        clone[name] = value;
        return clone;
    }
    updateTags(value) {
        const newFilter = this.#clone();
        const exclude = newFilter.tags?.exclude ?? false;
        newFilter.tags = new FilterItem({ exclude, values: value });
        return newFilter;
    }
    create() {
        const test = (contains, exclude) => (exclude && !contains) || (!exclude && contains);
        return (s) => {
            if (s instanceof EuroclearSecurity) {
                const tagsTest = (() => {
                    if (this.tags !== undefined && this.tags.values.length > 0) {
                        const tt = this.tags;
                        const contains = s.tags.some((t) => tt.values.includes(t));
                        const { exclude } = this.tags;
                        return (exclude && !contains) || (!exclude && contains);
                    }
                    return true;
                })();
                const countryTest = this.countries?.values.length ? test(this.countries.values.includes(s.issuercountryname), this.countries.exclude) : true;
                const categoryTest = this.categories?.values.length ? test(this.categories.values.includes(s.issuercategory), this.categories.exclude) : true;
                const cfiGroupTest = this.cfiGroups?.values.length ? test(s.cfi !== undefined ? this.cfiGroups.values.includes(s.cfi.group) : false, this.cfiGroups.exclude) : true;
                return tagsTest && countryTest && categoryTest && cfiGroupTest;
            }
            return false;
        };
    }
}
_a = EuroclearFilter;
export default EuroclearFilter;
