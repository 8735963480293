import * as React from 'react';
import { Banner } from '@symphony-ui/uitoolkit-components';
import MessageContext from 'contexts/MessageContext';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import DownloadService from 'services/DownloadService';
import UserContext from 'contexts/UserContext';
import ServiceWorkerMessageHandler from '../../syndicate/notification-click-handlers/ServiceWorkerMessageHandler';
const TabContainer = ({ children, }) => {
    const { setOffline } = React.useContext(AppViewContext);
    const { authorizedFetch } = React.useContext(UserContext);
    const [message, setMessage] = React.useState();
    const onClose = React.useCallback(() => setMessage(undefined), []);
    const messageValue = React.useMemo(() => ({ setMessage }), []);
    const downloadValue = React.useMemo(() => (new DownloadService({
        authorizedFetch, setMessage, setOffline,
    })), [authorizedFetch, setMessage, setOffline]);
    return (React.createElement(MessageContext.Provider, { value: messageValue },
        React.createElement(DownloadContext.Provider, { value: downloadValue },
            React.createElement(ServiceWorkerMessageHandler, null),
            React.createElement("div", { className: "base-box" },
                message !== undefined ? (React.createElement(Banner, { content: message?.content ?? 'no content', isClosable: true, onClose: onClose, show: true, variant: message?.variant })) : React.createElement("div", null),
                children))));
};
export default TabContainer;
