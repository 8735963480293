import { Button, TextField } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const CompanyNameForm = ({ name, setName }) => {
    const onClickCamelCase = React.useCallback(() => {
        const camelCase = name.split(" ").map(word => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()).join(" ");
        setName(camelCase);
    }, [name, setName]);
    const CCButton = React.useMemo(() => {
        return React.createElement(Button, { style: { marginTop: '0.25rem' }, onClick: onClickCamelCase, size: 'small', variant: 'tertiary-accent' }, "CC");
    }, [onClickCamelCase]);
    const onChangeName = React.useCallback((event) => {
        const eventValue = event.target.value;
        setName(eventValue);
    }, [setName]);
    return React.createElement(TextField, { label: "Display Name", onChange: onChangeName, rightDecorators: CCButton, value: name });
};
export default CompanyNameForm;
