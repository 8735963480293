/* eslint-disable react/function-component-definition */
import { DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import './Popup.scss';
import PopupButton from './PopupButton';
const ActionButton = function ActionButtonElement({ actionsMenu, context: actionContext }) {
    const childFn = React.useCallback((close) => {
        const list = actionsMenu.map((item) => {
            const action = (cellContext) => () => {
                close();
                item.callback(cellContext);
            };
            return (React.createElement(DropdownMenuItem, { className: item.className + (item.disabled ? ' co-disabled-menu-item' : ''), key: item.label, disabled: item.disabled ?? false, loading: item.loading, onClick: action(actionContext) }, item.label));
        });
        return list;
    }, [actionsMenu, actionContext]);
    return (React.createElement(PopupButton, { childFn: childFn, iconName: "more" }));
};
export default ActionButton;
