import { Button } from '@symphony-ui/uitoolkit-components';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import * as React from 'react';
import MetaModal from '../../overview/MetaModal';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
const MetaButton = ({ transactionId }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [showModal, setShowModal] = React.useState(false);
    const [trancheWrapper, setTrancheWrapper] = React.useState();
    const onClick = React.useCallback(() => {
        const abortController = new AbortController();
        if (transactionId !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions');
            url.searchParams.append('id', transactionId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    const newTranche = new TrancheWrapper(body[0]);
                    setTrancheWrapper(newTranche);
                    setShowModal(true);
                }
                catch (error) {
                    sendFetchError('Unable to download tranche', error, request);
                }
            })();
        }
        return () => { abortController.abort(); };
    }, [apiFetchJson, transactionId]);
    return React.createElement(React.Fragment, null,
        trancheWrapper !== undefined && showModal
            ? (React.createElement(MetaModal, { hideModal: () => { setShowModal(false); }, setTranche: setTrancheWrapper, tranche: trancheWrapper })) : undefined,
        React.createElement(Button, { iconButton: true, onClick: onClick, size: "small", title: "Search Update" }, "M"));
};
export default MetaButton;
