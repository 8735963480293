import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const PartyTable = ({ deleteFn, edit, parties, hasError, isLoading, ticker, unknowns, }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'name',
            header: 'Party',
        },
        {
            accessorKey: 'shortName',
            header: 'Short Name',
        },
        {
            accessorKey: 'podId',
            header: 'Pod ID',
        },
        {
            accessorFn: (party) => party.names.join(' // '),
            header: 'Names',
            id: 'names',
            size: 800,
        },
    ], [deleteFn, edit]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Parties")),
        React.createElement(CustomTable, { actionElements: [
                (React.createElement(Button, { disabled: hasError, onClick: unknowns, size: "small", style: { marginLeft: '0.5rem', marginTop: '0.25rem' } }, "View Unknowns"))
            ], columns: columns, data: parties || [], emptyMessage: "You have no parties yet", errorMessage: "Error downloading parties", hasError: hasError, isLoading: isLoading, name: "party" })));
};
export default PartyTable;
