import './Regexes.scss';
import * as React from 'react';
import { BannerType, Card, Typography } from '@symphony-ui/uitoolkit-components';
import KeyValueRegex from '../../models/regexes/KeyValueRegex';
import LineRegex from '../../models/regexes/LineRegex';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import ApiServerURL from 'models/ServerUrl';
import DataViewer from './data-viewer/DataViewer';
import DownloadContext from 'contexts/DownloadContext';
import IndexedRegex from '../../models/regexes/IndexedRegex';
import ItemRegex from '../../models/regexes/ItemRegex';
import Regex from '../../models/regexes/Regex';
import RegexFormModal from './regex-modal/RegexFormModal';
import RegexViewer from './regex-viewer/RegexViewer';
import ServerRequest from 'models/ServerRequest';
import { escapeRegex } from '../../models/regexes/regexUtils';
const Regexes = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [category, setCategory] = React.useState(() => {
        const stored = localStorage.getItem('category');
        return stored !== null ? stored : 'GENERIC';
    });
    const [type, setType] = React.useState(() => {
        const stored = localStorage.getItem('type');
        return stored !== null ? stored : 'keyvalues';
    });
    const [myKey, setMyKey] = React.useState(() => {
        const stored = localStorage.getItem('key');
        return stored !== null ? JSON.parse(stored) : undefined;
    });
    const [lineRegex, setLineRegex] = React.useState();
    const [keyValueRegex, setKeyValueRegex] = React.useState();
    const [originalRegex, setOriginalRegex] = React.useState();
    const [regex, setRegex] = React.useState();
    const [regexes, setRegexes] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [titleModal, setTitleModal] = React.useState('');
    React.useEffect(() => {
        switch (type) {
            case 'keyvalues':
                setOriginalRegex(keyValueRegex);
                break;
            case 'listitems':
                setOriginalRegex(new ItemRegex({ regex: { template: '' } }));
                break;
            case 'paragraphs':
                setOriginalRegex(lineRegex);
                break;
            default:
        }
    }, [keyValueRegex, lineRegex, regex, type]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (myKey?.id === null) {
            setKeyValueRegex(undefined);
        }
        else if (myKey?.id !== undefined) {
            const url = new ApiServerURL('/syndicate/regex/keyvalues');
            url.searchParams.append('id', myKey.id);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setKeyValueRegex(KeyValueRegex.create(body));
                }
                catch (error) {
                    sendFetchError('Unable to download keyvalues for regex', error, request);
                }
            })();
        }
        return () => { abortController.abort(); };
    }, [apiFetchJson, myKey, setKeyValueRegex]);
    const callback = React.useCallback((value) => {
        switch (type) {
            case 'keyvalues':
                if (value instanceof KeyValueRegex) {
                    setKeyValueRegex(value);
                }
                break;
            case 'listitems':
                if (value instanceof IndexedRegex) {
                    const existingIndex = regexes.findIndex((r) => r === value);
                    const clonedRegexes = [...regexes];
                    if (existingIndex === -1) {
                        clonedRegexes.push(value);
                    }
                    else {
                        clonedRegexes[existingIndex] = value;
                    }
                    setRegexes(clonedRegexes);
                }
                break;
            case 'paragraphs':
                if (value instanceof LineRegex) {
                    setLineRegex(value);
                }
                break;
            case 'subject':
                if (value instanceof IndexedRegex) {
                    const existingIndex = regexes.findIndex((r) => r === value);
                    const clonedRegexes = [...regexes];
                    if (existingIndex === -1) {
                        clonedRegexes.push(value);
                    }
                    else {
                        clonedRegexes[existingIndex] = value;
                    }
                    setRegexes(clonedRegexes);
                }
                break;
            default:
        }
    }, [regexes, type]);
    const create = React.useCallback(({ row: { original } }) => {
        if (type === 'keyvalues') {
            sendMessage('You need to create or select a Key Value Regex first', BannerType.ERROR);
        }
        else {
            const newRegex = new Regex({ template: escapeRegex(original.getValue()) });
            setRegex(newRegex);
            setTitleModal('Add Regex');
            setShowModal(true);
        }
    }, [type]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(RegexFormModal, { callback: callback, hideModal: () => { setShowModal(false); }, originalRegex: originalRegex, regex: regex, show: showModal, title: titleModal, type: type }),
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Regex Management")),
        React.createElement("div", { style: { display: 'flex', height: '100%' } },
            React.createElement("div", { style: { width: '50%' } },
                React.createElement(DataViewer, { create: create, category: category, clearRegexes: () => {
                        //
                    }, myKey: myKey, setCategory: setCategory, setMyKey: setMyKey, setType: setType, type: type })),
            !(type === 'keyvalues' && myKey === undefined) ?
                React.createElement("div", { style: { width: '50%' } },
                    React.createElement(RegexViewer, { category: category, keyValueRegex: keyValueRegex, lineRegex: lineRegex, myKey: myKey, regexes: regexes, setKeyValueRegex: setKeyValueRegex, setMyKey: setMyKey, setLineRegex: setLineRegex, setRegexes: setRegexes, type: type })) : undefined)));
};
export default Regexes;
