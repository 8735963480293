const compareDates = (date1, date2) => {
    if (date1 > date2) {
        return 1;
    }
    if (date1 < date2) {
        return -1;
    }
    return 0;
};
export default {
    date: (field) => (rowA, rowB) => ((field in rowA.original && field in rowB.original)
        ? rowA.original[field] - rowB.original[field] : 0),
    lastUpdate: (rowA, rowB) => compareDates(rowA.original.transaction.lastUpdate, rowB.original.transaction.lastUpdate),
    maturity: (rowA, rowB) => {
        if (rowA.original.data?.maturity?.date === undefined) {
            return -1;
        }
        if (rowB.original.data?.maturity?.date === undefined) {
            return 1;
        }
        const maturityA = rowA.original.data.maturity.date;
        const maturityB = rowB.original.data.maturity.date;
        if (maturityA.year !== undefined && maturityB.year !== undefined && maturityA.year !== maturityB.year) {
            return maturityA.year - maturityB.year;
        }
        if (maturityA.monthValue !== undefined && maturityB.monthValue !== undefined && maturityA.monthValue !== maturityB.monthValue) {
            return maturityA.monthValue - maturityB.monthValue;
        }
        if (maturityA.dayOfMonth !== undefined && maturityB.dayOfMonth !== undefined && maturityA.dayOfMonth !== maturityB.dayOfMonth) {
            return maturityA.dayOfMonth - maturityB.dayOfMonth;
        }
        return 0;
    },
};
