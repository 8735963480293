import printGuidance, { benchmarkName, printInterestRateBenchmark } from './printGuidance';
const textToNumber = (text, options) => {
    const numberRegex = /^-?[\d,.]+$/;
    if ((numberRegex.exec(text)) === null) {
        return text;
    }
    const parsed = Number.parseFloat(text);
    return Number.isNaN(parsed) ? text : { value: parsed, options };
};
const printIntlNumber = (value) => Intl.NumberFormat().format(value);
export default {
    amount: ({ data, overrideMap }) => {
        if (overrideMap?.amount && typeof overrideMap.amount === 'string') {
            return textToNumber(overrideMap.amount);
        }
        if (data?.nominal?.tapAmount?.size !== undefined) {
            if (typeof data.nominal.tapAmount.size.value === 'number') {
                return { value: data.nominal.tapAmount.size.value / 1000000 };
            }
        }
        if (data?.nominal?.amount?.size !== undefined) {
            const { size } = data.nominal.amount;
            if (size.value !== undefined) {
                if (size.qualifier !== undefined) {
                    const printQualifier = (q) => {
                        switch (q) {
                            case 'EXPECTED':
                                return 'exp.';
                            case 'INDICATIVE':
                                return 'ind.';
                            case 'MINIMUM':
                                return 'min.';
                            case 'UP_TO':
                                return 'max.';
                            case 'WILL_NOT_GROW':
                                return 'wng';
                            default:
                                return q;
                        }
                    };
                    return `${printIntlNumber(size.value / 1000000)} (${printQualifier(size.qualifier)})`;
                }
                return { value: size.value / 1000000 };
            }
            if (size.range !== undefined) {
                const { minimum, maximum } = size.range;
                return `${minimum ? printIntlNumber(minimum / 1000000) : '?'} - ${maximum ? printIntlNumber(maximum / 1000000) : '?'}`;
            }
            if (size.isBenchmark) {
                return 'Benchmark';
            }
            if (size.isSubBenchmark) {
                return 'Sub Benchmark';
            }
        }
        return undefined;
    },
    book: ({ data, overrideMap }) => {
        if (overrideMap?.books && typeof overrideMap.books === 'string') {
            return textToNumber(overrideMap.books);
        }
        if (data?.management?.books?.size?.value !== undefined) {
            return { value: data.management.books.size.value / 1000000 };
        }
        return undefined;
    },
    bookrunners: ({ meta, overrideMap }) => {
        if (overrideMap?.bookrunners && typeof overrideMap.bookrunners === 'string') {
            return overrideMap.bookrunners;
        }
        if (meta?.bookrunners !== undefined) {
            if (meta.bookrunners.length > 8) {
                const jgcs = meta.bookrunners.filter((b) => b.roles?.includes('GLOBAL_COORDINATOR'));
                if (jgcs.length === 1) {
                    return `SGC: ${jgcs[0].name}`;
                }
                else if (jgcs.length > 1) {
                    return `JGCs: ${jgcs.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ')}`;
                }
            }
            return meta.bookrunners.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ');
        }
        return undefined;
    },
    collateral: ({ data, overrideMap }) => {
        return "TBC";
    },
    coupon: ({ data, overrideMap }) => {
        if (overrideMap?.coupon && typeof overrideMap.coupon === 'string') {
            return textToNumber(overrideMap.coupon, { minimumFractionDigits: 2 });
        }
        if (data !== undefined) {
            const coupons = data.coupons ?? data.profitRates;
            if (coupons !== undefined) {
                const [coupon] = coupons;
                if (coupon.fixedRate?.value !== undefined) {
                    if (coupon.fixedRate.indicative === true) {
                        return `${coupon.fixedRate.value} (indic.)`;
                    }
                    return textToNumber(coupon.fixedRate.value, { minimumFractionDigits: 2 });
                }
                if (coupon.floatingRate?.referenceRate !== undefined && coupon.floatingRate.margin !== undefined) {
                    const items = [];
                    items.push(printInterestRateBenchmark(coupon.floatingRate.referenceRate));
                    const margin = coupon.floatingRate.margin === 0 ? 'flat' : `${coupon.floatingRate.margin > 0 ? '+' : ''}${coupon.floatingRate.margin.toString()}`;
                    items.push(margin);
                    return items.join(' ');
                }
            }
        }
        return undefined;
    },
    currency: ({ data, overrideMap }) => overrideMap?.currency ?? data?.nominal?.amount?.currency ?? data?.nominal?.tapAmount?.currency,
    cusip: ({ data, overrideMap }) => {
        if (overrideMap?.cusip !== undefined) {
            return overrideMap.cusip;
        }
        if (data?.identifiers !== undefined) {
            const isins = data.identifiers.filter((i) => i.type === 'CUSIP' && 'value' in i);
            if (isins.length === 1) {
                return isins[0].value;
            }
            if (isins.length > 1) {
                const isin144A = isins.find(i => i.regulation === 'RULE_144A');
                if (isin144A !== undefined) {
                    return isin144A.value;
                }
                return isins[0].value;
            }
        }
        return undefined;
    },
    identifier: (identifierType, regulationsType) => ({ data, overrideMap }) => {
        const regulation = (t) => {
            if (t === 'REGS')
                return 'RegS';
            if (t === 'RULE_144A')
                return '144A';
            return '';
        };
        const field = identifierType.toLowerCase() + regulation(regulationsType);
        if (overrideMap && field in overrideMap) {
            return overrideMap[field];
        }
        if (data?.identifiers !== undefined) {
            const identifies = data.identifiers.find((i) => i.type === identifierType && 'value' in i && regulationsType === i.regulation);
            return identifies?.value;
        }
        return undefined;
    },
    cusipRegS: ({ data, overrideMap }) => {
        if (overrideMap?.cusipRegS !== undefined) {
            return overrideMap.cusipRegS;
        }
        if (data?.identifiers !== undefined) {
            const cusips = data.identifiers.find((i) => i.type === 'CUSIP' && 'value' in i && i.regulation === 'REGS');
            return cusips?.value;
        }
        return undefined;
    },
    documentation: (map, field) => ({ data, overrideMap }) => {
        if (overrideMap && field in overrideMap) {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return map.get(val) ?? '';
            }
        }
        if (data?.documentation?.[field] !== undefined) {
            return map.get(data.documentation[field]);
        }
        return undefined;
    },
    documentationArray: (map, field) => ({ data, overrideMap }) => {
        if (overrideMap && field in overrideMap && typeof overrideMap[field] === 'string') {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return val.split(',').map((c) => map.get(c)).join(' ');
            }
        }
        if (data?.documentation?.[field] !== undefined) {
            return data.documentation[field].map((c) => map.get(c)).join(' ');
        }
        return undefined;
    },
    expectedMaturityDate: ({ meta, overrideMap }) => {
        return "TBC";
    },
    firstPayDate: ({ data, overrideMap }) => {
        if (data?.coupons !== undefined && data.coupons.length > 0) {
            return data.coupons[0].firstCouponDate;
        }
        return undefined;
    },
    guidance: (type) => ({ data, overrideMap }) => {
        if (overrideMap?.[type]) {
            return overrideMap[type];
        }
        if (data === undefined) {
            return undefined;
        }
        if (type === 'initialPriceThoughts') {
            if (data.initialPriceThoughts !== undefined) {
                return printGuidance(data.initialPriceThoughts);
            }
            if (data.guidance !== undefined && data.revisedGuidance !== undefined) {
                return printGuidance(data.guidance);
            }
        }
        if (type === 'guidance') {
            const items = [];
            if ((data.revisedGuidance === undefined || data.initialPriceThoughts !== undefined) && data.guidance !== undefined) {
                items.push(printGuidance(data.guidance));
            }
            if (data.revisedGuidance !== undefined) {
                items.push(`revised to ${printGuidance(data.revisedGuidance)}`);
            }
            return items.join(' ');
        }
        return undefined;
    },
    industry: ({ meta, overrideMap }) => {
        if (overrideMap?.industry) {
            return overrideMap.industry;
        }
        const issuer = meta?.parties?.find(p => p.type === 'ISSUER');
        if (issuer?.company?.sector !== undefined) {
            return issuer.company.sector;
        }
        return undefined;
    },
    interestBasis: ({ data, overrideMap }) => {
        if (overrideMap?.interestBasis) {
            return overrideMap.interestBasis;
        }
        if (overrideMap?.coupon) {
            return Number.isNaN(Number.parseFloat(overrideMap.coupon)) ? 'FLOATING' : 'FIXED';
        }
        if (data?.coupons !== undefined) {
            const [coupon] = data.coupons;
            if (coupon.interestBasis !== undefined) {
                return coupon.interestBasis;
            }
            if (coupon.fixedRate !== undefined) {
                return 'FIXED';
            }
            if (coupon.floatingRate !== undefined) {
                return 'FLOATING';
            }
        }
        if (data?.profitRates !== undefined) {
            const [coupon] = data.profitRates;
            if (coupon.interestBasis !== undefined) {
                return coupon.interestBasis;
            }
            if (coupon.fixedRate !== undefined) {
                return 'FIXED';
            }
            if (coupon.floatingRate !== undefined) {
                return 'FLOATING';
            }
        }
        return undefined;
    },
    iptMove: ({ data, overrideMap }) => {
        if (overrideMap?.iptMove) {
            return textToNumber(overrideMap.iptMove);
        }
        if (data?.pricing !== undefined) {
            if (data.pricing.spread?.value !== undefined) {
                const { value } = data.pricing.spread;
                const reoffer = value;
                if (data.initialPriceThoughts?.spread !== undefined) {
                    if (data.initialPriceThoughts.spread.value !== undefined) {
                        return { value: reoffer - data.initialPriceThoughts.spread.value.value };
                    }
                    if (data.initialPriceThoughts.spread.range !== undefined) {
                        return { value: reoffer - data.initialPriceThoughts.spread.range.minimum };
                    }
                }
                if (data.guidance?.spread !== undefined) {
                    if (data.guidance.spread.value !== undefined) {
                        return { value: reoffer - data.guidance.spread.value.value };
                    }
                    if (data.guidance.spread.range !== undefined) {
                        return { value: reoffer - data.guidance.spread.range.minimum };
                    }
                }
                if (data.revisedGuidance?.spread !== undefined) {
                    if (data.revisedGuidance.spread.value !== undefined) {
                        return { value: reoffer - data.revisedGuidance.spread.value.value };
                    }
                    if (data.revisedGuidance.spread.range !== undefined) {
                        return { value: reoffer - data.revisedGuidance.spread.range.minimum };
                    }
                }
            }
            if (data.pricing.yield?.value !== undefined) {
                const reofferYield = data.pricing.yield.value;
                if (data.initialPriceThoughts?.yield !== undefined) {
                    if (data.initialPriceThoughts.yield.value !== undefined) {
                        return { value: 100 * (reofferYield - data.initialPriceThoughts.yield.value.value) };
                    }
                    if (data.initialPriceThoughts.yield.range !== undefined) {
                        return { value: 100 * (reofferYield - data.initialPriceThoughts.yield.range.minimum) };
                    }
                }
                if (data.guidance?.yield !== undefined) {
                    if (data.guidance.yield.value) {
                        return { value: 100 * (reofferYield - data.guidance.yield.value.value) };
                    }
                    if (data.guidance.yield.range !== undefined) {
                        return { value: 100 * (reofferYield - data.guidance.yield.range.minimum) };
                    }
                }
                if (data.revisedGuidance?.yield !== undefined) {
                    if (data.revisedGuidance.yield.value !== undefined) {
                        return { value: 100 * (reofferYield - data.revisedGuidance.yield.value.value) };
                    }
                    if (data.revisedGuidance.yield.range !== undefined) {
                        return { value: 100 * (reofferYield - data.revisedGuidance.yield.range.minimum) };
                    }
                }
            }
        }
        return '';
    },
    isin: ({ data, overrideMap }) => {
        if (overrideMap?.isin !== undefined) {
            return overrideMap.isin;
        }
        if (data?.identifiers !== undefined) {
            const isins = data.identifiers.filter((i) => i.type === 'ISIN' && 'value' in i);
            if (isins.length === 1) {
                return isins[0].value;
            }
            if (isins.length > 1) {
                const isin144A = isins.find(i => i.regulation === 'RULE_144A');
                if (isin144A !== undefined) {
                    return isin144A.value;
                }
                return isins[0].value;
            }
        }
        return undefined;
    },
    isins: ({ data, overrideMap }) => {
        if (overrideMap?.isin !== undefined) {
            return overrideMap.isin;
        }
        if (data?.identifiers !== undefined) {
            return data.identifiers.filter((i) => i.type === 'ISIN' && 'value' in i)
                .map((i) => i.value + ('regulation' in i ? ` (${i.regulation === 'REGS' ? 'RegS' : '144A'})` : '')).join(', ');
        }
        return undefined;
    },
    issuer: ({ data, meta, overrideMap }) => {
        if (overrideMap?.issuer !== undefined) {
            return overrideMap.issuer;
        }
        if (meta?.parties !== undefined) {
            const text = [];
            const issuer = meta.parties.find(p => p.type === 'ISSUER');
            if (issuer?.company !== undefined) {
                text.push(issuer.company.name);
            }
            else {
                if (issuer?.name !== undefined) {
                    text.push(issuer.name);
                }
            }
            return text.join(' ');
        }
        if (data?.issuer !== undefined) {
            const text = [];
            if (data.issuer.name !== undefined) {
                text.push(data.issuer.name);
            }
            else if (data.issuer.shortName !== undefined) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            return text.join(' ');
        }
        return undefined;
    },
    issuerRaw: ({ data, overrideMap }) => {
        if (data?.obligor?.name !== undefined) {
            return `${data.obligor.name.replace(/, acting through.*/, '')} (Obligor)`;
        }
        if (data?.issuer !== undefined) {
            const text = [];
            if (data.issuer.name != null) {
                text.push(data.issuer.name.replace(/, acting through.*/, ''));
            }
            else if (data.issuer.shortName != null) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            if (text.length > 0) {
                return text.join(' ');
            }
        }
        return overrideMap?.issuer;
    },
    lei: ({ meta, overrideMap }) => overrideMap?.lei ?? meta?.lei,
    maturity: ({ data, overrideMap }) => {
        const override = overrideMap?.maturity;
        if (override !== undefined) {
            const dateRegex = /(?<year>[0-9]{4})(-(?<month>[0-9]{2})(-(?<day>[0-9]{2}))?)?/;
            const dateMatchArray = dateRegex.exec(override);
            const groups = dateMatchArray?.groups;
            if (groups !== undefined) {
                const { year } = groups;
                const month = 'month' in groups ? groups.month : undefined;
                const day = 'day' in groups ? groups.day : undefined;
                const yearValue = parseInt(year, 10);
                const monthValue = month !== undefined ? parseInt(month, 10) : 0;
                const dayValue = day !== undefined ? parseInt(day, 10) : 1;
                const realDate = new Date(Date.UTC(yearValue, monthValue - 1, dayValue));
                let precision = 'Y';
                if (month !== undefined)
                    precision = 'YM';
                if (day !== undefined)
                    precision = 'YMD';
                return { value: realDate, options: { precision } };
            }
            return override;
        }
        ;
        if (data?.maturity !== undefined) {
            const { maturity } = data;
            if ('scheduledDissolutionDate' in data.maturity) {
                const dateRegex = /(?<year>[0-9]{4})(-(?<month>[0-9]{2})(-(?<day>[0-9]{2}))?)?/;
                const dateMatchArray = dateRegex.exec(data.maturity.scheduledDissolutionDate);
                const groups = dateMatchArray?.groups;
                if (groups !== undefined) {
                    const { year } = groups;
                    const month = 'month' in groups ? groups.month : undefined;
                    const day = 'day' in groups ? groups.day : undefined;
                    const yearValue = parseInt(year, 10);
                    const monthValue = month !== undefined ? parseInt(month, 10) : 0;
                    const dayValue = day !== undefined ? parseInt(day, 10) : 1;
                    const realDate = new Date(Date.UTC(yearValue, monthValue - 1, dayValue));
                    let precision = 'Y';
                    if (month !== undefined)
                        precision = 'YM';
                    if (day !== undefined)
                        precision = 'YMD';
                    return { value: realDate, options: { precision } };
                }
                return override;
            }
            const { date } = maturity;
            if (date !== undefined) {
                const { dayOfMonth, monthValue, year } = date;
                let precision = "Y";
                if (monthValue !== undefined)
                    precision = "YM";
                if (dayOfMonth !== undefined)
                    precision = "YMD";
                const dateValue = new Date(Date.UTC(year ?? 2025, (monthValue ?? 1) - 1, dayOfMonth ?? 1));
                return { value: dateValue, options: { precision } };
            }
            if (maturity.perpetual || maturity.tenor?.perpetual) {
                return 'Perpetual';
            }
        }
        return undefined;
    },
    minDenomination: ({ data, overrideMap }) => {
        if (overrideMap?.denominations !== undefined) {
            return overrideMap.denominations;
        }
        const minimum = data?.documentation?.denominations?.minimum;
        const multiple = data?.documentation?.denominations?.multiple;
        if (minimum !== undefined) {
            const text = [];
            text.push(minimum.currency);
            let t;
            if (minimum.value > 999999) {
                t = (minimum.value / 1000000).toString() + "M";
            }
            else {
                t = (minimum.value / 1000).toString() + "K";
            }
            text.push(t);
            if (multiple !== undefined) {
                let m;
                if (multiple.value > 999999) {
                    m = (multiple.value / 1000000).toString() + "M";
                }
                else {
                    m = (multiple.value / 1000).toString() + "K";
                }
                text.push("+" + m);
            }
            return text.join(" ");
        }
        return undefined;
    },
    newIssueConcession: ({ data, overrideMap }) => {
        return "N/A";
    },
    oversubscription: ({ data, overrideMap }) => {
        if (overrideMap?.oversubscription !== undefined) {
            return textToNumber(overrideMap.oversubscription);
        }
        let bookSize;
        if (overrideMap?.books !== undefined) {
            const overrideBookSize = Number.parseInt(overrideMap.books, 10) * 1000000;
            if (!Number.isNaN(overrideBookSize)) {
                bookSize = overrideBookSize;
            }
            else {
                return undefined;
            }
        }
        if (bookSize === undefined && data?.management?.books?.size?.value !== undefined) {
            bookSize = data.management.books.size.value;
        }
        let amount;
        if (overrideMap?.amount !== undefined) {
            const overrideAmount = Number.parseInt(overrideMap.amount, 10) * 1000000;
            if (!Number.isNaN(overrideAmount)) {
                amount = overrideAmount;
            }
            else {
                return undefined;
            }
        }
        else if (amount === undefined && data?.nominal?.tapAmount?.size?.value !== undefined) {
            amount = data.nominal.tapAmount.size.value;
        }
        else if (amount === undefined && data?.nominal?.amount?.size?.value !== undefined) {
            amount = data.nominal.amount.size.value;
        }
        if (amount !== undefined && bookSize !== undefined) {
            return { value: bookSize / amount, options: { maximumFractionDigits: 1 } };
        }
        return undefined;
    },
    pricing: ({ data, overrideMap }) => {
        if (overrideMap?.pricing !== undefined) {
            return overrideMap.pricing;
        }
        if (data !== undefined) {
            const isYieldBase = ((data.initialPriceThoughts?.spread === undefined && data.initialPriceThoughts?.yield !== undefined)
                || (data.guidance?.spread === undefined && data.guidance?.yield !== undefined));
            if (data.pricing !== undefined) {
                const items = [];
                if (!isYieldBase) {
                    if (data.pricing.spread !== undefined) {
                        const { spread } = data.pricing;
                        if (spread.benchmark !== undefined) {
                            items.push(benchmarkName(spread.benchmark));
                        }
                        if (spread.value !== undefined) {
                            items.push((spread.value > 0 ? '+' : '') + spread.value.toString());
                        }
                    }
                    else {
                        const yieldPricing = data.pricing.yield;
                        if (yieldPricing?.value !== undefined) {
                            items.push(`${yieldPricing.value.toString()}% `);
                        }
                    }
                    return items.join(' ');
                }
                const yieldPricing = data.pricing.yield;
                if (yieldPricing?.value !== undefined) {
                    items.push(`${yieldPricing.value.toString()}% `);
                    return items.join(' ');
                }
            }
        }
        return '';
    },
    price: ({ data, overrideMap }) => {
        if (overrideMap?.price !== undefined) {
            return overrideMap.price;
        }
        if (data?.pricing !== undefined) {
            if (data.pricing.price !== undefined) {
                return { value: data.pricing.price };
            }
            else if (data.pricing.issuePrice !== undefined) {
                return { value: data.pricing.issuePrice };
            }
        }
        return undefined;
    },
    registration: ({ data, overrideMap }) => {
        return "TBC";
    },
    benchmark: ({ data, overrideMap }) => {
        if (overrideMap?.benchmark !== undefined) {
            return overrideMap.benchmark;
        }
        if (data !== undefined) {
            const isYieldBase = ((data.initialPriceThoughts?.spread === undefined && data.initialPriceThoughts?.yield !== undefined)
                || (data.guidance?.spread === undefined && data.guidance?.yield !== undefined));
            if (data.pricing !== undefined) {
                if (!isYieldBase) {
                    if (data.pricing.spread !== undefined) {
                        const { spread } = data.pricing;
                        if (spread.benchmark !== undefined) {
                            return benchmarkName(spread.benchmark);
                        }
                    }
                }
            }
        }
        return undefined;
    },
    pricingDate: ({ meta, overrideMap }) => {
        if (overrideMap?.pricingDate === 'TBC') {
            return undefined;
        }
        const dateValue = overrideMap?.pricingDate !== undefined ? new Date(overrideMap.pricingDate) : meta?.pricingDate;
        return dateValue !== undefined ? { value: dateValue } : undefined;
    },
    ratings: ({ data, overrideMap }) => {
        if (overrideMap?.ratings && typeof overrideMap.ratings === 'string') {
            return overrideMap.ratings;
        }
        const print = (ratings) => {
            if (ratings.unrated) {
                return 'Unrated';
            }
            const values = ratings.values ?? [];
            if (values.filter((r) => r.agency !== undefined).length === 0) {
                const text = values.map((r) => r.value);
                return text.join('/');
            }
            const moodys = values.find((r) => r.agency === 'MOODYS');
            const sp = values.find((r) => r.agency === 'S_P');
            const fitch = values.find((r) => r.agency === 'FITCH');
            const text = [];
            if (moodys !== undefined) {
                text.push(moodys.value);
            }
            if (typeof sp !== 'undefined') {
                const suffix = typeof fitch === 'undefined' && typeof moodys === 'undefined' ? ' (S&P)' : '';
                text.push(sp.value + suffix);
            }
            else if (typeof fitch !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            if (typeof fitch !== 'undefined') {
                const suffix = typeof sp === 'undefined' && typeof moodys === 'undefined' ? ' (Fitch)' : '';
                text.push(fitch.value + suffix);
            }
            else if (typeof sp !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            return text.join('/');
        };
        if (((data?.ratings?.values !== undefined && data.ratings.values.length > 0) || data?.ratings?.unrated)) {
            return print(data.ratings);
        }
        if (data?.issuer?.ratings !== undefined && ((data.issuer.ratings.values !== undefined && data.issuer.ratings.values.length > 0) || data.issuer.ratings.unrated)) {
            return print(data.issuer.ratings);
        }
        return undefined;
    },
    ratingIndividual: (label, agency) => ({ data, overrideMap }) => {
        if (overrideMap?.[label] && typeof overrideMap[label] === 'string') {
            return overrideMap[label];
        }
        if (data === undefined) {
            return undefined;
        }
        const printRatings = (ratings) => {
            if (ratings.unrated) {
                return '-';
            }
            const { values } = ratings;
            if (values !== undefined) {
                const issueRating = values.find((r) => r.agency === agency)?.value;
                if (issueRating !== undefined) {
                    return issueRating;
                }
                if (!values.some(r => r.agency !== undefined)) {
                    if (values.length > 0 && agency === 'MOODYS') {
                        return values[0].value;
                    }
                    if (values.length > 1 && agency === 'S_P') {
                        return values[1].value;
                    }
                    if (values.length > 2 && agency === 'FITCH') {
                        return values[2].value;
                    }
                }
            }
            return undefined;
        };
        const { ratings } = data;
        if (ratings !== undefined && ((ratings.values !== undefined && ratings.values.length > 0) || ratings.unrated)) {
            return printRatings(ratings);
        }
        const issuerRatings = data.issuer?.ratings;
        if (issuerRatings !== undefined && ((issuerRatings.values !== undefined && issuerRatings.values.length > 0) || issuerRatings.unrated)) {
            return printRatings(issuerRatings);
        }
        return undefined;
    },
    redemption: ({ meta, overrideMap }) => {
        return "TBC";
    },
    regions: (map) => ({ transaction }) => {
        return transaction.regions?.map((t) => map.get(t)).join(', ');
    },
    sector: (map) => ({ transaction }) => {
        return transaction.sector ? map.get(transaction.sector) : undefined;
    },
    settlementDate: ({ data, overrideMap }) => {
        if (overrideMap?.settlementDate === 'TBC') {
            return undefined;
        }
        const settlementDateString = overrideMap?.settlementDate ?? data?.settlement?.date;
        return settlementDateString !== undefined ? { value: new Date(settlementDateString) } : undefined;
    },
    spread: ({ data, overrideMap }) => {
        if (overrideMap?.spread) {
            return textToNumber(overrideMap.spread, { signDisplay: 'always' });
        }
        if (overrideMap?.pricing && typeof overrideMap.pricing === 'string' && !overrideMap.pricing.trimEnd().endsWith('%')
            && (overrideMap.pricing.includes("+") || overrideMap.pricing.includes("-"))) {
            const [first] = overrideMap.pricing.split(/[+-]/);
            return textToNumber(overrideMap.pricing.substring(first.length), { signDisplay: 'always' });
        }
        if (data !== undefined) {
            const isYieldBase = ((data.initialPriceThoughts?.spread === undefined && data.initialPriceThoughts?.yield !== undefined)
                || (data.guidance?.spread === undefined && data.guidance?.yield !== undefined));
            if (!isYieldBase && data.pricing?.spread != null) {
                const { spread } = data.pricing;
                if (spread.value !== undefined) {
                    return { value: spread.value, options: { signDisplay: 'always' } };
                }
            }
        }
        return undefined;
    },
    status: (map) => ({ meta, overrideMap }) => {
        if (overrideMap?.status !== undefined) {
            return overrideMap.status;
        }
        return meta?.status !== undefined ? map.get(meta.status) : undefined;
    },
    structuringLead: ({ meta, overrideMap }) => {
        return "TBC";
    },
    companySector: (map, field) => ({ meta, overrideMap }) => {
        if (overrideMap && field in overrideMap) {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return map.get(val) ?? '';
            }
        }
        const issuer = meta?.parties?.find(p => p.type === 'ISSUER');
        const value = issuer?.company?.[field];
        if (value !== undefined) {
            return map.get(value);
        }
        return undefined;
    },
    tap: ({ meta, overrideMap }) => {
        if (overrideMap?.tap?.toUpperCase() !== undefined) {
            return overrideMap.tap.toUpperCase() === 'TRUE';
        }
        return meta?.tap;
    },
    tapText: ({ meta, overrideMap }) => {
        if (overrideMap?.tap?.toUpperCase() !== undefined) {
            return overrideMap.tap.toUpperCase() === 'TRUE' ? 'Yes' : 'No';
        }
        return meta?.tap ? 'Yes' : 'No';
    },
    tenor: ({ data, meta, overrideMap }) => {
        if (overrideMap?.tenor !== undefined) {
            return overrideMap.tenor;
        }
        if (data !== undefined) {
            const { maturity } = data;
            let matMonth = null;
            let matYear = null;
            if (overrideMap?.maturity) {
                const dateRegex = /(?<year>[0-9]{4})(-(?<month>[0-9]{2})?)(-(?<day>[0-9]{2})?)/;
                const dateMatchArray = dateRegex.exec(overrideMap.maturity);
                const overrideDateGroups = dateMatchArray?.groups;
                if (overrideDateGroups !== undefined) {
                    const { year, month } = overrideDateGroups;
                    matYear = Number.parseInt(year, 10);
                    if (typeof month === 'string')
                        matMonth = Number.parseInt(month, 10);
                }
            }
            else if (maturity?.date !== undefined) {
                matYear = maturity.date.year ?? null;
                matMonth = maturity.date.monthValue ?? null;
            }
            if (matYear !== null && matMonth !== null && (overrideMap?.tap?.toUpperCase() === 'TRUE' || meta?.tap)) {
                return new Date(matYear, matMonth - 1, 1).toLocaleDateString((new Intl.DateTimeFormat()).resolvedOptions().locale, { month: 'short', year: '2-digit' });
            }
            if (maturity !== undefined) {
                if (maturity.tenor !== undefined) {
                    const text = [];
                    if (maturity.tenor.qualifier === 'LONG') {
                        text.push('Long ');
                    }
                    else if (maturity.tenor.qualifier === 'SHORT') {
                        text.push('Short ');
                    }
                    if (maturity.tenor.value != null) {
                        const { value } = maturity.tenor;
                        if (value.periodMultiplier !== undefined) {
                            text.push(value.periodMultiplier.toString());
                        }
                    }
                    else if (maturity.tenor.perpetual) {
                        text.push('Perp');
                    }
                    if (maturity.tenor.nonCall?.periodMultiplier !== undefined) {
                        text.push(`NC${maturity.tenor.nonCall.periodMultiplier.toString()} `);
                    }
                    else if (maturity.tenor.value?.period !== undefined) {
                        text.push(maturity.tenor.value.period);
                    }
                    return text.join('');
                }
                if (maturity.weightedAverageLife?.periodMultiplier !== undefined) {
                    return `${maturity.weightedAverageLife.periodMultiplier.toString()}${maturity.weightedAverageLife.period} WAL`;
                }
            }
            if (matMonth !== null && matYear !== null) {
                const today = new Date();
                const years = (matYear - today.getFullYear())
                    + (matMonth - today.getMonth() - 1) / 12;
                const rounded = Math.round(years * 10) / 10;
                return `[${rounded.toString()}Y]`;
            }
        }
        return undefined;
    },
    ticker: ({ meta, overrideMap }) => overrideMap?.ticker ?? meta?.ticker,
    timing: (type) => ({ data }) => {
        if (data?.management !== undefined) {
            const text = [];
            const value = data.management[type];
            if (value?.times !== undefined) {
                const time = value.times.find((t) => t.time !== undefined);
                if (time?.time !== undefined) {
                    text.push(time.time.substring(0, 5));
                    if ('zone' in time) {
                        text.push(time.zone);
                    }
                    if (value.times[0].time === undefined) {
                        text.push('?!');
                    }
                    return text.join(' ');
                }
            }
        }
        return undefined;
    },
    types: (map) => ({ transaction }) => {
        return transaction.types?.map((t) => map.get(t)).join(', ');
    },
    ultimateParent: ({ meta, overrideMap }) => {
        if (overrideMap?.parent) {
            return overrideMap.parent;
        }
        const issuer = meta?.parties?.find(p => p.type === 'ISSUER');
        if (issuer?.ultimateParent !== undefined) {
            return issuer.ultimateParent.name;
        }
        return undefined;
    },
    verified: ({ data, overrideMap }) => {
        return "TBC";
    },
    yield: ({ data, overrideMap }) => {
        if (overrideMap?.yield !== undefined) {
            return textToNumber(overrideMap.yield);
        }
        if (data?.pricing?.yield?.value !== undefined) {
            return { value: data.pricing.yield.value };
        }
        return undefined;
    },
};
