import * as React from 'react';
import CategoryPicker from './CategoryPicker';
import KeyPicker from './KeyPicker';
import TypePicker from './TypePicker';
import './DataViewerForm.scss';
const DataViewerForm = ({ category, clearRegexes, isLoading, myKey, type, setCategory, setMyKey, setType, }) => (React.createElement("div", { className: "co-data-viewer-form" },
    React.createElement(TypePicker, { clearRegexes: clearRegexes, hasError: false, isLoading: isLoading, setType: setType, type: type }),
    type === 'keyvalues' ? (React.createElement(KeyPicker, { isLoading: isLoading, myKey: myKey, setMyKey: setMyKey })) : '',
    type === 'paragraphs' ? (React.createElement(CategoryPicker, { category: category, isLoading: isLoading, setCategory: setCategory })) : ''));
export default DataViewerForm;
