import TrancheWrapper from '../../../../models/TrancheWrapper';
import * as React from 'react';
import EditableCell from './EditableCell';
import print from '../cells/print';
import DownloadContext from 'contexts/DownloadContext';
import Popup from 'components/custom-table/action/Popup';
import CompanySelector from './IssuerSelector';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const IssuerCell = ({ cell, column, row, table }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const popupRef = React.useRef(null);
    const editableCellRef = React.useRef(null);
    const [disabled, setDisabled] = React.useState(false);
    const [errorText, setErrorText] = React.useState();
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const closePopup = React.useCallback(() => {
        setEditing(false);
    }, []);
    const toggleEditing = React.useCallback((value) => setEditing(value), []);
    const originalText = React.useMemo(() => {
        return print.issuer(row.original);
    }, [row.original]);
    const onChange = React.useCallback((companyId) => {
        (async () => {
            if (table.options.meta?.updateTable !== undefined) {
                editableCellRef.current?.focus();
                const formData = new FormData();
                formData.append('companyId', companyId);
                formData.append('transactionId', row.original.transaction.id);
                formData.append('trancheIndex', row.original.trancheIndex.toString());
                const request = new ServerRequest('/syndicate/transactions/issuer', { body: formData, method: 'PATCH' });
                const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
                table.options.meta.updateTable(editableTranches);
                setDisabled(true);
                setLoading(true);
                try {
                    const body = await apiFetchJson(request);
                    const response = body.map((t) => new TrancheWrapper(t));
                    table.options.meta.updateTable(response);
                }
                catch (error) {
                    sendFetchError('Unable to save data', error, request);
                    setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
                }
                finally {
                    setDisabled(false);
                    setLoading(false);
                    setEditing(false);
                }
            }
        })();
    }, [apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const content = React.useMemo(() => {
        const issuer = row.original.meta?.parties?.find(p => p.type === 'ISSUER')?.company;
        if (isEditing) {
            return (React.createElement("div", null,
                React.createElement(Popup, { close: closePopup, ref: popupRef, style: { padding: '1rem' } },
                    React.createElement(CompanySelector, { company: issuer, disabled: disabled, label: "Issuer", onChange: onChange, style: { width: '20rem' } }))));
        }
        if (issuer !== undefined) {
            return React.createElement("b", null, issuer.name);
        }
        return row.original.data?.issuer?.name;
    }, [closePopup, disabled, isEditing, onChange, row.original.data?.issuer?.name, row.original.meta?.parties]);
    return (React.createElement(EditableCell, { errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing || isLoading, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, setEditing: toggleEditing, title: originalText ?? "" }, content));
};
export default IssuerCell;
