import { API_URL } from 'utils/system/envs-constants';
/**
 * When debugging the mobile site, this needs to be dev.server on a network where dev.server
 * is defined in the DNS on the router. In that way the mobile can connect to the site etc. without
 * any cross site problems.
 */
export const localhost = 'dev.server';
class ApiServerURL extends URL {
    constructor(path) {
        try {
            super(`${API_URL}${path}`, API_URL);
        }
        catch (error) {
            console.error('Unable to create URL for %o', path);
        }
    }
}
export default ApiServerURL;
