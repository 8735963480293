import * as React from 'react';
import { Banner } from '@symphony-ui/uitoolkit-components';
import { BannerType } from '@symphony-ui/uitoolkit-components/components/banner/interfaces';
import { envs } from '../utils/system/envs-constants';
const ConnectivityAlert = () => {
    const NETWORK_ERROR = React.useMemo(() => ({ content: 'You are working offline. Cannot connect to the Network', variant: BannerType.ERROR }), []);
    const NETWORK_ERROR_DEV = React.useMemo(() => ({ content: 'You are working offline. Some third party assets are unavailable', variant: BannerType.WARNING }), []);
    const NETWORK_RECOVERY = React.useMemo(() => ({ content: 'Network access restored!', variant: BannerType.SUCCESS }), []);
    const [message, setMessage] = React.useState();
    const [isApiLive, setApiLive] = React.useState(true);
    /**
   * DEV:
   * navi     server
   * off      on       ==> not offline (partly)  ==> NETWORK_ERROR_DEV
   * on       off      ==> offline               ==> SERVER_ERROR
   * off      off      ==> offline               ==> SERVER_ERROR
   * on       on       ==> not offline
  
    *            on      (server)             off
    *   on                                SERVER_ERROR
    *  (navi)
    *   off   NETWORK_ERROR_DEV           SERVER_ERROR
    *
  
   * PROD:
   * navi     server
   * off      on       ==> offline                ==> NETWORK_ERROR
   * on       off      ==> offline                ==> SERVER_ERROR
   * off      off      ==> offline                ==> NETWORK_ERROR
   * on       on       ==> not offline
  *
  *            on      (server)             off
  *   on                                SERVER_ERROR
  *  (navi)
  *   off    NETWORK_ERROR              NETWORK_ERROR
  *
  
  */
    /**
     * If we go offline, the only remedy for that is to go back online.
     *
     * For DEV it is (mostly) irrelevant, apart from e.g. FX Rates. If we do not have Server Access
     * yet, then we keep that message. If we were fine before, then we send a warning.
     *
     * For PROD we just need to wait for it to come online, and only then we check again
     * if we have Server access.
     */
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = () => {
            if (process.env.currEnv === envs.DEV && message === null) {
                // In DEV, we do not override the SERVER_ERROR message. Only when there is no message yet we
                // add the warning.
                setMessage(NETWORK_ERROR_DEV);
            }
            else if (process.env.currEnv === envs.PROD) {
                setMessage(NETWORK_ERROR);
            }
        };
        window.addEventListener('offline', callback, { signal: abortController.signal });
        return () => abortController.abort();
    }, [message, NETWORK_ERROR, NETWORK_ERROR_DEV]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = (event) => {
            if (event.detail && isApiLive === false) {
                setMessage({ content: 'This works!', variant: BannerType.SUCCESS });
                setApiLive(true);
            }
            else if (event.detail === false && isApiLive === true) {
                setMessage({ content: 'Unable to connect to the API Server as it is restarting.', variant: BannerType.ERROR });
                setApiLive(false);
            }
        };
        window.addEventListener('api.status', callback, { signal: abortController.signal });
        return () => abortController.abort();
    }, [isApiLive, NETWORK_ERROR]);
    /**
     * Back online. We would now like to:
     * - check if you have access to the server. If yes, show a
     */
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = () => {
            setMessage(NETWORK_RECOVERY);
        };
        window.addEventListener('online', callback, { signal: abortController.signal });
        return () => abortController.abort();
    }, [NETWORK_RECOVERY]);
    const onClose = React.useCallback(() => {
        setMessage(undefined);
    }, []);
    return message !== undefined ? (React.createElement(Banner, { content: message.content || '', isClosable: true, onClose: onClose, show: 'content' in message, variant: message.variant })) : undefined;
};
export default ConnectivityAlert;
