import { escapeRegex, unescapeRegex } from './regexUtils';
class Regex {
    markers;
    template;
    constructor({ markers, template, }) {
        this.markers = markers ?? [];
        this.template = template;
    }
    isEmpty() {
        return this.markers.length === 0 && this.template.length === 0;
    }
    safeTemplate() {
        return unescapeRegex(this.template.replace(/{}/g, '\u{25CF}'));
    }
    getMarkers() {
        return this.markers;
    }
    getTemplate() {
        return this.template;
    }
    removeMarker(m, text) {
        let index = -1;
        for (let i = 0; i < m + 1; i += 1) {
            index = this.template.indexOf('{}', index + 1);
        }
        const newRegex = new Regex({
            markers: this.markers,
            template: this.template.substring(0, index) + text + this.template.substring(index + 2),
        });
        newRegex.markers.splice(m, 1);
        return newRegex;
    }
    updateValue(value) {
        const newRegex = new Regex({
            markers: this.markers,
            template: escapeRegex(value),
        });
        const numberOfMarkers = (value.match(/{}/g) ?? []).length;
        while (newRegex.markers.length < numberOfMarkers) {
            newRegex.markers.push('');
        }
        while (newRegex.markers.length > numberOfMarkers) {
            this.markers.pop();
        }
        return newRegex;
    }
    updateMarker(m, value) {
        const newRegex = new Regex({
            markers: this.markers,
            template: this.template,
        });
        newRegex.markers[m] = value;
        return newRegex;
    }
}
export default Regex;
