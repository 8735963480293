import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import DailyEmailCreator from './DailyEmailCreator';
import EmailTrackerTable from './EmailTrackerTable';
import Interprice from './Interprice';
const DailyEmails = () => {
    const { updateView, view } = React.useContext(NavigationContext);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('CREATOR');
        }
    }, [view, updateView]);
    switch (view) {
        case 'CREATOR':
            return (React.createElement(DailyEmailCreator, null));
        case 'TRACKER':
            return (React.createElement(EmailTrackerTable, null));
        case 'INTERPRICE':
            return (React.createElement(Interprice, null));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default DailyEmails;
