import * as React from 'react';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import SecuritiesTable from '../components/results/SecuritiesTable';
import { Source } from '../sources/sources';
const OverviewCategory = ({ securities, title }) => {
    const currencies = React.useMemo(() => new Map(), []);
    if (securities.length === 0) {
        return null;
    }
    return (React.createElement(Card, null,
        React.createElement(Typography, { type: "h1" }, title),
        React.createElement(SecuritiesTable, { currencies: currencies, hasError: false, isLoading: false, filteredData: securities, source: Source.EUROCLEAR })));
};
export default OverviewCategory;
