import * as React from 'react';
import { Button, Modal, ModalFooter, Typography, } from '@symphony-ui/uitoolkit-components';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import './Footer.scss';
const Footer = () => {
    const [modalContent, setModalContent] = React.useState();
    const [show, setShow] = React.useState(false);
    const clickCookiePolicy = React.useCallback(() => {
        setModalContent(React.createElement(CookiePolicy, null));
        setShow(true);
    }, []);
    const clickOk = React.useCallback(() => {
        setModalContent(undefined);
        setShow(false);
    }, []);
    const clickPrivacyPolicy = React.useCallback(() => {
        setModalContent(React.createElement(PrivacyPolicy, null));
        setShow(true);
    }, []);
    const clickTermsOfService = React.useCallback(() => {
        setModalContent(React.createElement(TermsOfService, null));
        setShow(true);
    }, []);
    return (React.createElement("div", { id: "footer" },
        React.createElement(Modal, { closeButton: true, parentNode: document.body, show: show, size: "full-width" },
            modalContent,
            React.createElement(ModalFooter, null,
                React.createElement(Button, { onClick: clickOk }, "OK"))),
        React.createElement("div", { id: "co-footer-buttons" },
            React.createElement(Button, { onClick: (clickPrivacyPolicy), variant: "tertiary" }, "Privacy Policy"),
            React.createElement(Button, { onClick: clickCookiePolicy, variant: "tertiary" }, "Cookie policy"),
            React.createElement(Button, { onClick: clickTermsOfService, variant: "tertiary" }, "Terms of Service")),
        React.createElement("div", { id: "co-copyright" },
            React.createElement(Typography, { type: "small" }, "\u00A9 2023 Capital Markets Online Ltd. All rights reserved."))));
};
export default Footer;
