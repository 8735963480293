import * as React from 'react';
import CustomTable from 'components/custom-table/CustomTable';
import WebPushCheckbox from './WebPushCheckbox';
const WebPushTestTable = ({ isLoading, responses, setSelection }) => {
    const columns = React.useMemo(() => [
        {
            accessorKey: 'user',
            header: 'User',
            size: 40,
        },
        {
            accessorKey: 'endPoint',
            header: 'End Point',
        },
        {
            cell: WebPushCheckbox,
            header: () => null,
            id: 'webpushTickbox',
            meta: { className: 'co-center' },
            size: 28,
        },
        {
            accessorKey: 'statusCode',
            header: 'Code',
            size: 28,
        },
        {
            accessorKey: 'body',
            header: 'Body',
        },
    ], []);
    return (React.createElement(CustomTable, { columns: columns, data: responses, emptyMessage: "No responses from web push", errorMessage: "Error getting responses from web push", hasError: false, isLoading: isLoading, name: "web-push", setSelection: setSelection }));
};
export default WebPushTestTable;
