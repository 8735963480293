/* eslint-disable react/no-array-index-key */
import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const JsonModal = ({ onClose, show, title, tranches, }) => {
    const width = React.useMemo(() => `${100 / tranches.length}%`, [tranches.length]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: show, size: "full-width" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, title)),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { display: 'flex' } }, tranches.map((tranche, index) => (React.createElement("pre", { key: index, style: { overflow: 'auto', width } }, JSON.stringify(tranche, null, 4))))))));
};
export default JsonModal;
