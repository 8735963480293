import * as React from 'react';
import { Button, Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import { typeOptions } from '../pages/transactions/overview/table/options';
const TypeModal = ({ data: { transactionId, title }, onClose }) => {
    const { apiFetchNoContent } = React.useContext(DownloadContext);
    const [isDisabled, setDisabled] = React.useState(new Map());
    const [isLoading, setLoading] = React.useState(new Map());
    React.useEffect(() => {
        const newDisabled = new Map();
        const newLoading = new Map();
        typeOptions.forEach((o) => {
            if ('value' in o) {
                newDisabled.set(o.value, false);
                newLoading.set(o.value, false);
            }
        });
        setLoading(newLoading);
        setDisabled(newDisabled);
    }, [setDisabled, setLoading]);
    const onClick = React.useCallback((event) => {
        const newDisabled = new Map();
        const newLoading = new Map();
        typeOptions.forEach((o) => {
            if ('value' in o) {
                newDisabled.set(o.value, o.value !== event.currentTarget.name);
                newLoading.set(o.value, o.value === event.currentTarget.name);
            }
        });
        setDisabled(newDisabled);
        setLoading(newLoading);
        const url = new ApiServerURL('/syndicate/transactions/update-type');
        url.searchParams.append('id', transactionId);
        url.searchParams.append('type', event.currentTarget.name);
        const request = new ServerRequest(url);
        (async () => {
            try {
                await apiFetchNoContent(request);
                onClose();
            }
            catch (error) {
                sendFetchError('Unable to update type', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, transactionId, onClose]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "medium" },
        React.createElement(ModalTitle, null, title),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { alignItems: 'center', display: 'flex', flexDirection: 'column' } }, typeOptions.map((o) => (React.createElement(Button, { disabled: 'value' in o ? isDisabled.get(o.value) : true, key: o.label, loading: 'value' in o ? isLoading.get(o.value) : false, name: 'value' in o ? o.value : '', onClick: onClick, style: { marginBottom: '0.5rem', width: '8rem' } }, o.label)))))));
};
export default TypeModal;
