import { Dropdown, TextField } from '@symphony-ui/uitoolkit-components';
import useOption from 'components/customHooks';
import Company from '../../../../../admin/models/Company';
import * as React from 'react';
import LeiDropdown from '../../overview/meta/LeiDropdown';
import LEIRecord from '../../overview/meta/LEIRecord';
import CompanyNameForm from '../../../../../admin/pages/companies/CompanyNameField';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import DownloadContext from 'contexts/DownloadContext';
const CompanyMetaForm = ({ company = new Company(), selectedCompany, setCompany }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [loadingTicker, setLoadingTicker] = React.useState(false);
    const companyOptions = useOption('/admin/companies/ISSUER/options');
    const optionsIndustry = useOption('/admin/options/industry');
    const optionsSector = useOption('/admin/options/sector');
    const optionsType = useOption('/admin/options/type');
    const optionsCountry = useOption('/admin/options/country');
    const optionsSubSector = useOption('/admin/options/sub-sector');
    const onChangeSectors = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setCompany(company.setSectors(newValue));
    }, [company, setCompany]);
    const onChangeTypes = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setCompany(company.setTypes(newValue));
    }, [company, setCompany]);
    const onChangeUltimateParent = React.useCallback((event) => {
        const eventValue = event.target.value.value;
        const updated = company.setUltimateParentId(eventValue);
        setCompany(updated);
    }, [company, setCompany]);
    const findSectors = React.useCallback((sector) => {
        if (sector === 'FNCL') {
            return 'FINANCIAL';
        }
        else if (sector === 'PUBL') {
            return 'SSA';
        }
        return 'CORPORATE';
    }, []);
    const onChangeIndustry = React.useCallback((event) => {
        const eventValue = event.target.value.value;
        let updated = company.setIndustry(eventValue);
        const main = optionsIndustry.find(o => 'options' in o && o.options.some(op => op.value === eventValue));
        if (main !== undefined && 'value' in main) {
            const newSector = findSectors(main.value);
            updated = updated.setSector(main.value).setSectors([newSector]);
        }
        setCompany(updated);
    }, [company, findSectors, optionsIndustry, setCompany]);
    const onChangeCountry = React.useCallback((event) => {
        const eventValue = event.target.value.value;
        const updated = company.setCountryId(eventValue);
        setCompany(updated);
    }, [company, setCompany]);
    const onChangeLEI = React.useCallback((event) => {
        const eventValue = event.target.value;
        const updated = company.setLei(eventValue);
        setCompany(updated);
    }, [company, setCompany]);
    const onChangeShortName = React.useCallback((event) => {
        const eventValue = event.target.value;
        const updated = company.setShortName(eventValue);
        setCompany(updated);
    }, [company, setCompany]);
    const onChangeSubSector = React.useCallback((event) => {
        const { value } = event.target.value;
        const updated = company.setSector(value);
        setCompany(updated);
    }, [company, setCompany]);
    const onChangeTickers = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = Array.isArray(eventValue) ? eventValue.map(l => l.value) : [eventValue.value];
        const updated = company.setTickers(newValue);
        setCompany(updated);
    }, [company, setCompany]);
    const setName = React.useCallback((name) => {
        const updated = company.setName(name);
        setCompany(updated);
    }, [company, setCompany]);
    const setLei = React.useCallback((l) => {
        if (l !== undefined) {
            setCompany(company.setNameLei(l));
        }
    }, [company, setCompany]);
    const optionsTicker = React.useMemo(() => {
        if (company.tickers !== undefined) {
            return company.tickers.map(t => ({ value: t, label: t }));
        }
        return [];
    }, [company.tickers]);
    const onClickTicker = React.useCallback((lei) => {
        const url = new ApiServerURL('/syndicate/meta/ticker');
        url.searchParams.append('lei', lei);
        const request = new ServerRequest(url);
        (async () => {
            setLoadingTicker(true);
            try {
                const body = await apiFetchJson(request);
                if (body.length > 0) {
                    const updated = company.setTickers(body);
                    setCompany(updated);
                }
            }
            catch (error) {
                sendFetchError('Unable to download tickers', error, request);
            }
            finally {
                setLoadingTicker(false);
            }
        })();
    }, [apiFetchJson, company, setCompany]);
    const disabledTickers = React.useMemo(() => {
        return loadingTicker;
    }, [loadingTicker]);
    React.useEffect(() => {
        if (company.lei !== undefined && company.lei.length === 20 && (company.tickers === undefined || company.tickers.length === 0)) {
            onClickTicker(company.lei);
        }
    }, [company.lei, company.tickers, onClickTicker]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CompanyNameForm, { name: company.name, setName: setName }),
        React.createElement(TextField, { label: "Short Name (abbreviation)", onChange: onChangeShortName, value: company.shortName }),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginRight: '0.5rem', width: '100%' } },
                React.createElement(Dropdown, { label: "Country", onChange: onChangeCountry, options: optionsCountry, value: optionsCountry.find(o => 'value' in o && o.value === company.countryId) })),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginLeft: '0.5rem', width: '100%' } },
                React.createElement(Dropdown, { addNewOptions: true, disabled: disabledTickers, label: "Ticker(s)", isMultiSelect: true, onChange: onChangeTickers, options: optionsTicker, value: optionsTicker }))),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginRight: '0.5rem', width: '100%' } },
                React.createElement(TextField, { label: "LEI", onChange: onChangeLEI, value: company.lei ?? '' }),
                React.createElement(LeiDropdown, { initialValue: selectedCompany, setLei: setLei }),
                company.lei?.length === 20 ? (React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(LEIRecord, { lei: company.lei }),
                    React.createElement(LEIRecord, { lei: company.lei, ultimateParent: true }))) : undefined),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginLeft: '0.5rem', width: '100%' } },
                React.createElement(Dropdown, { label: "Ultimate Parent Company", onChange: onChangeUltimateParent, options: companyOptions, value: companyOptions.find(o => 'value' in o && company.ultimateParentId === o.value) }))),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginRight: '0.5rem', width: '100%' } },
                React.createElement(Dropdown, { isDisabled: true, label: "SubSector", onChange: onChangeSubSector, options: optionsSubSector, value: optionsSubSector.filter(o => 'value' in o).find(o => o.value === company.sector) })),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginLeft: '0.5rem', width: '100%' } },
                React.createElement(Dropdown, { isDisabled: true, isMultiSelect: true, label: "Sectors", onChange: onChangeSectors, options: optionsSector, value: optionsSector.filter(o => 'value' in o).filter(o => company.sectors.includes(o.value)) }))),
        React.createElement(Dropdown, { menuPlacement: "top", label: "Industry", onChange: onChangeIndustry, options: optionsIndustry, value: optionsIndustry.filter(o => 'options' in o).flatMap(o => o.options).filter(o => 'value' in o).find(o => company.industry === o.value) }),
        React.createElement(Dropdown, { isMultiSelect: true, label: "Types", onChange: onChangeTypes, options: optionsType, value: optionsType.filter(o => 'value' in o).filter(o => company.types.includes(o.value)) })));
};
export default CompanyMetaForm;
