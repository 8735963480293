import * as React from 'react';
import DataModal from '../DataModal';
import { Button } from '@symphony-ui/uitoolkit-components';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
const DataButton = ({ subject, updateId }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [data, setData] = React.useState();
    const [showDataModal, setShowDataModal] = React.useState(false);
    const handleData = React.useCallback(() => {
        const abortController = new AbortController();
        if (updateId?.index !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/data');
            url.searchParams.append('id', updateId.transactionId);
            url.searchParams.append('index', updateId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setData(body);
                    setShowDataModal(true);
                }
                catch (error) {
                    sendFetchError('Unable to download transaction data', error, request);
                }
            })();
        }
        return () => { abortController.abort(); };
    }, [apiFetchJson, updateId?.index, updateId?.transactionId]);
    return React.createElement(React.Fragment, null,
        showDataModal ?
            React.createElement(DataModal, { onClose: () => { setShowDataModal(false); }, title: subject ?? 'no subject', data: data }) : undefined,
        React.createElement(Button, { onClick: handleData, size: "small" }, "DATA"));
};
export default DataButton;
