import CustomTable from 'components/custom-table/CustomTable';
import * as React from 'react';
import AnnaCompany from '../../models/company/AnnaCompany';
import EsmaCompany from '../../models/company/EsmaCompany';
import EuroclearCompany from '../../models/company/EuroclearCompany';
import SecCompany from '../../models/company/SecCompany';
import { Source } from '../../sources/sources';
import markupColumns from './companiesTableColumnMarkup';
const CompaniesTable = ({ companies, hasError, isLoading, setActualCompanyTags, setCompany, source, }) => {
    switch (source) {
        case Source.ANNA: {
            return (React.createElement(CustomTable, { columns: markupColumns(AnnaCompany.columns, setActualCompanyTags, setCompany, source), data: companies.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "anna-company" }));
        }
        case Source.ESMA: {
            return (React.createElement(CustomTable, { columns: markupColumns(EsmaCompany.columns, setActualCompanyTags, setCompany, source), data: companies.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "esma-company" }));
        }
        case Source.EUROCLEAR: {
            return (React.createElement(CustomTable, { columns: markupColumns(EuroclearCompany.columns, setActualCompanyTags, setCompany, source), data: companies.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "euroclear-company" }));
        }
        case Source.SEC: {
            return (React.createElement(CustomTable, { columns: markupColumns(SecCompany.columns, setActualCompanyTags, setCompany, source), data: companies.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "sec-company" }));
        }
        default: throw new Error(`Unknown source: ${source}`);
    }
};
export default CompaniesTable;
