import * as React from 'react';
import AmendedContentWrapper from './AmendedContentWrapper';
import OriginalContent from './OriginalContent';
import OriginalHTML from './OriginalHTML';
const GridViewer = ({ content, hasContentError, hasHtmlError, html, isContentLoading, isHtmlLoading, noStyle, prettify, }) => {
    const [touchStart, setTouchStart] = React.useState();
    const [touchEnd, setTouchEnd] = React.useState();
    const [visible, setVisible] = React.useState(1);
    const [gridRef, setGridRef] = React.useState();
    const [atTop, setAtTop] = React.useState();
    const [atBottom, setAtBottom] = React.useState();
    const [atLeft, setAtLeft] = React.useState();
    const [atRight, setAtRight] = React.useState();
    const handleStart = React.useCallback((e) => {
        if (gridRef !== undefined) {
            setAtTop(false);
            setAtBottom(false);
            setAtLeft(false);
            setAtRight(false);
            if (gridRef.scrollTop === 0)
                setAtTop(true);
            if (gridRef.scrollTop > (gridRef.scrollHeight - gridRef.offsetHeight - 50))
                setAtBottom(true);
            if (gridRef.scrollLeft === 0)
                setAtLeft(true);
            if (gridRef.scrollLeft > (gridRef.scrollWidth - gridRef.offsetWidth - 50))
                setAtRight(true);
        }
        setTouchEnd(undefined); // otherwise the swipe is fired even with usual touch events
        setTouchStart({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
    }, [gridRef]);
    const handleEnd = React.useCallback(() => {
        if (touchStart?.x === undefined || touchStart.y === undefined || touchEnd?.x === undefined || touchEnd.y === undefined)
            return;
        const distanceX = touchStart.x - touchEnd.x;
        const distanceY = touchStart.y - touchEnd.y;
        const isLeftSwipe = distanceX > 200;
        const isRightSwipe = distanceX < -200;
        const isUpSwipe = distanceY > 250;
        const isDownSwipe = distanceY < -250;
        if (isLeftSwipe && visible % 2 === 1 && atRight) {
            setVisible(visible + 1);
        }
        else if (isRightSwipe && visible % 2 === 0 && atLeft) {
            setVisible(visible - 1);
        }
        if (isDownSwipe && visible > 2 && atTop) {
            setVisible(visible - 2);
        }
        else if (isUpSwipe && visible < 3 && atBottom) {
            setVisible(visible + 2);
        }
    }, [atBottom, atTop, atLeft, atRight, touchEnd, touchStart, visible]);
    const handleCancel = React.useCallback((e) => {
        //    e.preventDefault();
        console.log('cancel; %o', e);
    }, []);
    const handleMove = React.useCallback((e) => {
        setTouchEnd({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
    }, []);
    return (React.createElement("div", { className: "grid-container co-update-manager", onTouchCancel: handleCancel, onTouchEnd: handleEnd, onTouchStart: handleStart, onTouchMove: handleMove },
        React.createElement(OriginalContent, { className: "co-update-panel", html: html, hasError: hasHtmlError, isLoading: isHtmlLoading, isVisible: visible === 1, noStyle: noStyle, setRef: setGridRef }),
        React.createElement(AmendedContentWrapper, { content: content, hasError: hasContentError, isLoading: isContentLoading, isVisible: visible === 2, setRef: setGridRef }),
        React.createElement(OriginalHTML, { className: "co-update-panel", html: html, hasError: hasHtmlError, isLoading: isHtmlLoading, isVisible: visible === 3, prettify: prettify, setRef: setGridRef }),
        React.createElement(OriginalHTML, { className: "co-update-panel", html: content, hasError: hasContentError, isLoading: isContentLoading, isVisible: visible === 4, prettify: prettify, setRef: setGridRef })));
};
export default GridViewer;
