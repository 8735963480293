import { defaultPermissions } from 'components/metadata/utils';
import EuroclearFilter from '../filter/EuroclearFilter';
import EuroclearQuery from '../query/EuroclearQuery';
class EuroclearSearch {
    createdBy;
    createdDate;
    database;
    filter;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    query;
    constructor() {
        this.permissions = defaultPermissions;
        this.query = new EuroclearQuery();
        this.filter = new EuroclearFilter();
    }
    static create(base) {
        const query = EuroclearQuery.create(base.query);
        const filter = Object.assign(new EuroclearFilter(), base.filter);
        return Object.assign(new EuroclearSearch(), base, { filter, query });
    }
    updateName(value) {
        return Object.assign(new EuroclearSearch(), this, { name: value });
    }
    updatePermissions(value) {
        return Object.assign(new EuroclearSearch(), this, { permissions: value });
    }
}
export default EuroclearSearch;
