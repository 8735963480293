import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import TickerEntry from './meta/TickerEntry';
import MetaPicker from './meta/MetaPicker';
const MetaModal = ({ callback, hideModal, setTranche, tranche, }) => (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: hideModal, show: true, size: "medium" },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, "Manage Meta Data")),
    React.createElement(ModalBody, null,
        React.createElement(MetaPicker, { callback: callback, errorMessage: "Unable to find LEI", label: "LEI", setTranche: setTranche, tranche: tranche, type: "lei", value: tranche.meta?.lei ?? '' }),
        React.createElement(TickerEntry, { callback: callback, setTranche: setTranche, tranche: tranche }),
        React.createElement(MetaPicker, { callback: callback, errorMessage: "Can't find Pricing Date", label: "Pricing Date", setTranche: setTranche, tranche: tranche, type: "pricingDate", value: tranche.meta?.pricingDate ? tranche.meta.pricingDate.toLocaleDateString() : '' }),
        React.createElement(MetaPicker, { callback: callback, errorMessage: "Can't find bookrunners", label: "Book Runners", setTranche: setTranche, tranche: tranche, type: "bookrunners", value: tranche.meta?.bookrunners?.map((b) => b.name).sort().join(', ') ?? "" }),
        React.createElement(MetaPicker, { callback: callback, errorMessage: "Can't find Tap information", label: "Tap", setTranche: setTranche, tranche: tranche, type: "tap", value: tranche.meta?.tap ? "Yes" : "No" }))));
export default MetaModal;
