import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
const AnnaCompanyModal = ({ company, hideModal, updateCompany }) => {
    const [data, setData] = React.useState(company);
    const [isLoading, setLoading] = React.useState(false);
    const onChangeNameLong = React.useCallback((event) => {
        const { value } = event.target;
        const updatedAnnaCompany = data.updateNameLond(value);
        setData(updatedAnnaCompany);
    }, [data]);
    const onChangeAddressItem = React.useCallback((type, item) => (event) => {
        const { value } = event.target;
        const updatedAnnaCompany = data.updateAddress(type, item, value);
        setData(updatedAnnaCompany);
    }, [data]);
    const onClickSave = React.useCallback(() => {
        (async () => {
            setLoading(true);
            const request = new ServerRequest('/nit/anna/company', {
                body: JSON.stringify(data),
                headers: { 'content-type': 'application/json' },
                method: 'PUT',
            });
            await fetch(request);
            setLoading(false);
            updateCompany(data);
            hideModal();
        })();
    }, [data, hideModal, updateCompany]);
    const test = (value) => value.includes('��');
    const items = React.useCallback(() => {
        const addressTypes = ['reg', 'hq'];
        const addressItems = ['address1', 'address2', 'city', 'country', 'postalCode'];
        const list = [];
        addressTypes.forEach((type) => {
            addressItems.forEach((item) => {
                if (company[type]?.[item] !== undefined && test(company[type][item])) {
                    list.push(React.createElement(TextField, { key: `${type}.${item}`, label: `${type} ${item}`, onChange: onChangeAddressItem(type, item), value: data[type]?.[item] ?? '' }));
                }
            });
        });
        return list;
    }, [company, data, onChangeAddressItem]);
    return (React.createElement(Modal, { closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalTitle, null, "Correct Diacriticals"),
        React.createElement(ModalBody, null,
            test(company.nameLong) ? React.createElement(TextField, { label: "Name Long", onChange: onChangeNameLong, value: data.nameLong || '' }) : '',
            items()),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: isLoading, onClick: onClickSave }, "Save"))));
};
export default AnnaCompanyModal;
