import QualifiedSearch from '../QualifiedSearch';
import TimeRange from '../TimeRange';
class EuroclearQuery {
    codecreationdate;
    currency;
    market;
    maturitydate;
    q;
    rate;
    ratetype;
    securitytype;
    getCreationDate = () => this.codecreationdate;
    getCurrency = () => this.currency;
    getMaturity = () => this.maturitydate;
    static create(base) {
        const newQuery = Object.assign(new EuroclearQuery(), base);
        if (newQuery.maturitydate != null) {
            newQuery.maturitydate = new TimeRange(newQuery.maturitydate);
        }
        if (newQuery.codecreationdate != null) {
            newQuery.codecreationdate = new TimeRange(newQuery.codecreationdate);
        }
        if (newQuery.q != null) {
            newQuery.q = QualifiedSearch.create(newQuery.q);
        }
        if (newQuery.rate != null) {
            newQuery.rate = QualifiedSearch.create(newQuery.rate);
        }
        return newQuery;
    }
}
export default EuroclearQuery;
