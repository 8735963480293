import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
import LEIRecordCard from './LEIRecordCard';
const LEIRecord = ({ lei, ultimateParent = false }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [entity, setEntity] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL(`/gleif/lei-records/${lei}${ultimateParent ? '/ultimate-parent' : ''}`);
        const request = new ServerRequest(url);
        (async () => {
            try {
                setLoading(true);
                const body = await apiFetchJson(request, abortController.signal);
                const entity = body?.entity;
                setEntity(entity);
            }
            catch (error) {
                sendFetchError('Unable to download lei information', error, request);
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, lei, ultimateParent]);
    return (React.createElement(LEIRecordCard, { loading: isLoading, entity: entity }));
};
export default LEIRecord;
