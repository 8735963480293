class DateRange {
    start;
    end;
    constructor({ start, end }) {
        this.start = start;
        this.end = end;
    }
    static print(date) {
        return date.toLocaleString();
    }
    toString() {
        if (this.start !== undefined && this.end !== undefined) {
            return `from ${this.start.toLocaleString()} to ${this.end.toLocaleString()}`;
        }
        if (this.start && !this.end) {
            return `from ${this.start.toLocaleString()}`;
        }
        if (!this.start && this.end) {
            return `up to ${this.end.toLocaleString()}`;
        }
        return 'Any';
    }
    static from(start, end) {
        const startDate = start !== '' ? new Date(start) : undefined;
        const endDate = end !== '' ? new Date(start) : undefined;
        const newDateRange = { start: startDate, end: endDate };
        return !DateRange.isEmpty(newDateRange) ? new DateRange(newDateRange) : undefined;
    }
    static isEmpty({ start, end }) {
        return (start === undefined && end === undefined);
    }
}
export default DateRange;
