import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
import './BookrunnersCell.css';
const BookrunnersCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    const useGlobalCoordinators = React.useMemo(() => {
        const { meta } = row.original;
        return meta?.bookrunners !== undefined && meta.bookrunners.length > 8 && meta.bookrunners.some(b => b.roles?.includes('GLOBAL_COORDINATOR'));
    }, [row.original]);
    const originalBookrunners = React.useMemo(() => {
        const { meta } = row.original;
        if (meta?.bookrunners !== undefined) {
            const unsorted = useGlobalCoordinators ? meta.bookrunners.filter(b => b.roles?.includes('GLOBAL_COORDINATOR')) : meta.bookrunners;
            const bookrunners = unsorted.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            return bookrunners;
        }
        return undefined;
    }, [row.original, useGlobalCoordinators]);
    const prefix = React.useMemo(() => {
        return useGlobalCoordinators ? (originalBookrunners?.length === 1 ? "SGC" : "JGC") : undefined;
    }, [originalBookrunners, useGlobalCoordinators]);
    const bookrunnersText = React.useMemo(() => {
        if (originalBookrunners === undefined) {
            return undefined;
        }
        return (prefix !== undefined ? prefix + ": " : "") + originalBookrunners.map(b => b.name).join(", ");
    }, [originalBookrunners, prefix]);
    const bookrunnersHtml = React.useMemo(() => {
        if (originalBookrunners === undefined) {
            return undefined;
        }
        const nodes = originalBookrunners.map(b => {
            if (b.partyId === undefined) {
                return (React.createElement("span", { key: b.name, className: 'co-br' }, b.name));
            }
            else {
                return b.name;
            }
        }).flatMap((e, index) => {
            if (index < originalBookrunners.length - 2) {
                return [e, ", "];
            }
            else if (index === originalBookrunners.length - 2) {
                return [e, " and "];
            }
            else {
                return [e];
            }
        });
        if (prefix !== undefined) {
            nodes.unshift(prefix + ": ");
        }
        return nodes;
    }, [originalBookrunners, prefix]);
    React.useEffect(() => {
        setValue(bookrunnersText);
    }, [bookrunnersText]);
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && newValue !== undefined) {
            const formData = new FormData();
            formData.append('value', newValue);
            formData.append('key', column.id);
            formData.append('transactionId', row.original.transaction.id);
            formData.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest('/syndicate/transactions/override', { body: formData, method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const response = body.map((v) => new TrancheWrapper(v));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                sendFetchError('Unable to update override information', error, request);
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setEditing(false);
                setLoading(false);
            }
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const onBlur = React.useCallback(() => {
        (async () => save(value))();
    }, [save, value]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => {
                if (editableCellRef.current !== null) {
                    editableCellRef.current.focus();
                }
                await save(value);
            })();
        }
    }, [save, value]);
    const removeOverride = React.useCallback(() => {
        (async () => {
            await save('');
        })();
    }, [save]);
    const copyValue = React.useCallback(async () => {
        const calculated = bookrunnersText;
        let text;
        if (typeof calculated === 'string') {
            text = calculated;
        }
        if (text !== undefined) {
            const t = { type: 'text/plain' };
            const clipboardText = { columnId: column.id, text };
            const p = JSON.stringify(clipboardText);
            const blob = new Blob([p], t);
            const items = new ClipboardItem({ 'text/plain': blob });
            await navigator.clipboard.write([items]);
        }
    }, [bookrunnersText, column.id]);
    const pasteValue = React.useCallback(async () => {
        const clipboard = await navigator.clipboard.read();
        const blob = await clipboard[0].getType('text/plain');
        const text = await blob.text();
        try {
            const clipboardText = JSON.parse(text);
            if (typeof clipboardText === 'object' && clipboardText !== null && 'columnId' in clipboardText && 'text' in clipboardText && clipboardText.columnId === column.id && typeof clipboardText.text === 'string') {
                setValue(clipboardText.text);
                await save(clipboardText.text);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }
        catch (error) {
            // the current value in the clipboard is not a saved value
        }
    }, [column.id, save]);
    const content = React.useMemo(() => {
        if (isEditing) {
            return (React.createElement("div", { className: "co-text-edit-container" },
                React.createElement("input", { autoFocus: true, className: "co-editable-input", disabled: isLoading, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value }),
                ";"));
        }
        const { overrideMap } = row.original;
        if (overrideMap?.bookrunners !== undefined) {
            return overrideMap.bookrunners;
        }
        return bookrunnersHtml;
    }, [bookrunnersHtml, isEditing, isLoading, onBlur, onChange, onKeyUp, row.original, value]);
    return (React.createElement(EditableCell, { copyValue: copyValue, errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, pasteValue: pasteValue, removeOverride: removeOverride, setEditing: setEditing, title: bookrunnersText }, content));
};
export default BookrunnersCell;
