import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import { getOptions } from 'utils/change';
import QualifiedSearch from '../../models/QualifiedSearch';
import './QualifiedSearch.css';
const QualifiedSearchInput = ({ disabled, label, onChange, regex, tooltip, value, }) => {
    const [data, setData] = React.useState(value || new QualifiedSearch());
    const onChangeDropdown = React.useCallback((event) => {
        const clone = Object.assign(new QualifiedSearch(), data, { qualifier: event.target.value.value });
        setData(clone);
    }, [data]);
    const onChangeDropdownMulti = React.useCallback((event) => {
        const clone = Object.assign(new QualifiedSearch(), data, { text: event.target.value.map((v) => v.value) });
        setData(clone);
    }, [data]);
    React.useEffect(() => {
        const updatedQualifiedSearch = {
            target: {
                value: Object.assign(new QualifiedSearch(), data),
            },
        };
        onChange(updatedQualifiedSearch);
    }, [data]);
    return (React.createElement("div", { className: "tk-input-group tag-input" },
        React.createElement(LabelTooltipDecorator, { label: label, tooltip: tooltip }),
        React.createElement("div", { className: "co-input-container", id: "search-group" },
            React.createElement(Dropdown, { addNewOptions: true, className: "first-one", size: "large", isDisabled: disabled, menuPortalStyles: { background: 'green' }, style: { background: 'green' }, onChange: onChangeDropdown, options: QualifiedSearch.options, value: getOptions(data.qualifier, QualifiedSearch.options) }),
            React.createElement(Dropdown, { addNewOptions: true, className: "second-one", closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, onChange: onChangeDropdownMulti, options: data.text.map((t) => ({ label: t, value: t })), value: data.text.map((t) => ({ label: t, value: t })) }))));
};
export default QualifiedSearchInput;
