import UserContext from 'contexts/UserContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError, setOnline } from 'utils/messageUtils';
export default function useOption(path) {
    const [data, setData] = React.useState([]);
    const { authorizedFetch } = React.useContext(UserContext);
    const load = React.useCallback(async (request, signal) => {
        try {
            const response = await authorizedFetch(request, signal);
            setOnline();
            if (response.status === 200) {
                const body = await response.json();
                const hasLabel = (value) => typeof value === 'object' && value !== null && 'label' in value;
                const sorted = body.sort((a, b) => hasLabel(a) && hasLabel(b) ? (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1) : 0);
                setData(sorted);
            }
        }
        catch (error) {
            sendFetchError('Unable to download options', error, request);
        }
    }, [authorizedFetch]);
    /**
     * When one of the list of options is changed, a web push message is sent to notify all clients.
     * This will remove the list of options from the cache, and using a message from the ServiceWorker
     * to this hook the data will be reloaded for all user.
    */
    React.useEffect(() => {
        const abortController = new AbortController();
        const handleMessage = (event) => {
            const { tag, value } = event.data;
            const myUrl = URL.parse(value);
            if (tag === 'remove-from-cache' && myUrl?.pathname === path) {
                const request = new ServerRequest(myUrl.pathname);
                (async () => load(request, abortController.signal))();
            }
        };
        const channel = new BroadcastChannel('sw-data');
        channel.addEventListener('message', handleMessage, { signal: abortController.signal });
        return () => {
            channel.removeEventListener('message', handleMessage);
            abortController.abort();
        };
    }, [load, path]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest(path);
        (async () => load(request, abortController.signal))();
        return () => abortController.abort();
    }, [authorizedFetch, load, path]);
    return data;
}
