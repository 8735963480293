import { Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import CheckFilterPanel from './CheckFilterPanel';
import NumberFilter from './NumberFilter';
import TextFilterPanel from './TextFilterPanel';
import './Filter.scss';
import Popup from '../action/Popup';
const Filter = function FilterElement({ column, isNumber, }) {
    const [show, setShow] = React.useState(false);
    const [popupContent, setPopupContent] = React.useState(undefined);
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        if (column.columnDef.meta?.dropdown !== undefined) {
            const { dropdown } = column.columnDef.meta;
            setOptions(dropdown.options);
        }
    }, [column.columnDef.meta]);
    const close = React.useCallback(() => {
        setShow(false);
    }, []);
    const onClick = React.useCallback(() => {
        setShow(true);
    }, []);
    React.useEffect(() => {
        if ('meta' in column.columnDef && column.columnDef.meta?.Panel !== undefined) {
            setPopupContent((React.createElement(column.columnDef.meta.Panel, { close: close, column: column, isNumber: isNumber, sortedUniqueValues: options })));
        }
        else if (isNumber) {
            setPopupContent((React.createElement(NumberFilter, { close: close, column: column })));
        }
        else if (options.length > 1 && options.length < 24) {
            setPopupContent(React.createElement(CheckFilterPanel, { close: close, column: column, options: options }));
        }
        else {
            setPopupContent(React.createElement(TextFilterPanel, { close: close, column: column, sortedUniqueValues: options }));
        }
    }, [close, column, isNumber, setPopupContent, options]);
    return (React.createElement("div", null,
        React.createElement(Icon, { className: column.getIsFiltered() ? 'co-filtered' : '', iconName: "filter", onClick: onClick }),
        show
            ? (React.createElement(Popup, { close: close }, popupContent))
            : null));
};
export default Filter;
