import { defaultPermissions } from 'components/metadata/utils';
import SecFilter from '../filter/SecFilter';
import SecQuery from '../query/SecQuery';
class SecSearch {
    createdBy;
    createdDate;
    database;
    filter;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    query;
    constructor() {
        this.permissions = defaultPermissions;
        this.query = new SecQuery();
        this.filter = new SecFilter();
    }
    static create(base) {
        const query = Object.assign(new SecQuery(), base.query);
        const filter = Object.assign(new SecFilter(), base.filter);
        return Object.assign(new SecSearch(), base, { filter, query });
    }
    updateName(value) {
        return Object.assign(new SecSearch(), this, { name: value });
    }
    updatePermissions(value) {
        return Object.assign(new SecSearch(), this, { permissions: value });
    }
}
export default SecSearch;
