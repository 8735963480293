import * as React from 'react';
import { BannerType, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import { createGrid, createHeaders } from 'components/custom-table/data-grid';
import exportToExcelOverview from 'components/custom-table/data-excel';
import RenderingContext from 'contexts/RenderingContext';
import MessageContext from 'contexts/MessageContext';
import PopupButton from '../action/PopupButton';
import exportToCsv from '../data-csv';
import copySpreadSheet from '../data-copy';
const ExportButton = function ExportButtonElement({ exportHeaders, groupData, table, }) {
    const { printDate } = React.useContext(RenderingContext);
    const { setMessage } = React.useContext(MessageContext);
    const exportToCsvButton = React.useCallback(() => {
        const d1 = table.getRowModel().rows.map((r) => r.original);
        const headers = table.getFlatHeaders();
        const gridHeaders = createHeaders(headers);
        const gridData = createGrid(headers, d1, table.options.meta?.idFunction);
        exportToCsv(gridHeaders, gridData, exportHeaders, printDate);
        setMessage({ content: 'CSV File created', variant: BannerType.SUCCESS });
    }, [exportHeaders, printDate, setMessage, table]);
    const exportToExcel = React.useCallback(() => {
        const d1 = table.getRowModel().rows.map((r) => r.original);
        const headers = table.getFlatHeaders();
        const gridHeaders = createHeaders(headers);
        const gridData = createGrid(headers, d1, table.options.meta?.idFunction);
        (async () => {
            await exportToExcelOverview(gridHeaders, gridData, exportHeaders);
            setMessage({ content: 'Excel file created', variant: BannerType.SUCCESS });
        })();
    }, [exportHeaders, setMessage, table]);
    const copySpreadsheetButton = React.useCallback(() => {
        const d1 = table.getRowModel().rows.map((r) => r.original);
        const headers = table.getFlatHeaders();
        const gridHeaders = createHeaders(headers);
        const gridData = createGrid(headers, d1, table.options.meta?.idFunction);
        (async () => {
            await copySpreadSheet(gridHeaders, gridData, exportHeaders, groupData, printDate);
            setMessage({ content: 'Content copied to Clipboard', variant: BannerType.SUCCESS });
        })();
    }, [exportHeaders, groupData, printDate, setMessage, table]);
    const childFn = React.useCallback((close) => [
        (React.createElement(DropdownMenuItem, { key: "copy", onClick: () => {
                copySpreadsheetButton();
                close();
            } }, "Copy")),
        (React.createElement(DropdownMenuItem, { key: "excel", onClick: () => {
                exportToExcel();
                close();
            } }, "Excel")),
        (React.createElement(DropdownMenuItem, { key: "csv", onClick: () => {
                exportToCsvButton();
                close();
            } }, "CSV"))
    ], [copySpreadsheetButton, exportToCsvButton, exportToExcel]);
    return (React.createElement(PopupButton, { childFn: childFn, iconName: "share", style: { transform: 'translate(-3.5rem, -0.5rem)' } }));
};
export default ExportButton;
