import IndexedRegex from './IndexedRegex';
import Regex from './Regex';
class KeyValueRegex {
    id;
    keys;
    regexes = [];
    constructor({ id, keys, regexes }) {
        this.id = id;
        this.keys = keys;
        this.regexes = regexes?.map((r) => new Regex(r)) ?? [];
    }
    add(regex, index) {
        if (index !== undefined) {
            this.regexes[index] = regex;
        }
        else {
            this.regexes.push(regex);
        }
        return this;
    }
    updateRegexes(regexes) {
        return new KeyValueRegex({ id: this.id, keys: this.keys, regexes });
    }
    remove(index) {
        this.regexes.splice(index, 1);
    }
    switch(index1, index2) {
        const newKeyValueRegex = ({ id: this.id, keys: this.keys, regexes: this.regexes });
        newKeyValueRegex.regexes[index1] = this.regexes[index2];
        newKeyValueRegex.regexes[index2] = this.regexes[index1];
        return newKeyValueRegex;
    }
    toIndexedRegexes() {
        const s = this.id;
        if (s === undefined) {
            throw new Error('Unable to create indexed Regex without an id or index in the KeyValueRegex');
        }
        return this.regexes.map((r, index) => new IndexedRegex({
            id: s,
            index,
            markers: r.markers,
            template: r.template,
        }));
    }
}
export default KeyValueRegex;
