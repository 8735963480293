function exportToCsv(gridHeaders, gridData, exportHeaders, { printDate }) {
    const lines = exportHeaders ? [gridHeaders.map((h) => h.header).join(',')] : [];
    const mapCell = (cell) => {
        if (typeof cell === 'string') {
            return cell.includes(',') ? `"${cell}"` : cell;
        }
        if (typeof cell === 'number') {
            return cell.toString();
        }
        if (typeof cell === 'object' && cell !== null && 'value' in cell && cell.value !== undefined) {
            if (cell.value instanceof Date) {
                if ('options' in cell && cell.options !== undefined) {
                    return printDate(cell.value, cell.options);
                }
                return printDate(cell.value);
            }
            else if (typeof cell.value === 'number') {
                return cell.value.toString();
            }
        }
        if (cell instanceof Date) {
            return printDate(cell);
        }
        return '';
    };
    gridData.forEach((l) => {
        const lineContent = l.data.map(mapCell).join(',');
        lines.push(lineContent);
    });
    const content = lines.join('\n');
    const csvFile = new Blob([content], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = 'out';
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
export default exportToCsv;
