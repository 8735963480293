import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TimestampDropdown = ({ menuIsOpen = false, style, value, onChange }) => {
    const options = React.useMemo(() => [
        { label: 'Today', value: 'T' },
        { label: 'Last 24 hrs', value: 'D' },
        { label: 'This week', value: 'TW' },
        { label: 'Last 7 days', value: 'W' },
        { label: 'This month', value: 'TM' },
        { label: 'This year', value: 'TY' },
        { label: 'All', value: 'A' },
    ], []);
    const finalStyle = React.useMemo(() => {
        if (style === undefined) {
            return { width: '10rem' };
        }
        return Object.assign(style, { width: '10rem' });
    }, [style]);
    return (React.createElement("div", { style: finalStyle },
        React.createElement(Dropdown, { onChange: onChange, options: options, value: options.find((o) => 'value' in o && o.value === value) })));
};
export default TimestampDropdown;
