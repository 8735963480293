export var App;
(function (App) {
    App["SYND"] = "SYND";
    App["NIT"] = "NIT";
    App["IPO"] = "IPO";
    App["MACRO"] = "MACRO";
    App["MTN"] = "MTN";
    App["ADMIN"] = "ADMIN";
})(App || (App = {}));
export const appFromModule = (name) => {
    switch (name) {
        case 'cmo.syndicate': return App.SYND;
        case 'cmo.nit': return App.NIT;
        case 'cmo.ipo': return App.IPO;
        case 'cmo.release': return App.MACRO;
        case 'cmo.core': return App.ADMIN;
        case 'cmo.mtn': return App.MTN;
        default: throw new Error(`No app with name ${name}`);
    }
};
export const moduleFromApp = (app) => {
    switch (app) {
        case App.SYND: return 'cmo.syndicate';
        case App.NIT: return 'cmo.nit';
        case App.MTN: return 'cmo.mtn';
        case App.IPO: return 'cmo.ipo';
        case App.MACRO: return 'cmo.release';
        case App.ADMIN: return 'cmo.core';
        default: throw new Error('No app with name');
    }
};
