import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import CrudService from 'services/CrudService';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import Party from '../../models/Party';
import PartyForm from './PartyForm';
import PartyTable from './PartyTable';
import PartyUnknowns from './PartyUnknowns';
const Parties = () => {
    const download = React.useContext(DownloadContext);
    const { updateView, view } = React.useContext(NavigationContext);
    const [details, setDetails] = React.useState();
    const [parties, setParties] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const service = React.useMemo(() => new CrudService({ download, name: 'Parties', path: '/syndicate/parties' }), [download]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            setLoading(true);
            try {
                const body = await service.getPlus(abortController.signal);
                const downloadedParties = body.map((u) => Object.assign(new Party(), u));
                setParties(downloadedParties);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [service]);
    const deleteFn = React.useCallback(({ row: { original: party } }) => {
        (async () => {
            try {
                await service.delete(party);
                const filteredParties = parties.filter((u) => u.id !== party.id);
                setParties(filteredParties);
            }
            catch (error) {
                //
            }
        })();
    }, [parties, service]);
    const edit = React.useCallback(({ row: { original: party } }) => {
        setDetails(party);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback((goback) => async (data) => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedParty = Object.assign(new Party(), body);
            const updatedParties = CrudService.updateData(parties, updatedParty);
            setParties(updatedParties);
            if (goback) {
                updateView('TABLE');
            }
        }
        catch (error) {
            //
        }
    }, [parties, service, updateView]);
    const ticker = React.useCallback(() => updateView('TICKER'), [updateView]);
    const unknowns = React.useCallback(() => updateView('UNKNOWNS'), [updateView]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [view, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(PartyForm, { party: details, save: save(true) }));
        case 'UNKNOWNS':
            return (React.createElement(PartyUnknowns, { buttonLoading: isLoading, save: save(false), parties: parties }));
        case 'TABLE':
            return (React.createElement(PartyTable, { deleteFn: deleteFn, edit: edit, parties: parties, hasError: hasError, isLoading: isLoading, ticker: ticker, unknowns: unknowns }));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default Parties;
