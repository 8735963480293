import * as React from 'react';
import { BannerType, Button, Dropdown, Icon, Modal, ModalBody, ModalFooter, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import NavigationContext from 'contexts/NavigationContext';
import { sendError, sendFetchError, sendMessage } from 'utils/messageUtils';
import SyndicateContext from '../../context/SyndicateContext';
import Party from '../../models/Party';
const PartyUnknowns = ({ parties, removeName, saveName, buttonLoading, onClose }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { updateTab } = React.useContext(NavigationContext);
    const { setUpdateId } = React.useContext(SyndicateContext);
    const [loadingSourceButton, setLoadingSourceButton] = React.useState(false);
    const [partyId, setPartyId] = React.useState();
    const [name, setName] = React.useState();
    const [newName, setNewName] = React.useState('');
    const buttonRef = React.useRef(null);
    const onChangeName = React.useCallback((event) => {
        setName(event.target.value.value);
        setNewName('');
        setPartyId(undefined);
    }, []);
    const onChangeInput = React.useCallback((event) => {
        setNewName(event.target.value);
    }, []);
    const onChangeParty = React.useCallback((event) => {
        setPartyId(event.target.value?.value);
    }, []);
    const findSource = React.useCallback(() => {
        if (name !== undefined) {
            setLoadingSourceButton(true);
            const url = new ApiServerURL('/syndicate/transactions/find-with-manager');
            url.searchParams.append('name', name);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    if (body !== null) {
                        setUpdateId(body);
                        updateTab('overview', 'UPDATE');
                    }
                    else {
                        sendMessage("'" + name + "' no longer is a name used for a party, so it will be removed", BannerType.INFO);
                        removeName(name);
                    }
                }
                catch (error) {
                    sendFetchError('Unable to find source', error, request);
                    //
                }
                finally {
                    setLoadingSourceButton(false);
                }
            })();
        }
    }, [apiFetchJson, name, removeName, updateTab, setUpdateId]);
    const saveParty = React.useCallback(() => {
        if (name !== undefined) {
            const updatedParty = parties.find((p) => p.id === partyId) ?? new Party({ name: newName !== '' ? newName : name, names: [] });
            updatedParty.names.push(name);
            const newSet = new Set(updatedParty.names);
            updatedParty.names = [...newSet];
            (async () => {
                try {
                    await saveName(name, updatedParty);
                    setPartyId(undefined);
                    setNewName('');
                }
                catch (error) {
                    sendError("Unable to save unknown parties", error);
                }
            })();
        }
    }, [name, newName, partyId, parties, saveName]);
    const onClearParty = React.useCallback(() => {
        setPartyId(undefined);
    }, []);
    const nameOptions = React.useMemo(() => {
        const options = parties.find((p) => p.name === 'unknown')?.names.sort().map((t) => ({ label: t, value: t })) ?? [];
        if (options.length > 0) {
            setName(options[0].value);
        }
        return options;
    }, [parties]);
    const partyOptions = React.useMemo(() => parties.map((t) => ({ label: t.name, value: t.id ?? "" })), [parties]);
    const disabledButton = React.useMemo(() => (name === undefined), [name]);
    const disabledSourceButton = React.useMemo(() => (name === undefined), [name]);
    const disabledNewName = React.useMemo(() => (partyId !== undefined), [partyId]);
    const disabledParty = React.useMemo(() => (newName !== ''), [newName]);
    const ddvalue = partyOptions.find(o => 'value' in o && o.value === partyId);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: 'medium' },
        React.createElement(ModalTitle, null, "Allocate Party"),
        React.createElement(ModalBody, null,
            React.createElement(Dropdown, { label: "Name to Allocate", name: "parties", onChange: onChangeName, options: nameOptions, value: nameOptions.find(o => 'value' in o && o.value === name) }),
            React.createElement(Dropdown, { isDisabled: disabledParty, isInputClearable: true, label: "Existing Party", name: "names", onChange: onChangeParty, onClear: onClearParty, options: partyOptions, value: ddvalue }),
            React.createElement(TextField, { disabled: disabledNewName, label: "New Name to Save", onChange: onChangeInput, value: newName || '' })),
        React.createElement(ModalFooter, null,
            React.createElement("div", { className: "form-buttons", ref: buttonRef },
                React.createElement(Button, { disabled: disabledButton, loading: buttonLoading, onClick: saveParty },
                    newName !== '' ? 'SAVE AS NEW' : undefined,
                    partyId != null ? 'ADD TO EXISTING' : undefined,
                    partyId == null && newName === '' ? 'SAVE AS NEW UNDER OWN NAME' : undefined),
                React.createElement(Button, { disabled: disabledSourceButton, iconButton: true, loading: loadingSourceButton, onClick: findSource },
                    React.createElement(Icon, { iconName: 'search' }))))));
};
export default PartyUnknowns;
