import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ContentModal from '../ContentModal';
import DataTable from './data-table/DataTable';
import DataViewerForm from './data-viewer-form/DataViewerForm';
import DownloadContext from 'contexts/DownloadContext';
import ItemData from '../../../models/regexResponses/ItemData';
import KeyValuesData from '../../../models/regexResponses/KeyValuesData';
import LineData from '../../../models/regexResponses/LineData';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import SubjectData from '../../../models/regexResponses/SubjectData';
import SyndicateContext from '../../../context/SyndicateContext';
import { sendFetchError } from 'utils/messageUtils';
const DataViewer = ({ category, clearRegexes, create, myKey, setCategory, setType, setMyKey, type, }) => {
    const { apiFetchJson, apiFetchText } = React.useContext(DownloadContext);
    const { updateTab } = React.useContext(NavigationContext);
    const { setUpdateId } = React.useContext(SyndicateContext);
    const [update, setUpdate] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const goToUpdate = React.useCallback(({ row: { original: { messageId } } }) => {
        const url = new ApiServerURL('/syndicate/transactions/get-id');
        url.searchParams.append('messageId', messageId.id);
        url.searchParams.append('index', messageId.index.toString());
        (async () => {
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request);
                setUpdateId(body);
                updateTab('overview', 'UPDATE');
            }
            catch (error) {
                sendFetchError('Unable to find updateId', error, request);
            }
        })();
    }, [apiFetchJson, setUpdateId, updateTab]);
    const view = React.useCallback(({ row: { original: { messageId } } }) => {
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/update-message');
            url.searchParams.append('messageId', messageId.id);
            url.searchParams.append('index', messageId.index.toString());
            const request = new ServerRequest(url);
            const htmlUrl = new ApiServerURL('/syndicate/emails/html');
            htmlUrl.searchParams.append('messageId', messageId.id);
            htmlUrl.searchParams.append('index', messageId.index.toString());
            const htmlRequest = new ServerRequest(htmlUrl);
            try {
                const updateDownload = apiFetchJson(request);
                const htmlDownload = apiFetchText(htmlRequest);
                const [downloadedUpdate, downloadedHtml] = await Promise.all([updateDownload, htmlDownload]);
                setUpdate({ ...downloadedUpdate, html: downloadedHtml });
                setShowModal(true);
            }
            catch (error) {
                sendFetchError('Unable to download Update and HTML', error, request);
            }
        })();
    }, [apiFetchJson, apiFetchText]);
    const transformData = React.useCallback((downloadedData) => {
        switch (type) {
            case 'keyvalues':
                return Object.assign(new KeyValuesData(), downloadedData);
            case 'listitems':
                return Object.assign(new ItemData(), downloadedData);
            case 'paragraphs':
                return Object.assign(new LineData(), downloadedData);
            case 'subject':
                return Object.assign(new SubjectData(), downloadedData);
            default:
                throw new Error('Unknown type');
        }
    }, [type]);
    const name = React.useMemo(() => {
        switch (type) {
            case 'keyvalues':
                {
                    const keyName = myKey?.name;
                    return keyName !== undefined ? `data-keyvalues-${keyName}` : undefined;
                }
            case 'listitems':
                return 'data-lists';
            case 'paragraphs':
                return `data-paragrphs-${category}`;
            case 'subject':
                return 'data-subject';
            default:
                throw new Error('Unknown type');
        }
    }, [category, myKey?.name, type]);
    const dataTransform = React.useCallback((sourceData) => sourceData.map((d) => transformData(d)), [transformData]);
    const url = React.useMemo(() => {
        const baseUrl = `/syndicate/regex/${type}`;
        switch (type) {
            case 'keyvalues': {
                if (myKey !== undefined) {
                    const bytes = new TextEncoder().encode(myKey.name);
                    const binString = String.fromCodePoint(...bytes);
                    const escapedName = window.btoa(binString);
                    return `${baseUrl}/${escapedName}`;
                }
                return undefined;
            }
            case 'paragraphs':
                return `${baseUrl}/${category}`;
            default:
                return baseUrl;
        }
    }, [category, myKey, type]);
    return (React.createElement(React.Fragment, null,
        typeof update !== 'undefined'
            ? React.createElement(ContentModal, { update: update, hideModal: () => { setShowModal(false); }, show: showModal })
            : null,
        React.createElement("div", { style: { gridArea: 'dataForm' } },
            React.createElement(DataViewerForm, { category: category, clearRegexes: clearRegexes, isLoading: isLoading, myKey: myKey, type: type, setCategory: setCategory, setType: setType, setMyKey: setMyKey })),
        (url !== undefined && name !== undefined
            ? (React.createElement(DataTable, { create: create, dataTransform: dataTransform, emptyMessage: "No Data. Have you selected a key?", errorMessage: "Error downloading data", fetchSize: type === 'paragraphs' ? 10 : 50, view: view, name: name, update: goToUpdate, setLoading: setLoading, urlPage: url + "/page", urlTotal: url + "/total" }))
            : undefined)));
};
export default DataViewer;
