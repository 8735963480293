import * as React from 'react';
import { Button, DropdownMenu, Icon, } from '@symphony-ui/uitoolkit-components';
import Popup from './Popup';
const PopupButton = React.forwardRef(({ childFn, iconName, style }, ref) => {
    const [show, setShow] = React.useState(false);
    const close = React.useCallback(() => setShow(false), []);
    const onClick = React.useCallback(() => setShow(true), []);
    return (React.createElement("div", { ref: ref, style: { textAlign: 'center' } },
        React.createElement(Button, { className: "co-table-button", iconButton: true, onClick: onClick, variant: "tertiary" },
            React.createElement(Icon, { className: "co-table-icon", iconName: iconName })),
        React.createElement("div", null, show
            ? (React.createElement(Popup, { close: close, style: style },
                React.createElement(DropdownMenu, null, childFn(close)))) : null)));
});
export default PopupButton;
