import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { printAddress } from 'utils/table';
import './JoinModal.scss';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
const JoinModal = ({ hideModal, isLoading, join, show, updates, }) => {
    const { apiFetchText } = React.useContext(DownloadContext);
    const [updatesWithHtml, setUpdateWithHtml] = React.useState([]);
    const width = React.useMemo(() => {
        if (updatesWithHtml !== undefined) {
            return `${100 / updatesWithHtml.length}%`;
        }
        return undefined;
    }, [updatesWithHtml]);
    const getHtml = React.useCallback(async (update) => {
        const url = new ApiServerURL('/syndicate/emails/html');
        url.searchParams.append('messageId', update.emailId.id);
        url.searchParams.append('index', update.emailId.index.toString());
        const request = new ServerRequest(url);
        try {
            const html = await apiFetchText(request);
            return { ...update, html };
        }
        catch (error) {
            sendFetchError('Unable to download HTML', error, request);
        }
        return undefined;
    }, [apiFetchText]);
    React.useEffect(() => {
        if (show) {
            const sortedCheckedUpdates = updates.checkedUpdates(true);
            const withHtml = sortedCheckedUpdates.map((u) => getHtml(u));
            (async () => Promise.all(withHtml).then((list) => setUpdateWithHtml(list)))();
        }
    }, [getHtml, show, updates]);
    return (React.createElement(Modal, { size: "full-width", show: show, closeButton: true, onClose: hideModal, parentNode: document.body },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Join several of the same ")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { display: 'flex' } }, updatesWithHtml.map((update) => (React.createElement("div", { className: "co-update", key: `${update.timestamp.toLocaleString()}-${update.subject}`, style: { width } },
                React.createElement("h4", null, update.subject),
                React.createElement("div", null, printAddress(update.from)),
                React.createElement("div", { className: "co-update-timestamp" }, update.timestamp.toLocaleString()),
                React.createElement("div", { className: "co-update-html", dangerouslySetInnerHTML: { __html: update.html } })))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: isLoading, onClick: join }, "OK"))));
};
export default JoinModal;
