import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TextField } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const CompanyFormModal = ({ company, onClose, optionsSector, optionsType, save }) => {
    const [newCompany, setNewCompany] = React.useState(company.clone());
    const onClickSave = React.useCallback(() => {
        (async () => {
            await save(newCompany);
            onClose();
        })();
    }, [newCompany, onClose, save]);
    const onChangeLei = React.useCallback((event) => setNewCompany(newCompany.setLei(event.target.value)), [newCompany]);
    const onChangeName = React.useCallback((event) => setNewCompany(newCompany.setName(event.target.value)), [newCompany]);
    const onChangeSectors = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setNewCompany(newCompany.setSectors(newValue));
    }, [newCompany]);
    const onChangeTypes = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? value.map(o => o.value) : [value.value];
        setNewCompany(newCompany.setTypes(newValue));
    }, [newCompany]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            company.id ? "Update " : "Create ",
            " Company"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Name", onChange: onChangeName, value: newCompany.name }),
            React.createElement(TextField, { label: "LEI (Legal Entity Identifier)", onChange: onChangeLei, value: newCompany.lei }),
            React.createElement("div", { style: { height: '4rem' } },
                React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute', width: 'calc(100% - 5rem)' } },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Sectors", onChange: onChangeSectors, options: optionsSector, value: optionsSector.filter(o => 'value' in o).filter(o => newCompany.sectors.includes(o.value)) }))),
            React.createElement("div", { style: { height: '4rem' } },
                React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute', width: 'calc(100% - 5rem)' } },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Types", onChange: onChangeTypes, options: optionsType, value: optionsType.filter(o => 'value' in o).filter(o => newCompany.types.includes(o.value)) })))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default CompanyFormModal;
