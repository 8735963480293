import * as React from 'react';
import { Card, Modal, ModalBody, ModalTitle, Radio, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import { datePattern } from './dateFormat';
export const TableFormattingDefault = {
    booleanFormat: 'x',
    dateFormat: 'normal',
    exportHeaders: true,
    groupData: true,
};
const TableFormatSettingsModal = ({ close, formatting, setFormatting, }) => {
    const onchangeDate = React.useCallback((value) => () => {
        const updating = {
            booleanFormat: formatting.booleanFormat, dateFormat: value, exportHeaders: formatting.exportHeaders, groupData: formatting.groupData,
        };
        setFormatting(updating);
    }, [formatting, setFormatting]);
    const onchangeBoolean = React.useCallback((value) => () => {
        const updating = {
            booleanFormat: value, dateFormat: formatting.dateFormat, exportHeaders: formatting.exportHeaders, groupData: formatting.groupData,
        };
        setFormatting(updating);
    }, [formatting, setFormatting]);
    const onchangeExportHeaders = React.useCallback(() => {
        const updating = {
            booleanFormat: formatting.booleanFormat, dateFormat: formatting.dateFormat, exportHeaders: !formatting.exportHeaders, groupData: formatting.groupData,
        };
        setFormatting(updating);
    }, [formatting, setFormatting]);
    const onchangeGroupData = React.useCallback(() => {
        const updating = {
            booleanFormat: formatting.booleanFormat, dateFormat: formatting.dateFormat, exportHeaders: formatting.exportHeaders, groupData: !formatting.groupData,
        };
        setFormatting(updating);
    }, [formatting, setFormatting]);
    return (React.createElement(Modal, { closeButton: true, onClose: close, show: true, size: "medium" },
        React.createElement(ModalTitle, null, "Table Settings"),
        React.createElement(ModalBody, null,
            React.createElement(Card, { style: { marginBottom: '1rem', padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Date Formatting"),
                React.createElement(Radio, { label: `Standard local format (${datePattern('normal', 'YMD')})`, name: "normal", onChange: onchangeDate('normal'), status: formatting.dateFormat === 'normal' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: formatting.dateFormat === 'normal' ? 'true' : 'false' }),
                React.createElement(Radio, { label: `ISO format (${datePattern('iso', 'YMD')})`, name: "normal", onChange: onchangeDate('iso'), status: formatting.dateFormat === 'iso' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: formatting.dateFormat === 'iso' ? 'true' : 'false' })),
            React.createElement(Card, { style: { marginBottom: '1rem', padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Boolean Formatting"),
                React.createElement(Radio, { label: "Standard (X)", name: "z", onChange: onchangeBoolean('x'), status: formatting.booleanFormat === 'x' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: formatting.booleanFormat === 'x' ? 'true' : 'false' }),
                React.createElement(Radio, { label: "Full (TRUE)", name: "full", onChange: onchangeBoolean('code'), status: formatting.booleanFormat === 'code' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: formatting.booleanFormat === 'code' ? 'true' : 'false' })),
            React.createElement(Card, { style: { padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Export Headers"),
                React.createElement(Switch, { label: "on/off", name: "exportheaders", onChange: onchangeExportHeaders, status: formatting.exportHeaders ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: formatting.exportHeaders ? 'true' : 'false' })),
            React.createElement(Card, { style: { padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Group Data"),
                React.createElement(Switch, { label: "on/off", name: "groupdata", onChange: onchangeGroupData, status: formatting.groupData ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: formatting.groupData ? 'true' : 'false' })))));
};
export default TableFormatSettingsModal;
