import * as React from 'react';
const OptionRenderer = ({ data }) => {
    const text = React.useMemo(() => {
        if (data.data !== undefined && 'number' in data.data) {
            return `${data.label} (${data.data.number})`;
        }
        return data.label;
    }, [data]);
    return data?.data?.id !== null ? (React.createElement("b", null, text)) : (React.createElement("div", null, text));
};
export default OptionRenderer;
