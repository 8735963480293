import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import ReleaseTable from './ReleaseTable';
const Releases = () => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [releases, setReleases] = React.useState();
    const [source, setSource] = React.useState();
    const [sourceOptions, setSourceOptions] = React.useState();
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/bb/release/options');
            const body = await jsonPlus(request, 'Error getting source options');
            setSourceOptions(body);
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        if (source !== undefined) {
            (async () => {
                const url = new ApiServerURL('/bb/release');
                url.searchParams.append('source', source);
                const request = new ServerRequest(url);
                try {
                    const body = await jsonPlus(request, 'unable to download');
                    setReleases(body);
                }
                catch (error) {
                    setError(true);
                }
                setLoading(false);
            })();
        }
    }, [jsonPlus, source]);
    return (React.createElement(ReleaseTable, { hasError: hasError, isLoading: isLoading, releases: releases ?? [], setSource: setSource, source: source, sourceOptions: sourceOptions ?? [] }));
};
export default Releases;
