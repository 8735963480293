import * as React from 'react';
import { Switch, Typography } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
const PermissionGroup = ({ label, setValue, value }) => {
    const onChange = (name) => (event) => {
        const newValue = { ...value, [name]: event.target.value === 'false' };
        setValue(newValue);
    };
    return (React.createElement("div", null,
        React.createElement(Typography, { type: "h3" }, label),
        React.createElement(Switch, { label: "Read", name: "read", onChange: onChange('read'), status: value.read ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: value.read ? 'true' : 'false' }),
        React.createElement(Switch, { label: "Write", name: "write", onChange: onChange('write'), status: value.write ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: value.write ? 'true' : 'false' })));
};
export default PermissionGroup;
