class TermSheet {
    id;
    coupon;
    issueDate;
    issuer;
    currency;
    interestBasis;
    maturityDate;
    nominal;
}
export default TermSheet;
