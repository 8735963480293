import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EditableDateCell.css';
import RenderingContext from 'contexts/RenderingContext';
import Popup from 'components/custom-table/action/Popup';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
import MaturityCellDatePicker from './MaturityCellDatePicker';
import MaturityCellDateSelector from './MaturityCellDateSelector';
const MaturityCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { printDate } = React.useContext(RenderingContext);
    const popupRef = React.useRef(null);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isValueConfirm, setValueConfirm] = React.useState(false);
    // Value is the value return by the accessorFn, and it is either a Date, or a string equal to 'Perpetual', or of the form 'MM/YYYY' or 'YYYY';
    const [value, setValue] = React.useState();
    const stopMouseDown = React.useCallback((e) => e.stopPropagation(), []);
    const toggleEditing = React.useCallback((value) => setEditing(value), []);
    React.useEffect(() => {
        const currentRef = popupRef.current;
        if (currentRef !== null) {
            if (isEditing) {
                currentRef.addEventListener('mousedown', stopMouseDown, { capture: true });
            }
            else {
                currentRef.removeEventListener('mousedown', stopMouseDown);
            }
            return () => {
                currentRef.removeEventListener('mousedown', stopMouseDown);
            };
        }
    }, [isEditing, stopMouseDown]);
    const remove = React.useCallback(async () => {
        if (table.options.meta?.updateTable !== undefined) {
            const formData = new FormData();
            formData.append('key', column.id);
            formData.append('transactionId', row.original.transaction.id);
            formData.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest('/syndicate/transactions/override', { body: formData, method: 'DELETE' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                sendFetchError('Unable to delete override information', error, request);
            }
            setDisabled(false);
            toggleEditing(false);
        }
    }, [column, apiFetchJson, row.original, table, toggleEditing]);
    const save = React.useCallback(async (saveDate) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const formData = new FormData();
            formData.append('value', saveDate ?? '');
            formData.append('key', column.id);
            formData.append('transactionId', row.original.transaction.id);
            formData.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest('/syndicate/transactions/override', { body: formData, method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const response = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                sendFetchError('Unable to save data', error, request);
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setDisabled(false);
                setLoading(false);
                toggleEditing(false);
            }
        }
    }, [column, apiFetchJson, row.original, table, toggleEditing]);
    const onChange = React.useCallback((event) => {
        // const localDate = event.target.value !== null
        //   ? new Date(event.target.value.getFullYear(), event.target.value.getMonth(), event.target.value.getDate())
        //   : undefined;
        setValue(event.target.value);
        setEditing(false);
        setValueConfirm(true);
    }, []);
    const removeOverride = React.useCallback(() => {
        (async () => save(undefined))();
    }, [save]);
    const closePopup = React.useCallback(() => {
        setEditing(false);
    }, []);
    const title = React.useMemo(() => {
        if (column.accessorFn !== undefined) {
            const copy = row.original.removeOverrideMap();
            const newValue = column.accessorFn(copy, row.index);
            if (newValue === undefined) {
                return undefined;
            }
            if (typeof newValue === 'string') {
                if (newValue === 'Perpetual') {
                    return 'Perpetual';
                }
                return "UNKNOWN: " + newValue;
            }
            const date = printDate(newValue.value, newValue.options);
            return date;
        }
        return undefined;
    }, [column, printDate, row.index, row.original]);
    const content = React.useMemo(() => {
        if (isEditing || isValueConfirm) {
            return (React.createElement("div", { className: "co-editable-date" },
                React.createElement(Popup, { close: closePopup, ref: popupRef, style: { padding: '0.5rem', transform: 'translate(0.25rem, -0.75rem)' } }, isValueConfirm ? (React.createElement(MaturityCellDateSelector, { isDisabled: isDisabled, setValueConfirm: setValueConfirm, tableCellRef: editableCellRef.current, save: save, value: value })) : (React.createElement(MaturityCellDatePicker, { hasOverride: row.original.overrideMap?.[column.id] != null, hasValue: title !== undefined, isDisabled: isDisabled, onChange: onChange, remove: remove, save: save, tableCellRef: editableCellRef.current, value: value })))));
        }
        const newValue = column.accessorFn !== undefined ? column.accessorFn(row.original, row.index) : undefined;
        if (newValue === undefined || newValue === 'TBC') {
            return '';
        }
        if (typeof newValue === 'string') {
            if (newValue === 'Perpetual') {
                return 'Perpetual';
            }
            return 'UNKNOWN: ' + newValue;
        }
        return printDate(newValue.value, newValue.options);
    }, [closePopup, column, isDisabled, isEditing, isValueConfirm, onChange, printDate, remove, row.index, row.original, save, title, value]);
    return (React.createElement(EditableCell, { errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, removeOverride: removeOverride, setEditing: toggleEditing, title: title }, content));
};
export default MaturityCell;
