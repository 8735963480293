import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { optionSort } from 'utils/table';
import { sendFetchError } from 'utils/messageUtils';
import KeyValueRegexPicker from './KeyValueRegexPicker';
import KeyValueRegexButtons from './KeyValueRegexButtons';
const KeyValueRegexForm = ({ callbackAdd, callbackCreate, isFormDisabled, keyValueRegex, name, setKeyValueRegex, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [keyValueRegexId, setKeyValueRegexId] = React.useState(keyValueRegex?.id);
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const pathRegexes = '/syndicate/regex/keyvalues/regexoptions';
        const request = new ServerRequest(pathRegexes, { headers: { 'Content-Type': 'application/json' }, method: 'GET' });
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                body.sort(optionSort);
                setOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download regex options', error, request);
                //
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const callbackButtonCreate = (updatedKeyValueRegex) => {
        if (name !== undefined && updatedKeyValueRegex.id !== undefined) {
            const newOptions = [...options];
            newOptions.push({ label: name, value: updatedKeyValueRegex.id });
            setOptions(newOptions);
            setKeyValueRegex(updatedKeyValueRegex);
            callbackCreate(updatedKeyValueRegex);
        }
    };
    const callbackButtonAdd = (updatedKeyValueRegex) => {
        const newOptions = [...options];
        const option = newOptions.find((o) => 'value' in o && o.value === updatedKeyValueRegex.id);
        if (option !== undefined) {
            option.label = updatedKeyValueRegex.keys.join(', ');
            setOptions(newOptions);
            setKeyValueRegex(updatedKeyValueRegex);
            callbackAdd(updatedKeyValueRegex);
        }
    };
    return (React.createElement("div", { className: "co-keyregexvalue-form" },
        React.createElement(KeyValueRegexPicker, { isDisabled: isFormDisabled, keyValueRegexId: keyValueRegexId, options: options, setKeyValueRegexId: setKeyValueRegexId }),
        !isFormDisabled
            ? (React.createElement(KeyValueRegexButtons, { callbackAdd: callbackButtonAdd, callbackCreate: callbackButtonCreate, keyValueRegexId: keyValueRegexId, name: name })) : ''));
};
export default KeyValueRegexForm;
