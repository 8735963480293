import { Checkbox, SelectionStatus, } from '@symphony-ui/uitoolkit-components';
import CloseIcon from 'components/custom-table/filters/CloseIcon';
import * as React from 'react';
import TimestampDropdown from './TimestampDropdown';
const TimestampFilterPlus = function TimestampFilterElement({ column, close }) {
    const [value, setValue] = React.useState(column.getFilterValue() !== undefined
        ? (column.getFilterValue().split('_')[0]) : 'A');
    const [includeEmpty, setIncludeEmpty] = React.useState(column.getFilterValue() !== undefined
        ? column.getFilterValue().split('_')[1] !== '0' : true);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value.value);
        if (event.target.value.value === 'A' && includeEmpty) {
            column.setFilterValue(undefined);
        }
        else {
            column.setFilterValue(`${event.target.value.value}_${includeEmpty ? '1' : '0'}`);
        }
    }, [column, includeEmpty]);
    const onChangeCheckbox = React.useCallback(() => {
        const newValue = !includeEmpty;
        setIncludeEmpty(newValue);
        if (value === 'A' && newValue) {
            column.setFilterValue(undefined);
        }
        else {
            column.setFilterValue(`${value}_${newValue ? '1' : '0'}`);
        }
    }, [column, includeEmpty, value]);
    return (React.createElement("div", { className: "co-check-panel" },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Checkbox, { name: "includeEmpty", value: includeEmpty.toString(), onChange: onChangeCheckbox, label: "Include empty", status: (includeEmpty) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED }),
                React.createElement(CloseIcon, { onClick: close })),
            React.createElement(TimestampDropdown, { value: value, menuIsOpen: true, onChange: onChange }))));
};
export default TimestampFilterPlus;
