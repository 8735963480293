var _a;
class IpoQuery {
    exchange;
    constructor(response) {
        this.exchange = response?.exchange;
    }
    updateExchange(value) {
        const c = this.#clone();
        c.exchange = value;
        return c;
    }
    #clone() {
        return new _a({
            exchange: this.exchange
        });
    }
}
_a = IpoQuery;
export default IpoQuery;
