import AnnaCompany from '../models/company/AnnaCompany';
import EsmaCompany from '../models/company/EsmaCompany';
import EuroclearCompany from '../models/company/EuroclearCompany';
import SecCompany from '../models/company/SecCompany';
import AnnaSecurity from '../models/security/AnnaSecurity';
import EsmaSecurity from '../models/security/EsmaSecurity';
import EuroclearSecurity from '../models/security/EuroclearSecurity';
import SecSecurity from '../models/security/SecSecurity';
import { Source } from '../sources/sources';
export const getCompanySort = (source) => {
    switch (source) {
        case Source.ANNA:
            return AnnaCompany.getSort;
        case Source.ESMA:
            return EsmaCompany.getSort;
        case Source.EUROCLEAR:
            return EuroclearCompany.getSort;
        case Source.SEC:
            return SecCompany.getSort;
        default:
            throw new Error(`Unknown source ${source}`);
    }
};
export const getSecuritySort = (source) => {
    switch (source) {
        case Source.ANNA:
            return AnnaSecurity.getSort;
        case Source.ESMA:
            return EsmaSecurity.getSort;
        case Source.EUROCLEAR:
            return EuroclearSecurity.getSort;
        case Source.SEC:
            return SecSecurity.getSort;
        default:
            throw new Error(`Unknown source ${source}`);
    }
};
