export const envs = {
    DEV: 'DEV',
    MOCK: 'MOCK',
    PROD: 'PROD',
};
const createUrl = (scheme, host, port) => `${scheme}://${host}${port ? `:${port}` : ''}`;
export const API_URL = createUrl(process.env.API_SCHEME, process.env.API_HOST, process.env.API_PORT);
export const API_URL_WS = createUrl(process.env.API_SCHEME_WS, process.env.API_HOST, process.env.API_PORT);
export const APP_URL = createUrl(process.env.APP_SCHEME, process.env.APP_HOST, process.env.APP_PORT);
export const AUTH_URL = createUrl(process.env.AUTH_SCHEME, process.env.AUTH_HOST, process.env.AUTH_PORT);
