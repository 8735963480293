var _a;
import { Source } from '../../sources/sources';
import AnnaFilter from '../filter/AnnaFilter';
import AnnaQuery from '../query/AnnaQuery';
import MetaData from 'components/metadata/MetaData';
class AnnaSearch extends MetaData {
    filter;
    name;
    query;
    database() { return Source.ANNA; }
    getCreationDate = () => this.query.update;
    getCurrency = () => this.query.issueCurrency;
    getMaturity = () => this.query.maturity;
    updateFilter(value) {
        const clone = this.#clone();
        clone.filter = value;
        return clone;
    }
    updateName(value) {
        const clone = this.#clone();
        clone.name = value;
        return clone;
    }
    updateQuery(value) {
        const clone = this.#clone();
        clone.query = value;
        return clone;
    }
    updatePermissions(value) {
        const clone = this.#clone();
        clone.permissions = value;
        return clone;
    }
    constructor(response) {
        super(response);
        this.filter = response?.filter !== undefined ? new AnnaFilter(response.filter) : new AnnaFilter();
        this.name = response?.name;
        this.query = new AnnaQuery(response?.query ?? {});
    }
    #clone() {
        return new _a({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            filter: this.filter,
            id: this.id,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            name: this.name,
            permissions: this.permissions,
            query: this.query
        });
    }
    getState() {
        const queryState = this.query.getState();
        if (queryState !== undefined) {
            return queryState;
        }
        return this.filter.hasValues() ? 'other' : 'none';
    }
}
_a = AnnaSearch;
export default AnnaSearch;
