class TransactionWrapper {
    company;
    confirm;
    id;
    isEditing;
    name;
    firstUpdate;
    lastUpdate;
    sector;
    regions;
    types;
    constructor({ company, confirm, id, name, firstUpdate, lastUpdate, sector, regions, types, }) {
        this.company = company;
        this.confirm = confirm;
        this.id = id;
        this.name = name;
        this.firstUpdate = new Date(firstUpdate);
        this.lastUpdate = new Date(lastUpdate);
        this.sector = sector;
        this.regions = regions;
        this.types = types;
    }
    setEditable(flag) {
        const newTranche = this.clone();
        newTranche.isEditing = flag;
        return newTranche;
    }
    toResponse() {
        return {
            company: this.company,
            confirm: this.confirm,
            id: this.id,
            name: this.name,
            firstUpdate: this.firstUpdate.toISOString(),
            lastUpdate: this.lastUpdate.toISOString(),
            sector: this.sector,
            regions: this.regions,
            types: this.types,
        };
    }
    clone() {
        return new TransactionWrapper(this.toResponse());
    }
    setMeta({ companyName, regions, sector, types, }) {
        const updateTransaction = this.clone();
        if (updateTransaction.company === undefined) {
            updateTransaction.company = { name: companyName };
        }
        updateTransaction.sector = sector;
        updateTransaction.regions = regions;
        updateTransaction.types = types;
        return updateTransaction;
    }
    getMeta() {
        return {
            companyName: this.company?.name,
            regions: this.regions,
            sector: this.sector,
            types: this.types,
        };
    }
}
export default TransactionWrapper;
