class TransactionWrapper {
    company;
    confirm;
    id;
    isEditing;
    name;
    firstUpdate;
    lastUpdate;
    sector;
    region;
    types;
    constructor({ company, confirm, id, name, firstUpdate, lastUpdate, sector, region, types, }) {
        this.company = company;
        this.confirm = confirm;
        this.id = id;
        this.name = name;
        this.firstUpdate = new Date(firstUpdate);
        this.lastUpdate = new Date(lastUpdate);
        this.sector = sector;
        this.region = region;
        this.types = types;
    }
    setEditable(flag) {
        const newTranche = new TransactionWrapper(this.toResponse());
        newTranche.isEditing = flag;
        return newTranche;
    }
    toResponse() {
        return {
            company: this.company,
            confirm: this.confirm,
            id: this.id,
            name: this.name,
            firstUpdate: this.firstUpdate.toISOString(),
            lastUpdate: this.lastUpdate.toISOString(),
            sector: this.sector,
            region: this.region,
            types: this.types,
        };
    }
}
export default TransactionWrapper;
