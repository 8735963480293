import * as React from 'react';
import { Button, Typography } from '@symphony-ui/uitoolkit-components';
import './CookieConsent.css';
const CookieConsent = () => {
    const [agreed, setAgreed] = React.useState(localStorage.getItem('accept-cookie') === 'true');
    const clickAgree = React.useCallback(() => {
        setAgreed(true);
        localStorage.setItem('accept-cookie', 'true');
    }, []);
    return agreed === null ? (React.createElement("div", { id: "cookie-consent" },
        React.createElement("div", { id: "cookie-consent-text" },
            React.createElement(Typography, null, "This site uses cookies to enhance the user experience")),
        React.createElement(Button, { onClick: clickAgree }, "I agree"))) : null;
};
export default CookieConsent;
