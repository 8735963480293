import TableContext from 'components/custom-table/TableContext';
import * as React from 'react';
import TransactionMetaModal from './transaction-table/TransactionMetaModal';
import { Button } from '@symphony-ui/uitoolkit-components';
const TransactionMultiMetaButton = () => {
    const { selection, resetRowSelection } = React.useContext(TableContext);
    const [showModal, setShowModal] = React.useState(false);
    const clickMultiMeta = React.useCallback(() => {
        setShowModal(true);
    }, []);
    const onClose = React.useCallback(() => {
        setShowModal(false);
    }, []);
    const disabledMultiMeta = React.useMemo(() => selection !== undefined && selection.length < 2, [selection]);
    return (React.createElement(React.Fragment, null, showModal && selection !== undefined ?
        React.createElement(TransactionMetaModal, { callback: resetRowSelection, onClose: onClose, transaction: selection })
        : React.createElement(Button, { disabled: disabledMultiMeta, name: "multimeta", onClick: clickMultiMeta }, "META")));
};
export default TransactionMultiMetaButton;
