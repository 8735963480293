import { Client, } from '@stomp/stompjs';
import ApiServerURL from 'models/ServerUrl';
import WebsocketURL from 'models/WebsocketUrl';
import SockJS from 'sockjs-client';
class WebSocketService {
    client;
    subscriptions;
    subscribed;
    pending;
    constructor(accessToken, csrf) {
        this.subscriptions = [];
        this.subscribed = [];
        this.pending = [];
        this.client = new Client();
        if (accessToken !== undefined && csrf !== undefined) {
            this.setupConfig(accessToken, csrf);
        }
    }
    setupConfig = (accessToken, csrf) => {
        const authorizationHeader = `Bearer ${accessToken}`;
        const stompConfig = WebSocketService.createStompConfig(authorizationHeader, csrf);
        if (stompConfig !== null) {
            try {
                this.client.configure(stompConfig);
                // Fallback codeL
                if (typeof WebSocket !== 'function') {
                    // For SockJS you need to set a factory that creates a new SockJS instance
                    // to be used for each (re)connect
                    this.client.webSocketFactory = () => new SockJS(new ApiServerURL('/stomp').toString());
                }
                this.client.onChangeState = (a) => {
                    console.log(`[ws] Current WebSocket state: ${a.toString()}`);
                };
                this.client.onConnect = (frame) => {
                    console.log('[ws] WebSocket connected: %o', frame);
                };
                this.client.onDisconnect = (frame) => {
                    console.log('[ws] Disconnected: %o', frame);
                };
                this.client.onStompError = (frame) => {
                    // Will be invoked in case of error encountered at Broker
                    // Bad login/passcode typically will cause an error
                    // Complaint brokers will set `message` header with a brief message. Body may contain details.
                    // Compliant brokers will terminate the connection after any error
                    console.log(`[ws] Broker reported error: ${frame.headers.message}. Additional details: ${frame.body}`);
                };
                this.client.onUnhandledFrame = (frame) => {
                    console.log('[ws] Unhandled Frame: %o', frame);
                };
                this.client.onUnhandledMessage = (message) => {
                    console.log('[ws] Unhandled Message: %o', message);
                };
                this.client.onUnhandledReceipt = (receipt) => {
                    console.log('[ws] Unhandled Receipt: %o', receipt);
                };
                this.client.onWebSocketClose = (event) => {
                    console.log('[ws] WebSocket Close: %o', event);
                };
                this.client.onWebSocketError = (error) => {
                    console.error('[ws] WebSocket Error: %o', error);
                };
                this.client.activate();
            }
            catch (e) {
                console.error('Cannot connect to websocket: %o', e);
            }
        }
    };
    addSubscription = (destination, callback) => {
        if (!this.pending.includes(destination) && !this.subscribed.includes(destination)) {
            this.pending.push(destination);
            if (this.client.connected) {
                this.subscribe(destination, callback);
            }
            else {
                document.addEventListener('websocket.connected', () => this.subscribe(destination, callback));
            }
        }
    };
    subscribe = (destination, callback) => {
        const subscription = this.client.subscribe(destination, callback);
        this.subscriptions.push(subscription);
        this.pending = this.pending.filter((value) => value !== destination);
        this.subscribed.push(destination);
    };
    static baseStompConfig = {
        brokerURL: new WebsocketURL('/ws').toString(),
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000,
        reconnectDelay: 5000,
    };
    static createStompConfig = (authorizationHeader, csrf) => {
        const stompConfig = {};
        const url = new WebsocketURL('/ws');
        const connectHeaders = { [csrf.headerName]: csrf.token };
        connectHeaders.Authorization = authorizationHeader;
        stompConfig.brokerURL = url.toString();
        //    stompConfig.debug = (e) => console.log('ws: %o', e);
        stompConfig.connectHeaders = connectHeaders;
        return stompConfig;
    };
}
export default WebSocketService;
