import * as React from 'react';
import { flexRender, } from '@tanstack/react-table';
import { useDrag, useDrop, } from 'react-dnd';
import SortIcon from './filters/SortIcon';
import Filter from './filters/Filter';
import TableStateClass from './TableStateClass';
const reorderColumn = (draggedColumnId, targetColumnId, columnOrder2) => {
    columnOrder2.splice(columnOrder2.indexOf(targetColumnId), 0, columnOrder2.splice(columnOrder2.indexOf(draggedColumnId), 1)[0]);
    return [...columnOrder2];
};
const DraggableColumnHeader = function DraggableColumnHeaderElement({ header, isNumber, table, }) {
    const { getState, setState } = table;
    const { columnOrder } = getState();
    const { column } = header;
    const [{ isOver }, dropRef] = useDrop({
        accept: 'column',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (draggedColumn) => {
            const simplifiedColumns = columnOrder.filter((c) => c != null);
            const currentColumnOrder = simplifiedColumns.length > 0 ? simplifiedColumns : table.getAllColumns().map((c) => c.id);
            const newColumnOrder = reorderColumn(draggedColumn.id, column.id, currentColumnOrder);
            const updatedTableState = new TableStateClass(getState()).updateColumnOrder(newColumnOrder);
            setState(updatedTableState);
        },
    });
    const [collectedProps, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: 'column',
    });
    const previewRefReal = React.useMemo(() => {
        return previewRef;
    }, [previewRef]);
    const dragRefReal = React.useMemo(() => {
        return dragRef;
    }, [dragRef]);
    const dropRefReal = React.useMemo(() => {
        return dropRef;
    }, [dropRef]);
    return (React.createElement("th", { className: `co-header${isOver ? ' co-header-hover' : ''}`, colSpan: header.colSpan, key: header.id, ref: dropRefReal, style: {
            opacity: collectedProps.isDragging ? 0.5 : 1,
            width: (header.getSize() < 1000 ? header.getSize() : ''),
        } },
        React.createElement("div", { ref: previewRefReal }, header.isPlaceholder ? null
            : (React.createElement("div", { ref: dragRefReal, style: { display: 'flex', justifyContent: 'space-between' } },
                header.column.getCanFilter() ? (React.createElement(Filter, { column: header.column, isNumber: isNumber })) : (React.createElement("div", null)),
                React.createElement("div", { 
                    // className: `resizer ${header.column?.getIsResizing() ? 'isResizing' : ''}`,
                    className: 'resizer isResizing',
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler() }),
                flexRender(header.column.columnDef.header, header.getContext()),
                header.column.getCanSort()
                    ? React.createElement(SortIcon, { column: header.column }) : null)))));
};
export default DraggableColumnHeader;
