import { Loader } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import KeyFormModal from '../../regexes/key-form-modal/KeyFormModal';
import RegexFormModal from '../../regexes/regex-modal/RegexFormModal';
import AmendedContent from './AmendedContent';
const AmendedContentWrapper = ({ content, hasError, isLoading, isVisible, setRef, }) => {
    const [name, setName] = React.useState();
    const [originalRegex, setOriginalRegex] = React.useState();
    const [regex, setRegex] = React.useState();
    const [title, setTitle] = React.useState('');
    const [showKey, setShowKey] = React.useState(false);
    const [showRegex, setShowRegex] = React.useState(false);
    const [type, setType] = React.useState('keyvalues');
    const gridRef = React.useRef(null);
    React.useEffect(() => {
        if (isVisible && gridRef.current !== null) {
            setRef(gridRef.current);
        }
    });
    return (React.createElement("div", null,
        React.createElement(KeyFormModal, { hideModal: () => setShowKey(false), name: name, show: showKey, title: title }),
        React.createElement(RegexFormModal, { callback: () => document.dispatchEvent(new Event('reloadcontent')), hideModal: () => setShowRegex(false), originalRegex: originalRegex, regex: regex, show: showRegex, title: title, type: type }),
        React.createElement("div", { className: `co-update-panel${isVisible ? ' co-visible' : ''}`, ref: gridRef }, !hasError && !isLoading
            ? (React.createElement(AmendedContent, { content: content, setName: setName, setOriginalRegex: setOriginalRegex, setRegex: setRegex, setShowKey: setShowKey, setShowRegex: setShowRegex, setTitle: setTitle, setType: setType }))
            : React.createElement("div", null, isLoading ? React.createElement(Loader, { className: "co-loader", type: "spinner" }) : 'Error in Content download'))));
};
export default AmendedContentWrapper;
