var _a;
import QualifiedSearch from '../QualifiedSearch';
import TimeRange from '../TimeRange';
class EuroclearQuery {
    codecreationdate;
    currency;
    market;
    maturitydate;
    q;
    rate;
    ratetype;
    securitytype;
    constructor(response) {
        this.codecreationdate = response?.codecreationdate ? new TimeRange(response.codecreationdate) : undefined;
        this.currency = response?.currency;
        this.market = response?.market;
        this.maturitydate = response?.maturitydate ? new TimeRange(response.maturitydate) : undefined;
        this.q = response?.q ? new QualifiedSearch(response.q) : undefined;
        this.rate = response?.rate ? new QualifiedSearch(response.rate) : undefined;
        this.ratetype = response?.ratetype;
        this.securitytype = response?.securitytype;
    }
    #clone() {
        return new _a({
            codecreationdate: this.codecreationdate,
            currency: this.currency,
            market: this.market,
            maturitydate: this.maturitydate,
            q: this.q,
            rate: this.rate,
            ratetype: this.ratetype,
            securitytype: this.securitytype
        });
    }
    updateQualifiedSearch(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
    updateStringArray(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
    updateTimeRange(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
}
_a = EuroclearQuery;
export default EuroclearQuery;
