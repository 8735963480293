import { defaultPermissions } from 'components/metadata/utils';
class Report {
    createdBy;
    createdDate;
    frequency;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    searchId;
    showEmpty;
    source;
    streamIds;
    constructor() {
        this.permissions = defaultPermissions;
        this.streamIds = this.streamIds || [];
    }
    setSearchId(value) {
        this.searchId = value;
        return this;
    }
    update(name, value) {
        return Object.assign(new Report(), this, { [name]: value });
    }
    updatePermissions(permissions2) {
        this.permissions = permissions2;
        return this;
    }
}
export default Report;
