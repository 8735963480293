import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const KeyValueRegexPicker = ({ isDisabled, keyValueRegexId, options, setKeyValueRegexId, }) => {
    const onChange = React.useCallback((event) => {
        const { value } = event.target;
        setKeyValueRegexId(value?.value);
    }, [setKeyValueRegexId]);
    return (React.createElement(Dropdown, { isDisabled: isDisabled, isInputClearable: true, label: "Key Value Regex", name: "key", onChange: onChange, value: options.find((o) => (o.value === keyValueRegexId)), options: options }));
};
export default KeyValueRegexPicker;
