import * as React from 'react';
import SyndData from './SyndData';
import SyndDbManagement from './SyndDbManagement';
import SyndLEIManagement from './SyndLEIManagement';
const SystemAdmin = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(SyndData, null),
        React.createElement(SyndDbManagement, null),
        React.createElement(SyndLEIManagement, null)));
};
export default SystemAdmin;
