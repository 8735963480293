import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoSuchTab from 'components/NoSuchTab';
import Ipos from './pages/Ipos';
const IpoApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { auth: ['USER'], code: 'ipos', label: 'Initial Public Offerings' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'ipos':
            return (React.createElement(Ipos, null));
        default:
            return (React.createElement(NoSuchTab, { tab: tab }));
    }
};
export default IpoApp;
