import * as React from 'react';
import { DatePicker } from '@symphony-ui/uitoolkit-components';
import DateRange from '../models/DateRange';
const DateRangeInput = ({ disabled, name, onChange, value = { start: undefined, end: undefined }, }) => {
    const [data, setData] = React.useState(value);
    const onChangeDate = React.useCallback((dateName) => (event) => {
        const { value: newValue } = event.target;
        const newData = dateName === 'start' ? { start: newValue, end: data?.end } : { start: data?.start, end: newValue };
        setData(newData);
        const newObject = {
            target: {
                value: new DateRange(newData),
            },
        };
        onChange(newObject);
    }, [data, onChange]);
    return (React.createElement("div", { className: "co-range" },
        React.createElement(DatePicker, { className: "date", date: data?.start, disabled: disabled, format: "dd/MM/yyyy", name: `${name}start`, onChange: onChangeDate('start') }),
        React.createElement(DatePicker, { className: "date", date: data?.end, disabled: disabled, format: "dd/MM/yyyy", name: `${name}end`, onChange: onChangeDate('end') })));
};
export default DateRangeInput;
