import * as React from 'react';
import { Button, Card, Icon } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import MesssagesModal from '../transactions/overview/MessagesModal';
import TransactionWrapper from '../../models/TransactionWrapper';
import StreamItem from './StreamItem';
import StreamFilterModal from './StreamFilterModal';
import LoadingPage from 'components/loading-page/LoadingPage';
import createDataRequest from 'components/custom-table/infinite-data-request';
const Stream = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [items, setItems] = React.useState([]);
    const [detailsId, setDetailsId] = React.useState();
    const [filter, setFilter] = React.useState(() => {
        const stored = localStorage.getItem('stream-filter');
        return stored !== null ? JSON.parse(stored) : undefined;
    });
    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const onClickFilter = React.useCallback(() => {
        setShowFilterModal(true);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        const request = createDataRequest('/syndicate/transactions/page', {
            start: 0,
            fetchSize: 100,
            columnFilters: filter,
            signal: abortController.signal
        });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const transaction = body.map((b) => new TransactionWrapper(b));
                setItems(transaction);
            }
            catch (error) {
                sendFetchError('Unable to download transactions', error, request);
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, filter]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(StreamFilterModal, { filter: filter, onClose: () => setShowFilterModal(false), setFilter: setFilter, show: showFilterModal }),
        React.createElement(MesssagesModal, { hideModal: () => setDetailsId(undefined), show: detailsId !== undefined, splitScreen: false, transactionId: detailsId }),
        React.createElement("div", { style: { display: 'flex', paddingBottom: '0.5rem' } },
            React.createElement(Button, { iconButton: true, onClick: onClickFilter },
                React.createElement(Icon, { iconName: "filter" }))),
        isLoading ?
            React.createElement(LoadingPage, null) :
            React.createElement("div", { style: { overflow: 'auto' } }, items.map((m) => (React.createElement(StreamItem, { key: m.id, messages: m, setDetailsId: setDetailsId }))))));
};
export default Stream;
