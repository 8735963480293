import * as React from 'react';
import { Card, Radio, Typography } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
const ChangeTheme = () => {
    const initialTheme = React.useMemo(() => {
        const stored = localStorage.getItem('theme');
        return stored !== null ? JSON.parse(stored) : { themeV2: { name: 'light', size: 'normal' } };
    }, []);
    const [siteTheme, setSiteTheme] = React.useState(initialTheme);
    React.useEffect(() => {
        if (JSON.stringify(siteTheme) !== localStorage.getItem('theme')) {
            localStorage.setItem('theme', JSON.stringify(siteTheme));
            const newEvent = new CustomEvent('themeChangeV2');
            document.dispatchEvent(newEvent);
        }
    }, [siteTheme]);
    const onChangeName = React.useCallback((value) => () => {
        const origThemeV2 = siteTheme.themeV2;
        origThemeV2.name = value;
        setSiteTheme({ ...siteTheme, themeV2: origThemeV2 });
    }, [siteTheme]);
    const onChangeSize = React.useCallback((value) => () => {
        const origThemeV2 = siteTheme.themeV2;
        origThemeV2.size = value;
        setSiteTheme({ ...siteTheme, themeV2: origThemeV2 });
    }, [siteTheme]);
    return (React.createElement(Card, { className: "co-card-stack", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Theme"),
        React.createElement("div", { className: "co-info co-item" },
            React.createElement(Radio, { id: "1", label: "Light", name: "themename", onChange: onChangeName('light'), status: siteTheme.themeV2.name === 'light' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.name === 'light' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "2", label: "Dark", name: "themename", onChange: onChangeName('dark'), status: siteTheme.themeV2.name === 'dark' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.name === 'dark' ? 'true' : 'false' })),
        React.createElement("div", { className: "co-info co-item" },
            React.createElement(Radio, { id: "4", label: "Extra Small", name: "themesize", onChange: onChangeSize('xsmall'), status: siteTheme.themeV2.size === 'xsmall' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.size === 'xsmall' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "3", label: "Small", name: "themesize", onChange: onChangeSize('small'), status: siteTheme.themeV2.size === 'small' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.size === 'small' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "5", label: "Normal", name: "themesize", onChange: onChangeSize('normal'), status: siteTheme.themeV2.size === 'normal' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.size === 'normal' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "6", label: "Large", name: "themesize", onChange: onChangeSize('large'), status: siteTheme.themeV2.size === 'large' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: siteTheme.themeV2.size === 'large' ? 'true' : 'false' }))));
};
export default ChangeTheme;
