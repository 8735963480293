class CoreData {
    messageId;
    parsable;
    hasId(id) {
        return this.messageId.id === id.id && this.messageId.index === id.index;
    }
    sameId(that) {
        return this.messageId.id === that.messageId.id && this.messageId.index === that.messageId.index;
    }
}
export default CoreData;
