import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
const LEIInput = ({ onChange, value }) => {
    const [options, setOptions] = React.useState([]);
    const [defaultOptions, setDefaultOptions] = React.useState([]);
    const fetchOptions = React.useMemo(() => ({ headers: { Accept: 'application/vnd.api+json' } }), []);
    React.useEffect(() => {
        if (value !== undefined && value.length > 1) {
            (async () => {
                const leiUri = new URL(`/api/v1/lei-records/${value}`, 'https://api.gleif.org');
                const response = await fetch(leiUri.toString(), fetchOptions);
                const body = await response.json();
                const { data } = body;
                const newOptions = [{
                        label: data.attributes.entity.legalName.name,
                        value: data.id,
                    }];
                setDefaultOptions(newOptions);
                setOptions(newOptions);
            })();
        }
    }, [fetchOptions, value]);
    const asyncOptions = React.useCallback(async (inputText) => {
        if (inputText.length === 0) {
            return [];
        }
        const leiUri = new URL('/api/v1/fuzzycompletions', 'https://api.gleif.org');
        leiUri.searchParams.append('field', 'entity.legalName');
        leiUri.searchParams.append('q', inputText);
        const response = await fetch(leiUri.toString(), fetchOptions);
        const data = await response.json();
        const newOptions = data.data.filter((o) => 'relationships' in o).map((o) => ({ label: o.attributes.value, value: o.relationships['lei-records'].data.id }));
        setOptions(newOptions);
        return newOptions;
    }, [fetchOptions]);
    return (React.createElement(Dropdown, { asyncOptions: asyncOptions, defaultOptions: defaultOptions, label: "Issuer Name", onChange: onChange, value: options.find((o) => 'value' in o && o.value === value) }));
};
export default LEIInput;
