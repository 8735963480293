import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography, } from '@symphony-ui/uitoolkit-components';
import SectorSelector from '../settings/SectorSelector';
const StreamFilterModal = ({ filter, onClose, setFilter, show, }) => {
    const [newFilter, setNewFilter] = React.useState(filter ?? []);
    const onClickSave = React.useCallback(() => {
        localStorage.setItem('stream-filter', JSON.stringify(newFilter));
        setFilter(newFilter);
        onClose();
    }, [newFilter, onClose, setFilter]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: show, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(Typography, { type: "h2" }, "Filter Live Stream")),
        React.createElement(ModalBody, null,
            React.createElement(SectorSelector, { filter: newFilter, updateFilter: setNewFilter })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default StreamFilterModal;
