import * as React from 'react';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import KeyValueRegex from '../../models/regexes/KeyValueRegex';
import Regex from '../../models/regexes/Regex';
import { escapeRegex } from '../../models/regexes/regexUtils';
import FullCellButton from './FullCellButton';
const ToDoTable = ({ actionElements, data, hasError, isLoading, setName, setOriginalRegex, setRegex, setShowKey, setShowRegex, setTitle, setType, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const keyCell = React.useCallback(({ row }) => {
        const { key, known } = row.original;
        const onClick = () => {
            setTitle(key);
            setName(key);
            setShowKey(true);
        };
        const className = known ? 'co-parse-ok' : 'co-parse-no-key';
        return (React.createElement(FullCellButton, { className: className, onClick: onClick, text: key }));
    }, [setName, setShowKey, setTitle]);
    const valueCell = React.useCallback(({ row }) => {
        const { key, known, parsable, value, } = row.original;
        const onClick = () => {
            setTitle(`${key}: ${value}`);
            setType('keyvalues');
            const newRegex = new Regex({ markers: [], template: escapeRegex(value) });
            setRegex(newRegex);
            setShowRegex(true);
            const url = new ApiServerURL('/syndicate/regex/keyvalues/keyvalueregex');
            url.searchParams.append('key', key);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    setOriginalRegex(new KeyValueRegex(body));
                }
                catch (error) {
                    sendFetchError('Unable to downlaod Original Regex', error, request);
                }
            })();
        };
        let className;
        if (known) {
            className = parsable ? 'co-parse-ok' : 'co-parse-no-regex';
        }
        const disabled = known !== undefined || parsable !== undefined;
        return (React.createElement(FullCellButton, { className: className, disabled: disabled, onClick: onClick, text: value }));
    }, [apiFetchJson, setOriginalRegex, setShowRegex, setRegex, setTitle, setType]);
    const columns = React.useMemo(() => [
        {
            cell: keyCell,
            header: 'Key',
            id: 'key',
        }, {
            cell: valueCell,
            header: 'Value',
            id: 'value',
        },
    ], [keyCell, valueCell]);
    return (React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: data, emptyMessage: "No recent Key Values found", errorMessage: "Error loading Key Values", hasError: hasError, isLoading: isLoading, name: "todo" }));
};
export default ToDoTable;
