import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import AppViewContext from 'contexts/AppViewContext';
import NavigationContext from 'contexts/NavigationContext';
import NavigationService from 'services/NavigationService';
import UserContext from 'contexts/UserContext';
import WebSocketContext from 'contexts/WebSocketContext';
import WebSocketService from 'services/WebsocketService';
import { App } from 'utils/appUtils';
import initialisePushNotifications from '../../../site/init-push-notifications';
import Main from '../../../site/pages/main-page/Main';
import PageWrapper from './PageWrapper';
import './generic.css';
const LocationRouter = () => {
    const s = React.useContext(UserContext);
    const [offline, setOffline] = React.useState(false);
    const [imitate, setImitate] = React.useState(false);
    const [appView, setAppView] = React.useState();
    const [tab, setTab] = React.useState();
    const [view, setView] = React.useState();
    const [availableTabs, setAvailableTabs] = React.useState();
    const auth = useAuth();
    const navigationService = React.useMemo(() => new NavigationService({
        appView,
        setAppView,
        tab,
        setTab,
        view,
        setView,
        availableTabs,
        setAvailableTabs,
        user: auth.user,
        storage: localStorage,
    }), [auth.user, appView, availableTabs, tab, view]);
    const webSocketService = React.useMemo(() => new WebSocketService(auth.user?.access_token, s.csrf), [auth.user?.access_token, s.csrf]);
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('app') && params.get('app') === 'CMO') {
            navigationService.updateAppView(App.SYND);
        }
    }, [navigationService]);
    React.useEffect(() => {
        const token = auth.user?.access_token;
        if (token !== undefined) {
            (async () => {
                await initialisePushNotifications(token);
            })();
        }
    }, [auth.user?.access_token]);
    const appViewValue = React.useMemo(() => ({
        imitate: imitate ?? false,
        offline,
        setImitate,
        setOffline,
    }), [imitate, offline, setImitate, setOffline]);
    return (React.createElement(NavigationContext.Provider, { value: navigationService },
        React.createElement(WebSocketContext.Provider, { value: webSocketService },
            React.createElement(AppViewContext.Provider, { value: appViewValue }, appView === undefined ? React.createElement(Main, null) : React.createElement(PageWrapper, null)))));
};
export default LocationRouter;
