import { Button, Card, Loader, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import EmailGridViewer from './EmailGridViewer';
import EmailLoaderModal from './EmailLoaderModal';
import EmailFixFeedbackModal from './EmailFixFeedbackModal';
const EmailManager = ({ emailId, isNextLoading, next, previous, setEmailId, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [email, setEmail] = React.useState();
    const [feedback, setFeedback] = React.useState([]);
    const [hasEmailError, setEmailError] = React.useState(false);
    const [hasPartsError, setPartsError] = React.useState(false);
    const [isEmailLoading, setEmailLoading] = React.useState(false);
    const [isHtml, setHtml] = React.useState(false);
    const [isPartsLoading, setPartsLoading] = React.useState(false);
    const [parts, setParts] = React.useState();
    const [prettify, setPrettify] = React.useState(false);
    const [showFeedbackModal, setShowFeedbacModal] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const clickCopy = React.useCallback(() => {
        if (emailId !== null) {
            (async () => {
                await navigator.clipboard.writeText(emailId);
            })();
        }
    }, [emailId]);
    const clickFix = React.useCallback(() => {
        if (emailId !== null) {
            setEmailLoading(true);
            const url = new ApiServerURL('/syndicate/emails/fix-parts');
            url.searchParams.append('emailId', emailId);
            const request = new ServerRequest(url, { method: 'PATCH' });
            (async () => {
                try {
                    const body = await jsonPlus(request, 'Unable to download content');
                    setFeedback(body);
                    setShowFeedbacModal(true);
                }
                catch (error) {
                    //
                }
                setEmailLoading(false);
            })();
        }
    }, [jsonPlus, emailId]);
    const clickNext = React.useCallback(() => {
        (async () => next())();
    }, [next]);
    const clickOpen = React.useCallback(() => {
        setShowModal(true);
    }, []);
    const clickPrevious = React.useCallback(() => {
        (async () => previous())();
    }, [previous]);
    React.useEffect(() => {
        if (emailId !== null) {
            setEmailLoading(true);
            const url = new ApiServerURL('/syndicate/emails');
            url.searchParams.append('messageId', emailId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await jsonPlus(request, 'Unable to download content');
                    setEmail(body);
                }
                catch (error) {
                    setEmailError(true);
                }
                setEmailLoading(false);
            })();
        }
    }, [jsonPlus, emailId, setEmail]);
    React.useEffect(() => {
        if (emailId !== null) {
            setPartsLoading(true);
            const url = new ApiServerURL('/syndicate/emails/parts');
            url.searchParams.append('emailId', emailId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await jsonPlus(request, 'Unable to download content');
                    setParts(body);
                }
                catch (error) {
                    setPartsError(true);
                }
                setPartsLoading(false);
            })();
        }
    }, [jsonPlus, emailId]);
    return (React.createElement(React.Fragment, null,
        showModal
            ? React.createElement(EmailLoaderModal, { onClose: () => setShowModal(false), setEmailId: setEmailId }) : undefined,
        React.createElement(Card, { style: { padding: '1rem' } },
            showFeedbackModal ? React.createElement(EmailFixFeedbackModal, { feedback: feedback, onClose: () => setShowFeedbacModal(false) }) : undefined,
            React.createElement("div", { style: {
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
                } },
                React.createElement(Typography, { type: "h1" }, "Email Management"),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: () => updateView('TABLE'), size: "small" }, "OVERVIEW"))),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Switch, { label: "HTML", name: "html", onChange: () => setHtml(!isHtml), status: isHtml ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: isHtml ? 'true' : 'false' }),
                React.createElement(Switch, { label: "Prettify", name: "prettify", onChange: () => setPrettify(!prettify), status: prettify ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: prettify.toString() }),
                React.createElement(Button, { loading: isNextLoading, onClick: clickPrevious, size: "small" }, "PREVIOUS"),
                React.createElement(Button, { loading: isNextLoading, onClick: clickNext, size: "small" }, "NEXT"),
                React.createElement(Button, { disabled: emailId === null, loading: isNextLoading, onClick: clickCopy, size: "small" }, "COPY ID"),
                React.createElement(Button, { onClick: clickOpen, size: "small" }, "OPEN"),
                React.createElement(Button, { onClick: clickFix, size: "small" }, "FIX")),
            !isEmailLoading && email !== undefined ? (React.createElement(EmailGridViewer, { email: email, hasEmailError: hasEmailError, hasPartsError: hasPartsError, isEmailLoading: isEmailLoading, isHtml: isHtml, isPartsLoading: isPartsLoading, parts: parts ?? [], prettify: prettify })) : React.createElement("div", null,
                React.createElement(Loader, { className: "co-loader", type: "spinner" })))));
};
export default EmailManager;
