import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
const SearchNameInput = ({ hideModal, save }) => {
    const [value, setValue] = React.useState('');
    const [disabled, setDisabled] = React.useState(true);
    const validators = React.useMemo(() => ({
        name: [Validators.MinLength(3), Validators.MaxLength(24)],
    }), []);
    const onChangeName = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onClickSave = React.useCallback(() => {
        (async () => {
            await save(value);
            hideModal();
        })();
    }, [hideModal, save, value]);
    const onValidationChanged = React.useCallback((event) => {
        setDisabled(!event);
    }, []);
    return (React.createElement(Modal, { closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "medium" },
        React.createElement(ModalTitle, null, "Please give your Search a name"),
        React.createElement(ModalBody, null,
            React.createElement(Validation, { errorMessage: { minlength: 'Please provide a name with at least three letters' }, validator: validators.name, onValidationChanged: onValidationChanged },
                React.createElement(TextField, { label: "Name", onChange: onChangeName, value: value }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabled, onClick: onClickSave }, "SAVE"))));
};
export default SearchNameInput;
