import * as React from 'react';
import './Popup.css';
const Popup = ({ children, close, style, }) => {
    const popupRef = React.useRef(null);
    const [maxHeight, setMaxHeight] = React.useState();
    React.useEffect(() => {
        const abortController = new AbortController();
        const onClick = (event) => {
            if (popupRef.current !== null) {
                const rect = popupRef.current.getBoundingClientRect();
                const height = Math.max(rect.height, popupRef.current?.clientHeight ?? 0);
                const width = Math.max(rect.width, popupRef.current?.clientWidth ?? 0);
                // console.log('RectLeft: %o, clientX: %o, RectLeft + width: %o', rect.left, event.clientX, rect.left + width);
                // console.log('RectTop: %o, clientY: %o, RectTop + height: %o', rect.top, event.clientY, rect.top + height);
                if (event.isTrusted && !(rect.left <= event.clientX && event.clientX <= rect.left + width && rect.top <= event.clientY && event.clientY <= rect.top + Math.max(maxHeight ?? 0, height))) {
                    close();
                }
            }
        };
        document.addEventListener('mouseup', () => {
            document.addEventListener('click', onClick, { once: true, signal: abortController.signal });
        }, { signal: abortController.signal });
        return () => abortController.abort();
    }, [maxHeight, close]);
    React.useEffect(() => {
        if (popupRef.current !== null) {
            setMaxHeight(Math.max(maxHeight ?? 0, popupRef.current.clientHeight));
        }
    }, [maxHeight]);
    return (React.createElement("div", { ref: popupRef, className: "co-popup", style: style }, children));
};
export default Popup;
