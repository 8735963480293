import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@symphony-ui/uitoolkit-components';
import Company from '../../models/Company';
import * as React from 'react';
import CompanyMetaForm from '../../../syndicate/pages/transactions/transaction-viewer/transaction-meta/CompanyMetaForm';
import { sendError } from 'utils/messageUtils';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
const CompanyFormModal = ({ company = new Company(), onClose, callback, selectedCompany }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [newCompany, setNewCompany] = React.useState(company);
    const [loading, setLoading] = React.useState(false);
    const [companyForLei, setCompanyForLei] = React.useState(selectedCompany);
    const disabled = React.useMemo(() => newCompany.sectors.length === 0 || newCompany.types.length === 0 || newCompany.name.length < 3, [newCompany]);
    const onClickSave = React.useCallback(() => {
        setLoading(true);
        const method = newCompany.id ? "PATCH" : "POST";
        const request = new ServerRequest("/admin/companies", { body: JSON.stringify(newCompany), headers: { 'Content-Type': 'application/json' }, method });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                ;
                const updatedCompany = new Company(body);
                console.log("Executing callback on %o", updatedCompany);
                callback(updatedCompany);
                onClose();
            }
            catch (error) {
                sendError("Unable to save company", error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [apiFetchJson, callback, newCompany, onClose]);
    const onClickClear = React.useCallback(() => {
        setNewCompany(new Company());
        setCompanyForLei(undefined);
    }, []);
    const onClickClearAlternativeNames = React.useCallback(() => {
        const clone = newCompany.removeAlternativeName();
        setNewCompany(clone);
    }, [newCompany]);
    const onKeyUpSave = React.useCallback((event) => {
        if (event.code === 'Enter' || !disabled) {
            onClickSave();
        }
    }, [disabled, onClickSave]);
    return (React.createElement(Modal, { className: "cmo-dropdown-absolute", closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null,
                newCompany.id ? "Update " : "Create ",
                " Company"),
            React.createElement("div", { style: { textAlign: "right", fontSize: "small", fontStyle: "italic" } }, newCompany.alternativeNames?.join(", "))),
        React.createElement(ModalBody, null,
            React.createElement(CompanyMetaForm, { company: newCompany, selectedCompany: companyForLei, setCompany: setNewCompany })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: loading, onClick: onClickClear, style: { marginRight: '0.5rem' } }, "CLEAR"),
            React.createElement(Button, { onClick: onClickClearAlternativeNames, style: { marginRight: '0.5rem' } }, "CLEAR NAMES"),
            React.createElement(Button, { disabled: disabled, loading: loading, onClick: onClickSave, onKeyUp: onKeyUpSave }, "SAVE"))));
};
export default CompanyFormModal;
