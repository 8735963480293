import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const DocumentationCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isLoadingSave, setLoadingSave] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setvalue] = React.useState(() => {
        const field = column.id;
        const overrideValue = row.original.overrideMap?.[field];
        if (overrideValue !== undefined && typeof overrideValue !== 'string') {
            throw new Error(`Documentation cell must be a string but is ${typeof overrideValue}`);
        }
        const isMulti = column.columnDef.meta?.dropdown?.isMulti;
        if (overrideValue !== undefined && isMulti !== undefined) {
            return (isMulti && typeof overrideValue === 'string') ? overrideValue.split(',') : overrideValue;
        }
        return row.original.data?.documentation !== undefined ? row.original.data.documentation[field] : undefined;
    });
    const [className, setClassName] = React.useState();
    const title = React.useMemo(() => {
        const id = column.id;
        if (row.original.overrideMap?.[column.id] !== undefined
            && row.original.data?.documentation?.[id] !== undefined && column.columnDef.meta?.dropdown !== undefined) {
            const titleValue = row.original.data.documentation[id];
            const newTitle = Array.isArray(titleValue)
                ? column.columnDef.meta.dropdown.options.filter((o) => 'value' in o && titleValue.includes(o.value)).map((o) => o.label).join(', ')
                : column.columnDef.meta.dropdown.options.find((o) => 'value' in o && titleValue === o.value)?.label;
            return newTitle;
        }
        return undefined;
    }, [column.id, row.original.overrideMap, row.original.data?.documentation, column.columnDef.meta?.dropdown]);
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const formData = new FormData();
            formData.append('value', Array.isArray(newValue) ? newValue.join(',') : newValue ?? '');
            formData.append('key', column.id);
            formData.append('transactionId', row.original.transaction.id);
            formData.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest('/syndicate/transactions/override', { body: formData, method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoadingSave(true);
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                sendFetchError('Unable to save transaction override data', error, request);
            }
            finally {
                setLoadingSave(false);
            }
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const onChange = React.useCallback((event) => {
        const labelValue = event.target.value;
        let newValue;
        if (Array.isArray(labelValue)) {
            newValue = labelValue.map((v) => v.value);
            setvalue(newValue);
            setLoading(newValue.length === 0);
            setEditing(newValue.length > 0);
            (async () => {
                await save(newValue);
                setLoading(false);
                setEditing(newValue.length > 0);
            })();
        }
        else if (labelValue === null || 'value' in labelValue) {
            newValue = labelValue?.value;
            setvalue(newValue);
            setEditing(false);
            setLoading(true);
            (async () => {
                await save(newValue);
                setLoading(false);
                setEditing(false);
            })();
        }
    }, [save]);
    const removeOverride = React.useCallback(() => {
        const newValue = column.columnDef.meta?.dropdown?.isMulti ? [] : undefined;
        setvalue(newValue);
        setLoading(true);
        setEditing(false);
        (async () => {
            await save(newValue);
            setLoading(false);
            setEditing(false);
        })();
    }, [column.columnDef.meta?.dropdown, save]);
    const closeDropdown = React.useCallback(() => {
        setLoadingSave(true);
        setEditing(false);
        if (value !== undefined) {
            setLoading(true);
            (async () => {
                await save(value);
                setLoading(false);
            })();
        }
    }, [save, value]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = (event) => {
            event.stopPropagation();
        };
        if (isEditing) {
            document.addEventListener('click', callback, { signal: abortController.signal });
        }
        else {
            document.removeEventListener('click', callback);
        }
        return () => { abortController.abort(); };
    });
    const content = React.useMemo(() => {
        if (isEditing && column.columnDef.meta?.dropdown !== undefined) {
            setClassName('co-editable-dropdown');
            return (React.createElement(Popup, { close: closeDropdown, style: { width: '200px' } }, column.columnDef.meta.dropdown.isMulti
                ? (React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isLoadingSave, placeholder: "Select...", enableTermSearch: true, isMultiSelect: true, isInputClearable: true, isTypeAheadEnabled: true, closeMenuOnSelect: false, menuIsOpen: true, onChange: onChange, options: column.columnDef.meta.dropdown.options, value: column.columnDef.meta.dropdown.options.filter((o) => 'value' in o && Array.isArray(value) && value.includes(o.value)) })) : (React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isLoadingSave, placeholder: "Select...", enableTermSearch: true, isInputClearable: true, isTypeAheadEnabled: true, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: column.columnDef.meta.dropdown.options, value: column.columnDef.meta.dropdown.options.find((o) => 'value' in o && value === o.value) }))));
        }
        if (row.original.overrideMap?.[column.id] !== undefined || (row.original.data?.documentation !== undefined && column.id in row.original.data.documentation)) {
            setClassName('co-centered-box');
            const field = column.id;
            const print = (tranche) => {
                if (tranche.overrideMap && field in tranche.overrideMap) {
                    const val = tranche.overrideMap[field];
                    if (typeof val === 'string') {
                        return val.split(',');
                    }
                }
                const { data } = tranche;
                if (data?.documentation?.[field] !== undefined) {
                    const docValue = data.documentation[field];
                    return Array.isArray(docValue) ? docValue : [docValue];
                }
                return [];
            };
            if (column.columnDef.meta?.dropdown !== undefined) {
                const { dropdown } = column.columnDef.meta;
                const text = dropdown.options.filter((o) => 'value' in o && print(row.original).includes(o.value)).map((o) => o.label).join(', ');
                return text;
            }
            return print(row.original).join(', ');
        }
        return undefined;
    }, [closeDropdown, column.id, column.columnDef.meta, isLoadingSave, isEditing, onChange, row.original, value]);
    const copyValue = React.useCallback(async () => {
        console.log("Copying: %o", value);
        if (value !== undefined) {
            const t = { type: 'text/plain' };
            const clipboardText = { columnId: column.id, text: value };
            const p = JSON.stringify(clipboardText);
            const blob = new Blob([p], t);
            const items = new ClipboardItem({ 'text/plain': blob });
            await navigator.clipboard.write([items]);
        }
    }, [column, value]);
    const pasteValue = React.useCallback(async () => {
        const clipboard = await navigator.clipboard.read();
        const blob = await clipboard[0].getType('text/plain');
        const text = await blob.text();
        console.log("PASTING: %o", text);
        try {
            const clipboardText = JSON.parse(text);
            if (typeof clipboardText === 'object' && clipboardText !== null && 'columnId' in clipboardText && 'text' in clipboardText && clipboardText.columnId === column.id && (typeof clipboardText.text === 'string' || Array.isArray(clipboardText.text))) {
                setvalue(clipboardText.text);
                await save(clipboardText.text);
            }
        }
        catch (error) {
            console.error("Error: %o", error);
            // the current value in the clipboard is not a saved value
        }
    }, [column.id, save]);
    return (React.createElement(EditableCell, { className: className, copyValue: copyValue, errorText: undefined, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing || isLoading, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, pasteValue: pasteValue, removeOverride: removeOverride, setEditing: setEditing, title: title }, content));
};
export default DocumentationCell;
