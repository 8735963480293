import { Card, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import NavigationContext from 'contexts/NavigationContext';
const DevNotificationSettings = () => {
    const { apiFetchNoContent, apiFetchJson } = React.useContext(DownloadContext);
    const { isAdmin } = React.useContext(NavigationContext);
    const [dev, setDev] = React.useState(false);
    const pushId = React.useMemo(async () => {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (subscription === null) {
            return undefined;
        }
        const key = subscription.getKey ? subscription.getKey('p256dh') : null;
        const auth = subscription.getKey ? subscription.getKey('auth') : null;
        if (key === null || auth === null) {
            return undefined;
        }
        const auth8Array = new Uint8Array(auth);
        const authText = String.fromCharCode.apply(null, Array.from(auth8Array));
        const key8Array = new Uint8Array(key);
        const keyText = String.fromCharCode.apply(null, Array.from(key8Array));
        return {
            auth: window.btoa(authText),
            endpoint: subscription.endpoint,
            key: window.btoa(keyText),
        };
    }, []);
    const saveNotification = React.useCallback(() => {
        (async () => {
            const p = await pushId;
            if (p !== undefined) {
                const formData = new FormData();
                formData.append('subscription', new Blob([JSON.stringify(p)], { type: 'application/json' }));
                const method = !dev ? 'POST' : 'DELETE';
                const request = new ServerRequest('/admin/dev-notification-sub', { body: formData, method });
                try {
                    await apiFetchNoContent(request);
                    setDev(!dev);
                }
                catch (error) {
                    console.error("ERROR: %o", error);
                    sendFetchError('Unable to save notification', error, request);
                }
            }
        })();
    }, [apiFetchNoContent, dev, pushId]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const push = await pushId;
            if (push !== undefined) {
                const url = new ApiServerURL('/admin/dev-notification-sub');
                url.searchParams.append('key', push.key);
                url.searchParams.append('auth', push.auth);
                url.searchParams.append('endpoint', push.endpoint);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setDev(body !== null);
                }
                catch (error) {
                    sendFetchError('Unable to load notification subscription', error, request);
                }
            }
        })();
    }, [apiFetchJson, pushId]);
    const onChangeDevNotifications = React.useCallback(() => {
        saveNotification();
    }, [saveNotification]);
    return (React.createElement(Card, { className: "co-card-stack", key: "NOTIFICATION", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Notifications"),
        isAdmin() ?
            React.createElement(React.Fragment, null,
                React.createElement(Switch, { label: "Get Developer Notifications", name: "dev-notifications", onChange: onChangeDevNotifications, status: dev ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: dev ? 'true' : 'false' })) : undefined));
};
export default DevNotificationSettings;
