import * as React from 'react';
import TimestampFilter from './TimestampFilter';
import EditableTextCell from './EditableTextCell';
import print from '../cells/print';
import EditableDropdownCell from './EditableDropdownCell';
import DocumentationCell from './DocumentationCell';
import EditableDateCell from './EditableDateCell';
import TimestampFilterPlus from './TimestampFilterPlus';
import MaturityCell from './MaturityCell';
import EditableBooleanCell from './EditableBooleanCell';
import BookrunnersCell from './BookrunnersCell';
import RenderingContext from 'contexts/RenderingContext';
import useOption from 'components/customHooks';
import CompanyCellTranche from './CompanyCellTranche';
import IssuerCell from './IssuerCell';
function labelValuesToMap(values) {
    const map = new Map();
    values.filter(v => 'value' in v).forEach((v) => {
        if ('value' in v && v.value) {
            map.set(v.value, v.label);
        }
    });
    return map;
}
;
const useOverviewColumnsAdmin = () => {
    const { printDate } = React.useContext(RenderingContext);
    const currencyOptions = useOption('/syndicate/overview/currencies/options');
    const esgOptions = useOption('/syndicate/constant/ESG/options');
    const regionOptions = useOption('/syndicate/overview/regions/options');
    const sectorOptions = useOption('/syndicate/overview/sectors/options');
    const statusOfNotesOptions = useOption('/syndicate/constant/STATUS_NOTES/options');
    const usSalesOptions = useOption('/syndicate/constant/US_SALES/options');
    const statusOptions = useOption('/syndicate/overview/status/options');
    const typeOptions = useOption('/syndicate/overview/types/options');
    return [
        {
            accessorFn: print.sector(labelValuesToMap(sectorOptions)),
            header: 'Sector',
            id: 'sector',
            meta: {
                canCombine: true, className: 'co-static', dropdown: { isMulti: false, options: sectorOptions },
            },
            size: 80,
        },
        {
            accessorFn: print.types(labelValuesToMap(typeOptions)),
            header: 'Types',
            id: 'types',
            meta: { canCombine: true, className: 'co-static', dropdown: { isMulti: true, options: typeOptions } },
            size: 240,
        },
        {
            accessorFn: print.regions(labelValuesToMap(regionOptions)),
            header: 'Regions',
            id: 'regions',
            meta: { canCombine: true, className: 'co-static', dropdown: { isMulti: true, options: regionOptions } },
            size: 100,
        },
        {
            cell: CompanyCellTranche,
            header: 'Company',
            id: 'company',
            meta: { canCombine: true, className: 'co-static' },
            size: 240,
        },
        {
            accessorFn: print.documentation(labelValuesToMap(esgOptions), 'esg'),
            cell: DocumentationCell,
            header: 'ESG',
            id: 'esg',
            meta: { dropdown: { options: esgOptions }, isEditable: true },
            size: 80,
        },
        {
            accessorFn: print.documentationArray(labelValuesToMap(statusOfNotesOptions), 'statusOfNotes'),
            cell: DocumentationCell,
            header: 'Rank/Type',
            id: 'statusOfNotes',
            meta: { dropdown: { isMulti: true, options: statusOfNotesOptions }, isEditable: true },
            size: 200,
        },
        {
            accessorFn: print.issuer,
            cell: IssuerCell,
            enableColumnFilter: true,
            header: 'Issuer',
            id: 'issuer',
            meta: { canCombine: true, style: { textAlign: 'right', fontWeight: 'bold' }, isEditable: true },
            size: 400,
        },
        {
            accessorFn: print.ticker,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Ticker',
            id: 'ticker',
            meta: { isEditable: true },
            size: 120,
        },
        {
            accessorFn: print.ratings,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Ratings',
            // meta: { isEditable: true },
            id: 'ratings',
        },
        {
            accessorFn: print.ratingIndividual('moodys', 'MOODYS'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'MO',
            id: 'moodys',
            meta: { isEditable: true },
            size: 64
        },
        {
            accessorFn: print.ratingIndividual('sp', 'S_P'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'SP',
            id: 'sp',
            meta: { isEditable: true },
            size: 64
        },
        {
            accessorFn: print.ratingIndividual('fitch', 'FITCH'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'FI',
            id: 'fitch',
            meta: { isEditable: true },
            size: 64
        },
        {
            accessorFn: print.currency,
            cell: EditableTextCell,
            enableColumnFilter: true,
            header: 'Currency',
            id: 'currency',
            meta: {
                dropdown: { options: currencyOptions }, isEditable: true,
            },
            size: 100,
        },
        {
            accessorFn: print.amount,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Amount',
            id: 'amount',
            maxSize: 82,
            meta: { isEditable: true },
        },
        {
            accessorFn: print.coupon,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Coupon',
            id: 'coupon',
            size: 80,
            meta: { isEditable: true },
        },
        {
            accessorFn: print.maturity,
            cell: MaturityCell,
            enableColumnFilter: false,
            header: 'Maturity',
            id: 'maturity',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.tenor,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Tenor',
            id: 'tenor',
            meta: { isEditable: true },
            size: 48,
        },
        {
            accessorFn: print.guidance('initialPriceThoughts'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'IPT',
            id: 'initialPriceThoughts',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.guidance('guidance'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Guidance',
            id: 'guidance',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.pricing,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Spd at Issue',
            id: 'pricing',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.iptMove,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Move from IPT',
            id: 'iptMove',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.book,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Last Book Update',
            id: 'books',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.oversubscription,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Oversubscription level',
            id: 'oversubscription',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.bookrunners,
            cell: BookrunnersCell,
            header: 'Leads',
            id: 'bookrunners',
            meta: { canCombine: true, isEditable: true, style: { textAlign: 'left' } },
            size: 720,
        },
        {
            accessorFn: print.isins,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'ISIN (OLD)',
            id: 'isin_old',
            // meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('ISIN'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'ISIN',
            id: 'isin',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('ISIN', 'REGS'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'ISIN (RegS)',
            id: 'isinRegS',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('ISIN', 'RULE_144A'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'ISIN (144A)',
            id: 'isin144A',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('CUSIP'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'CUSIP',
            id: 'cusip',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('CUSIP', 'REGS'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'CUSIP (RegS)',
            id: 'cusipRegS',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.identifier('CUSIP', 'RULE_144A'),
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'CUSIP (144A)',
            id: 'cusip144A',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.lei,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'LEI',
            id: 'lei',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.documentationArray(labelValuesToMap(usSalesOptions), 'usSales'),
            cell: DocumentationCell,
            header: 'US Registration',
            id: 'usSales',
            meta: { dropdown: { isMulti: true, options: usSalesOptions }, isEditable: true },
            size: 200,
        },
        {
            accessorFn: print.minDenomination,
            cell: EditableTextCell,
            header: 'Denominations',
            id: 'denominations',
            meta: { isEditable: true },
            size: 200,
        },
        {
            accessorFn: print.pricingDate,
            cell: EditableDateCell,
            enableColumnFilter: true,
            header: 'Pricing Date',
            id: 'pricingDate',
            meta: {
                isEditable: true, Panel: TimestampFilterPlus,
            },
        },
        {
            accessorFn: print.settlementDate,
            cell: EditableDateCell,
            enableColumnFilter: false,
            header: 'Settlement Date',
            id: 'settlementDate',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.status(labelValuesToMap(statusOptions)),
            cell: EditableDropdownCell,
            header: 'Status',
            id: 'status',
            meta: { dropdown: { isMulti: false, options: statusOptions }, isEditable: true },
            size: 80,
        },
        {
            accessorFn: print.timing('booksSubject'),
            enableColumnFilter: false,
            header: 'Books Subject',
            id: 'booksSubject',
        },
        {
            accessorFn: print.timing('booksClose'),
            enableColumnFilter: false,
            header: 'Books Close',
            id: 'booksClose',
        },
        {
            accessorFn: print.timing('hedgeDeadline'),
            enableColumnFilter: false,
            header: 'Hedge Deadline',
            id: 'hedgeDeadline',
        },
        {
            accessorFn: print.timing('timeOfExecution'),
            enableColumnFilter: false,
            header: 'Time of Execution',
            id: 'timeOfExecution',
        },
        {
            accessorFn: print.timing('freeToTrade'),
            enableColumnFilter: false,
            header: 'Free to Trade',
            id: 'freeToTrade',
        },
        {
            accessorFn: print.interestBasis,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Interest Basis',
            id: 'interestBasis',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.yield,
            cell: EditableTextCell,
            header: 'Yield',
            id: 'yield',
            maxSize: 200,
            minSize: 200,
            size: 200,
            meta: { isEditable: true },
        },
        {
            accessorFn: print.tap,
            cell: EditableBooleanCell,
            enableColumnFilter: false,
            header: 'Tap',
            id: 'tap',
            meta: { isEditable: true },
            size: 50,
        },
        {
            accessorFn: print.benchmark,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'BNCH',
            id: 'benchmark',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.spread,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Spread',
            id: 'spread',
            meta: { isEditable: true },
        },
        {
            accessorFn: print.price,
            cell: EditableTextCell,
            enableColumnFilter: false,
            header: 'Price',
            id: 'price',
            meta: { isEditable: true },
        },
        {
            accessorFn: (t) => printDate(t.transaction.firstUpdate),
            header: 'Announced',
            id: 'announcementDate',
            meta: { className: 'co-static' },
        },
        {
            accessorFn: (t) => printDate(t.transaction.lastUpdate),
            header: 'Last Update',
            id: 'lastUpdate',
            meta: {
                className: 'co-static', Panel: TimestampFilter,
            },
        },
        {
            accessorFn: (t) => t.transaction.id,
            header: 'Transaction Id',
            id: 'transactionId',
        },
        {
            accessorFn: (t) => t.trancheIndex.toString(),
            header: 'Tranche Index',
            id: 'trancheIndex',
        },
        {
            accessorFn: (t) => t.errors?.join(", "),
            header: 'Error(s)',
            id: 'errors'
        }
    ];
};
export default useOverviewColumnsAdmin;
