import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle, TextField } from '@symphony-ui/uitoolkit-components';
import useOption from 'components/customHooks';
import Country from '../../models/Country';
import * as React from 'react';
const CountryFormModal = ({ country, onClose, save }) => {
    const [newCountry, setNewCountry] = React.useState(Country.cloneOrNew(country));
    const [loading, setLoading] = React.useState(false);
    const optionsRegion = useOption('/admin/options/region');
    const onClickSave = React.useCallback(() => {
        (async () => {
            setLoading(true);
            await save(newCountry);
            setLoading(false);
            onClose();
        })();
    }, [newCountry, onClose, save]);
    const onChangeName = React.useCallback((event) => {
        setNewCountry(newCountry.setName(event.target.value));
    }, [newCountry]);
    const onChangeShortName = React.useCallback((event) => {
        setNewCountry(newCountry.setShortName(event.target.value));
    }, [newCountry]);
    const onChangeId = React.useCallback((event) => {
        if (/^[a-zA-Z]*$/.exec(event.target.value) !== null && event.target.value.length < 3) {
            setNewCountry(newCountry.setId(event.target.value.toUpperCase()));
        }
    }, [newCountry]);
    const onChangeRegion = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = value.value;
        setNewCountry(newCountry.setRegion(newValue));
    }, [newCountry]);
    const disabled = React.useMemo(() => {
        return newCountry.id === undefined || newCountry.name === undefined || newCountry.id.length !== 2 || newCountry.name.length < 3;
    }, [newCountry]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalTitle, null,
            country?.id ? "Update " : "Create ",
            " Country"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { showRequired: true, label: "id (ISO 3166)", onChange: onChangeId, value: newCountry.id }),
            React.createElement(TextField, { showRequired: true, label: "Name", onChange: onChangeName, value: newCountry.name }),
            React.createElement(TextField, { label: "Short Name", onChange: onChangeShortName, value: newCountry.shortName }),
            React.createElement(Dropdown, { label: "Region", onChange: onChangeRegion, options: optionsRegion, required: true, value: optionsRegion.filter(o => 'value' in o).find(o => newCountry.region === o.value) })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabled, loading: loading, onClick: onClickSave }, "SAVE"))));
};
export default CountryFormModal;
