import * as React from 'react';
import ProgressError from './ProgressError';
const ProgressBar = ({ close, count, errors, timestamp, title, total, }) => {
    const estimatedTime = React.useMemo(() => {
        if (total <= 0) {
            return 'calculating total...';
        }
        const timestampInMillis = new Date(timestamp).getTime();
        const estimatedMillis = (Date.now() - timestampInMillis) * ((total - count) / count);
        let seconds = Math.round(estimatedMillis / 1000);
        let minutes = Math.round(seconds / 60);
        seconds %= 60;
        const hours = Math.round(minutes / 60);
        minutes %= 60;
        let value = `${seconds} seconds`;
        if (minutes === 0 && hours === 0) {
            return value;
        }
        value = `${minutes} minutes and ${value}`;
        if (hours === 0) {
            return value;
        }
        return `estimated time: ${hours} hours, ${value}`;
    }, [count, timestamp, total]);
    const onClick = React.useCallback(() => {
        close();
    }, [close]);
    const onKeyUp = React.useCallback(() => {
    }, []);
    return (React.createElement("div", { className: "tk-banner tk-banner--info tk-banner--medium" },
        React.createElement("div", { className: "tk-banner__variant-icon" }),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' } },
            React.createElement("label", { htmlFor: title }, `${title}, ${estimatedTime}`),
            total > 0
                ? React.createElement("progress", { id: title, max: total, value: count, style: { width: '100%' } })
                : undefined,
            React.createElement("div", null, typeof errors !== 'undefined' ? errors.map((e) => (React.createElement(ProgressError, { error: e, key: e.detail }))) : null)),
        React.createElement("div", { "aria-label": "Close", className: "tk-banner__close", onClick: onClick, role: "button", tabIndex: 0, onKeyUp: onKeyUp })));
};
export default ProgressBar;
