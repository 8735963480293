import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import CustomTable from 'components/custom-table/CustomTable';
import ActionCell from 'components/custom-table/action/ActionCell';
import { BannerType, Button } from '@symphony-ui/uitoolkit-components';
import Contributor from '../../../models/Contributor';
import ContributorFormModal from './ContributorFormModal';
import ApiServerURL from 'models/ServerUrl';
const ContributorTable = () => {
    const { apiFetchJson, apiFetchText } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isFillLoading, setFillLoading] = React.useState(false);
    const [contributor, setContributor] = React.useState();
    const [contributors, setContributors] = React.useState([]);
    const [showFormModal, setShowFormModal] = React.useState(false);
    const [optionsRegion, setOptionsRegion] = React.useState([]);
    const [optionsSector, setOptionsSector] = React.useState([]);
    const [optionsCompany, setOptionsCompany] = React.useState([]);
    const [optionsTimeZone, setOptionsTimeZone] = React.useState([]);
    const onCloseForm = React.useCallback(() => {
        setShowFormModal(false);
    }, []);
    const fetchContributors = React.useCallback(async (signal) => {
        const request = new ServerRequest("/syndicate/contributor");
        try {
            setLoading(true);
            console.log("DOWNLODIN");
            const body = await apiFetchJson(request, signal);
            const downloaded = body.map(c => new Contributor(c));
            setContributors(downloaded);
        }
        catch (error) {
            setError(true);
            sendFetchError("Unable to download contributors", error, request);
        }
        finally {
            setLoading(false);
        }
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => fetchContributors(abortController.signal))();
        return () => abortController.abort();
    }, [fetchContributors]);
    const edit = React.useCallback(({ row: { original: contributor } }) => {
        setContributor(contributor);
        setShowFormModal(true);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sectors');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsSector(body);
            }
            catch (error) {
                sendFetchError('Unable to download sector options', error, request);
            }
        })();
        return () => {
            abortController.abort();
        };
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/admin/companies/options');
            url.searchParams.append('type', 'CONTRIBUTOR');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsCompany(body);
            }
            catch (error) {
                sendFetchError('Unable to download company options', error, request);
            }
        })();
        return () => {
            abortController.abort();
        };
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'regions');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsRegion(body);
            }
            catch (error) {
                sendFetchError('Unable to download region options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const request = new ServerRequest('/syndicate/contributor/timezones');
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsTimeZone(body);
            }
            catch (error) {
                sendFetchError('Unable to download region options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const onClickFill = React.useCallback(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/contributor/fill', { method: 'POST' });
            try {
                setFillLoading(true);
                const body = await apiFetchText(request);
                sendMessage(body + " new contributors have been added", BannerType.SUCCESS);
                await fetchContributors();
            }
            catch (error) {
                sendFetchError("Unable to fill database with contributors", error, request);
            }
            finally {
                setFillLoading(false);
            }
        })();
    }, [apiFetchText, fetchContributors]);
    const save = React.useCallback(async (data) => {
        const request = new ServerRequest("/syndicate/contributor", { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PATCH' });
        try {
            const body = await apiFetchJson(request);
            ;
            const updatedCompany = new Contributor(body);
            ;
            const index = contributors.findIndex(d => d.id === body.id);
            const updatedContributors = [...contributors];
            if (index === -1) {
                updatedContributors.push(updatedCompany);
            }
            else {
                updatedContributors[index] = updatedCompany;
            }
            setContributors(updatedContributors);
        }
        catch (error) {
            sendFetchError("Unable to update company", error, request);
        }
    }, [apiFetchJson, contributors]);
    const actionElements = React.useMemo(() => {
        return [(React.createElement(Button, { loading: isFillLoading, onClick: onClickFill, size: "small" }, "FILL"))];
    }, [isFillLoading, onClickFill]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' }
                ]
            }, size: 20
        },
        {
            accessorFn: (contributor) => contributor.emailAddress.personal,
            header: "Name",
            id: 'name'
        },
        {
            accessorFn: (contributor) => contributor.emailAddress.value,
            header: "Email Address",
            id: 'emailAddress'
        },
        {
            accessorFn: (contributor) => optionsCompany.filter(o => 'value' in o).find(o => contributor.companyId === o.value)?.label,
            header: "Company",
            id: 'company'
        },
        {
            accessorFn: (contributor) => optionsRegion.filter(o => 'value' in o).filter(o => contributor.regions?.includes(o.value)).map(o => o.label).join(", "),
            header: "Region(s)",
            id: 'regions'
        },
        {
            accessorFn: (contributor) => optionsSector.filter(o => 'value' in o).filter(o => contributor.sectors?.includes(o.value)).map(o => o.label).join(", "),
            header: "Sector(s)",
            id: 'sectors'
        },
        {
            accessorKey: 'timeZone',
            header: "Time Zone",
            id: 'timeZone'
        }
    ], [edit, optionsCompany, optionsRegion, optionsSector]);
    return (React.createElement(React.Fragment, null,
        showFormModal && contributor !== undefined ?
            React.createElement(ContributorFormModal, { onClose: onCloseForm, contributor: contributor, optionsCompany: optionsCompany, optionsRegion: optionsRegion, optionsSector: optionsSector, optionsTimeZone: optionsTimeZone, save: save }) : undefined,
        React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: contributors, emptyMessage: "", errorMessage: "Error downloading contributors", hasError: hasError, isLoading: isLoading, name: "contributor" })));
};
export default ContributorTable;
