import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TextField } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const CountryFormModal = ({ country, onClose, optionsRegion, save }) => {
    const [newCountry, setNewCountry] = React.useState(country.clone());
    const onClickSave = React.useCallback(() => {
        (async () => {
            await save(newCountry);
            onClose();
        })();
    }, [newCountry, onClose, save]);
    const onChangeName = React.useCallback((event) => setNewCountry(newCountry.setName(event.target.value)), [newCountry]);
    const onChangeRegion = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = value.value;
        setNewCountry(newCountry.setRegion(newValue));
    }, [newCountry]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            country.id ? "Update " : "Create ",
            " Country"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Name", onChange: onChangeName, value: newCountry.name }),
            React.createElement("div", { style: { height: '4rem' } },
                React.createElement("div", { className: "cmo-dropdown-absolute", style: { position: 'absolute', width: 'calc(100% - 5rem)' } },
                    React.createElement(Dropdown, { label: "Types", onChange: onChangeRegion, options: optionsRegion, value: optionsRegion.filter(o => 'value' in o).find(o => newCountry.region === o.value) })))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default CountryFormModal;
