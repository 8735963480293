import { datePattern } from "components/custom-table/dateFormat";
class RenderingService {
    #booleanFormat;
    #dateFormat;
    constructor({ dateFormat, booleanFormat }) {
        this.#booleanFormat = booleanFormat;
        this.#dateFormat = dateFormat;
    }
    printAny = (value) => {
        if (value === undefined) {
            return undefined;
        }
        else if (typeof value === 'string') {
            return value;
        }
        else if (typeof value === 'boolean') {
            return this.printBoolean(value);
        }
        else if (typeof value === 'object') {
            if (typeof value.value === 'number') {
                const loc = (new Intl.NumberFormat()).resolvedOptions().locale;
                return Intl.NumberFormat(loc, value.options).format(value.value);
            }
            else if (typeof value.value === 'object') {
                return this.printDate(value.value, value.options);
            }
        }
    };
    printBoolean = (flag) => {
        if (!flag) {
            return '';
        }
        return this.#booleanFormat === 'x' ? 'X' : 'TRUE';
    };
    printDate = (date, options) => {
        const precision = options?.precision ?? 'YMD';
        if (date === undefined) {
            return '';
        }
        const zeroSeconds = 'T00:00:00.000Z';
        if (!(date instanceof Date)) {
            console.error("ERROR WITH DATE: %o", date);
            return "ERROR: " + date;
        }
        let isDate = false;
        try {
            isDate = date.toISOString().endsWith(zeroSeconds);
        }
        catch (error) {
            console.error("Unable to get ISO string of %o", date, error);
            return 'ERROR';
        }
        switch (this.#dateFormat) {
            case 'iso': {
                if (isDate) {
                    const isoString = date.toISOString().replace(zeroSeconds, '');
                    switch (precision) {
                        case 'Y':
                            return isoString.substring(0, 4);
                        case 'YM':
                            return isoString.substring(0, 7);
                        default:
                            return isoString;
                    }
                }
                return date.toISOString();
            }
            case 'normal': {
                const { locale } = Intl.NumberFormat().resolvedOptions();
                let options;
                if (isDate) {
                    options = { timeZone: 'UTC', year: 'numeric' };
                    if (precision.includes('M'))
                        options.month = 'numeric';
                    if (precision.includes('D'))
                        options.day = 'numeric';
                }
                else {
                    options = {
                        day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit', timeZone: 'UTC',
                    };
                }
                return date.toLocaleString(locale, options);
            }
            default:
                return 'unkown date pattern';
        }
    };
    datePattern = (precision) => {
        return datePattern(this.#dateFormat, precision);
    };
}
export default RenderingService;
