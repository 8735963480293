import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendError } from 'utils/messageUtils';
import TableContext from 'components/custom-table/TableContext';
import TransactionWrapper from '../../../../models/TransactionWrapper';
import TransactionMetaForm from '../transaction-meta/TransactionMetaForm';
import './TransactionMetaModal.css';
const TransactionMetaModal = ({ callback, onClose, transaction }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const [loadingMeta, setLoadingMeta] = React.useState(false);
    const [selectedCompany, setSelectedCompany] = React.useState();
    const [transactionMeta, setTransactionMeta] = React.useState(() => {
        const initialTransactionMeta = (Array.isArray(transaction) ? transaction[0] : transaction).getMeta();
        if (initialTransactionMeta.types === undefined || initialTransactionMeta.types.length === 0) {
            initialTransactionMeta.types = ['NEW_ISSUE'];
        }
        if (initialTransactionMeta.company === undefined) {
            initialTransactionMeta.company = {};
        }
        if (initialTransactionMeta.company.type === undefined) {
            initialTransactionMeta.company.type = 'ISSUER';
        }
        return initialTransactionMeta;
    });
    const disabledMeta = React.useMemo(() => transactionMeta.sector === undefined || transactionMeta.regions === undefined || transactionMeta.regions.length === 0 || transactionMeta.types === undefined || transactionMeta.types.length === 0 ||
        transactionMeta.company?.companyId === undefined || transactionMeta.company.type === undefined, [transactionMeta]);
    const title = React.useMemo(() => (Array.isArray(transaction) ? transaction[0] : transaction).name, [transaction]);
    const saveMeta = React.useCallback(async () => {
        if (transactionMeta.sector !== undefined && transactionMeta.regions !== undefined && transactionMeta.types !== undefined && tableUpdateFn !== undefined && transactionMeta.company !== undefined) {
            const companyClean = { ...transactionMeta.company };
            delete companyClean.company;
            delete companyClean.ultimateParent;
            const formData = new FormData();
            formData.append('transactionId', Array.isArray(transaction) ? transaction.map(t => t.id).join(",") : transaction.id);
            formData.append('sector', transactionMeta.sector);
            formData.append('regions', transactionMeta.regions.join(","));
            formData.append('types', transactionMeta.types.join(','));
            formData.append('company', new Blob([JSON.stringify(companyClean)], { type: 'application/json' }));
            const request = new ServerRequest('/syndicate/transactions/meta-all-update', { body: formData, method: 'PATCH' });
            const responseTransction = await apiFetchJson(request);
            const returnTransactions = responseTransction.map(t => new TransactionWrapper(t));
            const updateFn = (data) => ({
                pageParams: data.pageParams,
                pages: [...data.pages].map((p) => p.map((t) => returnTransactions.find(updated => updated.id === t.id) ?? t)),
            });
            tableUpdateFn(updateFn);
            if (callback !== undefined) {
                callback();
            }
        }
    }, [apiFetchJson, callback, transactionMeta, transaction, tableUpdateFn]);
    const onClickMetaSave = React.useCallback(() => {
        (async () => {
            setLoadingMeta(true);
            try {
                await saveMeta();
                onClose();
            }
            catch (error) {
                sendError("Unable to save Meta", error);
            }
            finally {
                setLoadingMeta(false);
            }
        })();
    }, [onClose, saveMeta]);
    const onKeyUpMetaSave = React.useCallback((event) => {
        if (event.key === 'Enter' && !disabledMeta) {
            onClickMetaSave();
        }
    }, [disabledMeta, onClickMetaSave]);
    const titleRef = React.useRef(null);
    const containsSelection = React.useCallback((parent, selection) => parent !== null && selection.type === 'Range' ? parent.contains(selection.anchorNode) && parent.contains(selection.focusNode) : false, []);
    const checkSelection = React.useCallback(() => {
        const selection = window.getSelection();
        console.log("Selection: %o", selection);
        if (selection !== null && (containsSelection(titleRef.current, selection))) {
            setSelectedCompany(selection.toString().trim());
        }
    }, [containsSelection]);
    React.useEffect(() => {
        const titleElement = titleRef.current;
        if (titleElement !== null) {
            titleElement.addEventListener('mouseup', checkSelection);
        }
        return () => {
            if (titleElement !== null) {
                titleElement.removeEventListener('mouseup', checkSelection);
            }
        };
    }, [checkSelection]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalTitle, null, "Set Meta data transaction"),
        React.createElement(ModalHeader, null,
            React.createElement("div", { ref: titleRef }, title)),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-form" },
                React.createElement(TransactionMetaForm, { isLoading: loadingMeta, transactionMeta: transactionMeta, selectedCompany: selectedCompany, setSelectedCompany: setSelectedCompany, setTransactionMeta: setTransactionMeta }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabledMeta, loading: loadingMeta, onClick: onClickMetaSave, onKeyUp: onKeyUpMetaSave }, "SAVE"))));
};
export default TransactionMetaModal;
