import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import MesssagesModal from '../transactions/overview/MessagesModal';
import TransactionWrapper from '../../models/TransactionWrapper';
import StreamItem from './StreamItem';
const Stream = () => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [items, setItems] = React.useState([]);
    const [detailsId, setDetailsId] = React.useState();
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/page');
            url.searchParams.append('start', '0');
            url.searchParams.append('end', '100');
            const request = new ServerRequest(url);
            try {
                const body = await jsonPlus(request, 'Error downloading users.');
                const transaction = body.map((b) => new TransactionWrapper(b));
                setItems(transaction);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(MesssagesModal, { hideModal: () => setDetailsId(undefined), show: detailsId !== undefined, splitScreen: false, transactionId: detailsId }),
        React.createElement("div", { style: { overflow: 'auto' } }, items.map((m) => (React.createElement(StreamItem, { key: m.id, messages: m, setDetailsId: setDetailsId }))))));
};
export default Stream;
