import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import { getOptionsMulti } from 'utils/change';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import { errorMessage } from 'utils/validation/initialValidation';
import Party from '../../models/Party';
const PartyForm = ({ party, save }) => {
    const [data, setData] = React.useState(party ?? new Party());
    const [errorState, setErrorState] = React.useState({});
    const [dropdownOptions, setDropdownOptions] = React.useState([]);
    const validators = React.useMemo(() => ({
        name: [Validators.Required],
        names: [Validators.Required],
        podId: [Validators.Number],
        shortName: [Validators.MaxLength(6)],
    }), []);
    React.useEffect(() => {
        setDropdownOptions('names' in data && data.names !== undefined ? data.names.map((p) => ({ label: p, value: p })) : []);
    }, [data]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Party", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "party-form" },
            React.createElement(Validation, { validator: validators.name, errorMessage: errorMessage, onValidationChanged: onValidationChanged('name') },
                React.createElement(TextField, { label: "Name", onChange: onChangeInput('name'), value: data.name || '' })),
            React.createElement(Validation, { validator: validators.podId, errorMessage: errorMessage, onValidationChanged: onValidationChanged('podId') },
                React.createElement(TextField, { label: "PodId", onChange: onChangeInput('podId'), value: data.podId?.toString() || '' })),
            React.createElement(Validation, { validator: validators.shortName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('shortName') },
                React.createElement(TextField, { label: "Short Name", onChange: onChangeInput('shortName'), tooltip: "Up to 6 capital letters", value: data.shortName || '' })),
            React.createElement(Validation, { validator: validators.names, errorMessage: errorMessage, onValidationChanged: onValidationChanged('names') },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Synonyms", name: "names", onChange: onChangeDropdown('names'), options: dropdownOptions, value: getOptionsMulti(data.names, dropdownOptions) })))));
};
export default PartyForm;
