class SecQuery {
    CIK;
    company;
    dateb;
    owner;
    type;
    getCreationDate = () => undefined;
    getCurrency = () => undefined;
    getMaturity = () => undefined;
}
export default SecQuery;
