export const datePattern = (format = 'normal', precision = 'YMD') => {
    switch (format) {
        case 'iso':
            return 'yyyy-MM-dd';
        case 'normal': {
            const mapPart = (part) => {
                switch (part.type) {
                    case 'literal':
                        return part.value;
                    case 'day':
                        return 'dd';
                    case 'month':
                        return 'MM';
                    case 'year':
                        return 'yyyy';
                    default:
                        return null;
                }
            };
            const parts = (new Intl.DateTimeFormat()).formatToParts(new Date()).filter((p) => {
                switch (p.type) {
                    case 'literal':
                        return true;
                    case 'day':
                        return precision.includes('D');
                    case 'month':
                        return precision.includes('M');
                    case 'year':
                        return precision.includes('Y');
                    default:
                        return false;
                }
            });
            while (parts.length > 0 && parts[0].type === 'literal') {
                parts.shift();
            }
            while (parts.length > 0 && parts[parts.length - 1].type === 'literal') {
                parts.pop();
            }
            return parts.map((p) => mapPart(p)).join('');
        }
        default:
            return 'unkown date pattern';
    }
};
// export const printBoolean = (flag: boolean, format: BooleanFormat): string => {
//   if (!flag) {
//     return '';
//   }
//   return format === 'x' ? 'X' : 'TRUE';
// };
// export const printDate = (date: Date, format: DateFormat, precision: DatePrecision = 'YMD'): string => {
//   if (date === undefined) {
//     return '';
//   }
//   const zeroSeconds = 'T00:00:00.000Z';
//   const isDate = date.toISOString().endsWith(zeroSeconds);
//   switch (format) {
//     case 'iso': {
//       if (isDate) {
//         const isoString = date.toISOString().replace(zeroSeconds, '');
//         switch (precision) {
//           case 'Y':
//             return isoString.substring(0, 4);
//           case 'YM':
//             return isoString.substring(0, 7);
//           default:
//             return isoString;
//         }
//       }
//       return date.toISOString();
//     }
//     case 'normal': {
//       const { locale } = Intl.NumberFormat().resolvedOptions();
//       let options: Intl.DateTimeFormatOptions;
//       if (isDate) {
//         options = { timeZone: 'UTC', year: 'numeric' };
//         if (precision.includes('M')) options.month = 'numeric';
//         if (precision.includes('D')) options.day = 'numeric';
//       } else {
//         options = {
//           day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit', timeZone: 'UTC',
//         };
//       }
//       return date.toLocaleString(locale, options);
//     }
//     default:
//       return 'unkown date pattern';
//   }
// };
