import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import SyndInfoLine from './SyndInfoLine';
const SyndData = () => (React.createElement(Card, { style: { height: 'min-content', marginBottom: '1rem', padding: '1rem' } },
    React.createElement(Typography, { type: "h1" }, "System Info"),
    React.createElement("table", null,
        React.createElement("tbody", null,
            React.createElement(SyndInfoLine, { path: "inbox-status", text: "Inbox Open" }),
            React.createElement(SyndInfoLine, { path: "active-thread-count", text: "Number of active threads" }),
            React.createElement(SyndInfoLine, { path: "largest-pool-size", text: "Largest Pool Size" }),
            React.createElement(SyndInfoLine, { path: "emails-drive", text: "Number of emails in inbox (drive)" }),
            React.createElement(SyndInfoLine, { path: "emails-imap", text: "Number of emails in inbox (IMAP)" }),
            React.createElement(SyndInfoLine, { path: "emails-database", text: "Number of emails saved in database" }),
            React.createElement(SyndInfoLine, { path: "updates-count", text: "Number of updates" }),
            React.createElement(SyndInfoLine, { path: "transactions-count", text: "Number of transactions" })))));
export default SyndData;
