import { BannerType } from '@symphony-ui/uitoolkit-components/components/banner/interfaces';
class DownloadService {
    #authorizedFetch;
    setMessage;
    setOffline;
    constructor({ setMessage, setOffline, authorizedFetch, }) {
        this.setMessage = setMessage;
        this.setOffline = setOffline;
        this.#authorizedFetch = authorizedFetch;
    }
    setError = (error, request) => {
        if ((error instanceof TypeError && error.message === 'Failed to fetch')) {
            this.setOffline(true);
        }
        else {
            this.setMessage({ content: `${error.name} - ${error.message} in ${request.url.toString()}`, variant: BannerType.ERROR });
        }
    };
    apiFetchNoContent = async (request, errorMessage) => {
        try {
            const response = await this.#authorizedFetch(request);
            if (response.status === 204) {
                // need to read empty body to avoid console error 'Fetch failed loading'
                await response.text();
            }
            else if (response.status !== 401) {
                const problemDetail = await response.json();
                this.#displayServerErrorMessage(problemDetail, errorMessage);
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
    };
    apiFetchJson = async (request, errorMessage) => {
        try {
            const response = await this.#authorizedFetch(request);
            if (response.status === 200) {
                return await response.json();
            }
            if (response.status !== 401) {
                const problemDetail = await response.json();
                this.#displayServerErrorMessage(problemDetail, errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
        throw new Error(typeof errorMessage === 'undefined' ? 'Download Error' : errorMessage);
    };
    apiFetchText = async (request, errorMessage) => {
        try {
            const response = await this.#authorizedFetch(request);
            if (response.status === 200) {
                return await response.text();
            }
            if (response.status !== 401) {
                const problemDetail = await response.json();
                this.#displayServerErrorMessage(problemDetail, errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
        throw new Error(errorMessage);
    };
    #displayServerErrorMessage = (problemDetail, errorMessage) => {
        this.setMessage({ content: `${errorMessage} ${problemDetail.title}: (${problemDetail.detail} - ${problemDetail.type} - ${problemDetail.status} - ${problemDetail.instance})`, variant: BannerType.ERROR });
    };
}
export default DownloadService;
