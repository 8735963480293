import { Button } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import JsonModal from '../JsonModal';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
const JsonButton = ({ subject, updateId }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [showModal, setShowModal] = React.useState(false);
    const [tranches, setTranches] = React.useState([]);
    const onClick = React.useCallback(() => {
        const abortController = new AbortController();
        if (updateId?.index !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/tranches');
            url.searchParams.append('id', updateId.transactionId);
            url.searchParams.append('index', updateId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setTranches(body);
                    setShowModal(true);
                }
                catch (error) {
                    sendFetchError('Unable to download tranche', error, request);
                }
            })();
        }
        return () => { abortController.abort(); };
    }, [apiFetchJson, updateId]);
    return React.createElement(React.Fragment, null,
        showModal ?
            React.createElement(JsonModal, { onClose: () => { setShowModal(false); }, title: subject ?? 'no subject', tranches: tranches }) : undefined,
        React.createElement(Button, { onClick: onClick, size: "small" }, "JSON"));
};
export default JsonButton;
