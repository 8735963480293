import * as React from 'react';
const ValueCell = ({ row: { original: regexData } }) => {
    if (regexData.getSubItems === undefined || regexData.getSubItems().length === 0) {
        return (React.createElement("p", null, regexData.getValue()));
    }
    return (React.createElement("ul", null,
        React.createElement("li", null,
            regexData.getValue(),
            React.createElement("ul", null, regexData.getSubItems().map((e) => (React.createElement("li", { key: e }, e)))))));
};
export default ValueCell;
