import * as React from 'react';
import './EditableCell.css';
import { Icon, Loader } from '@symphony-ui/uitoolkit-components';
const EditableCell = React.forwardRef(({ children, className, errorText, isEditing, isLoading, isLoadingRow, isOverriden = false, removeOverride, setEditing, title, }, ref) => {
    const onClick = React.useCallback(() => {
        if (!isLoadingRow) {
            setEditing(true);
        }
    }, [isLoadingRow, setEditing]);
    const onKeyDiv = React.useCallback((event) => {
        if (!isLoadingRow) {
            if (event.key === 'Enter') {
                setEditing(true);
            }
            if ((event.key === 'Delete' || event.key === 'Backspace') && removeOverride !== undefined && !isEditing) {
                removeOverride();
            }
        }
    }, [isEditing, isLoadingRow, removeOverride, setEditing]);
    const content = React.useMemo(() => {
        if (errorText !== undefined) {
            return (React.createElement(Icon, { className: "co-red", iconName: "alert-triangle", title: errorText }));
        }
        if (isLoading) {
            return (React.createElement(Loader, null));
        }
        return children;
    }, [children, errorText, isLoading]);
    return (React.createElement("td", { className: `co-table-cell co-editable${isOverriden ? ' co-overriden' : ''}${className ? ` ${className}` : ''}${title && isOverriden ? ' co-exists' : ''}`, onClick: onClick, onKeyUp: onKeyDiv, ref: ref, role: "gridcell", tabIndex: 0, title: isOverriden ? title : undefined }, content));
});
export default EditableCell;
