import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import useDebounce from './useDebounce';
const LeiDropdown = ({ initialValue, setLei }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const onChangeLei = React.useCallback((event) => {
        const value = event.target.value;
        if (value.data?.name !== undefined) {
            const nameLei = {
                countryId: value.data.countryId,
                name: value.data.name,
                alternativeNames: value.data.alternativeNames,
                lei: value.value
            };
            setLei(nameLei);
        }
    }, [setLei]);
    const downloadOptions = React.useCallback(async (inputValue) => {
        if (inputValue.length < 3) {
            return [];
        }
        const url = new ApiServerURL('/gleif/lei-records/all');
        url.searchParams.append('name', inputValue);
        const request = new ServerRequest(url);
        let newOptions = [];
        try {
            const body = await apiFetchJson(request);
            newOptions = body.map((nl) => ({ label: nl.name.name, value: nl.lei, data: { alternativeNames: nl.alternativeNames, countryId: nl.countryId, name: nl.name } }));
        }
        catch (error) {
            sendFetchError('Unable to download ticker information', error, request);
        }
        console.log("NEW OPTIONS: %o", newOptions);
        return newOptions;
    }, [apiFetchJson]);
    const [initialOptions, setInitialOptions] = React.useState([]);
    React.useEffect(() => {
        if (initialValue !== undefined && initialValue.length > 2) {
            (async () => {
                const options = await downloadOptions(initialValue);
                setInitialOptions(options);
            })();
        }
    }, [downloadOptions, initialValue]);
    const asyncOptions = useDebounce(downloadOptions, [], 500);
    const optionRenderer = React.useCallback((value) => {
        console.log("Render: %o", value);
        return (React.createElement("div", null,
            React.createElement("div", null, value.data.label),
            value.data.data !== undefined ? value.data.data.alternativeNames?.map((t) => (React.createElement("div", { style: {
                    color: 'darkgray', fontSize: 'x-small', marginLeft: '0.5rem', textWrap: 'nowrap',
                } }, `${t.name} - ${t.type}`))) : undefined));
    }, []);
    return (React.createElement(Dropdown, { asyncOptions: asyncOptions, defaultOptions: initialOptions, noOptionMessage: 'Nothing found yet', onChange: onChangeLei, optionRenderer: optionRenderer }));
};
export default LeiDropdown;
