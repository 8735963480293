import { fromSource, Source, turnFilterIntoClass, turnSecurityIntoClass } from '../../sources/sources';
import EuroclearSecurity from '../../models/security/EuroclearSecurity';
import EuroclearCompany from '../../models/company/EuroclearCompany';
import AnnaSecurity from '../../models/security/AnnaSecurity';
import AnnaCompany from '../../models/company/AnnaCompany';
import EsmaCompany from '../../models/company/EsmaCompany';
import EsmaSecurity from '../../models/security/EsmaSecurity';
import SecSecurity from '../../models/security/SecSecurity';
import SecCompany from '../../models/company/SecCompany';
const resultsDownload = (body) => {
    const newSource = fromSource(body.source);
    let newSecurities = body.securities.map(s => turnSecurityIntoClass(newSource, s));
    let newCompanies;
    const newFilter = turnFilterIntoClass(newSource, body.filter);
    switch (newSource) {
        case Source.ANNA:
            newCompanies = body.companies.map((c) => ({
                company: new AnnaCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body
                .securities.map((s) => new AnnaSecurity(s, newCompanies));
            break;
        case Source.ESMA:
            newCompanies = body.companies.map((c) => ({
                company: new EsmaCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body.securities.map((s) => new EsmaSecurity(s, newCompanies));
            break;
        case Source.EUROCLEAR:
            newCompanies = body.companies.map((c) => ({
                company: new EuroclearCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body.securities.map((s) => new EuroclearSecurity(s, newCompanies));
            break;
        case Source.SEC:
            newCompanies = body.companies.map((c) => ({
                company: new SecCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body.securities.map((s) => new SecSecurity(s, newCompanies));
            break;
        default:
            throw new Error(`Unable to find source: ${body.source}`);
    }
    return {
        filter: newFilter,
        securities: newSecurities,
        source: newSource,
    };
};
export default resultsDownload;
