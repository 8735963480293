import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import ApiServerURL from 'models/ServerUrl';
const SectorSelector = ({ filter, updateFilter }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [optionsSector, setOptionsSector] = React.useState([]);
    const [optionsRegion, setOptionsRegion] = React.useState([]);
    const [optionsType, setOptionsType] = React.useState([]);
    const onChangeSectors = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        const newFilter = filter ? [...filter.filter(i => i.id !== 'sector')] : [];
        if (newValue.length > 0) {
            newFilter.push({ id: 'sector', value: newValue });
        }
        (async () => updateFilter(newFilter))();
    }, [filter, updateFilter]);
    const onChangeRegions = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        const newFilter = filter ? [...filter.filter(i => i.id !== 'regions')] : [];
        if (newValue.length > 0) {
            newFilter.push({ id: 'regions', value: newValue });
        }
        (async () => updateFilter(newFilter))();
    }, [filter, updateFilter]);
    const onChangeTypes = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        const newFilter = filter ? [...filter.filter(i => i.id !== 'types')] : [];
        if (newValue.length > 0) {
            newFilter.push({ id: 'types', value: newValue });
        }
        (async () => updateFilter(newFilter))();
    }, [filter, updateFilter]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL('/syndicate/overview/map');
        url.searchParams.append('field', 'sectors');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsSector(body);
            }
            catch (error) {
                sendFetchError('Unable to download asset class options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL('/syndicate/overview/map');
        url.searchParams.append('field', 'regions');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsRegion(body);
            }
            catch (error) {
                sendFetchError('Unable to download region options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL('/syndicate/overview/map');
        url.searchParams.append('field', 'types');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsType(body);
            }
            catch (error) {
                sendFetchError('Unable to download region options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const regions = React.useMemo(() => {
        const regionFilter = filter?.find(f => f.id === 'regions');
        return regionFilter ? regionFilter.value : [];
    }, [filter]);
    const sectors = React.useMemo(() => {
        const sectorFilter = filter?.find(f => f.id === 'sector');
        return sectorFilter ? sectorFilter.value : [];
    }, [filter]);
    const types = React.useMemo(() => {
        const typeFilter = filter?.find(f => f.id === 'types');
        return typeFilter ? typeFilter.value : [];
    }, [filter]);
    return (React.createElement("div", null,
        React.createElement("div", { style: { height: '4.5rem' } },
            React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Region(s)", onChange: onChangeRegions, options: optionsRegion, value: optionsRegion.filter(o => 'value' in o && regions.includes(o.value)) }))),
        React.createElement("div", { style: { height: '4.5rem' } },
            React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Sector", onChange: onChangeSectors, options: optionsSector, value: optionsSector.filter(o => 'value' in o && sectors.includes(o.value)) }))),
        React.createElement("div", { style: { height: '4.5rem' } },
            React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                React.createElement(Dropdown, { isMultiSelect: true, label: "Type(s)", onChange: onChangeTypes, options: optionsType, style: { width: '5rem' }, value: optionsType.filter(o => 'value' in o && types.includes(o.value)) })))));
};
export default SectorSelector;
