import { unescapeRegex } from './regexUtils';
import Regex from './Regex';
import IndexedRegex from './IndexedRegex';
class SubjectRegex {
    id;
    index;
    regex;
    constructor({ id, index, regex }) {
        this.id = id;
        this.index = index;
        this.regex = new Regex(regex);
    }
    static createFromIndexedRegex(indexedRegex) {
        return new SubjectRegex({ id: indexedRegex.id, index: indexedRegex.index, regex: { markers: indexedRegex.markers, template: indexedRegex.template } });
    }
    safeTemplate() {
        return unescapeRegex(this.regex.template.replace(/{}/g, '\u{25CF}'));
    }
    getMarkers() {
        return this.regex.markers;
    }
    getTemplate() {
        return this.regex.template;
    }
    toIndexedRegex() {
        if (this.id === undefined || this.index === undefined) {
            throw new Error('Unable to create indexed Regex without an id or index in the SubjectRegex');
        }
        return new IndexedRegex({
            id: this.id, index: this.index, markers: this.regex.markers, template: this.regex.template,
        });
    }
}
export default SubjectRegex;
