import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { Button, DatePicker } from '@symphony-ui/uitoolkit-components';
import RenderingContext from 'contexts/RenderingContext';
import { datePattern } from 'components/custom-table/dateFormat';
import Popup from 'components/custom-table/action/Popup';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
import './EditableDateCell.css';
const EditableDateCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { dateFormat, printDate } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const value = React.useMemo(() => {
        if (column.accessorFn === undefined) {
            return undefined;
        }
        return column.accessorFn(row.original, row.index);
    }, [column, row.index, row.original]);
    const text = React.useMemo(() => {
        if (value === undefined || value === 'TBC') {
            return '';
        }
        try {
            return printDate(value, 'YMD');
        }
        catch (error) {
            return `ERROR: ${error}`;
        }
    }, [printDate, value]);
    const remove = React.useCallback(async () => {
        if (table.options?.meta?.updateTable !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'DELETE' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            setLoading(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                setErrorText(`Unable to save: ${JSON.stringify(error)}`);
            }
            finally {
                setDisabled(false);
                setEditing(false);
                setLoading(false);
            }
        }
    }, [column, jsonPlus, row.original, table]);
    const save = React.useCallback(async (date) => {
        if (table.options?.meta?.updateTable !== undefined) {
            const dateText = date instanceof Date
                ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().substring(0, 10)
                : (date ?? '');
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', dateText);
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            setDisabled(true);
            table.options.meta.updateTable(editableTranches);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                //
            }
            setDisabled(false);
        }
    }, [column, jsonPlus, row.original, table]);
    const onChange = React.useCallback((event) => {
        const localDate = event.target.value ?? undefined;
        (async () => save(localDate))();
    }, [save]);
    const onClickRemove = React.useCallback(() => {
        (async () => remove())();
    }, [remove]);
    const onClickTbc = React.useCallback(() => {
        (async () => save('TBC'))();
    }, [save]);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save(value))();
        }
    }, [save, value]);
    const closePopup = React.useCallback(() => setEditing(false), []);
    const stopMouseDown = React.useCallback((e) => e.stopPropagation(), []);
    React.useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', stopMouseDown, true);
        }
        else {
            document.removeEventListener('mousedown', stopMouseDown);
        }
    }, [isEditing, stopMouseDown]);
    const title = React.useMemo(() => {
        if (column.accessorFn !== undefined) {
            const copy = row.original.removeOverrideMap();
            try {
                const copyValue = column.accessorFn(copy, row.index);
                if (typeof copyValue === 'object' && copyValue instanceof Date) {
                    const titleText = printDate(copyValue, 'YMD');
                    return titleText;
                }
                return undefined;
            }
            catch (error) {
                setErrorText('Unable to set the title');
                return undefined;
            }
        }
        else {
            setErrorText(`No Accessor Function defined for ${column.id}`);
            return undefined;
        }
    }, [column, printDate, row.index, row.original]);
    const content = React.useMemo(() => {
        if (isEditing) {
            return (React.createElement("div", { className: "co-editable-date" },
                React.createElement(Popup, { close: closePopup, style: {
                        padding: '0.5rem', transform: 'translate(0.25rem, -0.75rem)',
                    } },
                    React.createElement(DatePicker, { autoFocus: true, date: value !== 'TBC' ? value : undefined, disabled: isDisabled, format: datePattern(dateFormat, 'YMD'), onChange: onChange, onKeyUp: onKeyUp, showRequired: true, showOverlay: true }),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                        title !== undefined
                            ? React.createElement("div", null,
                                React.createElement(Button, { onClick: onClickTbc, variant: "tertiary-accent" }, "TBC")) : undefined,
                        row.original.overrideMap?.[column.id] != null
                            ? React.createElement("div", null,
                                React.createElement(Button, { onClick: onClickRemove, variant: "tertiary-destructive" }, "REMOVE")) : undefined))));
        }
        return text;
    }, [closePopup, column.id, dateFormat, isDisabled, isEditing, onChange, onClickRemove, onClickTbc, onKeyUp, row.original.overrideMap, text, title, value]);
    return (React.createElement(EditableCell, { errorText: errorText, ref: editableCellRef, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] != null, key: cell.id, setEditing: setEditing, title: title }, content));
};
export default EditableDateCell;
