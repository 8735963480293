import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import { getOptionsMulti } from 'utils/change';
import UserContext from 'contexts/UserContext';
import QualifiedSearchInput from '../../qualified-search/QualifiedSearchInput';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import Option from '../../../models/Option';
import currencyOptionsJson from '../../../public/assets/banksecurities.currency.json';
import marketOptionsJson from '../../../public/assets/banksecurities.market.json';
import ratetypeOptionsJson from '../../../public/assets/banksecurities.ratetype.json';
import securitytypeOptionsJson from '../../../public/assets/banksecurities.securitytype.json';
const EuroclearForm = ({ setSearch, search }) => {
    const { checkEditable } = React.useContext(UserContext);
    const currencyOptions = React.useMemo(() => Option.fixDoubles(currencyOptionsJson), []);
    const marketOptions = React.useMemo(() => Option.fixDoubles(marketOptionsJson), []);
    const ratetypeOptions = React.useMemo(() => Option.fixDoubles(ratetypeOptionsJson), []);
    const securitytypeOptions = React.useMemo(() => Option.fixDoubles(securitytypeOptionsJson), []);
    const disabled = React.useMemo(() => (search.id !== undefined ? !checkEditable(search) : false), [checkEditable, search]);
    const changeFilter = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedFilter = search.filter.updateFilter(name, value);
        const updatedSearch = search.updateFilter(updatedFilter);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedValue = Array.isArray(value) ? value.map((v) => v.value) : [value.value];
        const updatedQuery = search.query.updateStringArray(name, updatedValue);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryQualifiedSearch = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = search.query.updateQualifiedSearch(name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryTimeRange = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = search.query.updateTimeRange(name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    return (React.createElement("div", { className: "base-form" },
        React.createElement(QualifiedSearchInput, { disabled: disabled, label: "Shortname", value: search.query.q, onChange: changeQueryQualifiedSearch('q'), tooltip: "Search the shortname of the security. This typically starts with the legal name of the issuer. An example is 'NRW.BANK            0.20000 01/03/34'" }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Currency", name: "currency", onChange: changeQueryDropdown('currency'), options: currencyOptions, value: getOptionsMulti(search.query.currency, currencyOptions) }),
        React.createElement(QualifiedSearchInput, { disabled: disabled, label: "Rate", value: search.query.rate, onChange: changeQueryQualifiedSearch('rate'), tooltip: "Search by rate. The search is by text" }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Market", name: "market", onChange: changeQueryDropdown('market'), options: marketOptions, value: getOptionsMulti(search.query.market, marketOptions) }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Rate Type", name: "ratetype", onChange: changeQueryDropdown('ratetype'), options: ratetypeOptions, value: getOptionsMulti(search.query.ratetype, ratetypeOptions) }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Security Type", name: "securitytype", onChange: changeQueryDropdown('securitytype'), options: securitytypeOptions, value: getOptionsMulti(search.query.securitytype, securitytypeOptions) }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Tags", onChange: changeFilter('tags'), path: "/nit/companies/tags/options/euroclear", value: search.filter.tags }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Countries", onChange: changeFilter('countries'), path: "/nit/companies/euroclear/countries/options", value: search.filter.countries }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Categories", onChange: changeFilter('categories'), path: "/nit/companies/euroclear/categories/options", value: search.filter.categories }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "CFI Groups", onChange: changeFilter('cfiCategories'), path: "/nit/security/cfi-groups/options", value: search.filter.cfiGroups }),
        React.createElement(TimeRangeInput, { defaultPeriod: "Y", disabled: disabled, label: "Maturity Date", onChange: changeQueryTimeRange('maturitydate'), tooltip: "", value: search.query.maturitydate }),
        React.createElement(TimeRangeInput, { defaultPeriod: "D", disabled: disabled, label: "Code Creation Date", onChange: changeQueryTimeRange('codecreationdate'), tooltip: "", value: search.query.codecreationdate })));
};
export default EuroclearForm;
