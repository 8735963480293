import { Button } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import KeyValueRegex from '../../../../models/regexes/KeyValueRegex';
const AddKeyButton = ({ callback, keyValueRegexId, name, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const addKey = React.useCallback(() => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('id', keyValueRegexId);
        const request = new ServerRequest('/syndicate/regex/keyvalues/add-name', { body: formData, method: 'POST' });
        (async () => {
            try {
                const updated = await jsonPlus(request, 'Unable to add name to regex');
                const updateKVR = KeyValueRegex.create(updated);
                callback(updateKVR);
            }
            catch (error) {
                //
            }
        })();
    }, [callback, jsonPlus, keyValueRegexId, name]);
    return (React.createElement(Button, { onClick: addKey, style: { marginLeft: '0.5rem' } }, "Add Key to Existing"));
};
export default AddKeyButton;
