import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import TableContext from 'components/custom-table/TableContext';
import NavigationContext from 'contexts/NavigationContext';
import WebSocketContext from 'contexts/WebSocketContext';
import { sendFetchError } from 'utils/messageUtils';
import SyndicateContext from '../../../context/SyndicateContext';
import TrancheWrapper from '../../../models/TrancheWrapper';
import MesssagesModal from './MessagesModal';
import OverviewTable from './table/OverviewTable';
import OtherSourcesModal from './OtherSourcesModal';
import MetaModal from './MetaModal';
const OverviewBody = () => {
    const { setUpdateId } = React.useContext(SyndicateContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { isAdmin, updateView } = React.useContext(NavigationContext);
    const { addSubscription, removeSubscription } = React.useContext(WebSocketContext);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showMetaModal, setShowMetaModal] = React.useState(false);
    const [showOtherModal, setShowOtherModal] = React.useState(false);
    const [trancheWrapper, setTrancheWrapper] = React.useState();
    const [isin, setIsin] = React.useState();
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const tranches = response;
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]),
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber]?.findIndex((d) => d.transaction.id === tranches[0].transaction.id);
                }
                if (index > -1) {
                    const currentLength = data.pages[pageNumber].filter((d) => d.transaction.id === tranches[0].transaction.id).length;
                    updated.pages[pageNumber].splice(index, currentLength, ...tranches);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
            // } else {
            //   setMessage({ content: 'No table update function.', variant: BannerType.ERROR });
        }
    }, [tableUpdateFn]);
    const rowClassFn = React.useCallback((row) => {
        const classes = [];
        if (row.original.isEditing) {
            classes.push('co-row-loading');
        }
        const { confirm, lastUpdate } = row.original.transaction;
        if (confirm !== undefined && new Date(confirm.timestamp).getTime() > new Date(lastUpdate).getTime()) {
            classes.push('co-confirmed');
        }
        return classes.length > 0 ? ` ${classes.join(' ')}` : '';
    }, []);
    const meta = React.useMemo(() => ({
        className: 'co-centered',
        idFunction: (d) => d.transaction.id,
        rowClassFn,
        updateTable,
    }), [rowClassFn, updateTable]);
    const addTranche = React.useCallback(({ row: { original: { transaction: { id } } } }) => {
        const formData = new FormData();
        formData.append('transactionId', id);
        const request = new ServerRequest('/syndicate/transactions/add-tranche', { body: formData, method: 'PATCH' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                sendFetchError('Unable to add a new tranche', error, request);
            }
        })();
    }, [apiFetchJson, updateTable]);
    const confirm = React.useCallback(({ row: { original } }) => {
        const { id } = original.transaction;
        const formData = new FormData();
        formData.append('transactionId', id);
        const request = new ServerRequest('/syndicate/transactions/confirm', { body: formData, method: 'POST' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                sendFetchError('Unable to save override', error, request);
            }
        })();
    }, [apiFetchJson, updateTable]);
    const details = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowDetailsModal(true);
    }, []);
    const manageMeta = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowMetaModal(true);
    }, []);
    const removeOverrides = React.useCallback(({ row: { original: { transaction: { id }, trancheIndex } } }) => {
        const formData = new FormData();
        formData.append('transactionId', id);
        formData.append('trancheIndex', trancheIndex.toString());
        const request = new ServerRequest('/syndicate/transactions/override', { body: formData, method: 'DELETE' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                updateTable(tranches);
            }
            catch (error) {
                sendFetchError('Unable to delete override', error, request);
            }
        })();
    }, [apiFetchJson, updateTable]);
    const seeUpdate = React.useCallback(({ row: { original: { transaction: { id } } } }) => {
        setUpdateId({ transactionId: id });
        updateView('UPDATE');
    }, [setUpdateId, updateView]);
    const updateWebSocket = React.useCallback((e) => {
        const body = JSON.parse(e.body);
        const tranches = body.map((t) => new TrancheWrapper(t));
        updateTable(tranches);
    }, [updateTable]);
    const viewOthers = React.useCallback(({ row: { original: { overrideMap, data } } }) => {
        if (data?.identifiers !== undefined) {
            const i = data.identifiers.find((id) => id.type === 'ISIN');
            setIsin(i?.value);
        }
        else if (overrideMap?.isin !== undefined) {
            const isinRegex = /(?<isin>[A-Z]{2}[A-Z0-9]{12})/;
            const match = isinRegex.exec(overrideMap.isin);
            setIsin(match?.groups?.isin);
        }
        else {
            setIsin(undefined);
        }
        setShowOtherModal(true);
    }, []);
    const overviewClass = React.useMemo(() => isAdmin() ? 'admin' : undefined, [isAdmin]);
    React.useEffect(() => {
        setUpdateId(undefined);
    }, [setUpdateId]);
    /*
    Not sure if we really need to remove the subscription. We have less errors adding a subscription that already exists, than unsubscribing one that does not.
    */
    React.useEffect(() => {
        const destination = '/topic/newissue.data';
        addSubscription(destination, updateWebSocket);
        // return () => removeSubscription(destination);
    }, [addSubscription, removeSubscription, updateWebSocket]);
    return (React.createElement(Card, { className: overviewClass, style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "New Issues"),
            isAdmin() ?
                React.createElement("div", null,
                    React.createElement(Button, { name: "overview", onClick: () => { updateView('TABLE'); }, size: "small" }, "TRANSACTIONS"))
                : undefined),
        trancheWrapper !== undefined && showMetaModal
            ? (React.createElement(MetaModal, { callback: updateTable, hideModal: () => { setShowMetaModal(false); }, setTranche: setTrancheWrapper, tranche: trancheWrapper })) : undefined,
        isin !== undefined && showOtherModal
            ? (React.createElement(OtherSourcesModal, { hideModal: () => { setShowOtherModal(false); }, title: "Other sources", isin: isin })) : undefined,
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', overflow: 'auto', height: '100%', justifyContent: 'space-between',
            } },
            trancheWrapper !== undefined && showDetailsModal ? (React.createElement(MesssagesModal, { hideModal: () => { setShowDetailsModal(false); }, splitScreen: true, transactionId: trancheWrapper.transaction.id })) : undefined,
            React.createElement(OverviewTable, { addTranche: addTranche, confirm: confirm, details: details, manageMeta: manageMeta, meta: meta, removeOverrides: removeOverrides, seeUpdate: seeUpdate, viewOthers: viewOthers }))));
};
export default OverviewBody;
