import * as React from 'react';
import { Button, Card, Icon, Loader, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import DebouncedInput from 'components/custom-table/filters/DebouncedInput';
import { sendFetchError } from 'utils/messageUtils';
import TransactionWrapper from '../../models/TransactionWrapper';
import IFRStreamItem from './IFRStreamItem';
import IFRStoryModal from './IFRStoryModal';
import IFRFilterModal from './IFRFilterModal';
import StreamItem from '../live/StreamItem';
import MesssagesModal from '../transactions/overview/MessagesModal';
const IFR = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [cmoItems, setCmoItems] = React.useState([]);
    const [cmoLoading, setCmoLoading] = React.useState(false);
    const [detailsId, setDetailsId] = React.useState();
    const [filter, setFilter] = React.useState(() => {
        const stored = localStorage.getItem('ifr-filter');
        return stored !== null ? JSON.parse(stored) : undefined;
    });
    const [ifrLoading, setIfrLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [story, setStory] = React.useState();
    const [search, setSearch] = React.useState('');
    const hideStory = React.useCallback((hide) => {
        const newItems = items.map((i) => {
            if (i.data.storyid === hide.data.storyid) {
                const copyStory = { ...hide };
                copyStory.hide = true;
                return copyStory;
            }
            return i;
        });
        setItems(newItems);
    }, [items]);
    const onClickFilter = React.useCallback(() => {
        setShowModal(true);
    }, []);
    const onChangeSearch = React.useCallback((value) => {
        setSearch(value);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/ifr/all');
            if (filter?.region !== undefined && filter.region.length > 0) {
                url.searchParams.append('region', filter.region.join(','));
            }
            if (filter?.assetClass !== undefined && filter.assetClass.length > 0) {
                url.searchParams.append('assetClass', filter.assetClass.join(','));
            }
            if (search.length > 2) {
                url.searchParams.append('search', search);
            }
            const request = new ServerRequest(url);
            try {
                setIfrLoading(true);
                const body = await apiFetchJson(request, abortController.signal);
                setItems(body);
                setIfrLoading(false);
            }
            catch (error) {
                sendFetchError('Unable to download IFR stories', error, request);
                //
            }
        })();
    }, [apiFetchJson, filter, search]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/page');
            url.searchParams.append('start', '0');
            url.searchParams.append('end', '100');
            const request = new ServerRequest(url);
            try {
                setCmoLoading(true);
                const body = await apiFetchJson(request, abortController.signal);
                const transactions2 = body.map((t) => new TransactionWrapper(t));
                setCmoItems(transactions2);
                setCmoLoading(false);
            }
            catch (error) {
                sendFetchError('Unable to download transactions', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const showMessagesModal = React.useMemo(() => detailsId !== undefined, [detailsId]);
    const showIFRStoryModal = React.useMemo(() => story !== undefined, [story]);
    return (React.createElement(Card, { className: "co-card-main" },
        showModal ?
            React.createElement(IFRFilterModal, { filter: filter, onClose: () => setShowModal(false), setFilter: setFilter }) : undefined,
        showMessagesModal ?
            React.createElement(MesssagesModal, { hideModal: () => setDetailsId(undefined), splitScreen: false, transactionId: detailsId }) : undefined,
        showIFRStoryModal && story !== undefined
            ? (React.createElement(IFRStoryModal, { onClose: () => setStory(undefined), story: story })) : undefined,
        React.createElement("div", { style: {
                display: 'flex', justifyContent: 'space-evenly', height: '100%',
            } },
            React.createElement("div", { style: { height: '100%', width: '50%' } },
                React.createElement("div", { style: { display: 'flex', paddingBottom: '0.5rem' } },
                    React.createElement(Button, { iconButton: true, onClick: onClickFilter },
                        React.createElement(Icon, { iconName: "filter" })),
                    React.createElement("div", { style: { paddingLeft: '1rem', paddingRight: '1rem', width: '100%' } },
                        React.createElement(DebouncedInput, { debounce: 200, onChange: onChangeSearch, placeholder: "Search", value: search }))),
                React.createElement("div", { style: { height: '100%' } }, !ifrLoading
                    ? (React.createElement("div", { style: { height: '100%', overflowY: 'scroll' } }, items.filter((m) => !m.hide).map((m) => (React.createElement(IFRStreamItem, { hideStory: () => hideStory(m), story: m, setStory: () => setStory(m) })))))
                    : (React.createElement("div", { className: "loader-wrapper3" },
                        React.createElement(Loader, { className: "co-center-page", type: "spinner", variant: "primary" }))))),
            React.createElement("div", { style: { height: '100%', width: '50%' } }, !cmoLoading
                ? (React.createElement("div", { style: { height: '100%', overflowY: 'scroll' } }, cmoItems.map((m) => (React.createElement(StreamItem, { messages: m, setDetailsId: setDetailsId })))))
                : (React.createElement("div", { className: "loader-wrapper3" },
                    React.createElement(Loader, { className: "co-center-page", type: "spinner", variant: "primary" })))))));
};
export default IFR;
