import LocationRouter from 'pages/LocationRouter';
import * as React from 'react';
import UserContext from 'contexts/UserContext';
import FullPageLoader from 'components/loading-page/FullPageLoader';
import UserAccessService from 'services/UserAccessService';
import { useAuth } from 'react-oidc-context';
import ThemeContext from 'contexts/ThemeContext';
import ServerRequest from 'models/ServerRequest';
import WebSocketService from 'services/WebsocketService';
import WebSocketContext from 'contexts/WebSocketContext';
import FrontPage from './pages/main-page/FrontPage';
import initialisePushNotifications from './init-push-notifications';
import '../modules/core/public/sass/main.scss';
/**
 * This is the main entry point for
 * @returns
 */
const MainRoute = () => {
    const { colorScheme } = React.useContext(ThemeContext);
    const auth = useAuth();
    const [csrf, setCsrf] = React.useState();
    const [isDark, setDark] = React.useState();
    const userAccessService = React.useMemo(() => new UserAccessService({ user: auth.user, csrf }), [auth.user, csrf]);
    const webSocketService = React.useMemo(() => {
        const token = auth.user != null ? {
            accessToken: auth.user.access_token,
            scope: auth.user.scope,
            tokenType: auth.user.token_type,
            expiresAt: auth.user.expires_at,
            issuedAt: auth.user.expires_at && auth.user.expires_in ? auth.user.expires_at - auth.user.expires_in : undefined,
        } : undefined;
        return new WebSocketService(token, csrf);
    }, [auth.user, csrf]);
    React.useEffect(() => {
        webSocketService.activate();
        return () => {
            (async () => webSocketService.deactivate())();
        };
    }, [webSocketService]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const { signal } = abortController;
        if (auth.user !== undefined && auth.user !== null && !auth.user?.expired) {
            const request = new ServerRequest('/csrf', { credentials: 'include', headers: { Authorization: `Bearer ${auth.user.access_token}` } });
            (async () => {
                try {
                    const response = await fetch(request, { signal });
                    if (response.status === 200) {
                        const body = await response.json();
                        setCsrf(body);
                    }
                }
                catch (error) {
                    console.error("hello", error);
                    //
                }
            })();
        }
        return () => abortController.abort();
    }, [auth.user]);
    React.useEffect(() => {
        const abortController = new AbortController();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
            if (colorScheme === 'match') {
                setDark(event.matches);
            }
        }, { signal: abortController.signal });
        return () => abortController.abort();
    }, [colorScheme]);
    React.useEffect(() => {
        const token = auth.user?.access_token;
        if (token !== undefined && !auth.user?.expired) {
            (async () => {
                await initialisePushNotifications(token);
            })();
        }
    }, [auth.user?.access_token, auth.user?.expired]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = () => {
            (async () => {
                await auth.signoutSilent();
                await auth.removeUser();
                await auth.revokeTokens();
            })();
        };
        window.addEventListener('logout', callback, { signal: abortController.signal });
        return () => abortController.abort();
    }, [auth]);
    const content = React.useMemo(() => {
        switch (auth.activeNavigator) {
            case 'signinRedirect':
                console.log("Sign in Redirect");
                return undefined;
            case 'signinSilent':
                return React.createElement("div", null, "Signing you in...");
            case 'signoutRedirect':
                return React.createElement("div", null, "Signing you out...");
            default:
        }
        if (auth.isLoading) {
            return (React.createElement(FullPageLoader, null));
        }
        if (auth.isAuthenticated) {
            return (React.createElement(UserContext.Provider, { value: userAccessService },
                React.createElement(WebSocketContext.Provider, { value: webSocketService },
                    React.createElement(LocationRouter, null))));
        }
        return React.createElement(FrontPage, null);
    }, [auth.activeNavigator, auth.isAuthenticated, auth.isLoading, userAccessService, webSocketService]);
    React.useEffect(() => {
        switch (colorScheme) {
            case 'dark':
                setDark(true);
                break;
            case 'light':
                setDark(false);
                break;
            case 'match':
                setDark((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ?? false);
        }
    }, [colorScheme]);
    return (React.createElement("div", { className: isDark ? 'dark' : '' }, content));
};
export default MainRoute;
