class TableStateClass {
    columnFilters;
    columnOrder;
    columnPinning;
    columnSizing;
    columnSizingInfo;
    columnVisibility;
    expanded;
    globalFilter;
    grouping;
    pagination;
    rowPinning;
    rowSelection;
    sorting;
    constructor({ columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo, columnVisibility, expanded, globalFilter, grouping, pagination, rowPinning, rowSelection, sorting, }) {
        this.columnFilters = columnFilters;
        this.columnOrder = columnOrder;
        this.columnPinning = columnPinning;
        this.columnSizing = columnSizing;
        this.columnSizingInfo = columnSizingInfo;
        this.columnVisibility = columnVisibility;
        this.expanded = expanded;
        this.globalFilter = globalFilter;
        this.grouping = grouping;
        this.pagination = pagination;
        this.rowPinning = rowPinning;
        this.rowSelection = rowSelection;
        this.sorting = sorting;
    }
    static DEFAULT = new TableStateClass({
        columnFilters: [],
        columnOrder: [],
        columnPinning: {},
        columnSizing: {},
        columnSizingInfo: {
            columnSizingStart: [],
            deltaOffset: null,
            deltaPercentage: null,
            isResizingColumn: false,
            startOffset: 0,
            startSize: 150,
        },
        columnVisibility: {},
        expanded: {},
        globalFilter: null,
        grouping: [],
        pagination: {
            pageIndex: 0,
            pageSize: 1000,
        },
        rowPinning: {},
        rowSelection: {},
        sorting: [],
    });
    clone() {
        return new TableStateClass({
            columnFilters: this.columnFilters,
            columnOrder: this.columnOrder,
            columnPinning: this.columnPinning,
            columnSizing: this.columnSizing,
            columnSizingInfo: this.columnSizingInfo,
            columnVisibility: this.columnVisibility,
            expanded: this.expanded,
            globalFilter: this.globalFilter,
            grouping: this.grouping,
            pagination: this.pagination,
            rowPinning: this.rowPinning,
            rowSelection: this.rowSelection,
            sorting: this.sorting,
        });
    }
    updateColumnOrder(columnOrderUpdater) {
        const newTableState = this.clone();
        if (typeof columnOrderUpdater === 'function') {
            columnOrderUpdater(newTableState.columnOrder);
        }
        else {
            newTableState.columnOrder = columnOrderUpdater;
        }
        return newTableState;
    }
    updateVisibility(columnVisibility) {
        const newTableState = this.clone();
        const newVisibilityState = {};
        const keys = [...columnVisibility.keys()];
        keys.forEach((k) => {
            const value = columnVisibility.get(k);
            if (value !== undefined) {
                newVisibilityState[k] = value;
            }
        });
        newTableState.columnVisibility = newVisibilityState;
        return newTableState;
    }
    updateColumnSizing(columnSizing) {
        const newTableState = this.clone();
        newTableState.columnSizing = columnSizing;
        return newTableState;
    }
    updateState(tableStateUpdater) {
        const newTableState = this.clone();
        if (typeof tableStateUpdater === 'function') {
            tableStateUpdater(newTableState);
        }
        else {
            newTableState.columnFilters = tableStateUpdater.columnFilters;
            newTableState.columnOrder = tableStateUpdater.columnOrder;
            newTableState.columnPinning = tableStateUpdater.columnPinning;
            newTableState.columnSizing = tableStateUpdater.columnSizing;
            newTableState.columnSizingInfo = tableStateUpdater.columnSizingInfo;
            newTableState.columnVisibility = tableStateUpdater.columnVisibility;
            newTableState.expanded = tableStateUpdater.expanded;
            newTableState.globalFilter = tableStateUpdater.globalFilter;
            newTableState.grouping = tableStateUpdater.grouping;
            newTableState.pagination = tableStateUpdater.pagination;
            newTableState.rowPinning = tableStateUpdater.rowPinning;
            newTableState.rowSelection = tableStateUpdater.rowSelection;
        }
        return newTableState;
    }
    equals(that) {
        const arrayFilterDoesNotEqual = (array1, array2) => array1.length !== array2.length || array1.some((value, index) => value.id !== array2[index].id || value.value !== array2[index].value);
        if (arrayFilterDoesNotEqual(this.columnFilters, that.columnFilters))
            return false;
        const arrayDoesNotEqual = (array1, array2) => array1.length !== array2.length || array1.some((value, index) => value !== array2[index]);
        if (arrayDoesNotEqual(this.columnOrder, that.columnOrder))
            return false;
        const arrayFilterDoesNotEqualOrUndefined = (array1, array2) => {
            if (array1 === undefined)
                return array2 !== undefined;
            if (array2 === undefined)
                return true;
            return arrayDoesNotEqual(array1, array2);
        };
        if (arrayFilterDoesNotEqualOrUndefined(this.columnPinning.left, that.columnPinning.left))
            return false;
        if (arrayFilterDoesNotEqualOrUndefined(this.columnPinning.right, that.columnPinning.right))
            return false;
        if (Object.keys(this.columnVisibility).length !== Object.keys(that.columnVisibility).length || Object.keys(this.columnVisibility).some((key) => this.columnVisibility[key] !== that.columnVisibility[key]))
            return false;
        return true;
    }
}
export default TableStateClass;
