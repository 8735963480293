import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EditableDateCell.css';
import RenderingContext from 'contexts/RenderingContext';
import Popup from 'components/custom-table/action/Popup';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
import MaturityCellDatePicker from './MaturityCellDatePicker';
import MaturityCellDateSelector from './MaturityCellDateSelector';
const MaturityCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { printDate } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isValueConfirm, setValueConfirm] = React.useState(false);
    // Value is the value return by the accessorFn, and it is either a Date, or a string equal to 'Perpetual', or of the form 'MM/YYYY' or 'YYYY';
    const [value, setValue] = React.useState();
    const remove = React.useCallback(async () => {
        if (table.options.meta?.updateTable !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'DELETE' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                //
            }
            setDisabled(false);
            setEditing(false);
        }
    }, [column, jsonPlus, row.original, table]);
    const save = React.useCallback(async (saveDate) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', saveDate ?? '');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            setLoading(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const response = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setDisabled(false);
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column, jsonPlus, row.original, table]);
    const onChange = React.useCallback((event) => {
        // const localDate = event.target.value !== null
        //   ? new Date(event.target.value.getFullYear(), event.target.value.getMonth(), event.target.value.getDate())
        //   : undefined;
        setValue(event.target.value);
        setEditing(false);
        setValueConfirm(true);
    }, []);
    const closePopup = React.useCallback(() => {
        setEditing(false);
    }, []);
    const printDateElements = React.useCallback((date) => {
        const dateRegex = /(?<year>[0-9]{4})(-(?<month>[0-9]{2})(-(?<day>[0-9]{2}))?)?/;
        const dateMatchArray = date.match(dateRegex);
        const groups = dateMatchArray?.groups;
        if (groups !== undefined) {
            const { year, month, day } = groups;
            const yearValue = parseInt(year, 10);
            const monthValue = month !== undefined ? parseInt(month, 10) : 1;
            const dayValue = day !== undefined ? parseInt(day, 10) : 1;
            const realDate = new Date(Date.UTC(yearValue, monthValue - 1, dayValue));
            let precision = 'Y';
            if (month !== undefined)
                precision = 'YM';
            if (day !== undefined)
                precision = 'YMD';
            return printDate(realDate, precision);
        }
        return undefined;
    }, [printDate]);
    const title = React.useMemo(() => {
        if (column.accessorFn !== undefined) {
            const copy = row.original.removeOverrideMap();
            const newValue = column.accessorFn(copy, row.index);
            if (newValue === undefined) {
                return undefined;
            }
            if (newValue === 'Perpetual') {
                return 'Perpetual';
            }
            const date = printDateElements(newValue);
            return date;
        }
        return undefined;
    }, [column, printDateElements, row.index, row.original]);
    const stopMouseDown = React.useCallback((e) => {
        e.stopPropagation();
    }, []);
    React.useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', stopMouseDown, { capture: true });
        }
        else {
            document.removeEventListener('mousedown', stopMouseDown);
        }
    }, [isEditing, stopMouseDown]);
    const content = React.useMemo(() => {
        if (isEditing || isValueConfirm) {
            return (React.createElement("div", { className: "co-editable-date" },
                React.createElement(Popup, { close: closePopup, style: { padding: '0.5rem', transform: 'translate(0.25rem, -0.75rem)' } }, isEditing
                    ? (React.createElement(MaturityCellDatePicker, { hasOverride: row.original.overrideMap?.[column.id] != null, hasValue: title !== undefined, isDisabled: isDisabled, onChange: onChange, remove: remove, save: save, tableCellRef: editableCellRef.current, value: value }))
                    : (React.createElement(MaturityCellDateSelector, { isDisabled: isDisabled, setValueConfirm: setValueConfirm, tableCellRef: editableCellRef.current, save: save, value: value })))));
        }
        const newValue = column.accessorFn !== undefined ? column.accessorFn(row.original, row.index) : undefined;
        if (newValue === undefined || newValue === 'TBC') {
            return '';
        }
        if (newValue === 'Perpetual') {
            return 'Perpetual';
        }
        return printDateElements(newValue);
    }, [closePopup, column, isDisabled, isEditing, isValueConfirm, onChange, printDateElements, remove, row.index, row.original, save, title, value]);
    return (React.createElement(EditableCell, { errorText: errorText, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, setEditing: setEditing, title: title }, content));
};
export default MaturityCell;
