import * as React from 'react';
import { Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import './IpoTable.css';
import IpoExchangeTable from './IpoExchangeTable';
import Ipo from '../models/Ipo';
const IpoTable = ({ exchange, hasError, isLoading, ipos, exchangeOptions, errorExchangeOptions, loadingExchangeOptions, setExchange, }) => {
    const [data, setData] = React.useState(new Ipo());
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    React.useEffect(() => {
        setExchange(data.exchange);
    }, [data, setExchange]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Releases"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Dropdown, { id: "exchange-dropdown", isDisabled: errorExchangeOptions !== undefined, name: "exchange", label: "Download Ipos from Exchange", onChange: onChangeDropdown('exchange'), placeHolder: errorExchangeOptions ?? (loadingExchangeOptions ? 'Loading' : 'Please Select.'), options: exchangeOptions, value: exchangeOptions.find((o) => 'value' in o && o.value === exchange) }))),
        exchange !== undefined
            ? (React.createElement(IpoExchangeTable, { exchange: exchange, ipos: ipos, emptyMessage: "You have no ipos yet", errorMessage: "Error downloading ipos", hasError: hasError, isLoading: isLoading })) : undefined));
};
export default IpoTable;
