const initServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        const swURL = new URL('sw.js', window.location.href);
        const { currEnv } = process.env;
        if (currEnv === 'DEV') {
            swURL.searchParams.append('env', 'DEV');
        }
        (async () => {
            try {
                const registration = await navigator
                    .serviceWorker.register(swURL, { scope: './' });
                let serviceWorker;
                if (registration.installing) {
                    serviceWorker = registration.installing;
                }
                else if (registration.waiting) {
                    serviceWorker = registration.waiting;
                }
                else if (registration.active) {
                    serviceWorker = registration.active;
                }
                if (serviceWorker !== undefined) {
                    serviceWorker.addEventListener('statechange', (event) => {
                        console.log('ServiceWorker state change event: %o', event);
                    });
                }
            }
            catch (error) {
                console.error('ServiceWorker registration error: %o', error);
                // Something went wrong during registration. The service-worker.js file
                // might be unavailable or contain a syntax error.
            }
        })();
    }
    else {
        console.warn('CANNOT LOAD SERVICE WORKER');
        // The current browser doesn't support service workers.
        // Perhaps it is too old or we are not in a Secure Context.
    }
};
export default initServiceWorker;
