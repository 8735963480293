import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import { getOptionsMulti } from 'utils/change';
const TagInput = ({ className, disabled = false, label, onChange, regex, tagOptions, tags, }) => {
    const [previous, setPrevious] = React.useState('');
    const onInputChange = (a) => {
        if (regex === undefined || a.match(regex) !== null || a === '') {
            setPrevious(a);
            return a;
        }
        return previous;
    };
    return (React.createElement(Dropdown, { addNewOptions: true, className: className, isDisabled: disabled, isMultiSelect: true, label: label, onChange: onChange, onInputChange: onInputChange, options: tagOptions, value: getOptionsMulti(tags, tagOptions) }));
};
export default TagInput;
