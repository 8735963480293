import * as React from 'react';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import currencyDownload from '../utils/currencyDownload';
import OverviewCategory from './OverviewCategory';
import resultsDownload from './results/resultsDownload';
const Overview = () => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [categoryList, setCategoryList] = React.useState([]);
    const [currencies, setCurrencies] = React.useState(new Map());
    const [downloadedSecurities, setDownloadedSecurities] = React.useState([]);
    const categories = React.useMemo(() => [
        {
            filter: (s) => {
                const fxRate = currencies.get(s.currencylabel);
                return (fxRate !== undefined ? (Number.parseFloat(s.totalissuedamount.replace(/,/g, '')) / fxRate) > 200000000 : false);
            },
            title: 'Public deals',
        }, {
            filter: (s) => s.currencylabel === 'EUR' && (new Date(s.maturitydate).getTime() - Date.now()) / 1000 / 3600 / 24 / 365 > 20,
            title: 'Long dated euros',
        },
    ], [currencies]);
    const absFilter = React.useCallback((s) => {
        if (s.tags?.includes('CLO')) {
            return true;
        }
        if (s.cfi && (s.cfi.group === 'Asset-Backed Securities' || s.cfi.group === 'Mortgage-Backed Securities')) {
            return true;
        }
        if (s.shortname.includes('2021-') || s.shortname.includes(' CLO ')) {
            return true;
        }
        return false;
    }, []);
    React.useEffect(() => {
        // download all new securities from today
        const request = new ServerRequest('/nit/security/today');
        (async () => {
            try {
                const body = await jsonPlus(request, 'Error downloading today\'s securities');
                const { securities: newSecurities } = resultsDownload(body);
                setDownloadedSecurities(newSecurities.map((a) => a).filter((s) => !absFilter(s)));
            }
            catch (error) {
                //
            }
        })();
    }, [absFilter, jsonPlus]);
    React.useEffect(() => {
        (async () => {
            const downloadedCurrencies = await currencyDownload();
            setCurrencies(downloadedCurrencies);
        })();
    }, []);
    React.useEffect(() => {
        const list = categories.map((c) => {
            const filteredSecurities = downloadedSecurities.filter(c.filter);
            if (!filteredSecurities.length) {
                return undefined;
            }
            return (React.createElement(OverviewCategory, { key: c.title, title: c.title, securities: filteredSecurities }));
        });
        setCategoryList(list);
    }, [downloadedSecurities, currencies, categories]);
    return (React.createElement("div", null,
        React.createElement(Typography, { type: "h1" }, "Summary of today's New Issues"),
        categoryList,
        React.createElement(Card, { className: "co-card-stack" },
            React.createElement("div", { className: "co-item" },
                React.createElement(Typography, { type: "h1" }, "Total Volume")))));
};
export default Overview;
