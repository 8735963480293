import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import FilterItem from '../../models/FilterItem';
import './FilterItemInput.css';
const FilterItemInput = ({ disabled = false, label, options, placeholder = 'Select...', value, onChange, }) => {
    const [exclude, setExclude] = React.useState(value?.exclude ?? true);
    const [values, setValues] = React.useState(value?.values ?? []);
    const flagOptions = React.useMemo(() => ([{ label: 'Exclude', value: true }, { label: 'Include', value: false }]), []);
    const onChangeDropdownExclude = React.useCallback((event) => {
        const { value: value2 } = event.target;
        setExclude(value2.value);
        const newObject2 = { target: { value: new FilterItem({ exclude, values }) } };
        onChange(newObject2);
    }, [exclude, onChange, values]);
    const onChangeDropdownValues = React.useCallback((event) => {
        const { value: value2 } = event.target;
        const newValues = Array.isArray(value2) ? [...new Set(value2.filter(v => 'value' in v).map((v) => v.value))] : ('value' in value2 ? [value2.value] : []);
        setValues(newValues);
        const newObject2 = { target: { value: new FilterItem({ exclude, values }) } };
        onChange(newObject2);
    }, [exclude, onChange, values]);
    const onMenuClose = React.useCallback(() => {
        document.activeElement.blur();
    }, []);
    console.log("values: %o, %o", value, values);
    return (React.createElement("div", { className: "tk-input-group tag-input" },
        React.createElement(LabelTooltipDecorator, { label: label }),
        React.createElement("div", { className: "co-input-container", id: "tag-group" },
            React.createElement(Dropdown, { className: "first-one", isDisabled: disabled, isTypeAheadEnabled: false, name: "exclude", onChange: onChangeDropdownExclude, onMenuClose: onMenuClose, options: flagOptions, size: "large", value: flagOptions.find((o) => o.value === exclude) }),
            React.createElement(Dropdown, { className: "second-one", isDisabled: disabled, isMultiSelect: true, name: "values", onChange: onChangeDropdownValues, options: options, placeholder: placeholder, value: options.filter(o => 'value' in o && values.includes(o.value)) }))));
};
export default FilterItemInput;
