import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import WebPushTestTable from 'pages/monitor/web-push/WebPushTestTable';
import * as React from 'react';
import WebPushTestForm from './WebPushTestForm';
const WebPushMonitor = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [isLoading, setLoading] = React.useState(false);
    const [responses, setResponses] = React.useState([]);
    const [selection, setSelection] = React.useState([]);
    React.useEffect(() => {
        const request = new ServerRequest('/notification/all');
        (async () => {
            setLoading(true);
            try {
                const responseBody = await apiFetchJson(request, 'Error getting notifications');
                setResponses(responseBody);
            }
            catch (error) {
                //
            }
            finally {
                setLoading(false);
            }
        })();
    }, [apiFetchJson]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Web Push Notifications"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "authentication", onClick: () => updateView('authentication'), size: "small" }, "AUTHENTICATION"),
                React.createElement(Button, { name: "web-sockets", onClick: () => updateView('web-sockets'), size: "small", style: { marginLeft: '0.25rem' } }, "WEB SOCKETS"))),
        React.createElement(WebPushTestForm, { responses: responses, selection: selection, setResponses: setResponses }),
        React.createElement(WebPushTestTable, { isLoading: isLoading, responses: responses, setSelection: setSelection })));
};
export default WebPushMonitor;
