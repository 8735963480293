import { Checkbox } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import './CheckFilterPanel.css';
import CloseIcon from './CloseIcon';
const CheckFilterPanel = function CheckFilterPanelElement({ close, column, options, }) {
    const allRef = React.useRef(null);
    const [checked, setChecked] = React.useState((() => {
        const initial = column.getFilterValue();
        return initial !== undefined ? initial : undefined;
    }));
    const optionsWithEmpty = React.useMemo(() => [{ label: '-', value: 'EMPTY' }].concat(options), [options]);
    /**
     * Change the filtervalue, but only after one second, and if there is anything to filter.
     */
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (column.getFilterValue() !== checked && (checked === undefined || checked.length > 0)) {
                column.setFilterValue(checked);
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [column, checked]);
    const checkManuallyAll = React.useCallback((values) => {
        const refElement = allRef.current;
        if (refElement !== null) {
            const checkboxElement = refElement.querySelector(`input[name=all]`);
            if (checkboxElement !== null) {
                const inputElement = checkboxElement;
                const on = (values === undefined) || values.length > 0;
                if (inputElement.checked !== on) {
                    inputElement.click();
                }
            }
        }
    }, []);
    const checkManually = React.useCallback((values) => {
        const refElement = allRef.current;
        if (refElement !== null) {
            optionsWithEmpty.forEach((v) => {
                const name = v.value;
                const checkboxElement = refElement.querySelector(`input[name=A_${name}]`);
                if (checkboxElement !== null) {
                    const inputElement = checkboxElement;
                    const on = (values === undefined) || values.includes(name);
                    if (inputElement.checked !== on) {
                        inputElement.click();
                    }
                }
            });
        }
    }, [optionsWithEmpty]);
    const onClick = React.useCallback((event) => {
        if (event.type === 'click' && event.isTrusted) {
            const target = event.target;
            const name = target.getAttribute('name');
            if (name !== null) {
                const clone = checked ? [...checked] : [...optionsWithEmpty.map((c) => c.value)];
                const cleanName = name.replace('A_', '');
                const index = clone.indexOf(cleanName);
                if (index > -1) { // only splice array when item is found
                    clone.splice(index, 1); // 2nd parameter means remove one item only
                }
                else {
                    clone.push(cleanName);
                }
                const value = clone.length < optionsWithEmpty.length ? clone : undefined;
                setChecked(value);
                checkManuallyAll(value);
            }
        }
    }, [checked, checkManuallyAll, optionsWithEmpty]);
    const onClickAll = React.useCallback((event) => {
        if (event.type === 'click' && event.isTrusted) {
            if (checked !== undefined) {
                setChecked(undefined);
                checkManually(undefined);
                checkManuallyAll(undefined);
            }
            else {
                setChecked([]);
                checkManually([]);
                checkManuallyAll([]);
            }
        }
    }, [checked, checkManually, checkManuallyAll]);
    /**
     * This happens only at the start.
     */
    React.useLayoutEffect(() => {
        const current = column.getFilterValue();
        const initialValue = current === undefined ? undefined : current;
        checkManually(initialValue);
    }, [checkManually, column]);
    const allStatus = React.useMemo(() => {
        if (checked === undefined) {
            return SelectionStatus.CHECKED;
        }
        if (checked.length === 0) {
            return SelectionStatus.UNCHECKED;
        }
        return SelectionStatus.MIXED;
    }, [checked]);
    return (React.createElement("div", { className: "co-check-panel", ref: allRef },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Checkbox, { label: "", name: "all", onClick: onClickAll, status: allStatus, value: '' }),
            React.createElement(CloseIcon, { onClick: close })),
        optionsWithEmpty.map((v) => (React.createElement(Checkbox, { key: v.label, label: v.label, name: `A_${v.value}`, onClick: onClick, status: (checked === undefined || checked.includes(v.value)) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: (checked === undefined || checked.includes(v.value)) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED })))));
};
export default CheckFilterPanel;
