import CoreUser from "models/CoreUser";
class MetaData {
    createdBy;
    createdDate;
    id;
    lastModifiedBy;
    lastModifiedDate;
    permissions;
    constructor(response) {
        if (response?.createdBy !== undefined)
            this.createdBy = new CoreUser(response.createdBy);
        if (response?.createdDate !== undefined)
            this.createdDate = response.createdDate;
        if (response?.id !== undefined)
            this.id = response.id;
        if (response?.lastModifiedBy !== undefined)
            this.lastModifiedBy = new CoreUser(response.lastModifiedBy);
        if (response?.lastModifiedDate !== undefined)
            this.lastModifiedDate = response.lastModifiedDate;
        if (response?.permissions !== undefined)
            this.permissions = response.permissions;
    }
}
export default MetaData;
