import { Button } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
import KeyValueRegex from '../../../../models/regexes/KeyValueRegex';
const AddKeyButton = ({ callback, keyValueRegexId, name, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const addKey = React.useCallback(() => {
        const formData = new FormData();
        formData.append('key', name);
        formData.append('id', keyValueRegexId);
        const request = new ServerRequest('/syndicate/regex/keyvalues/add-key', { body: formData, method: 'PATCH' });
        (async () => {
            try {
                const updated = await apiFetchJson(request);
                const updateKVR = new KeyValueRegex(updated);
                callback(updateKVR);
            }
            catch (error) {
                sendFetchError('Unable to add name to regex', error, request);
            }
        })();
    }, [callback, apiFetchJson, keyValueRegexId, name]);
    return (React.createElement(Button, { onClick: addKey, style: { marginLeft: '0.5rem' } }, "Add Key to Existing"));
};
export default AddKeyButton;
