import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import ResultsPicker from './ResultsPicker';
const ResultsPickerRow = ({ clickFilter, clickShare, result, setResult, }) => {
    const [disabled, setDisabled] = React.useState(false);
    return (React.createElement("div", { className: "co-dropdown-row co-item" },
        React.createElement(Button, { className: "co-button", disabled: disabled, iconButton: true, onClick: clickFilter },
            React.createElement(Icon, { iconName: "filter" })),
        React.createElement(Button, { className: "co-button", disabled: disabled, iconButton: true, onClick: clickShare },
            React.createElement(Icon, { iconName: "share" })),
        React.createElement(ResultsPicker, { result: result, setDisabled: setDisabled, setResult: setResult })));
};
export default ResultsPickerRow;
