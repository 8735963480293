import { Button, Modal, ModalBody, ModalFooter, ModalHeader, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const EmailFixFeedbackModal = ({ feedback, onClose }) => {
    const clickOk = React.useCallback(() => {
        onClose();
    }, [onClose]);
    return (React.createElement(Modal, { show: true, size: "medium" },
        React.createElement(ModalHeader, null, "Result Fix"),
        React.createElement(ModalBody, null, feedback.length === 0 ? (React.createElement("p", null, "ALL OK")) : (React.createElement("div", null, feedback.map((s) => (React.createElement("p", null, s)))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: clickOk }, "OK"))));
};
export default EmailFixFeedbackModal;
