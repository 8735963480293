import * as React from 'react';
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle, TextField } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const ConstantModal = ({ constant, genericOptions, onClose, setConstant, updateTable }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [displayName, setDisplayName] = React.useState(constant.displayName ?? "");
    const [generidIds, setGenericIds] = React.useState(constant.genericIds ?? []);
    const onChangeDisplayName = React.useCallback((event) => {
        setDisplayName(event.target.value);
    }, []);
    const onChangeGenericIds = React.useCallback((event) => {
        const { value } = event.target;
        if (Array.isArray(value)) {
            setGenericIds(value.map(v => v.value));
        }
        else {
            setGenericIds([value.value]);
        }
    }, []);
    const onClickSave = React.useCallback(() => {
        const clone = { ...constant };
        clone.displayName = displayName;
        clone.genericIds = generidIds;
        const request = new ServerRequest('/syndicate/constant', {
            body: JSON.stringify(clone),
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT'
        });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                setConstant(body);
                updateTable(body);
                onClose();
            }
            catch (error) {
                sendFetchError("Unable to save Constant", error, request);
            }
        })();
    }, [apiFetchJson, constant, displayName, generidIds, onClose, setConstant, updateTable]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: "medium" },
        React.createElement(ModalTitle, null, "Update Constant"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Display Name", onChange: onChangeDisplayName, value: displayName }),
            React.createElement(Dropdown, { isMultiSelect: true, label: "More Generic Constants", onChange: onChangeGenericIds, options: genericOptions.filter(o => 'value' in o && constant.id !== o.value), tooltip: "More generic categories than the current one. For instance, just 'Senior' is more generic / encompasses 'Senior Unsecured'", value: genericOptions.filter(o => 'value' in o && generidIds.includes(o.value)) })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default ConstantModal;
