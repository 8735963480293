import * as React from 'react';
import { App, appFromModule } from 'utils/appUtils';
import { Icon } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
import FullPageLoader from 'components/loading-page/FullPageLoader';
import ImageCard from '../../components/ImageCard';
import { localIconUrl as nitIconUrl } from '../../../modules/nit/utils/iconUrls';
import './main.css';
import { useAuth } from 'react-oidc-context';
const Main = () => {
    const { availableApps, updateAppView, updateView } = React.useContext(NavigationContext);
    const auth = useAuth();
    const onClickCard = React.useCallback((destination) => () => {
        updateAppView(destination);
    }, [updateAppView]);
    React.useEffect(() => {
        if (auth.user != null) {
            const claims = auth.user.profile;
            const moduleNames = Object.keys(claims.authorityMap);
            const moduleNamesExAdmin = moduleNames.filter(m => m !== 'cmo.core');
            if (moduleNamesExAdmin.length === 1) {
                const singleApp = appFromModule(moduleNamesExAdmin[0]);
                updateView(singleApp);
            }
        }
    }, [auth.user, updateView]);
    const available = React.useMemo(() => availableApps(), [availableApps]);
    if (available === undefined) {
        return (React.createElement(FullPageLoader, null));
    }
    if (availableApps()?.length === 0) {
        return (React.createElement("p", null, "You are not allowed to access any apps."));
    }
    return (React.createElement("div", { id: "main-page" },
        React.createElement("div", { id: "image-grid" },
            available.includes(App.SYND)
                ? React.createElement(ImageCard, { id: "synd", onClick: onClickCard(App.SYND), text: "Syndicate App" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            available.includes(App.NIT)
                ? React.createElement(ImageCard, { id: "nit", onClick: onClickCard(App.NIT), text: "New Issue Tracker" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            available.includes(App.IPO)
                ? React.createElement(ImageCard, { id: "ipo", onClick: onClickCard(App.IPO), text: "IPO Tracker" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            available.includes(App.MACRO)
                ? React.createElement(ImageCard, { id: "release", onClick: onClickCard(App.MACRO), text: "Economic Releases" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            available.includes(App.MTN)
                ? React.createElement(ImageCard, { id: "mtn", onClick: onClickCard(App.MTN), text: "MTN Workflow App" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            available.includes(App.ADMIN)
                ? React.createElement(ImageCard, { id: "admin", onClick: onClickCard(App.ADMIN), text: "Admin" },
                    React.createElement(Icon, { className: "main-settings-icon", iconName: "settings" })) : undefined)));
};
export default Main;
