import * as React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { APP_URL, AUTH_URL } from 'utils/system/envs-constants';
import ThemeContext from 'contexts/ThemeContext';
import MainRoute from '../../../site/MainRoute';
const MyRoutes = () => {
    const [colorScheme, setColorScheme] = React.useState(localStorage.getItem('colorScheme') ?? 'match');
    const onSigninCallback = React.useCallback(() => window.history.replaceState({}, document.title, APP_URL), []);
    const themeValue = React.useMemo(() => ({ colorScheme, setColorScheme }), [colorScheme]);
    return (React.createElement(AuthProvider, { authority: AUTH_URL, automaticSilentRenew: true, client_id: "public-client", includeIdTokenInSilentRenew: true, includeIdTokenInSilentSignout: true, loadUserInfo: true, onSigninCallback: onSigninCallback, post_logout_redirect_uri: `${APP_URL}/sign-out`, redirect_uri: `${APP_URL}/callback`, response_mode: "query", response_type: "code", scope: "openid profile email", 
        // scope="openid profile email offline_access"
        silent_redirect_uri: `${APP_URL}/silent` },
        React.createElement(ThemeContext.Provider, { value: themeValue },
            React.createElement(MainRoute, null))));
};
export default MyRoutes;
