import { Button } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import SearchNameInput from '../SearchNameInput';
const SearchSaveButton = ({ disabled, save, search }) => {
    const [modalContent, setModalContent] = React.useState();
    const addNameAndSave = async (name) => {
        if (search !== undefined) {
            const nameUpdated = search.updateName(name);
            await save(nameUpdated);
        }
    };
    const hideModal = () => setModalContent(undefined);
    const onClick = () => {
        if (search?.name !== undefined) {
            (async () => {
                await save(search);
            })();
        }
        else {
            setModalContent(React.createElement(SearchNameInput, { hideModal: hideModal, save: addNameAndSave }));
        }
    };
    return (React.createElement(React.Fragment, null,
        modalContent,
        React.createElement(Button, { disabled: disabled, onClick: onClick }, "SAVE")));
};
export default SearchSaveButton;
