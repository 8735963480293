import { defaultPermissions } from 'components/metadata/utils';
import AnnaFilter from '../filter/AnnaFilter';
import AnnaQuery from '../query/AnnaQuery';
class AnnaSearch {
    createdBy;
    createdDate;
    database;
    filter;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    query;
    constructor() {
        this.permissions = defaultPermissions;
        this.filter = new AnnaFilter();
        this.query = new AnnaQuery();
    }
    static create(base) {
        const query = Object.assign(new AnnaQuery(), base.query);
        const filter = Object.assign(new AnnaFilter(), base.filter);
        return Object.assign(new AnnaSearch(), base, { filter, query });
    }
    updateName(value) {
        return Object.assign(new AnnaSearch(), this, { name: value });
    }
    updatePermissions(value) {
        return Object.assign(new AnnaSearch(), this, { permissions: value });
    }
}
export default AnnaSearch;
