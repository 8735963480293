import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import EditableCell from '../transactions/overview/table/EditableCell';
import '../transactions/overview/table/EditableCell.css';
const EditableNameTextCell = ({ table, row, column, cell, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    const [originalValue, setOriginalValue] = React.useState();
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            setValue(calculated);
        }
        else {
            setValue('');
        }
    }, [column, row.index, row.original]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && value !== undefined) {
            const url = new ApiServerURL('/syndicate/constant/name');
            url.searchParams.append('name', value);
            url.searchParams.append('id', row.original.id);
            url.searchParams.append('index', row.original.index.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            setDisabled(true);
            setLoading(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const { id } = body;
                if (id !== undefined) {
                    const constantName = body.names.map((name, index) => ({ id, index, name }));
                    table.options.meta.updateTable(constantName);
                }
            }
            catch (error) {
                setErrorText('Unable to save constant info');
            }
            setDisabled(false);
            setEditing(false);
            setLoading(false);
        }
    }, [jsonPlus, row.original.id, row.original.index, table, value]);
    const onBlur = React.useCallback(() => {
        (async () => save())();
    }, [save]);
    const inputRef = React.useRef(null);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save())();
        }
    }, [save]);
    React.useLayoutEffect(() => {
        if (isEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            setOriginalValue(calculated);
        }
    }, [column, row.original, row.index]);
    if (originalValue === undefined) {
        return React.createElement("div", null);
    }
    return (React.createElement(EditableCell, { errorText: errorText, isLoading: isLoading, isLoadingRow: isLoading, key: cell.id, setEditing: setEditing }, isEditing
        ? React.createElement("input", { className: "co-editable-input", disabled: isDisabled, ref: inputRef, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value })
        : value));
};
export default EditableNameTextCell;
