import { defaultPermissions } from 'components/metadata/utils';
import EsmaFilter from '../filter/EsmaFilter';
import EsmaQuery from '../query/EsmaQuery';
class EsmaSearch {
    createdBy;
    createdDate;
    database;
    filter;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    query;
    constructor() {
        this.permissions = defaultPermissions;
        this.filter = new EsmaFilter();
        this.query = new EsmaQuery();
    }
    static create(base) {
        const query = Object.assign(new EsmaQuery(), base.query);
        const filter = Object.assign(new EsmaFilter(), base.filter);
        return Object.assign(new EsmaSearch(), base, { filter, query });
    }
    updateName(value) {
        return Object.assign(new EsmaSearch(), this, { name: value });
    }
    updatePermissions(value) {
        return Object.assign(new EsmaSearch(), this, { permissions: value });
    }
}
export default EsmaSearch;
