import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import KeyValueRegexForm from '../regex-viewer/key-value-regex-form/KeyValueRegexForm';
import './KeyFormModal.scss';
const KeyFormModal = ({ hideModal, name, show, title, }) => {
    const [keyValueRegex, setKeyValueRegex] = React.useState();
    const callback = React.useCallback(() => {
        hideModal();
        setKeyValueRegex(undefined);
        document.dispatchEvent(new Event('reloadcontent'));
    }, [hideModal]);
    const onClose = React.useCallback(() => {
        setKeyValueRegex(undefined);
        hideModal();
    }, [hideModal]);
    return (React.createElement(Modal, { className: "co-key-form-modal", size: "large", show: show, closeButton: true, onClose: onClose, parentNode: document.body },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, `NEW KEY: ${title}`)),
        React.createElement(ModalBody, null,
            React.createElement(KeyValueRegexForm, { callbackAdd: callback, callbackCreate: callback, isFormDisabled: false, keyValueRegex: keyValueRegex, name: name, setKeyValueRegex: setKeyValueRegex }))));
};
export default KeyFormModal;
