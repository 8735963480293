import AnnaSearch from '../models/search/AnnaSearch';
import EsmaSearch from '../models/search/EsmaSearch';
import EuroclearSearch from '../models/search/EuroclearSearch';
import IpoSearch from '../models/search/IpoSearch';
import SecSearch from '../models/search/SecSearch';
import AnnaSecurity from '../models/security/AnnaSecurity';
import EsmaSecurity from '../models/security/EsmaSecurity';
import EuroclearSecurity from '../models/security/EuroclearSecurity';
import SecSecurity from '../models/security/SecSecurity';
import EsmaFilter from '../models/filter/EsmaFilter';
import EuroclearFilter from '../models/filter/EuroclearFilter';
import SecFilter from '../models/filter/SecFilter';
import AnnaFilter from '../models/filter/AnnaFilter';
import AnnaCompany from '../models/company/AnnaCompany';
import EsmaCompany from '../models/company/EsmaCompany';
import EuroclearCompany from '../models/company/EuroclearCompany';
import SecCompany from '../models/company/SecCompany';
export var SecuritySource;
(function (SecuritySource) {
    SecuritySource["ANNA"] = "ANNA";
    SecuritySource["ESMA"] = "ESMA";
    SecuritySource["EUROCLEAR"] = "Euroclear";
})(SecuritySource || (SecuritySource = {}));
export var Source;
(function (Source) {
    Source["ANNA"] = "ANNA";
    Source["ESMA"] = "ESMA";
    Source["EUROCLEAR"] = "Euroclear";
    Source["SEC"] = "SEC";
    Source["IPO"] = "IPO";
})(Source || (Source = {}));
export const fromSource = (value) => {
    switch (value.toUpperCase()) {
        case 'ANNA':
            return Source.ANNA;
        case 'ESMA':
            return Source.ESMA;
        case 'EUROCLEAR':
            return Source.EUROCLEAR;
        case 'SEC':
            return Source.SEC;
        case 'IPO':
            return Source.IPO;
        default:
            throw new Error(`Unknown source: ${value}`);
    }
};
export const databaseOptions = [
    { label: 'Euroclear', value: Source.EUROCLEAR },
    { label: 'ESMA', value: Source.ESMA },
    { label: 'ANNA', value: Source.ANNA },
    { label: 'SEC', value: Source.SEC },
    { label: 'IPO', value: Source.IPO },
];
export const turnCompanyIntoClass = (db, response) => {
    switch (db) {
        case Source.ANNA:
            return new AnnaCompany(response);
        case Source.ESMA:
            return new EsmaCompany(response);
        case Source.EUROCLEAR:
            return new EuroclearCompany(response);
        case Source.SEC:
            return new SecCompany(response);
        default:
            throw new Error('Unable to find source');
    }
};
export const turnIntoClass = (db, response) => {
    switch (db) {
        case Source.ANNA:
            return new AnnaSearch(response);
        case Source.ESMA:
            console.log("Setting: %o", response);
            return new EsmaSearch(response);
        case Source.EUROCLEAR:
            return new EuroclearSearch(response);
        case Source.IPO:
            return new IpoSearch(response);
        case Source.SEC:
            return new SecSearch(response);
        default:
            throw new Error('Unable to find source');
    }
};
export const turnSecurityIntoClass = (db, response) => {
    switch (db) {
        case Source.ANNA:
            return new AnnaSecurity(response);
        case Source.ESMA:
            return new EsmaSecurity(response);
        case Source.EUROCLEAR:
            return new EuroclearSecurity(response);
        case Source.SEC:
            return new SecSecurity(response);
        default:
            throw new Error('Unable to find source');
    }
};
export const turnFilterIntoClass = (db, response) => {
    switch (db) {
        case Source.ANNA:
            return new AnnaFilter(response);
        case Source.ESMA:
            return new EsmaFilter(response);
        case Source.EUROCLEAR:
            return new EuroclearFilter(response);
        case Source.SEC:
            return new SecFilter(response);
        default:
            throw new Error('Unable to find source');
    }
};
