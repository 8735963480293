import { BannerType } from '@symphony-ui/uitoolkit-components/components/banner/interfaces';
export const sendMessage = (content, variant) => {
    const errorMessage = { content, variant };
    const errorEvent = new CustomEvent('mymessage', { detail: errorMessage });
    document.dispatchEvent(errorEvent);
};
export const sendError = (content, error) => {
    if (!(error instanceof Error && error.name === 'AbortError')) {
        let errorText;
        if (error instanceof Error) {
            errorText = `${error.name} - ${error.message}`;
        }
        else if (typeof error === 'string') {
            errorText = error;
        }
        else {
            errorText = 'unknown error';
        }
        sendMessage(`${content} (${errorText})`, BannerType.ERROR);
    }
};
const isOfflineError = (error) => error instanceof TypeError && error.message === 'Failed to fetch';
export const setOffline = (error) => {
    const offline = isOfflineError(error);
    const apiStatusEvent = new CustomEvent('api.status', { detail: !offline });
    window.dispatchEvent(apiStatusEvent);
};
export const setOnline = () => {
    const apiStatusEvent = new CustomEvent('api.status', { detail: true });
    window.dispatchEvent(apiStatusEvent);
};
export const sendFetchError = (errorMessage, error, request) => {
    if (!isOfflineError(error)) {
        const errorText = `${errorMessage} (${request.url.toString()})`;
        sendError(errorText, error);
    }
};
