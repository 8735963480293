import TimeRange from '../TimeRange';
class EsmaQuery {
    textCriteria;
    getCreationDate = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'mrkt_trdng_rqst_date');
        return textCriteria !== undefined ? TimeRange.from(textCriteria.values) : undefined;
    };
    getCurrency = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'gnr_notional_curr_code');
        return textCriteria ? textCriteria.values : [];
    };
    getMaturity = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'bnd_maturity_date');
        return textCriteria ? TimeRange.from(textCriteria.values) : undefined;
    };
}
export default EsmaQuery;
