var _a;
import { GenericFilter } from '../GenericFilter';
class AnnaFilter extends GenericFilter {
    #clone() {
        return new _a({
            tags: this.tags
        });
    }
    updateTags(value) {
        const clone = this.#clone();
        clone.tags = value;
        return clone;
    }
    hasValues() {
        if (this.tags?.values !== undefined && this.tags.values.length > 0) {
            return true;
        }
        return false;
    }
}
_a = AnnaFilter;
export default AnnaFilter;
