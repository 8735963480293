export const typeOptions = [
    { label: 'New Issue', value: 'NEW_ISSUE' },
    { label: 'Consent Solicitation', value: 'CONSENT_SOLICITATION' },
    { label: 'Exchange Offer', value: 'EXCHANGE_OFFER' },
    { label: 'NI & TO', value: 'NEW_ISSUE_TENDER_OFFER' },
    { label: 'Tender Offer', value: 'TENDER_OFFER' },
    { label: 'Non-deal Roadshow', value: 'NON_DEAL_ROADSHOW' },
    { label: '---', options: [] },
    { label: 'RECALC', value: 'RECALC' },
];
export const typeNewOptions = [
    { label: 'New Issue', value: 'NEW_ISSUE' },
    { label: 'Consent Solicitation', value: 'CONSENT_SOLICITATION' },
    { label: 'Exchange Offer', value: 'EXCHANGE_OFFER' },
    { label: 'Tender Offer', value: 'TENDER_OFFER' },
    { label: 'Non-deal Roadshow', value: 'NON_DEAL_ROADSHOW' },
    { label: '---', options: [] },
    { label: 'RECALC', value: 'RECALC' },
];
export const sectorOptions = [
    { label: 'ABS', value: 'ABS' },
    { label: 'Corp', value: 'CORPORATE' },
    { label: 'Covered', value: 'COVERED' },
    { label: 'EM', value: 'EM' },
    { label: 'FI', value: 'FINANCIAL' },
    { label: 'HY', value: 'HY' },
    { label: 'SSA', value: 'SSA' },
    { label: '---', options: [] },
    { label: 'RECALC', value: 'RECALC' },
];
