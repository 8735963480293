class Company {
    alternativeNames;
    countryId;
    id;
    lei;
    name;
    sectors;
    tickers;
    types;
    constructor(companyResponse) {
        this.alternativeNames = companyResponse.alternativeNames;
        this.countryId = companyResponse.countryId;
        this.id = companyResponse.id;
        this.lei = companyResponse.lei;
        this.name = companyResponse.name;
        this.sectors = companyResponse.sectors;
        this.tickers = companyResponse.tickers;
        this.types = companyResponse.types;
    }
    clone() {
        return new Company({
            alternativeNames: this.alternativeNames,
            countryId: this.countryId,
            id: this.id,
            lei: this.lei,
            name: this.name,
            sectors: this.sectors,
            tickers: this.tickers,
            types: this.types
        });
    }
    addAlternativeName(name) {
        const clone = this.clone();
        if (clone.alternativeNames === undefined) {
            clone.alternativeNames = [];
        }
        clone.alternativeNames.push(name);
        return clone;
    }
    setCountryId(countryId) {
        const clone = this.clone();
        clone.countryId = countryId;
        return clone;
    }
    setLei(lei) {
        const clone = this.clone();
        clone.lei = lei;
        return clone;
    }
    setName(newName) {
        const clone = this.clone();
        clone.name = newName;
        return clone;
    }
    setSectors(sectors) {
        const clone = this.clone();
        clone.sectors = sectors;
        return clone;
    }
    setTickers(tickers) {
        const clone = this.clone();
        clone.tickers = tickers;
        ;
        return clone;
    }
    setTypes(types) {
        const clone = this.clone();
        clone.types = types;
        return clone;
    }
    static create() {
        return new Company({
            alternativeNames: [],
            countryId: "",
            id: undefined,
            lei: "",
            name: "",
            sectors: [],
            tickers: [],
            types: []
        });
    }
}
export default Company;
