var _a;
class Country {
    id;
    name;
    shortName;
    region;
    alternativeNames;
    constructor(countryResponse) {
        this.alternativeNames = countryResponse?.alternativeNames;
        this.id = countryResponse?.id;
        this.name = countryResponse?.name;
        this.region = countryResponse?.region;
        this.shortName = countryResponse?.shortName;
    }
    #clone() {
        return new _a({
            alternativeNames: this.alternativeNames,
            id: this.id,
            name: this.name,
            region: this.region,
            shortName: this.shortName
        });
    }
    static cloneOrNew(country) {
        return country !== undefined ? country.#clone() : new _a();
    }
    setName(name) {
        const clone = this.#clone();
        clone.name = name;
        return clone;
    }
    setShortName(shortName) {
        const clone = this.#clone();
        clone.shortName = shortName;
        return clone;
    }
    setId(id) {
        const clone = this.#clone();
        clone.id = id;
        return clone;
    }
    setRegion(region) {
        const clone = this.#clone();
        clone.region = region;
        return clone;
    }
}
_a = Country;
export default Country;
