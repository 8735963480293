import * as React from 'react';
import { BannerType } from '@symphony-ui/uitoolkit-components';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import TableContext from 'components/custom-table/TableContext';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendError, sendFetchError } from 'utils/messageUtils';
import ExpanderCell from './ExpanderCell';
import TableCheckbox from './TableCheckbox';
import CollapseCell from './CollapseCell';
import TransactionMetaModal from './TransactionMetaModal';
import SubComponent from './sub-component/SubComponent';
const TransactionTable = ({ actionElements, dataTransform }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { resetRowExpansion, tableUpdateFn } = React.useContext(TableContext);
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [showMetaModal, setShowMetaModal] = React.useState(false);
    const [transaction, setTransaction] = React.useState();
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]),
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber].findIndex((d) => d.id === response.id);
                }
                if (index > -1) {
                    updated.pages[pageNumber].splice(index, 1, response);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
        }
        else {
            sendError('No table update function.', BannerType.ERROR);
        }
    }, [tableUpdateFn]);
    const onClickCollapse = React.useCallback(() => {
        if (resetRowExpansion !== undefined) {
            resetRowExpansion([]);
        }
    }, [resetRowExpansion]);
    const onCloseMeta = React.useCallback(() => {
        setShowMetaModal(false);
    }, []);
    const test = React.useCallback(() => (React.createElement(CollapseCell, { onClick: onClickCollapse })), [onClickCollapse]);
    const subComponent = React.useCallback((row) => {
        const newTransactionId = row.original.id;
        const transactionMeta = row.original.getMeta();
        return (React.createElement(SubComponent, { colSpan: row.getVisibleCells().length, transactionId: newTransactionId, transactionMeta: transactionMeta }));
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sectors');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setSectorOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download sector options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'regions');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setRegionOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download region options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'types');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setTypeOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download type options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const openModal = React.useCallback((item) => () => {
        setTransaction(item);
        setShowMetaModal(true);
    }, []);
    const meta = React.useMemo(() => ({
        idFunction: (d) => d.id,
        updateTable,
        onClickFn: (item) => openModal(item),
    }), [openModal, updateTable]);
    const columns = React.useMemo(() => [
        {
            cell: (ExpanderCell), header: test, id: 'expander', size: 28,
        },
        {
            cell: (TableCheckbox), header: () => null, id: 'transactionTickbox', size: 28,
        },
        {
            accessorFn: (t) => sectorOptions.find((o) => ('value' in o && o.value === t.sector))?.label,
            header: 'Sector',
            id: 'sector',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: sectorOptions }, isClickable: true },
            size: 80,
        },
        {
            accessorFn: (t) => regionOptions.filter((o) => ('value' in o && t.regions?.includes(o.value))).map(o => o.label).join(", "),
            header: 'Region(s)',
            id: 'regions',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: regionOptions }, isClickable: true },
            size: 80,
        },
        {
            accessorFn: (t) => typeOptions.filter((o) => ('value' in o && t.types?.includes(o.value))).map((o) => o.label).join(', '),
            header: 'Type(s)',
            id: 'types',
            meta: { className: 'co-centered', dropdown: { isMulti: true, options: typeOptions }, isClickable: true },
            size: 80,
        },
        {
            accessorFn: (t) => t.company?.name,
            header: 'Company',
            id: 'company',
            meta: { isClickable: true },
            size: 240,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            id: 'name',
            meta: { isClickable: true },
            size: 9999,
        },
        {
            accessorFn: (t) => t.firstUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'First Update',
            id: 'firstUpdate',
            meta: { className: 'co-centered', isClickable: true },
            size: 200,
        },
        {
            accessorFn: (t) => t.lastUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'Last Update',
            id: 'lastUpdate',
            meta: { className: 'co-centered', isClickable: true },
            size: 200,
        },
    ], [regionOptions, sectorOptions, test, typeOptions]);
    return (React.createElement(React.Fragment, null,
        showMetaModal && transaction !== undefined ? React.createElement(TransactionMetaModal, { onClose: onCloseMeta, transaction: transaction }) : undefined,
        React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, dataTransform: dataTransform, emptyMessage: "You have no transactions yet", errorMessage: "Unable to connect to server to load the data. Please check connection.", fetchSize: 100, meta: meta, name: "transactions", subComponent: subComponent, urlPage: "/syndicate/transactions/page", urlTotal: "/syndicate/transactions/total" })));
};
export default TransactionTable;
