import { Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const JsonModal = ({ onClose, title, tranches, }) => {
    const width = React.useMemo(() => `${(100 / tranches.length).toString()}%`, [tranches.length]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "full-width" },
        React.createElement(ModalTitle, null, title),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { display: 'flex' } }, tranches.map((tranche, index) => (React.createElement("pre", { key: index, style: { overflow: 'auto', width } }, JSON.stringify(tranche, null, 4))))))));
};
export default JsonModal;
