var _a;
import SecSecurity from '../security/SecSecurity';
import { GenericFilter } from '../GenericFilter';
class SecFilter extends GenericFilter {
    exclude;
    name;
    values;
    constructor(response) {
        super(response);
        this.exclude = response?.exclude ?? true;
        this.name = response?.name;
        this.values = response?.values ?? [];
    }
    #clone() {
        return new _a({
            exclude: this.exclude,
            name: this.name,
            values: this.values,
            tags: this.tags
        });
    }
    updateExclude(event) {
        const newFilter = this.#clone();
        newFilter.exclude = event.target.value;
        return newFilter;
    }
    updateName(event) {
        const newFilter = this.#clone();
        newFilter.name = event.target.value;
        return newFilter;
    }
    updateTags(value) {
        const newFilter = this.#clone();
        newFilter.tags = value;
        return newFilter;
    }
    updateValues(event) {
        const newFilter = this.#clone();
        newFilter.values = event.target.value;
        return newFilter;
    }
    create() {
        const test = (contains, name) => (this[name]?.exclude && !contains) ?? (!this[name]?.exclude && contains);
        return (s) => {
            if (s instanceof SecSecurity) {
                const tagsTest = this.tags?.values.length ? test(s.tags.some((t) => this.tags?.values.includes(t)), 'tags') : true;
                return tagsTest;
            }
            return false;
        };
    }
}
_a = SecFilter;
export default SecFilter;
