import { Button, DatePicker } from '@symphony-ui/uitoolkit-components';
import { datePattern } from 'components/custom-table/dateFormat';
import RenderingContext from 'contexts/RenderingContext';
import * as React from 'react';
const MaturityCellDatePicker = ({ hasOverride, hasValue, isDisabled, onChange, remove, save, tableCellRef, value, }) => {
    const { dateFormat } = React.useContext(RenderingContext);
    const onClickPerpetual = React.useCallback(() => {
        (async () => {
            tableCellRef?.focus();
            await save('Perpetual');
        })();
    }, [save, tableCellRef]);
    const onClickTbc = React.useCallback(() => {
        (async () => {
            tableCellRef?.focus();
            await save('TBC');
        })();
    }, [save, tableCellRef]);
    const onClickRemove = React.useCallback(() => {
        (async () => {
            tableCellRef?.focus();
            await remove();
        })();
    }, [remove, tableCellRef]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DatePicker, { autoFocus: true, date: value, disabled: isDisabled, format: datePattern(dateFormat, 'YMD'), onChange: onChange, showRequired: true, showOverlay: true }),
        React.createElement("div", { style: { display: 'flex', marginLeft: '10px', justifyContent: 'center' } },
            React.createElement(Button, { onClick: onClickPerpetual, variant: "tertiary-accent" }, "PERPETUAL"),
            hasValue
                ? React.createElement("div", null,
                    React.createElement(Button, { onClick: onClickTbc, variant: "tertiary-accent" }, "TBC")) : undefined,
            hasOverride
                ? React.createElement("div", null,
                    React.createElement(Button, { onClick: onClickRemove, variant: "tertiary-destructive" }, "REMOVE")) : undefined)));
};
export default MaturityCellDatePicker;
