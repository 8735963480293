import * as React from 'react';
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptionsMulti } from 'utils/change';
const IFRFilterModal = ({ filter, onClose, setFilter, show, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [regions, setRegions] = React.useState(filter !== undefined ? filter.region : []);
    const [assetClass, setAssetClass] = React.useState(filter !== undefined ? filter.assetClass : []);
    const [optionsAssetClass, setOptionsAssetClass] = React.useState([]);
    const [optionsRegion, setOptionsRegion] = React.useState([]);
    const onChangeRegion = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        setRegions(newValue);
    }, []);
    const onChangeAssetClass = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        setAssetClass(newValue);
    }, []);
    const onClickSave = React.useCallback(() => {
        const newFilter = { region: regions, assetClass };
        localStorage.setItem('ifr-filter', JSON.stringify(newFilter));
        setFilter(newFilter);
    }, [assetClass, regions, setFilter]);
    React.useEffect(() => {
        const request = new ServerRequest('/syndicate/ifr/options/asset-class');
        (async () => {
            try {
                const body = await jsonPlus(request, 'Error downloading asset class options');
                setOptionsAssetClass(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    React.useEffect(() => {
        const request = new ServerRequest('/syndicate/ifr/options/region');
        (async () => {
            try {
                const body = await jsonPlus(request, 'Error downloading region options');
                setOptionsRegion(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: show, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(Typography, { type: "h2" }, "Filter IFR Stream")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { height: '4.5rem' } },
                React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Regions", onChange: onChangeRegion, options: optionsRegion, value: getOptionsMulti(regions, optionsRegion) }))),
            React.createElement("div", { style: { height: '4.5rem' } },
                React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                    React.createElement(Dropdown, { isMultiSelect: true, label: "Asset Classes", onChange: onChangeAssetClass, options: optionsAssetClass, value: getOptionsMulti(assetClass, optionsAssetClass) })))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default IFRFilterModal;
