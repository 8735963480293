import * as React from 'react';
import { Banner } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import UserContext from 'contexts/UserContext';
import DownloadService from 'services/DownloadService';
import ServiceWorkerMessageHandler from '../../syndicate/notification-click-handlers/ServiceWorkerMessageHandler';
import './TabContainer.css';
const TabContainer = ({ children }) => {
    const { authorizedFetch } = React.useContext(UserContext);
    const [message, setMessage] = React.useState();
    const onClose = React.useCallback(() => setMessage(undefined), []);
    React.useEffect(() => {
        const abortController = new AbortController();
        const callback = (event) => {
            setMessage(event.detail);
        };
        document.addEventListener('mymessage', callback, { signal: abortController.signal });
        return () => abortController.abort();
    }, []);
    const downloadValue = React.useMemo(() => (new DownloadService({ authorizedFetch })), [authorizedFetch]);
    return (React.createElement(DownloadContext.Provider, { value: downloadValue },
        React.createElement(ServiceWorkerMessageHandler, null),
        React.createElement("div", { className: "base-box" },
            message !== undefined ? (React.createElement(Banner, { content: message?.content ?? 'no content', isClosable: true, onClose: onClose, show: true, variant: message?.variant })) : React.createElement("div", null),
            children)));
};
export default TabContainer;
