import ActionButton from 'components/custom-table/action/ActionButton';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import * as React from 'react';
import { printAddress, printTimestampString } from 'utils/table';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
import DocumentTextModal from './DocumentTextModal';
const EmailTable = ({ actionElements, setEmailId, setMessageId, setShowModal, }) => {
    const { apiFetchText } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [content, setContent] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [show, setShow] = React.useState(false);
    const clickView = React.useCallback(({ row }) => {
        setMessageId(row.original.messageId);
        setShowModal(true);
    }, [setMessageId, setShowModal]);
    const clickManager = React.useCallback(({ row }) => {
        setEmailId(row.original.messageId);
        localStorage.setItem('emailId', row.original.messageId);
        updateView('MANAGER');
    }, [setEmailId, updateView]);
    const clickFile = React.useCallback((index) => ({ row }) => {
        const file = row.original.files?.[index];
        if (file !== undefined) {
            // Create a new link
            const anchor = document.createElement('a');
            const url = new ApiServerURL('/syndicate/emails/attachment');
            url.searchParams.append('id', file.id);
            anchor.href = url.toString();
            anchor.download = file.name;
            // Append to the DOM
            document.body.appendChild(anchor);
            // Trigger `click` event
            anchor.click();
            // Remove element from DOM
            document.body.removeChild(anchor);
        }
    }, []);
    const openText = React.useCallback((id) => ({ row }) => {
        const file = row.original.files?.find((f) => f.id === id);
        if (file !== undefined) {
            (async () => {
                const url = new ApiServerURL('/syndicate/emails/text');
                url.searchParams.append('id', id);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchText(request);
                    setContent(body);
                    setTitle(file.name);
                    setShow(true);
                }
                catch (error) {
                    sendFetchError(`Unable to find attachment with id ${id}`, error, request);
                }
            })();
        }
    }, [apiFetchText]);
    const actionFunction = React.useCallback((context) => {
        const actionsMenu = [
            { callback: clickView, label: 'View' },
            { callback: clickManager, label: 'Manage' },
        ];
        context.row.original.files?.forEach((f, index) => actionsMenu.push({
            callback: clickFile(index), label: f.name,
        }, {
            callback: openText(f.id),
            label: 'as text',
        }));
        return (React.createElement(ActionButton, { context: context, actionsMenu: actionsMenu }));
    }, [clickFile, clickManager, clickView, openText]);
    const columns = React.useMemo(() => [
        {
            cell: actionFunction,
            id: 'actions',
            size: 28,
        },
        {
            accessorFn: (t) => t.subject ?? 'none',
            enableColumnFilter: true,
            header: 'Subject',
            id: 'subject',
            size: 9999,
        },
        {
            accessorFn: (t) => printAddress(t.from), enableColumnFilter: true, header: 'From', id: 'from', size: 9999,
        },
        {
            accessorFn: (t) => (t.files ? t.files.length : 'NONE'), enableColumnFilter: false, header: '# attachments', id: 'noAttachments', size: 28,
        },
        {
            accessorFn: (t) => printTimestampString(t.timestamp), enableColumnFilter: false, header: 'Last Update', id: 'timestamp', size: 200,
        },
        {
            accessorKey: 'zoneId',
            header: 'ZoneId',
            id: 'zoneId'
        },
        {
            accessorFn: (t) => t.errors?.join(", "), enableColumnFilter: true, header: 'Errors', id: 'errors'
        }
    ], [actionFunction]);
    //
    return (React.createElement(React.Fragment, null,
        show ?
            React.createElement(DocumentTextModal, { content: content, onClose: () => setShow(false), title: title }) : undefined,
        React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, errorMessage: "", emptyMessage: "", fetchSize: 50, name: "email", urlPage: "/syndicate/emails/all", urlTotal: "/syndicate/emails/total" })));
};
export default EmailTable;
