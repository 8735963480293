import * as React from 'react';
import TimestampDropdown from './TimestampDropdown';
const TimestampFilter = function TimestampFilterElement({ column, close }) {
    const [value, setValue] = React.useState(column.getFilterValue() !== undefined
        ? column.getFilterValue() : 'A');
    const onChange = React.useCallback((event) => {
        const newValue = event.target.value.value;
        column.setFilterValue(newValue !== 'A' ? newValue : undefined);
        setValue(newValue);
        close();
    }, [close, column]);
    return (React.createElement("div", { className: "co-check-panel" },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(TimestampDropdown, { menuIsOpen: true, value: value, onChange: onChange }))));
};
export default TimestampFilter;
