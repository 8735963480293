export const initialValidation = async (validators, data) => {
    const promises = Object.keys(validators).map((name) => (async () => {
        const value = data[name] || '';
        const validator = validators[name];
        let hasError;
        if (validator === null) {
            hasError = false;
        }
        else if (Array.isArray(validator)) {
            const validationResults = await Promise.all(validator.map((v) => v(value)));
            hasError = validationResults.some((r) => r !== null);
        }
        else {
            const validationResults = await validator(value);
            hasError = validationResults !== null;
        }
        return { hasError, name };
    })());
    const validationArray = await Promise.all(promises);
    const newErrorObject = {};
    validationArray.forEach((validationObject) => {
        newErrorObject[validationObject.name] = !validationObject.hasError;
    });
    return newErrorObject;
};
export const errorMessage = {
    email: 'Please enter a valid email address',
    maxLength: 'Name cannot be longer than 24 characters',
    minLength: 'Input is too short',
    number: 'Please input a number',
    passwordLength: 'between 8 and 16 characters',
    passwordLetters: 'one lower case and one upper case character',
    passwordNumbers: 'at least one number',
    pattern: 'This is not the right pattern',
    firstName: 'This input is required',
    Required: 'This input is required',
    required: 'This input is required',
};
