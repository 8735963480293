import * as React from 'react';
import { Button, Card, Icon, TextField, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
import ContentModal from '../../regexes/ContentModal';
import EmailTable from './EmailTable';
const EmailOverview = ({ setEmailId }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [messageId, setMessageId] = React.useState();
    const [email, setEmail] = React.useState();
    const [showContentModal, setShowContentModal] = React.useState(false);
    const [textId, setTextId] = React.useState('');
    const changeId = React.useCallback((event) => {
        setTextId(event.target.value);
    }, []);
    const clickId = React.useCallback(() => {
        setEmailId(textId);
        localStorage.setItem('emailId', textId);
        updateView('MANAGER');
    }, [setEmailId, updateView, textId]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (messageId) {
            const url = new ApiServerURL('/syndicate/emails');
            url.searchParams.append('messageId', messageId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setEmail(body);
                }
                catch (error) {
                    sendFetchError(`Unable to find email with id ${messageId}`, error, request);
                }
            })();
        }
        return () => abortController.abort();
    }, [apiFetchJson, messageId]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Emails"),
            React.createElement("div", null,
                React.createElement(Button, { onClick: () => updateView('CONTRIBUTORS'), size: "small" }, "CONTRIBUTORS"),
                React.createElement(Button, { onClick: () => updateView('STATS'), size: "small" }, "STATS"))),
        showContentModal && email !== undefined
            ? (React.createElement(ContentModal, { update: email, hideModal: () => setShowContentModal(false) })) : null,
        React.createElement(EmailTable, { actionElements: [
                (React.createElement(TextField, { onChange: changeId, placeholder: "email ID", value: textId })),
                (React.createElement(Button, { iconButton: true, onClick: clickId, size: "small", style: { marginLeft: '0.5rem', marginTop: '0.25rem' } },
                    React.createElement(Icon, { iconName: "search" })))
            ], setEmailId: setEmailId, setMessageId: setMessageId, setShowModal: setShowContentModal })));
};
export default EmailOverview;
