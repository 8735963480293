import * as React from 'react';
import { Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendError, sendFetchError } from 'utils/messageUtils';
import { Source, turnSecurityIntoClass } from '../../sources/sources';
import ResultsShare from '../../components/results/ResultsShareModal';
import SecuritiesTable from '../../components/results/SecuritiesTable';
import currencyDownload from '../../utils/currencyDownload';
import ResultsFilter from '../Filter';
import './Results.scss';
import ResultsPickerRow from '../../components/results/ResultsPickerRow';
import resultsDownload from './resultsDownload';
const Results = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [currencies, setCurrencies] = React.useState(new Map());
    const [filter, setFilter] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [filteredData, setFilteredData] = React.useState([]);
    const [result, setResult] = React.useState(() => {
        const saved = localStorage.getItem('result');
        return saved ? JSON.parse(saved) : undefined;
    });
    const [showFilter, setShowFilter] = React.useState(false);
    const [showResultsShare, setShowResultsShare] = React.useState(false);
    const [source, setSource] = React.useState();
    const createdOptions = React.useCallback((array) => {
        const frequencyCount = (allTags, tag) => {
            const newTags = { ...allTags };
            if (tag in allTags) {
                newTags[tag] += 1;
            }
            else {
                newTags[tag] = 1;
            }
            return newTags;
        };
        const countedNames = array.reduce(frequencyCount, {});
        return Object.entries(countedNames)
            .sort(([, v1], [, v2]) => v2 - v1)
            .map(([key, value]) => ({ label: `${key} [${value.toString()}]`, value: key }));
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setFilteredData([]);
        setLoading(true);
        (async () => {
            const url = new ApiServerURL('/nit/results/all');
            if (result) {
                url.searchParams.append('id', result.id);
                url.searchParams.append('source', result.source);
            }
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const { source: newSource, securities: newSecurities, filter: newFilter, } = resultsDownload(body);
                setSource(newSource);
                setFilter(newFilter);
                const securities = newSecurities.map(s => turnSecurityIntoClass(newSource, s));
                setFilteredData(securities);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to download results from previous searches', error, request);
                    setError(true);
                }
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson, result]);
    // }, [createdOptions, currencies, download, filterOptions, requestWithId]);
    const filterOptions = React.useMemo(() => {
        const tags = filteredData.flatMap((t) => t.tags);
        const tagOptions = createdOptions(tags);
        const fo = { tags: tagOptions };
        if (source === Source.EUROCLEAR) {
            fo.categories = createdOptions(filteredData.map((s) => s.issuercategory));
            fo.cfiGroups = createdOptions(filteredData.map((s) => (s.cfi ? s.cfi.group : '')));
            fo.countries = createdOptions(filteredData.map((s) => s.issuercountryname));
        }
        return fo;
    }, [createdOptions, source, filteredData]);
    /* Download the currency data from the ECB */
    React.useEffect(() => {
        (async () => {
            try {
                const parsedCurrencies = await currencyDownload();
                setCurrencies(parsedCurrencies);
            }
            catch (error) {
                sendError("Unable to download FX Rates from ECB", error);
            }
        })();
    }, []);
    return (React.createElement(Card, { className: 'co-card-main' },
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, { type: "h1" }, "Results")),
        React.createElement(ResultsPickerRow, { clickFilter: () => setShowFilter(!showFilter), clickShare: () => setShowResultsShare(true), result: result, setResult: setResult }),
        showResultsShare ?
            React.createElement(ResultsShare, { filter: filter, result: result, securities: filteredData, setShow: setShowResultsShare, source: source }) : undefined,
        showFilter
            ? React.createElement(ResultsFilter, { setFilter: setFilter, filter: filter, options: filterOptions }) : undefined,
        source !== undefined
            ? (React.createElement(SecuritiesTable, { currencies: currencies, filteredData: filteredData, hasError: hasError, isLoading: isLoading, source: source })) : ''));
};
export default Results;
