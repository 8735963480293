import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import UserContext from 'contexts/UserContext';
import CustomTable from 'components/custom-table/CustomTable';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import ActionButton from 'components/custom-table/action/ActionButton';
import { databaseOptions } from '../../sources/sources';
const SearchTable = ({ deleteFn, edit, hasError, isLoading, searches, }) => {
    const { checkEditable } = React.useContext(UserContext);
    const actionCell = React.useCallback((context) => {
        const isEditable = checkEditable(context.row.original);
        const actionsMenu = [
            { callback: edit, label: isEditable ? 'Open' : 'View' },
            { callback: deleteFn, label: 'Delete' }
        ];
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [checkEditable, deleteFn, edit]);
    const columns = React.useMemo(() => [
        {
            cell: actionCell,
            id: 'actions',
            size: 20,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 9999,
        },
        {
            accessorFn: (search) => search.getCurrency()?.join(', '),
            header: 'Currency',
            id: 'currency',
        },
        {
            accessorFn: (search) => search.getCreationDate()?.toString(),
            header: 'Code Creation Date',
            id: 'codecreationdate',
        },
        {
            accessorFn: (search) => search.getMaturity()?.toString(),
            header: 'Maturity',
            id: 'maturitydate',
        },
        {
            accessorFn: (search) => databaseOptions.find((o) => o.value === search.database())?.label,
            header: 'Database',
            id: 'database',
        },
    ], [actionCell]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { newView: "FORM", title: "Searches" }),
        React.createElement(CustomTable, { columns: columns, data: searches, emptyMessage: "You have no searches yet", errorMessage: "Error loading searches", hasError: hasError, isLoading: isLoading, name: "search" })));
};
export default SearchTable;
