import * as React from 'react';
import { Card, Radio, Typography } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import ThemeContext from 'contexts/ThemeContext';
const ChangeTheme = () => {
    const { colorScheme, setColorScheme } = React.useContext(ThemeContext);
    const onChangeName = React.useCallback((value) => () => {
        localStorage.setItem('colorScheme', value);
        setColorScheme(value);
    }, [setColorScheme]);
    return (React.createElement(Card, { className: "co-card-stack", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Theme"),
        React.createElement("div", { className: "co-info co-item" },
            React.createElement(Radio, { id: "1", label: "Light", name: "themename", onChange: onChangeName('light'), status: colorScheme === 'light' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: colorScheme === 'light' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "2", label: "Dark", name: "themename", onChange: onChangeName('dark'), status: colorScheme === 'dark' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: colorScheme === 'dark' ? 'true' : 'false' }),
            React.createElement(Radio, { id: "3", label: "Match Operating System", name: "themename", onChange: onChangeName('match'), status: colorScheme === 'match' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: colorScheme === 'match' ? 'true' : 'false' }))));
};
export default ChangeTheme;
