import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import Workflows from './workflows/Workflows';
import TermSheets from './term-sheets/TermSheets';
const MTNApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { auth: ['ADMIN'], code: 'workflows', label: 'Workflows' },
        { auth: ['ADMIN'], code: 'termsheets', label: 'Term Sheets' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'termsheets':
            return (React.createElement(TermSheets, null));
        default:
            return (React.createElement(Workflows, null));
    }
};
export default MTNApp;
