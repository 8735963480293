class Period {
    period;
    periodMultiplier;
    constructor({ period, periodMultiplier }) {
        this.period = period;
        this.periodMultiplier = periodMultiplier;
    }
    static multiplierRegex = /^(?<periodMultiplier>(0|-|-?[1-9][0-9]{0,1})?)$/u;
    static regex = /(?<period>[DWMY])/u;
    static from(value) {
        const regex = new RegExp(`${this.multiplierRegex.source}${this.regex.source}`, 'u');
        const match = value.match(regex);
        const period = match?.groups?.period;
        const periodMultiplierMatch = match?.groups?.periodMultiplier;
        const periodMultiplier = periodMultiplierMatch !== undefined ? Number.parseInt(periodMultiplierMatch, 10) : undefined;
        return period !== undefined && periodMultiplier !== undefined ? new Period({ period, periodMultiplier }) : undefined;
    }
    static parseMultiplier(value) {
        if (value === undefined || value === '-') {
            return undefined;
        }
        const match = value.match(Period.multiplierRegex);
        const periodMultiplierMatch = match?.groups?.periodMultiplier;
        return periodMultiplierMatch !== undefined ? Number.parseInt(periodMultiplierMatch, 10) : undefined;
    }
    toString() {
        if (this.periodMultiplier === -1 && this.period === 'D') {
            return 'yesterday';
        }
        if (this.periodMultiplier === 0 && this.period === 'D') {
            return 'today';
        }
        return `${this.periodMultiplier} ${this.period}`;
    }
}
export default Period;
