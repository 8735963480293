import { Button, Checkbox, Icon, TextEllipsis, } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import { printAddress } from 'utils/table';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import NavigationContext from 'contexts/NavigationContext';
import SyndicateContext from '../../../../../context/SyndicateContext';
import './SubRow.css';
const SubRow = ({ alternativeNames = [], checkUpdate, className, raw, setAlternativeName, setSelectedUpdate, setShowModal, transactionId, update, }) => {
    const { apiFetchNoContent, apiFetchText } = React.useContext(DownloadContext);
    const { setUpdateId } = React.useContext(SyndicateContext);
    const { updateTab } = React.useContext(NavigationContext);
    const click2 = React.useCallback(() => {
        const url = new ApiServerURL('/syndicate/emails/html');
        url.searchParams.append('messageId', update.emailId.id);
        url.searchParams.append('index', update.emailId.index.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const text = await apiFetchText(request);
                const checkedWithHtml = { ...update, html: text };
                setSelectedUpdate(checkedWithHtml);
                setShowModal(true);
            }
            catch (error) {
                sendFetchError('Unable to download HTML', error, request);
            }
        })();
    }, [apiFetchText, setSelectedUpdate, setShowModal, update]);
    const click3 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        const url = new ApiServerURL('/syndicate/update/force-event');
        (async () => {
            const request = new ServerRequest(url, { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await apiFetchNoContent(request);
            }
            catch (error) {
                sendFetchError('Unable to Force Update Event', error, request);
            }
        })();
    }, [apiFetchNoContent, update]);
    const click4 = React.useCallback(() => {
        const body = JSON.stringify(update.emailId);
        (async () => {
            const request = new ServerRequest('/syndicate/update/remove-event', { body, headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            try {
                await apiFetchNoContent(request);
            }
            catch (error) {
                sendFetchError('Unable to Remove Event', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, update]);
    const onChange = React.useCallback((emailId) => () => {
        checkUpdate(emailId);
    }, [checkUpdate]);
    const onClick = React.useCallback(() => {
        setUpdateId({ transactionId, index: update.index });
        updateTab('overview', 'UPDATE');
    }, [setUpdateId, transactionId, updateTab, update.index]);
    const subjectRef = React.useRef(null);
    const titleRef = React.useRef(null);
    const containsSelection = React.useCallback((parent, selection) => parent !== null && selection.type === 'Range' ? parent.contains(selection.anchorNode) && parent.contains(selection.focusNode) : false, []);
    const checkSelection = React.useCallback(() => {
        const selection = window.getSelection();
        if (selection !== null && (containsSelection(subjectRef.current, selection) || containsSelection(titleRef.current, selection))) {
            setAlternativeName(selection.toString().trim());
        }
    }, [containsSelection, setAlternativeName]);
    React.useEffect(() => {
        const subjectElement = subjectRef.current;
        if (subjectElement !== null) {
            subjectElement.addEventListener('mouseup', checkSelection);
        }
        return () => {
            if (subjectElement !== null) {
                subjectElement.removeEventListener('mouseup', checkSelection);
            }
        };
    }, [checkSelection]);
    React.useEffect(() => {
        const titleElement = titleRef.current;
        if (titleElement !== null) {
            titleElement.addEventListener('mouseup', checkSelection);
        }
        return () => {
            if (titleElement !== null) {
                titleElement.removeEventListener('mouseup', checkSelection);
            }
        };
    }, [checkSelection]);
    const createLocal = React.useCallback((text, frequency, level) => {
        const className = 'co-alt-name-' + level.toString();
        const elements = [];
        let flag = false;
        let startIndex = 0;
        for (let index = 0; index < text.length; index++) {
            const newFlag = frequency[index] !== 0;
            if (flag !== newFlag) {
                const fragment = text.substring(startIndex, index);
                if (flag) {
                    const localFrequencyMap = frequency.slice(startIndex, index).map(ia => ia - 1);
                    const localOne = createLocal(fragment, localFrequencyMap, level + 1);
                    elements.push(React.createElement("span", { className: className }, localOne));
                }
                else {
                    elements.push(fragment);
                }
                startIndex = index;
            }
            flag = newFlag;
        }
        const fragment = text.substring(startIndex, text.length);
        if (flag) {
            elements.push(React.createElement("span", { className: className }, fragment));
        }
        else {
            elements.push(fragment);
        }
        return elements;
    }, []);
    const highlightName = React.useCallback((text) => {
        const frequency = [];
        for (let i = 0; i < text.length; i++) {
            frequency[i] = 0;
        }
        alternativeNames.forEach(n => {
            const start = text.indexOf(n);
            if (start > -1) {
                for (let t = start; t < start + n.length; t++) {
                    frequency[t] = (frequency[t] ?? 0) + 1;
                }
            }
        });
        return createLocal(text, frequency, 0);
    }, [alternativeNames, createLocal]);
    const subjectText = React.useMemo(() => {
        const text = raw ? update.subjectRaw : update.subject;
        return highlightName(text);
    }, [highlightName, raw, update.subject, update.subjectRaw]);
    const titleText = React.useMemo(() => {
        const text = raw ? update.titleRaw : update.title;
        return text ? highlightName(text) : undefined;
    }, [highlightName, raw, update.title, update.titleRaw]);
    return (React.createElement("tr", { className: className },
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: onClick, variant: "primary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click2, variant: "secondary" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click3, variant: "tertiary" },
                React.createElement(Icon, { iconName: "symphony" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Button, { "aria-label": "plus", iconButton: true, onClick: click4, variant: "tertiary" },
                React.createElement(Icon, { iconName: "delete" }))),
        React.createElement("td", { className: "co-sub-button" },
            React.createElement(Checkbox, { "aria-label": "plus", key: `key${update.emailId.id}${update.emailId.index.toString()}`, name: "updateCheck", status: update.checked ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: "", onChange: onChange(update.emailId), label: "" })),
        React.createElement("td", { className: "co-sub-subject", ref: subjectRef },
            React.createElement(TextEllipsis, { tooltipOnEllipsis: true, tooltipProps: { type: "hint" } }, subjectText)),
        React.createElement("td", { className: "co-sub-title", ref: titleRef },
            React.createElement(TextEllipsis, { tooltipProps: { type: "hint" } }, titleText)),
        React.createElement("td", { className: "co-sub-from" },
            React.createElement(TextEllipsis, { tooltipProps: { type: "hint" } }, printAddress(update.from))),
        React.createElement("td", { className: "co-sub-timestamp" },
            React.createElement(TextEllipsis, { tooltipProps: { type: "hint" } }, update.timestamp.toLocaleString()))));
};
export default SubRow;
