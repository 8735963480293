import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import SectorPicker from './meta/SectorPicker';
import LeiEntry from './meta/LeiEntry';
import TickerEntry from './meta/TickerEntry';
const MetaModal = ({ hideModal, setTranche, tranche, updateTable, }) => (React.createElement(Modal, { closeButton: true, onClose: hideModal, show: true, size: "medium" },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, "Manage Meta Data")),
    React.createElement(ModalBody, null,
        React.createElement(LeiEntry, { setTranche: setTranche, tranche: tranche, updateTable: updateTable }),
        React.createElement(TickerEntry, { setTranche: setTranche, tranche: tranche, updateTable: updateTable }),
        React.createElement(SectorPicker, { label: "Pricing Date", setTranche: setTranche, tranche: tranche, type: "pricingDate", updateTable: updateTable, value: tranche.meta?.pricingDate ? tranche.meta.pricingDate.toLocaleDateString() : '' }),
        React.createElement(SectorPicker, { label: "Book Runners", setTranche: setTranche, tranche: tranche, type: "bookrunners", updateTable: updateTable, value: tranche.meta?.bookrunners?.map((b) => b.name).join(', ') ?? "" }))));
export default MetaModal;
