import { escapeRegex, unescapeRegex } from './regexUtils';
class Regex {
    markers;
    template;
    constructor(regexResponse) {
        this.markers = regexResponse.markers ?? [];
        this.template = regexResponse.template;
    }
    isEmpty() {
        return this.markers.length === 0 && this.template.length === 0;
    }
    safeTemplate() {
        return unescapeRegex(this.template.replace(/{}/g, '\u{25CF}'));
    }
    escaped() {
        return new Regex({ markers: this.markers, template: escapeRegex(this.template) });
    }
    getMarkers() {
        return this.markers;
    }
    getTemplate() {
        return this.template;
    }
    removeMarker(m, text) {
        let index = -1;
        for (let i = 0; i < m + 1; i += 1) {
            index = this.template.indexOf('{}', index + 1);
        }
        const newRegex = new Regex({
            markers: this.markers,
            template: this.template.substring(0, index) + text + this.template.substring(index + 2),
        });
        newRegex.markers.splice(m, 1);
        return newRegex;
    }
    updateValue(value, countPreceding, countIncluded) {
        const markersClone = [...this.markers];
        markersClone.splice(countPreceding, countIncluded, null);
        return new Regex({
            markers: markersClone,
            template: escapeRegex(value),
        });
    }
    updateMarker(m, value) {
        const newRegex = new Regex({
            markers: this.markers,
            template: this.template,
        });
        newRegex.markers[m] = value;
        return newRegex;
    }
}
export default Regex;
