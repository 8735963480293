/* eslint-disable react/no-danger */
import * as React from 'react';
import { Modal, ModalBody, ModalHeader, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import AmendedContentWrapper from '../pages/transactions/update-manager/AmendedContentWrapper';
const DetailsModal = ({ onClose, data: { title, messageId } }) => {
    const { apiFetchText: textPlus } = React.useContext(DownloadContext);
    const [content, setContent] = React.useState();
    const [hasContentError, setContentError] = React.useState(false);
    const [isContentLoading, setContentLoading] = React.useState(false);
    React.useEffect(() => {
        setContentLoading(true);
        const url = new ApiServerURL('/syndicate/transactions/content');
        url.searchParams.append('messageId', messageId.id);
        url.searchParams.append('index', messageId.index.toString());
        url.searchParams.append('type', 'true');
        url.searchParams.append('send', 'false');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await textPlus(request, 'Unable to download content');
                setContent(body);
            }
            catch (error) {
                setContentError(true);
            }
            setContentLoading(false);
        })();
    }, [textPlus, messageId.id, messageId.index]);
    return (React.createElement(Modal, { onClose: onClose, show: true, size: "full-width" },
        React.createElement(ModalHeader, null,
            React.createElement(Typography, { type: "h1" }, title)),
        React.createElement(ModalBody, null,
            React.createElement(AmendedContentWrapper, { content: content ?? '', hasError: hasContentError, isLoading: isContentLoading, isVisible: true, setRef: () => { } }))));
};
export default DetailsModal;
