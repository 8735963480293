import { BannerType, Button, Card, Loader, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
const SyndLEIManagement = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [numberInDatabase, setNumberInDatabase] = React.useState();
    const [lastUpdated, setLastUpdated] = React.useState();
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const [loadingNumber, setLoadingNumber] = React.useState(false);
    const [loadingDate, setLoadingDate] = React.useState(false);
    const onClick = React.useCallback(() => {
        const url = new ApiServerURL(`/gleif/admin/download-isin-lei`);
        const request = new ServerRequest(url, { method: 'POST' });
        (async () => {
            setLoadingUpload(true);
            try {
                const text = await apiFetchJson(request);
                sendMessage(text.text, BannerType.SUCCESS);
            }
            catch (error) {
                sendFetchError(`Unable to download ISINs to LEI`, error, request);
            }
            finally {
                setLoadingUpload(false);
            }
        })();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const request = new ServerRequest('/gleif/admin/number-isin-lei');
        (async () => {
            setLoadingNumber(true);
            try {
                const text = await apiFetchJson(request);
                setNumberInDatabase(text);
            }
            catch (error) {
                sendFetchError(`Unable to download number ISINs to LEI`, error, request);
            }
            finally {
                setLoadingNumber(false);
            }
        })();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const request = new ServerRequest('/gleif/admin/date-isin-lei');
        (async () => {
            setLoadingDate(true);
            try {
                const text = await apiFetchJson(request);
                if (text !== undefined) {
                    const date = new Date(text);
                    setLastUpdated(date);
                }
            }
            catch (error) {
                sendFetchError(`Unable to download ISIN LEI last updated`, error, request);
            }
            finally {
                setLoadingDate(false);
            }
        })();
    }, [apiFetchJson]);
    return (React.createElement(Card, { style: { height: 'min-content', marginBottom: '1rem', padding: '1rem' } },
        React.createElement(Typography, { type: "h1" }, "LEI to ISIN"),
        React.createElement("div", { style: { display: 'flex', marginTop: '0.5rem' } },
            React.createElement(Button, { loading: loadingUpload, onClick: onClick, style: { marginRight: '0.5rem' } }, "DOWNLOAD"),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' } },
                React.createElement("div", null,
                    "Number Currently in Database: ",
                    loadingNumber ? React.createElement(Loader, null) : (numberInDatabase !== undefined ? Intl.NumberFormat().format(numberInDatabase) : "")),
                React.createElement("div", null,
                    "Last Downloaded: ",
                    loadingDate ? React.createElement(Loader, null) : lastUpdated?.toLocaleDateString())))));
};
export default SyndLEIManagement;
