export const CustomValidatorMinLength = (length) => (event) => {
    const pass = event.length < length;
    return Promise.resolve({ minLength: pass });
};
const CustomValidators = {
    Email: (event) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log('TEST: %o', regex.test(event));
        return Promise.resolve({ email: !regex.test(event) });
    },
    Frequency: (event) => (event === null || event === '' ? Promise.resolve({ required: true }) : Promise.resolve(null)),
    Password: (event) => {
        if (event === '------') {
            return Promise.resolve(null);
        }
        const invalidLength = event === null || event.length < 8 || event.length > 17;
        const invalidLetters = !/[a-z]/.test(event) || !/[A-Z]/.test(event);
        const invalidNumbers = !/\d/.test(event);
        const invalid = invalidLength || invalidLetters || invalidNumbers;
        return invalid ? Promise.resolve({
            passwordLength: invalidLength,
            passwordLetters: invalidLetters,
            passwordNumbers: invalidNumbers,
        }) : Promise.resolve(null);
    },
    RequiredArray: (event) => (event === null || event.length === 0 ? Promise.resolve({ required: true }) : Promise.resolve(null)),
    RequiredFile: (event) => (event === null || event.length === 0 ? Promise.resolve({ required: true }) : Promise.resolve(null)),
    RequiredOption: (event) => (event === null || event.length === 0 ? Promise.resolve({ required: true }) : Promise.resolve(null)),
    Token: (event) => {
        const regex = /^\d{6}$/u;
        return regex.test(event) ? Promise.resolve(null) : Promise.resolve({ token: true });
    },
};
export default CustomValidators;
