import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { getOptionsMulti } from 'utils/change';
import DownloadContext from 'contexts/DownloadContext';
const MultiInput = ({ label, name, onChange, values, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    console.log("LABEL: %o", label);
    const asyncOptions = async (inputText) => {
        const url = new ApiServerURL('/nit/esma/options');
        url.searchParams.append('name', name);
        url.searchParams.append('q', inputText);
        url.searchParams.append('values', values.join(','));
        const request = new ServerRequest(url);
        const body = await apiFetchJson(request);
        setOptions(body);
        return body;
    };
    return (React.createElement(Dropdown, { asyncOptions: asyncOptions, label: label, isMultiSelect: true, onChange: onChange, value: getOptionsMulti(values, options) }));
};
export default MultiInput;
