class AnnaQuery {
    annaStatus;
    country;
    entityName;
    entityNameOpton1;
    entityNameOpton2;
    instrumentCategory;
    issueCurrency;
    issueDescription;
    issueDesOptions;
    isinType;
    isinValue;
    maturity;
    maturitydate;
    update;
    getCreationDate = () => this.update;
    getCurrency = () => this.issueCurrency;
    getMaturity = () => this.maturity;
}
export default AnnaQuery;
