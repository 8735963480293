import { Button, Dropdown, Icon, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
const FieldSelector = ({ onChangeDropdown, removeFieldData, testListItem, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const onClickAdd = React.useCallback(() => {
        const { fieldId, text } = testListItem;
        if (fieldId !== undefined) {
            const formData = new FormData();
            formData.append('id', fieldId);
            formData.append('text', text);
            const request = new ServerRequest('/syndicate/constant/add', { body: formData, method: 'POST' });
            (async () => {
                try {
                    await apiFetchJson(request);
                    removeFieldData();
                }
                catch (error) {
                    sendFetchError('Unable to save', error, request);
                }
            })();
        }
    }, [apiFetchJson, removeFieldData, testListItem]);
    const onClickNew = React.useCallback(() => {
        const { field, text } = testListItem;
        if (field !== undefined) {
            const body = {
                field: field,
                names: [text],
            };
            const request = new ServerRequest('/syndicate/constant', { body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            (async () => {
                try {
                    await apiFetchJson(request);
                }
                catch (error) {
                    sendFetchError('Unable to save', error, request);
                }
                removeFieldData();
            })();
        }
    }, [apiFetchJson, removeFieldData, testListItem]);
    return (React.createElement("div", { className: "co-field-wrapper" },
        React.createElement(Dropdown, { className: "co-field-selector", onChange: onChangeDropdown, options: testListItem.options ?? [], value: testListItem.options?.find((o) => ('value' in o && o.value === testListItem.fieldId)) }),
        React.createElement(Button, { className: "co-field-button", disabled: testListItem.fieldId == null, iconButton: true, onClick: onClickAdd, size: "small" },
            React.createElement(Icon, { iconName: "enter" })),
        React.createElement(Button, { className: "co-field-button", onClick: onClickNew, size: "small" }, "NEW")));
};
export default FieldSelector;
