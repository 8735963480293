import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import NavigationContext from 'contexts/NavigationContext';
const EmailStatsOverview = () => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [stats, setStats] = React.useState([]);
    React.useEffect(() => {
        const url = new ServerRequest('/syndicate/email-stats/all');
        (async () => {
            const body = await jsonPlus(url, 'Unable to download email stats');
            const week = (d) => {
                const onejan = new Date(d.getFullYear(), 0, 1);
                return Math.ceil((((d.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
            };
            const newStats = body.map((s) => ({ date: new Date(s.date), total: s.total }));
            const weeklyStatsList = [];
            newStats.forEach((s) => {
                const w = week(s.date);
                const weeklyStats = weeklyStatsList.find((ws) => ws.week === w);
                if (weeklyStats === undefined) {
                    const newWS = { week: w, values: [] };
                    newWS.values[s.date.getDay()] = s;
                    weeklyStatsList.push(newWS);
                }
                else {
                    weeklyStats.values[s.date.getDay()] = s;
                }
            });
            setStats(weeklyStatsList);
        })();
    }, [jsonPlus]);
    const days = React.useMemo(() => (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']), []);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Email Stats"),
            React.createElement("div", null,
                React.createElement(Button, { name: "overview", onClick: () => updateView('OVERVIEW') }, "OVERVIEW"))),
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Week No."),
                    days.map((d) => (React.createElement("th", { key: d }, d))))),
            React.createElement("tbody", { style: { textAlign: 'center' } }, stats.map((l) => (React.createElement("tr", { key: l.week },
                React.createElement("td", null, l.week),
                l.values.map((v, index) => (React.createElement("td", { key: l.week + days[index], title: v.date.toLocaleDateString() }, v.total))))))))));
};
export default EmailStatsOverview;
