import ApiServerURL from './ServerUrl';
class ServerRequest extends Request {
    constructor(path, init) {
        const input = path instanceof ApiServerURL ? path : new ApiServerURL(path);
        const updatedInit = (init ? { ...init } : {});
        if (window.jwt != null) {
            const newHeaders = { ...updatedInit.headers, Authentication: window.jwt };
            updatedInit.headers = newHeaders;
        }
        else {
            updatedInit.credentials = 'include';
        }
        super(input.toString(), updatedInit);
    }
}
export default ServerRequest;
