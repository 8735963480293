import * as React from 'react';
import ActionCell from 'components/custom-table/action/ActionCell';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import CustomTable from 'components/custom-table/CustomTable';
const ScheduleTable = ({ edit, hasError, isLoading, schedules = [], }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            enableColumnFilter: false,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                ],
            },
            size: 20,
        }, {
            accessorKey: 'name',
            enableColumnFilter: false,
            header: 'Name',
        }, {
            accessorKey: 'status',
            enableColumnFilter: false,
            header: 'Status',
        }, {
            accessorKey: 'timing.initialDelay',
            enableColumnFilter: false,
            header: 'Initial Delay',
        }, {
            accessorKey: 'timing.period',
            enableColumnFilter: false,
            header: 'Period',
        }, {
            accessorKey: 'timing.unit',
            enableColumnFilter: false,
            header: 'Unit',
        },
    ], [edit]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Schedules")),
        React.createElement(CustomTable, { columns: columns, data: schedules, emptyMessage: "You have no schedules yet", errorMessage: "Error downloading schedules", hasError: hasError, isLoading: isLoading, name: "user" })));
};
export default ScheduleTable;
