import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import CompanyFormModal from './CompanyFormModal';
import TableCheckbox from '../../../syndicate/pages/transactions/transaction-viewer/transaction-table/TableCheckbox';
import { FiniteTableContext } from 'components/custom-table/TableContext';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import useMap from 'components/customHooksMap';
const CompanyTable = ({ deleteFn, hasError, isLoading, companies, setCompanies }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { selection, resetRowSelection, tableUpdateFn } = React.useContext(FiniteTableContext);
    const [showFormModal, setShowFormModal] = React.useState(false);
    const [company, setCompany] = React.useState();
    const [isLoadingMerge, setLoadingMerge] = React.useState(false);
    const mapCountry = useMap('/admin/map/country');
    const mapIndustry = useMap('/admin/map/industry');
    const mapSector = useMap('/admin/map/sector');
    const mapSubSector = useMap('/admin/map/sub-sector');
    const mapType = useMap('/admin/map/type');
    const edit = React.useCallback(({ row: { original: company } }) => {
        setCompany(company);
        setShowFormModal(true);
    }, []);
    const deleteCallback = React.useCallback(({ row: { original: company } }) => {
        (async () => deleteFn(company))();
    }, [deleteFn]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteCallback, label: 'Delete', type: 'error' },
                ],
            },
            size: 28
        },
        {
            cell: (TableCheckbox), header: () => null, id: 'companyCheckbox', size: 28,
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'shortName',
            header: 'Short Name',
            size: 60
        },
        {
            accessorFn: (company) => {
                return company.ultimateParentId !== undefined ? companies.find(c => c.id === company.ultimateParentId)?.name : '';
            },
            header: 'Ultimate Parent',
        },
        {
            accessorFn: (company) => company.tickers?.join(", "),
            header: 'Ticker(s)',
        },
        {
            accessorFn: (company) => company.countryId !== undefined ? mapCountry.get(company.countryId) : undefined,
            header: 'Country',
            id: 'country',
        },
        {
            accessorKey: 'lei',
            header: 'LEI',
        },
        {
            accessorFn: (company) => company.sectors.map(s => mapSector.get(s)).join(', '),
            header: 'Sectors',
            id: 'sectors',
        },
        {
            accessorFn: (company) => company.sector !== undefined ? mapSubSector.get(company.sector) : undefined,
            header: 'Sector',
            id: 'sector',
        },
        {
            accessorFn: (company) => company.industry ? mapIndustry.get(company.industry) : undefined,
            header: 'Industry',
            id: 'industry',
        },
        {
            accessorFn: (company) => company.types.map(c => mapType.get(c)).join(", "),
            header: 'Types',
            id: 'types',
        },
    ], [companies, deleteCallback, edit, mapIndustry, mapType, mapSector, mapSubSector, mapCountry]);
    const onCloseForm = React.useCallback(() => {
        setShowFormModal(false);
    }, []);
    const onClickCreate = React.useCallback(() => {
        setCompany(undefined);
        setShowFormModal(true);
    }, []);
    const saveMerge = React.useCallback(async () => {
        if (resetRowSelection !== undefined && selection !== undefined && selection.length > 1) {
            const idSelections = selection.map((t) => t.id).filter(i => i !== undefined);
            const idSelection = idSelections.join(',');
            resetRowSelection();
            setLoadingMerge(true);
            const formData = new FormData();
            formData.append('ids', idSelection);
            const request = new ServerRequest('/admin/companies/merge', { body: formData, method: 'POST' });
            try {
                const responseConstant = await apiFetchJson(request);
                if (tableUpdateFn !== undefined) {
                    const updateFn = (values) => {
                        return values.filter(v => v.id === responseConstant.id || idSelections.every((i => v.id !== i)));
                    };
                    tableUpdateFn(updateFn);
                }
                resetRowSelection();
            }
            catch (error) {
                sendFetchError('Unable to merge companies', error, request);
                //
            }
            finally {
                setLoadingMerge(false);
            }
        }
    }, [apiFetchJson, resetRowSelection, selection, tableUpdateFn]);
    const callback = React.useCallback((updatedCompany) => {
        if (tableUpdateFn !== undefined) {
            const updateFn = (values) => {
                const index = values.findIndex(d => d.id === updatedCompany.id);
                const updatedCompanies = [...values];
                if (index === -1) {
                    updatedCompanies.push(updatedCompany);
                }
                else {
                    updatedCompanies[index] = updatedCompany;
                }
                return updatedCompanies;
            };
            tableUpdateFn(updateFn);
        }
    }, [tableUpdateFn]);
    const disabledMerge = React.useMemo(() => selection !== undefined && selection.length < 2, [selection]);
    const onClickMerge = React.useCallback(() => {
        console.log("Selection: %o", selection);
        if (selection !== undefined && selection.length > 1) {
            (async () => await saveMerge())();
        }
    }, [saveMerge, selection]);
    const actionElements = React.useMemo(() => {
        return [
            React.createElement(Button, { disabled: hasError, key: "create", onClick: onClickCreate, size: "small", style: { marginLeft: '0.5rem' } }, "CREATE"),
            React.createElement(Button, { disabled: disabledMerge, key: "merge", loading: isLoadingMerge, onClick: onClickMerge, size: "small", style: { marginLeft: '0.5rem' } }, "MERGE")
        ];
    }, [disabledMerge, isLoadingMerge, onClickCreate, onClickMerge, hasError]);
    return (React.createElement(Card, { style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Companies")),
        showFormModal ?
            React.createElement(CompanyFormModal, { onClose: onCloseForm, company: company, callback: callback }) : undefined,
        React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: companies, emptyMessage: "You have no companies yet", errorMessage: "Error downloading companies", hasError: hasError, isLoading: isLoading, name: "company", setData: setCompanies })));
};
export default CompanyTable;
