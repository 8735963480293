var _a;
class Party {
    id;
    name;
    names;
    podId;
    shortName;
    constructor(response) {
        this.id = response.id;
        this.name = response.name;
        this.names = response.names;
        this.podId = response.podId;
        this.shortName = response.shortName;
    }
    #clone() {
        return new _a({
            id: this.id,
            name: this.name,
            names: this.names,
            podId: this.podId,
            shortName: this.shortName
        });
    }
    updateStringField(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
    updateStringArrayField(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
    updateNumberField(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
}
_a = Party;
export default Party;
