import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import './transaction-table/TransactionMetaModal.css';
import TransactionMetaForm from './TransactionMetaForm';
const TransactionMergeModal = ({ onClose, save }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { selection } = React.useContext(TableContext);
    const [isLoading, setLoading] = React.useState(false);
    // const [sector, setSector] = React.useState<Sector | undefined>();
    // const [region, setRegion] = React.useState<Region | undefined>();
    // const [types, setTypes] = React.useState<TypeNew[] | undefined>();
    // const [companyName, setCompanyName] = React.useState<string>('');
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [transactionMeta, setTransactionMeta] = React.useState({});
    const [initialTransactionMeta, setInitialTransactionMeta] = React.useState({});
    const onClickSave = React.useCallback(() => {
        const { sector, regions, types, companyName, } = transactionMeta;
        if (sector !== undefined && regions !== undefined && types !== undefined && companyName !== undefined && companyName.length > 2) {
            (async () => {
                setLoading(true);
                await save(sector, regions, types, companyName);
                setLoading(false);
                onClose();
            })();
        }
    }, [onClose, save, transactionMeta]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sectors');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setSectorOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download Sector options', error, request);
            }
        })();
        return () => { abortController.abort(); };
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'regions');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setRegionOptions(body);
            }
            catch (error) {
                sendFetchError("Unable to get map", error, request);
            }
        })();
        return () => { abortController.abort(); };
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'types');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setTypeOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download type options', error, request);
            }
        })();
        return () => { abortController.abort(); };
    }, [apiFetchJson]);
    React.useEffect(() => {
        const newInitialTransactionmeta = {};
        const initialSectorValues = [...new Set(selection?.map((t) => t.sector).filter((s) => s !== undefined))];
        if (initialSectorValues.length === 1) {
            [newInitialTransactionmeta.sector] = initialSectorValues;
        }
        const initialCompanyValues = [...new Set(selection?.map((t) => t.company?.name).filter((s) => s !== undefined))];
        if (initialCompanyValues.length === 1) {
            [newInitialTransactionmeta.companyName] = initialCompanyValues;
        }
        const initialRegionsValues = selection?.map((t) => t.regions).filter((s) => s !== undefined);
        if (initialRegionsValues !== undefined) {
            const joinedUpNames = [...new Set(initialRegionsValues.map((v) => v.sort().join('')))];
            if (joinedUpNames.length === 1) {
                [newInitialTransactionmeta.regions] = initialRegionsValues;
            }
        }
        const initialTypesValues = selection?.map((t) => t.types).filter((s) => s !== undefined);
        if (initialTypesValues !== undefined) {
            const joinedUpNames = [...new Set(initialTypesValues.map((v) => v.sort().join('')))];
            if (joinedUpNames.length === 1) {
                [newInitialTransactionmeta.types] = initialTypesValues;
            }
        }
        setInitialTransactionMeta(newInitialTransactionmeta);
    }, [selection]);
    const isDisabled = React.useMemo(() => transactionMeta.sector === undefined || transactionMeta.regions === undefined || transactionMeta.types === undefined || transactionMeta.types.length === 0 || transactionMeta.companyName === undefined || transactionMeta.companyName.length < 3, [transactionMeta]);
    const onClickButton = React.useCallback((type, value) => () => {
        const updatedInitialTransactionMeta = { ...initialTransactionMeta };
        switch (type) {
            case 'SECTOR':
                updatedInitialTransactionMeta.sector = value;
                break;
            case 'REGION':
                updatedInitialTransactionMeta.regions = value;
                break;
            case 'COMPANY':
                updatedInitialTransactionMeta.companyName = value;
                break;
            case 'TYPES':
                updatedInitialTransactionMeta.types = value;
                break;
            default:
        }
        setInitialTransactionMeta(updatedInitialTransactionMeta);
    }, [initialTransactionMeta]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Meta data merged transaction")),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-merge" },
                React.createElement("div", { className: "cmo-sector cmo-header" }, "Sector"),
                React.createElement("div", { className: "cmo-region cmo-header" }, "Region"),
                React.createElement("div", { className: "cmo-types1 cmo-header" }, "Types"),
                React.createElement("div", { className: "cmo-company cmo-header" }, "Company"),
                selection?.map((t) => (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "cmo-meta-text", key: `${t.id}n` }, t.name),
                    t.sector !== undefined ? React.createElement(Button, { className: "cmo-sector cmo-button", onClick: onClickButton('SECTOR', t.sector), size: "small", variant: "secondary" }, sectorOptions.find((o) => 'value' in o && t.sector === o.value)?.label) : undefined,
                    t.regions !== undefined && t.regions.length > 0 ? React.createElement(Button, { className: "cmo-region cmo-button", onClick: onClickButton('REGION', t.regions), size: "small", variant: "secondary" }, regionOptions.filter((o) => 'value' in o && t.regions?.includes(o.value)).map(o => o.label).join(", ")) : undefined,
                    t.types !== undefined && t.types.length > 0 ? React.createElement(Button, { className: "cmo-types1 cmo-button", onClick: onClickButton('TYPES', t.types), size: "small", variant: "secondary" }, typeOptions.filter((o) => 'value' in o && t.types?.includes(o.value)).map((o) => o.label).join(', ')) : undefined,
                    t.company?.name !== undefined ? React.createElement(Button, { className: "cmo-company cmo-button", onClick: onClickButton('COMPANY', t.company.name), size: "small", variant: "secondary" }, t.company.name) : undefined))),
                React.createElement(TransactionMetaForm, { isLoading: isLoading, regionOptions: regionOptions, sectorOptions: sectorOptions, typeOptions: typeOptions, initialTransactionMeta: initialTransactionMeta, setTransactionMeta: setTransactionMeta }),
                React.createElement("div", { style: { height: '3.5rem' } }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, loading: isLoading, onClick: onClickSave }, "SAVE"))));
};
export default TransactionMergeModal;
