import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoSuchTab from 'components/NoSuchTab';
import Releases from './pages/Releases';
const MacroApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { auth: ['ADMIN'], code: 'releases', label: 'Releases' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'releases':
            return (React.createElement(Releases, null));
        default:
            return (React.createElement(NoSuchTab, { tab: tab }));
    }
};
export default MacroApp;
