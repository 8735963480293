import * as React from 'react';
import { DatePicker } from '@symphony-ui/uitoolkit-components';
import DateRange from '../models/DateRange';
import { datePattern } from 'components/custom-table/dateFormat';
const DateRangeInput = ({ disabled, name, onChange, value = new DateRange({ start: undefined, end: undefined }), }) => {
    const onChangeDate = React.useCallback((dateName) => (event) => {
        const { value: newValue } = event.target;
        const newData = dateName === 'start' ? { start: newValue, end: value.end } : { start: value.start, end: newValue };
        const newObject = {
            target: {
                value: new DateRange(newData),
            },
        };
        onChange(newObject);
    }, [onChange, value.end, value.start]);
    return (React.createElement("div", { className: "co-range" },
        React.createElement(DatePicker, { className: "date", date: value.start, disabled: disabled, format: datePattern(), name: `${name}start`, onChange: onChangeDate('start') }),
        React.createElement(DatePicker, { className: "date", date: value.end, disabled: disabled, format: datePattern(), name: `${name}end`, onChange: onChangeDate('end') })));
};
export default DateRangeInput;
