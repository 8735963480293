export function createGridFromAccessorFns(accessorFns, data, idFunction) {
    const grid = data.map((d, index) => {
        const row = [];
        accessorFns.forEach((a) => {
            const value = a(d, 0);
            row.push(value);
        });
        return { data: row, id: idFunction ? idFunction(d) : index.toString() };
    });
    return grid;
}
export function createGrid(headers, data, idFunction) {
    const accessorFns = headers.filter((h) => h?.column.accessorFn !== undefined && typeof h.column.accessorFn === 'function').map((h) => h.column.accessorFn).filter((a) => a !== undefined);
    return createGridFromAccessorFns(accessorFns, data, idFunction);
}
export function createHeadersFromColumns(columns) {
    const row = [];
    columns.forEach((columnDef) => {
        const name = columnDef.header ?? columnDef.id;
        const info = { header: name?.toString() ?? 'UNKNOWN', meta: columnDef.meta, size: columnDef.size };
        row.push(info);
    });
    return row;
}
export function createHeaders(headers) {
    const columns = headers.filter((h) => h.column.accessorFn !== undefined).map((h) => h.column.columnDef);
    return createHeadersFromColumns(columns);
}
