import * as React from 'react';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import LEIPicker from './LEIPicker';
const LeiEntry = ({ setTranche, tranche, updateTable }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [leiVisible, setLeiVisible] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const name = React.useMemo(() => {
        const overridden = tranche.overrideMap?.issuer;
        if (overridden !== undefined) {
            return overridden;
        }
        return tranche.data?.issuer?.name ?? "";
    }, [tranche.data?.issuer?.name, tranche.overrideMap?.issuer]);
    const onClickLei = React.useCallback(() => {
        setLeiVisible(!leiVisible);
    }, [leiVisible, setLeiVisible]);
    const saveLei = React.useCallback((lei2) => {
        const formData = new FormData();
        formData.append('lei', lei2);
        formData.append('transactionId', tranche.transaction.id);
        formData.append('trancheIndex', tranche.trancheIndex.toString());
        const request = new ServerRequest('/syndicate/meta/lei', { method: 'POST', body: formData });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const udpatedTranches = body.map((t) => new TrancheWrapper(t));
                setTranche(udpatedTranches[tranche.trancheIndex]);
                updateTable(udpatedTranches);
                setLeiVisible(false);
            }
            catch (error) {
                sendFetchError('Unable to save LEI', error, request);
            }
        })();
    }, [apiFetchJson, setTranche, tranche.trancheIndex, tranche.transaction.id, updateTable]);
    const icon = React.useMemo(() => {
        if (isLoading) {
            return 'loading';
        }
        return leiVisible ? 'more-horizontal' : 'more';
    }, [isLoading, leiVisible]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement("div", { className: "tk-label" }, "LEI"),
                React.createElement("div", null, tranche.meta?.lei)),
            React.createElement(Button, { iconButton: true, onClick: onClickLei, size: "small" },
                React.createElement(Icon, { iconName: icon }))),
        leiVisible ? React.createElement(LEIPicker, { name: name, saveLei: saveLei, setLoading: setLoading }) : undefined));
};
export default LeiEntry;
