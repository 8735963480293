import * as React from 'react';
import { Loader } from '@symphony-ui/uitoolkit-components';
import TickIcon from 'components/tick-icon/TickIcon';
const SyndInfoValue = ({ isLoading, value }) => {
    if (isLoading) {
        return (React.createElement(Loader, null));
    }
    if (value === undefined) {
        return React.createElement("div", null, "N/A");
    }
    if (typeof value === 'boolean') {
        return (React.createElement(TickIcon, { value: value }));
    }
    return React.createElement("div", null, value);
};
export default SyndInfoValue;
