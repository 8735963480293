import { Modal, ModalBody, ModalHeader, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const IFRStoryModal = ({ onClose, story }) => {
    const markupContent = React.useMemo(() => {
        const wholeText = story.summary ?? story.mainArticleTxt;
        if (wholeText !== undefined) {
            if (story.data.assets.includes('bonds')) {
                const regex = /PRICED:|LAUNCH:|PRICE TALK:|\([0-9]{1,2}:[0-9]{2}[ap]m\)/g;
                const plainText = wholeText.split(regex);
                const list = [];
                const files = [...wholeText.matchAll(regex)].map((a) => a[0]);
                for (let i = 0; i < files.length; i += 1) {
                    const cleanedText = plainText[i].replaceAll(/------------------------- LONDON, /g, '').replaceAll(/\(IFR\)/g, '');
                    list.push((React.createElement("p", null, cleanedText)));
                    list.push((React.createElement("h4", null, files[i])));
                }
                list.push((React.createElement("p", null, plainText[plainText.length - 1])));
                return list;
            }
            return [(React.createElement("p", null, wholeText))];
        }
        return [(React.createElement("p", null, "Neither summary nor main article text found"))];
    }, [story.data.assets, story.mainArticleTxt, story.summary]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Typography, { type: "h2" }, story.data.title),
                story.info?.date !== undefined ? new Date(story.info?.date).toLocaleTimeString() : '')),
        React.createElement(ModalBody, null, markupContent)));
};
export default IFRStoryModal;
