import ActionButton from 'components/custom-table/action/ActionButton';
import * as React from 'react';
const ActionCell = function ActionCellElement(context) {
    // eslint-disable-next-line react/destructuring-assignment
    const columnMeta = context.column.columnDef.meta;
    if (columnMeta?.actionsMenu !== undefined) {
        return (React.createElement(ActionButton, { context: context, actionsMenu: columnMeta.actionsMenu }));
    }
    return undefined;
};
export default ActionCell;
