import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import TickerPicker from './TickerPicker';
const TickerEntry = ({ setTranche, tranche, updateTable }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [isLoading, setLoading] = React.useState(false);
    const [tickers, setTickers] = React.useState();
    const lei = React.useMemo(() => tranche.overrideMap?.lei ?? tranche.meta.lei, [tranche.meta?.lei, tranche.overrideMap?.lei]);
    const saveTicker = React.useCallback((ticker) => {
        const formData = new FormData();
        formData.append('ticker', ticker);
        formData.append('transactionId', tranche.transaction.id);
        formData.append('trancheIndex', tranche.trancheIndex.toString());
        const request = new ServerRequest('/syndicate/meta/ticker', { method: 'POST', body: formData });
        (async () => {
            const body = await jsonPlus(request, 'Unable to save Ticker');
            const newTranches = body.map((t) => new TrancheWrapper(t));
            setTranche(newTranches[tranche.trancheIndex]);
            updateTable(newTranches);
            setTickers(undefined);
        })();
    }, [jsonPlus, setTranche, tranche.trancheIndex, tranche.transaction.id, updateTable]);
    const onClickTicker = React.useCallback(() => {
        if (lei !== undefined) {
            const url = new ApiServerURL('/syndicate/meta/ticker');
            url.searchParams.append('lei', lei);
            const request = new ServerRequest(url);
            (async () => {
                setLoading(true);
                const body = await jsonPlus(request, 'unable to download tickers');
                if (body.length === 1) {
                    saveTicker(body[0]);
                }
                else {
                    setTickers(body);
                }
                setLoading(false);
            })();
        }
    }, [jsonPlus, lei, saveTicker]);
    return (React.createElement("div", null,
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement("div", { className: "tk-label" }, "Ticker"),
                tickers === undefined ? (React.createElement("div", null, tranche.meta.ticker)) : undefined,
                tickers !== undefined && tickers.length === 0 ? (React.createElement("div", { style: { fontStyle: 'italic', paddingLeft: '0.5rem', color: '#dd342e' } }, "None found for LEI")) : undefined),
            React.createElement(Button, { iconButton: true, loading: isLoading, onClick: onClickTicker, size: "small" },
                React.createElement(Icon, { iconName: "reload" }))),
        tickers !== undefined && tickers.length > 1
            ? React.createElement(TickerPicker, { tickers: tickers, saveTicker: saveTicker }) : undefined));
};
export default TickerEntry;
