import DateRange from './DateRange';
import PeriodRange from './PeriodRange';
/**
 * Object consisting of an absolute range (DateRange) and a relative range (PeriodRange)
 */
class TimeRange {
    absolute;
    relative;
    constructor({ absolute, relative }) {
        if (absolute !== undefined) {
            this.absolute = new DateRange(absolute);
        }
        if (relative !== undefined) {
            this.relative = new PeriodRange(relative);
        }
    }
    toString() {
        if (this.absolute && ('start' in this.absolute || 'end' in this.absolute)) {
            return this.absolute.toString();
        }
        if (this.relative && ('start' in this.relative || 'end' in this.relative)) {
            return this.relative.toString();
        }
        return '';
    }
    static isEmpty(timeRange) {
        if (timeRange === undefined) {
            return true;
        }
        return timeRange.absolute === undefined && timeRange.relative === undefined;
    }
    static from(rangeValues) {
        const absolute = DateRange.from(rangeValues[0], rangeValues[1]);
        const relative = PeriodRange.from(rangeValues[2], rangeValues[3]);
        const newTimeRange = { absolute, relative };
        return !TimeRange.isEmpty(newTimeRange) ? new TimeRange(newTimeRange) : undefined;
    }
    static #printDate(date) {
        console.log("Date: %o", date);
        return date.toISOString();
    }
    toStringArray() {
        return [
            this.absolute?.start !== undefined ? TimeRange.#printDate(this.absolute.start) : '',
            this.absolute?.end !== undefined ? TimeRange.#printDate(this.absolute.end) : '',
            this.relative?.start !== undefined ? this.relative.start.print() : '',
            this.relative?.end !== undefined ? this.relative.end.print() : ''
        ];
    }
}
export default TimeRange;
