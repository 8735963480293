const sort = (u0, u1) => u1.timestamp.getTime() - u0.timestamp.getTime();
const sortDescending = (u0, u1) => u0.timestamp.getTime() - u1.timestamp.getTime();
class UpdatesWrapper {
    updates;
    constructor(updateMeta) {
        const withCheckedFalse = updateMeta.map((d, index) => ({
            emailId: d.emailId,
            originalEmailId: d.originalEmailId,
            from: d.from,
            subject: d.subject,
            subjectRaw: d.subjectRaw,
            title: d.title,
            titleRaw: d.titleRaw,
            timestamp: new Date(d.timestamp),
            checked: false,
            index,
        }));
        this.updates = withCheckedFalse;
    }
    checkedUpdates(checkedStatus) {
        return this.updates.filter((u) => u.checked === (checkedStatus ?? true));
    }
    numberOriginalChecked() {
        return this.updates.filter((u) => u.checked && u.originalEmailId === undefined).length;
    }
    numberCopyChecked() {
        return this.updates.filter((u) => u.checked && u.originalEmailId !== undefined).length;
    }
    nameUpdatesByCheckedStatus(checkedStatus) {
        const filteredUpdates = this.checkedUpdates(checkedStatus).sort(sort);
        const names = filteredUpdates.map((u) => u.title ?? u.subject).filter((n) => n.length > 0);
        const firstNonTrivial = names.find((n) => n.split(' ').length > 2);
        if (firstNonTrivial !== undefined) {
            return firstNonTrivial;
        }
        return names.length > 0 ? names[0] : 'UNKNOWN';
    }
    clone() {
        const metaResponses = this.updates.map((u) => ({
            emailId: u.emailId,
            originalEmailId: u.originalEmailId,
            from: u.from,
            subject: u.subject,
            subjectRaw: u.subjectRaw,
            title: u.title,
            titleRaw: u.titleRaw,
            timestamp: u.timestamp.toISOString(),
        }));
        const updated = new UpdatesWrapper(metaResponses);
        updated.updates = updated.updates.map((u, index) => ({ ...u, checked: this.updates[index].checked }));
        return updated;
    }
    check(emailId) {
        const clone = this.clone();
        const updateFound = clone.updates.find((u) => u.emailId === emailId);
        if (updateFound !== undefined) {
            updateFound.checked = !updateFound.checked;
        }
        return clone;
    }
    static #sort(u0, u1) { return u1.timestamp.getTime() - u0.timestamp.getTime(); }
    sortedUpdates() {
        return this.updates.sort(sort);
    }
    /**
     * We find the status of the latest update (i.e. checked or not) and find all the other updates with the same status.
     * @returns
     */
    newestSelection() {
        const lastUpdate = this.updates.sort(sort)[0];
        return this.updates.filter((u) => u.checked === lastUpdate?.checked);
    }
    groupByOriginal() {
        const originalUpdates = [...this.updates.filter((u) => u.originalEmailId === undefined).sort(sort)];
        const findCopies = (emailId) => this.updates
            .filter((u) => (u.originalEmailId?.index === emailId.index && u.originalEmailId?.id === emailId.id));
        return originalUpdates.flatMap((u) => [u].concat(findCopies(u.emailId)));
    }
    filterNewestOnly() {
        this.updates = this.newestSelection();
    }
}
export default UpdatesWrapper;
