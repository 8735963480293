import * as React from 'react';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import './ImageCard.css';
const ImageCard = ({ children, text, id, onClick, }) => {
    React.useEffect(() => {
        const element = document.getElementById(id);
        if (element !== null) {
            element.onclick = onClick;
        }
    }, [id, onClick]);
    return (React.createElement(Card, { className: "image-card", id: id },
        React.createElement("div", { className: "image-box" },
            children,
            React.createElement("div", { className: "title-box" },
                React.createElement(Typography, { className: "image-text", type: "h1" }, text)))));
};
export default ImageCard;
