class CompanyTag {
    companyId;
    id;
    source;
    tags;
    constructor(companyTagResponse) {
        this.companyId = companyTagResponse.companyId;
        this.id = companyTagResponse.id;
        this.source = companyTagResponse.source;
        this.tags = companyTagResponse.tags;
    }
    clone() {
        return new CompanyTag({
            companyId: this.companyId,
            id: this.id,
            source: this.source,
            tags: this.tags
        });
    }
    static fromCompany = (company, source) => {
        if (company.id === undefined) {
            return undefined;
        }
        return new CompanyTag({
            companyId: company.id,
            id: undefined,
            source,
            tags: []
        });
    };
}
export default CompanyTag;
