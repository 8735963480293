import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TabContainer from 'components/TabContainer';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import Settings from 'pages/settings/Settings';
import ConnectivityAlert from 'components/ConnectivityAlert';
import NavMenu from 'components/NavMenu';
import { App } from 'utils/appUtils';
import AdminApp from '../../admin/AdminApp';
import DevNotificationSettings from '../../admin/pages/settings/DevNotificationSettings';
import IpoApp from '../../ipo/IpoApp';
import MacroApp from '../../macro/MacroApp';
import MTNApp from '../../mtn/pages/MTNApp';
import NewIssueTrackerApp from '../../nit/pages/NewIssueTrackerApp';
import NITSettingsExport from '../../nit/pages/NITSettings';
import SyndicateApp from '../../syndicate/SyndicateApp';
import SyndNotificationSettings from '../../syndicate/pages/settings/SyndNotificationSettings';
import './PageWrapper.css';
const PageWrapper = () => {
    const { appView } = React.useContext(NavigationContext);
    const [showSettings, setShowSettings] = React.useState(false);
    const queryClient = React.useMemo(() => new QueryClient(), []);
    const settings = React.useMemo(() => {
        switch (appView) {
            case App.ADMIN:
                return React.createElement(DevNotificationSettings, null);
            case App.SYND:
                return React.createElement(SyndNotificationSettings, null);
            case App.NIT:
                return React.createElement(NITSettingsExport, null);
            default:
                return React.createElement("div", null);
        }
    }, [appView]);
    const content = React.useMemo(() => {
        switch (appView) {
            case App.ADMIN:
                return React.createElement(AdminApp, null);
            case App.IPO:
                return React.createElement(IpoApp, null);
            case App.MACRO:
                return React.createElement(MacroApp, null);
            case App.MTN:
                return React.createElement(MTNApp, null);
            case App.NIT:
                return React.createElement(NewIssueTrackerApp, null);
            case App.SYND:
                return React.createElement(SyndicateApp, null);
            default:
                return React.createElement("p", null, "Unknown App");
        }
    }, [appView]);
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement("div", { className: "co-page-wrapper" },
                React.createElement(ConnectivityAlert, null),
                React.createElement(NavMenu, { setShowSettings: setShowSettings }),
                appView !== undefined ? (React.createElement(TabContainer, null, !showSettings
                    ? content
                    : (React.createElement(Settings, { onClose: () => setShowSettings(false) }, settings)))) : (React.createElement(LoadingPage, null))))));
};
export default PageWrapper;
