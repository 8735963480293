import * as React from 'react';
import { BannerType, Button, Modal, ModalBody, ModalFooter, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendMessage } from 'utils/messageUtils';
import TransactionWrapper from '../../../models/TransactionWrapper';
import './transaction-table/TransactionMetaModal.css';
import TransactionMetaForm from './transaction-meta/TransactionMetaForm';
const TransactionSplitModal = ({ onClose, originalTransactionMeta, transactionId, updates, isLoading, setLoading, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const [transactionMetaChecked, setTransactionMetaChecked] = React.useState(() => {
        return { ...originalTransactionMeta };
    });
    const [transactionMetaUnchecked, setTransactionMetaUnchecked] = React.useState({});
    const checkEmpty = React.useCallback((meta) => meta.company?.type === undefined || meta.company.companyId === undefined || meta.sector === undefined || meta.regions === undefined || meta.regions.length === 0 || meta.types === undefined || meta.types.length === 0, []);
    const isDisabled = React.useMemo(() => checkEmpty(transactionMetaChecked) || checkEmpty(transactionMetaUnchecked), [checkEmpty, transactionMetaChecked, transactionMetaUnchecked]);
    /*
  This method throws an error that must be caught downstream
  */
    // const createServerRequest = React.useCallback(async (
    //   allMessageIds: MessageId[],
    // ): Promise<UpdateResponse> => {
    // }, [apiFetchJson, transactionId]);
    // const processTransactionAction = React.useCallback(async (): Promise<UpdateResponse | undefined> => {
    //   return body;
    // }, [createServerRequest, updates]);
    const processMessage = React.useCallback((message, error) => {
        if (message !== undefined) {
            sendMessage(message, BannerType.SUCCESS);
        }
        else if (error !== undefined) {
            sendMessage(error, BannerType.ERROR);
        }
    }, []);
    const saveNew = React.useCallback(() => {
        if (tableUpdateFn !== undefined) {
            setLoading(true);
            (async () => {
                const metaCheckedClone = { ...transactionMetaChecked };
                if (metaCheckedClone.company !== undefined) {
                    delete metaCheckedClone.company.company;
                    delete metaCheckedClone.company.ultimateParent;
                }
                const metaUncheckedClone = { ...transactionMetaUnchecked };
                if (metaUncheckedClone.company !== undefined) {
                    delete metaUncheckedClone.company.company;
                    delete metaUncheckedClone.company.ultimateParent;
                }
                const messageIdsChecked = updates.checkedUpdates(true).map((u) => u.emailId);
                const messageIdsUnchecked = updates.checkedUpdates(false).map((u) => u.emailId);
                const options = { type: 'application/json' };
                const formData = new FormData();
                formData.append('transactionId', transactionId);
                formData.append('messageIdsChecked', new Blob([JSON.stringify(messageIdsChecked)], options));
                formData.append('messageIdsUnchecked', new Blob([JSON.stringify(messageIdsUnchecked)], options));
                formData.append('metaChecked', new Blob([JSON.stringify(metaCheckedClone)], options));
                formData.append('metaUnchecked', new Blob([JSON.stringify(metaUncheckedClone)], options));
                const request = new ServerRequest('/syndicate/transactions/split', { body: formData, method: 'POST' });
                const body = await apiFetchJson(request);
                updates.filterNewestOnly();
                processMessage(body.message, body.error);
                const newTransaction = new TransactionWrapper(body.newTransaction);
                const oldTransaction = new TransactionWrapper(body.oldTransaction);
                const updateFn = (data) => {
                    const updated = {
                        pageParams: data.pageParams,
                        pages: data.pages.map((p) => [...p]),
                    };
                    /*
                    * First update the newest transaction, which is the one that stays the same so is called 'old transaction'
                    */
                    let pageNumberOld = -1;
                    let indexOld = -1;
                    while (indexOld === -1 && pageNumberOld) {
                        pageNumberOld += 1;
                        indexOld = data.pages[pageNumberOld].findIndex((d) => d.id === oldTransaction.id);
                    }
                    if (indexOld > -1) {
                        updated.pages[pageNumberOld].splice(indexOld, 1, oldTransaction);
                    }
                    /*
                    * Now include the new Transaction
                    */
                    const { lastUpdate } = newTransaction;
                    let indexNewer = -1;
                    const flatData = data.pages.flatMap((p) => p);
                    // const isDescending = flatData[0].lastUpdate > flatData[flatData.length - 1].lastUpdate;
                    while (indexNewer + 1 < flatData.length && flatData[indexNewer + 1].lastUpdate.getTime() > lastUpdate.getTime()) {
                        indexNewer += 1;
                    }
                    let pageNumber;
                    let index;
                    if (indexNewer === -1) {
                        pageNumber = 0;
                        index = 0;
                    }
                    else {
                        pageNumber = -1;
                        index = -1;
                        while (index === -1) {
                            pageNumber += 1;
                            index = updated.pages[pageNumber].findIndex((d) => d.id === flatData[indexNewer].id);
                        }
                        index += 1;
                    }
                    updated.pages[pageNumber].splice(index, 0, newTransaction);
                    return updated;
                };
                tableUpdateFn(updateFn);
                setLoading(false);
            })();
        }
    }, [apiFetchJson, transactionId, transactionMetaChecked, transactionMetaUnchecked, tableUpdateFn, updates, processMessage, setLoading]);
    const onClickCopyA = React.useCallback(() => {
        setTransactionMetaChecked(originalTransactionMeta);
    }, [originalTransactionMeta]);
    const onClickCopyB = React.useCallback(() => {
        setTransactionMetaUnchecked(originalTransactionMeta);
    }, [originalTransactionMeta]);
    const onClickSave = React.useCallback(() => {
        setLoading(true);
        saveNew();
        setLoading(false);
        onClose();
    }, [onClose, saveNew, setLoading]);
    const onKeyUpSave = React.useCallback((event) => {
        if (event.code === 'Enter') {
            setLoading(true);
            saveNew();
            setLoading(false);
            onClose();
        }
    }, [onClose, saveNew, setLoading]);
    const firstUpdateA = React.useMemo(() => updates.nameUpdatesByCheckedStatus(true), [updates]);
    const firstUpdateB = React.useMemo(() => updates.nameUpdatesByCheckedStatus(false), [updates]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalTitle, null, "Meta data split transactions"),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-split" },
                React.createElement("div", { className: "cmo-name" }, firstUpdateA),
                React.createElement(Button, { className: "cmo-button", onClick: onClickCopyA }, "Copy"),
                React.createElement(TransactionMetaForm, { isLoading: isLoading, transactionMeta: transactionMetaChecked, setTransactionMeta: setTransactionMetaChecked }),
                React.createElement("div", { className: "cmo-name" }, firstUpdateB),
                React.createElement(Button, { className: "cmo-button", onClick: onClickCopyB }, "Copy"),
                React.createElement(TransactionMetaForm, { isLoading: isLoading, transactionMeta: transactionMetaUnchecked, setTransactionMeta: setTransactionMetaUnchecked }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, loading: isLoading, onClick: onClickSave, onKeyUp: onKeyUpSave }, "SAVE"))));
};
export default TransactionSplitModal;
