import { unescapeRegex } from './regexUtils';
import SingleItemRegex from './SingleItemRegex';
import IndexedRegex from './IndexedRegex';
class ItemRegex extends SingleItemRegex {
    id;
    index;
    constructor(value) {
        super(value);
        this.id = value.id;
        this.index = value.index;
    }
    static create(regex) {
        return new ItemRegex(regex);
    }
    static createFromRegex(regex) {
        return new ItemRegex({ regex });
    }
    static createFromIndexedRegex(indexedRegex) {
        return new ItemRegex({ id: indexedRegex.id, index: indexedRegex.index, regex: { markers: indexedRegex.markers, template: indexedRegex.template } });
    }
    add(regex) {
        if (this.regex.isEmpty()) {
            this.regex = regex;
        }
        else {
            const singleItemRegex = new SingleItemRegex({ regex });
            if (typeof this.subregexes === 'undefined') {
                this.subregexes = [];
            }
            this.subregexes.push(singleItemRegex);
        }
    }
    getMarkers() {
        return this.regex.markers;
    }
    getTemplate() {
        return this.regex.template;
    }
    safeTemplate() {
        return unescapeRegex(this.regex.template.replace(/{}/g, '\u{25CF}'));
    }
    toIndexedRegex() {
        if (this.id === undefined || this.index === undefined) {
            throw new Error('Unable to create indexed Regex without an id or index in the ItemRegex');
        }
        return new IndexedRegex({
            id: this.id, index: this.index, markers: this.regex.markers, template: this.regex.template,
        });
    }
}
export default ItemRegex;
