import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import TransactionWrapper from '../../../models/TransactionWrapper';
import EditableCell from '../overview/table/EditableCell';
const TransactionCellDropdownMultiple = ({ table, row, column, cell, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [content, setContent] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [values, setvalues] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [errorText, setErrorText] = React.useState();
    const [className, setClassName] = React.useState('');
    const save = React.useCallback(async (newValues) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/meta-multi-update');
            url.searchParams.append('transactionId', row.original.id);
            url.searchParams.append('key', column.id);
            if (newValues !== undefined) {
                url.searchParams.append('values', newValues.join(','));
            }
            const request = new ServerRequest(url, { method: newValues !== undefined ? 'PATCH' : 'DELETE' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.id === row.original.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            setDisabled(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const transaction = new TransactionWrapper(body);
                table.options.meta.updateTable(transaction);
                setvalues(body[column.id]);
            }
            catch (error) {
                setErrorText(`Unable to save value ${JSON.stringify(error)}`);
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, jsonPlus, row.original.id, table]);
    const onChange = React.useCallback((event) => {
        setEditing(false);
        const targetValue = event.target.value;
        let newValue = [];
        if (Array.isArray(targetValue)) {
            newValue = targetValue.map((v) => v.value);
        }
        else if (typeof targetValue === 'string') {
            newValue = [targetValue];
        }
        setvalues(newValue);
        (async () => save(newValue))();
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        setEditing(false);
    }, []);
    React.useEffect(() => {
        setvalues(row.original[column.id]);
    }, [column.id, row.original]);
    React.useEffect(() => {
        if (column.columnDef.meta !== undefined) {
            const { dropdown } = column.columnDef.meta;
            if (dropdown) {
                setOptions(dropdown.options);
            }
        }
    }, [column.columnDef.meta]);
    React.useEffect(() => {
        if (isEditing) {
            setContent(React.createElement(Popup, { close: closeDropdown, style: { width: `${(column.columnDef.size ?? 150) + 20}px` } },
                React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: false, isMultiSelect: true, isTypeAheadEnabled: false, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: options, value: options.filter((o) => 'value' in o && values?.includes(o.value)) })));
        }
        else {
            setClassName('co-centered-box');
            const text = options.filter((o) => 'value' in o && values?.includes(o.value))?.map((v) => v.label).join(', ') ?? '??';
            setContent(text);
        }
    }, [closeDropdown, column.columnDef.size, column.id, errorText, isDisabled, isEditing, isLoading,
        setContent, onChange, options, row.original, values]);
    return (React.createElement(EditableCell, { className: className, errorText: errorText, isLoading: isLoading, isLoadingRow: row.original.isEditing, key: cell.id, setEditing: setEditing }, content));
};
export default TransactionCellDropdownMultiple;
