import { Checkbox } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import './TransactionCheckbox.scss';
import TableContext from 'components/custom-table/TableContext';
const TableCheckbox = function (context) {
    const { setRowSelection } = React.useContext(TableContext);
    const onClick = React.useCallback((event) => {
        if (event.isTrusted) {
            if (event.shiftKey) {
                const currentSelectedRowIds = context.table.getSelectedRowModel().rows.map(r => r.index);
                currentSelectedRowIds.push(context.row.index);
                const newMinimum = Math.min(...currentSelectedRowIds);
                const newMaximum = Math.max(...currentSelectedRowIds);
                const { rows } = context.table.getRowModel();
                for (let ind = newMinimum; ind < newMaximum + 1; ind++) {
                    const r = rows[ind];
                    if (!r.getIsSelected()) {
                        r.toggleSelected();
                    }
                }
                if (setRowSelection !== undefined) {
                    const ids = rows.slice(newMinimum, newMaximum + 1).map(r => r.id);
                    setRowSelection(ids);
                }
            }
            else {
                context.row.toggleSelected();
            }
        }
    }, [context, setRowSelection]);
    return (React.createElement("div", { className: "co-transaction-checkbox", style: { justifyContent: 'center !important' } },
        React.createElement(Checkbox, { status: context.row.getIsSelected() ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, name: `check_${context.row.id}`, onClick: onClick, value: "" })));
};
export default TableCheckbox;
