import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const BenchmarkTable = ({ create, deleteFn, edit, hasError, isLoading, benchmarks, }) => {
    const columns = React.useMemo(() => ([
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
        },
        { accessorKey: 'name', header: 'Benchmark', id: 'name' },
    ]), [deleteFn, edit]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "Benchmarks" }),
        React.createElement(CustomTable, { columns: columns, data: benchmarks, emptyMessage: "You have no benchmarks yet", errorMessage: "Error downloading benchmarks", hasError: hasError, isLoading: isLoading, name: "benchmark" })));
};
export default BenchmarkTable;
