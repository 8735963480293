var _a;
import { Source } from '../../sources/sources';
import EsmaFilter from '../filter/EsmaFilter';
import EsmaQuery from '../query/EsmaQuery';
import MetaData from 'components/metadata/MetaData';
import TimeRange from '../TimeRange';
class EsmaSearch extends MetaData {
    filter;
    name;
    query;
    constructor(response) {
        super(response);
        this.filter = response?.filter ? new EsmaFilter(response.filter) : new EsmaFilter();
        this.name = response?.name;
        this.query = response?.query ? new EsmaQuery(response.query) : new EsmaQuery();
        console.log("After updating: %o we get %o", response, this);
    }
    #clone() {
        return new _a({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            filter: this.filter,
            id: this.id,
            lastModifiedDate: this.lastModifiedDate,
            lastModifiedBy: this.lastModifiedBy,
            name: this.name,
            permissions: this.permissions,
            query: this.query
        });
    }
    database() { return Source.ESMA; }
    getCreationDate = () => {
        const textCriteria = this.query.textCriteria.find((t) => t.name === 'mrkt_trdng_rqst_date');
        return textCriteria !== undefined ? TimeRange.from(textCriteria.values) : undefined;
    };
    getCurrency = () => {
        const textCriteria = this.query.textCriteria.find((t) => t.name === 'gnr_notional_curr_code');
        return textCriteria ? textCriteria.values : [];
    };
    getMaturity = () => {
        const textCriteria = this.query.textCriteria.find((t) => t.name === 'bnd_maturity_date');
        return textCriteria ? TimeRange.from(textCriteria.values) : undefined;
    };
    updateFilter(value) {
        const clone = this.#clone();
        clone.filter = value;
        return clone;
    }
    updateName(value) {
        const clone = this.#clone();
        clone.name = value;
        return clone;
    }
    updatePermissions(value) {
        const clone = this.#clone();
        clone.permissions = value;
        return clone;
    }
    updateQuery(value) {
        const clone = this.#clone();
        clone.query = value;
        return clone;
    }
}
_a = EsmaSearch;
export default EsmaSearch;
