import * as React from 'react';
import DebouncedInput from './DebouncedInput';
import './TextFilterPanel.scss';
const TextFilterPanel = function TextFilterPanelElement({ close, column, sortedUniqueValues, }) {
    const clear = React.useCallback(() => {
        column.setFilterValue('');
        close();
    }, [close, column]);
    const onChange = React.useCallback((value) => {
        column.setFilterValue(value);
    }, [column]);
    return (React.createElement("div", { className: "co-check-panel" },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            sortedUniqueValues.length < 100 ? (React.createElement("datalist", { id: `${column.id}list` }, sortedUniqueValues.slice(0, 5000).map((value) => (React.createElement("option", { "aria-label": typeof value === 'string' ? value : value.value, key: typeof value === 'string' ? value : value.value, value: typeof value === 'string' ? value : value.label }))))) : null,
            React.createElement(DebouncedInput, { clear: clear, list: `${column.id}list`, onChange: onChange, placeholder: "Search...", type: "text", value: (column.getFilterValue() ?? '') }))));
};
export default TextFilterPanel;
