class FilterItem {
    exclude;
    values;
    constructor({ exclude, values }) {
        this.exclude = exclude;
        this.values = values;
    }
    static isEmpty(filterItem) {
        if (filterItem === undefined) {
            return true;
        }
        return filterItem.values.length > 0;
    }
    static NEW = new FilterItem({ exclude: true, values: [] });
}
export default FilterItem;
