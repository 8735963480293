/* eslint-disable react/no-danger */
import { Loader } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const cleanDiv = (element) => {
    element.style.removeProperty('font-family');
    element.style.removeProperty('font-size');
    element.style.removeProperty('font-weight');
    element.style.removeProperty('width');
    element.style.removeProperty('max-width');
    element.style.removeProperty('min-width');
    element.style.removeProperty('text-wrap');
    element.style.removeProperty('white-space');
    element.style.removeProperty('color');
    element.style.removeProperty('height');
    element.style.removeProperty('padding');
    element.style.removeProperty('border-left-color');
    element.style.removeProperty('border-right-color');
    element.style.removeProperty('border-top-color');
    element.style.removeProperty('border-bottom-color');
    element.style.removeProperty('width');
    element.style.removeProperty('max-width');
    for (let i = 0; i < element.children.length; i += 1) {
        cleanDiv(element.children.item(i));
    }
};
const OriginalContent = ({ className, hasError, html, isLoading, isVisible, noStyle, setRef, }) => {
    const gridRef = React.useRef(null);
    const divRef = React.useRef(null);
    const [content, setContent] = React.useState();
    const [observer, setObserver] = React.useState();
    const callback = React.useCallback((mutationList) => {
        mutationList.forEach((mutation) => {
            if (mutation.type === 'childList') {
                if (mutation.addedNodes.length > 0 && divRef.current !== null) {
                    cleanDiv(divRef.current);
                    divRef.current.style.setProperty('font-family', 'monospace');
                    divRef.current.style.setProperty('font-size', 'small');
                    divRef.current.style.setProperty('white-space', 'pre');
                    divRef.current.querySelectorAll('td').forEach((cell) => {
                        cell.style.setProperty('border-style', 'dotted');
                        cell.style.setProperty('border-width', 'thin');
                        cell.style.setProperty('padding-left', '0.5rem');
                    });
                    divRef.current.querySelectorAll('table').forEach((table) => {
                        table.style.setProperty('background-color', 'lavender');
                    });
                    divRef.current.querySelectorAll('div').forEach((table) => {
                        table.style.setProperty('background-color', 'lemonchiffon');
                    });
                    divRef.current.querySelectorAll('span').forEach((table) => {
                        table.style.setProperty('background-color', 'mistyrose');
                    });
                }
            }
        });
    }, []);
    React.useEffect(() => {
        if (isVisible && gridRef.current !== null && setRef !== undefined) {
            setRef(gridRef.current);
        }
    }, [isVisible, setRef]);
    React.useEffect(() => {
        setObserver(new MutationObserver(callback));
    }, [callback]);
    React.useEffect(() => {
        if (observer !== undefined) {
            const config = { attributes: true, childList: true, subtree: true };
            const targetNode = divRef.current;
            if (targetNode !== null) {
                // Start observing the target node for configured mutations
                observer.observe(targetNode, config);
            }
        }
    }, [observer]);
    React.useEffect(() => {
        if (hasError) {
            setContent(React.createElement("div", null, "Error in Content download"));
        }
        else if (isLoading) {
            setContent(React.createElement(Loader, { className: "co-loader", type: "spinner" }));
        }
        else {
            setContent(React.createElement("div", { ref: divRef, dangerouslySetInnerHTML: { __html: html } }));
        }
    }, [hasError, html, isLoading]);
    return (React.createElement("div", { ref: gridRef, className: `${className}${isVisible ? ' co-visible' : ''}` }, content));
};
export default OriginalContent;
