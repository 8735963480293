import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import EditableCell from '../transactions/overview/table/EditableCell';
import '../transactions/overview/table/EditableCell.css';
const EditableNameTextCell = ({ table, row, column, cell, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            setValue(column.accessorFn(row.original, row.index));
        }
    }, [column, row.original, row.index]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && value !== undefined) {
            const formData = new FormData();
            formData.append('name', value);
            formData.append('id', row.original.id);
            formData.append('index', row.original.index.toString());
            const request = new ServerRequest('/syndicate/constant/name', { body: formData, method: 'PATCH' });
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const { id } = body;
                if (id !== undefined) {
                    const constantNames = body.names.map((name, index) => ({ id, index, name }));
                    table.options.meta.updateTable(constantNames);
                }
            }
            catch (error) {
                sendFetchError('Unable to save override information', error, request);
                setErrorText('Unable to save constant info');
            }
            setEditing(false);
            setLoading(false);
        }
    }, [apiFetchJson, row.original.id, row.original.index, table, value]);
    const onBlur = React.useCallback(() => {
        (async () => save())();
    }, [save]);
    const inputRef = React.useRef(null);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save())();
        }
    }, [save]);
    React.useLayoutEffect(() => {
        if (isEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isEditing, column.id]);
    return (React.createElement(EditableCell, { errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: isLoading, key: cell.id, setEditing: setEditing }, isEditing
        ? React.createElement("input", { className: "co-editable-input", disabled: isLoading, ref: inputRef, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value })
        : value));
};
export default EditableNameTextCell;
