import { Button, Icon, Loader, Nav, } from '@symphony-ui/uitoolkit-components';
import MenuItems from 'components/menu-items-list/MenuItemsList';
import NavigationContext from 'contexts/NavigationContext';
import * as React from 'react';
const NavMenu = ({ setShowSettings }) => {
    const { availableTabs, tab, updateTab } = React.useContext(NavigationContext);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
    const onActiveTabChange = React.useCallback((navItem) => {
        setMenuOpen(false);
        setMainMenuOpen(false);
        updateTab(navItem.id);
    }, [updateTab]);
    const toggleMenu = React.useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen]);
    const toggleMainMenu = () => {
        setMainMenuOpen(!mainMenuOpen);
    };
    const tabs = React.useMemo(() => {
        if (availableTabs === undefined) {
            return undefined;
        }
        return availableTabs.map((p) => ({ id: p.code, label: p.label }));
    }, [availableTabs]);
    return (React.createElement("div", { className: "co-form-buttons-plus" },
        React.createElement("div", { className: "co-button-menu" },
            React.createElement("button", { className: "co-collapse-button", type: "button", onClick: toggleMainMenu }, "\uD83D\uDFF0"),
            tabs !== undefined
                ? (React.createElement("div", { className: `co-fixed-top-row${mainMenuOpen ? ' co-open' : ''}` },
                    React.createElement(Nav, { activeItemId: tab, items: tabs, onActiveTabChange: onActiveTabChange }))) : React.createElement(Loader, null)),
        React.createElement("div", null,
            React.createElement(Button, { className: "co-menu-button", iconButton: true, onClick: toggleMenu, variant: "tertiary" },
                React.createElement(Icon, { iconName: "settings" })),
            React.createElement(MenuItems, { closeMenu: () => setMenuOpen(false), setShowSettings: setShowSettings, show: menuOpen }))));
};
export default NavMenu;
