import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const DocumentationCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setvalue] = React.useState(() => {
        const field = column.id;
        const overrideValue = row.original.overrideMap?.[field];
        if (overrideValue !== undefined && typeof overrideValue !== 'string') {
            throw new Error(`Documentation cell must be a string but is ${typeof overrideValue}`);
        }
        const isMulti = column.columnDef.meta?.dropdown?.isMulti;
        if (overrideValue !== undefined && isMulti !== undefined) {
            return (isMulti && typeof overrideValue === 'string') ? overrideValue.split(',') : overrideValue;
        }
        return row.original.data?.documentation !== undefined ? row.original.data?.documentation[field] : undefined;
    });
    const [isMultiSelect, setMultiSelect] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [className, setClassName] = React.useState();
    React.useEffect(() => {
        if (column.columnDef.meta !== undefined) {
            const { dropdown } = column.columnDef.meta;
            if (dropdown) {
                setOptions(dropdown.options);
                setMultiSelect(dropdown.isMulti ?? false);
            }
        }
    }, [column.columnDef.meta]);
    const title = React.useMemo(() => {
        const id = column.id;
        if (row.original.overrideMap?.[column.id] !== undefined
            && row.original.data?.documentation?.[id] !== undefined) {
            const titleValue = row.original.data.documentation[id];
            const newTitle = Array.isArray(titleValue)
                ? options.filter((o) => 'value' in o && titleValue.includes(o.value)).map((o) => o.label).join(', ')
                : options.find((o) => 'value' in o && titleValue === o.value)?.label;
            return newTitle;
        }
        return undefined;
    }, [column.id, options, row.original.overrideMap, row.original.data?.documentation]);
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && value !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', Array.isArray(newValue) ? newValue.join(',') : newValue ?? '');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setDisabled(true);
            setLoading(true);
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
            catch (error) {
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setLoading(false);
                setDisabled(false);
            }
        }
    }, [column.id, jsonPlus, row.original.trancheIndex, row.original.transaction.id, table, value]);
    const onChange = React.useCallback((event) => {
        const labelValue = event.target.value;
        let newValue;
        if (Array.isArray(labelValue)) {
            newValue = labelValue.map((v) => v.value);
            setvalue(newValue);
            (async () => save(newValue))();
        }
        else if ('value' in labelValue) {
            newValue = labelValue.value;
            setvalue(newValue);
            (async () => {
                await save(newValue);
                setEditing(false);
            })();
        }
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        if (value !== undefined) {
            (async () => {
                await save(value);
                setEditing(false);
            })();
        }
        else {
            setEditing(false);
        }
    }, [save, value]);
    const content = React.useMemo(() => {
        if (isEditing) {
            setClassName('co-editable-dropdown');
            return (React.createElement(Popup, { close: closeDropdown, style: { width: '200px' } }, isMultiSelect
                ? (React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: true, isMultiSelect: true, isTypeAheadEnabled: true, closeMenuOnSelect: false, menuIsOpen: true, onChange: onChange, options: options, value: options.filter((o) => 'value' in o && Array.isArray(value) && value.includes(o.value)) })) : (React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: true, isInputClearable: true, isTypeAheadEnabled: true, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: options, value: options.find((o) => 'value' in o && value === o.value) }))));
        }
        if (row.original.overrideMap?.[column.id] !== undefined || (row.original.data?.documentation !== undefined && column.id in row.original.data.documentation)) {
            setClassName('co-centered-box');
            const field = column.id;
            const print = (tranche) => {
                if (tranche.overrideMap && field in tranche.overrideMap) {
                    const val = tranche.overrideMap[field];
                    if (typeof val === 'string') {
                        return val.split(',');
                    }
                }
                const { data } = tranche;
                if (data !== undefined && 'documentation' in data && field in data.documentation) {
                    const docValue = data.documentation[field];
                    return Array.isArray(docValue) ? docValue : [docValue];
                }
                return [];
            };
            if (column.columnDef.meta?.dropdown !== undefined) {
                const { dropdown } = column.columnDef.meta;
                const text = dropdown.options.filter((o) => 'value' in o && print(row.original).includes(o.value)).map((o) => o.label).join(', ');
                return text;
            }
            return print(row.original).join(', ');
        }
        return undefined;
    }, [closeDropdown, column.id, column.columnDef.meta, isDisabled, isEditing, isMultiSelect,
        options, onChange, row.original, value]);
    return (React.createElement(EditableCell, { className: className, errorText: errorText, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, setEditing: setEditing, title: title }, content));
};
export default DocumentationCell;
