import { Button, Checkbox, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const ScheduleModal = ({ onClose, save, schedule }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [optionsUnit, setOptionsUnit] = React.useState([]);
    const [period, setPeriod] = React.useState(schedule.timing.period.toString());
    const [initialDelay, setInitialDelay] = React.useState(schedule.timing.initialDelay.toString());
    const [unit, setUnit] = React.useState(schedule.timing.unit);
    const onChangeInitialDelay = React.useCallback((event) => {
        const { value } = event.target;
        setInitialDelay(value);
    }, []);
    const onChangePeriod = React.useCallback((event) => {
        const { value } = event.target;
        setPeriod(value);
    }, []);
    const onClickSave = React.useCallback(() => {
        schedule.updateTiming(Number.parseInt(period, 10), Number.parseInt(initialDelay, 10), unit);
        save(schedule);
        onClose();
    }, [initialDelay, onClose, period, save, schedule, unit]);
    const onChangeStatus = React.useCallback(() => {
        schedule.switch();
        save(schedule);
    }, [save, schedule]);
    const onChangeUnit = React.useCallback((event) => {
        const { value } = event.target.value;
        setUnit(value);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/core/schedule/options/unit');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setOptionsUnit(body);
            }
            catch (error) {
                sendFetchError('Unable to download timing units', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalTitle, null, schedule.name),
        React.createElement(ModalBody, null,
            React.createElement(Checkbox, { label: "On/Off", name: "status", onChange: onChangeStatus, status: schedule.selectionStatus(), value: "" }),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '120px' } },
                React.createElement("div", { style: { marginLeft: '1rem', width: '80px' } },
                    React.createElement(TextField, { label: "Initial Delay", onChange: onChangeInitialDelay, value: initialDelay })),
                React.createElement("div", { style: { marginLeft: '1rem', width: '80px' } },
                    React.createElement(TextField, { label: "Period", onChange: onChangePeriod, value: period })),
                React.createElement("div", { style: { width: '200px' } },
                    React.createElement(Dropdown, { label: "Unit", onChange: onChangeUnit, options: optionsUnit, value: optionsUnit.find((o) => o.value === unit) })))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default ScheduleModal;
