import { Button } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
const AuthenticationActions = () => {
    const [sessionStatusValue, setSessionStatusValue] = React.useState(null);
    const auth = useAuth();
    const onClickClearStaleState = React.useCallback(() => {
        (async () => auth.clearStaleState())();
    }, [auth]);
    const onClickQuerySessionStatus = React.useCallback(() => {
        (async () => {
            const sessionStatus = await auth.querySessionStatus();
            setSessionStatusValue(sessionStatus);
        })();
    }, [auth]);
    const onClickRemoveUser = React.useCallback(() => {
        (async () => auth.removeUser())();
    }, [auth]);
    const onClickRevokeTokens = React.useCallback(() => {
        (async () => auth.revokeTokens())();
    }, [auth]);
    return (React.createElement("div", null,
        React.createElement(Button, { onClick: onClickClearStaleState }, "CLEAR STALE STATE"),
        React.createElement(Button, { onClick: onClickQuerySessionStatus }, "QUERY SESSION STATUS"),
        React.createElement(Button, { onClick: onClickRemoveUser }, "REMOVE USER"),
        React.createElement(Button, { onClick: onClickRevokeTokens }, "REVOKE TOKENS"),
        React.createElement("div", null, sessionStatusValue !== null ? JSON.stringify(sessionStatusValue) : 'NULL')));
};
export default AuthenticationActions;
