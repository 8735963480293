import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import ConstantNameTable from './ConstantNameTable';
const ConstantList = ({ constantId }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [constant, setConstant] = React.useState();
    React.useEffect(() => {
        if (constantId !== null) {
            (async () => {
                const url = new ApiServerURL('/syndicate/constant');
                url.searchParams.append('id', constantId);
                const request = new ServerRequest(url);
                try {
                    const body = await jsonPlus(request, 'Error downloading options');
                    setConstant(body);
                }
                catch (error) {
                    //
                }
            })();
        }
        else {
            setConstant(undefined);
        }
    }, [constantId, jsonPlus]);
    if (constant !== undefined) {
        return (React.createElement(ConstantNameTable, { constant: constant }));
    }
    return React.createElement("div", null);
};
export default ConstantList;
