/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
const DraggableRow = function DraggableRowElement({ row, reorderRow }) {
    const [, dropRef] = useDrop({
        accept: 'row',
        drop: (draggedRow) => reorderRow(draggedRow.index, row.index),
    });
    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    });
    return (React.createElement("tr", { ref: previewRef, style: { opacity: isDragging ? 0.5 : 1 } },
        React.createElement("td", { ref: dropRef, style: { textAlign: 'center' } },
            React.createElement("button", { ref: dragRef, type: "button" }, "\uD83D\uDFF0")),
        row.getVisibleCells().map((cell) => (React.createElement("td", { className: `co-table-cell ${cell.column.columnDef.meta?.className ?? ''}`, key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext()))))));
};
export default DraggableRow;
