import { Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import KeyValueRegexForm from '../regex-viewer/key-value-regex-form/KeyValueRegexForm';
import './KeyFormModal.scss';
const KeyFormModal = ({ hideModal, name, title, }) => {
    const [keyValueRegex, setKeyValueRegex] = React.useState();
    const callback = React.useCallback(() => {
        hideModal();
        setKeyValueRegex(undefined);
        document.dispatchEvent(new Event('reloadcontent'));
    }, [hideModal]);
    const onClose = React.useCallback(() => {
        setKeyValueRegex(undefined);
        hideModal();
    }, [hideModal]);
    return (React.createElement(Modal, { className: "cmo-dropdown-absolute co-key-form-modal", closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalTitle, null, `NEW KEY: ${title}`),
        React.createElement(ModalBody, null,
            React.createElement(KeyValueRegexForm, { callbackAdd: callback, callbackCreate: callback, isFormDisabled: false, initialKeyValueRegexId: keyValueRegex?.id, name: name, setKeyValueRegex: setKeyValueRegex }))));
};
export default KeyFormModal;
