var _a;
import { Source } from '../../sources/sources';
import SecFilter from '../filter/SecFilter';
import SecQuery from '../query/SecQuery';
import MetaData from 'components/metadata/MetaData';
class SecSearch extends MetaData {
    filter;
    name;
    query;
    constructor(response) {
        super(response);
        this.name = response?.name;
        this.query = response?.query ? new SecQuery(response.query) : new SecQuery();
        this.filter = response?.filter ? new SecFilter(response.filter) : new SecFilter();
    }
    database() { return Source.SEC; }
    #clone() {
        return new _a({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            name: this.name,
            permissions: this.permissions,
            query: this.query,
            filter: this.filter
        });
    }
    getCreationDate = () => undefined;
    getCurrency = () => undefined;
    getMaturity = () => undefined;
    updateFilter(value) {
        const clone = this.#clone();
        clone.filter = value;
        return clone;
    }
    updateName(value) {
        const clone = this.#clone();
        clone.name = value;
        return clone;
    }
    updatePermissions(value) {
        const clone = this.#clone();
        clone.permissions = value;
        return clone;
    }
    updateQuery(query) {
        const clone = this.#clone();
        clone.query = query;
        return clone;
    }
}
_a = SecSearch;
export default SecSearch;
