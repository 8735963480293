import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import FilterItemInput from './FilterItemInput';
import './FilterItemInput.css';
const RemoteFilterItem = ({ disabled = false, label, onChange, path, value, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [placeholder, setPlaceholder] = React.useState();
    React.useEffect(() => {
        if (path !== null) {
            (async () => {
                setPlaceholder('Loading...');
                try {
                    const request = new ServerRequest(path);
                    const body = await jsonPlus(request, 'Error downloading filre');
                    setOptions(body);
                    setPlaceholder('Select...');
                    setError(false);
                }
                catch (error) {
                    setError(true);
                    setPlaceholder('Error downloading options...');
                }
            })();
        }
    }, [jsonPlus, options.length, path]);
    React.useEffect(() => {
        if (options.length === 0) {
            setError(true);
            setPlaceholder('None available yet...');
        }
    }, [options.length]);
    return (React.createElement(FilterItemInput, { disabled: disabled || hasError, label: label, onChange: onChange, options: options, placeholder: placeholder, value: value }));
};
export default RemoteFilterItem;
