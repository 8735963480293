import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import TransactionWrapper from '../../../models/TransactionWrapper';
import EditableCell from '../overview/table/EditableCell';
const TransactionEditCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [className, setClassName] = React.useState('');
    const inputRef = React.useRef(null);
    React.useEffect(() => {
        const classname = column.columnDef.meta?.className;
        if (classname !== undefined) {
            setClassName(classname);
        }
    }, [column.columnDef.meta?.className]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/meta-update');
            url.searchParams.append('transactionId', row.original.id);
            url.searchParams.append('key', column.id);
            if (value !== '') {
                url.searchParams.append('value', value);
            }
            setLoading(true);
            setDisabled(true);
            const request = new ServerRequest(url, { method: value !== '' ? 'PATCH' : 'DELETE' });
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const b = new TransactionWrapper(body);
                table.options.meta.updateTable(b);
                setValue(b.company?.name);
            }
            catch (error) {
                setErrorText(`Unable to save ${JSON.stringify(error)}`);
                //
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, jsonPlus, row.original.id, table, value]);
    const onBlur = React.useCallback(() => {
        (async () => save())();
    }, [save]);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save())();
        }
    }, [save]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    React.useEffect(() => {
        setValue(row.original.company?.name ?? '');
    }, [column.id, row.original]);
    React.useLayoutEffect(() => {
        if (isEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    const content = React.useMemo(() => {
        if (isEditing) {
            return (
            // eslint-disable-next-line jsx-a11y/no-autofocus
            React.createElement("input", { autoFocus: true, className: "co-editable-input", disabled: isDisabled, ref: inputRef, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value }));
        }
        setClassName('co-centered-box');
        return value;
    }, [isDisabled, isEditing, onBlur, onChange, onKeyUp, value]);
    return (React.createElement(EditableCell, { className: className, errorText: errorText, isLoading: isLoading, isLoadingRow: isLoading, key: cell.id, setEditing: setEditing }, content));
};
export default TransactionEditCell;
