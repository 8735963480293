import * as React from 'react';
import { Button, Icon, Loader, Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import ApiServerURL from 'models/ServerUrl';
import DownloadContext from 'contexts/DownloadContext';
import MessagesView from './MessagesView';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const MesssagesModal = ({ hideModal, splitScreen, transactionId, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { isAdmin } = React.useContext(NavigationContext);
    const [content, setContent] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const resetTableHeight = React.useCallback(() => {
        setIsMobile(window.innerWidth < 1025);
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        resetTableHeight();
        window.addEventListener('resize', resetTableHeight, { signal: abortController.signal });
        return () => { abortController.abort(); };
    }, [resetTableHeight]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (transactionId !== undefined) {
            const url = new ApiServerURL('/api/syndicate/view');
            url.searchParams.append('transactionId', transactionId);
            if (isAdmin()) {
                url.searchParams.append('markup', 'true');
            }
            const request = new ServerRequest(url);
            setLoading(true);
            void (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setContent(body);
                }
                catch (error) {
                    sendFetchError('Unable to download details', error, request);
                    if (!(error instanceof Error && error.name === 'AbortError')) {
                        setError(true);
                    }
                }
                setLoading(false);
            })();
        }
        return () => { abortController.abort(); };
    }, [apiFetchJson, isAdmin, transactionId]);
    return isMobile || !splitScreen ? (React.createElement(Modal, { className: isAdmin() ? "admin" : "", closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "full-width" },
        React.createElement(ModalTitle, null, (content?.messages.length !== undefined && content.messages.length > 0) && !isLoading ? (React.createElement("div", null,
            React.createElement("span", null, content.messages[0].title),
            React.createElement("span", { style: { float: 'right', marginLeft: '2rem' } }, new Date(content.messages[0].timestamp).toLocaleString()))) : 'Loading Messages...'),
        React.createElement(ModalBody, null, !hasError && !isLoading ? content?.messages.map((c, index) => (React.createElement(MessagesView, { body: c.body, key: c.timestamp + "-" + index.toString(), timestamp: index !== 0 ? new Date(c.timestamp) : undefined, title: index !== 0 ? c.title : undefined }))) : React.createElement("div", null, isLoading ? React.createElement(Loader, { className: "co-loader", type: "spinner" }) : 'Unable to download messages')))) : (React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', minWidth: '33%',
        } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("h4", { style: { marginTop: '0px' } }, content?.messages.length !== undefined && content.messages.length > 0 && !isLoading ? (React.createElement("div", null,
                React.createElement("span", null, content.messages[0].subject),
                React.createElement("span", { style: { float: 'right', marginLeft: '2rem' } }, new Date(content.messages[0].timestamp).toLocaleString()))) : 'Loading Messages'),
            React.createElement(Button, { iconButton: true, onClick: hideModal, variant: "tertiary" },
                React.createElement(Icon, { iconName: "cross" }))),
        React.createElement("div", { style: { overflowY: 'scroll' } }, !hasError && !isLoading ? content?.messages.map((c, index) => (React.createElement(MessagesView, { body: c.body, key: `Message${c.timestamp}`, timestamp: index !== 0 ? new Date(c.timestamp) : undefined, title: index !== 0 ? c.subject : undefined }))) : React.createElement("div", null, isLoading ? React.createElement(Loader, { className: "co-loader", type: "spinner" }) : 'Unable to download messages'))));
};
export default MesssagesModal;
