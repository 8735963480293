import CoreData from './CoreData';
class LineData extends CoreData {
    entry;
    constructor(response) {
        super(response);
        this.entry = response.entry;
    }
    getValue() {
        return this.entry.value;
    }
}
export default LineData;
