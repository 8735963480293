import print from '../transactions/overview/cells/print';
// PRICING DATE
// INDUSTRY
// REGINO
// ISSUER
// CCY
// SIZE (M)
// UPSIZED
// TNR
// MTY
// RNK
// MO
// SP
// FI
// TYPE  "FX", "VAR", "FL
// BNCH	"Swaps", "G", "3mBBSW", "ASW", "ACGB", "3mEu", "OLO"
// SPRD
// CPN
// PRICE
const tableMapFinsight = (regionsMap, statusMap, sectorMap, industryMap, usSalesMap) => ([
    { header: 'PRICING DATE', printFn: print.pricingDate },
    { header: 'REGION', printFn: print.regions(regionsMap) },
    { header: 'ISSUER', printFn: print.issuer },
    { header: 'CCY', printFn: print.currency },
    { header: 'SIZE (M)', printFn: print.amount },
    { header: 'UPSIZED', printFn: () => "" },
    { header: 'TENOR', printFn: print.tenor },
    { header: 'MTY', printFn: print.maturity },
    { header: 'RNK', printFn: print.documentationArray(statusMap, 'statusOfNotes') },
    { header: 'MO', printFn: print.ratingIndividual('moodys', 'MOODYS') },
    { header: 'SP', printFn: print.ratingIndividual('sp', 'S_P') },
    { header: 'FI', printFn: print.ratingIndividual('fitch', 'FITCH') },
    { header: 'TYPE', printFn: print.interestBasis },
    { header: 'BNCH', printFn: print.benchmark },
    { header: 'SPRD', printFn: print.spread },
    { header: 'CPN', printFn: print.coupon },
    { header: 'PRICE', printFn: print.price },
    { header: 'industry', printFn: print.companySector(sectorMap, 'sector') },
    { header: 'subindustry', printFn: print.companySector(industryMap, 'industry') },
    { header: 'structuring_lead', printFn: print.structuringLead },
    { header: 'joint_lead', printFn: print.bookrunners },
    { header: 'parent', printFn: print.ultimateParent },
    { header: 'ticker', printFn: print.ticker },
    { header: 'dbrs', printFn: print.ratingIndividual('dbrs', 'DBRS') },
    { header: 'morningstar', printFn: print.ratingIndividual('morningstar', 'UNKNOWN') },
    { header: 'kroll', printFn: print.ratingIndividual('kroll', 'KBRA') },
    { header: 'euler_hermes', printFn: print.ratingIndividual('euler_hermes', 'UNKNOWN') },
    { header: 'fedafin', printFn: print.ratingIndividual('fedafin', 'FEDAFIN') },
    { header: 'scope', printFn: print.ratingIndividual('scope', 'SCOPE') },
    { header: 'china_dagong', printFn: print.ratingIndividual('china_dagong', 'UNKNOWN') },
    { header: 'china_pengyuan', printFn: print.ratingIndividual('china_pengyuan', 'CSPI') },
    { header: 'china_chengxin', printFn: print.ratingIndividual('china_chengxin', 'CCXAP') },
    { header: 'ram', printFn: print.ratingIndividual('ram', 'UNKNOWN') },
    { header: 'r_and_i', printFn: print.ratingIndividual('r_and_i', 'R_I') },
    { header: 'marc', printFn: print.ratingIndividual('marc', 'UNKNOWN') },
    { header: 'tris', printFn: print.ratingIndividual('tris', 'UNKNOWN') },
    { header: 'initial_price_guidance', printFn: print.guidance('initialPriceThoughts') },
    { header: 'guidance', printFn: print.guidance('guidance') },
    { header: 'yield', printFn: print.yield },
    { header: 'expected_maturity_date', printFn: print.expectedMaturityDate },
    { header: 'first_pay_date', printFn: print.firstPayDate },
    { header: 'redemption', printFn: print.redemption },
    { header: 'new_issue_concession', printFn: print.newIssueConcession },
    { header: 'book_size_mm', printFn: print.book },
    { header: 'cusip', printFn: print.cusip },
    { header: 'cusip_regs', printFn: print.cusipRegS },
    { header: 'isin', printFn: print.isin },
    { header: 'registration', printFn: print.documentationArray(usSalesMap, 'usSales') },
    { header: 're_open', printFn: print.tapText },
    { header: 'verified', printFn: print.verified },
    { header: 'min_denomination', printFn: print.minDenomination },
    { header: 'collateral', printFn: print.collateral },
]);
const tableMapInterprice = (esgMap) => ([
    { header: 'DealId', printFn: (t) => t.transaction.id },
    { header: 'Issuer', printFn: print.issuer },
    { header: 'DealStatus', printFn: () => 'PRICED' },
    { header: 'BBGTicker', printFn: print.ticker },
    { header: 'TrancheId', printFn: (t) => t.trancheIndex.toString() },
    { header: 'Coupon', printFn: print.coupon },
    { header: 'CouponType', printFn: print.interestBasis },
    { header: 'Currency', printFn: print.currency },
    { header: 'ESG', printFn: print.documentation(esgMap, 'esg') },
    { header: 'Guidance', printFn: print.guidance('guidance') },
    { header: 'IPT', printFn: print.guidance('initialPriceThoughts') },
    { header: 'Maturity', printFn: print.maturity },
    {
        header: 'Oversubscription',
        printFn: (value) => {
            const os = print.oversubscription(value);
            if (os === undefined || typeof os === 'string') {
                return os;
            }
            const options = { maximumFractionDigits: 1 };
            return Intl.NumberFormat((new Intl.NumberFormat()).resolvedOptions().locale, options).format(os.value);
        },
    },
    { header: 'Pricing', printFn: print.pricing },
    { header: 'Rating', printFn: print.ratings },
    { header: 'PricingDate', printFn: print.pricingDate },
    { header: 'Settlement', printFn: print.settlementDate },
    { header: 'Size', printFn: print.amount },
    { header: 'Spread', printFn: print.spread },
    { header: 'Yield', printFn: print.yield },
    {
        header: 'Tap',
        printFn: (value) => {
            const t = print.tap(value);
            return t === true ? "TRUE" : undefined;
        },
    },
]);
export const interpriceColumns = tableMapInterprice(new Map()).map((m) => m.header);
const transformDataForInterprice = (data, esgMap) => {
    const all = tableMapInterprice(esgMap);
    const allArrays = [];
    const headers = all.map((i) => i.header);
    allArrays.push(headers);
    data.forEach((t) => {
        const trancheRow = all.map((i) => {
            const value = i.printFn(t);
            if (typeof value === 'string') {
                if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                    const newValue = value.replaceAll('"', '""');
                    return `"${newValue}"`;
                }
                return value;
            }
            if (typeof value === 'object') {
                if (value.value instanceof Date) {
                    return value.value.toISOString().substring(0, 10);
                }
                else {
                    if (typeof value.value === 'number') {
                        return value.value.toString();
                    }
                }
            }
            return '';
        });
        allArrays.push(trancheRow);
    });
    return allArrays;
};
export default transformDataForInterprice;
export const transformDataForFinsight = (data, regionMap, statusMap, industryMap, sectorMap, usSalesMap) => {
    const all = tableMapFinsight(regionMap, statusMap, sectorMap, industryMap, usSalesMap);
    const allArrays = [];
    const headers = all.map((i) => i.header);
    allArrays.push(headers);
    data.forEach((t) => {
        const trancheRow = all.map((i) => {
            const value = i.printFn(t);
            if (typeof value === 'string') {
                if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                    const newValue = value.replaceAll('"', '""');
                    return `"${newValue}"`;
                }
                return value;
            }
            if (typeof value === 'object') {
                if (value.value instanceof Date) {
                    return value.value.toISOString().substring(0, 10);
                }
                else {
                    if (typeof value.value === 'number') {
                        return value.value.toString();
                    }
                }
            }
            return '';
        });
        allArrays.push(trancheRow);
    });
    console.log("NEW ARRAYS! %o", allArrays);
    return allArrays;
};
