import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import RenderingContext from 'contexts/RenderingContext';
const MaturityCellDateSelector = ({ isDisabled, setValueConfirm, tableCellRef, save, value, }) => {
    const { printDate } = React.useContext(RenderingContext);
    const onClickDate = React.useCallback((mode) => (event) => {
        event.stopPropagation();
        if (value instanceof Date) {
            const year = value.getFullYear().toString();
            const month = (value.getMonth() + 1).toString().padStart(2, '0');
            const day = value.getDate().toString().padStart(2, '0');
            let dateString;
            switch (mode) {
                case 'OK':
                    dateString = `${year}-${month}-${day}`;
                    break;
                case 'M':
                    dateString = `${year}-${month}`;
                    break;
                case 'Y':
                    dateString = year;
                    break;
                default:
                    throw new Error('Unknown date mode (must be OK, M or Y)');
            }
            (async () => {
                setValueConfirm(false);
                tableCellRef?.focus();
                await save(dateString);
            })();
        }
    }, [value, tableCellRef, save, setValueConfirm]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { fontWeight: 'bold', margin: '1rem', textAlign: 'center' } }, value instanceof Date
            ? printDate(new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())), { precision: 'YMD' })
            : ''),
        React.createElement("div", { style: { display: 'flex', marginLeft: '10px' } },
            React.createElement(Button, { disabled: isDisabled, onClick: onClickDate('OK'), variant: "tertiary-accent" }, "OK"),
            React.createElement(Button, { disabled: isDisabled, onClick: onClickDate('M'), variant: "tertiary" }, "Month"),
            React.createElement(Button, { disabled: isDisabled, onClick: onClickDate('Y'), variant: "tertiary" }, "Year"))));
};
export default MaturityCellDateSelector;
