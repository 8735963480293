import * as React from 'react';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import Company from '../../models/Company';
import CompanyTable from './CompanyTable';
import CompanyForm from './CompanyForm';
const Companies = () => {
    const download = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [companies, setCompanies] = React.useState();
    const [details, setDetails] = React.useState();
    const [isLoading, setLoading] = React.useState(true);
    const [hasError, setError] = React.useState(false);
    const [types, setTypes] = React.useState([]);
    const service = React.useMemo(() => new CrudService({ download, name: 'Company', path: '/core/companies' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                const downloadedCompanies = body.map((u) => Object.assign(new Company(), u));
                setCompanies(downloadedCompanies);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
        (async () => {
            const request = new ServerRequest('/core/companies/type/options');
            try {
                const body = await download.apiFetchJson(request, 'Unable to download types');
                setTypes(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    const create = React.useCallback(() => {
        setDetails(new Company());
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: company } }) => {
        (async () => {
            try {
                await service.delete(company);
                const filteredCompanies = companies !== undefined ? companies.filter((u) => u.id !== company.id) : undefined;
                setCompanies(filteredCompanies);
            }
            catch (error) {
                //
            }
        })();
    }, [companies, service]);
    const edit = React.useCallback(({ row: { original: company } }) => {
        setDetails(company);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedCompany = Object.assign(new Company(), body);
            const updatedCompanies = CrudService.updateData(companies ?? [], updatedCompany);
            setCompanies(updatedCompanies);
            updateView('TABLE');
        }
        catch (error) {
            //
        }
    }, [companies, service, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(CompanyForm, { company: details ?? new Company(), save: save, types: types }));
        case 'TABLE':
            return (React.createElement(CompanyTable, { companies: companies ?? [], create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, types: types }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Companies;
