import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const TermSheetTable = ({ create, deleteFn, edit, hasError, isLoading, termSheets, }) => {
    const datePrinter = (date) => new Date(date).toLocaleDateString();
    const numberPrinter = (number) => new Intl.NumberFormat().format(number);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteFn, label: 'Delete', type: 'error' },
                ],
            },
        },
        {
            accessorKey: 'issuer',
            header: 'Issuer',
        }, {
            accessorKey: 'currency',
            header: 'Currency',
        }, {
            accessorFn: ({ nominal }) => (nominal !== undefined ? numberPrinter(parseInt(nominal, 10)) : undefined),
            header: 'Nominal',
            id: 'nominal',
        }, {
            accessorFn: ({ issueDate }) => (issueDate !== undefined ? datePrinter(issueDate) : undefined),
            header: 'Issue Date',
            id: 'issueDate',
        }, {
            accessorFn: ({ maturityDate }) => (maturityDate !== undefined ? datePrinter(maturityDate) : undefined),
            header: 'Maturity',
            id: 'maturityDate',
        }, {
            accessorKey: 'interestBasis',
            header: 'Interest Basis',
        }, {
            accessorKey: 'coupon',
            header: 'Coupon',
        },
    ], [deleteFn, edit]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "Term Sheets" }),
        React.createElement(CustomTable, { columns: columns, data: termSheets, emptyMessage: "You have no term sheets yet", errorMessage: "Error downloading term sheets", hasError: hasError, isLoading: isLoading, name: "termsheet" })));
};
export default TermSheetTable;
