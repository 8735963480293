import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import IpoTable from './IpoTable';
const Ipos = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [errorIpos, setErrorIpos] = React.useState();
    const [loadingIpos, setLoadingIpos] = React.useState(true);
    const [ipos, setIpos] = React.useState();
    const [exchangeOptions, setExchangeOptions] = React.useState([]);
    const [exchange, setExchange] = React.useState();
    const [errorExchangeOptions, setErrorExchangeOptions] = React.useState();
    const [loadingExchangeOptions, setLoadingExchangeOptions] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            setLoadingExchangeOptions(true);
            const request = new ServerRequest('/bb/ipo/options');
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setExchangeOptions(body);
            }
            catch (error) {
                setErrorExchangeOptions(', variant: BannerType.ERROR }Unable to download IPO Exchanges');
                sendFetchError('Unable to download source options', error, request);
            }
            finally {
                setLoadingExchangeOptions(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (exchange !== undefined) {
            (async () => {
                setLoadingIpos(true);
                const url = new ApiServerURL('/bb/ipo');
                url.searchParams.append('exchangeCode', exchange);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setIpos(body);
                }
                catch (error) {
                    setErrorIpos('Unable to download data');
                    sendFetchError('Unable to download IPOs', error, request);
                }
                finally {
                    setLoadingIpos(false);
                }
            })();
        }
        return () => abortController.abort();
    }, [apiFetchJson, exchange]);
    return (React.createElement(IpoTable, { exchange: exchange, hasError: errorIpos !== undefined, isLoading: loadingIpos, ipos: ipos ?? [], loadingExchangeOptions: loadingExchangeOptions, errorExchangeOptions: errorExchangeOptions, exchangeOptions: exchangeOptions, setExchange: setExchange }));
};
export default Ipos;
