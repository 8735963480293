import * as React from 'react';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import TimeRange from '../../models/TimeRange';
import DateRangeInput from '../DateRangeInput';
import PeriodRangeInput from '../PeriodRangeInput';
import ToggleDecorator from '../toggle-decorator/ToggleDecorator';
import DateRange from '../../models/DateRange';
import './TimeRangeInput.scss';
const TimeRangeInput = ({ defaultPeriod, disabled = false, label, onChange, tooltip, value, }) => {
    const [toggled, setToggle] = React.useState((value !== undefined && value.absolute instanceof DateRange
        && (value.absolute.start !== undefined || value.absolute.end !== undefined)));
    const onChangeInputDateRange = React.useCallback((event) => {
        const { value: newValue } = event.target;
        const updatedTimeRange = { absolute: newValue, relative: undefined };
        onChange({ target: { value: newValue !== undefined ? new TimeRange(updatedTimeRange) : undefined } });
    }, [onChange]);
    const onChangeInputTimeRange = React.useCallback((event) => {
        const { value: newValue } = event.target;
        const updatedTimeRange = { absolute: undefined, relative: newValue };
        onChange({ target: { value: newValue !== undefined ? new TimeRange(updatedTimeRange) : undefined } });
    }, [onChange]);
    return (React.createElement("div", { className: "tk-input-group" },
        React.createElement(LabelTooltipDecorator, { label: label, tooltip: tooltip }),
        React.createElement("div", { className: "tk-input__container select-container" },
            React.createElement(ToggleDecorator, { toggled: toggled, setToggle: setToggle }),
            toggled
                ? (React.createElement(DateRangeInput, { disabled: disabled, name: "absolute", onChange: onChangeInputDateRange, value: value?.absolute }))
                : (React.createElement(PeriodRangeInput, { defaultPeriod: defaultPeriod, disabled: disabled, onChange: onChangeInputTimeRange, value: value?.relative })))));
};
export default TimeRangeInput;
