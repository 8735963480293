/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable, flexRender, } from '@tanstack/react-table';
import { useInfiniteQuery, useQueryClient, } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Loader, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import DraggableColumnHeader from './DraggableColumnHeader';
import ExportButton from './buttons/ExportButton';
import SettingsButton from './buttons/SettingsButton';
import { printBoolean, printDate } from './dateFormat';
import TableContext from './TableContext';
import createDataRequest from './infinite-data-request';
import TableStateClass from './TableStateClass';
import { TableFormattingDefault } from './TableFormatSettingsModal';
import './CustomTable.css';
const startingPage = 0;
const InfiniteTable = function InfiniteTableElement({ columns, dataTransform, emptyMessage, errorMessage, fetchSize, meta, queryName, name, urlPage, urlTotal, setLoading = undefined, subComponent, actionElements = [], }) {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { setTableUpdateFn, setResetRowExpansion, setResetRowSelection, setSelection, } = React.useContext(TableContext);
    const tableContainerRef = React.useRef(null);
    const [tableState, setTableState] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored !== null) {
            const s = JSON.parse(stored);
            if ('state' in s) {
                return new TableStateClass(s.state);
            }
            localStorage.removeItem(`${name}-table-settings`);
        }
        return TableStateClass.DEFAULT;
    });
    const [formatting, setFormatting] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).formatting : TableFormattingDefault;
    });
    const [settingsMeta, setSettingsMeta] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored === null) {
            return undefined;
        }
        const { id, createdBy, createdDate, modifiedBy, modifiedDate, permissions, } = JSON.parse(stored);
        return {
            id, createdBy, createdDate, modifiedBy, modifiedDate, permissions,
        };
    });
    const [settingsName, setSettingsName] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).name : undefined;
    });
    const [totalDBRowCount, setTotalDBRowCount] = React.useState(-1);
    const [hasError, setError] = React.useState(false);
    const [isTotalLoading, setTotalLoading] = React.useState(false);
    const [initialPageParam, setInitialPageParam] = React.useState(startingPage);
    const [renderValue, setRenderValue] = React.useState({
        dateFormat: formatting.dateFormat,
        printBoolean: (flag) => printBoolean(flag, formatting.booleanFormat),
        printDate: (date, precision) => printDate(date, formatting.dateFormat, precision),
        printNumber: (value) => Intl.NumberFormat().format(value),
    });
    /** SCROLL DETAILS START */
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    const [scrollY, setScrollY] = React.useState();
    React.useEffect(() => {
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const settings = {
            state: tableState,
            id: settingsMeta?.id,
            createdBy: settingsMeta?.createdBy,
            createdDate: settingsMeta?.createdDate,
            modifiedBy: settingsMeta?.modifiedBy,
            modifiedDate: settingsMeta?.modifiedDate,
            permissions: settingsMeta?.permissions,
            name: settingsName,
            formatting,
            table: name,
        };
        const stringified = JSON.stringify(settings);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [formatting, name, settingsMeta, settingsName, tableState]);
    const queryClient = useQueryClient();
    const fetchData = React.useCallback(async (params) => {
        const request = createDataRequest(urlPage, params);
        try {
            if (setLoading !== undefined) {
                setLoading(true);
            }
            const body = await apiFetchJson(request, 'Error downloading');
            return dataTransform !== undefined ? dataTransform(body) : body.map((u) => u);
        }
        catch (error) {
            setError(true);
            throw error;
        }
        finally {
            if (setLoading !== undefined) {
                setLoading(false);
            }
        }
    }, [apiFetchJson, dataTransform, setLoading, urlPage]);
    React.useEffect(() => {
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const settings = {
            state: tableState,
            id: settingsMeta?.id,
            createdBy: settingsMeta?.createdBy,
            createdDate: settingsMeta?.createdDate,
            modifiedBy: settingsMeta?.modifiedBy,
            modifiedDate: settingsMeta?.modifiedDate,
            permissions: settingsMeta?.permissions,
            name: settingsName,
            formatting,
            table: name,
        };
        const stringified = JSON.stringify(settings);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [formatting, name, settingsMeta, settingsName, tableState]);
    React.useEffect(() => {
        if (tableState.columnOrder.filter((c) => c == null).length > 0) {
            const updatedColumnOrder = tableState.columnOrder.filter((c) => c != null);
            const updatedState = tableState.updateColumnOrder(updatedColumnOrder);
            setTableState(updatedState);
        }
    }, [tableState]);
    React.useEffect(() => {
        if (urlTotal !== undefined) {
            (async () => {
                const url = new ApiServerURL(urlTotal);
                if (tableState.columnFilters.length > 0) {
                    url.searchParams.append('filterId', tableState.columnFilters.map((s) => s.id).join(','));
                    url.searchParams.append('filterValue', tableState.columnFilters.map((f) => (Array.isArray(f.value) ? f.value.join('|') : f.value)).join(','));
                }
                const request = new ServerRequest(url);
                try {
                    setTotalLoading(true);
                    const body = await apiFetchJson(request, 'Error downloading options');
                    setTotalDBRowCount(body);
                    setTotalLoading(false);
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [tableState.columnFilters, apiFetchJson, urlTotal]);
    const queryKey = React.useMemo(() => [queryName ?? name, tableState.sorting, tableState.columnFilters, initialPageParam], [name, queryName, tableState.sorting, tableState.columnFilters, initialPageParam]);
    React.useEffect(() => {
        if (setTableUpdateFn !== null && setTableUpdateFn !== undefined) {
            const myTable = (queryUpdateFn) => queryClient.setQueryData(queryKey, queryUpdateFn);
            setTableUpdateFn(() => myTable);
        }
    }, [queryClient, queryKey, setTableUpdateFn]);
    // react-query has an useInfiniteQuery hook just for this situation!
    const { data, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetchingNextPage, isFetchingPreviousPage, isLoading, } = useInfiniteQuery({
        queryKey, // adding sorting state as key causes table to reset and fetch from new beginning upon sort
        queryFn: ({ pageParam }) => (async () => {
            const start = pageParam * fetchSize;
            return fetchData({
                start, fetchSize, sorting: tableState.sorting, columnFilters: tableState.columnFilters,
            });
        })(),
        getNextPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === Math.floor((totalDBRowCount - 1) / fetchSize)) {
                return undefined;
            }
            return lastPageParam + 1;
        },
        getPreviousPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === 0) {
                return undefined;
            }
            return lastPageParam - 1;
        },
        initialPageParam,
    });
    // we must flatten the array of arrays from the useInfiniteQuery hook
    const flatData = React.useMemo(() => data?.pages.filter((p) => p !== null).flatMap((page) => page) ?? [], [data]);
    React.useEffect(() => {
        if (setSelection !== undefined) {
            const selectedData = Object.keys(tableState.rowSelection)
                .map((index) => {
                const indexNumber = Number.parseInt(index, 10);
                return flatData[indexNumber];
            });
            setSelection(selectedData);
        }
    }, [flatData, tableState.rowSelection, setSelection]);
    const table = useReactTable({
        columnResizeMode: 'onChange',
        columns,
        data: flatData,
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getRowCanExpand: () => (subComponent !== undefined),
        getSortedRowModel: getSortedRowModel(),
        manualFiltering: true,
        manualSorting: true,
        meta,
        onStateChange: setTableState,
        state: tableState,
    });
    // const arrowKeyEvent = React.useCallback((e: KeyboardEvent) => {
    //   if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    //     console.log('Active Element: %o', document.activeElement);
    //     if (document.activeElement !== null && document.activeElement.tagName !== 'INPUT') {
    //       //        let active: Element | null = document.activeElement.classList.contains('co-editable') ? document.activeElement : document.querySelector<Element>('.co-active');
    //       const active: Element | null = document.querySelector<Element>('.co-active');
    //       console.log('After that, active is: %o', active);
    //       // if (active !== null && document.activeElement !== active) {
    //       //   (active as HTMLDivElement).focus();
    //       // }
    //       // while (active !== null && !active.className.includes('co-editable')) {
    //       //   active = active.parentElement;
    //       // }
    //       const tableBody: HTMLTableSectionElement | null = bodyRef.current;
    //       console.log('ACTIVE: %o, TQABLE BODY: %o', active, tableBody);
    //       if (active === null && tableBody !== null) {
    //         const cell: HTMLDivElement | null = tableBody.querySelector('.co-editable');
    //         if (cell !== null) {
    //           const body: HTMLBodyElement | null = document.querySelector('body');
    //           if (body !== null) {
    //             body.classList.add('tab-clicked');
    //           }
    //           cell.focus();
    //         }
    //       } else if (!active?.classList.contains('co-editable') && tableBody !== null) {
    //         const firstEditable: Element | null = tableBody.querySelector('.co-editable');
    //         console.log('FIRST EDITABLE: %o', firstEditable);
    //         if (firstEditable !== null) {
    //           (firstEditable as HTMLTableCellElement).focus();
    //         }
    //       } else if (active?.classList.contains('co-editable')) {
    //         e.preventDefault();
    //         let row: Element | null = active;
    //         while (row !== null && row.tagName !== 'TR') {
    //           row = row.parentElement;
    //         }
    //         if (row !== null && tableBody !== null) {
    //           let columnIndex = 0;
    //           const allEditable: NodeListOf<HTMLDivElement> = row.querySelectorAll('.co-editable');
    //           while (allEditable.item(columnIndex) !== active) {
    //             columnIndex += 1;
    //           }
    //           let rowIndex = 0;
    //           const allRows: NodeListOf<HTMLTableRowElement> = tableBody.querySelectorAll('tr');
    //           while (allRows.item(rowIndex) !== row) {
    //             rowIndex += 1;
    //           }
    //           if (e.key === 'ArrowLeft') {
    //             if (columnIndex > 0) {
    //               allEditable.item(columnIndex - 1).focus();
    //             }
    //           }
    //           if (e.key === 'ArrowRight') {
    //             if (columnIndex < allEditable.length - 1) {
    //               allEditable.item(columnIndex + 1).focus();
    //             }
    //           }
    //           if (e.key === 'ArrowUp') {
    //             if (rowIndex > 0) {
    //               const newRow = allRows.item(rowIndex - 1);
    //               const newAllEditable: NodeListOf<HTMLDivElement> = newRow.querySelectorAll('.co-editable');
    //               newAllEditable.item(columnIndex).focus();
    //             }
    //           }
    //           if (e.key === 'ArrowDown') {
    //             if (rowIndex < allRows.length - 1) {
    //               const newRow = allRows.item(rowIndex + 1);
    //               const newAllEditable: NodeListOf<HTMLDivElement> = newRow.querySelectorAll('.co-editable');
    //               console.log('ROW INDEX: %o, COLUMN INDEX: %o', rowIndex + 1, columnIndex);
    //               newAllEditable.item(columnIndex).focus();
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }, []);
    const focusInEvent = React.useCallback((event) => {
        const eventTarget = event.target;
        if (eventTarget.tagName === 'DIV' && eventTarget.classList.contains('co-editable')) {
            document.querySelectorAll('.co-active-parent').forEach((e) => {
                e.classList.remove('co-active-parent');
            });
            document.querySelectorAll('.co-active').forEach((e) => {
                e.classList.remove('co-active');
            });
            const parent = eventTarget.parentElement;
            if (parent !== null) {
                parent.classList.add('co-active-parent');
            }
            eventTarget.classList.add('co-active');
            eventTarget.setAttribute('tabIndex', '1');
            eventTarget.focus();
        }
    }, []);
    React.useEffect(() => {
        window.addEventListener('focusin', focusInEvent);
    }, [focusInEvent]);
    React.useLayoutEffect(() => {
        document.querySelectorAll('td').forEach((elem) => {
            if (elem.offsetWidth < elem.scrollWidth && elem.textContent !== null) {
                elem.setAttribute('title', elem.textContent);
            }
        });
    }, [isLoading]);
    /**
  * The values of the row selection can be changed programmatically, using React Table's
  * table.resetRowSelection. However, when the value of the checkbox is changed programatically,
  * the tick does NOT disappear. This is because the row doesn't change - only the result of
  * row.getIsSelected(), which is stored in separate variables in the background.
  * As the tick only disappears when clicking, we mimic the click for each row that is selected.
  */
    React.useEffect(() => {
        if (setResetRowSelection !== undefined) {
            setResetRowSelection(() => () => {
                if (bodyRef.current !== null) {
                    const rowElements = bodyRef.current.children;
                    table.getSelectedRowModel().rows.forEach((row) => {
                        const rowElement = rowElements.namedItem((`row-${row.index}`));
                        if (rowElement !== null) {
                            const checkbox = rowElement.getElementsByClassName('tk-checkbox__input')[0];
                            checkbox.click();
                        }
                    });
                }
            });
        }
    }, [setResetRowSelection, table]);
    /**
  * Set the function to reset the row expansion. Either specific rows are collapsed, or if an
  * empty string is used as parameter, then all expanded rows will be collapsed.
  */
    React.useEffect(() => {
        if (setResetRowExpansion !== undefined) {
            setResetRowExpansion(() => (items) => {
                table.getRowModel().rows.forEach((row) => {
                    if (items?.length === 0 && row.getIsExpanded()) {
                        row.toggleExpanded(false);
                    }
                    else if (items?.some((item) => item === row.original)) {
                        row.toggleExpanded(false);
                    }
                });
            });
        }
    }, [setResetRowExpansion, table]);
    const rowVirtualizer = useVirtualizer({
        count: totalDBRowCount,
        getScrollElement: () => tableContainerRef.current,
        getItemKey: (index) => startingPage * fetchSize + index,
        estimateSize: () => 34,
        indexAttribute: 'test',
        initialOffset: 500,
        overscan: 20,
        paddingStart: startingPage * fetchSize * 34,
    });
    React.useEffect(() => {
        if (totalDBRowCount > 0) {
            const body = tableContainerRef.current;
            //      console.log('Initial jump to page %o', startingPage);
            if (body !== null) {
                body.scroll(0, startingPage * fetchSize * 34);
            }
        }
    }, [totalDBRowCount, fetchSize]);
    const getParentNode = React.useCallback((element, tagName) => {
        if (element.tagName === tagName.toUpperCase()) {
            return element;
        }
        let parent = element;
        while (parent.parentElement !== null) {
            parent = parent.parentElement;
            if (parent.tagName === tagName.toUpperCase()) {
                return parent;
            }
        }
        return null;
    }, []);
    const keyDownEvent = React.useCallback((event) => {
        const { key } = event;
        if (document.activeElement !== null && (key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter')) {
            const tableBody = bodyRef.current;
            if (tableBody !== null && !(document.activeElement.tagName === 'INPUT')) {
                event.preventDefault();
                let editable = document.activeElement;
                while (editable !== null && !editable.classList.contains('co-editable')) {
                    editable = editable.parentElement;
                }
                if (editable !== null) {
                    if (key === 'ArrowLeft') {
                        let newCell = editable.previousElementSibling;
                        while (newCell !== null && !newCell.classList.contains('co-editable')) {
                            newCell = newCell.previousElementSibling;
                        }
                        if (newCell !== null) {
                            newCell.focus();
                        }
                    }
                    else if (key === 'ArrowRight') {
                        let newCell = editable.nextElementSibling;
                        while (newCell !== null && !newCell.classList.contains('co-editable')) {
                            newCell = newCell.nextElementSibling;
                        }
                        if (newCell !== null) {
                            newCell.focus();
                        }
                    }
                    else if (key === 'ArrowDown' || key === 'ArrowUp') {
                        const row = getParentNode(editable, 'TR');
                        if (row !== null) {
                            let i = 0;
                            while (row.children.item(i) !== editable) {
                                i += 1;
                            }
                            if (key === 'ArrowDown') {
                                const nextRow = row.nextElementSibling;
                                if (nextRow !== null) {
                                    const ithChild = nextRow.children.item(i);
                                    if (ithChild !== null) {
                                        ithChild.focus();
                                    }
                                }
                            }
                            if (key === 'ArrowUp') {
                                const previousRow = row.previousElementSibling;
                                if (previousRow !== null) {
                                    const ithChild = previousRow.children.item(i);
                                    if (ithChild !== null) {
                                        ithChild.focus();
                                    }
                                }
                            }
                        }
                    }
                    else if (key === 'Enter') {
                        editable.focus();
                    }
                }
                else if (document.activeElement.tagName === 'BODY') {
                    const firstEditable = tableBody.querySelector('.co-editable');
                    if (firstEditable !== null) {
                        firstEditable.focus();
                    }
                }
            }
        }
    }, [getParentNode]);
    React.useEffect(() => {
        const clickCell = (event) => {
            const eventTarget = event.target;
            const parent = getParentNode(eventTarget, 'td');
            document.querySelectorAll('.co-active').forEach((element) => element.classList.remove('co-active'));
            if (parent?.classList.contains('co-edit')) {
                parent?.classList.add('co-active');
            }
        };
        document.addEventListener('click', clickCell);
    }, [getParentNode]);
    React.useEffect(() => {
        document.addEventListener('keydown', keyDownEvent);
    }, [keyDownEvent]);
    React.useEffect(() => {
        document.onkeyup = () => {
            //      console.log('Virtual Items: %o', rowVirtualizer.getVirtualItems());
            //      console.log('Flat Data: %o, Data: %o', flatData, data);
            // rowVirtualizer.scrollToIndex(0);
        };
    }, [data, flatData, rowVirtualizer]);
    React.useEffect(() => {
        //    console.log('Checking if we need to fetch next or previous page:\nVirtual Items: %o\n', rowVirtualizer.getVirtualItems());
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
        if (!lastItem) {
            return;
        }
        if (lastItem.index >= flatData.length - 1
            && hasNextPage
            && !isFetchingNextPage) {
            //      console.log('Getting next page');
            (async () => fetchNextPage())();
        }
        if (rowVirtualizer.getVirtualItems()[0].index === 0 && hasPreviousPage && !isFetchingPreviousPage) {
            //      console.log('There is a previous page but we are not getting it');
            //      (async () => fetchPreviousPage())();
        }
    }, [
        hasNextPage,
        hasPreviousPage,
        fetchNextPage,
        fetchPreviousPage,
        flatData.length,
        isFetchingNextPage,
        isFetchingPreviousPage,
        rowVirtualizer,
    ]);
    const onScroll = React.useCallback((event) => {
        const { currentTarget } = event;
        //  console.log('After Scroll ScrollTop becomes: %o, previous value: %o', currentTarget.scrollTop, scrollY);
        if (scrollY !== undefined) {
            if (Math.abs(scrollY - currentTarget.scrollTop) > 4 * fetchSize * 34) {
                //      console.log('Resetting initial page as scroll jumped too far (more than 4 pages): %o', rowVirtualizer.options);
                const { options } = rowVirtualizer;
                const newPage = Math.round(currentTarget.scrollTop / (34 * fetchSize));
                options.paddingStart = newPage * 34 * fetchSize;
                rowVirtualizer.setOptions(options);
                setInitialPageParam(newPage);
            }
            else {
                //     console.log('We scrolled, and are now checking if we need to get more data');
                const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
                if (!lastItem) {
                    //          console.log('There were no virtual items so we ignore.');
                    return;
                }
                if (lastItem.index >= flatData.length - 1 && hasNextPage && !isFetchingNextPage) {
                    //        console.log('Fetching next page after scroll. Before fetch the first page is %o.\nThe VirtualItems are: %o', startingPage, rowVirtualizer.getVirtualItems());
                    (async () => fetchNextPage())();
                }
                // if (rowVirtualizer.getVirtualItems()[0].index === 0 && hasPreviousPage && !isFetchingPreviousPage && (scrollY > currentTarget.scrollTop)) {
                //   console.log('Fetching previous page after scroll. Before fetch the first page is %o.\nThe VirtualItems are: %o', firstPage, rowVirtualizer.getVirtualItems());
                //   (async () => fetchPreviousPage())();
                // }
            }
        }
        setScrollY(currentTarget.scrollTop);
    }, [
        hasNextPage,
        // hasPreviousPage,
        fetchNextPage,
        // fetchPreviousPage,
        fetchSize,
        flatData.length,
        isFetchingNextPage,
        // isFetchingPreviousPage,
        rowVirtualizer,
        scrollY,
    ]);
    React.useEffect(() => {
        const loc = (new Intl.NumberFormat()).resolvedOptions().locale;
        setRenderValue({
            dateFormat: formatting.dateFormat,
            printBoolean: (flag) => printBoolean(flag, formatting.booleanFormat),
            printDate: (date, precision) => printDate(date, formatting.dateFormat, precision),
            printNumber: (value, options) => Intl.NumberFormat(loc, options).format(value),
        });
    }, [formatting.booleanFormat, formatting.dateFormat]);
    /** SCROLL DETAILS END */
    return (
    // <DndProvider backend={HTML5Backend}>
    React.createElement(RenderingContext.Provider, { value: renderValue },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'column', overflowY: 'auto', height: isLoading ? 'inherit' : 'initial',
            } },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement("div", { style: { display: 'flex' } }, actionElements),
                React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(SettingsButton, { formatting: formatting, meta: settingsMeta, name: settingsName, setFormatting: setFormatting, setMeta: setSettingsMeta, setName: setSettingsName, table: table, tableState: tableState, tableName: name }),
                    React.createElement(ExportButton, { exportHeaders: formatting.exportHeaders, groupData: formatting.groupData, table: table }))),
            React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef },
                React.createElement("div", { style: { height: isLoading || hasError ? `${rowVirtualizer.getTotalSize() + 60}px` : '' } },
                    React.createElement("table", { className: `co-table ${meta?.className ?? ''}`, role: "grid" },
                        React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(DraggableColumnHeader, { header: header, isNumber: typeof table.getRowModel().flatRows[0]?.getValue(header.column.id) === 'number', key: header.id, table: table }))))))),
                        React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } }, !isLoading && !hasError && !isTotalLoading && totalDBRowCount !== 0
                            ? (React.createElement(React.Fragment, null,
                                rowVirtualizer.getVirtualItems().length > 0 ? (React.createElement("tr", null,
                                    React.createElement("td", { "aria-label": "cell", style: { height: `${rowVirtualizer.getVirtualItems()[0].start}px` } })))
                                    : null,
                                rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                    const row = table.getRowModel().rows[virtualRow?.index];
                                    return row !== undefined ? (React.createElement(React.Fragment, { key: virtualRow?.index },
                                        React.createElement("tr", { className: `co-row${meta?.rowClassFn !== undefined ? meta.rowClassFn(row) : ''}`, key: row.id, id: `row-${row?.index}`, style: { height: `${virtualRow.size}px` } }, row.getVisibleCells().map((cell) => {
                                            if (cell.column.columnDef.meta?.isEditable) {
                                                return flexRender(cell.column.columnDef.cell, cell.getContext());
                                            }
                                            return (React.createElement("td", { className: `co-table-cell ${cell.column.columnDef.meta?.className ?? ''}${cell.column.columnDef.meta?.isEditable ? ' co-edit' : ''}`, key: cell.id, role: "gridcell", style: cell.column.columnDef.meta?.style }, flexRender(cell.column.columnDef.cell, cell.getContext())));
                                        })),
                                        row.getIsExpanded() && typeof subComponent === 'function' ? subComponent(row, table) : null)) : React.createElement("tr", { className: "co-row", key: virtualRow?.index },
                                        React.createElement("td", null, "LOADING..."));
                                }),
                                rowVirtualizer.getVirtualItems().length > 0 ? (React.createElement("tr", null,
                                    React.createElement("td", { "aria-label": "cell", style: { height: `${rowVirtualizer.getVirtualItems()[rowVirtualizer.getVirtualItems().length - 1].end}px` } }))) : null))
                            : (React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement("div", { className: "loader-wrapper" }, isLoading || isTotalLoading
                                        ? React.createElement(Loader, { className: "co-center-page", variant: isLoading ? 'primary' : 'attention' })
                                        : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage))))))))))));
};
export default InfiniteTable;
