import * as React from 'react';
import CustomTable from 'components/custom-table/CustomTable';
import { BLSColumns, BOECOlumns, ECBColumns, IFOColumns, MARKITColumns, } from './releaseSources';
const ReleaseSourceTable = ({ releaseSource, releases, emptyMessage, errorMessage, hasError, isLoading, }) => {
    switch (releaseSource) {
        case 'BLS':
            return (React.createElement(CustomTable, { columns: BLSColumns, data: releases, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "blsReleases" }));
        case 'BOE':
            return (React.createElement(CustomTable, { columns: BOECOlumns, data: releases, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "boeReleases" }));
        case 'ECB':
            return (React.createElement(CustomTable, { columns: ECBColumns, data: releases, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "ecbReleases" }));
        case 'IFO':
            return (React.createElement(CustomTable, { columns: IFOColumns, data: releases, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "ifoReleases" }));
        case 'MARKIT':
            return (React.createElement(CustomTable, { columns: MARKITColumns, data: releases, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, name: "markitReleases" }));
        default:
            return undefined;
    }
};
export default ReleaseSourceTable;
