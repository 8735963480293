import Period from './Period';
class PeriodRange {
    start;
    end;
    constructor({ start, end }) {
        if (start !== undefined) {
            this.start = new Period(start);
        }
        if (end !== undefined) {
            this.end = new Period(end);
        }
    }
    static from(start, end) {
        return new PeriodRange({ start: Period.from(start), end: Period.from(end) });
    }
    toString() {
        if (this.start !== undefined && this.end !== undefined) {
            if (this.start.period === this.end.period
                && this.start.periodMultiplier === this.end.periodMultiplier) {
                return this.start.toString();
            }
            return `from ${this.start.toString()} to ${this.end.toString()}`;
        }
        if (this.start !== undefined && this.end === undefined) {
            return `from ${this.start.toString()}`;
        }
        if (this.start === undefined && this.end !== undefined) {
            return `up to ${this.end.toString()}`;
        }
        return 'Any';
    }
    static isEmpty(periodRange) {
        return periodRange.start === undefined && periodRange.end === undefined;
    }
}
export default PeriodRange;
