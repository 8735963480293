import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import TableDnd from '../regexes/regex-viewer/regex-table/TableDnd';
import EditableNameTextCell from './EditableNameTextCell';
import NameAddModal from './NameAddModal';
import ConstantDisplayName from './ConstantDisplayName';
const ConstantNameTable = ({ constant }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [names, setNames] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [displayName, setDisplayName] = React.useState('');
    React.useEffect(() => {
        const { id } = constant;
        if (id !== undefined) {
            setNames(constant.names.map((name, index) => ({ id, index, name })));
        }
    }, [constant]);
    const meta = {
        updateTable: (updatedNames) => setNames(updatedNames),
    };
    const deleteFn = React.useCallback(({ row: { original } }) => {
        const { id } = original;
        const url = new ApiServerURL('/syndicate/constant/name');
        url.searchParams.append('id', id);
        url.searchParams.append('index', original.index.toString());
        const request = new ServerRequest(url, { method: 'DELETE' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const updatedNames = body.names.map((name, index) => ({ id, index, name }));
                setNames(updatedNames);
            }
            catch (error) {
                sendFetchError('Unable to delete constant name', error, request);
            }
        })();
    }, [apiFetchJson]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorFn: (t) => t.name,
            cell: EditableNameTextCell,
            id: 'name',
            size: 1000,
        },
    ], [deleteFn]);
    const setData = React.useCallback((d) => {
        const constantClone = { ...constant };
        constantClone.names = d.map((n) => n.name);
        const url = new ApiServerURL('/syndicate/constant');
        const request = new ServerRequest(url, {
            body: JSON.stringify(constantClone),
            headers: { 'content-type': 'application/json' },
            method: 'PUT',
        });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const { id } = body;
                if (id !== undefined) {
                    const updatedNames = body.names.map((name, index) => ({ id, index, name }));
                    setNames(updatedNames);
                }
            }
            catch (error) {
                sendFetchError('Unable to update constant info', error, request);
            }
        })();
    }, [constant, apiFetchJson]);
    const addName = React.useCallback(async (value) => {
        const { id } = constant;
        if (id !== undefined) {
            const url = new ApiServerURL('/syndicate/constant/name');
            url.searchParams.append('id', id);
            url.searchParams.append('name', value);
            const request = new ServerRequest(url, { method: 'PUT' });
            try {
                const body = await apiFetchJson(request);
                const updatedNames = body.names.map((name, index) => ({ id, index, name }));
                setNames(updatedNames);
            }
            catch (error) {
                sendFetchError('Unable to update constant', error, request);
                //
            }
        }
    }, [constant, apiFetchJson]);
    const onChangeDisplayName = React.useCallback((event) => {
        setDisplayName(event.target.value);
    }, []);
    const onClickAdd = React.useCallback(() => setShowModal(true), []);
    return (React.createElement("div", null,
        showModal
            ? (React.createElement(NameAddModal, { addName: addName, closeModal: () => setShowModal(false) }))
            : undefined,
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', padding: '0.5rem' } },
            React.createElement(ConstantDisplayName, { id: constant.id, displayName: constant?.displayName }),
            React.createElement(Button, { onClick: onClickAdd }, "ADD")),
        React.createElement(TableDnd, { actionElements: [], columns: columns, data: names, emptyMessage: "Nothing to see here", errorMessage: "oops we have an errors", hasError: false, isLoading: false, meta: meta, setData: setData })));
};
export default ConstantNameTable;
