import ActionButton from 'components/custom-table/action/ActionButton';
import * as React from 'react';
import AnnaCompany from '../../models/company/AnnaCompany';
import CompanyTag from '../../models/company/CompanyTag';
const companiesTableActionCell = (setActualCompanyTags, setCompany, source) => function func(context) {
    const companyTags = ({ row: { original } }) => {
        const newCompanyTags = CompanyTag.fromCompany(original, source);
        if (newCompanyTags !== undefined) {
            setActualCompanyTags(newCompanyTags);
        }
    };
    const actionsMenu = [{
            callback: companyTags,
            label: 'Edit Company Tags',
        }];
    const addressTest = (address) => {
        if (address.address1?.includes('��'))
            return true;
        if (address.address2?.includes('��'))
            return true;
        if (address.city?.includes('��'))
            return true;
        if (address.country.includes('��'))
            return true;
        if (address.postalCode?.includes('��'))
            return true;
        if (address.state?.includes('��'))
            return true;
        return false;
    };
    const c = context.row.original;
    if (c instanceof AnnaCompany) {
        const details = ({ row: { original: rowCompany } }) => {
            if (rowCompany instanceof AnnaCompany) {
                setCompany(rowCompany);
            }
        };
        if (c.nameLong.includes('��') || (addressTest(c.reg)) || (c.hq !== undefined && addressTest(c.hq))) {
            actionsMenu.push({
                callback: details,
                label: 'Correct Diacriticals',
            });
        }
    }
    return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
};
export default companiesTableActionCell;
