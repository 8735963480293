import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import TickerPicker from './TickerPicker';
const TickerEntry = ({ callback, setTranche, tranche }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isLoading, setLoading] = React.useState(false);
    const [tickers, setTickers] = React.useState();
    const lei = React.useMemo(() => tranche.overrideMap?.lei ?? tranche.meta?.lei, [tranche.meta?.lei, tranche.overrideMap?.lei]);
    const saveTicker = React.useCallback((ticker) => {
        const formData = new FormData();
        formData.append('ticker', ticker);
        formData.append('transactionId', tranche.transaction.id);
        formData.append('trancheIndex', tranche.trancheIndex.toString());
        const request = new ServerRequest('/syndicate/meta/ticker', { body: formData, method: 'PATCH' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const newTranches = body.map((t) => new TrancheWrapper(t));
                setTranche(newTranches[tranche.trancheIndex]);
                if (callback !== undefined) {
                    callback(newTranches);
                }
                setTickers(undefined);
            }
            catch (error) {
                sendFetchError('Unable to save ticker', error, request);
            }
        })();
    }, [apiFetchJson, callback, setTranche, tranche.trancheIndex, tranche.transaction.id]);
    const onClickTicker = React.useCallback(() => {
        if (lei !== undefined) {
            const url = new ApiServerURL('/syndicate/meta/ticker');
            url.searchParams.append('lei', lei);
            const request = new ServerRequest(url);
            (async () => {
                setLoading(true);
                try {
                    const body = await apiFetchJson(request);
                    if (body.length === 1) {
                        saveTicker(body[0]);
                    }
                    else {
                        setTickers(body);
                    }
                    setLoading(false);
                }
                catch (error) {
                    sendFetchError('Unable to download tickers', error, request);
                }
            })();
        }
    }, [apiFetchJson, lei, saveTicker]);
    return (React.createElement("div", null,
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement("div", { className: "tk-label" }, "Ticker"),
                tickers === undefined ? (React.createElement("div", null, tranche.meta?.ticker)) : undefined,
                tickers !== undefined && tickers.length === 0 ? (React.createElement("div", { style: { fontStyle: 'italic', paddingLeft: '0.5rem', color: '#dd342e' } }, "None found for LEI")) : undefined),
            React.createElement(Button, { iconButton: true, loading: isLoading, onClick: onClickTicker, size: "small" },
                React.createElement(Icon, { iconName: "reload" }))),
        tickers !== undefined && tickers.length > 1
            ? React.createElement(TickerPicker, { tickers: tickers, saveTicker: saveTicker }) : undefined));
};
export default TickerEntry;
