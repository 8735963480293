import * as React from 'react';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import Metadata from 'components/Metadata';
import PermissionsModal from 'components/permissions/PermissionsModal';
const MetadataButtons = ({ disabled, data, onChange }) => {
    const [modalContent, setModalContent] = React.useState();
    const hideModal = React.useCallback(() => {
        setModalContent(undefined);
    }, []);
    const clickShowMeta = React.useCallback(() => {
        setModalContent(React.createElement(Metadata, { hideModal: hideModal, value: data }));
    }, [data, hideModal]);
    const clickShowPermissions = React.useCallback(() => {
        setModalContent(React.createElement(PermissionsModal, { hideModal: hideModal, onChange: onChange, value: data?.permissions }));
    }, [data?.permissions, hideModal, onChange]);
    return (React.createElement("div", { className: "co-form-buttons" },
        modalContent,
        data?.id ? (React.createElement(Button, { iconButton: true, onClick: clickShowMeta },
            React.createElement(Icon, { iconName: "info-round" }))) : '',
        !disabled ? (React.createElement(Button, { iconButton: true, onClick: clickShowPermissions },
            React.createElement(Icon, { iconName: "people" }))) : ''));
};
export default MetadataButtons;
