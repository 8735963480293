class SyndicateService {
    updateId;
    constructor() {
        const saved = localStorage.getItem('updateId');
        this.updateId = saved !== null ? JSON.parse(saved) : undefined;
    }
    removeUpdateId = () => {
        this.updateId = undefined;
        localStorage.removeItem('updateId');
    };
    setUpdateId = (updateId) => {
        this.updateId = updateId;
        if (updateId !== undefined) {
            localStorage.setItem('updateId', JSON.stringify(updateId));
        }
        else {
            localStorage.removeItem('updateId');
        }
    };
    getUpdateId() {
        return this.updateId;
    }
    updateIndex = (index) => {
        if (this.updateId !== undefined) {
            this.setUpdateId({ transactionId: this.updateId.transactionId, index });
        }
    };
    isStart = () => this.updateId === undefined || this.updateId.index === 0;
}
export default SyndicateService;
