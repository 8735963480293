function createHTMLTable(gridHeaders, gridData, exportHeaders, groupData) {
    const table = document.createElement('table');
    if (exportHeaders) {
        const thead = document.createElement('thead');
        const header = document.createElement('tr');
        header.setAttribute('style', 'font-weight: 800');
        gridHeaders.forEach((gridHeader) => {
            const cell = document.createElement('td');
            cell.innerText = gridHeader.header;
            header.appendChild(cell);
        });
        table.appendChild(thead);
        thead.appendChild(header);
    }
    table.setAttribute('style', 'height: 28.5px');
    const tbody = document.createElement('tbody');
    tbody.setAttribute('style', 'height: 28.5px; text-align: center;');
    gridData.forEach((dataRow, rowIndex) => {
        const row = document.createElement('tr');
        row.setAttribute('style', 'height: 28.5px; text-align: center;');
        const { id } = dataRow;
        const hasSimilarPreceding = rowIndex > 0 && gridData[rowIndex - 1].id === id;
        let numberSimilarSucceeding = 1;
        while (rowIndex + numberSimilarSucceeding < gridData.length && gridData[rowIndex + numberSimilarSucceeding].id === id) {
            numberSimilarSucceeding += 1;
        }
        dataRow.data.forEach((dataCell, index) => {
            const gridHeader = gridHeaders[index];
            // console.log('GRID HEADER: %o = %o', gridHeaders, index);
            // console.log('Can combine: %o, length: %o, indexWithinRows: %o', gridHeader?.meta?.canCombine, rowsWithid.length, indexWithinRows);
            if (groupData && gridHeader.meta?.canCombine && !hasSimilarPreceding && numberSimilarSucceeding > 1) {
                // console.log('Creating cell with height: %d', rowsWithid.length);
                const cell = document.createElement('td');
                cell.setAttribute('rowSpan', numberSimilarSucceeding.toString());
                if (dataCell !== undefined) {
                    cell.setAttribute('style', 'text-align: center; font-weigth: 600');
                    //            cell.setAttribute('style', 'padding-top: 1px; padding-right: 1px; padding-left: 1px; color: black; font-size: 11pt; font-weight: 400; font-style: normal; text-decoration: none; font-family: Arial, sans-serif; vertical-align: middle; border: none; white-space: nowrap; text-align: right; width: 65pt; height: 24pt;');
                    cell.innerText = dataCell;
                }
                row.appendChild(cell);
            }
            else if (!groupData || !gridHeader.meta?.canCombine || !hasSimilarPreceding) {
                const cell = document.createElement('td');
                if (dataCell !== undefined) {
                    cell.setAttribute('style', 'text-wrap: wrap');
                    // cell.setAttribute('style', 'padding-top: 1px; padding-right: 1px; padding-left: 1px; color: black; font-size: 11pt; font-weight: 400; font-style: normal; text-decoration: none; font-family: Arial, sans-serif; vertical-align: middle; border: none; white-space: nowrap; text-align: right; width: 65pt; height: 24pt;');
                    cell.innerText = dataCell;
                }
                row.appendChild(cell);
            }
        });
        tbody.appendChild(row);
    });
    table.appendChild(tbody);
    return table;
}
async function copySpreadSheet(gridHeaders, gridData, exportHeaders, groupData, printDate) {
    const mapCell = (cell) => {
        if (typeof cell === 'object' && cell instanceof Date) {
            return printDate(cell, 'YMD');
        }
        if (typeof cell === 'string') {
            return cell;
        }
        if (typeof cell === 'number') {
            return cell.toString();
        }
        return '';
    };
    const gridText = gridData.map((row) => ({ data: row.data.map(mapCell), id: row.id }));
    const myTable = createHTMLTable(gridHeaders, gridText, exportHeaders, groupData);
    const t = { type: 'text/html' };
    const p = myTable.outerHTML;
    const blob = new Blob([p], t);
    const items = new ClipboardItem({ 'text/html': blob });
    await navigator.clipboard.write([items]);
}
export default copySpreadSheet;
