import * as React from 'react';
import LoadingPage from 'components/loading-page/LoadingPage';
import DownloadContext from 'contexts/DownloadContext';
import NavigationContext from 'contexts/NavigationContext';
import UserTable from './UserTable';
import UserForm from './UserForm';
import User from '../../models/User';
import { sendFetchError } from 'utils/messageUtils';
import ServerRequest from 'models/ServerRequest';
const Users = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [user, setUser] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest("/admin/users");
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const downloadedUsers = body.map((u) => new User(u));
                setUsers(downloadedUsers);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setError(true);
                    sendFetchError("Unable to download users", error, request);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const create = React.useCallback(() => {
        setUser(User.NEW);
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: newUser } }) => {
        const request = new ServerRequest('/admin/users', { body: JSON.stringify(newUser), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredUsers = users !== undefined ? users.filter((u) => u.id !== newUser.id) : [];
                setUsers(filteredUsers);
            }
            catch (error) {
                sendFetchError("Unable to delete user", error, request);
            }
        })();
    }, [apiFetchNoContent, users]);
    const edit = React.useCallback(({ row: { original: newUser } }) => {
        setUser(newUser);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback((isUser) => async (data) => {
        const userNew = data;
        if (!isUser && user !== undefined) {
            delete user.authorityMap;
            delete user.username;
            delete user.password;
        }
        const method = data.id ? "PATCH" : "POST";
        const request = new ServerRequest('/admin/users', { body: JSON.stringify(userNew), headers: { 'Content-Type': 'application/json' }, method });
        try {
            const response = await apiFetchJson(request);
            const updatedUser = new User(response);
            const index = (users ?? []).findIndex(d => d.id === updatedUser.id);
            const updatedUsers = [...(users ?? [])];
            if (index === -1) {
                updatedUsers.push(updatedUser);
            }
            else {
                updatedUsers[index] = updatedUser;
            }
            setUsers(updatedUsers);
            updateView('TABLE');
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError("Unable to " + (data.id ? "update" : "create") + " user", error, request);
        }
    }, [apiFetchJson, updateView, user, users]);
    React.useEffect(() => {
        if (view !== 'FORM' && view !== 'TABLE') {
            updateView('TABLE');
        }
    }, [updateView, view]);
    switch (view) {
        case 'FORM':
            return (React.createElement(UserForm, { save: save, user: user }));
        case 'TABLE':
            return (React.createElement(UserTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, users: users }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Users;
