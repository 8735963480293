import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import Country from '../../models/Country';
import CountryFormModal from './CountryFormModal';
const CountryTable = ({ deleteFn, hasError, isLoading, countries, optionsRegion, save }) => {
    const [showFormModal, setShowFormModal] = React.useState(false);
    const [country, setCountry] = React.useState();
    const edit = React.useCallback(({ row: { original: country } }) => {
        setCountry(country);
        setShowFormModal(true);
    }, []);
    const deleteCallback = React.useCallback(({ row: { original: country } }) => {
        (async () => deleteFn(country))();
    }, [deleteFn]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteCallback, label: 'Delete', type: 'error' },
                ],
            },
            size: 20
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorFn: (country) => optionsRegion.find((t) => country.region === t.value)?.label,
            header: 'Region',
            id: 'region',
        }
    ], [deleteCallback, edit, optionsRegion]);
    const onCloseForm = React.useCallback(() => {
        setShowFormModal(false);
    }, []);
    const onClickCreate = React.useCallback(() => {
        setCountry(Country.create());
        setShowFormModal(true);
    }, []);
    const actionElements = React.useMemo(() => {
        return [
            React.createElement(Button, { disabled: hasError, onClick: onClickCreate, size: "small", style: { marginLeft: '0.5rem' } }, "CREATE")
        ];
    }, [onClickCreate, hasError]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Countries")),
        showFormModal && country !== undefined ?
            React.createElement(CountryFormModal, { onClose: onCloseForm, country: country, optionsRegion: optionsRegion, save: save }) : undefined,
        React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: countries, emptyMessage: "You have no countries yet", errorMessage: "Error downloading countries", hasError: hasError, isLoading: isLoading, name: "country" })));
};
export default CountryTable;
