import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { optionSort } from 'utils/table';
import { sendFetchError } from 'utils/messageUtils';
import ItemRegex from '../../../models/regexes/ItemRegex';
import LineRegex from '../../../models/regexes/LineRegex';
import Regex from '../../../models/regexes/Regex';
import SubjectRegex from '../../../models/regexes/SubjectRegex';
import KeyValueRegex from '../../../models/regexes/KeyValueRegex';
import { escapeRegex, unescapeRegex } from '../../../models/regexes/regexUtils';
import MarkerDropdown from './MarkerDropdown';
import './RegexFormModal.scss';
import useOption from 'components/customHooks';
const RegexFormModal = ({ callback, hideModal, originalRegex, regex, title, type, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isSaveLoading, setSaveLoading] = React.useState(false);
    const [testList, setTestList] = React.useState([]);
    const [newRegex, setNewRegex] = React.useState(regex !== undefined ? new Regex(regex) : undefined);
    const options = useOption('/syndicate/regexes/markers');
    /**
     * Note - this cannot be memoized as it will only be recalculated if the length of the markers array changes but not the individual elements.
     */
    const canSave = newRegex?.markers.some((m) => m === null);
    const onChangeMarker = React.useCallback((m) => (event) => {
        const { value } = event.target.value;
        if (newRegex !== undefined) {
            const newRegex2 = newRegex.updateMarker(m, value);
            setNewRegex(newRegex2);
        }
        const newTestList = [...testList];
        newTestList[m].className = '';
        setTestList(newTestList);
        (async () => {
            const url = new ApiServerURL('/syndicate/regexes/test');
            url.searchParams.append('text', newTestList[m].text);
            url.searchParams.append('marker', value);
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request);
                const newNewTestList = [...testList];
                if (body.fieldOptions !== undefined) {
                    newNewTestList[m] = {
                        className: 'co-test-warning',
                        field: body.field,
                        fieldId: undefined,
                        options: body.fieldOptions.sort(optionSort),
                        text: testList[m].text,
                    };
                }
                else {
                    newNewTestList[m].className = body.result ? 'co-test-success' : 'co-test-error';
                }
                newNewTestList[m].text = testList[m].text;
                setTestList(newNewTestList);
            }
            catch (error) {
                sendFetchError('Unable to test the marker', error, request);
            }
        })();
    }, [apiFetchJson, newRegex, setNewRegex, testList]);
    const onClickDeleteMarker = React.useCallback((m) => () => {
        const regexClone = newRegex?.removeMarker(m, testList[m].text);
        const cloneTest = [...testList];
        cloneTest.splice(m, 1);
        setTestList(cloneTest);
        setNewRegex(regexClone);
    }, [newRegex, testList]);
    const removeFieldData = React.useCallback((m) => () => {
        const newTestList = [...testList];
        delete newTestList[m].fieldId;
        delete newTestList[m].options;
        newTestList[m].className = 'co-test-success';
        setTestList(newTestList);
    }, [setTestList, testList]);
    const onChangeDropdown = React.useCallback((m) => (event) => {
        const newTestList = [...testList];
        newTestList[m].fieldId = event.target.value.value;
        setTestList(newTestList);
    }, [setTestList, testList]);
    const modalRef = React.useRef(null);
    const modalTextRef = React.useRef(null);
    const checkSelection = React.useCallback(() => {
        const selection = window.getSelection();
        // If the selection is across nodes we ignore it
        if (selection?.type === 'Range' && selection.anchorNode === selection.focusNode) {
            // if selection is the subject's full text, we amend the regex. Oth
            if (selection.anchorNode?.parentElement === modalTextRef.current) {
                const newRegex2 = new Regex({ template: selection.toString() });
                setNewRegex(newRegex2);
            }
            else if (selection.anchorNode?.parentElement === modalRef.current) {
                if (selection.toString().endsWith('{')) {
                    selection.modify('extend', 'right', 'character');
                }
                if (selection.toString().startsWith('}')) {
                    selection.modify('extend', 'left', 'character');
                }
                let startOffset = Math.min(selection.anchorOffset, selection.focusOffset);
                let endOffset = Math.max(selection.anchorOffset, selection.focusOffset);
                const startNode = selection.anchorOffset < selection.focusOffset
                    ? selection.anchorNode : selection.focusNode;
                const endNode = selection.anchorOffset > selection.focusOffset
                    ? selection.anchorNode : selection.focusNode;
                if (startNode !== null && endNode !== null) {
                    if (selection.toString().endsWith(' ')) {
                        endOffset -= 1;
                        selection.setBaseAndExtent(startNode, startOffset, endNode, endOffset);
                    }
                    if (selection.toString().startsWith(' ')) {
                        startOffset += 1;
                        selection.setBaseAndExtent(startNode, startOffset, endNode, endOffset);
                    }
                }
                const text = selection.toString();
                const oldText = unescapeRegex(newRegex?.template ?? '');
                const value = `${oldText.substring(0, startOffset)}{}${oldText.substring(endOffset)}`;
                const countPreceding = (newRegex?.template.substring(0, startOffset).match(/{}/g) ?? []).length;
                // if 'text' contains {} then we need to replace those
                let newText = text;
                const countIncluded = (text.match(/{}/g) ?? []).length;
                (text.match(/{}/g) ?? []).forEach((t, index) => {
                    newText = newText.replace(t, testList[countPreceding + index].text);
                });
                const newTextList = [...testList];
                newTextList.splice(countPreceding, countIncluded, { className: '', text: newText });
                if (newRegex !== undefined) {
                    const newMarkers = [...newRegex.markers];
                    newMarkers.splice(countPreceding, countIncluded, null);
                    const newRegex2 = new Regex({ markers: newMarkers, template: escapeRegex(value) });
                    setNewRegex(newRegex2);
                }
                else {
                    //
                }
                setTestList(newTextList);
            }
        }
    }, [newRegex, setNewRegex, testList]);
    React.useEffect(() => {
        document.addEventListener('mouseup', checkSelection);
        return () => document.removeEventListener('mouseup', checkSelection);
    }, [checkSelection]);
    const saveRegex = React.useCallback(() => {
        if (newRegex !== undefined) {
            setSaveLoading(true);
            (async () => {
                let updatedRegex;
                switch (type) {
                    case 'keyvalues':
                        {
                            if (originalRegex === undefined) {
                                throw new Error('Keyvalues must have an originalRegex');
                            }
                            const formData = new FormData();
                            if (originalRegex.id !== undefined) {
                                formData.append("id", originalRegex.id);
                            }
                            formData.append('regex', new Blob([JSON.stringify(newRegex)], { type: 'application/json' }));
                            originalRegex.add(newRegex);
                            const request = new ServerRequest('/syndicate/regex/keyvalues/add-regex', { body: formData, method: 'PATCH' });
                            const body = await apiFetchJson(request);
                            updatedRegex = new KeyValueRegex(body);
                        }
                        break;
                    case 'listitems':
                        {
                            if (originalRegex !== undefined) {
                                originalRegex.add(newRegex);
                            }
                            const toUpdate = originalRegex ?? new ItemRegex({ regex: newRegex });
                            const method = toUpdate.id ? 'PATCH' : 'POST';
                            const request = new ServerRequest('/syndicate/regex/listitems', { body: JSON.stringify(toUpdate), headers: { 'Content-Type': 'application/json' }, method });
                            const body = await apiFetchJson(request);
                            updatedRegex = new ItemRegex(body);
                        }
                        break;
                    case 'paragraphs':
                        {
                            if (originalRegex === undefined) {
                                throw new Error('Paragraphs must have an originalRegex');
                            }
                            originalRegex.add(newRegex);
                            const method = originalRegex.id ? 'PATCH' : 'POST';
                            const request = new ServerRequest('/syndicate/regex/paragraphs', { body: JSON.stringify(originalRegex), headers: { 'Content-Type': 'application/json' }, method });
                            const body = await apiFetchJson(request);
                            updatedRegex = new LineRegex(body);
                        }
                        break;
                    case 'subject':
                        {
                            const toUpdate = new SubjectRegex({ regex: newRegex });
                            const request = new ServerRequest('/syndicate/regex/subject', { body: JSON.stringify(toUpdate), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
                            const body = await apiFetchJson(request);
                            updatedRegex = new SubjectRegex(body);
                        }
                        break;
                    default:
                        throw new Error("HI");
                }
                callback(updatedRegex);
                document.dispatchEvent(new Event('reloaddata'));
                setSaveLoading(false);
                hideModal();
            })();
        }
    }, [apiFetchJson, callback, hideModal, originalRegex, newRegex, type]);
    const markerList = React.useMemo(() => {
        return newRegex?.markers.map((m, index) => {
            return React.createElement(MarkerDropdown, { index: index, key: `marker${index.toString()}`, onChangeDropdown: onChangeDropdown(index), onChangeMarker: onChangeMarker(index), onClickDeleteMarker: onClickDeleteMarker(index), options: options, removeFieldData: removeFieldData(index), testListItem: testList[index], value: m });
        });
    }, [onChangeDropdown, onChangeMarker, onClickDeleteMarker, removeFieldData, options, newRegex?.markers, testList]);
    return (React.createElement(Modal, { style: { userSelect: 'none' }, closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalTitle, null, title),
        React.createElement(ModalBody, null,
            type === 'subject' ? (React.createElement("div", { className: "co-text-container" },
                React.createElement("p", { ref: modalTextRef }, unescapeRegex(regex?.template ?? '')))) : null,
            React.createElement("div", { className: "co-text-container" },
                React.createElement("p", { ref: modalRef }, unescapeRegex(newRegex?.template ?? ''))),
            markerList),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: canSave, loading: isSaveLoading, onClick: saveRegex }, "SAVE"))));
};
export default RegexFormModal;
