import * as React from 'react';
import { BannerType, Button, Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import MetadataButtons from 'components/metadata/MetadataButtons';
import DownloadContext from 'contexts/DownloadContext';
import { getOptions } from 'utils/change';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import UserContext from '../../../core/contexts/UserContext';
import { Source } from '../../sources/sources';
import EsmaSearch from '../../models/search/EsmaSearch';
import EuroclearSearch from '../../models/search/EuroclearSearch';
import AnnaSearch from '../../models/search/AnnaSearch';
import SecSearch from '../../models/search/SecSearch';
import SearchSaveButton from './search-form/SearchSaveButton';
import SecForm from './sec-search-form/SecForm';
import EsmaForm from './esma-search-form/EsmaForm';
import AnnaForm from './anna-search-form/AnnaForm';
import EuroclearForm from './euroclear-search-form/EuroclearForm';
const SearchForm = ({ anySearch, save, source, setAnySearch, setSource, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const { checkEditable } = React.useContext(UserContext);
    const [disabled, setDisabled] = React.useState(false);
    const [isSearching, setSearching] = React.useState(false);
    const databaseOptions = React.useMemo(() => [
        { label: 'Euroclear', value: Source.EUROCLEAR },
        { label: 'ESMA', value: Source.ESMA },
        { label: 'ANNA', value: Source.ANNA },
        { label: 'SEC', value: Source.SEC },
        { label: 'IPO', value: Source.IPO },
    ], []);
    React.useEffect(() => {
        if (anySearch?.id) {
            const isEditable = checkEditable(anySearch);
            setDisabled(!isEditable);
        }
    }, [anySearch, checkEditable]);
    const changeDatabase = React.useCallback((event) => {
        setSource(event.target.value.value);
    }, [setSource]);
    const changePermissions = React.useCallback((event) => {
        if (anySearch !== undefined) {
            const newSearch = anySearch.updatePermissions(event);
            setAnySearch(newSearch);
        }
    }, [anySearch, setAnySearch]);
    const clickClear = React.useCallback(() => {
        setAnySearch(undefined);
        setDisabled(false);
    }, [setAnySearch]);
    const clickSearch = React.useCallback(() => {
        if (anySearch !== undefined) {
            setSearching(true);
            const searchRequest = {
                createdBy: anySearch?.createdBy,
                createdDate: anySearch.createdDate,
                filter: anySearch.filter,
                id: anySearch.id,
                name: anySearch.name,
                permissions: anySearch.permissions,
                query: anySearch.query,
            };
            const request = new ServerRequest(`/nit/${source.toLowerCase()}/query`, { body: JSON.stringify(searchRequest), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    sendMessage(`${body.length} securities found. Please view securities on the Results page.`, BannerType.SUCCESS);
                }
                catch (error) {
                    sendFetchError('Unable to perform search', error, request);
                }
                setSearching(false);
            })();
        }
    }, [anySearch, apiFetchJson, source]);
    const clickTable = React.useCallback(() => {
        updateView('TABLE');
    }, [updateView]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, anySearch?.name ? anySearch.name : 'Search')),
        React.createElement("div", { className: "co-container co-item" },
            React.createElement(Dropdown, { className: "co-dropdown", isTypeAheadEnabled: false, label: "Database", onChange: changeDatabase, options: databaseOptions, value: getOptions(source, databaseOptions) })),
        (() => {
            switch (source) {
                case Source.ANNA: return (React.createElement(AnnaForm, { search: anySearch instanceof AnnaSearch ? anySearch : new AnnaSearch(), setSearch: setAnySearch }));
                case Source.ESMA: return (React.createElement(EsmaForm, { search: anySearch instanceof EsmaSearch ? anySearch : new EsmaSearch(), setSearch: setAnySearch }));
                case Source.SEC: return (React.createElement(SecForm, { search: anySearch instanceof SecSearch ? anySearch : new SecSearch(), setSearch: setAnySearch }));
                case Source.EUROCLEAR:
                    return (React.createElement(EuroclearForm, { search: anySearch instanceof EuroclearSearch ? anySearch : new EuroclearSearch(), setSearch: setAnySearch }));
                default:
                    throw new Error(`Unknown source: ${source}`);
            }
        })(),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement("div", { className: "co-form-buttons" },
                React.createElement(Button, { loading: isSearching, onClick: clickSearch }, "Search"),
                React.createElement(SearchSaveButton, { disabled: disabled, save: save, search: anySearch }),
                React.createElement(Button, { onClick: clickTable }, "Open"),
                React.createElement(Button, { onClick: clickClear }, "Clear")),
            React.createElement(MetadataButtons, { disabled: disabled, onChange: changePermissions, data: anySearch }))));
};
export default SearchForm;
