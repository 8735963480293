import * as React from 'react';
import { TextField, Validation } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import Benchmark from 'models/Benchmark';
import { errorMessage } from 'utils/initialValidation';
import FormWrapper from 'components/form-wrapper/FormWrapper';
const BenchmarkForm = ({ benchmark, save }) => {
    const [data, setData] = React.useState(benchmark ?? new Benchmark());
    const [errorState, setErrorState] = React.useState();
    const validators = React.useMemo(() => ({
        name: [Validators.Required],
    }), []);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onValidationChange = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Benchmark", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "benchmark-form" },
            React.createElement(Validation, { validator: validators.name, errorMessage: errorMessage, onValidationChanged: onValidationChange('name') },
                React.createElement(TextField, { label: "Name", onChange: onChangeInput('name'), value: data.name || '' })))));
};
export default BenchmarkForm;
