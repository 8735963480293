import * as React from 'react';
import Scheduling from 'pages/scheduling/Scheduling';
import NavigationContext from 'contexts/NavigationContext';
import NoSuchTab from 'components/NoSuchTab';
import Companies from 'pages/companies/Companies';
import Currencies from 'pages/currencies/Currencies';
import Benchmarks from 'pages/benchmarks/Benchmarks';
import Monitor from 'pages/monitor/Monitor';
import Users from './Users';
const AdminApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { auth: ['SUPER_ADMIN'], code: 'users', label: 'Users' },
        { auth: ['SUPER_ADMIN'], code: 'monitor', label: 'Monitor' },
        { code: 'scheduling', label: 'Scheduling' },
        { code: 'companies', label: 'Companies' },
        { code: 'currencies', label: 'Currencies' },
        { code: 'benchmarks', label: 'Benchmarks' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'users':
            return React.createElement(Users, null);
        case 'monitor':
            return React.createElement(Monitor, null);
        case 'scheduling':
            return React.createElement(Scheduling, null);
        case 'companies':
            return React.createElement(Companies, null);
        case 'currencies':
            return React.createElement(Currencies, null);
        case 'benchmarks':
            return React.createElement(Benchmarks, null);
        default:
            return (React.createElement(NoSuchTab, { tab: tab }));
    }
};
export default AdminApp;
