import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import TransactionWrapper from '../../../models/TransactionWrapper';
import EditableCell from '../overview/table/EditableCell';
const TransactionCellDropdown = ({ cell, column, row, table, }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setvalue] = React.useState(row.original[column.id]);
    const [isLoading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [className, setClassName] = React.useState('');
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/meta-update');
            url.searchParams.append('transactionId', row.original.id);
            url.searchParams.append('key', column.id);
            if (newValue !== undefined) {
                url.searchParams.append('value', newValue);
            }
            setLoading(true);
            setDisabled(true);
            const request = new ServerRequest(url, { method: newValue !== undefined ? 'PATCH' : 'DELETE' });
            try {
                const body = await jsonPlus(request, 'Unable to save override information');
                const transaction = new TransactionWrapper(body);
                table.options.meta.updateTable(transaction);
                setvalue(transaction[column.id]);
            }
            catch (error) {
                setErrorText('Unable to save');
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, jsonPlus, row.original.id, table]);
    const onChange = React.useCallback((event) => {
        const eventValue = event.target.value;
        if (eventValue !== null) {
            const newValue = eventValue.value;
            setvalue(newValue);
            (async () => save(newValue))();
        }
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        setEditing(false);
    }, []);
    React.useEffect(() => {
        if (column.columnDef.meta !== undefined) {
            const { dropdown } = column.columnDef.meta;
            if (dropdown) {
                setOptions(dropdown.options);
            }
        }
    }, [column.columnDef.meta]);
    const content = React.useMemo(() => {
        if (isEditing) {
            return (React.createElement(Popup, { close: closeDropdown, style: { width: `${(column.columnDef.size ?? 150) + 20}px` } },
                React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: false, isTypeAheadEnabled: true, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: options, value: options.find((o) => 'value' in o && value === o.value) })));
        }
        setClassName('co-centered-box');
        const text = options.filter((o) => 'value' in o && value === o.value)?.map((v) => v.label).join(', ') ?? '??';
        return text;
    }, [closeDropdown, column.columnDef.size, isDisabled, isEditing,
        onChange, options, value]);
    return (React.createElement(EditableCell, { className: className, errorText: errorText, isLoading: isLoading, isLoadingRow: isLoading, key: cell.id, setEditing: setEditing }, content));
};
export default TransactionCellDropdown;
