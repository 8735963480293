import Regex from './Regex';
class IndexedRegex extends Regex {
    id;
    index;
    constructor({ id, index, markers, template, }) {
        super({ markers, template });
        this.id = id;
        this.index = index;
    }
}
export default IndexedRegex;
