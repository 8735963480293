import { Button, Modal, ModalBody, ModalFooter, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import * as React from 'react';
import TransactionMetaForm from './transaction-meta/TransactionMetaForm';
import useMap from 'components/customHooksMap';
import './transaction-table/TransactionMetaModal.css';
const TransactionMergeModal = ({ onClose, save }) => {
    const { selection } = React.useContext(TableContext);
    const [isLoading, setLoading] = React.useState(false);
    const [transactionMeta, setTransactionMeta] = React.useState(() => {
        const newInitialTransactionmeta = {};
        const initialSectorValues = [...new Set(selection?.map((t) => t.sector).filter((s) => s !== undefined))];
        if (initialSectorValues.length === 1) {
            [newInitialTransactionmeta.sector] = initialSectorValues;
        }
        const initialCompanies = selection?.map(t => t.company).filter(s => s !== undefined) ?? [];
        const initialCompanyIds = [...new Set(initialCompanies.map((t) => t.companyId).filter(i => i !== undefined))];
        if (initialCompanyIds.length === 1) {
            if (newInitialTransactionmeta.company === undefined) {
                newInitialTransactionmeta.company = {};
            }
            const foundCompany = initialCompanies.find(c => c.companyId !== undefined);
            if (foundCompany !== undefined) {
                newInitialTransactionmeta.company.companyId = foundCompany.companyId;
                newInitialTransactionmeta.company.company = foundCompany.company;
            }
        }
        const initialCompanyTypeValues = [...new Set(initialCompanies.map((t) => t.type).filter((s) => s !== undefined))];
        if (initialCompanyTypeValues.length === 1) {
            if (newInitialTransactionmeta.company === undefined) {
                newInitialTransactionmeta.company = {};
            }
            newInitialTransactionmeta.company.type = initialCompanyTypeValues[0];
        }
        const initialRegionsValues = selection?.map((t) => t.regions).filter((s) => s !== undefined);
        if (initialRegionsValues !== undefined) {
            const joinedUpNames = [...new Set(initialRegionsValues.map((v) => v.sort().join('')))];
            if (joinedUpNames.length === 1) {
                [newInitialTransactionmeta.regions] = initialRegionsValues;
            }
        }
        const initialTypesValues = selection?.map((t) => t.types).filter((s) => s !== undefined);
        if (initialTypesValues !== undefined) {
            const joinedUpNames = [...new Set(initialTypesValues.map((v) => v.sort().join('')))];
            if (joinedUpNames.length === 1) {
                [newInitialTransactionmeta.types] = initialTypesValues;
            }
        }
        return newInitialTransactionmeta;
    });
    const regionMap = useMap('/syndicate/overview/regions/map');
    const sectorMap = useMap('/syndicate/overview/sectors/map');
    const typeMap = useMap('/syndicate/overview/types/map');
    const isDisabled = React.useMemo(() => transactionMeta.sector === undefined || transactionMeta.regions === undefined || transactionMeta.types === undefined || transactionMeta.types.length === 0 || transactionMeta.company?.type === undefined || transactionMeta.company.companyId === undefined, [transactionMeta]);
    const onClickButtonCompany = React.useCallback((company) => () => {
        const updatedTransactionMeta = { ...transactionMeta };
        if (updatedTransactionMeta.company === undefined) {
            updatedTransactionMeta.company = {};
        }
        updatedTransactionMeta.company.company = company.company;
        updatedTransactionMeta.company.companyId = company.companyId;
        setTransactionMeta(updatedTransactionMeta);
    }, [transactionMeta]);
    const onClickButton = React.useCallback((type, value) => () => {
        const updatedTransactionMeta = { ...transactionMeta };
        switch (type) {
            case 'SECTOR':
                updatedTransactionMeta.sector = value;
                break;
            case 'REGION':
                updatedTransactionMeta.regions = value;
                break;
            case 'TYPES':
                updatedTransactionMeta.types = value;
                break;
            case 'COMPANY_TYPE':
                if (updatedTransactionMeta.company === undefined) {
                    updatedTransactionMeta.company = {};
                }
                updatedTransactionMeta.company.type = value;
                break;
            default:
        }
        setTransactionMeta(updatedTransactionMeta);
    }, [transactionMeta]);
    const onClickSave = React.useCallback(() => {
        const { sector, regions, types, company, } = transactionMeta;
        if (sector !== undefined && regions !== undefined && types !== undefined && company?.companyId !== undefined) {
            (async () => {
                setLoading(true);
                await save(sector, regions, types, company);
                setLoading(false);
                onClose();
            })();
        }
    }, [onClose, save, transactionMeta]);
    const onKeyUpSave = React.useCallback((event) => {
        if (event.code === 'Enter') {
            onClickSave();
        }
    }, [onClickSave]);
    return (React.createElement(Modal, { className: "cmo-dropdown-absolute", closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalTitle, null, "Meta data merged transaction"),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-merge" },
                React.createElement("div", { className: 'cmo-name' }),
                React.createElement(TransactionMetaForm, { isLoading: isLoading, transactionMeta: transactionMeta, setTransactionMeta: setTransactionMeta }),
                selection?.map((t) => (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "cmo-name", key: `${t.id}n` }, t.name),
                    t.company?.company?.name !== undefined && t.company.companyId !== undefined ? React.createElement(Button, { className: "cmo-company cmo-button", onClick: onClickButtonCompany(t.company), size: "small", variant: "secondary" }, t.company.company.name) : undefined,
                    t.company?.type !== undefined ? React.createElement(Button, { className: "cmo-company-type cmo-button", onClick: onClickButton('COMPANY_TYPE', t.company.type), size: "small", variant: "secondary" }, t.company.type) : undefined,
                    t.sector !== undefined ? React.createElement(Button, { className: "cmo-sector cmo-button", onClick: onClickButton('SECTOR', t.sector), size: "small", variant: "secondary" }, sectorMap.get(t.sector)) : undefined,
                    t.regions !== undefined && t.regions.length > 0 ? React.createElement(Button, { className: "cmo-region cmo-button", onClick: onClickButton('REGION', t.regions), size: "small", variant: "secondary" }, t.regions.map(r => regionMap.get(r)).join(", ")) : undefined,
                    t.types !== undefined && t.types.length > 0 ? React.createElement(Button, { className: "cmo-types cmo-button", onClick: onClickButton('TYPES', t.types), size: "small", variant: "secondary" }, t.types.map(t => typeMap.get(t)).join(', ')) : undefined))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, loading: isLoading, onClick: onClickSave, onKeyUp: onKeyUpSave }, "SAVE"))));
};
export default TransactionMergeModal;
