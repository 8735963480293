import * as React from 'react';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import { App, appFromModule } from 'utils/appUtils';
import NavigationContext from 'contexts/NavigationContext';
import { useAuth } from 'react-oidc-context';
const MenuItems = ({ closeMenu, setShowSettings, show }) => {
    const { updateAppView } = React.useContext(NavigationContext);
    const [menuItems, setMenuItems] = React.useState([]);
    const { user } = useAuth();
    const availableApps = React.useMemo(() => {
        if (user == null) {
            return undefined;
        }
        const claims = user.profile;
        return Object.keys(claims.authorityMap).map((name) => appFromModule(name));
    }, [user]);
    const onClickApp = React.useCallback((key) => () => {
        closeMenu();
        updateAppView(key);
    }, [closeMenu, updateAppView]);
    const onClickLogout = React.useCallback(() => {
        const event = new CustomEvent('logout');
        window.dispatchEvent(event);
        closeMenu();
    }, [closeMenu]);
    const onClickSettings = React.useCallback(() => {
        closeMenu();
        setShowSettings(true);
    }, [closeMenu, setShowSettings]);
    React.useEffect(() => {
        const items = [
            (React.createElement(DropdownMenuItem, { key: "settings", onClick: onClickSettings }, "Settings")),
            (React.createElement(DropdownMenuDivider, { key: "divider1" })),
        ];
        availableApps?.forEach((key) => {
            items.push((React.createElement(DropdownMenuItem, { key: key, onClick: onClickApp(key) }, App[key])));
        });
        items.push((React.createElement(DropdownMenuDivider, { key: "divider2" })));
        items.push((React.createElement(DropdownMenuItem, { key: "log-out", onClick: onClickLogout }, "Log Out")));
        setMenuItems(items);
    }, [availableApps, onClickApp, onClickLogout, onClickSettings]);
    return (React.createElement(DropdownMenu, { className: "co-menu-dropdown", show: show }, menuItems));
};
export default MenuItems;
