class QualifiedSearch {
    qualifier;
    text;
    constructor() {
        this.qualifier = 'sw';
        this.text = [];
    }
    toString() {
        return `${this.qualifier} ${this.text.join(', ')}`;
    }
    clean() {
        return this.text.length && this.qualifier ? this : null;
    }
    updateQualifier(value) {
        return Object.assign(new QualifiedSearch(), this, { qualifier: value });
    }
    chooseTag(value) {
        return Object.assign(new QualifiedSearch(), this, { text: [...this.text, value.toUpperCase()] });
    }
    clearTag() {
        return Object.assign(new QualifiedSearch(), this, { text: [] });
    }
    removeTag(removingValue) {
        return Object.assign(new QualifiedSearch(), this, { text: this.text.filter((el) => el !== removingValue.value) });
    }
    static create(base) {
        return Object.assign(new QualifiedSearch(), base);
    }
    static get options() { return [{ label: 'Starts with', value: 'sw' }, { label: 'Equal to', value: 'eq' }, { label: 'Contains', value: 'ct' }]; }
}
export default QualifiedSearch;
