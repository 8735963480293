import * as React from 'react';
import ActionCell from 'components/custom-table/action/ActionCell';
import TableDnd from './TableDnd';
const RegexTable = ({ actionElements, deleteRegex, emptyMessage, errorMessage, regexes, hasError, isLoading, resetData, }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: deleteRegex, label: 'delete' },
                ],
            },
            size: 20,
        },
        {
            accessorFn: (regex) => ('safeTemplate' in regex ? regex.safeTemplate() : 'UNKONWN'),
            header: 'Template',
            id: 'template',
            size: 1000,
        },
        {
            accessorFn: (regex) => ('getMarkers' in regex ? regex.getMarkers().join(', ') : 'unknown'),
            header: 'Markers',
            id: 'markers',
            size: 1000,
        },
    ], [deleteRegex]);
    return (React.createElement(TableDnd, { actionElements: actionElements, columns: columns, data: regexes, emptyMessage: emptyMessage, errorMessage: errorMessage, hasError: hasError, isLoading: isLoading, setData: resetData }));
};
export default RegexTable;
