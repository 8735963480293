/* eslint-disable sort-keys */
const options = {
    instrumentCategory: [
        { value: '01-All', label: ' All' },
        { value: '02-EQ', label: 'Equity (EQ)' },
        { value: '03-DT', label: 'Debt (DT)' },
        { value: '04-CV', label: 'Collective Investment Vehicles (CV)' },
        { value: '05-EN', label: 'Entitlements/Rights (EN)' },
        { value: '06-OP', label: 'Options (OP)' },
        { value: '07-FT', label: 'Futures (FT)' },
        { value: '08-SP', label: 'Structured Products (SP)' },
        { value: '09-RI', label: 'Referential Instruments (RI)' },
        { value: '10-OT', label: 'Others (OT)' },
    ],
    annaStatus: [
        { value: '1 All', label: ' All' },
        { value: '2 New', label: 'New' },
        { value: '3 Updated', label: 'Updated' },
        { value: '4 Deleted', label: 'Deleted' },
        { value: '5 Re-Used', label: 'Re-Used' },
    ],
    country: [
        { value: 'All', label: ' All' },
        { value: 'AD', label: 'AD - ANDORRA' },
        { value: 'AE', label: 'AE - UNITED ARAB EMIRATES' },
        { value: 'AF', label: 'AF - AFGHANISTAN' },
        { value: 'AG', label: 'AG - ANTIGUA AND BARBUDA' },
        { value: 'AI', label: 'AI - ANGUILLA' },
        { value: 'AL', label: 'AL - ALBANIA' },
        { value: 'AM', label: 'AM - ARMENIA' },
        { value: 'AN', label: 'AN - NETHERLANDS ANTILLES' },
        { value: 'AO', label: 'AO - ANGOLA' },
        { value: 'AQ', label: 'AQ - ANTARCTICA' },
        { value: 'AR', label: 'AR - ARGENTINA' },
        { value: 'AS', label: 'AS - AMERICAN SAMOA' },
        { value: 'AT', label: 'AT - AUSTRIA' },
        { value: 'AU', label: 'AU - AUSTRALIA' },
        { value: 'AW', label: 'AW - ARUBA' },
        { value: 'AZ', label: 'AZ - AZERBAIJAN' },
        { value: 'BA', label: 'BA - BOSNIA AND HERZEGOVINA' },
        { value: 'BB', label: 'BB - BARBADOS' },
        { value: 'BD', label: 'BD - BANGLADESH' },
        { value: 'BE', label: 'BE - BELGIUM' },
        { value: 'BF', label: 'BF - BURKINA FASO' },
        { value: 'BG', label: 'BG - BULGARIA' },
        { value: 'BH', label: 'BH - BAHRAIN' },
        { value: 'BI', label: 'BI - BURUNDI' },
        { value: 'BJ', label: 'BJ - BENIN' },
        { value: 'BL', label: 'BL - SAINT BARTHELEMY' },
        { value: 'BM', label: 'BM - BERMUDA' },
        { value: 'BN', label: 'BN - BRUNEI DARUSSALAM' },
        { value: 'BO', label: 'BO - BOLIVIA' },
        { value: 'BQ', label: 'BQ - BONAIRE, ST. EUSTATIUS AND SABA' },
        { value: 'BR', label: 'BR - BRAZIL' },
        { value: 'BS', label: 'BS - BAHAMAS' },
        { value: 'BT', label: 'BT - BHUTAN' },
        { value: 'BU', label: 'BU - BURMA (OLD)' },
        { value: 'BV', label: 'BV - BOUVET ISLAND' },
        { value: 'BW', label: 'BW - BOTSWANA' },
        { value: 'BY', label: 'BY - BELARUS' },
        { value: 'BZ', label: 'BZ - BELIZE' },
        { value: 'CA', label: 'CA - CANADA' },
        { value: 'CC', label: 'CC - COCOS  (KEELING) ISLANDS' },
        { value: 'CD', label: 'CD - CONGO, DEMOCRATIC REPUBLIC OF THE' },
        { value: 'CF', label: 'CF - CENTRAL AFRICAN REPUBLIC' },
        { value: 'CG', label: 'CG - CONGO' },
        { value: 'CH', label: 'CH - SWITZERLAND' },
        { value: 'CI', label: 'CI - COTE D\'IVOIRE' },
        { value: 'CK', label: 'CK - COOK ISLANDS' },
        { value: 'CL', label: 'CL - CHILE' },
        { value: 'CM', label: 'CM - CAMEROON' },
        { value: 'CN', label: 'CN - CHINA' },
        { value: 'CO', label: 'CO - COLOMBIA' },
        { value: 'CR', label: 'CR - COSTA RICA' },
        { value: 'CS', label: 'CS - CZECHOSLOVAKIA' },
        { value: 'CU', label: 'CU - CUBA' },
        { value: 'CV', label: 'CV - CAPE VERDE' },
        { value: 'CW', label: 'CW - CURACAO' },
        { value: 'CX', label: 'CX - CHRISTMAS ISLAND' },
        { value: 'CY', label: 'CY - CYPRUS' },
        { value: 'CZ', label: 'CZ - CZECH REPUBLIC' },
        { value: 'DE', label: 'DE - GERMANY' },
        { value: 'DJ', label: 'DJ - DJIBOUTI' },
        { value: 'DK', label: 'DK - DENMARK' },
        { value: 'DM', label: 'DM - DOMINICA' },
        { value: 'DO', label: 'DO - DOMINICAN REPUBLIC' },
        { value: 'DZ', label: 'DZ - ALGERIA' },
        { value: 'EC', label: 'EC - ECUADOR' },
        { value: 'EE', label: 'EE - ESTONIA' },
        { value: 'EG', label: 'EG - EGYPT' },
        { value: 'EH', label: 'EH - WESTERN SAHARA' },
        { value: 'ER', label: 'ER - ERITREA' },
        { value: 'ES', label: 'ES - SPAIN' },
        { value: 'ET', label: 'ET - ETHIOPIA' },
        { value: 'EU', label: 'EU - European Union' },
        { value: 'FI', label: 'FI - FINLAND' },
        { value: 'FJ', label: 'FJ - FIJI' },
        { value: 'FK', label: 'FK - FALKLAND ISLANDS (MALVINAS)' },
        { value: 'FM', label: 'FM - MICRONESIA' },
        { value: 'FO', label: 'FO - FAROE ISLANDS' },
        { value: 'FR', label: 'FR - FRANCE' },
        { value: 'GA', label: 'GA - GABON' },
        { value: 'GB', label: 'GB - UNITED KINGDOM' },
        { value: 'GD', label: 'GD - GRENADA' },
        { value: 'GE', label: 'GE - GEORGIA' },
        { value: 'GF', label: 'GF - FRENCH GUIANA' },
        { value: 'GG', label: 'GG - GUERNSEY' },
        { value: 'GH', label: 'GH - GHANA' },
        { value: 'GI', label: 'GI - GIBRALTAR' },
        { value: 'GL', label: 'GL - GREENLAND' },
        { value: 'GM', label: 'GM - GAMBIA' },
        { value: 'GN', label: 'GN - GUINEA' },
        { value: 'GP', label: 'GP - GUADELOUPE' },
        { value: 'GQ', label: 'GQ - EQUATORIAL GUINEA' },
        { value: 'GR', label: 'GR - GREECE' },
        { value: 'GS', label: 'GS - SOUTH GEORGIA & SANDWICH ISL.' },
        { value: 'GT', label: 'GT - GUATEMALA' },
        { value: 'GU', label: 'GU - GUAM' },
        { value: 'GW', label: 'GW - GUINEA-BISSAU' },
        { value: 'GY', label: 'GY - GUYANA' },
        { value: 'HK', label: 'HK - HONG KONG' },
        { value: 'HM', label: 'HM - HEARD ISLAND & MCDONALD ISL.' },
        { value: 'HN', label: 'HN - HONDURAS' },
        { value: 'HR', label: 'HR - CROATIA' },
        { value: 'HT', label: 'HT - HAITI' },
        { value: 'HU', label: 'HU - HUNGARY' },
        { value: 'ID', label: 'ID - INDONESIA' },
        { value: 'IE', label: 'IE - IRELAND' },
        { value: 'IL', label: 'IL - ISRAEL' },
        { value: 'IM', label: 'IM - ISLE OF MAN' },
        { value: 'IN', label: 'IN - INDIA' },
        { value: 'IO', label: 'IO - BRITISH INDIAN OCEAN TERRITORY' },
        { value: 'IQ', label: 'IQ - IRAQ' },
        { value: 'IR', label: 'IR - IRAN' },
        { value: 'IS', label: 'IS - ICELAND' },
        { value: 'IT', label: 'IT - ITALY' },
        { value: 'JE', label: 'JE - JERSEY' },
        { value: 'JM', label: 'JM - JAMAICA' },
        { value: 'JO', label: 'JO - JORDAN' },
        { value: 'JP', label: 'JP - JAPAN' },
        { value: 'KE', label: 'KE - KENYA' },
        { value: 'KG', label: 'KG - KYRGYZSTAN' },
        { value: 'KH', label: 'KH - CAMBODIA' },
        { value: 'KI', label: 'KI - KIRIBATI' },
        { value: 'KM', label: 'KM - COMOROS' },
        { value: 'KN', label: 'KN - SAINT KITTS AND NEVIS' },
        { value: 'KP', label: 'KP - KOREA, DEMOCRATIC PEOPLE\'S REP' },
        { value: 'KR', label: 'KR - KOREA, REPUBLIC OF' },
        { value: 'KW', label: 'KW - KUWAIT' },
        { value: 'KY', label: 'KY - CAYMAN ISLANDS' },
        { value: 'KZ', label: 'KZ - KAZAKSTAN' },
        { value: 'LA', label: 'LA - LAO' },
        { value: 'LB', label: 'LB - LEBANON' },
        { value: 'LC', label: 'LC - SAINT LUCIA' },
        { value: 'LI', label: 'LI - LIECHTENSTEIN' },
        { value: 'LK', label: 'LK - SRI LANKA' },
        { value: 'LR', label: 'LR - LIBERIA' },
        { value: 'LS', label: 'LS - LESOTHO' },
        { value: 'LT', label: 'LT - LITHUANIA' },
        { value: 'LU', label: 'LU - LUXEMBOURG' },
        { value: 'LV', label: 'LV - LATVIA' },
        { value: 'LY', label: 'LY - LIBYAN ARAB JAMAHIRIYA' },
        { value: 'MA', label: 'MA - MOROCCO' },
        { value: 'MC', label: 'MC - MONACO' },
        { value: 'MD', label: 'MD - MOLDOVA' },
        { value: 'ME', label: 'ME - MONTENEGRO' },
        { value: 'MF', label: 'MF - SAINT MARTIN' },
        { value: 'MG', label: 'MG - MADAGASCAR' },
        { value: 'MH', label: 'MH - MARSHALL ISLANDS' },
        { value: 'MK', label: 'MK - MACEDONIA' },
        { value: 'ML', label: 'ML - MALI' },
        { value: 'MM', label: 'MM - MYANMAR' },
        { value: 'MN', label: 'MN - MONGOLIA' },
        { value: 'MO', label: 'MO - MACAU' },
        { value: 'MP', label: 'MP - NORTHERN MARIANA ISLANDS' },
        { value: 'MQ', label: 'MQ - MARTINIQUE' },
        { value: 'MR', label: 'MR - MAURITANIA' },
        { value: 'MS', label: 'MS - MONTSERRAT' },
        { value: 'MT', label: 'MT - MALTA' },
        { value: 'MU', label: 'MU - MAURITIUS' },
        { value: 'MV', label: 'MV - MALDIVES' },
        { value: 'MW', label: 'MW - MALAWI' },
        { value: 'MX', label: 'MX - MEXICO' },
        { value: 'MY', label: 'MY - MALAYSIA' },
        { value: 'MZ', label: 'MZ - MOZAMBIQUE' },
        { value: 'NA', label: 'NA - NAMIBIA' },
        { value: 'NC', label: 'NC - NEW CALEDONIA' },
        { value: 'NE', label: 'NE - NIGER' },
        { value: 'NF', label: 'NF - NORFOLK ISLAND' },
        { value: 'NG', label: 'NG - NIGERIA' },
        { value: 'NI', label: 'NI - NICARAGUA' },
        { value: 'NL', label: 'NL - NETHERLANDS' },
        { value: 'NO', label: 'NO - NORWAY' },
        { value: 'NP', label: 'NP - NEPAL' },
        { value: 'NR', label: 'NR - NAURU' },
        { value: 'NU', label: 'NU - NIUE' },
        { value: 'NZ', label: 'NZ - NEW ZEALAND' },
        { value: 'OM', label: 'OM - OMAN' },
        { value: 'PA', label: 'PA - PANAMA' },
        { value: 'PE', label: 'PE - PERU' },
        { value: 'PF', label: 'PF - FRENCH POLYNESIA' },
        { value: 'PG', label: 'PG - PAPUA NEW GUINEA' },
        { value: 'PH', label: 'PH - PHILIPPINES' },
        { value: 'PK', label: 'PK - PAKISTAN' },
        { value: 'PL', label: 'PL - POLAND' },
        { value: 'PM', label: 'PM - SAINT PIERRE AND MIQUELON' },
        { value: 'PN', label: 'PN - PITCAIRN' },
        { value: 'PR', label: 'PR - PUERTO RICO' },
        { value: 'PS', label: 'PS - PALESTINE' },
        { value: 'PT', label: 'PT - PORTUGAL' },
        { value: 'PW', label: 'PW - PALAU' },
        { value: 'PY', label: 'PY - PARAGUAY' },
        { value: 'QA', label: 'QA - QUATAR' },
        { value: 'QP', label: 'QP - DUMMY ISIN CUSIP (USA)' },
        { value: 'QT', label: 'QT - DOM ISIN' },
        { value: 'QY', label: 'QY - DUMMY ISIN CDS (CANADA)' },
        { value: 'RE', label: 'RE - REUNION' },
        { value: 'RO', label: 'RO - ROMANIA' },
        { value: 'RS', label: 'RS - SERBIA' },
        { value: 'RU', label: 'RU - RUSSIAN FEDERATION' },
        { value: 'RW', label: 'RW - RWANDA' },
        { value: 'SA', label: 'SA - SAUDI ARABIA' },
        { value: 'SB', label: 'SB - SOLOMON ISLANDS' },
        { value: 'SC', label: 'SC - SEYCHELLES' },
        { value: 'SD', label: 'SD - SUDAN' },
        { value: 'SE', label: 'SE - SWEDEN' },
        { value: 'SG', label: 'SG - SINGAPORE' },
        { value: 'SH', label: 'SH - SAINT HELENA' },
        { value: 'SI', label: 'SI - SLOVENIA' },
        { value: 'SJ', label: 'SJ - SVALBARD AND JAN MAYEN' },
        { value: 'SK', label: 'SK - SLOVAKIA' },
        { value: 'SL', label: 'SL - SIERRA LEONE' },
        { value: 'SM', label: 'SM - SAN MARINO' },
        { value: 'SN', label: 'SN - SENEGAL' },
        { value: 'SO', label: 'SO - SOMALIA' },
        { value: 'SR', label: 'SR - SURINAME' },
        { value: 'ST', label: 'ST - SAO TOME & PRINCIPE' },
        { value: 'SU', label: 'SU - USSR/SOVIET UNION (OLD)' },
        { value: 'SV', label: 'SV - EL SALVADOR' },
        { value: 'SX', label: 'SX - SINT MAARTEN' },
        { value: 'SY', label: 'SY - SYRIAN ARAB REPUBLIC' },
        { value: 'SZ', label: 'SZ - SWAZILAND' },
        { value: 'TC', label: 'TC - TURKS AND CAICOS ISLANDS' },
        { value: 'TD', label: 'TD - CHAD' },
        { value: 'TF', label: 'TF - FRENCH SOUTHERN TERRITORIES' },
        { value: 'TG', label: 'TG - TOGO' },
        { value: 'TH', label: 'TH - THAILAND' },
        { value: 'TJ', label: 'TJ - TAJIKISTAN' },
        { value: 'TK', label: 'TK - TOKELAU' },
        { value: 'TM', label: 'TM - TURKMENISTAN' },
        { value: 'TN', label: 'TN - TUNISIA' },
        { value: 'TO', label: 'TO - TONGA' },
        { value: 'TP', label: 'TP - EAST TIMOR' },
        { value: 'TR', label: 'TR - TURKEY' },
        { value: 'TT', label: 'TT - TRINIDAD AND TOBAGO' },
        { value: 'TV', label: 'TV - TUVALU' },
        { value: 'TW', label: 'TW - TAIWAN, PROVINCE OF CHINA' },
        { value: 'TZ', label: 'TZ - TANZANIA' },
        { value: 'UA', label: 'UA - UKRAINE' },
        { value: 'UG', label: 'UG - UGANDA' },
        { value: 'UM', label: 'UM - U. S. MINOR OUTLYING ISLANDS' },
        { value: 'US', label: 'US - UNITED STATES' },
        { value: 'UY', label: 'UY - URUGUAY' },
        { value: 'UZ', label: 'UZ - UZBEKISTAN' },
        { value: 'VA', label: 'VA - VATICAN CITY STATE (HOLY SEE)' },
        { value: 'VC', label: 'VC - SAINT VINCENT AND GRENADINES' },
        { value: 'VE', label: 'VE - VENEZUELA' },
        { value: 'VG', label: 'VG - VIRGIN ISLANDS (BRITISH)' },
        { value: 'VI', label: 'VI - VIRGIN ISLANDS (US)' },
        { value: 'VN', label: 'VN - VIET NAM' },
        { value: 'VU', label: 'VU - VANUATU' },
        { value: 'WF', label: 'WF - WALLIS AND FUTUNA ISLANDS' },
        { value: 'WS', label: 'WS - SAMOA' },
        { value: 'XA', label: 'XA - CUSIP,SUBSTITUTE AGENCY FOR ISIN' },
        { value: 'XB', label: 'XB - SICOVAM, SUBSTITUTE AGENCY FOR ISIN' },
        { value: 'XC', label: 'XC - WERTTPAPIER-MITTEILUNGEN, SUBSTITUTE' },
        { value: 'XD', label: 'XD - TELEKURS AG, SUBSTITUTE AGENCY FOR I' },
        { value: 'XS', label: 'XS - DOM COMMON CODE' },
        { value: 'YD', label: 'YD - JEMEN, DEMOCRATIC (OLD)' },
        { value: 'YE', label: 'YE - YEMEN' },
        { value: 'YT', label: 'YT - MAYOTTE' },
        { value: 'YU', label: 'YU - YUGOSLAVIA' },
        { value: 'ZA', label: 'ZA - SOUTH AFRICA' },
        { value: 'ZM', label: 'ZM - ZAMBIA' },
        { value: 'ZR', label: 'ZR - ZAIRE (OLD)' },
        { value: 'ZW', label: 'ZW - ZIMBABWE' },
    ],
    issueCurrency: [
        { value: 'All', label: '-All-' },
        { value: 'ADP', label: 'ANDORRAN PESETA (ADP)' },
        { value: 'AED', label: 'UAE DIRHAM (AED)' },
        { value: 'AFA', label: 'AFGHANI (AFA)' },
        { value: 'AFN', label: 'AFGHAN AFGHANI (AFN)' },
        { value: 'ALL', label: 'LEK (ALL)' },
        { value: 'AMD', label: 'ARMENIAN DRAM (AMD)' },
        { value: 'ANG', label: 'NETHERLANDS ANTILLIAN GUILDER (ANG)' },
        { value: 'AOA', label: 'KWANZA (AOA)' },
        { value: 'ARA', label: 'AUSTRAL (ARA)' },
        { value: 'ARS', label: 'ARGENTINE PESO (ARS)' },
        { value: 'ATS', label: 'SCHILLING (ATS)' },
        { value: 'AUD', label: 'AUSTRALIAN DOLLAR (AUD)' },
        { value: 'AWG', label: 'ARUBAN GUILDER (AWG)' },
        { value: 'AZM', label: 'AZERBAIJANIAN MANAT (AZM)' },
        { value: 'AZN', label: 'AZERBAIJANI MANAT (AZN)' },
        { value: 'BAM', label: 'CONVERTIBLE MARK (BAM)' },
        { value: 'BBD', label: 'BARBADOS DOLLAR (BBD)' },
        { value: 'BDT', label: 'TAKA (BDT)' },
        { value: 'BEC', label: 'CONVERTIBLE FRANC (BEC)' },
        { value: 'BEF', label: 'BELGIAN FRANC (BEF)' },
        { value: 'BEL', label: 'FINANCIAL FRANC (BEL)' },
        { value: 'BGL', label: 'LEV (BGL)' },
        { value: 'BGN', label: 'BULGARIAN LEV (BGN)' },
        { value: 'BHD', label: 'BAHRAINI DINAR (BHD)' },
        { value: 'BIF', label: 'BURUNDI FRANC (BIF)' },
        { value: 'BMD', label: 'BERMUDIAN DOLLAR (BMD)' },
        { value: 'BND', label: 'BRUNEI DOLLAR (BND)' },
        { value: 'BOB', label: 'BOLIVIANO (BOB)' },
        { value: 'BOV', label: 'MVDOL (BOV)' },
        { value: 'BRL', label: 'BRAZILIAN REAL (BRL)' },
        { value: 'BRR', label: 'CRUZEIRO REAL (BRR)' },
        { value: 'BSD', label: 'BAHAMIAN DOLLAR (BSD)' },
        { value: 'BTN', label: 'NGULTRUM (BTN)' },
        { value: 'BWP', label: 'PULA (BWP)' },
        { value: 'BYN', label: 'BELARUS (BYN)' },
        { value: 'BYR', label: 'BELARUSSIAN RUBLE (BYR)' },
        { value: 'BZD', label: 'BELIZE DOLLAR (BZD)' },
        { value: 'CAD', label: 'CANADIAN DOLLAR (CAD)' },
        { value: 'CDF', label: 'FRANC CONGOLAIS (CDF)' },
        { value: 'CHE', label: 'WIR Euro (CHE)' },
        { value: 'CHF', label: 'SWISS FRANC (CHF)' },
        { value: 'CHW', label: 'WIR Franc (CHW)' },
        { value: 'CLF', label: 'UNIDADES DE FOMENTO (CLF)' },
        { value: 'CLP', label: 'CHILEAN PESO (CLP)' },
        { value: 'CNH', label: 'CHINESE OFFSHORE RENMINBI (CNH)' },
        { value: 'CNY', label: 'YUAN RENMINBI (CNY)' },
        { value: 'COP', label: 'COLOMBIAN PESO (COP)' },
        { value: 'COU', label: 'COLUMBIAN UNIDAD DE VALOR REAL (COU)' },
        { value: 'CRC', label: 'COSTA RICA COLON (CRC)' },
        { value: 'CSK', label: 'KORUNA (CSK)' },
        { value: 'CUC', label: 'Peso Convertible (CUC)' },
        { value: 'CUP', label: 'CUBAN PESO (CUP)' },
        { value: 'CVE', label: 'CAPE VERDE ESCUDO (CVE)' },
        { value: 'CYP', label: 'CYPRUS POUND (CYP)' },
        { value: 'CZK', label: 'CZECH KORUNA (CZK)' },
        { value: 'DEM', label: 'DEUTSCHE MARK (DEM)' },
        { value: 'DJF', label: 'DJIBOUTI FRANC (DJF)' },
        { value: 'DKK', label: 'DANISH KRONE (DKK)' },
        { value: 'DOP', label: 'DOMINICAN PESO (DOP)' },
        { value: 'DZD', label: 'ALGERIAN DINAR (DZD)' },
        { value: 'ECS', label: 'SUCRE (ECS)' },
        { value: 'EEK', label: 'KROON (EEK)' },
        { value: 'EGP', label: 'EGYPTIAN POUND (EGP)' },
        { value: 'ERN', label: 'NAKFA (ERN)' },
        { value: 'ESP', label: 'SPANISH PESETA (ESP)' },
        { value: 'ETB', label: 'ETHIOPIAN BIRR (ETB)' },
        { value: 'EUR', label: 'EURO (EUR)' },
        { value: 'FIM', label: 'MARKKA (FIM)' },
        { value: 'FJD', label: 'FIJI DOLLAR (FJD)' },
        { value: 'FKP', label: 'FALKLAND ISLANDS POUND (FKP)' },
        { value: 'FRF', label: 'FRENCH FRANC (FRF)' },
        { value: 'GBP', label: 'POUND STERLING (GBP)' },
        { value: 'GBX', label: 'PENNY UK STERLING (GBX)' },
        { value: 'GEL', label: 'LARI (GEL)' },
        { value: 'GHC', label: 'CEDI (GHC)' },
        { value: 'GHS', label: 'GHANAIAN CEDI (GHS)' },
        { value: 'GIP', label: 'GIBRALTAR POUND (GIP)' },
        { value: 'GMD', label: 'DALASI (GMD)' },
        { value: 'GNF', label: 'GUINEA FRANC (GNF)' },
        { value: 'GRD', label: 'DRACHMA (GRD)' },
        { value: 'GTQ', label: 'QUETZAL (GTQ)' },
        { value: 'GYD', label: 'GUYANA DOLLAR (GYD)' },
        { value: 'HKD', label: 'HONG KONG DOLLAR (HKD)' },
        { value: 'HNL', label: 'LEMPIRA (HNL)' },
        { value: 'HRK', label: 'KUNA (HRK)' },
        { value: 'HTG', label: 'GOURDE (HTG)' },
        { value: 'HUF', label: 'FORINT (HUF)' },
        { value: 'IDR', label: 'RUPIAH (IDR)' },
        { value: 'IEP', label: 'IRISH POUND (IEP)' },
        { value: 'ILS', label: 'NEW ISRAELI SHEQEL (ILS)' },
        { value: 'INR', label: 'INDIAN RUPEE (INR)' },
        { value: 'IQD', label: 'IRAQI DINAR (IQD)' },
        { value: 'IRR', label: 'IRANIAN RIAL (IRR)' },
        { value: 'ISJ', label: 'OLD KRONA (ISJ)' },
        { value: 'ISK', label: 'ICELAND KRONA (ISK)' },
        { value: 'ITL', label: 'ITALIAN LIRA (ITL)' },
        { value: 'JMD', label: 'JAMAICAN DOLLAR (JMD)' },
        { value: 'JOD', label: 'JORDANIAN DINAR (JOD)' },
        { value: 'JPY', label: 'YEN (JPY)' },
        { value: 'KES', label: 'KENYAN SHILLING (KES)' },
        { value: 'KGS', label: 'KYRGYZSTAN SOM (KGS)' },
        { value: 'KHR', label: 'RIEL (KHR)' },
        { value: 'KMF', label: 'COMORO FRANC (KMF)' },
        { value: 'KPW', label: 'NORTH KOREAN WON (KPW)' },
        { value: 'KRW', label: 'WON (KRW)' },
        { value: 'KWD', label: 'KUWAITI DINAR (KWD)' },
        { value: 'KYD', label: 'CAYMAN ISLANDS DOLLAR (KYD)' },
        { value: 'KZT', label: 'TENGE (KZT)' },
        { value: 'LAK', label: 'KIP (LAK)' },
        { value: 'LBP', label: 'LEBANESE POUND (LBP)' },
        { value: 'LKR', label: 'SRI LANKA RUPEE (LKR)' },
        { value: 'LRD', label: 'LIBERIAN DOLLAR (LRD)' },
        { value: 'LSL', label: 'LOTI (LSL)' },
        { value: 'LSM', label: 'MALOTI (LSM)' },
        { value: 'LTL', label: 'LITHUANIAN LITAS (LTL)' },
        { value: 'LUF', label: 'LUXEMBOURG FRANC (LUF)' },
        { value: 'LVL', label: 'LATVIAN LATS (LVL)' },
        { value: 'LYD', label: 'LIBYAN DINAR (LYD)' },
        { value: 'MAD', label: 'MOROCCAN DIRHAM (MAD)' },
        { value: 'MCU', label: 'MULTI-CURRENCY (MCU)' },
        { value: 'MDL', label: 'MOLDOVAN LEU (MDL)' },
        { value: 'MGA', label: 'Malagasy Ariary (MGA)' },
        { value: 'MGF', label: 'MALAGASY FRANC (MGF)' },
        { value: 'MKD', label: 'MACEDONIAN DENAR (MKD)' },
        { value: 'MMK', label: 'KYAT (MMK)' },
        { value: 'MNT', label: 'TUGRIK (MNT)' },
        { value: 'MOP', label: 'PATACA (MOP)' },
        { value: 'MRO', label: 'OUGUIYA (MRO)' },
        { value: 'MTL', label: 'MALTESE LIRA (MTL)' },
        { value: 'MUR', label: 'MAURITIUS RUPEE (MUR)' },
        { value: 'MVR', label: 'RUFIYAA (MVR)' },
        { value: 'MWK', label: 'KWACHA (MWK)' },
        { value: 'MXN', label: 'MEXICAN PESO (MXN)' },
        { value: 'MXV', label: 'MEXICAN UNIDAD DE INVERSION (UDI) (MXV)' },
        { value: 'MYR', label: 'MALAYSIAN RINGGIT (MYR)' },
        { value: 'MZM', label: 'METICAL (MZM)' },
        { value: 'MZN', label: 'MOZAMBICAN METICAL (MZN)' },
        { value: 'NAD', label: 'NAMIBIA DOLLAR (NAD)' },
        { value: 'NGN', label: 'NAIRA (NGN)' },
        { value: 'NIO', label: 'CORDOBA ORO (NIO)' },
        { value: 'NLG', label: 'NETHERLANDS GUILDER (NLG)' },
        { value: 'NOK', label: 'NORWEGIAN KRONE (NOK)' },
        { value: 'NPR', label: 'NEPALESE RUPEE (NPR)' },
        { value: 'NZD', label: 'NEW ZEALAND DOLLAR (NZD)' },
        { value: 'OMR', label: 'RIAL OMANI (OMR)' },
        { value: 'PAB', label: 'BALBOA (PAB)' },
        { value: 'PEN', label: 'NUEVO SOL (PEN)' },
        { value: 'PGK', label: 'KINA (PGK)' },
        { value: 'PHP', label: 'PHILIPPINE PESO (PHP)' },
        { value: 'PKR', label: 'PAKISTAN RUPEE (PKR)' },
        { value: 'PLN', label: 'ZLOTY (PLN)' },
        { value: 'PRC', label: 'Percent (PRC)' },
        { value: 'PTE', label: 'PORTUGESE ESCUDO (PTE)' },
        { value: 'PTX', label: 'Points (PTX)' },
        { value: 'PYG', label: 'GUARANI (PYG)' },
        { value: 'QAR', label: 'QATARI RIAL (QAR)' },
        { value: 'RON', label: 'LEU (RON)' },
        { value: 'RSD', label: 'RSD Dinar (RSD)' },
        { value: 'RUB', label: 'RUSSIAN RUBLE (RUB)' },
        { value: 'RUR', label: 'RUSSIAN RUBLE (RUR)' },
        { value: 'RWF', label: 'RWANDA FRANC (RWF)' },
        { value: 'SAR', label: 'SAUDI RIYAL (SAR)' },
        { value: 'SBD', label: 'SOLOMON ISLANDS DOLLAR (SBD)' },
        { value: 'SCR', label: 'SEYCHELLES RUPEE (SCR)' },
        { value: 'SDD', label: 'SUDANESE DINAR (SDD)' },
        { value: 'SDG', label: 'SUDANESE POUND (SDG)' },
        { value: 'SEK', label: 'SWEDISH KRONA (SEK)' },
        { value: 'SGD', label: 'SINGAPORE DOLLAR (SGD)' },
        { value: 'SHP', label: 'SAINT HELENA POUND (SHP)' },
        { value: 'SIT', label: 'TOLAR (SIT)' },
        { value: 'SKK', label: 'SLOVAK KORUNA (SKK)' },
        { value: 'SLL', label: 'LEONE (SLL)' },
        { value: 'SOS', label: 'SOMALI SHILLING (SOS)' },
        { value: 'SRD', label: 'SURINAMESE DOLLAR (SRD)' },
        { value: 'SRG', label: 'SURINAM GUILDER (SRG)' },
        { value: 'SSP', label: 'South Sudanese Pound (SSP)' },
        { value: 'STD', label: 'DOBRA (STD)' },
        { value: 'SVC', label: 'EL SALVADOR COLON (SVC)' },
        { value: 'SYP', label: 'SYRIAN POUND (SYP)' },
        { value: 'SZL', label: 'LILANGENI (SZL)' },
        { value: 'THB', label: 'BAHT (THB)' },
        { value: 'TJR', label: 'TAJIK RUBLE (TJR)' },
        { value: 'TJS', label: 'Tajikistan/Tajik Ruble/Somoni (TJS)' },
        { value: 'TMM', label: 'MANAT (TMM)' },
        { value: 'TMT', label: 'TURKMENISTANI MANAT (TMT)' },
        { value: 'TND', label: 'TUNISIAN DINAR (TND)' },
        { value: 'TOP', label: 'PA\'ANGA (TOP)' },
        { value: 'TRY', label: 'TURKISH LIRA (TRY)' },
        { value: 'TTD', label: 'TRINIDAD AND TOBAGO DOLLAR (TTD)' },
        { value: 'TWD', label: 'NEW TAIWAN DOLLAR (TWD)' },
        { value: 'TZS', label: 'TANZANIAN SHILLING (TZS)' },
        { value: 'UAH', label: 'HRYVNIA (UAH)' },
        { value: 'UGX', label: 'UGANDA SHILLING (UGX)' },
        { value: 'USD', label: 'US DOLLAR (USD)' },
        { value: 'USN', label: 'US Dollar (Next day) (USN)' },
        { value: 'USS', label: 'UNITED STATES DOLLAR (SAME DAY)(FUNDS CODE) (USS)' },
        { value: 'UYI', label: 'URUGUAYAN PESO (INDEXED UNITS) (UYI)' },
        { value: 'UYP', label: 'URUGUAYAN PESO (UYP)' },
        { value: 'UYR', label: 'Index Adjusted Currency (UYR)' },
        { value: 'UYU', label: 'PESO URUGUAYO (UYU)' },
        { value: 'UZS', label: 'UZBEKISTAN SUM (UZS)' },
        { value: 'VEB', label: 'BOLIVAR (VEB)' },
        { value: 'VEF', label: 'VENEZUELAN BOLAVAR FUERTE (VEF)' },
        { value: 'VND', label: 'DONG (VND)' },
        { value: 'VUV', label: 'VATU (VUV)' },
        { value: 'WST', label: 'TALA (WST)' },
        { value: 'XAF', label: 'CFA-FRANC BEAC (XAF)' },
        { value: 'XAG', label: 'SILVER (XAG)' },
        { value: 'XAU', label: 'GOLD (XAU)' },
        { value: 'XBA', label: 'EUROPEAN COMPOSITE UNIT (EURCO) (BOND MARKET UNIT) (XBA)' },
        { value: 'XBB', label: 'EUROPEAN MONETARY UNIT (E.M.U.-6) (BOND MARKET UNIT) (XBB)' },
        { value: 'XBC', label: 'EUROPEAN UNIT OF ACCOUNT 9  (E.U.A.-9) (BOND MARKET UNIT) (XBC)' },
        { value: 'XBD', label: 'EUROPEAN UNIT OF ACCOUNT 17  (E.U.A.-17) (BOND MARKET UNIT) (XBD)' },
        { value: 'XCD', label: 'EAST CARIBBEAN DOLLAR (XCD)' },
        { value: 'XDR', label: 'SPECIAL DRAWING RIGHTS (XDR)' },
        { value: 'XEU', label: 'EUROPEAN CURRENCY UNIT (XEU)' },
        { value: 'XOF', label: 'CFA FRANC BCEAO (XOF)' },
        { value: 'XPD', label: 'PALLADIUM (XPD)' },
        { value: 'XPF', label: 'CFP FRANC (XPF)' },
        { value: 'XPT', label: 'PLATINUM (XPT)' },
        { value: 'XSU', label: 'Sucre (XSU)' },
        { value: 'XUA', label: 'ADB Unit of Account (XUA)' },
        { value: 'XXR', label: 'Reichsmark (XXR)' },
        { value: 'YER', label: 'YEMENI RIAL (YER)' },
        { value: 'YUM', label: 'NEW DINAR (YUM)' },
        { value: 'ZAL', label: 'FINANCIAL RAND (ZAL)' },
        { value: 'ZAR', label: 'RAND (ZAR)' },
        { value: 'ZMK', label: 'KWACHA (ZMK)' },
        { value: 'ZMW', label: 'ZAMBIAN KWACHA (ZMW)' },
        { value: 'ZWD', label: 'ZIMBABWE DOLLAR (ZWD)' },
        { value: 'ZWL', label: 'ZIMBABWEAN DOLLAR (ZWL)' },
    ],
    entityNameOpton1: [
        { value: '1-ISSUER_NAMELONG', label: 'Long' },
        { value: '2-ISSUER_NAME_SHORT', label: 'Short' },
    ],
    entityNameOpton2: [
        { value: '3 Contains', label: 'Contains' },
        { value: '4 Does not contain', label: 'Does not contain' },
    ],
    issueDesOptions: [
        { value: '3 Contains', label: 'Contains' },
        { value: '4 Does not contain', label: 'Does not contain' },
    ],
    isinType: [
        { value: 'Primary ISIN', label: 'Primary' },
        { value: 'UnderlyingISIN', label: 'Underlying' },
    ],
};
export default options;
