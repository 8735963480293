class SecCompany {
    cik;
    id;
    name;
    sicDescription;
    tags;
    static columns = [
        { accessorKey: 'cik', header: 'CIK' },
        {
            accessorKey: 'name', header: 'Name',
        },
    ];
    static getSort = (s0, s1) => s0.name.localeCompare(s1.name);
}
export default SecCompany;
