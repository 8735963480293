import * as React from 'react';
import { TextField, Validation } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import { errorMessage } from 'utils/validation/initialValidation';
import Currency from '../../models/Currency';
const CurrencyForm = ({ currency, save }) => {
    const [data, setData] = React.useState(currency);
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        value: [Validators.Required, Validators.MinLength(3), Validators.MaxLength(3)],
    }), []);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const oldData = data ?? new Currency();
        setData({ ...oldData, [name]: value });
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Currency", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "currency-form" },
            React.createElement(Validation, { validator: validators.value, errorMessage: errorMessage, onValidationChanged: onValidationChanged('value') },
                React.createElement(TextField, { label: "Name", onChange: onChangeInput('value'), value: data?.value })))));
};
export default CurrencyForm;
