import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const CurrencyTable = ({ create, currencies, deleteFn, edit, hasError, isLoading, }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
        },
        { accessorKey: 'value', className: 'co-centered', header: 'Currency' },
    ], [deleteFn, edit]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "Currencies" }),
        React.createElement(CustomTable, { columns: columns, data: currencies, emptyMessage: "You have no currencies yet", errorMessage: "Error downloading currencies", hasError: hasError, isLoading: isLoading, name: "currency" })));
};
export default CurrencyTable;
