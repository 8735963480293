import * as React from 'react';
import { Button, Checkbox, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, TextArea, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import ServerRequest from 'models/ServerRequest';
import { errorMessage, initialValidation } from 'utils/validation/initialValidation';
import ApiServerURL from 'models/ServerUrl';
const findAreaCode = () => {
    if ('languages' in navigator && navigator.languages) {
        const firstLocale = navigator.languages[0];
        switch (firstLocale) {
            case 'de-DE': return 49;
            case 'en-GB': return 44;
            case 'fr-FR': return 33;
            case 'nl-NL': return 31;
            default:
        }
    }
    return 1;
};
const Contact = ({ close }) => {
    const [agreement, setAgreement] = React.useState(false);
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [external, setExternal] = React.useState(false);
    const [errorState, setErrorState] = React.useState({});
    const [data, setData] = React.useState({
        areaCode: `+${findAreaCode()}`,
    });
    const validators = React.useMemo(() => ({
        agreement: Validators.Required,
        company: Validators.Required,
        email: Validators.Email,
        firstName: Validators.Required,
        lastName: Validators.Required,
        message: Validators.Required,
    }), []);
    React.useEffect(() => {
        (async () => {
            const newErrorState = await initialValidation(validators, data);
            newErrorState.agreement = false;
            setErrorState(newErrorState);
        })();
    }, [data, validators]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/user/info');
            try {
                const response = await fetch(request);
                if (response.status === 200) {
                    const body = await response.json();
                    setData(Object.assign(data, body));
                    setExternal(true);
                }
            }
            catch (error) {
                //
            }
        })();
    }, [data]);
    React.useEffect(() => {
        const disabled = Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled);
    }, [errorState]);
    const clickContact = React.useCallback(() => {
        setButtonDisabled(true);
        const formData = new FormData();
        formData.append('firstName', data.firstName ?? '');
        formData.append('lastName', data.lastName ?? '');
        formData.append('areaCode', data.areaCode);
        formData.append('phoneNumber', data.phoneNumber ?? '');
        formData.append('email', data.email ?? '');
        formData.append('company', data.company ?? '');
        formData.append('message', data.message ?? '');
        const url = new ApiServerURL('/request-demo');
        const init = { body: formData, method: 'POST' };
        (async () => {
            try {
                await fetch(url.toString(), init);
                close();
            }
            catch (error) {
                //
            }
        })();
    }, [close, data.areaCode, data.company, data.email, data.firstName, data.lastName,
        data.message, data.phoneNumber]);
    const onChangeAgreement = React.useCallback((event) => {
        const { value } = event.target;
        const newErrorState = { ...errorState };
        newErrorState.agreement = value !== 'true';
        setErrorState(newErrorState);
        setAgreement(value === 'true');
    }, [errorState]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(Modal, { show: true, closeButton: true, onClose: close, parentNode: document.body, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Request a Demo")),
        React.createElement(ModalBody, null,
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('firstName'), validator: validators.firstName },
                React.createElement(TextField, { disabled: external, label: "First Name", onChange: onChangeInput('firstName'), value: data.firstName ?? '' })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('lastName'), validator: validators.lastName },
                React.createElement(TextField, { disabled: external, label: "Last Name", onChange: onChangeInput('lastName'), value: data.lastName ?? '' })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('email'), validator: validators.email },
                React.createElement(TextField, { label: "Email", onChange: onChangeInput('email'), value: data.email ?? '' })),
            React.createElement(TextField, { disabled: external, label: "Country Code", onChange: onChangeInput('areaCode'), value: data.areaCode || '' }),
            React.createElement(TextField, { disabled: external, label: "Phone Number", onChange: onChangeInput('phoneNumber'), value: data.phoneNumber ?? '' }),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('company'), validator: validators.company },
                React.createElement(TextField, { disabled: external, label: "Company", onChange: onChangeInput('company'), value: data.company ?? '' })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('message'), validator: validators.message },
                React.createElement(TextArea, { label: "Your message", onChange: onChangeInput('message'), value: data.message ?? '' })),
            React.createElement(Checkbox, { name: "agreement", value: agreement.toString(), onChange: onChangeAgreement, label: "I agree to my email being stored and used to receive communications from Vanadis Ltd." })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabledButton, onClick: clickContact }, "Contact"))));
};
export default Contact;
