import * as React from 'react';
import Constants from './pages/constants/Constants';
import DailyEmails from './pages/daily-emails/DailyEmails';
import Emails from './pages/emails/Emails';
import IFR from './pages/ifr/IFR';
import Live from './pages/live/Live';
import NavigationContext from 'contexts/NavigationContext';
import NoSuchTab from 'components/NoSuchTab';
import OpenNLPTest from './pages/opennlp-test/OpenNLPTest';
import Parties from './pages/parties/Parties';
import Regexes from './pages/regexes/Regexes';
import SystemAdmin from './pages/system-admin/SyndAdmin';
import ToDo from './pages/to-do/ToDo';
import Transactions from './pages/transactions/Transactions';
const SyndicateApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { code: 'overview', label: 'Transactions' },
        { code: 'live', label: 'Live Stream' },
        { auth: ['ADMIN'], code: 'emails', label: 'Emails' },
        { auth: ['ADMIN'], code: 'parties', label: 'Parties' },
        { auth: ['ADMIN'], code: 'regexes', label: 'Regexes' },
        { auth: ['ADMIN'], code: 'constants', label: 'Constants' },
        { auth: ['ADMIN'], code: 'daily', label: 'Daily' },
        { auth: ['ADMIN'], code: 'todo', label: 'To Do' },
        { auth: ['SUPER_ADMIN'], code: 'system', label: 'System' },
        { auth: ['ADMIN'], code: 'ifr', label: 'IFR' },
        { auth: ['SUPER_ADMIN'], code: 'ai', label: 'AI' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'overview':
            return React.createElement(Transactions, null);
        case 'live':
            return React.createElement(Live, null);
        case 'emails':
            return React.createElement(Emails, null);
        case 'parties':
            return React.createElement(Parties, null);
        case 'regexes':
            return React.createElement(Regexes, null);
        case 'constants':
            return React.createElement(Constants, null);
        case 'daily':
            return React.createElement(DailyEmails, null);
        case 'todo':
            return React.createElement(ToDo, null);
        case 'system':
            return React.createElement(SystemAdmin, null);
        case 'ifr':
            return React.createElement(IFR, null);
        case 'ai':
            return React.createElement(OpenNLPTest, null);
        default:
            return (React.createElement(NoSuchTab, { tab: tab }));
    }
};
export default SyndicateApp;
