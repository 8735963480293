import * as React from 'react';
const CompanyCellTranche = ({ row }) => {
    if (row.original.transaction.company === undefined) {
        return undefined;
    }
    const { company, name, type } = row.original.transaction.company;
    if (company?.name !== undefined) {
        let typeText;
        switch (type) {
            case 'GUARANTOR':
                typeText = ' (Guar.)';
                break;
            case 'OBLIGOR':
                typeText = ' (Obl.)';
                break;
            default:
                typeText = '';
        }
        return (React.createElement("b", null, company.name + typeText));
    }
    return name;
};
export default CompanyCellTranche;
