import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import DownloadContext from '../../../core/contexts/DownloadContext';
import MessageContext from '../../../core/contexts/MessageContext';
import ServerRequest from '../../../core/models/ServerRequest';
import { getOptions } from '../../../core/utils/change';
const ResultsPicker = ({ result, setDisabled, setResult, }) => {
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [placeHolder, setPlaceHolder] = React.useState('Select...');
    const { setMessage } = React.useContext(MessageContext);
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const onChangeResult = React.useCallback((event) => {
        const { value } = event.target.value;
        const newResult = result !== undefined ? { ...result, value } : { data: '', value };
        setResult(newResult);
    }, [setResult, result]);
    React.useEffect(() => {
        setDisabled(isLoading || hasError);
    }, [isLoading, hasError, setDisabled]);
    React.useEffect(() => {
        const request = new ServerRequest('/nit/results/options');
        (async () => {
            setLoading(true);
            try {
                const body = await jsonPlus(request, 'Error downloading results');
                if (body.length > 0) {
                    setOptions(body);
                    const first = body[0];
                    const option = 'options' in first ? first.options[0] : first;
                    const newResult = {
                        data: option.label,
                        value: option.value,
                    };
                    setResult(newResult);
                }
                else {
                    setError(true);
                    setPlaceHolder('No results found. Please run a search first.');
                }
            }
            catch (error) {
                setError(true);
                setPlaceHolder('Unable to download options. Please check connection');
            }
            setLoading(false);
        })();
    }, [jsonPlus, setMessage, setResult]);
    return (React.createElement(Dropdown, { className: "co-dropdown", isDisabled: hasError || isLoading, label: "Please select from saved results", onChange: onChangeResult, options: options, placeHolder: placeHolder, value: getOptions(result?.value, options) }));
};
export default ResultsPicker;
