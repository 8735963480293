import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import TableContext from 'components/custom-table/TableContext';
import TransactionWrapper from '../../../../models/TransactionWrapper';
import TransactionMetaForm from '../TransactionMetaForm';
import './TransactionMetaModal.css';
const TransactionMetaModal = ({ onClose, transaction }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const [isDisabled, setDisabled] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    const [transactionMeta, setTransactionMeta] = React.useState({});
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const initialTransactionMeta = React.useMemo(() => {
        return transaction.getMeta();
    }, [transaction]);
    const save = React.useCallback(async () => {
        if (transactionMeta.sector !== undefined && transactionMeta.regions !== undefined && transactionMeta.types !== undefined && tableUpdateFn !== undefined && transactionMeta.companyName !== undefined) {
            const formData = new FormData();
            formData.append('transactionId', transaction.id);
            formData.append('sector', transactionMeta.sector);
            formData.append('regions', transactionMeta.regions.join(","));
            formData.append('types', transactionMeta.types.join(','));
            formData.append('companyName', transactionMeta.companyName);
            const request = new ServerRequest('/syndicate/transactions/meta-all-update', { body: formData, method: 'PATCH' });
            try {
                const responseTransction = await apiFetchJson(request);
                const returnTransaction = new TransactionWrapper(responseTransction);
                const updateFn = (data) => ({
                    pageParams: data.pageParams,
                    pages: [...data.pages].map((p) => p
                        .map((t) => (t.id === transaction.id ? returnTransaction : t))),
                });
                tableUpdateFn(updateFn);
            }
            catch (error) {
                sendFetchError('Unable to merge transactions', error, request);
            }
        }
    }, [apiFetchJson, transactionMeta, transaction.id, tableUpdateFn]);
    const onClickSave = React.useCallback(() => {
        if (transactionMeta.sector !== undefined && transactionMeta.regions !== undefined && transactionMeta.types !== undefined && transactionMeta.companyName !== undefined) {
            (async () => {
                setLoading(true);
                await save();
                setLoading(false);
                onClose();
            })();
        }
    }, [onClose, save, transactionMeta]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sectors');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setSectorOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download Sector options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'regions');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setRegionOptions(body);
            }
            catch (error) {
                sendFetchError("Unable to update region", error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'types');
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setTypeOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download type options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        setDisabled(transactionMeta.sector === undefined || transactionMeta.regions === undefined || transactionMeta.regions.length === 0 || transactionMeta.types === undefined || transactionMeta.types.length === 0 || (transactionMeta.companyName === undefined || transactionMeta.companyName.length < 3));
    }, [transactionMeta]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Set Meta data transaction"),
            React.createElement("div", null, transaction.name)),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-form" },
                React.createElement(TransactionMetaForm, { isLoading: isLoading, regionOptions: regionOptions, sectorOptions: sectorOptions, typeOptions: typeOptions, initialTransactionMeta: initialTransactionMeta, setTransactionMeta: setTransactionMeta }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, loading: isLoading, onClick: onClickSave }, "SAVE"))));
};
export default TransactionMetaModal;
