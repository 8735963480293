import { Checkbox, Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import TableStateClass from './TableStateClass';
const ColumnPicker = function ColumnPickerElement({ onClose, table }) {
    const bodyRef = React.useRef(null);
    const [checkboxList, setCheckboxList] = React.useState([]);
    const [visibilityMap, setVisibilityMap] = React.useState(new Map());
    const onChange = React.useCallback((column) => () => {
        const newMap = new Map(visibilityMap);
        column.toggleVisibility();
        newMap.set(column.id, !newMap.get(column.id));
        setVisibilityMap(newMap);
        const updatedState = new TableStateClass(table.getState()).updateVisibility(newMap);
        table.setState(updatedState);
    }, [visibilityMap, table]);
    React.useEffect(() => {
        const newMap = new Map();
        const allColumns = table.getAllLeafColumns().filter((c) => typeof c.columnDef.header === 'string');
        allColumns.forEach((c) => newMap.set(c.id, c.getIsVisible()));
        setVisibilityMap(newMap);
    }, [table]);
    React.useLayoutEffect(() => {
        if (bodyRef.current !== null) {
            const allColumns = table.getAllLeafColumns().filter((c) => typeof c.columnDef.header === 'string');
            const numberOfColumns = Math.round(bodyRef.current.clientWidth / 200);
            const numberOfRows = Math.ceil(table.getAllLeafColumns().length / numberOfColumns);
            const list = [];
            for (let row = 0; row < numberOfRows; row += 1) {
                const rowList = [];
                for (let col = 0; col < numberOfColumns; col += 1) {
                    const index = col * numberOfRows + row;
                    if (index < allColumns.length) {
                        const column = allColumns[index];
                        rowList.push(React.createElement("div", { key: column.id, style: { width: '200px' } },
                            React.createElement(Checkbox, { label: column.columnDef.header?.toString(), name: column.id, onChange: onChange(column), status: visibilityMap.get(column.id) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: "" })));
                    }
                    else {
                        rowList.push(React.createElement("div", { key: `empty-${row}-${col}`, style: { width: '200px' } }));
                    }
                }
                list.push(React.createElement("div", { key: `row-${row}`, style: { display: 'flex' } }, rowList));
            }
            setCheckboxList(list);
        }
    }, [onChange, table, visibilityMap]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Please choose columns")),
        React.createElement("div", { ref: bodyRef },
            React.createElement(ModalBody, null, checkboxList))));
};
export default ColumnPicker;
