import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
const AddCorpNameModal = ({ updateNamesOfCompany, company, name, onClose }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [loading, setLoading] = React.useState(false);
    const onClickSave = React.useCallback(() => {
        if (company.companyId !== undefined) {
            const formData = new FormData();
            formData.append('companyId', company.companyId);
            formData.append('name', name);
            const request = new ServerRequest('/admin/companies/add-name', { body: formData, method: 'PATCH' });
            (async () => {
                try {
                    setLoading(true);
                    const updatedNames = await apiFetchJson(request);
                    updateNamesOfCompany(updatedNames);
                    onClose();
                }
                catch (error) {
                    sendFetchError('Unable to save name to company', error, request);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [updateNamesOfCompany, apiFetchJson, company.companyId, name, onClose]);
    const currentAlternativeNames = React.useMemo(() => company.company?.alternativeNames?.join(", "), [company.company?.alternativeNames]);
    const content = React.useMemo(() => {
        if (company.company === undefined) {
            return (React.createElement("p", null, "Please set up "));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Add:"),
            React.createElement("p", null,
                React.createElement("b", null, name))));
    }, [company.company, name]);
    const disabled = React.useMemo(() => company.companyId === undefined, [company.companyId]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: 'medium' },
        React.createElement(ModalTitle, null, company.company?.name ?? "No Company Set Yet"),
        React.createElement(ModalBody, null,
            React.createElement("p", null,
                "Current names: ",
                currentAlternativeNames !== undefined ? (React.createElement("b", null, currentAlternativeNames)) : (React.createElement("i", null, "None Yet"))),
            content),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabled, loading: loading, onClick: onClickSave }, "ADD"))));
};
export default AddCorpNameModal;
