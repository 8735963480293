import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import SectorSelector from '../settings/SectorSelector';
const StreamFilterModal = ({ filter, onClose, setFilter, }) => {
    const [newFilter, setNewFilter] = React.useState(filter ?? []);
    const onClickSave = React.useCallback(() => {
        localStorage.setItem('stream-filter', JSON.stringify(newFilter));
        setFilter(newFilter);
        onClose();
    }, [newFilter, onClose, setFilter]);
    return (React.createElement(Modal, { className: 'cmo-dropdown-absolute', closeButton: true, onClose: onClose, show: true, size: "medium" },
        React.createElement(ModalTitle, null, "Filter Live Stream"),
        React.createElement(ModalBody, null,
            React.createElement(SectorSelector, { filter: newFilter, updateFilter: setNewFilter })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave }, "SAVE"))));
};
export default StreamFilterModal;
