import * as React from 'react';
import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import { TableContextProvider } from 'components/custom-table/TableContext';
import NavigationContext from 'contexts/NavigationContext';
import SyndicateContext from '../../../context/SyndicateContext';
import TransactionWrapper from '../../../models/TransactionWrapper';
import TransactionMergeButton from './TransactionMergeButton';
import TransactionTable from './transaction-table/TransactionTable';
const TransactionViewer = () => {
    const { updateView } = React.useContext(NavigationContext);
    const { removeUpdateId } = React.useContext(SyndicateContext);
    const dataTransform = React.useCallback((response) => response.map((r) => new TransactionWrapper(r)), []);
    React.useEffect(() => {
        removeUpdateId();
    }, [removeUpdateId]);
    return (React.createElement(TableContextProvider, null,
        React.createElement(Card, { className: "co-card-main" },
            React.createElement("div", { className: "co-header-main" },
                React.createElement(Typography, { type: "h1" }, "Transactions"),
                React.createElement("div", null,
                    React.createElement(Button, { name: "overview", onClick: () => updateView('OVERVIEW'), size: "small" }, "NEW ISSUES"))),
            React.createElement(TransactionTable, { actionElements: [(React.createElement(TransactionMergeButton, { key: "tmb" }))], dataTransform: dataTransform }))));
};
export default TransactionViewer;
