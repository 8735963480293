import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, } from '@symphony-ui/uitoolkit-components';
import { useAuth } from 'react-oidc-context';
import Contact from './Contact';
import './RightPanel.scss';
const RightPanel = () => {
    const [showContact, setShowContact] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const auth = useAuth();
    const clickContact = React.useCallback(() => {
        setShowContact(true);
    }, []);
    const clickLogin = React.useCallback(() => {
        (async () => {
            try {
                await auth.signinRedirect();
            }
            catch (error) {
                setShowModal(true);
            }
        })();
    }, [auth]);
    return (React.createElement("div", { id: "right-panel" },
        React.createElement(Modal, { closeButton: true, onClose: () => setShowModal(false), show: showModal, size: "small" },
            React.createElement(ModalBody, null, "Unable to contact Authentication Server, please check your connection."),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { onClick: () => setShowModal(false), variant: "primary-destructive" }, "OK"))),
        showContact
            ? React.createElement(Contact, { close: () => setShowContact(false) }) : '',
        React.createElement(Button, { onClick: clickContact }, "REQUEST A DEMO"),
        React.createElement(Button, { onClick: clickLogin }, "SIGN IN")));
};
export default RightPanel;
