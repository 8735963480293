import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, TextField, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import AnnaCompany from '../../models/company/AnnaCompany';
const AnnaCompanyModal = ({ company, hideModal, updateCompany }) => {
    const [data, setData] = React.useState(company);
    const [isLoading, setLoading] = React.useState(false);
    const onChange = {
        input: (name) => (event) => {
            const { value } = event.target;
            if (name === 'nameLong') {
                setData({ ...data, nameLong: value });
            }
            else {
                const [type, field] = name.split('.');
                const address = { ...data[type], [field]: value };
                setData({ ...data, [type]: address });
            }
        },
        inputAddress: (type, item) => (event) => {
            const { value } = event.target;
            const newData = Object.assign(new AnnaCompany(), data);
            newData[type][item] = value;
            setData(newData);
        },
    };
    const onClick = {
        save: async () => {
            setLoading(true);
            const request = new ServerRequest('/nit/anna/company', {
                body: JSON.stringify(data),
                headers: { 'content-type': 'application/json' },
                method: 'PUT',
            });
            await fetch(request);
            setLoading(false);
            updateCompany(data);
            hideModal();
        },
    };
    const test = (value) => value?.includes('��');
    const items = () => {
        const addressTypes = ['reg', 'hq'];
        const addressItems = ['address1', 'address2', 'city', 'country', 'postalCode'];
        const list = [];
        addressTypes.forEach((type) => {
            addressItems.forEach((item) => {
                if (type in company && test(company[type]?.[item])) {
                    list.push(React.createElement(TextField, { key: `${type}.${item}`, label: `${type} ${item}`, onChange: onChange.input(`${type}.${item}`), value: data[type][item] || '' }));
                }
            });
        });
        return list;
    };
    return (React.createElement(Modal, { show: true, closeButton: true, onClose: hideModal, parentNode: document.body, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Correct Diacriticals")),
        React.createElement(ModalBody, null,
            test(company.nameLong) ? React.createElement(TextField, { label: "Name Long", onChange: onChange.input('nameLong'), value: data.nameLong || '' }) : '',
            items()),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: isLoading, onClick: onClick.save }, "Save"))));
};
export default AnnaCompanyModal;
