var _a;
class SecQuery {
    CIK;
    company;
    dateb;
    owner;
    type;
    constructor(response) {
        this.company = response?.company;
        this.CIK = response?.CIK;
        this.dateb = response?.dateb;
        this.owner = response?.owner;
        this.type = response?.type;
    }
    #clone() {
        return new _a({
            CIK: this.CIK,
            company: this.company,
            dateb: this.dateb,
            owner: this.owner,
            type: this.type
        });
    }
    updateField(field, value) {
        const clone = this.#clone();
        clone[field] = value;
        return clone;
    }
}
_a = SecQuery;
export default SecQuery;
