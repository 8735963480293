var _a;
class EsmaQuery {
    textCriteria;
    constructor(response) {
        this.textCriteria = response?.textCriteria ?? [];
    }
    #clone() {
        return new _a({
            textCriteria: this.textCriteria
        });
    }
    update(name, value) {
        const newTextCriteria = { name, values: value };
        const clone = this.#clone();
        const index = clone.textCriteria.findIndex((l) => l.name === name);
        if (index === -1) {
            clone.textCriteria.push(newTextCriteria);
        }
        else {
            clone.textCriteria[index] = newTextCriteria;
        }
        return clone;
    }
    remove(textCriteria) {
        const clone = this.#clone();
        clone.textCriteria = clone.textCriteria.filter((l) => l.name !== textCriteria.name);
        return clone;
    }
}
_a = EsmaQuery;
export default EsmaQuery;
