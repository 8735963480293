import { BannerType, Button, Card, DatePicker, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
const SyndDbManagement = () => {
    const { apiFetchJson: jsonPlus, apiFetchText: textPlus } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const [isDisabled, setDisabled] = React.useState(true);
    const [options, setOptions] = React.useState([]);
    const [isLoading, setLoading] = React.useState();
    const [path, setPath] = React.useState();
    const [date, setDate] = React.useState();
    const onChange = React.useCallback((event) => {
        setPath(event.target.value.value);
    }, []);
    const onChangeDate = React.useCallback((event) => {
        setDate(event.target.value);
    }, []);
    const onClick = React.useCallback(() => {
        const url = new ApiServerURL(`/syndicate/system/${path}`);
        if (date !== undefined) {
            url.searchParams.append('from', date?.toISOString());
        }
        const request = new ServerRequest(url);
        (async () => {
            try {
                const text = await textPlus(request, `Error with ${path} `);
                setMessage({ content: text, type: BannerType.SUCCESS });
            }
            catch (error) {
                //
            }
        })();
    }, [date, textPlus, path, setMessage]);
    React.useEffect(() => {
        setDisabled(path === '' || typeof path === 'undefined');
    }, [path]);
    React.useEffect(() => {
        const optionsRequest = new ServerRequest('/syndicate/system/options');
        (async () => {
            setLoading(true);
            try {
                const body = await jsonPlus(optionsRequest, 'Error getting Transactions count');
                setOptions(body);
            }
            catch (error) {
                //
            }
            setLoading(false);
        })();
    }, [jsonPlus]);
    console.log('Synd DB managemen');
    return (React.createElement(Card, { style: { height: 'min-content', marginBottom: '1rem', padding: '1rem' } },
        React.createElement(Typography, { type: "h1" }, "Database Management"),
        React.createElement("div", { style: { display: 'flex', width: '100%' } },
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Dropdown, { size: "large", isDisabled: isLoading, label: "Select a task", name: "type", onChange: onChange, options: options, value: options.find((o) => o.value === path), width: "100px" })),
            React.createElement("div", { style: { marginLeft: '1rem' } },
                React.createElement(DatePicker, { date: date, disabledDays: [{ before: new Date(2022, 1, 17) }, { after: new Date() }], label: "Restrict to data from", onChange: onChangeDate })),
            React.createElement("div", { style: { marginLeft: '1rem', marginTop: '1.5rem' } },
                React.createElement(Button, { disabled: isDisabled, onClick: onClick, type: "button" }, "GO")))));
};
export default SyndDbManagement;
