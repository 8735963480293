import { Button, Icon, TextField, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
const ConstantDisplayName = ({ displayName, id }) => {
    const { apiFetchNoContent: noContentPlus } = React.useContext(DownloadContext);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState('');
    const textRef = React.useRef(null);
    React.useEffect(() => {
        setValue(displayName ?? '');
    }, [displayName]);
    const onClickAdd = React.useCallback(() => {
        setEditing(true);
        if (textRef.current !== null) {
            //
        }
    }, []);
    const onClickEdit = React.useCallback(() => {
        setEditing(true);
    }, []);
    const onClickSave = React.useCallback(() => {
        if (id !== undefined && value !== undefined) {
            const url = new ApiServerURL('/syndicate/constant/display-name');
            url.searchParams.append('id', id);
            url.searchParams.append('displayName', value);
            const request = new ServerRequest(url, { method: 'POST' });
            (async () => {
                try {
                    await noContentPlus(request, 'Unable to save override information');
                    setEditing(false);
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [noContentPlus, id, value]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUpEdit = React.useCallback(() => {
    }, []);
    if (!isEditing) {
        if (displayName === undefined) {
            return React.createElement(Button, { onClick: onClickAdd, variant: "tertiary" }, "ADD DISPLAY NAME");
        }
        return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("label", { htmlFor: "display-name", className: "tk-label" }, "Display Name"),
            React.createElement("div", { className: "co-clickable-text", id: "display-name", onClick: onClickEdit, onFocus: () => { }, onKeyUp: onKeyUpEdit, role: "button", tabIndex: 0 }, value)));
    }
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(TextField, { label: "Display Name", onChange: onChange, ref: textRef, value: value, rightDecorators: (React.createElement(Button, { iconButton: true, onClick: onClickSave, size: "small", style: { marginTop: '0.25rem' }, variant: "secondary" },
                React.createElement(Icon, { iconName: "enter" }))) })));
};
export default ConstantDisplayName;
