import * as React from 'react';
import { Loader } from '@symphony-ui/uitoolkit-components';
const LEIRecordCard = ({ entity, loading }) => {
    const camelCase = React.useCallback((text) => text.split("_").map(i => i.substring(0, 1) + i.substring(1).toLowerCase()).join(" "), []);
    if (loading) {
        return (React.createElement(Loader, null));
    }
    if (entity === undefined) {
        return (React.createElement("p", null, "Entity empty"));
    }
    return (React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', padding: '0.25rem', fontSize: 'small',
        } },
        React.createElement("div", null,
            entity.legalName.name,
            " ",
            entity.legalName.language !== 'en' ? entity.legalName.language : undefined),
        entity.otherNames.map(n => (React.createElement("em", { key: n.name },
            n.name,
            " (",
            n.language,
            " - ",
            camelCase(n.type),
            ")"))),
        React.createElement("div", null,
            entity.headquartersAddress.city,
            ",",
            ' ',
            entity.headquartersAddress.country),
        React.createElement("div", null,
            entity.legalAddress.city,
            ",",
            ' ',
            entity.legalAddress.country),
        React.createElement("div", null, entity.category),
        React.createElement("div", null, entity.status)));
};
export default LEIRecordCard;
