import Regex from './Regex';
class SingleItemRegex {
    regex;
    subregexes;
    constructor({ regex, subregexes }) {
        this.regex = new Regex(regex);
        if (subregexes !== undefined) {
            this.subregexes = subregexes.map((r) => new SingleItemRegex(r));
        }
    }
    static create(singleItemRegexInterface) {
        return new SingleItemRegex(singleItemRegexInterface);
    }
}
export default SingleItemRegex;
