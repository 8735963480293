export const BMEColumns = [];
export const BORSAColumns = [
    { accessorKey: 'security', header: 'Security' },
    { accessorKey: 'offeringPrice', header: 'Offering Price' },
    { accessorKey: 'market', header: 'Market' },
    { accessorKey: 'stockDescription', header: 'Stock Description' },
];
export const EuronextColumns = [
    {
        accessorFn: (ipo) => ipo.iponiDisplayTitle?.name,
        header: 'Name',
    },
    { accessorKey: 'iponiIpoDate', header: 'Date' },
    { accessorKey: 'iponiTickerSymbol', header: 'Ticker' },
    { accessorKey: 'issueType', header: 'Issue Type' },
    { accessorKey: 'iponiPriceRange', header: 'Price Range' },
    { accessorKey: 'tradingLocation', header: 'Location' },
    { accessorKey: 'listingSponsor', header: 'Listing Sponsor' },
    { accessorKey: 'iponiWebSiteAddress', header: 'Web Site Address' },
    { accessorKey: 'iponiIsinCode', header: 'ISIN' },
    { accessorKey: 'iponiLeiCode', header: 'LEI' },
];
export const HKSEColumns = [];
export const LSEColumns = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'priceRange', header: 'Price Range' },
    { accessorKey: 'market', header: 'Market' },
    { accessorKey: 'currency', header: 'Currency' },
    { accessorKey: 'type', header: 'Type' },
    { accessorKey: 'link', header: 'Link' },
];
export const NASDAQColumns = [
    { accessorKey: 'companyName', header: 'Name' },
    { accessorKey: 'expectedPriceDate', header: 'Expected Price Date' },
    { accessorKey: 'proposedSharePrice', header: 'Proposed Share Price' },
    { accessorKey: 'proposedExchange', header: 'Proposed Exchange' },
    { accessorKey: 'proposedTickerSymbol', header: 'Ticker Symbol' },
];
export const NYSEColumns = [
    { accessorKey: 'issuerNm', header: 'Name' },
    { accessorKey: 'expected_dt_report', header: 'Expected Date' },
    { accessorKey: 'current_file_price_range_usd', header: 'Price Range' },
    { accessorKey: 'deal_status_desc', header: 'Deal Status' },
    { accessorKey: 'offer_px_usd', header: 'Offer Price' },
    { accessorKey: 'current_shares_filed', header: 'Shares Filed' },
];
export const XETRAColumns = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'date', header: 'Date' },
    { accessorKey: 'bookbuildingRange', header: 'Bookbuilding Range' },
    { accessorKey: 'issuingMethod', header: 'Issuing Method' },
];
