import * as React from 'react';
import ProgressBar from './ProgressBar';
import SyndData from './SyndData';
import SyndDbManagement from './SyndDbManagement';
import WebSocketContext from 'contexts/WebSocketContext';
const SystemAdmin = () => {
    const { addSubscription } = React.useContext(WebSocketContext);
    const [progressData, setProgressData] = React.useState([]);
    const showProgress = React.useCallback((e) => {
        const newProgressData = JSON.parse(e.body);
        const progressDataClone = [...progressData];
        const toUpdate = progressDataClone.find((p) => p.title === newProgressData.title);
        if (toUpdate === undefined) {
            progressDataClone.push(newProgressData);
        }
        else {
            toUpdate.count = newProgressData.count;
            toUpdate.done = newProgressData.done;
        }
        setProgressData(progressDataClone);
    }, [progressData]);
    React.useEffect(() => {
        addSubscription('/topic/progress', showProgress);
    }, [addSubscription, showProgress]);
    const closeProgressBar = React.useCallback((progressBar) => () => {
        const progressDataClone = progressData.filter((p) => p !== progressBar);
        setProgressData(progressDataClone);
    }, [progressData]);
    return (React.createElement(React.Fragment, null,
        progressData.map((pr) => (React.createElement(ProgressBar, { close: closeProgressBar(pr), count: pr.count, errors: pr.errors, key: pr.title + pr.timestamp.toString(), timestamp: pr.timestamp, title: pr.title, total: pr.total }))),
        React.createElement(SyndData, null),
        React.createElement(SyndDbManagement, null)));
};
export default SystemAdmin;
