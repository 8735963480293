import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import Report from '../models/Report';
import ReportForm from '../components/reports/ReportForm';
import ReportTable from '../components/reports/ReportTable';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const Reports = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [data, setData] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [report, setReport] = React.useState();
    React.useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        const request = new ServerRequest('/nit/report');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const loaded = body.map(r => new Report(r));
                setData(loaded);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setError(true);
                    sendFetchError("Unable to download Reports", error, request);
                }
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const create = React.useCallback(() => {
        setReport(new Report());
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest('/nit/report', requestInit);
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredData = data.filter((i) => i.id !== item.id);
                setData(filteredData);
            }
            catch (error) {
                sendFetchError('Unable to delete Report', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, data]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setReport(item);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback((item) => async () => {
        const method = item.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/nit/report', requestInit);
        try {
            const body = await apiFetchJson(request);
            ;
            const newReport = new Report(body);
            const index = data.findIndex((d) => d.id === newReport.id);
            const updateData = [...data];
            if (index === -1) {
                updateData.push(newReport);
            }
            else {
                updateData[index] = newReport;
            }
            setData(updateData);
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError('Unable to update Report', error, request);
        }
    }, [apiFetchJson, data, updateView]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [view, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(ReportForm, { report: report, save: save }));
        case 'TABLE':
            return (React.createElement(ReportTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, reports: data }));
        default:
            updateView('TABLE');
            return React.createElement(LoadingPage, null);
    }
};
export default Reports;
