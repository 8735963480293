import { BannerType, Button, Switch } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import { sendError, sendFetchError, sendMessage } from 'utils/messageUtils';
import UpdatesWrapper from '../../../../../models/UpdatesWrapper';
import ContentModal from '../../../../regexes/ContentModal';
import JoinModal from './JoinModal';
import SubRow from './SubRow';
import MesssagesModal from '../../../overview/MessagesModal';
import TransactionSplitModal from '../../TransactionSplitModal';
const SubComponent = ({ colSpan, transactionId, transactionMeta }) => {
    const { apiFetchNoContent, apiFetchJson } = React.useContext(DownloadContext);
    const [isJoinDisabled, setJoinDisabled] = React.useState(false);
    const [isUnjoinDisabled, setUnjoinDisabled] = React.useState(false);
    const [isSplitDisabled, setSplitDisabled] = React.useState(false);
    const [isAutogroupLoading, setAutogroupLoading] = React.useState(false);
    const [isJoinLoading, setJoinLoading] = React.useState(false);
    const [isRebuildLoading, setRebuildLoading] = React.useState(false);
    const [isSplitLoading, setSplitLoading] = React.useState(false);
    const [isUnjoinLoading, setUnjoinLoading] = React.useState(false);
    const [showContentModal, setShowContentModal] = React.useState(false);
    const [showJoinModal, setShowJoinModal] = React.useState(false);
    const [showMessagesModal, setShowMessagesModal] = React.useState(false);
    const [showSplitModal, setShowSplitModal] = React.useState(false);
    const [selectedUpdate, setSelectedUpdate] = React.useState();
    const [isRaw, setRaw] = React.useState(true);
    const [updatesWrapper, setUpdatesWrapper] = React.useState(new UpdatesWrapper([]));
    const processMessage = React.useCallback((message, error) => {
        if (message != null) {
            sendMessage(message, BannerType.SUCCESS);
        }
        else if (error != null) {
            sendMessage(error, BannerType.ERROR);
        }
    }, []);
    /*
    This method throws an error that must be caught downstream
    */
    const createServerRequest = React.useCallback(async (path) => {
        const allMessageIds = updatesWrapper?.checkedUpdates().map((u) => u.emailId);
        const options = { type: 'application/json' };
        const formData = new FormData();
        formData.append('messageIds', new Blob([JSON.stringify(allMessageIds)], options));
        formData.append('transactionId', transactionId);
        const request = new ServerRequest(`/syndicate/update/${path}`, { body: formData, method: 'POST' });
        const body = await apiFetchJson(request);
        return body;
    }, [apiFetchJson, transactionId, updatesWrapper]);
    const joinUpdates = React.useCallback(() => {
        setJoinLoading(true);
        (async () => {
            try {
                const body = await createServerRequest('join');
                setUpdatesWrapper(new UpdatesWrapper(body.updates));
                processMessage(body.message, body.error);
                setJoinLoading(false);
                setShowJoinModal(false);
            }
            catch (error) {
                sendError('Unable to join updates', error);
            }
        })();
    }, [createServerRequest, processMessage]);
    const onClickAutogroup = React.useCallback(() => {
        setAutogroupLoading(true);
        const formData = new FormData();
        formData.append('transactionId', transactionId);
        const request = new ServerRequest('/syndicate/transactions/auto-group', { body: formData, method: 'POST' });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                setUpdatesWrapper(new UpdatesWrapper(body.updates));
                processMessage(body.message, body.error);
            }
            catch (error) {
                sendFetchError('Unable to auto group transactions', error, request);
            }
            finally {
                setAutogroupLoading(false);
            }
        })();
    }, [apiFetchJson, processMessage, transactionId]);
    const onClickJoin = React.useCallback(() => {
        setShowJoinModal(true);
    }, []);
    const onClickRebuild = React.useCallback(() => {
        setRebuildLoading(true);
        const formData = new FormData();
        formData.append('transactionId', transactionId);
        const request = new ServerRequest('/api/syndicate/rebuild', { body: formData, method: 'POST' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                setShowMessagesModal(true);
            }
            catch (error) {
                sendFetchError('Unable to rebuild transaction messages', error, request);
                //
            }
            finally {
                setRebuildLoading(false);
            }
        })();
    }, [apiFetchNoContent, transactionId]);
    const onClickSplit = React.useCallback(() => {
        setShowSplitModal(true);
    }, []);
    const onClickUnjoin = React.useCallback(() => {
        setUnjoinLoading(true);
        (async () => {
            try {
                const body = await createServerRequest('unjoin');
                setUpdatesWrapper(new UpdatesWrapper(body.updates));
                processMessage(body.message, body.error);
            }
            catch (error) {
                sendError('Unable to unjoin updates', error);
            }
            finally {
                setUnjoinLoading(false);
            }
        })();
    }, [createServerRequest, processMessage]);
    const onChangeRaw = React.useCallback(() => {
        setRaw(!isRaw);
    }, [isRaw]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/update-meta');
            url.searchParams.append('transactionId', transactionId);
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const updatesNew = new UpdatesWrapper(body);
                setUpdatesWrapper(updatesNew);
            }
            catch (error) {
                sendFetchError('Unable to download transactions', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, transactionId]);
    const checkUpdate = React.useCallback((emailId) => {
        const updated = updatesWrapper.check(emailId);
        setUpdatesWrapper(updated);
    }, [updatesWrapper]);
    React.useEffect(() => {
        setJoinDisabled(updatesWrapper.numberOriginalChecked() + updatesWrapper.numberCopyChecked() < 2);
        setUnjoinDisabled(updatesWrapper.numberCopyChecked() === 0);
        setSplitDisabled(updatesWrapper.numberCopyChecked() + updatesWrapper.numberOriginalChecked() === 0);
    }, [updatesWrapper]);
    if (updatesWrapper.updates.length === 0) {
        return null;
    }
    return (React.createElement("tr", { style: {
            transition: 'height 1s linear',
        } },
        React.createElement("td", { colSpan: colSpan },
            typeof selectedUpdate !== 'undefined'
                ? (React.createElement(ContentModal, { update: selectedUpdate, hideModal: () => setShowContentModal(false), show: showContentModal })) : null,
            showSplitModal
                ? (React.createElement(TransactionSplitModal, { onClose: () => setShowSplitModal(false), originalTransactionMeta: transactionMeta, updates: updatesWrapper, isLoading: isSplitLoading, setLoading: setSplitLoading, transactionId: transactionId })) : undefined,
            React.createElement(JoinModal, { hideModal: () => setShowJoinModal(false), isLoading: isJoinLoading, join: joinUpdates, show: showJoinModal, updates: updatesWrapper }),
            showMessagesModal ? (React.createElement(MesssagesModal, { hideModal: () => setShowMessagesModal(false), show: showMessagesModal, splitScreen: false, transactionId: transactionId })) : undefined,
            React.createElement("table", { style: {
                    fontSize: 'small', marginLeft: '3rem', tableLayout: 'fixed', width: 'calc(100% - 3rem)',
                } },
                React.createElement("tbody", null, updatesWrapper.groupByOriginal().map((u) => (React.createElement(React.Fragment, { key: `${u.emailId.id}${u.emailId.index}` },
                    React.createElement(SubRow, { className: u.originalEmailId !== undefined ? 'co-sub-row' : undefined, checkUpdate: checkUpdate, raw: isRaw, setSelectedUpdate: setSelectedUpdate, setShowModal: setShowContentModal, update: u })))))),
            React.createElement("div", { style: { display: 'flex', marginLeft: '2rem', marginTop: '0.5rem' } },
                React.createElement(Button, { disabled: isJoinDisabled, loading: isJoinLoading, variant: "secondary", onClick: onClickJoin, style: { marginRight: '0.5rem' } }, "JOIN"),
                React.createElement(Button, { disabled: isUnjoinDisabled, loading: isUnjoinLoading, variant: "secondary", onClick: onClickUnjoin, style: { marginRight: '0.5rem' } }, "UNJOIN"),
                React.createElement(Button, { disabled: isSplitDisabled, loading: isSplitLoading, variant: "secondary", onClick: onClickSplit, style: { marginRight: '0.5rem' } }, "Split into New Transaction"),
                React.createElement(Button, { loading: isAutogroupLoading, variant: "secondary", onClick: onClickAutogroup, style: { marginRight: '0.5rem' } }, "Auto Group"),
                React.createElement(Button, { loading: isRebuildLoading, variant: "secondary", onClick: onClickRebuild, style: { marginRight: '0.5rem' } }, "Rebuild Messages"),
                React.createElement(Switch, { label: isRaw ? 'Raw' : 'Clean', name: "raw", onChange: onChangeRaw, status: isRaw ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: isRaw ? 'checked' : 'unchecked' })))));
};
export default SubComponent;
