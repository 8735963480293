import ProblemDetailError from 'models/ProblemDetailsError';
import UnauthorizedError from 'models/UnauthorizedError';
import { setOffline, setOnline } from 'utils/messageUtils';
class DownloadService {
    #authorizedFetch;
    constructor({ authorizedFetch }) {
        this.#authorizedFetch = authorizedFetch;
    }
    apiFetchNoContent = async (request, signal) => {
        try {
            const response = await this.#authorizedFetch(request, signal);
            setOnline();
            if (response.status === 204) {
                // need to read empty body to avoid console error 'Fetch failed loading'
                await response.text();
            }
            else {
                await this.#handleError(response);
            }
        }
        catch (error) {
            setOffline(error);
            throw error;
        }
    };
    apiFetchJson = async (request, signal) => {
        try {
            const response = await this.#authorizedFetch(request, signal);
            setOnline();
            if (response.status === 200) {
                return await response.json();
            }
            await this.#handleError(response);
        }
        catch (error) {
            setOffline(error);
            throw error;
        }
    };
    apiFetchText = async (request, signal) => {
        try {
            const response = await this.#authorizedFetch(request, signal);
            setOnline();
            if (response.status === 200) {
                return await response.text();
            }
            if (response.status === 401) {
                const event = new CustomEvent('logout');
                window.dispatchEvent(event);
                throw new UnauthorizedError();
            }
            const problemDetail = await response.json();
            throw new ProblemDetailError(problemDetail);
        }
        catch (error) {
            setOffline(error);
            throw error;
        }
    };
    #handleError = async (response) => {
        if (response.status === 401) {
            const event = new CustomEvent('logout');
            window.dispatchEvent(event);
            throw new UnauthorizedError();
        }
        const problemDetail = await response.json();
        throw new ProblemDetailError(problemDetail);
    };
}
export default DownloadService;
