class EsmaCompany {
    id;
    lei;
    entity;
    tags;
    static columns = [
        { accessorKey: 'lei', header: 'LEI' },
        { accessorKey: 'entity.legalName.name', header: 'Name' },
    ];
    static getSort = (s0, s1) => s0.entity.legalName.name.localeCompare(s1.entity.legalName.name);
}
export default EsmaCompany;
