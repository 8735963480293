import * as React from 'react';
import CustomTable from 'components/custom-table/CustomTable';
import EuroclearSecurity from '../../models/security/EuroclearSecurity';
import EsmaSecurity from '../../models/security/EsmaSecurity';
import AnnaSecurity from '../../models/security/AnnaSecurity';
import SecSecurity from '../../models/security/SecSecurity';
import { Source } from '../../sources/sources';
const SecuritiesTable = ({ currencies, filteredData, hasError, isLoading, source, }) => {
    const [modalContent, setModalContent] = React.useState();
    const table = React.useMemo(() => {
        switch (source) {
            case Source.ANNA: {
                return (React.createElement(CustomTable, { columns: AnnaSecurity.columns(currencies, setModalContent), data: filteredData.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "anna-security" }));
            }
            case Source.ESMA: {
                return (React.createElement(CustomTable, { columns: EsmaSecurity.columns(currencies, setModalContent), data: filteredData.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "esma-security" }));
            }
            case Source.EUROCLEAR: {
                return (React.createElement(CustomTable, { columns: EuroclearSecurity.columns(currencies, setModalContent), data: filteredData.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, meta: { className: 'co-centered' }, name: "euroclear-security" }));
            }
            case Source.SEC: {
                return (React.createElement(CustomTable, { columns: SecSecurity.columns(setModalContent), data: filteredData.map((d) => d), emptyMessage: "You have no results yet. Run a search first.", errorMessage: "Error getting results", hasError: hasError, isLoading: isLoading, name: "sec-security" }));
            }
            default: throw new Error(`Unknown source: ${source}`);
        }
    }, [currencies, filteredData, hasError, isLoading, source]);
    return (React.createElement(React.Fragment, null,
        modalContent,
        table));
};
export default SecuritiesTable;
