import * as React from 'react';
import { Button, Dropdown, Icon, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EsmaForm.scss';
import { sendFetchError } from 'utils/messageUtils';
import FilterItem from '../../../models/FilterItem';
import EsmaFilter from '../../..//models/filter/EsmaFilter';
import NameValuePair from '../../name-value-pair/NameValuePair';
const EsmaForm = ({ search, setSearch }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [selector, setSelector] = React.useState();
    const [nameValueFields, setNameValueFields] = React.useState([]);
    const [nameValueOptions, setNameValueOptions] = React.useState([]);
    const selectorDisabled = React.useMemo(() => selector === undefined, [selector]);
    const changeSelect = React.useCallback((event) => {
        const { value } = event.target;
        setSelector(value.value);
    }, []);
    const clickAdd = React.useCallback(() => {
        let updatedSearch = null;
        if (selector === 'tags') {
            const newFilter = new EsmaFilter();
            newFilter.tags = new FilterItem();
            updatedSearch = search.updateFilter(newFilter);
        }
        else if (selector !== undefined) {
            const query = search.query.update(selector, []);
            updatedSearch = search.updateQuery(query);
        }
        if (updatedSearch !== null) {
            setSearch(updatedSearch);
            setSelector(undefined);
        }
    }, [search, selector, setSearch]);
    const remove = React.useCallback((textCriteria) => () => {
        const updatedQuery = search.query.remove(textCriteria);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const removeFilter = React.useCallback(() => {
        const updatedFilter = search.filter.removeTags();
        const updatedSearch = search.updateFilter(updatedFilter);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const setValues = React.useCallback((textCriteria) => (value) => {
        const updatedQuery = search.query.update(textCriteria.name, value);
        const updatedSearch = search.updateQuery(updatedQuery);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const setValuesFilter = React.useCallback((value) => {
        const updatedFilter = search.filter.updateTags(value);
        const updatedSearch = search.updateFilter(updatedFilter);
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const optionNotDisabled = React.useCallback((option) => {
        const chosenOptions = search.query.textCriteria.map((t) => t.name);
        return !chosenOptions.includes(option.value);
    }, [search.query.textCriteria]);
    console.log("Search: %o", search);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const request = new ServerRequest('/nit/search/esma/name-fields');
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setNameValueFields(body);
                const options = body.map((o) => ({ label: o.description, value: o.name }));
                const leiOption = options.find((o) => o.value === 'lei');
                if (leiOption !== undefined) {
                    leiOption.label = 'Issuer Name';
                }
                const cfiOption = options.find((o) => o.value === 'gnr_cfi_code');
                if (cfiOption !== undefined) {
                    cfiOption.label = 'Category';
                }
                const currencyOption = options.find((o) => o.value === 'bnd_nmnl_value_curr_code');
                if (currencyOption !== undefined) {
                    currencyOption.label = 'Currency';
                }
                options.push({ label: 'Tags', value: 'tags' });
                setNameValueOptions(options);
            }
            catch (error) {
                sendFetchError('Unable to download ESMA NameFields', error, request);
                //
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const nameValueList = React.useMemo(() => {
        const elements = search.query.textCriteria.map((n) => {
            const field = nameValueFields.find((f) => f.name === n.name);
            const type = field ? field.type : '';
            return (React.createElement(NameValuePair, { description: field ? field.description : '', key: n.name, name: n.name, remove: remove(n), setValues: setValues(n), type: type, values: n.values }));
        });
        Object.entries(search.filter).filter(([, value]) => value !== undefined).forEach(([key, value]) => {
            elements.push(React.createElement(NameValuePair, { description: "", key: key, name: key, remove: removeFilter, setValues: setValuesFilter, type: "FILTER", values: value }));
        });
        return elements;
    }, [remove, removeFilter, search, setValues, setValuesFilter, nameValueFields]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "base-form" }, nameValueList),
        React.createElement("div", { className: "co-dropdown-row co-item" },
            React.createElement(Dropdown, { className: "co-dropdown", label: "Add a field for the search", onChange: changeSelect, options: nameValueOptions.filter(optionNotDisabled), value: nameValueOptions.filter(optionNotDisabled).find((o) => 'value' in o && o.value === selector) }),
            React.createElement(Button, { className: "co-button", disabled: selectorDisabled, iconButton: true, onClick: clickAdd },
                React.createElement(Icon, { iconName: "plus" })))));
};
export default EsmaForm;
