/* eslint-disable react/function-component-definition */
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TimestampFilter = function TimestampFilterElement({ column, close }) {
    const [value, setValue] = React.useState(column.getFilterValue() !== undefined
        ? column.getFilterValue() : 'A');
    const onChange = React.useCallback((event) => {
        const newValue = event.target.value.value;
        column.setFilterValue(newValue !== 'A' ? newValue : undefined);
        setValue(newValue);
        close();
    }, [close, column]);
    const options = React.useMemo(() => [
        { label: 'Today', value: 'T' },
        { label: 'Last 24 hrs', value: 'D' },
        { label: 'This week', value: 'TW' },
        { label: 'Last 7 days', value: 'W' },
        { label: 'All', value: 'A' },
    ], []);
    return (React.createElement("div", { className: "co-check-panel" },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("div", { style: { width: '150px' } },
                React.createElement(Dropdown, { displayArrowIndicator: false, isTypeAheadEnabled: false, menuIsOpen: true, onChange: onChange, options: options, value: options.find((o) => 'value' in o && o.value === value) })))));
};
export default TimestampFilter;
