import { BannerType, Button, Card, Dropdown, Icon, TextArea, TextField, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import SubscriptionsTable from './SubscriptionsTable';
import EmailGridAutoModal from './EmailGridAutoModal';
import EmailGridManualModal from './EmailGridManualModal';
const DailyEmailCreator = () => {
    const { apiFetchJson, apiFetchText } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const fileInputRef = React.useRef(null);
    const [content, setContent] = React.useState();
    const [contentNew, setContentNew] = React.useState();
    const [emailContent, setEmailContent] = React.useState(() => {
        const dailyDetails = localStorage.getItem('daily-details');
        if (dailyDetails !== null) {
            return JSON.parse(dailyDetails).default.content;
        }
        const legacyContent = localStorage.getItem('default-content');
        return legacyContent ?? '';
    });
    const [from, setFrom] = React.useState();
    const [fromOptions, setFromOptions] = React.useState([]);
    const [isAutoDisabled, setAutoDisabled] = React.useState(true);
    const [isAutoLoading, setAutoLoading] = React.useState(false);
    const [isManualDisabled, setManualDisabled] = React.useState(true);
    const [isManualLoading, setManualLoading] = React.useState(false);
    const [subject, setSubject] = React.useState(() => {
        const dailyDetails = localStorage.getItem('daily-details');
        if (dailyDetails !== null) {
            return JSON.parse(dailyDetails).default.subject;
        }
        const legacyDetails = localStorage.getItem('default-subject');
        return legacyDetails ?? 'Capital Markets Online Daily New Issue Summary';
    });
    const [settingsEmailBodyId, setSettingsEmailBodyId] = React.useState(() => {
        const dailyDetails = localStorage.getItem('daily-details');
        if (dailyDetails !== null) {
            return JSON.parse(dailyDetails).settingsIds.emailBody;
        }
        const legacyTableState = localStorage.getItem('auto-table-state');
        return legacyTableState ?? 'current';
    });
    const [settingsExcelId, setSettingsExcelId] = React.useState(() => {
        const dailyDetails = localStorage.getItem('daily-details');
        if (dailyDetails !== null) {
            return JSON.parse(dailyDetails).settingsIds.excel;
        }
        const legacyTableState = localStorage.getItem('auto-table-state');
        return legacyTableState ?? 'current';
    });
    const [tableSettingsOptions, setTableSettingsOptions] = React.useState([]);
    const [showAutoModal, setShowAutoModal] = React.useState(false);
    const [showTestModal, setShowTestModal] = React.useState(false);
    const getDailyDetails = React.useCallback(() => {
        const dailyDetails = localStorage.getItem('daily-details');
        if (dailyDetails !== null) {
            return JSON.parse(dailyDetails);
        }
        const legacyContent = localStorage.getItem('default-content');
        const legacySubject = localStorage.getItem('default-subject');
        const legacyTableState = localStorage.getItem('auto-table-state');
        const newDetails = {
            default: {
                content: legacyContent ?? '',
                subject: legacySubject ?? 'Capital Markets Online Daily New Issue Summary',
            },
            settingsIds: {
                emailBody: legacyTableState ?? 'current',
                excel: legacyTableState ?? 'current',
            },
        };
        localStorage.setItem('daily-details', JSON.stringify(newDetails));
        localStorage.removeItem('default-content');
        localStorage.removeItem('default-subject');
        localStorage.removeItem('auto-table-state');
        return newDetails;
    }, []);
    const createFormData = React.useCallback(() => {
        const form = new FormData();
        form.append('subject', subject);
        form.append('content', emailContent);
        form.append('from', new Blob([JSON.stringify(from)], { type: 'application/json' }));
        return form;
    }, [emailContent, from, subject]);
    const onChangeEmailContent = React.useCallback((event) => {
        setEmailContent(event.target.value);
    }, []);
    const onChangeFiles = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    sendMessage(`Please upload an .xlsx spread sheet rather than a ${file.type}`, BannerType.ERROR);
                    fileInputRef.current.value = '';
                }
            }
        }
        setManualDisabled(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0 || subject.length === 0 || from === undefined || emailContent.length === 0);
    }, [emailContent.length, from, setManualDisabled, subject.length]);
    const onChangeFrom = React.useCallback((event) => {
        const { value } = event.target;
        setFrom(value.value);
    }, []);
    const onChangeSubject = React.useCallback((event) => {
        setSubject(event.target.value);
    }, []);
    const onChangeTableState = React.useCallback((field) => (event) => {
        const { value } = event.target.value;
        switch (field) {
            case 'emailBody':
                setSettingsEmailBodyId(value);
                break;
            case 'excel':
                setSettingsExcelId(value);
                break;
            default:
        }
        const existingDetails = getDailyDetails();
        existingDetails.settingsIds[field] = value;
        localStorage.setItem('daily-details', JSON.stringify(existingDetails));
    }, [getDailyDetails]);
    const saveDefaultSubject = React.useCallback(() => {
        const existingDetails = getDailyDetails();
        existingDetails.default.subject = subject;
        localStorage.setItem('daily-details', JSON.stringify(existingDetails));
    }, [getDailyDetails, subject]);
    const saveDefaultEmailContent = React.useCallback(() => {
        const existingDetails = getDailyDetails();
        existingDetails.default.content = emailContent;
        localStorage.setItem('daily-details', JSON.stringify(existingDetails));
    }, [emailContent, getDailyDetails]);
    const onClickManual = React.useCallback((live) => () => {
        const formData = createFormData();
        if (fileInputRef.current?.files != null) {
            formData.append('file', fileInputRef.current.files[0]);
        }
        setManualLoading(true);
        const request = new ServerRequest(`/syndicate/subscriptions/manual/${live ? 'send' : 'view'}`, { body: formData, method: 'POST' });
        (async () => {
            try {
                const text = await apiFetchText(request);
                setContent(text);
                setShowTestModal(true);
            }
            catch (error) {
                sendFetchError(`Unable to ${live ? 'send' : 'test'} manual daily email`, error, request);
            }
            finally {
                setManualLoading(false);
            }
        })();
    }, [createFormData, apiFetchText]);
    const autoFormData = React.useMemo(() => {
        const stored = localStorage.getItem('overview-table-settings');
        const formData = createFormData();
        if (settingsEmailBodyId === 'current' && stored !== null) {
            const settings = JSON.parse(stored);
            formData.append('emailBodyState', new Blob([JSON.stringify(settings.state)], { type: 'application/json' }));
        }
        else if (settingsEmailBodyId !== 'current' && settingsEmailBodyId !== null) {
            formData.append('emailBodySettingsId', settingsEmailBodyId);
        }
        if (settingsExcelId === 'current' && stored !== null) {
            const settings = JSON.parse(stored);
            formData.append('excelState', new Blob([JSON.stringify(settings.state)], { type: 'application/json' }));
        }
        else if (settingsExcelId !== 'current' && settingsExcelId !== null) {
            formData.append('excelSettingsId', settingsExcelId);
        }
        return formData;
    }, [createFormData, settingsEmailBodyId, settingsExcelId]);
    const onClickAuto = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/auto/view', { body: autoFormData, method: 'POST' });
        setAutoLoading(true);
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const response = body;
                setContentNew(response.emailBody);
                sendMessage(`Test message sent to ${Intl.NumberFormat().format(response.users.length)} users`, BannerType.SUCCESS);
                setShowAutoModal(true);
            }
            catch (error) {
                sendFetchError('Error testing automated emails', error, request);
            }
            finally {
                setAutoLoading(false);
            }
        })();
    }, [autoFormData, apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/syndicate/subscriptions/sender-options');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setFromOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download senders', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const url = new ApiServerURL('/syndicate/table/options');
        url.searchParams.append('name', 'overview');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const newOptions = [{ label: '-- current --', value: 'current' }];
                setTableSettingsOptions(newOptions.concat(body));
            }
            catch (error) {
                sendFetchError('Unable to download table options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        setManualDisabled(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0 || subject.length === 0 || from === undefined || emailContent.length === 0);
        setAutoDisabled(subject.length === 0 || from === undefined || emailContent.length === 0);
    }, [emailContent.length, from, subject.length]);
    React.useEffect(() => {
        if (typeof fromOptions === 'object' && fromOptions.length > 0 && 'value' in fromOptions[0]) {
            setFrom(fromOptions[0].value);
        }
    }, [fromOptions]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Send Daily Email to subscribers"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "finsight", onClick: () => updateView('FINSIGHT'), size: "small" }, "FINSIGHT"),
                React.createElement(Button, { name: "interprice", onClick: () => updateView('INTERPRICE'), size: "small" }, "INTERPRICE"),
                React.createElement(Button, { name: "tracker", onClick: () => updateView('TRACKER'), size: "small", style: { marginLeft: '0.25rem' } }, "TRACKER ANALYSIS"))),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            React.createElement("div", { style: { width: '12rem' } },
                React.createElement(Dropdown, { label: "From", onChange: onChangeFrom, options: fromOptions, value: fromOptions.find((o) => 'value' in o && o.value === from) })),
            React.createElement("div", { style: { alignContent: 'end' } },
                React.createElement("input", { onChange: onChangeFiles, ref: fileInputRef, type: "file" }))),
        React.createElement(TextField, { label: "subject", onChange: onChangeSubject, value: subject, rightDecorators: (React.createElement(Button, { iconButton: true, onClick: saveDefaultSubject, size: "small", style: { marginTop: '0.25rem' }, title: "Save as default" },
                React.createElement(Icon, { iconName: "pin-on" }))) }),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'right' } },
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(TextArea, { label: "content", onChange: onChangeEmailContent, size: "medium", value: emailContent })),
            React.createElement("div", { style: { margin: '4rem 1rem 0rem 1rem' } },
                React.createElement(Button, { iconButton: true, onClick: saveDefaultEmailContent, title: "Save as default" },
                    React.createElement(Icon, { iconName: "pin-on" })))),
        React.createElement("p", null,
            "Note: use",
            ' ',
            React.createElement("b", null, "/NAME/"),
            ' ',
            "for the first name of the subscriber, and",
            ' ',
            React.createElement("b", null, "/LINK/"),
            ' ',
            "for the hyper link to the bot"),
        React.createElement("div", { style: { alignItems: 'end', display: 'flex' } },
            React.createElement(Button, { disabled: isManualDisabled, loading: isManualLoading, onClick: onClickManual(false), variant: "primary" }, "VIEW MANUAL"),
            React.createElement(Button, { style: { marginLeft: '1rem' }, disabled: isAutoDisabled, loading: isAutoLoading, onClick: onClickAuto }, "VIEW AUTO"),
            React.createElement("div", { style: { marginLeft: '1rem', width: '48rem' } },
                React.createElement(Dropdown, { style: { marginLeft: '1rem', width: '48rem' }, label: "Email", onChange: onChangeTableState('emailBody'), options: tableSettingsOptions, value: tableSettingsOptions.find((o) => 'value' in o && o.value === settingsEmailBodyId) })),
            React.createElement("div", { style: { marginLeft: '1rem', width: '48rem' } },
                React.createElement(Dropdown, { label: "Excel", onChange: onChangeTableState('excel'), options: tableSettingsOptions, value: tableSettingsOptions.find((o) => 'value' in o && o.value === settingsExcelId) }))),
        React.createElement("hr", null),
        showAutoModal && contentNew !== undefined ? (React.createElement(EmailGridAutoModal, { emailBody: contentNew, formData: autoFormData, onClose: () => setShowAutoModal(false) })) : undefined,
        showTestModal && content !== undefined ? (React.createElement(EmailGridManualModal, { content: content, onClose: () => setShowTestModal(false), send: onClickManual(true) })) : undefined,
        React.createElement(SubscriptionsTable, null)));
};
export default DailyEmailCreator;
