import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoSuchTab from 'components/NoSuchTab';
import Results from './results/Results';
import Reports from './Reports';
import Companies from './Companies';
import Overview from './Overview';
import Searches from './Searches';
const NewIssueTrackerApp = () => {
    const { availableTabs, tab, updateAvailableTabs } = React.useContext(NavigationContext);
    const allTabs = React.useMemo(() => [
        { code: 'overview', label: 'Overview' },
        { code: 'search', label: 'Search' },
        { code: 'results', label: 'Results' },
        { code: 'reports', label: 'Reports' },
        { code: 'companies', label: 'Companies' },
    ], []);
    React.useEffect(() => {
        if (availableTabs === undefined) {
            updateAvailableTabs(allTabs);
        }
    }, [allTabs, availableTabs, updateAvailableTabs]);
    switch (tab) {
        case 'overview':
            return React.createElement(Overview, null);
        case 'search':
            return React.createElement(Searches, null);
        case 'results':
            return React.createElement(Results, null);
        case 'reports':
            return React.createElement(Reports, null);
        case 'companies':
            return React.createElement(Companies, null);
        default:
            return (React.createElement(NoSuchTab, { tab: tab }));
    }
};
export default NewIssueTrackerApp;
