import CrudService from 'services/CrudService';
import LineRegex from '../../models/regexes/LineRegex';
import SubjectRegex from '../../models/regexes/SubjectRegex';
import KeyValueRegex from '../../models/regexes/KeyValueRegex';
import ItemRegex from '../../models/regexes/ItemRegex';
class RegexCrudService {
    service;
    type;
    constructor({ download, type, }) {
        this.service = new CrudService({ download, name: 'Regex', path: `/syndicate/regex/${type}` });
        this.type = type;
    }
    async delete(regex) {
        switch (this.type) {
            case 'listitems': {
                return this.service.delete(regex);
            }
            case 'subject': {
                return this.service.delete(regex);
            }
            default:
                throw new Error(`Unknown type: ${this.type}`);
        }
    }
    async remove(originalRegex, index) {
        switch (this.type) {
            case 'keyvalues': {
                originalRegex.remove(index);
                const updated = await this.service.updatePlus(originalRegex);
                return new KeyValueRegex(updated);
            }
            case 'paragraphs': {
                originalRegex.remove(index);
                const updated = await this.service.updatePlus(originalRegex);
                return new LineRegex(updated);
            }
            default:
                throw new Error(`Unknown type: ${this.type}`);
        }
    }
    async saveAny(originalRegex) {
        const updated = await (originalRegex.id
            ? this.service.updatePlus(originalRegex)
            : this.service.createPlus(originalRegex));
        switch (this.type) {
            case 'keyvalues':
                return new KeyValueRegex(updated);
            case 'listitems':
                return new ItemRegex(updated);
            case 'paragraphs':
                return new LineRegex(updated);
            case 'subject':
                return new SubjectRegex(updated);
            default:
                throw new Error('Unknown type');
        }
    }
    async save(regex, originalRegex) {
        switch (this.type) {
            case 'keyvalues': {
                if (originalRegex === undefined) {
                    throw new Error('Keyvalues must have an originalRegex');
                }
                originalRegex.add(regex);
                return this.saveAny(originalRegex);
            }
            case 'listitems': {
                if (originalRegex !== undefined) {
                    originalRegex.add(regex);
                    return this.saveAny(originalRegex);
                }
                const newRegex = new ItemRegex({ regex });
                return this.saveAny(newRegex);
            }
            case 'paragraphs': {
                if (originalRegex === undefined) {
                    throw new Error('Paragraphs must have an originalRegex');
                }
                originalRegex.add(regex);
                return this.saveAny(originalRegex);
            }
            case 'subject': {
                const subjectRegex = new SubjectRegex({ regex });
                return this.saveAny(subjectRegex);
            }
            default:
                throw new Error('Unknown type');
        }
    }
}
export default RegexCrudService;
