import * as React from 'react';
import { Loader } from '@symphony-ui/uitoolkit-components';
const OtherSourcesRow = ({ error, isLoading, security, source, }) => {
    const maturity = React.useMemo(() => {
        const mat = security?.getMaturity();
        if (mat instanceof Date) {
            return mat.toLocaleDateString();
        }
        return mat;
    }, [security]);
    const getLine = React.useCallback(() => {
        if (isLoading) {
            return React.createElement(Loader, { className: "co-loader", type: "spinner" });
        }
        if (error !== undefined) {
            return React.createElement("span", { style: { color: 'red' } }, error.detail);
        }
        if (security === null) {
            return React.createElement("div", null, "N/A");
        }
        return undefined;
    }, [error, isLoading, security]);
    if (security != null) {
        return (React.createElement("tr", null,
            React.createElement("td", null, source),
            React.createElement("td", null, security.getIssuername()),
            React.createElement("td", null, security.getCurrency()),
            React.createElement("td", null, security.getAmount()?.toLocaleString()),
            React.createElement("td", null, maturity)));
    }
    return (React.createElement("tr", null,
        React.createElement("td", null, source),
        React.createElement("td", { colSpan: 4 }, getLine())));
};
export default OtherSourcesRow;
