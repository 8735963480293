import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
const FrontPageMinimal = () => {
    const onClickConducto = React.useCallback(() => {
        window.location.href = "https://open.symphony.com/?startChat=5005939002310666";
    }, []);
    const auth = useAuth();
    const clickLogin = React.useCallback(() => {
        (async () => {
            try {
                await auth.signinRedirect();
            }
            catch (error) {
                console.error('OOPS', error);
            }
        })();
    }, [auth]);
    return (React.createElement("div", { className: "co-page-wrapper", style: { justifyContent: 'center', alignItems: 'center' } },
        React.createElement("img", { alt: "CMO logo", src: "/assets/cmo_icon_x192.png", width: "192", height: "192" }),
        React.createElement("h1", null, "CapitalMarkets Online"),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
            React.createElement(Button, { className: "co-center-page", onClick: clickLogin, size: 'large' }, "SIGN IN"),
            React.createElement("div", { style: { marginLeft: '1rem' } },
                React.createElement(Button, { className: "co-center-page", iconRight: true, onClick: onClickConducto, size: 'large' },
                    "CONDUCTO",
                    React.createElement(Icon, { className: 'co-icon', iconName: 'symphony-logo' }))))));
};
export default FrontPageMinimal;
