import companiesTableActionCell from './companiesTableActionCell';
const markupColumns = function func(columns, setActualCompanyTags, setCompany, source) {
    console.log("MARKING UP");
    const actionColumn = {
        cell: companiesTableActionCell(setActualCompanyTags, setCompany, source),
        id: 'actions',
        size: 28
    };
    const tagsColumn = {
        accessorFn: ({ tags }) => (tags && Array.isArray(tags) ? tags.join(', ') : ''),
        header: 'Tags',
        id: 'companyTags',
    };
    return [actionColumn].concat(columns).concat([tagsColumn]);
};
export default markupColumns;
