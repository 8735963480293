import Company from "../../admin/models/Company";
import TrancheMeta from "./TrancheMeta";
class TrancheMetaWrapper extends TrancheMeta {
    constructor(response) {
        super(response);
        this.parties?.forEach(p => {
            if (p.company !== undefined) {
                p.company = new Company(p.company);
            }
            if (p.ultimateParent !== undefined) {
                p.ultimateParent = new Company(p.ultimateParent);
            }
        });
    }
}
export default TrancheMetaWrapper;
