import * as React from 'react';
import CookieConsent from './CookieConsent';
import Footer from './footer/Footer';
import RightPanel from './right-panel/RightPanel';
import Cards from './cards/Cards';
import LeftPanel from './left-panel/LeftPanel';
import BottomPanel from './bottom-panel/BottomPanel';
import './front-page.css';
const FrontPage = () => (React.createElement("div", { id: "front-page" },
    React.createElement("div", { id: "top-card" },
        React.createElement(LeftPanel, null),
        React.createElement(RightPanel, null)),
    React.createElement(Cards, null),
    React.createElement(BottomPanel, null),
    React.createElement(Footer, null),
    !window.jwt ? React.createElement(CookieConsent, null) : undefined));
export default FrontPage;
