import IpoSecurity from '../security/IpoSecurity';
class IpoFilter {
    tags;
    exclude;
    name;
    values;
    update(name) {
        return (event) => Object.assign(new IpoFilter(), this, { [name]: event });
    }
    updateTags(event) {
        const newFilter = Object.assign(new IpoFilter(), this);
        newFilter.tags.values = (event.target.value || []).map((t) => t.value);
        return newFilter;
    }
    create() {
        const test = (contains, name) => (this[name].exclude && !contains)
            || (!this[name].exclude && contains);
        return (s) => {
            if (s instanceof IpoSecurity) {
                const tagsTest = s.tags && this.tags.values.length ? test(s.tags.some((t) => this.tags.values.includes(t)), 'tags') : true;
                return tagsTest;
            }
            return false;
        };
    }
}
export default IpoFilter;
