import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const EmailLoaderModal = ({ onClose, setEmailId }) => {
    const [disabled, setDisabled] = React.useState(true);
    const [value, setValue] = React.useState();
    const onClick = React.useCallback(() => {
        if (value !== undefined) {
            setEmailId(value);
            localStorage.setItem('emailId', value);
            onClose();
        }
    }, [onClose, setEmailId, value]);
    const onChangeText = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    React.useEffect(() => {
        setDisabled(value === undefined || !value.startsWith('<') || !value.endsWith('>'));
    }, [value]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "small" },
        React.createElement(ModalHeader, null, "Load Email"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { onChange: onChangeText, value: value })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: disabled, onClick: onClick }, "OPEN"))));
};
export default EmailLoaderModal;
