var _a;
import GenericCompany from './GenericCompany';
class EsmaCompany extends GenericCompany {
    lei;
    entity;
    static columns = [
        { accessorKey: 'lei', header: 'LEI', size: 230 },
        { accessorKey: 'entity.legalName.name', header: 'Name', size: 9999 },
    ];
    static getSort = (s0, s1) => s0.entity.legalName.name.localeCompare(s1.entity.legalName.name);
    constructor(response) {
        super(response);
        this.entity = response.entity;
        this.lei = response.lei;
    }
    #clone() {
        return new _a({
            entity: this.entity,
            id: this.id,
            lei: this.lei,
            tags: this.tags
        });
    }
    updateTags(allCompanyTags) {
        const clone = this.#clone();
        const foundTag = allCompanyTags.find(t => t.companyId === this.id);
        this.tags = foundTag?.tags;
        return clone;
    }
}
_a = EsmaCompany;
export default EsmaCompany;
