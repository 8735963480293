import { SelectionStatus } from '@symphony-ui/uitoolkit-components';
class Schedule {
    id;
    name;
    status; // on or off
    timing;
    constructor(schedule) {
        this.id = schedule.id;
        this.name = schedule.name;
        this.status = schedule.status;
        this.timing = schedule.timing;
    }
    switch() {
        this.status = !this.status;
    }
    selectionStatus() {
        return this.status ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED;
    }
    updateTiming(initialDelay, period, unit) {
        this.timing.initialDelay = initialDelay;
        this.timing.period = period;
        this.timing.unit = unit;
    }
}
export default Schedule;
