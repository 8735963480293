import * as React from 'react';
import { Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import { printAddress } from 'utils/table';
const ContentModal = ({ hideModal, update }) => (React.createElement(Modal, { closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "full-width" },
    React.createElement(ModalTitle, null, update.subject),
    React.createElement(ModalBody, null,
        React.createElement("div", null, printAddress(update.from)),
        React.createElement("div", { className: "co-update-timestamp" }, update.timestamp.toLocaleString()),
        React.createElement("div", { className: "co-update-html", dangerouslySetInnerHTML: { __html: update.html } }))));
export default ContentModal;
