import * as React from 'react';
import { BannerType, Button, Modal, ModalBody, ModalFooter, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
const EmailGridAutoModal = ({ emailBody, formData, onClose, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [isLoading, setLoading] = React.useState(false);
    const onClick = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/auto/send', { body: formData, method: 'POST' });
        setLoading(true);
        (async () => {
            try {
                const body = await apiFetchJson(request);
                const response = body;
                sendMessage(`Email sent succesfully to ${response.users.length} users`, BannerType.SUCCESS);
                onClose();
            }
            catch (error) {
                sendFetchError('Unable to send subscription', error, request);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [apiFetchJson, formData, onClose]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "full-width" },
        React.createElement(ModalBody, null,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: emailBody } })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: isLoading, onClick: onClick, variant: "primary-destructive" }, "SEND"))));
};
export default EmailGridAutoModal;
