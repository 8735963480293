import CoreData from './CoreData';
class KeyValuesData extends CoreData {
    value;
    constructor(response) {
        super(response);
        this.value = response.value;
    }
    getValue() {
        return this.value;
    }
}
export default KeyValuesData;
