class AnnaCompany {
    id;
    nameLong;
    hq;
    reg;
    tags;
    static sortField = 'nameLong';
    static columns = [
        { accessorKey: 'lei', header: 'LEI' },
        { accessorKey: 'nameLong', header: 'Name' },
        { accessorFn: (c) => c.reg?.address1, header: 'Reg Address 1' },
        { accessorFn: (c) => c.reg?.address2, header: 'Reg Address 2' },
        { accessorFn: (c) => c.reg?.city, header: 'Reg City' },
        { accessorFn: (c) => c.reg?.country, header: 'Reg Country' },
        { accessorFn: (c) => c.reg?.postalCode, header: 'Reg Postal Code' },
        { accessorFn: (c) => c.reg?.state, header: 'Reg State' },
        { accessorFn: (c) => c.hq?.address1, header: 'HQ Address 1' },
        { accessorFn: (c) => c.hq?.address2, header: 'HQ Address 2' },
        { accessorFn: (c) => c.hq?.city, header: 'HQ City' },
        { accessorFn: (c) => c.hq?.country, header: 'HQ Country' },
        { accessorFn: (c) => c.hq?.postalCode, header: 'HQ Postal Code' },
        { accessorFn: (c) => c.hq?.state, header: 'HQ State' },
        { accessorKey: 'nameShort', header: 'Name (short)' },
    ];
    static getSort = (s0, s1) => s0.nameLong.localeCompare(s1.nameLong);
}
export default AnnaCompany;
