import * as React from 'react';
const ToggleDecorator = ({ toggled, setToggle }) => {
    const onClickSwitch = React.useCallback(() => {
        const newToggle = !toggled;
        document.activeElement.blur();
        setToggle(newToggle);
    }, [setToggle, toggled]);
    const onKeyUpSwitch = React.useCallback((event) => {
        if (event.key === 'Enter') {
            const newToggle = !toggled;
            document.activeElement.blur();
            setToggle(newToggle);
        }
    }, [setToggle, toggled]);
    return (React.createElement("i", { "aria-label": "Switch between Frequency and Time", className: `tk-icon-chevron-${toggled ? 'right' : 'left'} tk-input__icon`, onClick: onClickSwitch, onKeyUp: onKeyUpSwitch, role: "button", tabIndex: 0 }));
};
export default ToggleDecorator;
