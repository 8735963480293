import { BannerType, Button, Switch } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import TableContext from 'components/custom-table/TableContext';
import ContentModal from '../../../../regexes/ContentModal';
import JoinModal from './JoinModal';
import SubRow from './SubRow';
import MesssagesModal from '../../../overview/MessagesModal';
const SubComponent = ({ colSpan, onClick, transactionId, }) => {
    const { apiFetchNoContent: noContentPlus, apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const [isJoinDisabled, setJoinDisabled] = React.useState(false);
    const [isUnjoinDisabled, setUnjoinDisabled] = React.useState(false);
    const [isSplitDisabled, setSplitDisabled] = React.useState(false);
    const [isAutogroupLoading, setAutogroupLoading] = React.useState(false);
    const [isJoinLoading, setJoinLoading] = React.useState(false);
    const [isRebuildLoading, setRebuildLoading] = React.useState(false);
    const [isSplitLoading, setSplitLoading] = React.useState(false);
    const [isUnjoinLoading, setUnjoinLoading] = React.useState(false);
    const [showContentModal, setShowContentModal] = React.useState(false);
    const [showJoinModal, setShowJoinModal] = React.useState(false);
    const [showMessagesModal, setShowMessagesModal] = React.useState(false);
    const [selectedUpdate, setSelectedUpdate] = React.useState();
    const [updates, setUpdates] = React.useState([]);
    const [isRaw, setRaw] = React.useState(true);
    const amendUpdates = React.useCallback((updatedUpdates) => {
        const withCheckedFalse = updatedUpdates.map((d, index) => ({
            ...d,
            checked: false,
            index,
        }));
        const removalList = [];
        withCheckedFalse.forEach((u) => {
            if ('originalEmailId' in u) {
                const originalUpdate = withCheckedFalse.find((ou) => ou.emailId.id === u.originalEmailId.id
                    && ou.emailId.index === u.originalEmailId.index);
                if (originalUpdate !== undefined) {
                    if (originalUpdate.copies === undefined) {
                        originalUpdate.copies = [];
                    }
                    originalUpdate.copies.push(u);
                }
                else {
                    removalList.push(u);
                }
            }
        });
        return withCheckedFalse.filter((u) => !('originalEmailId' in u) || removalList.indexOf(u) > -1);
    }, []);
    const processDownloaded = React.useCallback((updatedUpdates) => {
        const amended = amendUpdates(updatedUpdates);
        setUpdates(amended);
    }, [amendUpdates]);
    const processMessage = React.useCallback((message, error) => {
        if (message != null) {
            setMessage({ content: message, variant: BannerType.SUCCESS });
        }
        else if (error != null) {
            setMessage({ content: error, variant: BannerType.ERROR });
        }
    }, [setMessage]);
    const createServerRequest = React.useCallback(async (allMessageIds, path) => {
        const options = { type: 'application/json' };
        const formData = new FormData();
        formData.append('messageIds', new Blob([JSON.stringify(allMessageIds)], options));
        formData.append('transactionId', transactionId);
        const p = path === 'split' ? '/syndicate/transactions/split' : `/syndicate/update/${path}`;
        const request = new ServerRequest(p, { body: formData, method: 'POST' });
        const body = await jsonPlus(request, 'Error downloading update');
        return body;
    }, [jsonPlus, transactionId]);
    const processUpdateAction = React.useCallback(async (path) => {
        const updateMessageIds = updates.filter((u) => u.checked);
        const updateCopyMessageIds = updates.filter((u) => 'copies' in u)
            .flatMap((u) => u.copies).filter((u) => u?.checked);
        const checkedUpdates = updateMessageIds.concat(updateCopyMessageIds.map((u) => u))
            .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
        const allMessageIds = checkedUpdates.map((u) => u.emailId);
        return createServerRequest(allMessageIds, path);
    }, [createServerRequest, updates]);
    const processTransactionAction = React.useCallback(async (path) => {
        const lastUpdate = updates
            .flatMap((u) => u.copies).concat(updates)
            .sort(((u0, u1) => new Date(u1.timestamp).getTime() - new Date(u0.timestamp).getTime()))[0];
        const updateMessageIds = updates
            .filter((u) => u.checked !== lastUpdate?.checked).map((u) => u.emailId);
        const updateCopyMessageIds = updates
            .filter((u) => 'copies' in u)
            .flatMap((u) => u.copies)
            .filter((u) => u !== undefined && u.checked !== lastUpdate?.checked)
            .map((u) => u.emailId);
        const allMessageIds = updateMessageIds.concat(updateCopyMessageIds);
        return createServerRequest(allMessageIds, path);
    }, [createServerRequest, updates]);
    const joinUpdates = React.useCallback(() => {
        setJoinLoading(true);
        (async () => {
            try {
                const body = await processUpdateAction('join');
                processDownloaded(body.updates);
                processMessage(body.message, body.error);
                setJoinLoading(false);
                setShowJoinModal(false);
            }
            catch (error) {
                //
            }
        })();
    }, [processUpdateAction, processDownloaded, processMessage]);
    const onClickAutogroup = React.useCallback(() => {
        setAutogroupLoading(true);
        const formData = new FormData();
        formData.append('transactionId', transactionId);
        (async () => {
            try {
                const request = new ServerRequest('/syndicate/transactions/auto-group', { body: formData, method: 'POST' });
                const body = await jsonPlus(request, 'unable to auto group transactions');
                processDownloaded(body.updates);
                processMessage(body.message, body.error);
            }
            catch (error) {
                //
            }
            finally {
                setAutogroupLoading(false);
            }
        })();
    }, [jsonPlus, processDownloaded, processMessage, transactionId]);
    const onClickJoin = React.useCallback(() => {
        setShowJoinModal(true);
    }, []);
    const onClickRebuild = React.useCallback(() => {
        setRebuildLoading(true);
        const formData = new FormData();
        formData.append('transactionId', transactionId);
        (async () => {
            try {
                const request = new ServerRequest('/api/syndicate/rebuild', { body: formData, method: 'POST' });
                await noContentPlus(request, 'unable to rebuild transaction messages');
                setShowMessagesModal(true);
            }
            catch (error) {
                //
            }
            finally {
                setRebuildLoading(false);
            }
        })();
    }, [noContentPlus, transactionId]);
    const onClickSplit = React.useCallback(() => {
        if (tableUpdateFn !== undefined) {
            setSplitLoading(true);
            (async () => {
                try {
                    const body = await processTransactionAction('split');
                    const amended = amendUpdates(body.updates);
                    setUpdates(amended);
                    processMessage(body.message, body.error);
                    const updateFn = (data) => {
                        const updated = {
                            pageParams: data.pageParams,
                            pages: data.pages.map((p) => [...p]),
                        };
                        const { lastUpdate } = body.newTransaction;
                        let indexNewer = 0;
                        const flatData = data.pages.flatMap((p) => p);
                        while (indexNewer + 1 < flatData.length && flatData[indexNewer + 1].lastUpdate > lastUpdate) {
                            indexNewer += 1;
                        }
                        let pageNumber = -1;
                        let index = -1;
                        while (index === -1) {
                            pageNumber += 1;
                            index = data.pages[pageNumber].findIndex((d) => d.id === flatData[indexNewer].id);
                        }
                        if (index > -1) {
                            updated.pages[pageNumber].splice(index, 0, body.newTransaction);
                        }
                        return updated;
                    };
                    tableUpdateFn(updateFn);
                    setSplitLoading(false);
                }
                catch (error) {
                    // Ignore
                }
                finally {
                    setSplitLoading(false);
                }
            })();
        }
    }, [amendUpdates, tableUpdateFn, processTransactionAction, processMessage]);
    const onClickUnjoin = React.useCallback(() => {
        setUnjoinLoading(true);
        (async () => {
            try {
                const body = await processUpdateAction('unjoin');
                processDownloaded(body.updates);
                processMessage(body.message, body.error);
            }
            catch (error) {
                //
            }
            finally {
                setUnjoinLoading(false);
            }
        })();
    }, [processUpdateAction, processDownloaded, processMessage]);
    const onChangeRaw = React.useCallback(() => {
        setRaw(!isRaw);
    }, [isRaw]);
    React.useEffect(() => {
        (async () => {
            const url = new ApiServerURL('/syndicate/transactions/update-meta');
            url.searchParams.append('transactionId', transactionId);
            const request = new ServerRequest(url, { method: 'GET' });
            try {
                const body = await jsonPlus(request, 'Error downloading transactions');
                processDownloaded(body);
            }
            catch (error) {
                //
            }
        })();
    }, [jsonPlus, processDownloaded, transactionId]);
    React.useEffect(() => {
        const numberOriginalChecked = updates.filter((u) => u.checked).length;
        const numberCopyChecked = updates.flatMap((u) => u.copies ?? []).filter((u) => u.checked).length;
        setJoinDisabled(numberOriginalChecked + numberCopyChecked < 2);
        setUnjoinDisabled(numberCopyChecked === 0);
        setSplitDisabled(numberCopyChecked + numberOriginalChecked === 0);
    }, [updates]);
    if (updates.length === 0) {
        return null;
    }
    return (React.createElement("tr", { style: {
            transition: 'height 1s linear',
        } },
        React.createElement("td", { colSpan: colSpan },
            typeof selectedUpdate !== 'undefined'
                ? (React.createElement(ContentModal, { update: selectedUpdate, hideModal: () => setShowContentModal(false), show: showContentModal })) : null,
            React.createElement(JoinModal, { hideModal: () => setShowJoinModal(false), isLoading: isJoinLoading, join: joinUpdates, show: showJoinModal, updates: updates }),
            showMessagesModal ? (React.createElement(MesssagesModal, { hideModal: () => setShowMessagesModal(false), show: showMessagesModal, splitScreen: false, transactionId: transactionId })) : undefined,
            React.createElement("table", { style: {
                    fontSize: 'small', marginLeft: '3rem', tableLayout: 'fixed', width: 'calc(100% - 3rem)',
                } },
                React.createElement("tbody", null, updates.map((u) => (React.createElement(React.Fragment, { key: u.index },
                    React.createElement(SubRow, { onClick: onClick(u.index), raw: isRaw, setSelectedUpdate: setSelectedUpdate, setShowModal: setShowContentModal, setUpdates: setUpdates, update: u, updates: updates }),
                    u.copies !== undefined ? u.copies?.map((c) => (React.createElement(SubRow, { className: "co-sub-row", key: c.index, onClick: onClick(u.index), raw: isRaw, setSelectedUpdate: setSelectedUpdate, setShowModal: setShowContentModal, setUpdates: setUpdates, update: c, updates: updates }))) : undefined))))),
            React.createElement("div", { style: { display: 'flex', marginLeft: '2rem', marginTop: '0.5rem' } },
                React.createElement(Button, { disabled: isJoinDisabled, loading: isJoinLoading, variant: "secondary", onClick: onClickJoin, style: { marginRight: '0.5rem' } }, "JOIN"),
                React.createElement(Button, { disabled: isUnjoinDisabled, loading: isUnjoinLoading, variant: "secondary", onClick: onClickUnjoin, style: { marginRight: '0.5rem' } }, "UNJOIN"),
                React.createElement(Button, { disabled: isSplitDisabled, loading: isSplitLoading, variant: "secondary", onClick: onClickSplit, style: { marginRight: '0.5rem' } }, "Split into New Transaction"),
                React.createElement(Button, { loading: isAutogroupLoading, variant: "secondary", onClick: onClickAutogroup, style: { marginRight: '0.5rem' } }, "Auto Group"),
                React.createElement(Button, { loading: isRebuildLoading, variant: "secondary", onClick: onClickRebuild, style: { marginRight: '0.5rem' } }, "Rebuild Messages"),
                React.createElement(Switch, { label: isRaw ? 'Raw' : 'Clean', name: "raw", onChange: onChangeRaw, status: isRaw ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: isRaw ? 'checked' : 'unchecked' })))));
};
export default SubComponent;
