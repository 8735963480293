import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import ReleaseTable from './ReleaseTable';
const Releases = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [releases, setReleases] = React.useState();
    const [source, setSource] = React.useState();
    const [sourceOptions, setSourceOptions] = React.useState();
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const request = new ServerRequest('/bb/release/options');
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setSourceOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to get release options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    React.useEffect(() => {
        const abortController = new AbortController();
        if (source !== undefined) {
            (async () => {
                const url = new ApiServerURL('/bb/release');
                url.searchParams.append('source', source);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setReleases(body);
                }
                catch (error) {
                    sendFetchError('Unable to download releases', error, request);
                    setError(true);
                }
                setLoading(false);
            })();
        }
        return () => abortController.abort();
    }, [apiFetchJson, source]);
    return (React.createElement(ReleaseTable, { hasError: hasError, isLoading: isLoading, releases: releases ?? [], setSource: setSource, source: source, sourceOptions: sourceOptions ?? [] }));
};
export default Releases;
