import TrancheMetaWrapper from './TrancheMetaWrapper';
import TransactionWrapper from './TransactionWrapper';
class TrancheWrapper {
    data;
    errors;
    isEditing;
    meta;
    override;
    overrideMap;
    trancheIndex;
    transaction;
    constructor({ data, errors, meta, override, overrideMap, trancheIndex, transaction, transactionId, }) {
        this.data = data;
        this.errors = errors;
        this.isEditing = false;
        this.meta = new TrancheMetaWrapper(meta);
        this.override = override;
        this.overrideMap = overrideMap;
        this.trancheIndex = trancheIndex;
        this.transaction = new TransactionWrapper(transaction);
        this.transaction.id = transactionId;
    }
    setEditable(flag) {
        const newTranche = new TrancheWrapper(this.toResponse());
        newTranche.isEditing = flag;
        return newTranche;
    }
    removeOverrideMap() {
        const newTranche = new TrancheWrapper(this.toResponse());
        delete newTranche.overrideMap;
        newTranche.isEditing = this.isEditing;
        return newTranche;
    }
    toResponse() {
        return {
            data: this.data,
            errors: this.errors,
            meta: this.meta?.toResponse(),
            override: this.override,
            overrideMap: this.overrideMap,
            trancheIndex: this.trancheIndex,
            transaction: this.transaction.toResponse(),
            transactionId: this.transaction.id,
        };
    }
}
export default TrancheWrapper;
