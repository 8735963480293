import TickIcon from 'components/tick-icon/TickIcon';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
const AuthenticationStatus = () => {
    const auth = useAuth();
    return (React.createElement("table", null,
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", null, "Authenticated:"),
                React.createElement("td", null,
                    React.createElement(TickIcon, { value: auth.isAuthenticated }))),
            React.createElement("tr", null,
                React.createElement("td", null, "Loading:"),
                React.createElement("td", null,
                    React.createElement(TickIcon, { value: auth.isLoading }))),
            React.createElement("tr", null,
                React.createElement("td", null, "Active Navigator:"),
                React.createElement("td", null, auth.activeNavigator)),
            React.createElement("tr", null,
                React.createElement("td", null, "Error Message:"),
                React.createElement("td", null, auth.error?.message)),
            React.createElement("tr", null,
                React.createElement("td", null, "Error Name:"),
                React.createElement("td", null, auth.error?.name)),
            React.createElement("tr", null,
                React.createElement("td", null, "Error Stack:"),
                React.createElement("td", null, auth.error?.stack)))));
};
export default AuthenticationStatus;
