import ServerRequest from 'models/ServerRequest';
const base64UrlToUint8Array = (base64UrlData) => {
    const padding = '='.repeat((4 - (base64UrlData.length % 4)) % 4);
    const base64 = (base64UrlData + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i += 1) {
        buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
};
const sendSubscriptionToServer = async (subscription, accessToken) => {
    const key = subscription.getKey ? subscription.getKey('p256dh') : null;
    const auth = subscription.getKey ? subscription.getKey('auth') : null;
    if (key !== null && auth !== null) {
        try {
            // Normally, you would actually send the subscription to the server:
            const auth8Array = new Uint8Array(auth);
            const authText = String.fromCharCode.apply(null, Array.from(auth8Array));
            const key8Array = new Uint8Array(key);
            const keyText = String.fromCharCode.apply(null, Array.from(key8Array));
            const init = {
                body: JSON.stringify({
                    auth: window.btoa(authText),
                    endpoint: subscription.endpoint,
                    key: window.btoa(keyText),
                }),
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            };
            const request = new ServerRequest('/notification/subscribe', init);
            await fetch(request);
        }
        catch (error) {
            // We should try again later
            console.error('Cannot subscribe to notification as unable to get CSRF Token', error);
        }
    }
};
// vapid:
//   public:
//     key: BMWpV8QshQ4t_ML7AAFcNZnPAcloDXWW4qJfLaUKH-sx6IzXFykEIav72AVz9I4p43BDEnyKydO_NxYsOTET8m0
//   private:
//     key: 90n2xXUizsce8apNGVW1VnOSXUMJyXxY02EZ2kIEz04
const subscribe = async (accessToken) => {
    const publicKey = base64UrlToUint8Array('BH5GeS568g1dutC_VWgCDdJD2wqs95Rdz7Ux6N4j7-0mSxRdrI7X3swCI5nlP_WX25KT04aor2CTfZ5KRnUBz-I');
    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const options = {
            applicationServerKey: publicKey,
            userVisibleOnly: true,
        };
        const subscription = await serviceWorkerRegistration.pushManager.subscribe(options);
        await sendSubscriptionToServer(subscription, accessToken);
    }
    catch (e) {
        if (Notification.permission === 'denied') {
            console.warn('Permission for Notifications was denied');
        }
        else {
            console.error('Unable to subscribe to push.', e);
        }
    }
};
const initialisePushNotifications = async (accessToken) => {
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        console.warn('Notifications aren\'t supported.');
        return;
    }
    if (Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        return;
    }
    if (!('PushManager' in window)) {
        console.warn('Push messaging isn\'t supported.');
        return;
    }
    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (subscription === null) {
            await subscribe(accessToken);
            return;
        }
        // Keep your server in sync with the latest subscriptionId
        await sendSubscriptionToServer(subscription, accessToken);
    }
    catch (err) {
        console.warn('Error during getSubscription()', err);
    }
};
export default initialisePushNotifications;
