import { Loader } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
const LEIRecord = ({ lei }) => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [entity, setEntity] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    React.useEffect(() => {
        const url = new ApiServerURL(`/gleif/lei-records/${lei}`);
        const request = new ServerRequest(url);
        (async () => {
            try {
                setLoading(true);
                const body = await jsonPlus(request, 'Unable to download ticker information');
                setEntity(body.entity);
            }
            catch (error) {
                //
            }
            finally {
                setLoading(false);
            }
        })();
    }, [jsonPlus, lei]);
    if (entity === undefined) {
        return undefined;
    }
    return !isLoading ? (React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', padding: '0.25rem', fontSize: 'small',
        } },
        React.createElement("div", null, entity.legalName.name),
        React.createElement("div", null,
            entity.headquartersAddress.city,
            ",",
            ' ',
            entity.headquartersAddress.country),
        React.createElement("div", null,
            entity.legalAddress.city,
            ",",
            ' ',
            entity.legalAddress.country),
        React.createElement("div", null, entity.category),
        React.createElement("div", null, entity.status))) : React.createElement(Loader, null);
};
export default LEIRecord;
