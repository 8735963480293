class Country {
    id;
    name;
    region;
    alternativeNames;
    constructor(countryResponse) {
        this.id = countryResponse.id;
        this.name = countryResponse.name;
        this.region = countryResponse.region;
        this.alternativeNames = countryResponse.alternativeNames;
    }
    static create() {
        return new Country({
            id: "",
            name: "",
            alternativeNames: []
        });
    }
    clone() {
        return new Country({
            alternativeNames: this.alternativeNames,
            id: this.id,
            name: this.name,
            region: this.region
        });
    }
    setName(name) {
        const clone = this.clone();
        clone.name = name;
        return clone;
    }
    setRegion(region) {
        const clone = this.clone();
        clone.region = region;
        return clone;
    }
}
export default Country;
