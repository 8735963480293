var _a;
import GenericCompany from './GenericCompany';
class EuroclearCompany extends GenericCompany {
    category;
    cityname;
    countryname;
    name;
    static getSort = (s0, s1) => s0.name.localeCompare(s1.name);
    static columns = [
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'cityname', header: 'City' },
        { accessorKey: 'countryname', header: 'Country' },
        { accessorKey: 'category', header: 'Category' },
    ];
    constructor(response) {
        super(response);
        this.category = response.category;
        this.cityname = response.cityname;
        this.countryname = response.countryname;
        this.name = response.name;
    }
    #clone() {
        return new _a({
            category: this.category,
            cityname: this.cityname,
            countryname: this.countryname,
            id: this.id,
            name: this.name,
            tags: this.tags
        });
    }
    updateTags(allCompanyTags) {
        const clone = this.#clone();
        clone.tags = allCompanyTags.find(t => t.companyId === this.id)?.tags;
        return clone;
    }
}
_a = EuroclearCompany;
export default EuroclearCompany;
