import cfiData from './ cfiData';
const cfiOptions = cfiData.categories.map((category) => {
    const options = category.groups.map((g) => ({
        label: `${category.name} - ${g.name}`,
        value: category.code + g.code,
    }));
    options.unshift({
        label: `${category.name} - Any`,
        value: category.code,
    });
    const newValue = {
        label: category.name,
        options,
        value: `${category.code}?`,
    };
    return newValue;
});
export default cfiOptions;
