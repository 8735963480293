import * as React from 'react';
import { Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import './ReleaseTable.css';
import ReleaseSourceTable from './ReleaseSourceTable';
const ReleaseTable = ({ hasError, isLoading, releases, source, sourceOptions, setSource, }) => {
    const [data, setData] = React.useState();
    const onChangeDropdown = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = value.value;
        setData(newValue);
    }, []);
    React.useEffect(() => {
        setSource(data);
    }, [data, setSource]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Releases"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Dropdown, { isDisabled: hasError, id: "source-dropdown", name: "source", label: "Download Releases from Source", onChange: onChangeDropdown, options: sourceOptions, value: sourceOptions.find((o) => 'value' in o && o.value === source) }))),
        source !== undefined
            ? (React.createElement(ReleaseSourceTable, { releases: releases, releaseSource: source, emptyMessage: "You have no releases yet", errorMessage: "Error downloading release", hasError: hasError, isLoading: isLoading })) : undefined));
};
export default ReleaseTable;
