import { Button, Dropdown, Icon } from '@symphony-ui/uitoolkit-components';
import useOption from 'components/customHooks';
import CompanyFormModal from '../../../../../admin/pages/companies/CompanyFormModal';
import * as React from 'react';
const CompanySelector = ({ company, disabled, label, onChange, selectedCompany, setSelectedCompany, style }) => {
    const [showModal, setShowModal] = React.useState(false);
    const companyOptions = useOption('/admin/companies/ISSUER/options');
    const onChangeCompany = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        onChange(newValue);
    }, [onChange]);
    const onClick = React.useCallback(() => {
        setShowModal(true);
    }, []);
    const onClose = React.useCallback(() => {
        if (setSelectedCompany !== undefined) {
            setSelectedCompany(undefined);
        }
        setShowModal(false);
    }, [setSelectedCompany]);
    const callback = React.useCallback((company) => {
        const { id } = company;
        if (id !== undefined) {
            if (setSelectedCompany !== undefined) {
                setSelectedCompany(undefined);
            }
            onChange(id);
        }
    }, [onChange, setSelectedCompany]);
    const onKeyUp = React.useCallback((event) => event.preventDefault(), []);
    const combinedStyle = React.useMemo(() => {
        const baseStyle = { display: 'flex', justifyContent: 'space-between' };
        return style !== undefined ? Object.assign(style, baseStyle) : baseStyle;
    }, [style]);
    return (React.createElement("div", { style: combinedStyle },
        showModal || selectedCompany !== undefined ?
            React.createElement(CompanyFormModal, { company: company, onClose: onClose, callback: callback, selectedCompany: selectedCompany })
            : undefined,
        React.createElement("div", { style: { width: '100%' } },
            React.createElement(Dropdown, { autoFocus: true, disabled: disabled, label: label, onChange: onChangeCompany, onKeyUp: onKeyUp, options: companyOptions, style: { width: '100%' }, value: companyOptions.find(o => 'value' in o && company?.id === o.value) })),
        React.createElement("div", { style: { alignSelf: 'end', marginLeft: '0.5rem' } },
            React.createElement(Button, { disabled: disabled, iconButton: true, onClick: onClick, size: 'small' },
                React.createElement(Icon, { iconName: 'search' })))));
};
export default CompanySelector;
