import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import NavigationContext from 'contexts/NavigationContext';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import AuthenticationStatus from './AuthenticationStatus';
import AuthenticationUser from './AuthenticationUser';
import AuthenticationModal from './AuthenticationModal';
import AuthenticationActions from './AuthenticationActions';
const AuthenticationMonitor = () => {
    const { updateView } = React.useContext(NavigationContext);
    const auth = useAuth();
    const [show, setShow] = React.useState(false);
    const [text, setText] = React.useState();
    React.useEffect(() => {
        auth.events.addAccessTokenExpired((ev) => {
            setText(`Access Token Expired: ${JSON.stringify(ev)}`);
            setShow(true);
        });
        auth.events.addAccessTokenExpiring((ev) => {
            setText(`Access Token Expiring ${JSON.stringify(ev)}`);
            setShow(true);
        });
        auth.events.addSilentRenewError((ev) => {
            setText(`Silent Renew Error: ${JSON.stringify(ev)}`);
            setShow(true);
        });
        auth.events.addUserLoaded((ev) => {
            setText(`User Loaded: ${JSON.stringify(ev)}`);
            setShow(true);
        });
        auth.events.addUserSessionChanged(() => {
            setText('Session Changed');
            setShow(true);
        });
        auth.events.addUserSignedIn(() => {
            setText('User Signed In');
            setShow(true);
        });
        auth.events.addUserSignedOut(() => {
            setText('User Signed Out');
            setShow(true);
        });
        auth.events.addUserUnloaded(() => {
            setText('User Unloaded');
            setShow(true);
        });
    }, [auth.events]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Authentication"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "web-push", onClick: () => updateView('web-push'), size: "small" }, "WEB PUSH"),
                React.createElement(Button, { name: "web-sockets", onClick: () => updateView('web-sockets'), size: "small", style: { marginLeft: '0.25rem' } }, "WEB SOCKETS"))),
        React.createElement(AuthenticationModal, { show: show, onClose: () => setShow(false), text: text }),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { overflow: 'hidden', padding: '0.5rem', width: '50%' } },
                React.createElement(AuthenticationUser, null)),
            React.createElement("div", { style: { overflow: 'hidden', padding: '0.5rem', width: '50%' } },
                React.createElement(AuthenticationStatus, null))),
        React.createElement(AuthenticationActions, null)));
};
export default AuthenticationMonitor;
