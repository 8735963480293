import { Button, Dropdown, Icon, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import FieldSelector from './FieldSelector';
const MarkerDropdown = ({ index, onChangeDropdown, onChangeMarker, onClickDeleteMarker, removeFieldData, options, testListItem, value, }) => (React.createElement("div", null,
    React.createElement("div", { style: { height: '4rem' } },
        React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", { style: { width: '100%' } },
                    React.createElement(Dropdown, { className: typeof testListItem !== 'undefined' ? testListItem.className : '', label: typeof testListItem !== 'undefined' ? testListItem.text : '', onChange: onChangeMarker, options: options, value: options.find((o) => ('value' in o && o.value === value)) ?? null })),
                React.createElement(Button, { className: "co-delete-button", iconButton: true, name: `removeMarker${index.toString()}`, onClick: onClickDeleteMarker, size: "small" },
                    React.createElement(Icon, { iconName: "delete" }))))),
    typeof testListItem !== 'undefined' && 'options' in testListItem
        ? (React.createElement("div", { style: { height: '3rem' } },
            React.createElement("div", { style: { position: 'absolute', width: 'calc(100% - 4rem)' } },
                React.createElement(FieldSelector, { onChangeDropdown: onChangeDropdown, removeFieldData: removeFieldData, testListItem: testListItem }))))
        : null));
export default MarkerDropdown;
