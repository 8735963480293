import * as React from 'react';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import Stream from './Stream';
const Live = () => {
    const { updateView, view } = React.useContext(NavigationContext);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('STREAM');
        }
    }, [view, updateView]);
    switch (view) {
        case 'STREAM':
            return (React.createElement(Stream, null));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default Live;
