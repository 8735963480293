import * as React from 'react';
import { Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptions } from 'utils/change';
const BaseCurrency = () => {
    const { apiFetchJson: jsonPlus } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const [currency, setCurrency] = React.useState();
    const [storageAvailable, setStorageAvailable] = React.useState(false);
    const onChangeCurrency = React.useCallback((event) => {
        const { value } = event.target.value;
        setCurrency(value);
        localStorage.setItem('base-currency', value);
    }, []);
    React.useEffect(() => {
        /**
        * Only when the user has access to localStorage can he choose the base currency
        */
        try {
            const storedValue = localStorage.getItem('base-currency') ?? 'EUR';
            setCurrency(storedValue);
            setStorageAvailable(true);
            (async () => {
                const request = new ServerRequest('/nit/currencies');
                try {
                    const body = await jsonPlus(request, 'Error downloading currencies');
                    const newOptions = body.sort().map((c) => ({ label: c, value: c }));
                    setOptions(newOptions);
                }
                catch (error) {
                    //
                }
            })();
        }
        catch (error) {
            setStorageAvailable(false);
        }
    }, [jsonPlus]);
    return storageAvailable
        ? (React.createElement(Card, { className: "co-card-stack" },
            React.createElement("div", { className: "co-item" },
                React.createElement(Typography, { type: "h1" }, "Base Currency")),
            React.createElement("div", { className: "co-info co-item" },
                React.createElement(Dropdown, { label: "Base currency from which equivalent amounts are calculated.", name: "period", onChange: onChangeCurrency, options: options, value: getOptions(currency, options) }))))
        : null;
};
export default BaseCurrency;
