import EsmaSecurity from '../security/EsmaSecurity';
class EsmaFilter {
    exclude;
    name;
    values;
    tags;
    update(name) {
        return (event) => Object.assign(new EsmaFilter(), this, { [name]: event });
    }
    updateTags(event) {
        const newFilter = Object.assign(new EsmaFilter(), this);
        if (newFilter.tags === undefined) {
            newFilter.tags = {
                exclude: false,
                values: [],
            };
        }
        newFilter.tags.values = (event.target.value || []).map((t) => t.value);
        return newFilter;
    }
    create() {
        const test = (contains, name) => (this[name]?.exclude && !contains)
            ?? (!this[name]?.exclude && contains);
        return (s) => {
            if (s instanceof EsmaSecurity) {
                const tagsTest = s.tags && this.tags?.values.length ? test(s.tags.some((t) => this.tags?.values.includes(t)), 'tags') : true;
                return tagsTest;
            }
            return false;
        };
    }
}
export default EsmaFilter;
