import * as React from 'react';
import { Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import { Source } from '../../sources/sources';
import ResultsShare from '../../components/results/ResultsShareModal';
import SecuritiesTable from '../../components/results/SecuritiesTable';
import currencyDownload from '../../utils/currencyDownload';
import Filter from '../Filter';
import './Results.scss';
import ResultsPickerRow from '../../components/results/ResultsPickerRow';
import resultsDownload from './resultsDownload';
const Results = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [currencies, setCurrencies] = React.useState(new Map());
    const [filter, setFilter] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [unfilteredData, setUnfilteredData] = React.useState([]);
    const [result, setResult] = React.useState(() => {
        const saved = localStorage.getItem('result');
        return saved ? JSON.parse(saved) : undefined;
    });
    const [showFilter, setShowFilter] = React.useState(false);
    const [showResultsShare, setShowResultsShare] = React.useState(false);
    const [source, setSource] = React.useState();
    const [filteredData, setFilteredData] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState({});
    const createdOptions = React.useCallback((array) => {
        const frequencyCount = (allTags, tag) => {
            const newTags = { ...allTags };
            if (tag in allTags) {
                newTags[tag] += 1;
            }
            else {
                newTags[tag] = 1;
            }
            return newTags;
        };
        const countedNames = array.reduce(frequencyCount, {});
        return Object.entries(countedNames)
            .sort(([, v1], [, v2]) => v2 - v1)
            .map(([key, value]) => ({ label: `${key} [${value}]`, value: key }));
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setUnfilteredData([]);
        setLoading(true);
        (async () => {
            const url = new ApiServerURL('/nit/results/all');
            if (result) {
                url.searchParams.append('id', result.value);
                url.searchParams.append('source', result.data);
            }
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const { source: newSource, securities: newSecurities, filter: newFilter, } = resultsDownload(body);
                setSource(newSource);
                setFilter(newFilter);
                setUnfilteredData(newSecurities);
            }
            catch (error) {
                sendFetchError('Unable to download results from previous searches', error, request);
                setError(true);
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson, result]);
    // }, [createdOptions, currencies, download, filterOptions, requestWithId]);
    React.useEffect(() => {
        setFilteredData(unfilteredData.filter(filter !== undefined ? filter.create() : () => true));
    }, [filter, unfilteredData]);
    React.useEffect(() => {
        if (source === Source.EUROCLEAR) {
            setFilterOptions((f) => ({
                ...f,
                categories: createdOptions(unfilteredData.map((s) => s.issuercategory)),
                cfiGroups: createdOptions(unfilteredData.map((s) => (s.cfi ? s.cfi.group : ''))),
                countries: createdOptions(unfilteredData
                    .map((s) => s.issuercountryname)),
            }));
        }
    }, [createdOptions, unfilteredData, source]);
    React.useEffect(() => {
        const tags = unfilteredData.flatMap((t) => t.tags);
        const tagOptions = createdOptions(tags);
        setFilterOptions((f) => ({ ...f, tags: tagOptions }));
    }, [createdOptions, unfilteredData]);
    /* Download the currency data from the ECB */
    React.useEffect(() => {
        (async () => {
            const parsedCurrencies = await currencyDownload();
            setCurrencies(parsedCurrencies);
        })();
    }, []);
    return (React.createElement(Card, null,
        React.createElement(ResultsShare, { filter: filter, result: result, securities: filteredData, setShow: setShowResultsShare, show: showResultsShare, source: source }),
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, { type: "h1" }, "Results")),
        React.createElement(ResultsPickerRow, { clickFilter: () => setShowFilter(!showFilter), clickShare: () => setShowResultsShare(true), result: result, setResult: setResult }),
        showFilter
            ? React.createElement(Filter, { setFilter: setFilter, filter: filter, options: filterOptions }) : '',
        source != null
            ? (React.createElement(SecuritiesTable, { currencies: currencies, filteredData: filteredData, hasError: hasError, isLoading: isLoading, source: source })) : ''));
};
export default Results;
