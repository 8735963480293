import RenderingContext from 'contexts/RenderingContext';
import * as React from 'react';
const DateTimeCell = ({ column, row }) => {
    const { printDate } = React.useContext(RenderingContext);
    const text = React.useMemo(() => {
        if (column.accessorFn !== undefined) {
            let value = column.accessorFn(row.original, row.index);
            if (typeof value === 'string') {
                value = new Date(value);
            }
            try {
                return printDate(value, 'YMD');
            }
            catch (error) {
                return `ERROR: ${error}`;
            }
        }
        return '';
    }, [column, printDate, row.original, row.index]);
    return (React.createElement("div", null, text));
};
export default DateTimeCell;
