import * as React from 'react';
import FilterItemInput from '../components/filter-item/FilterItemInput';
const Filter = function FilterElement({ filter, options, setFilter, }) {
    const [data, setData] = React.useState(filter);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        if (data !== undefined) {
            setData({ ...data, [name]: value });
        }
    }, [data]);
    React.useEffect(() => {
        setFilter(data);
    }, [data, setFilter]);
    return (React.createElement("div", { id: "filter-box", className: "base-form" }, data !== undefined ? Object.entries(data).map(([propertyName, value]) => (React.createElement(FilterItemInput, { label: value?.name ?? 'UNKNOWN???', onChange: onChangeInput(propertyName), options: options[propertyName], value: value }))) : null));
};
export default Filter;
