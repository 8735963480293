import * as React from 'react';
import './EditableCell.css';
import { Icon, Loader } from '@symphony-ui/uitoolkit-components';
const EditableCell = React.forwardRef(({ children, className, copyValue, errorText, isEditing, isLoading, isLoadingRow, isOverriden = false, metaValue, pasteValue, removeOverride, setEditing, title, }, ref) => {
    const onClick = React.useCallback(() => {
        if (!isLoadingRow) {
            setEditing(true);
        }
    }, [isLoadingRow, setEditing]);
    const onKeyDown = React.useCallback((event) => {
        if (!isLoadingRow && !isEditing) {
            if (copyValue !== undefined && (event.key === 'C' || event.key === 'c') && event.ctrlKey) {
                (async () => copyValue())();
            }
            if (pasteValue !== undefined && (event.key === 'V' || event.key === 'v') && event.ctrlKey) {
                (async () => pasteValue())();
            }
            if (metaValue !== undefined && (event.key === 'M' || event.key === 'm') && event.ctrlKey) {
                (async () => metaValue())();
            }
        }
    }, [copyValue, isEditing, isLoadingRow, metaValue, pasteValue]);
    const onKeyUp = React.useCallback((event) => {
        if (!isLoadingRow) {
            if (event.key === 'Enter') {
                setEditing(true);
            }
            if (event.key === 'Escape') {
                setEditing(false);
            }
            if ((event.key === 'Delete' || event.key === 'Backspace') && removeOverride !== undefined && !isEditing) {
                removeOverride();
            }
        }
    }, [isEditing, isLoadingRow, removeOverride, setEditing]);
    const content = React.useMemo(() => {
        if (errorText !== undefined) {
            return (React.createElement(Icon, { className: "co-red", iconName: "alert-triangle", title: errorText }));
        }
        if (isLoading) {
            return (React.createElement("div", { style: { marginTop: '0.25rem' } },
                React.createElement(Loader, null)));
        }
        return children;
    }, [children, errorText, isLoading]);
    return (React.createElement("td", { className: `co-table-cell co-editable${isOverriden ? ' co-overriden' : ''}${className ? ` ${className}` : ''}${title && isOverriden ? ' co-exists' : ''}`, onClick: onClick, onKeyDown: onKeyDown, onKeyUp: onKeyUp, ref: ref, role: "gridcell", tabIndex: 0, title: isOverriden ? title : undefined }, content));
});
export default EditableCell;
