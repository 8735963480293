/* eslint-disable import/extensions */
import AnnaSecurity from '../models/security/AnnaSecurity';
import EsmaSecurity from '../models/security/EsmaSecurity';
import EuroclearSecurity from '../models/security/EuroclearSecurity';
import SecSecurity from '../models/security/SecSecurity';
import { Source } from '../sources/sources';
/**
 * This uses https://www.npmjs.com/package/xlsx-populate
 * @param {Security[]} data
 */
export const getColumnsSpreadSheet = (source) => {
    switch (source) {
        case Source.ANNA:
            return AnnaSecurity.columnsSpreadSheet;
        case Source.ESMA:
            return EsmaSecurity.columnsSpreadsheet;
        case Source.EUROCLEAR:
            return EuroclearSecurity.columnsSpreadsheet;
        case Source.SEC:
            return SecSecurity.columnsSpreadsheet;
        default:
            throw new Error(`Unknown source: ${source}`);
    }
};
export const getColumnsSpreadSheetFromClass = (security) => {
    if (security instanceof AnnaSecurity) {
        return AnnaSecurity.columnsSpreadSheet;
    }
    if (security instanceof EsmaSecurity) {
        return EsmaSecurity.columnsSpreadsheet;
    }
    if (security instanceof EuroclearSecurity) {
        return EuroclearSecurity.columnsSpreadsheet;
    }
    if (security instanceof SecSecurity) {
        return SecSecurity.columnsSpreadsheet;
    }
    throw new Error('Unknown type of security');
};
const exportToExcel = async (table) => {
    const XlsxPopulate = await import(
    /* webpackChunkName: "xlsx-chunk" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    'xlsx-populate/browser/xlsx-populate.js');
    const numberOfColumns = Math.max(...table.getRowModel().rows.map((r) => r.getVisibleCells().length));
    const numberOfRows = table.getRowModel().rows.length;
    const workbook = await XlsxPopulate.default.fromBlankAsync();
    const sheet = workbook.sheet('Sheet1');
    const range = sheet.range(1, 1, 1, numberOfColumns + 1);
    range.autoFilter();
    sheet.freezePanes(0, 1);
    table.getHeaderGroups().forEach((headerGroup) => {
        headerGroup.headers.forEach((header, index) => {
            const headerText = header.column.columnDef.header?.toString();
            sheet.cell(1, index + 1).value(headerText);
            sheet.column(index + 1).columnName(headerText);
        });
    });
    table.getRowModel().rows.forEach((row, rowIndex) => {
        row.getVisibleCells().forEach((cell, columnIndex) => {
            const spreadsheetCell = sheet.cell(rowIndex + 2, columnIndex + 1);
            const value = cell.getValue();
            if (value == null) {
                spreadsheetCell.value('-');
            }
            else if (value instanceof Date) {
                spreadsheetCell.value(value).style('numberFormat', 'DD MMM YYYY');
            }
            else if (typeof value === 'number') {
                spreadsheetCell.value(value).style('numberFormat', '#,##0;-#,##0');
            }
            else if (typeof value === 'string') {
                spreadsheetCell.value(value);
            }
            else {
                throw new Error(`Unknown value type: ${typeof value}`);
            }
        });
    });
    sheet.range(1, 1, numberOfRows + 1, numberOfColumns + 1).style('fontSize', 10).style('fontFamily', 'Arial');
    const blob = await workbook.outputAsync();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'out.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
export default exportToExcel;
