import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
const WorkflowTable = ({ create, deleteFn, edit, hasError, isLoading, workflows, }) => {
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteFn, label: 'Delete', type: 'error' },
                ],
            },
        },
        {
            accessorKey: 'issuer',
            header: 'Status Bloomberg',
        },
    ], [deleteFn, edit]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "MTN Workflows" }),
        React.createElement(CustomTable, { columns: columns, data: workflows, emptyMessage: "You have no MTN work flows yet", errorMessage: "Error downloading MTN work flows", hasError: hasError, isLoading: isLoading, name: "workflow" })));
};
export default WorkflowTable;
