import FilterItem from '../FilterItem';
import EuroclearSecurity from '../security/EuroclearSecurity';
class EuroclearFilter {
    categories;
    cfiGroups;
    countries;
    exclude;
    name;
    values;
    tags;
    update(name) {
        return (event) => Object
            .assign(new EuroclearFilter(), this, { [name]: event });
    }
    updateTags(event) {
        const newFilter = Object.assign(new EuroclearFilter(), this);
        const exclude = newFilter.tags?.exclude ?? false;
        newFilter.tags = new FilterItem({ exclude, values: (event.target.value || []).map((t) => t.value) });
        return newFilter;
    }
    create() {
        const test = (contains, exclude) => (exclude && !contains) || (!exclude && contains);
        return (s) => {
            if (s instanceof EuroclearSecurity) {
                const tagsTest = (() => {
                    if (s.tags !== undefined && this.tags !== undefined) {
                        const tt = this.tags;
                        const contains = s.tags.some((t) => tt.values.includes(t));
                        const { exclude } = this.tags;
                        return (exclude && !contains) || (!exclude && contains);
                    }
                    return true;
                })();
                const countryTest = this.countries?.values?.length ? test(this.countries.values.includes(s.issuercountryname), this.countries.exclude) : true;
                const categoryTest = this.categories?.values?.length ? test(this.categories.values.includes(s.issuercategory), this.categories.exclude) : true;
                const cfiGroupTest = this.cfiGroups?.values?.length ? test(s.cfi !== undefined ? this.cfiGroups.values.includes(s.cfi?.group) : false, this.cfiGroups.exclude) : true;
                return tagsTest && countryTest && categoryTest && cfiGroupTest;
            }
            return false;
        };
    }
}
export default EuroclearFilter;
